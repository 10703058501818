import { SET_EMAIL_PREFERENCE_MUTATION } from './schema';

export async function setEmailPreference(client, options = {}) {
  const response = await client.mutate({
    mutation: SET_EMAIL_PREFERENCE_MUTATION,
    ...options,
  });

  return {
    data: response.data?.setEmailPreference,
    error: response.error,
  };
}
