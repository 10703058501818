import { FC } from 'react';
import { useStoreLocatorContext } from './context';
import { Alert, Button, Icon, Spinner, TreezStore } from '@/components';
import classNames from 'classnames';

import styles from './storelocator.module.scss';
import { StoreExternalItem } from './StoreLocator.components';

interface IPickupSectionProps {
  showInstructionText: boolean;
  loadingLocation: boolean;
  handleUseCurrentLocation: () => void;
}

const PickupSection: FC<IPickupSectionProps> = ({
  showInstructionText,
  loadingLocation,
  handleUseCurrentLocation,
}) => {
  const { isPickup, isPickupInvalid, setIsPickupInvalid, singleStore } =
    useStoreLocatorContext();

  return (
    <div className={styles.pickup_section}>
      {showInstructionText && (
        <p className={styles.pickup_section__title}>Select a pickup location</p>
      )}
      <div className={styles.pickup_section__content}>
        {isPickup && isPickupInvalid && (
          <Alert
            error
            isOpen
            toggle={event => {
              event.stopPropagation();
              setIsPickupInvalid(false);
            }}
          >
            Please select a shop.
          </Alert>
        )}
        <StoresList loadingLocation={loadingLocation} />
        <Button
          className={classNames(styles.current_location, {
            [styles.hidden]: singleStore && isPickup,
          })}
          onClick={handleUseCurrentLocation}
        >
          {loadingLocation ? (
            <Spinner className={styles.crosshairs_icon} sm />
          ) : (
            <Icon className={styles.crosshairs_icon} name="crosshairs" />
          )}
          <span>Use current location</span>
        </Button>
      </div>
    </div>
  );
};

interface IStoresListProps {
  loadingLocation: boolean;
}

const StoresList: FC<IStoresListProps> = ({ loadingLocation }) => {
  const { stores, selectedStoreId, setSelectedStoreId, externalStores } =
    useStoreLocatorContext();

  return (
    <div>
      {stores.map(store => {
        const isActive = selectedStoreId === store.shortName;

        return (
          <TreezStore
            className={styles.store}
            distanceInMiles={store?.distance ?? ''}
            isActive={isActive}
            isLoadingLocation={loadingLocation}
            isRadioType
            key={store?.shortName}
            onSelected={setSelectedStoreId}
            store={store}
            withBorders={false}
          />
        );
      })}
      {externalStores?.map(store => (
        <StoreExternalItem
          isOpen={true}
          isSelected={selectedStoreId === store?.shortName}
          key={store?.shortName}
          onSelected={setSelectedStoreId}
          store={store}
        />
      ))}
    </div>
  );
};

export { PickupSection };
