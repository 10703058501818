'use client';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/navigation';
import '@idscan/idvc2/dist/css/idvc.css';

import { Alert, Button, Heading, useAuth } from '@/components';
import {
  initializeApollo,
  useEcomStore,
  validateDIVETransaction,
} from '@/data';

import { useNotify } from '@/hooks';
import Config, { ID_SCAN_URL } from './DIVE.config';

import style from './dive.module.scss';

// Documentation
// https://docs.idscan.net/dive/dive-api/web-library-v2-config-settings.html

// Digital Indentity Verification Engine
// https://mydive.idscan.net/overview

const IDSCAN_MAX_ATTEMPTS = 5;
const VERIFICATION_FAILED_ERROR_MESSAGE = `ID verification has failed, please try again. If this issue persist
 please contact support using the chat feature on the bottom right of the page.`;
const IDSCAN_MAX_ATTEMPTS_ERROR_MESSAGE = `You have used all your ID verification attempts. Please contact support
 by using the chat feature on the bottom right of the page, or call the store directly.`;

const DIVEComponent = () => {
  const [storedState, setStoredState] = useEcomStore();
  const [showInfoText, setShowInfoText] = useState(true);
  const maxIdScanAttemptsReached = useMemo(
    () => storedState.idscan_attempts >= IDSCAN_MAX_ATTEMPTS,
    [storedState.idscan_attempts],
  );
  const [errorOcurred, setErrorOcurred] = useState(maxIdScanAttemptsReached);
  const { updateUserAttributes } = useAuth();
  const { notify } = useNotify();
  const client = initializeApollo();
  const router = useRouter();

  const handleSubmit = async (idvc, req: any) => {
    setShowInfoText(false);
    if (maxIdScanAttemptsReached) {
      setErrorOcurred(true);
      return;
    }

    setStoredState({ idscan_attempts: storedState.idscan_attempts + 1 });

    const {
      data: { success: valitated_user, error: reqError },
      error,
    } = await validateDIVETransaction(client, {
      variables: {
        input: req,
      },
    });

    if (error || reqError) {
      setErrorOcurred(true);
      idvc.showSpinner(false);
      return;
    }

    setStoredState({
      berbix_verified: valitated_user,
    });

    if (!valitated_user) {
      setErrorOcurred(true);
      idvc.showSpinner(false);
      return;
    }

    updateUserAttributes({
      'custom:BERBIX_VERIFY': 'true',
    });

    notify('success', 'ID verification completed successfully');
  };

  const handleTryAgain = () => {
    location.reload();
  };

  const handleGoBack = () => {
    router.push('/');
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${ID_SCAN_URL}/idvc.js`;

    script.onload = function () {
      // @ts-ignore
      const idvc = new window.IDVC({
        ...Config,
        onChange(_data) {},
        onCameraError(_data) {
          setErrorOcurred(true);
        },
        onReset(_data) {},
        onRetakeHook(_data) {},
        clickGuidlines() {},
        submit(data) {
          idvc.showSpinner(true);

          let frontStep, pdfStep, faceStep, mrzStep;
          let frontImage, backImage, faceImage;
          let captureMethod;
          let rawTrackString;

          switch (data.documentType) {
            // Drivers License and Identification Card
            case 1:
              frontStep = data.steps.find(item => item.type === 'front');
              pdfStep = data.steps.find(item => item.type === 'pdf');
              faceStep = data.steps.find(item => item.type === 'face');

              frontImage = frontStep.img.split(/:image\/(jpeg|png);base64,/)[2];
              backImage = pdfStep.img.split(/:image\/(jpeg|png);base64,/)[2];
              faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];

              rawTrackString =
                pdfStep && pdfStep.trackString ? pdfStep.trackString : '';

              captureMethod =
                JSON.stringify(+frontStep.isAuto) +
                JSON.stringify(+pdfStep.isAuto) +
                JSON.stringify(+faceStep.isAuto);

              break;
            // US and International Passports
            case 2:
              mrzStep = data.steps.find(item => item.type === 'mrz');
              faceStep = data.steps.find(item => item.type === 'face');

              frontImage = mrzStep.img.split(/:image\/(jpeg|png);base64,/)[2];
              faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];

              rawTrackString =
                mrzStep && mrzStep.mrzText ? mrzStep.mrzText : '';

              captureMethod =
                JSON.stringify(+mrzStep.isAuto) +
                JSON.stringify(+faceStep.isAuto);

              break;
            default:
          }

          const trackStringArray = rawTrackString.split('.');
          const trackString = trackStringArray[0];
          const barcodeParams = trackStringArray[1];

          const req = {
            front_image_base64: frontImage,
            back_or_second_image_base64: backImage,
            face_image_base64: faceImage,
            document_type: data.documentType,
            track_string: {
              data: trackString,
              barcode_params: barcodeParams,
            },
            overridden_settings: {
              is_ocr_enabled: true,
              is_back_or_second_image_processing_enabled: true,
              is_face_match_enabled: true,
            },
            metadata: {
              capture_method: captureMethod,
            },
          };

          handleSubmit(idvc, req);
        },
      });
    };

    script.onerror = function (_e) {
      setErrorOcurred(true);
    };

    document.head.appendChild(script);
  }, []);

  return (
    <div className={style.berbix__container}>
      <Heading level={2}>Verify your Identity</Heading>
      {errorOcurred && (
        <Alert error={true} isOpen={true} toggle={() => {}}>
          {maxIdScanAttemptsReached
            ? IDSCAN_MAX_ATTEMPTS_ERROR_MESSAGE
            : VERIFICATION_FAILED_ERROR_MESSAGE}
        </Alert>
      )}
      {showInfoText && !errorOcurred && (
        <p>
          Please use the following prompt to verify your ID and selfie. Please
          note that the photo of your identification must be clear and is best
          taken against a solid background, also, must not be expired. Your
          selfie must be a picture that is taken at the time of verification and
          is best taken with adequate lighting.
        </p>
      )}
      {!maxIdScanAttemptsReached && (
        <div id={Config.el} style={{ width: '100%' }}></div>
      )}

      {errorOcurred && (
        <div className={style.berbix_buttons}>
          <Button
            color="primary"
            disabled={maxIdScanAttemptsReached}
            onClick={handleTryAgain}
          >
            Try again
          </Button>
          <Button color="secondary" onClick={handleGoBack}>
            Go back
          </Button>
        </div>
      )}
    </div>
  );
};

export { DIVEComponent };
