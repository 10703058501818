import React, { FC } from 'react';
import { Accordion } from '../Accordion';
import { Container } from '@/components';
import { FiltersStore, useStoreFilterList } from './FiltersStoreList';
import styles from './filterstore.module.scss';

const FilterStoreContainer: FC<IFiltersStoreContainerProps> = ({
  children,
}) => {
  return <Container>{children}</Container>;
};

const FiltersStoreList: FC<IFiltersStoreList> = ({ handleFilterSelection }) => {
  return (
    <div>
      <WantFilter handleFilterSelection={handleFilterSelection} />
      <TypeFilter handleFilterSelection={handleFilterSelection} />
      <DealsFilter handleFilterSelection={handleFilterSelection} />
    </div>
  );
};

const WantFilter: FC<IFiltersStoreList> = ({ handleFilterSelection }) => {
  const resultState = useStoreFilterList('want');

  return (
    <Accordion
      classNameHeader={styles.accordion_header}
      isExpanded
      label="I want"
    >
      <FiltersStore
        handleFilterSelection={handleFilterSelection}
        options={resultState}
        type={'want'}
      />
    </Accordion>
  );
};

const TypeFilter: FC<IFiltersStoreList> = ({ handleFilterSelection }) => {
  const resultType = useStoreFilterList('type');

  return (
    <Accordion
      classNameHeader={styles.accordion_header}
      isExpanded
      label="Store type"
    >
      <FiltersStore
        handleFilterSelection={handleFilterSelection}
        options={resultType}
        type="type"
      />
    </Accordion>
  );
};

const DealsFilter: FC<IFiltersStoreList> = ({ handleFilterSelection }) => {
  const resultDeals = useStoreFilterList('deals');

  return (
    <Accordion
      classNameHeader={styles.accordion_header}
      isExpanded
      label="Deals"
    >
      <FiltersStore
        handleFilterSelection={handleFilterSelection}
        options={resultDeals}
        type="deals"
      />
    </Accordion>
  );
};

interface IFiltersStoreList {
  // eslint-disable-next-line no-unused-vars
  handleFilterSelection: (id: string, options: any) => void;
}

interface IFiltersStoreContainerProps {
  children: React.ReactNode;
}

export { FilterStoreContainer, FiltersStoreList };
