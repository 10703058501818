'use client';

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useUiStore } from '@/data';
import {
  useActiveHeaderSlice,
  useCurrentPromotion,
  useIsServerSide,
} from '@/hooks';
import { shouldHideNavigation } from '@/utils';
import { Alert, Link } from '@/components';
import { Swiper as ISwiper } from 'swiper/types';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import style from './promotion.module.scss';

//swipper Styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import { asLink } from '@prismicio/helpers';

interface IPromotionProps {
  className?: string;
  location?: 'layout' | 'header';
}

const Promotion: FC<IPromotionProps> = ({
  location = 'layout',
  className,
  ...props
}) => {
  const activeSlice = useActiveHeaderSlice();
  const pathname = usePathname();
  const { isServerSide } = useIsServerSide();
  const [state, setState] = useUiStore();
  const { delay, messages } = useCurrentPromotion();
  const isCheck = shouldHideNavigation(pathname as string);
  const promotionRef = useRef<HTMLDivElement>(null);
  const [current, setCurrent] = useState({
    dismisible: false,
    index: 0,
  });

  useEffect(() => {
    const element = promotionRef.current;

    if (!element || element?.id !== 'promotion-id-layout') {
      document.documentElement.style.setProperty('--top-promotion', '0px');
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        document.documentElement.style.setProperty(
          '--top-promotion',
          `${entry.contentRect?.height}px`,
        );
      }
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, []);

  const { actives } = useMemo(() => {
    const actives = messages?.filter(message => message?.active);

    if (actives) {
      setCurrent({
        dismisible: actives?.[0]?.dismissible,
        index: 0,
      });
    }

    return { actives };
  }, [messages]);

  if (
    isCheck ||
    !state.is_promotionbar_open ||
    !actives.length ||
    (location === 'layout' &&
      activeSlice?.variation === 'withCenteredLogoAndLocatorFix')
  ) {
    return null;
  }

  const handleDismisible = () => {
    if (actives.length === 1) {
      setState({ is_promotionbar_open: false });
      return;
    }

    actives.splice(current.index, 1);
  };

  const handleSwiperChange = (swiper: ISwiper) => {
    const index = swiper?.activeIndex;
    setCurrent({
      dismisible: actives[index]?.dismissible,
      index,
    });
  };

  const getMessage = () => {
    return (
      <Swiper
        autoHeight={true}
        autoplay={{
          delay: delay ?? 6000,
          disableOnInteraction: false,
        }}
        className={style.promotion_swiper}
        effect={'fade'}
        loop
        modules={[Autoplay, EffectFade]}
        onSlideChange={handleSwiperChange}
        slidesPerView={1}
        spaceBetween={30}
      >
        {actives?.map((message, index) => (
          <SwiperSlide className={style.promotion_swiper__item} key={index}>
            <Link
              href={(asLink(message?.cta) ?? message?.internalCta) as string}
              target={message?.cta ? '_blank' : '_self'}
            >
              {message.message}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  return (
    <aside
      className={classNames(style.promotion, className, {
        [style.promotion__sticky]:
          isServerSide && activeSlice?.primary?.floating,
      })}
      id={`promotion-id-${location}`}
      ref={promotionRef}
      {...props}
    >
      <Alert
        className={classNames(style.promotion__text, style.alert)}
        floatingAction
        isOpen={state.is_promotionbar_open}
        showIcon={false}
        toggle={current.dismisible ? handleDismisible : undefined}
      >
        {getMessage()}
      </Alert>
    </aside>
  );
};

export default Promotion;
