import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

function Collapse({ isOpen, className, ...props }) {
  const [isMounted, setIsMounted] = useState(isOpen);
  const ref = useRef(null);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);

      return;
    }

    const element = ref.current;

    if (isOpen) {
      element.style.height = `${element.scrollHeight}px`;

      const animationEnd = () => {
        document.removeEventListener('transitionend', animationEnd);
        element.style.height = null;
      };

      document.addEventListener('transitionend', animationEnd);
    } else {
      requestAnimationFrame(() => {
        element.style.height = `${element.scrollHeight}px`;

        requestAnimationFrame(() => {
          element.style.height = '0px';
        });
      });
    }
  }, [isOpen]);

  return (
    <div
      className={classNames('collapse', className)}
      data-collapsed={!isOpen}
      ref={ref}
      style={{ height: 0 }}
      {...props}
    />
  );
}

export default Collapse;
