import { CSSProperties, FC } from 'react';

import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import { Heading, Icon } from '@/components';

import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceWithImageGallery,
} from 'prismicio-types';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';

import style from './withimagegalleryvariation.module.scss';

export const WithImageGalleryVariation: FC<{
  slice: ImageWithTextSideBySideSliceWithImageGallery &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);
  const {
    all_images_border_radius,
    content_horizontal_align,
    content_vertical_align,
    image_bottom_left_border_radius,
    image_bottom_right_border_radius,
    image_top_left_border_radius,
    image_top_right_border_radius,
    item_description_color,
    top_image,
    bottom_left_image,
    bottom_right_image,
    item_title_color,
    layout_direction,
  } = slice.primary;

  const alignText = content_horizontal_align ?? 'center';
  const layoutDirection = layout_direction ?? 'left';

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'left':
        return 'flex-start';

      case 'right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  const bodyVariables = {
    '--content-padding-left': layoutDirection === 'left' ? '13.6%' : '0px',
    '--content-padding-right': layoutDirection === 'left' ? '0px' : '13.6%',
  };

  const imageBorderRadiuses = (index: number) => {
    if (all_images_border_radius) {
      return {
        borderTopLeftRadius: all_images_border_radius,
        borderBottomLeftRadius: all_images_border_radius,
        borderTopRightRadius: all_images_border_radius,
        borderBottomRightRadius: all_images_border_radius,
      };
    }

    if (index === 0) {
      return {
        borderTopLeftRadius: image_top_left_border_radius,
        borderTopRightRadius: image_top_right_border_radius,
      };
    }

    if (index === 1) {
      return {
        borderBottomLeftRadius: image_bottom_left_border_radius,
      };
    }

    if (index === 2) {
      return {
        borderBottomRightRadius: image_bottom_right_border_radius,
      };
    }
  };

  return (
    <>
      <div
        className={classNames(style.body, {
          [style.body__left]: layoutDirection === 'left',
        })}
        style={{
          alignItems: textAlign(content_horizontal_align as string),
          textAlign: alignText,
          alignSelf: textAlign(content_vertical_align),
          ...bodyVariables,
        }}
      >
        {filteredItems &&
          filteredItems?.map((option, index) => (
            <div className={style.content__option_item} key={index}>
              {option.title && (
                <Heading
                  className={style.title}
                  level={5}
                  style={{ color: item_title_color as string }}
                >
                  {option.title}
                </Heading>
              )}
              {option.description && (
                <p
                  className={style.description}
                  style={{ color: item_description_color as string }}
                >
                  {option.description}
                </p>
              )}
            </div>
          ))}
      </div>
      <div
        className={classNames(style.figure_container, {
          [style.figure_container__left]: layoutDirection === 'left',
        })}
      >
        <figure
          className={classNames(style.figure, style.top_image)}
          style={imageBorderRadiuses(0) as CSSProperties}
        >
          {top_image?.url ? (
            <PrismicNextImage
              fallbackAlt=""
              field={top_image}
              fill
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <Icon name="image" />
          )}
        </figure>
        <figure
          className={classNames(style.figure, style.bottom_image)}
          style={imageBorderRadiuses(1) as CSSProperties}
        >
          {bottom_left_image?.url ? (
            <PrismicNextImage
              fallbackAlt=""
              field={bottom_left_image}
              fill
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <Icon name="image" />
          )}
        </figure>
        <figure
          className={classNames(style.figure, style.bottom_image)}
          style={imageBorderRadiuses(2) as CSSProperties}
        >
          {bottom_right_image?.url ? (
            <PrismicNextImage
              fallbackAlt=""
              field={bottom_right_image}
              fill
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <Icon name="image" />
          )}
        </figure>
      </div>
    </>
  );
};
