import { useEffect, useMemo, useState } from 'react';

const useCenterPositionSearchBox = maxHeigth => {
  const [width, setWidth] = useState<number>(0);
  const aspectRatio = useMemo(() => (width < 640 ? 0.5813 : 0.2227), [width]);
  const applyMaxHeigth = useMemo(
    () => !!(width > 1536 && maxHeigth > 0),
    [width],
  );
  const position = useMemo(
    () => (applyMaxHeigth ? maxHeigth : width * aspectRatio) / 2,
    [width, aspectRatio],
  );

  const center = useMemo(
    () => (width > 320 ? position - 56 : -100),
    [position],
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return { center };
};

export { useCenterPositionSearchBox };
