import { FC } from 'react';

import classNames from 'classnames';
import { getSlicePadding } from '@/utils';
import { Container } from '@/components';
import { ImageRowSlice } from '@/types';
import { useShowByLocationSlug } from '@/hooks';
import { DefaultVariation } from './DefaultVariation';
import ImageComparisonSlider from './ImageComparisonSlider';

import style from './imagerow.module.scss';

const ImageRow: FC<ImageRowSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);

  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;
  const background = slice?.primary?.background_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.gallery__image_row,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: background!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container
        className={classNames(style.gallery__images, {
          [style.gallery__images_comparison_slider]:
            slice?.variation === 'imageComparisonSlider',
        })}
      >
        {slice?.variation === 'default' && <DefaultVariation slice={slice} />}
        {slice?.variation === 'imageComparisonSlider' && (
          <ImageComparisonSlider slice={slice} />
        )}
      </Container>
    </section>
  );
};

export default ImageRow;
