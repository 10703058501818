import { Button, Container, IconSvg } from '@/components';
import { FC } from 'react';
import { useRouter } from 'next/navigation';
import {
  asText,
  ColorField,
  ImageField,
  KeyTextField,
  NumberField,
  RichTextField,
} from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializerWithProps } from '@/utils';
import { TEMPLATE_BASE_PATH } from '@/constants';

import styles from './NotFound.module.scss';
import { PrismicNextImage } from '@prismicio/next';
import { IPrismicSlice } from 'types/slices';

export interface INotFoundProps {
  title?: RichTextField;
  titleColor?: ColorField;
  description?: RichTextField;
  descriptionColor?: ColorField;
  ctaText?: KeyTextField;
  ctaLink?: KeyTextField;
  image?: ImageField;
  imageMaxWidth?: NumberField;
}

export interface INotFoundContent {
  props?: INotFoundProps;
  slices?: IPrismicSlice[];
}

const DEFAULT_TITLE = 'Oops! Page not found.';
const DEFAULT_DESCRIPTION =
  'Sorry! We are out of stock right now. Please shop our other products.';
const DEFAULT_LINK_TEXT = 'SHOP NOW';

const CustomNotFound: FC<INotFoundProps> = ({
  title,
  titleColor,
  description,
  descriptionColor,
  ctaText,
  ctaLink,
  image,
  imageMaxWidth,
}) => {
  const router = useRouter();

  const handleNavBtn = () => {
    if (ctaLink) {
      router.push(ctaLink);
      return;
    }
    router.push(TEMPLATE_BASE_PATH.SHOP);
  };

  const handleGoBack = () => {
    router.back();
  };

  const renderImage = () => {
    if (!image?.url) {
      return (
        <figure className={styles.heading__image_default}>
          <IconSvg name="not-found" />
        </figure>
      );
    }

    return (
      <figure
        className={styles.heading__image}
        style={{ maxWidth: imageMaxWidth ?? '' }}
      >
        <PrismicNextImage
          fallbackAlt=""
          field={image}
          height={image?.dimensions?.height}
          style={{ objectFit: 'cover' }}
          width={image?.dimensions?.width}
        />
      </figure>
    );
  };

  return (
    <Container className={styles.page_not_found__content}>
      {renderImage()}
      <div className={styles.heading__description}>
        {asText(title) ? (
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: titleColor,
            })}
            field={title}
          />
        ) : (
          <h1 style={{ color: titleColor ?? '' }}>{DEFAULT_TITLE}</h1>
        )}
      </div>
      <div className={styles.page_not_found__content__description}>
        {asText(description) ? (
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: descriptionColor,
            })}
            field={description}
          />
        ) : (
          <p style={{ color: descriptionColor ?? '' }}>{DEFAULT_DESCRIPTION}</p>
        )}
      </div>
      <div className={styles.page_not_found__cta_section}>
        <Button color="secondary" onClick={handleGoBack}>
          <div className={styles.go_back_btn}>
            <IconSvg color="red" name="arrow-left" />
            GO BACK
          </div>
        </Button>
        <Button color="primary" onClick={handleNavBtn}>
          {ctaText ?? DEFAULT_LINK_TEXT}
        </Button>
      </div>
    </Container>
  );
};

export { CustomNotFound };
