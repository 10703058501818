import { FC, useState } from 'react';
import { useFeaturedFlag } from '@/hooks';
import { Collapse, Heading, Icon } from '@/components';
import classNames from 'classnames';
import { Product } from '@/types';

interface IProductWarningProps {
  product: Product;
}

const WARNING_MESSAGE = `
This product can expose you to chemicals including β-myrcene
and cannabis smoke, which are known to the State of
California to cause cancer, and chemicals including cannabis
smoke and 9-tetrahydrocannabinol, which are known to the
State of California to cause birth defects or other
reproductive harm.`;

const ProductWarning: FC<IProductWarningProps> = ({ product }) => {
  const { showProductWarning } = useFeaturedFlag();
  const [isOpen, setIsOpen] = useState(false);

  const toggleWarning = () => {
    setIsOpen(!isOpen);
  };

  if (
    (!product?.thc?.percentage &&
      !product?.cbd?.percentage &&
      !product?.dosage) ||
    !showProductWarning
  ) {
    return null;
  }

  return (
    <>
      <Heading className={'details__h3'} level={3}>
        Warning
      </Heading>
      <button
        aria-controls="accordion-content-iWarning"
        className="bottom__warning"
        onClick={() => toggleWarning()}
      >
        Proposition 65 Warning for California Consumers
        <Icon name={isOpen ? 'angle-up' : 'angle-down'} />
      </button>
      <Collapse
        className={classNames('accordion__content', {
          ['accordion__content_collapse']: isOpen,
        })}
        id="accordion-content-iWarning"
        isOpen={isOpen}
      >
        <figure>
          <svg height="32" width="32" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m1,31c0,0 15,-30.5 15,-30l15,
                            30c0.5,0 -30.5,0 -30,0z"
              fill="#fff945"
              stroke="#000"
              strokeWidth="2"
            />
            <path
              d="m16,13l0,10m0,2l0,2"
              fill="none"
              stroke="#000"
              strokeWidth="3"
            />
          </svg>
        </figure>
        <div className={'warning__content'}>
          <span>WARNING:</span>
          <p>{WARNING_MESSAGE}</p>
          <br />
          <br />
          For more information go to{' '}
          <a
            aria-label="Opens in new window"
            href="https://www.P65Warnings.ca.gov"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.P65Warnings.ca.gov
          </a>
        </div>
      </Collapse>
    </>
  );
};

export { ProductWarning };
