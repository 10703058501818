import { Heading } from '@/components';

const EMBEDDED_CONTENT_WIDTH = '100%';
const EMBEDDED_CONTENT_HEIGHT = '500';

const htmlSerializer = {
  embed: ({ node }) => {
    const finalHtml = node.oembed.html;

    // Parse the finalHtml to extract the iframe tag
    const iframeRegex = /<iframe[^>]*>/;
    const iframeTag = finalHtml.match(iframeRegex)[0];

    // Parse the iframe tag to extract existing attributes
    const widthRegex = /width="([^"]*)"/;
    const heightRegex = /height="([^"]*)"/;

    // Replace existing width and height attributes with new values
    const updatedIframeTag = iframeTag
      .replace(widthRegex, `width="${EMBEDDED_CONTENT_WIDTH}"`)
      .replace(heightRegex, `height="${EMBEDDED_CONTENT_HEIGHT}"`);

    // Replace the old iframe tag with the updated one in the finalHtml
    const updatedFinalHtml = finalHtml.replace(iframeRegex, updatedIframeTag);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: updatedFinalHtml }}
        data-oembed={node.oembed.embed_url}
        data-oembed-provider={node.oembed.provider_name}
        data-oembed-type={node.oembed.type}
      />
    );
  },
  heading1: ({ children }) => <Heading level={1}>{children}</Heading>,
  heading2: ({ children }) => <Heading level={2}>{children}</Heading>,
  heading3: ({ children }) => <Heading level={3}>{children}</Heading>,
  heading4: ({ children }) => <Heading level={4}>{children}</Heading>,
  paragraph: ({ children }) => <p>{children}</p>,
};

const htmlSerializerWithProps = (style = {}, className) => ({
  heading1: ({ children }) => (
    <Heading className={className} level={1} style={style}>
      {children}
    </Heading>
  ),
  heading2: ({ children }) => (
    <Heading className={className} level={2} style={style}>
      {children}
    </Heading>
  ),
  heading3: ({ children }) => (
    <Heading className={className} level={3} style={style}>
      {children}
    </Heading>
  ),
  heading4: ({ children }) => (
    <Heading className={className} level={4} style={style}>
      {children}
    </Heading>
  ),
  heading5: ({ children }) => (
    <Heading className={className} level={5} style={style}>
      {children}
    </Heading>
  ),
  heading6: ({ children }) => (
    <Heading className={className} level={6} style={style}>
      {children}
    </Heading>
  ),
  listItem: ({ children }) => (
    <li className={className} style={style}>
      {children}
    </li>
  ),
  oListItem: ({ children }) => (
    <li className={className} style={style}>
      {children}
    </li>
  ),
  paragraph: ({ children }) => (
    <p className={className} style={style}>
      {children}
    </p>
  ),
});

export { htmlSerializer, htmlSerializerWithProps };
