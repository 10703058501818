import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import {
  checkIfStoreIsOpen,
  createLocationUrl,
  getFormatedPhoneNumber,
  transformMilitaryTime,
} from '@/utils';
import { MenuService, useEcomStore } from '@/data';
import { TreezStore as TreezStoreType } from '@/types';
import { Badge, IconSvg, Image, Link } from '@/components';

import { StoreRadioItem } from './TreezStore.components';

import styles from './treezstore.module.scss';
import { IStoreLocatorPageStyle } from 'app/(Storefront)/(Shop)/(Local-SEO)/store-locator/page';

const TreezStore: FC<TreezStoreProps> = ({
  store,
  isActive,
  //distanceInMiles,
  //withBorders = false,
  onSelected,
  //className = '',
  //targetStore,
  //isLoadingLocation = false,
  isRadioType,
  styleProps,
}) => {
  //const metricSystem = process.env.NEXT_PUBLIC_DISTANCE_MEASUREMENT_UNIT;
  //const [load, setLoad] = useState(true);
  const [storedState, setStoredState] = useEcomStore();

  const { checkIfProductsBelongsToStore } = new MenuService();

  const {
    shortName: id,
    name,
    full_address: address,
    phone,
    _geoloc: { lat: storeLatitude, lng: storeLongitude },
    google_map_url,
    working_hours,
  } = store;

  const workingDay = useMemo(() => {
    const indexDay = new Date().getDay();
    return working_hours[indexDay];
  }, [working_hours]);

  const isStoreOpen = useMemo(() => {
    const { period } = workingDay;
    const { from, to } = period;
    return checkIfStoreIsOpen(from, to);
  }, [workingDay]);

  const closingTime = useMemo(() => {
    const { period } = workingDay;
    const { to } = period;
    if (!to) {
      return null;
    }
    const time = transformMilitaryTime(to);

    return time;
  }, [workingDay]);

  const openingTime = useMemo(() => {
    const { period } = workingDay;
    const { from } = period;
    if (!from) {
      return null;
    }
    const time = transformMilitaryTime(from);

    return time;
  }, [workingDay]);

  const showStoreValidator = () => {
    setStoredState({
      next_store_id: id,
    });
  };

  const changeStore = () => {
    setStoredState({
      treez_store_name: id,
    });
  };

  const handleClick = e => {
    e.stopPropagation();
  };

  /*  const handleCardStoreSelected = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    await handleStoreSelected();
  }; */

  const handleStoreSelected = async () => {
    if (isRadioType) {
      onSelected(id);
      return;
    }

    if (!storedState.line_items.length) {
      changeStore();
      onSelected();
      return;
    }

    const { hasOutsideProducts } = await checkIfProductsBelongsToStore(
      storedState.line_items,
      id,
    );

    if (hasOutsideProducts) {
      showStoreValidator();
    } else {
      changeStore();
    }

    onSelected();
  };

  const loaderImage = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  if (isRadioType) {
    return (
      <StoreRadioItem
        isOpen={isStoreOpen}
        isSelected={isActive}
        onSelected={handleStoreSelected}
        store={store}
      />
    );
  }

  return (
    <div className={classNames(styles.store__item)} key={store.url_slug}>
      <div className={styles.store__body}>
        <div className={styles.store__text}>
          {name && (
            <h2
              dangerouslySetInnerHTML={{
                __html: name,
              }}
              style={{
                color: styleProps?.headingColor!,
              }}
            />
          )}
          {phone && (
            <a
              className={styles.store__phone}
              href={`tel: ${phone}`}
              onClick={handleClick}
            >
              {getFormatedPhoneNumber(phone)}
            </a>
          )}
          {address && (
            <a
              // eslint-disable-next-line max-len
              className={styles.store__addres}
              href={
                google_map_url ??
                `https://maps.google.com/?q=${storeLatitude},${storeLongitude}`
              }
              onClick={handleClick}
              rel="noreferrer"
              target={'_blank'}
            >
              {address}
            </a>
          )}
          <div className={styles.store__info}>
            <div className={styles.store__status}>
              <Badge
                className={classNames(styles.store__status_badge, {
                  [styles.store__status_badge_closed]: !isStoreOpen,
                })}
              >
                {isStoreOpen ? 'OPEN' : 'CLOSED'}
              </Badge>
              <span
                className={styles.store__status_message}
                style={{
                  color: styleProps?.textColor!,
                }}
              >
                {isStoreOpen
                  ? `${
                      closingTime ? `Closes at ${closingTime}` : 'Not Specified'
                    }`
                  : `${
                      openingTime ? `Opens at ${openingTime}` : 'Not Specified'
                    }`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Link href={createLocationUrl(store.url_slug)}>
        <figure
          className={classNames(styles.store__image, {
            [styles.placeholder]: !store?.asset,
          })}
        >
          {store?.asset && store?.asset && (
            <Image
              alt={store?.name as string}
              blurDataURL={
                // eslint-disable-next-line max-len
                "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 1 1'%3E%3Crect fill='#dedede' /%3E%3C/svg%3E\")"
              }
              fallback="/images/product-placeholder.svg"
              fill
              id={store?.id as string}
              loader={loaderImage}
              /*   onLoad={() => {
                setLoad(false);
              }} */
              placeholder="blur"
              priority
              quality={100}
              src={store?.asset}
              style={{ objectFit: 'cover' }}
            />
          )}
          {!store?.asset && (
            <div>
              <IconSvg name="image" />
              <p>
                <b>There is no image</b>
                <br />
                Kindly establish an image
              </p>
            </div>
          )}
        </figure>
        <span className={styles.overlay__container}>
          <span
            className={classNames(styles.overlay, styles.overlay_effect_shadow)}
          />
        </span>
        {/* <button
            className={styles.store__btn_select}
            onClick={handleCardStoreSelected}
          >
            <IconSvg name={isActive ? 'shopping-select' : 'shopping'} />
          </button> */}
      </Link>
    </div>
  );
};

{
  /* <div
        className={classNames(
          className,
          styles['store-list__item'],
          {
            [styles['is-target']]: targetStore?.getTitle() === name,
          },
          {
            [styles['is-active']]: isActive,
          },
          {
            [styles['store-list__item__without_bordered']]: !withBorders,
          },
        )}
        // eslint-disable-next-line max-len
        data-jane-store-id={store.objectID} //
        id={name}
        key={id}
      >
        {isActive && (
          <p
            className={classNames(styles['store-item__current'], {
              [styles['with-border']]: withBorders,
            })}
          >
            Current store
          </p>
        )}
        <div
          className={classNames(styles['store-item__content'], {
            [styles['with-border']]: withBorders,
          })}
        >
          <Link
            className={styles['store-item__content-name']}
            href={createLocationUrl(store.url_slug)}
          >
            {name}
          </Link>
          {isLoadingLocation && (
            <p className={styles['store-item__content-miles']}>
              {'Unknown distance (loading distance)'}
            </p>
          )}
          {!isLoadingLocation && (
            <p className={styles['store-item__content-miles']}>
              {distanceInMiles
                ? `${distanceInMiles} ${metricSystem} away`
                : 'Unknown distance (allow location)'}
            </p>
          )}
          <a
            className={styles['store-item__content-address']}
            // eslint-disable-next-line max-len
            href={`https://maps.google.com/?q=${storeLatitude},${storeLongitude}`}
            rel="noreferrer"
            target={'_blank'}
          >
            {address}
          </a>
          <a
            className={styles['store-item__content-phone']}
            href={`tel: ${phone}`}
          >
            {getFormatedPhoneNumber(phone)}
          </a>
          <div className={styles['store-item__content-opening']}>
            <span
              className={classNames(styles.store__status, {
                [styles.closed]: !isStoreOpen,
              })}
            >
              {isStoreOpen ? 'Open now' : 'Closed'}
            </span>{' '}
            <span className={styles.divider}></span>
            <span className={styles['store__closing-time']}>
              {isStoreOpen
                ? `${
                    closingTime ? `Closes at ${closingTime}` : 'Not Specified'
                  }`
                : `${
                    openingTime ? `Opens at ${openingTime}` : 'Not Specified'
                  }`}
            </span>
          </div>
          <Link
            className={styles['store-item__content-details']}
            href={createLocationUrl(store.url_slug)}
          >
            Show more details
          </Link>
          {!isActive && (
            <Button
              className={styles['store-item__content-btn']}
              color={'secondary'}
              onClick={handleStoreSelected}
            >
              make this your store
            </Button>
          )}
        </div>
      </div> */
}

interface TreezStoreProps {
  store: TreezStoreType;
  isActive: boolean;
  distanceInMiles: string;
  withBorders: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelected: (id?: string) => void;
  className?: string;
  isLoadingLocation: boolean;
  // eslint-disable-next-line no-undef
  targetStore?: google.maps.Marker;
  isRadioType?: boolean;
  styleProps?: IStoreLocatorPageStyle;
}

export { TreezStore };
