import { useState } from 'react';
import { useEcomStore } from '../data';
import { Address, Button, Icon, Input, Link } from '../components';
import PopoverPanel from './PopoverPanel';

function Selector({ storeAddress }) {
  const [isMethodsOpen, setIsMethodsOpen] = useState(false);
  const [storedState, setStoredState] = useEcomStore();

  const isDelivery = !!storedState.delivery_details?.delivery;
  const id = 'selector-address';
  const address = storedState.delivery_address;

  const formatAddress = address => {
    return address.address1
      ? `${address.address1}${
          address.address2 ? ` ${address.address2}` : ''
        }, ${address.city}, ${address.province} ${address.zip}, ${
          address.country_code
        }`
      : '';
  };
  // const methodOptions = [
  //     {
  //         label: "Delivery",
  //         value: "delivery",
  //     },
  //     {
  //         label: "Pickup",
  //         value: "pickup",
  //     },
  // ];

  const handleChangeAddress = address => {
    const exist = storedState.addresses.some(current => {
      return (
        current.latitude === address.latitude &&
        current.longitude === address.longitude
      );
    });

    setStoredState({
      addresses: exist
        ? storedState.addresses
        : [address, ...storedState.addresses],
      delivery_address: address,
    });
  };

  const handleChangeMethod = () => {
    setIsMethodsOpen(false);

    if (isDelivery) {
      setStoredState({
        delivery_address: {
          address1: storeAddress.address1,
          address2: storeAddress.address2,
          city: storeAddress.city,
          city_code: storeAddress.city_code,
          country: storeAddress.country,
          country_code: storeAddress.country_code,
          latitude: storeAddress.latitude,
          longitude: storeAddress.longitude,
          province: storeAddress.province,
          province_code: storeAddress.province_code,
          zip: storeAddress.zip,
        },
        delivery_details: {
          delivery: false,
          pickup: true,
        },
      });
    } else {
      setStoredState({
        delivery_address: storedState.addresses[0]
          ? { ...storedState.addresses[0] }
          : {
              address1: '',
              address2: '',
              city: '',
              city_code: '',
              country: '',
              country_code: '',
              latitude: 0,
              longitude: 0,
              province: '',
              province_code: '',
              zip: '',
            },
        delivery_details: {
          delivery: true,
          pickup: false,
        },
      });
    }
  };

  return (
    <div className="selector">
      <div className="selector__methods">
        <Button onClick={() => setIsMethodsOpen(!isMethodsOpen)}>
          {isDelivery ? 'Delivery' : 'Pickup'} <Icon name="angle-down" />
        </Button>
        <PopoverPanel
          className="selector__method"
          isOpen={isMethodsOpen}
          toggle={() => setIsMethodsOpen(!isMethodsOpen)}
        >
          <button
            onClick={() => {
              handleChangeMethod();
            }}
            type="button"
          >
            {isDelivery ? 'Pickup' : 'Delivery'}
          </button>
        </PopoverPanel>
      </div>
      <span className="selector__location">
        <Icon name="location" />
      </span>
      {!isDelivery && storeAddress ? (
        <Input defaultValue={formatAddress(storeAddress)} readOnly>
          Store address
        </Input>
      ) : (
        // <input

        //     className="selector__pickup-address"
        //     defaultValue={formatAddress(storeAddress)}
        // />
        <Address
          country={['us']}
          defaultValue={formatAddress(address)}
          id={id}
          label="Enter an address"
          onAddressChange={address => {
            handleChangeAddress(address);
          }}
        />
      )}
      <Link className="button button_color-primary" href="/">
        Shop Now
      </Link>
    </div>
  );
}

export default Selector;
