'use client';

import { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import {
  Alert,
  Button,
  Close,
  Heading,
  IconSvg,
  Modal,
  useData,
} from '@/components';
import { useIsServerSide, useIsTimeWithinTimeInterval } from '@/hooks';
import { useEcomStoreSelector } from '@/data';

import styles from './expressshippingbanner.module.scss';
import { getDeliveryScheduleByZip } from '@/utils';
import { OutsideProducts } from '../StoreValidator/StoreValidator.components';

const ExpressShippingBanner: FC = () => {
  // this is a gap commerce store config
  const { store } = useData();
  const [showValidatorModal, setShowValidatorModal] = useState(false);
  const { isServerSide } = useIsServerSide();

  const {
    provider_inventory_location_id,
    line_items,
    delivery_details,
    delivery_address,
    setState: setEcomStore,
  } = useEcomStoreSelector([
    'provider_inventory_location_id',
    'next_store_id',
    'delivery_details',
    'delivery_address',
    'line_items',
  ]);

  // TODO: add an id to the delivery schedule to make it
  // easier to find the correct one for the zip
  const ds = getDeliveryScheduleByZip(delivery_address?.zip!, store);

  const ec = ds?.express_menu_config;

  const isActiveByDefault = ec?.active_by_default;

  // if express config is undefined, it will return false by default
  const isInTimeSpan = useIsTimeWithinTimeInterval({
    start: ec?.start!,
    end: ec?.end!,
  });

  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const showBanner = !!delivery_details.delivery && isInTimeSpan;

    setIsBannerVisible(showBanner);

    if (showBanner) {
      if (isActiveByDefault) {
        setEcomStore({
          provider_inventory_location_id: ec?.inventory_provider_ids,
          line_items: [],
        });
      }
    } else {
      setEcomStore({ provider_inventory_location_id: null });
    }
  }, [delivery_details.delivery, isInTimeSpan]);

  const isExpressMode = useMemo(() => {
    if (isServerSide) {
      return false;
    }

    return !!provider_inventory_location_id;
  }, [isServerSide, provider_inventory_location_id]);

  const displayExpressNote = useMemo(() => {
    if ((isServerSide || isExpressMode) && !isActiveByDefault) {
      return 'YOU ARE BUYING FROM OUR EXPRESS MENU: ';
    }

    const note = ec?.note ?? 'GET YOUR ORDER IN 60 MIN ETA: ';

    return note;
  }, [isServerSide, isExpressMode]);

  const onClose = () => {
    setIsBannerVisible(false);
  };

  const onChangeMode = () => {
    setShowValidatorModal(false);

    if (provider_inventory_location_id) {
      setEcomStore({
        provider_inventory_location_id: null,
        line_items: [], // remove all products from cart
      });

      return;
    }

    setEcomStore({
      provider_inventory_location_id: ec?.inventory_provider_ids,
      line_items: [],
    });
  };

  const toggleExpressMode = async () => {
    if (line_items.length > 0) {
      setShowValidatorModal(true);
      return;
    }

    onChangeMode();
  };

  return (
    <>
      <aside
        className={classNames(styles.banner, {
          [styles.show]: isBannerVisible,
          [styles.express_mode]: isExpressMode,
        })}
      >
        <figure>
          <IconSvg name="truck" />
        </figure>
        <span className={styles.message}>
          {displayExpressNote}
          {!isActiveByDefault && (
            <a onClick={toggleExpressMode} role="button">
              {isExpressMode
                ? 'CLICK HERE TO SEE OUR FULL STOCK INSTEAD'
                : 'CLICK TO SEE THE EXPRESS DELIVERY MENU'}
            </a>
          )}
        </span>
        <Close className={styles.close} onClick={onClose} />
      </aside>
      <Modal className={styles.modal} isOpen={showValidatorModal} md>
        <div className={styles.store_validator}>
          <div className={styles.store_validator__header}>
            <Heading className={styles.header_title} level={2}>
              Do you want to change express menu?
            </Heading>
            <Close
              onClick={() => {
                setShowValidatorModal(!showValidatorModal);
              }}
            />
          </div>
          <Alert
            className={styles.warning_message}
            isOpen={true}
            warning={true}
          >
            <p>
              {`Be careful! You are about to change the shipping method. Some 
                of your items will be removed from your shopping cart`}
            </p>
          </Alert>
          <OutsideProducts products={line_items} />
          <div className={styles.store_validator__footer}>
            <Button color="primary" onClick={onChangeMode}>
              {`Shop from ${isExpressMode ? 'full inventory' : 'express menu'}`}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setShowValidatorModal(false);
              }}
            >
              {"Keep item(s) & Don't change mode"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExpressShippingBanner;
