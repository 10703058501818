import React from 'react';

import type { LocalBusinessJsonLdProps } from '@/types';

import { JsonLd } from './jsonld';
import { setAddress } from '../schemas/setAddress';
import { setReviews } from '../schemas/setReviews';
import { setGeo } from '../schemas/setGeo';
import { setAggregateRating } from '../schemas/setAggregateRating';
import { setAction } from '../schemas/setAction';
import { setGeoCircle } from '../schemas/setGeoCircle';
import { setOffer } from '../schemas/setOffer';
import { setOpeningHours } from '../schemas/setOpeningHours';

function LocalBusinessJsonLd({
  type = 'LocalBusiness',
  keyOverride,
  address,
  geo,
  rating,
  review,
  action,
  areaServed,
  makesOffer,
  openingHours,
  images,
  ...rest
}: LocalBusinessJsonLdProps) {
  const data = {
    ...rest,
    address: setAddress(address),
    aggregateRating: setAggregateRating(rating),
    areaServed: areaServed && areaServed.map(setGeoCircle),
    geo: setGeo(geo),
    image: images,
    makesOffer: makesOffer?.map(setOffer),
    openingHoursSpecification: Array.isArray(openingHours)
      ? openingHours.map(setOpeningHours)
      : setOpeningHours(openingHours),
    potentialAction: setAction(action),
    review: setReviews(review),
  };

  return (
    <JsonLd
      keyOverride={keyOverride}
      type={type}
      {...data}
      scriptKey="LocalBusiness"
    />
  );
}

export default LocalBusinessJsonLd;
