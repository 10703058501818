import { StatsSlice } from 'prismicio-types';
import { FC } from 'react';

import DefaultVariation from './DefaultVariation';
import PieChartVariation from './PieChartVariation';
import ChronologyVariation from './ChronologyVariation';
import WithBordersAndSeparatorsVariation from './WithBordersAndSeparatorsVariation';
import WithIconsAndDescriptionsVariation from './WithIconsAndDescriptionsVariation';
import WithImagesAndDescriptionsVariation from './WithImagesAndDescriptionsVariation';

export const Stats: FC<StatsSlice> = slice => {
  if (!slice?.primary?.active) {
    return null;
  }

  if (slice.variation === 'asPieChart') {
    return <PieChartVariation slice={slice} />;
  }

  if (slice.variation === 'asChronology') {
    return <ChronologyVariation slice={slice} />;
  }

  if (slice.variation === 'withIconsAndDescriptions') {
    return <WithIconsAndDescriptionsVariation slice={slice} />;
  }

  if (slice.variation === 'withImagesAndDescriptions') {
    return <WithImagesAndDescriptionsVariation slice={slice} />;
  }

  if (slice.variation === 'withBordersAndSeparators') {
    return <WithBordersAndSeparatorsVariation slice={slice} />;
  }

  return <DefaultVariation slice={slice} />;
};
