import { useMemo } from 'react';
import classNames from 'classnames';
import { useFeaturedFlag } from '@/hooks';
import { JaneSpecial } from '@/types';
import { Badge, Icon, Placeholder } from '@/components';
import { Accordion } from '../Accordion';
import { useSpecialsContext } from '@/context';

import styles from './DiscountsSection.module.scss';
import _ from 'lodash';

const DiscountsSection = () => {
  const { specials, isLoading } = useSpecialsContext();

  const discounts = useMemo(
    () => filterByType(specials, ['qualified_group', 'bundle']),
    [specials],
  );

  const { isDiscountsList } = useFeaturedFlag();

  const discountTypes = Object.keys(discounts);

  if (!isLoading && (!isDiscountsList || !discountTypes.length)) {
    return null;
  }

  return (
    <div className={classNames(styles.discounts)}>
      <ul className={styles.discounts__container}>
        {isLoading && (
          <div className={styles.discount__content_paceholder}>
            {new Array(2).fill(null).map((_, index) => (
              <Placeholder
                key={index}
                style={{
                  height: '20px',
                  marginBottom: '0.5rem',
                  width: '100%',
                }}
              />
            ))}{' '}
          </div>
        )}

        {!isLoading &&
          discountTypes?.map((key, index) => {
            return (
              <Accordion
                className={styles.discounts__type}
                isExpanded={index === 0}
                key={index}
                label={
                  <label className={styles.discounts__title}>
                    <span>{`${key === 'bundle' ? 'Bundle' : 'Veteran'}`}</span>
                    <Badge className={styles.discount_header__badge}>
                      <Icon name="offer" />
                      <span>Discounts</span>
                    </Badge>
                  </label>
                }
              >
                {discounts[key].map(({ title, description }, i) => {
                  return (
                    <div className={styles.discounts__item} key={i}>
                      <Icon name="offer" /> {description ? description : title}
                    </div>
                  );
                })}
              </Accordion>
            );
          })}
      </ul>
    </div>
  );
};

const filterByType = (specials: JaneSpecial[], types: string[]) => {
  const discounts = specials?.filter(({ special_type }) =>
    types.includes(special_type as string),
  );

  return _.groupBy(discounts, 'special_type');
};

export { DiscountsSection };
