import Image from 'next/image';
import classNames from 'classnames';

import { Rating } from '../rating';

function Review({
  children,
  rating,
  avatar,
  name,
  address,
  className,
  ...props
}) {
  return (
    <blockquote className={classNames('review', className)} {...props}>
      <Image
        alt={name}
        src={avatar}
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
      <p className="review__text">{children}</p>
      <Rating value={rating} />
      <cite>
        <span className="review__cite_name">{name}</span>: {address}
      </cite>
    </blockquote>
  );
}

export default Review;
