import { useCallback, useEffect } from 'react';

const useOutsideClick = (ref, callback, exceptionsRef) => {
  const handleOutsideClick = useCallback(
    event => {
      if (
        exceptionsRef?.some(exception =>
          exception?.current?.contains(event.target),
        )
      ) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick, ref]);
};

export { useOutsideClick };
