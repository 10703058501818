import { FC } from 'react';
import { BrandsSlice } from '@/types';
import { BrandsCarousel, BrandsGrid } from './Brands.components';

const Brands: FC<BrandsSlice> = slice => {
  if (!slice?.primary?.active) {
    return null;
  }

  if (slice?.variation === 'grid') {
    return <BrandsGrid {...slice} />;
  }
  return <BrandsCarousel {...slice} />;
};

export default Brands;
