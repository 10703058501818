'use client';

import { FC, useRef, useState } from 'react';
import classNames from 'classnames';
import { useGetAccountPhoto, useUploadAccountPhoto } from '@/data';
import { Icon, IconSvg, Image, Spinner } from '@/components';
import style from './accountphoto.module.scss';
import {
  FILE_SIZE_ERROR_THRESHOLD_VALUE,
  RESIZE_THRESHOLD,
  UPLOAD_ERROR_INVALID_FORMAT,
  UPLOAD_ERROR_MAX_SIZE_EXCEEDED,
  UPLOAD_ERROR_MESSAGE,
} from './constants';

const AccountPhoto: FC<IAccountPhotoProps> = ({
  value,
  loading,
  onChange,
  title,
  alt,
  field,
  rounded,
}) => {
  const [isLoading, setIsLoading] = useState(loading);
  const photo = useRef<File | null>(null);

  const { data } = useGetAccountPhoto({
    skip: !value,
    variables: {
      filename: value,
    },
  });

  const { uploadAccountPhoto } = useUploadAccountPhoto({
    onCompleted: res => {
      import('axios').then(mod => {
        const axios = mod.default;
        const { file_name, url, headers } = res.uploadAccountPhoto;

        axios
          .put(url, photo?.current, {
            headers: {
              'Content-Type': (photo?.current as any)?.type,
              ...headers.reduce(
                (prev, { key, value }) => ({
                  ...prev,
                  [key]: value,
                }),
                {},
              ),
            },
          })
          .then(uploadResponse => {
            if (uploadResponse.status !== 200) {
              onChange(field, null, UPLOAD_ERROR_MESSAGE);
              return;
            }

            onChange(field, file_name, null);
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
            onChange(field, null, UPLOAD_ERROR_MESSAGE);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    },
    onError: e => {
      // eslint-disable-next-line no-console
      console.error(e);
      onChange(field, null, UPLOAD_ERROR_MESSAGE);
      setIsLoading(false);
    },
  });

  const handleFileUpload = e => {
    const file = (e.target.files ?? [])[0];

    if (file) {
      const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (!validFileTypes.includes(file.type)) {
        onChange(field, null, UPLOAD_ERROR_INVALID_FORMAT);
        e.target.value = null;
        return;
      }

      const uploadFile = (file: File) => {
        photo.current = file;

        setIsLoading(true);
        uploadAccountPhoto({
          variables: {
            input: {
              file_name: file.name.replace(/\s+/g, '-'),
              file_type: file.type,
            },
          },
        });
      };

      if (file.size > FILE_SIZE_ERROR_THRESHOLD_VALUE) {
        setIsLoading(false);
        onChange(field, null, UPLOAD_ERROR_MAX_SIZE_EXCEEDED);
        e.target.value = null;
        return;
      }

      // If file size exceeds max limit, resize it
      if (file.size > RESIZE_THRESHOLD) {
        resizeImageFile(file, resizedFile => {
          if (resizedFile) {
            uploadFile(resizedFile);
          } else {
            setIsLoading(false);
            onChange(field, null, UPLOAD_ERROR_MAX_SIZE_EXCEEDED);
            e.target.value = null;
            return;
          }
        });
      } else {
        uploadFile(file);
      }
    }
  };

  const resizeImageFile = (
    file: File,
    callback: (_resizedFile: File | null) => void,
  ) => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(file);

    setIsLoading(true);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        // eslint-disable-next-line no-console
        console.error('Failed to get canvas context');
        callback(null);
        return;
      }

      let width = img.width;
      let height = img.height;

      // Define a scaling factor and initial quality
      let quality = 0.8;
      let blob;

      const compressAndResize = () => {
        // Calculate the scaling factor
        const scaleFactor = Math.sqrt(RESIZE_THRESHOLD / file.size) * 0.9;
        width = Math.round(width * scaleFactor);
        height = Math.round(height * scaleFactor);

        // Resize the canvas and draw the image
        canvas.width = width;
        canvas.height = height;
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas to dataURL with current quality
        const dataUrl = canvas.toDataURL(file.type, quality);

        // Convert dataURL to Blob
        const byteString = atob(dataUrl.split(',')[1]);
        const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new Uint8Array(byteString.length);

        for (let i = 0; i < byteString.length; i++) {
          arrayBuffer[i] = byteString.charCodeAt(i);
        }

        blob = new Blob([arrayBuffer], { type: mimeString });

        // Check the blob size
        if (blob.size <= RESIZE_THRESHOLD || quality <= 0.4) {
          // If size is acceptable or quality is too low, return the result
          const resizedFile = new File([blob], file.name, { type: file.type });
          callback(resizedFile);
        } else {
          // Reduce quality and try again
          quality -= 0.1;
          compressAndResize();
        }
      };

      compressAndResize();
    };

    img.onerror = () => {
      // eslint-disable-next-line no-console
      console.error('Failed to load image');
      callback(null);
    };
  };

  const showImage = !isLoading && data;

  return (
    <div className={style.account__photo}>
      <p
        className={style.account__photo_title}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <div className={style.account__photo_container}>
        {isLoading && (
          <Spinner className={style.spinner_photo} sm>
            Uploading
          </Spinner>
        )}
        {showImage && (
          <figure
            className={classNames(style.account__documment_img, {
              [style.account__documment_img_perfil]: rounded,
            })}
          >
            <Image
              alt={alt ?? ''}
              fill
              sizes="(min-width: 576px) 176px, 50wv"
              src={data}
              style={{ objectFit: rounded ? 'cover' : 'contain' }}
            />
          </figure>
        )}
        <label
          className={classNames(style.account__document_input_upload, {
            [style.account__document_input_upload_perfil]: rounded,
            [style.account__document_input_loading]: isLoading,
            [style.account__document_input_upload_borderless]: showImage,
          })}
        >
          {showImage && (
            <Icon
              className={classNames(style.account__edit_action, {
                [style.account__edit_action_perfil]: rounded,
              })}
              name="edit"
            />
          )}
          {!isLoading && !value && (
            <span className={style.account__document_input_upload_message}>
              <strong>
                <IconSvg name="upload" />
                Upload the document.
                <span>png,jpg</span>
              </strong>
            </span>
          )}
          <input
            accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
            capture="environment"
            disabled={isLoading}
            onChange={handleFileUpload}
            type="file"
          />
        </label>
      </div>
    </div>
  );
};

interface IAccountPhotoProps {
  alt?: string;
  field: string;
  loading?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (field: any, url: any, error: string | null) => void;
  rounded?: boolean;
  title: string;
  value: any;
}

export default AccountPhoto;
