import { FC, useState } from 'react';
import classNames from 'classnames';

import { CartLineItem, ISpecial } from '@/types';
import {
  currencyFormat,
  getCartItemsCount,
  getCartItemsSubtotal,
} from '@/utils';

import { IconSvg } from '../IconSvg';

import styles from './SpecialBundleProgress.module.scss';
import PopoverPanel from '../PopoverPanel';
import { BundleProductsMenu } from './BundleProductsMenu';

interface SpecialBundleProgressProps {
  special: ISpecial;
  bundleProducts: CartLineItem[];
}

const SpecialBundleProgress: FC<SpecialBundleProgressProps> = ({
  special,
  bundleProducts,
}) => {
  const [showBundleProducts, setShowBundleProducts] = useState(false);

  return (
    <div className={styles.special_bundle_progress__container}>
      <SpecialBundleProgressIndicator
        bundleProducts={bundleProducts}
        showBundleMenu={showBundleProducts}
        special={special}
        toggleShowBundleMenu={() => setShowBundleProducts(show => !show)}
      />
      <PopoverPanel
        className={styles.special_bundle__panel}
        isOpen={showBundleProducts}
        toggle={() => setShowBundleProducts(show => !show)}
      >
        <div className={styles.special_bundle__panel__body}>
          <BundleProductsMenu bundleProducts={bundleProducts} />
        </div>
      </PopoverPanel>
    </div>
  );
};

interface SpecialBundleProgressIndicatorProps
  extends SpecialBundleProgressProps {
  showBundleMenu: boolean;
  toggleShowBundleMenu(): void;
}

const SpecialBundleProgressIndicator: FC<SpecialBundleProgressIndicatorProps> =
  ({ special, bundleProducts, showBundleMenu, toggleShowBundleMenu }) => {
    const { progress, max, progressLabel, totalLabel } =
      getSpecialProgressValues(special, bundleProducts);

    return (
      <div
        className={styles.special_bundle_progress}
        onClick={toggleShowBundleMenu}
        role="button"
      >
        <span
          className={classNames(styles.special_bundle_progress__icon, {
            [styles.special_bundle_progress__icon__complete]:
              special.bundleComplete,
          })}
        >
          <IconSvg
            name={special.bundleComplete ? 'gift-box-open' : 'gift-box'}
          />
        </span>
        <div className={styles.special_bundle_progress__content}>
          <p className={styles.special_bundle_progress__title}>
            Track your progress
          </p>
          <progress
            className={classNames(styles.special_bundle_progress__bar, {
              [styles.special_bundle_progress__bar_complete]: progress === max,
            })}
            max={max}
            value={progress}
          />
          <p
            className={styles.special_bundle_progress__description}
          >{`${progressLabel} out of ${totalLabel}`}</p>
        </div>
        <span
          className={classNames(styles.special_bundle__dropdown_icon, {
            [styles.special_bundle__dropdown_icon__open]: showBundleMenu,
          })}
        >
          <IconSvg name={'chevron-right'} />
        </span>
      </div>
    );
  };

const getSpecialProgressValues = (
  special: ISpecial,
  bundleProducts: CartLineItem[],
) => {
  const isUnitCount = special.purchaseRequirement === 'UNIT_COUNT';
  const progressValue = isUnitCount
    ? getCartItemsCount(bundleProducts)
    : getCartItemsSubtotal(bundleProducts);
  const max = (special.buyCount ?? 0) * (isUnitCount ? 1 : 100);
  const progress = Math.min(progressValue, max);
  const progressLabel = isUnitCount ? progress : `$${currencyFormat(progress)}`;
  const totalLabel = isUnitCount ? max : `$${currencyFormat(max!)}`;

  return { progress, max, progressLabel, totalLabel };
};

export { SpecialBundleProgress };
