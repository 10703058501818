import { useQuery } from '@tanstack/react-query';
import {
  SearchProductsRequest,
  useEcomStoreSelector,
  useMenuService,
} from '@/data';
import { GProduct, LastKey, SortBySelectOption } from '@/types';

interface UseGetProductByCategoryProps {
  dispensaryName?: string;

  products?: GProduct[];

  // limit and sort
  sortBy?: SortBySelectOption;
  limit: number;

  // filters
  filterByStore?: boolean;
  filterByCategory?: string;
  filterByBrand?: string;
  filterByGroupId?: string;
  filterByOnSale?: boolean;
}

export const useProductsByCategory = ({
  dispensaryName,
  limit,
  sortBy,
  products,

  filterByStore,
  filterByCategory,
  filterByBrand,
  filterByGroupId,
  filterByOnSale,
}: UseGetProductByCategoryProps) => {
  const { customer_type, provider_inventory_location_id } =
    useEcomStoreSelector(['customer_type', 'provider_inventory_location_id']);

  const queryKey = [
    'productsByCategory',
    dispensaryName,
    customer_type,
    filterByCategory,
    filterByGroupId,
    filterByOnSale,
    provider_inventory_location_id,
  ];

  const { searchProducts, searchProductsByGroupId } = useMenuService();

  const { data, isLoading, error, isError } = useQuery({
    queryKey,
    queryFn: async (): Promise<{
      products: GProduct[];
      lastKey: LastKey;
    }> => {
      const q: SearchProductsRequest = {
        sortBy,
        pageSize: limit,
      };

      if (filterByGroupId) {
        const productGroup = await searchProductsByGroupId({
          id: filterByGroupId,
        });

        // filter by group id
        q.filters = {
          ...q.filters,
          productId: {
            values: productGroup.map((e: any) => e.id),
          },
        };
      }

      // filter by on sale
      if (filterByOnSale) {
        q.displayOnSale = true;
      }

      if (filterByCategory && filterByCategory !== 'default') {
        q.filters = {
          productTypeName: { values: [filterByCategory?.toUpperCase()!] },
        };
      }

      // filter by brand
      if (filterByBrand && filterByBrand !== 'default') {
        q.filters = {
          ...q.filters,
          brand: {
            values: [filterByBrand?.toUpperCase()!],
          },
        };
      }

      return searchProducts(q);
    },

    enabled: filterByStore,
    initialData: {
      products: products ?? [],
      lastKey: { productId: '', customerType: '' },
    },
  });

  return {
    error,
    isError,
    isLoading,
    products: data?.products ?? [],
    total: 0,
  };
};
