import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import { useFilteredSliceItemsByLocationSlug, useScreenWidth } from '@/hooks';
import {
  Container,
  Heading,
  IconSvg,
  Link,
  Placeholder,
  Slider,
} from '@/components';
import { BrandsSliceDefault, BrandsSliceGrid } from '@/types';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';
import * as prismicH from '@prismicio/helpers';
import classNames from 'classnames';
import styles from './brands.module.scss';

const BrandsCarousel: FC<BrandsSliceDefault> = ({ primary, items }) => {
  const { width } = useScreenWidth();
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(items);
  const [isLoading, setIsLoading] = useState(true);
  const {
    spacing_between,
    spacing_between_mobile,
    brands_header,
    header_color,
    brand_name_color,
    image_size,
    image_size_mobile,
    link_text_see_all,
    link_text_color_see_all,
    bottom_spacing,
    background_color,
    border_radius,
    round_image,
    padding_section = 'large',
    full_width,
  } = primary;
  const borderRadius = round_image
    ? '100%'
    : border_radius
    ? `${border_radius}px`
    : '0';
  const paddingTop = !!background_color;
  const background = background_color ?? undefined;
  const backgroundImage = primary?.background_image;
  const backgroundStyleFixed = primary?.background_style_fixed_cover;
  const minHeight = primary?.min_heigth;

  useEffect(() => {
    if (filteredItems?.length) {
      setIsLoading(false);
    }
  }, [filteredItems]);

  const getImageSize = (width: number) => {
    if (width < 1024) {
      return image_size_mobile ? `${image_size_mobile}px` : undefined;
    }

    return image_size ? `${image_size}px` : undefined;
  };

  const getSpaceBetween = width => {
    if (width < 1024) {
      return spacing_between_mobile;
    }

    return spacing_between;
  };

  return (
    <section
      className={classNames(getSlicePadding(padding_section), {
        [styles.spacing_bottom]: bottom_spacing,
        [styles.padding_background]: paddingTop && background,
        ['background__fixed_cover']: backgroundStyleFixed,
        ['background__contained']: !backgroundStyleFixed,
      })}
      style={{
        background: background_color!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Slider
        activatedHover={false}
        className={styles.brands_carousel}
        isNotPaddingMobile
        seeAllElement={
          <Link
            className={styles['brands__see-all']}
            href="/brands"
            style={{ color: link_text_color_see_all ?? '' }}
          >
            {link_text_see_all || 'SHOP ALL'}
          </Link>
        }
        showSlidesOutsideContainer={full_width}
        sliderHeading={
          <Heading level={2} style={{ color: header_color ?? '' }}>
            {prismicH.asText(brands_header)}
          </Heading>
        }
        spaceBetween={getSpaceBetween(width) || 24}
      >
        {isLoading || !filteredItems?.length
          ? Array.from({ length: 30 }).map((_, index) => (
              <div
                className={styles.brands_carousel__placeholder_brand}
                key={index}
              >
                <Placeholder
                  className={styles.brands_carousel__placeholder_image}
                />
                <Placeholder
                  className={styles.brands_carousel__placeholder_title}
                />
              </div>
            ))
          : filteredItems.map(brand => (
              <Link
                className={styles.slide__main}
                href={brand?.link ?? ''}
                key={brand.brand_name}
                style={{ minWidth: getImageSize(width) }}
              >
                {brand.brand_name && (
                  <Heading level={3} style={{ color: brand_name_color ?? '' }}>
                    {brand.brand_name}
                  </Heading>
                )}
                <figure
                  className={classNames(styles.brand_image, {
                    [styles.brand_image_placeholder]: !brand?.brand_image?.url,
                  })}
                  style={{ borderRadius, maxWidth: getImageSize(width) }}
                >
                  {brand?.brand_image?.url ? (
                    <PrismicNextImage
                      fallbackAlt=""
                      field={brand.brand_image}
                      sizes="(min-width: 1200px) 158px, 
                                        (min-width: 992px) 170px, 
                                        (min-width: 768px) 157px, 
                                        (min-width: 576px) 156px, 50vw"
                      style={{
                        borderRadius,
                        objectFit: 'cover',
                      }}
                      title={brand?.brand_name ?? ''}
                    />
                  ) : (
                    <IconSvg name="image" />
                  )}
                </figure>
              </Link>
            ))}
      </Slider>
    </section>
  );
};

const BrandsGrid: FC<BrandsSliceGrid> = ({ primary, items }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(items);
  const [hovered, setHovered] = useState<boolean>();
  const router = useRouter();

  const {
    brands_header,
    bottom_spacing,
    background_color,
    border_radius,
    round_image,
    header_color,
    brand_name_color,
    cta_text_color,
    cta_text_color_hover,
    cta_background_color,
    cta_background_color_hover,
    cta_border,
    cta_border_hover,
    hide_cta,
  } = primary;
  const borderRadius = round_image
    ? '100%'
    : border_radius
    ? `${border_radius}px`
    : '0px';
  const paddingSection = primary?.padding_section;
  const paddingTop = !!background_color;
  const background = background_color ?? undefined;
  const backgroundImage = primary?.background_image?.url;
  const backgroundStyleFixed = primary?.background_style_fixed_cover;
  const minHeight = primary?.min_heigth;

  const handleClick = (e, link) => {
    if (!hide_cta) {
      return;
    }
    e.preventDefault();
    router.push(link);
  };

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        [styles.spacing_bottom]: bottom_spacing,
        [styles.padding_background]: paddingTop && background,
        ['background__fixed_cover']: backgroundStyleFixed,
        ['background__contained']: !backgroundStyleFixed,
      })}
      style={{
        background: background_color!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={styles.brands_grid}>
        <PrismicRichText
          components={htmlSerializerWithProps({ color: header_color })}
          field={brands_header}
        />
        <div className={styles.brands_grid__container}>
          {filteredItems.map(brand => {
            return (
              <div
                className={styles.brands_grid__container__brands}
                key={brand.brand_name}
                onClick={e => {
                  handleClick(e, brand?.cta_link ?? '/');
                }}
                role="button"
              >
                {brand?.brand_name && (
                  <p style={{ color: brand_name_color ?? '' }}>
                    {brand.brand_name}
                  </p>
                )}
                <figure
                  className={classNames(styles.image, {
                    [styles.image_placeholder]: !brand?.brand_image?.url,
                  })}
                  style={{ borderRadius }}
                >
                  {brand?.brand_image?.url ? (
                    <PrismicNextImage
                      fallbackAlt=""
                      field={brand.brand_image}
                      fill
                      sizes="(min-width: 1200px) 158px, 
                                        (min-width: 992px) 170px, 
                                        (min-width: 768px) 157px, 
                                        (min-width: 576px) 156px, 50vw"
                      style={{ borderRadius, objectFit: 'cover' }}
                      title={brand?.brand_name ?? ''}
                    />
                  ) : (
                    <IconSvg name="image" />
                  )}
                </figure>
                {!hide_cta && (
                  <Link
                    href={brand?.cta_link ?? ''}
                    onMouseEnter={() => {
                      setHovered(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(false);
                    }}
                    style={{
                      backgroundColor: (hovered
                        ? cta_background_color_hover
                        : cta_background_color) as string,
                      color: (hovered
                        ? cta_text_color_hover
                        : cta_text_color) as string,
                      border: (hovered
                        ? cta_border_hover
                        : cta_border) as string,
                    }}
                  >
                    {brand?.cta_text ?? 'Shop Now'}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export { BrandsCarousel, BrandsGrid };
