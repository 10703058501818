import { FC, useEffect, useState } from 'react';

import { useWishList } from '@/hooks';
import { IconSvg, Link } from '@/components';

import style from './favoriteheader.module.scss';

interface FavoriteHeaderProps {
  showLabel?: boolean;
  label?: string;
}

const FavoriteHeader: FC<FavoriteHeaderProps> = ({
  label = 'Wishlist',
  showLabel,
}) => {
  const [favLength, setFavLength] = useState(0);
  const { favProducts } = useWishList();

  useEffect(() => {
    setFavLength(favProducts.length);
  }, [favProducts]);

  return (
    <Link className={style.fav__actions} href={'/wishlist'}>
      <IconSvg name={'favorite'} />
      {favLength > 0 && <span className={style.counter}>{favLength}</span>}
      {showLabel && <span className={style.fav__actions_label}>{label}</span>}
    </Link>
  );
};
export default FavoriteHeader;
