import { ReactNode } from 'react';
import { Slide, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type TNotifyType = 'success' | 'warn' | 'error' | 'default';
const useNotify = () => {
  const notify = (type: TNotifyType, message: string | ReactNode) => {
    const toastStyle: ToastOptions = {
      autoClose: 3000,
      closeOnClick: true,
      draggable: true,
      hideProgressBar: true,
      pauseOnHover: true,
      position: 'bottom-center',
      progress: undefined,
      theme: 'colored',
      transition: Slide,
    };
    switch (type) {
      case 'success':
        toast.success(message, toastStyle);
        break;
      case 'warn':
        toast.warn(message, toastStyle);
        break;
      case 'error':
        toast.error(message, toastStyle);
        break;
      case 'default':
        toast(message, toastStyle);
        break;
    }
  };
  return { notify };
};

export default useNotify;
