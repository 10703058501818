import { FC } from 'react';

import classNames from 'classnames';

import { Heading, Icon } from '@/components';
import {
  getActivityIconName,
  getFeelingIconName,
  getReviewsActivitiesCount,
  getReviewsFeelingsCount,
} from '@/utils';
import { CustomerReview } from '@/types';

import { useReviewsFilters } from './ReviewsFilterProvider';

interface IProductReviewsFiltersProps {
  reviews: CustomerReview[];
}

const ProductReviewsFilters: FC<IProductReviewsFiltersProps> = ({
  reviews,
}) => {
  const reviewsFilters = useReviewsFilters();

  return (
    <div className="rating_reviews__filters_container">
      <div className="rating_reviews__filters">
        <Heading className="filter_header" level={3}>
          Top feelings mentioned
        </Heading>
        <ul className="filter_list">
          {getReviewsFeelingsCount(reviews).map(([feeling, count], index) => (
            <li
              className={classNames('filter_list__item', {
                active: reviewsFilters?.feelingsFilter.includes(feeling),
              })}
              key={index}
              onClick={() =>
                reviewsFilters?.handleFeelingsFilterChange(feeling)
              }
            >
              <span>{`${feeling} (${count})`}</span>
              <Icon className="item_icon" name={getFeelingIconName(feeling)} />
            </li>
          ))}
        </ul>
      </div>
      <div className="rating_reviews__filters">
        <Heading className="filter_header" level={3}>
          Top activities mentioned
        </Heading>
        <ul className="filter_list">
          {getReviewsActivitiesCount(reviews).map(
            ([activity, count], index) => (
              <li
                className={classNames('filter_list__item', {
                  active: reviewsFilters?.activitiesFilter.includes(activity),
                })}
                key={index}
                onClick={() =>
                  reviewsFilters?.handleActivitiesFilterChange(activity)
                }
              >
                <span>{`${activity} (${count})`}</span>
                <Icon
                  className="item_icon"
                  name={getActivityIconName(activity)}
                />
              </li>
            ),
          )}
        </ul>
      </div>
    </div>
  );
};

export { ProductReviewsFilters };
