import { FC } from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { useRouter } from 'next/navigation';
import { InstagramSlice } from '@/types';
import { Link } from '../atoms/link';
import { Button, Container, IconSvg } from '@/components';
import { Slider } from '../Slider';
import classNames from 'classnames';
import styles from './instagram.module.scss';
import * as prismicH from '@prismicio/helpers';
import { getSlicePadding } from 'utils/utils';
import { useIsMobile } from 'hooks/useIsMobile';
import { asText } from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializerWithProps } from 'utils/serializer';
import { convertHexadecimalFormatToRGB } from '@/utils';

const Instagram: FC<InstagramSlice> = slice => {
  const router = useRouter();
  const { isMobile } = useIsMobile(1024);

  const active = slice?.primary?.active;

  if (!active) {
    return null;
  }

  const background = slice?.primary?.background_color;
  const overlay = convertHexadecimalFormatToRGB(
    slice?.primary?.hover_overlay_color || '#0d0d0d',
  );
  const overlayOpacity = slice?.primary?.hover_overlay_opacity ?? 0.6;
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const fullWidth = slice?.primary?.full_width;
  const profileUser = slice?.primary?.profile_user;
  const profileUserColor = slice?.primary?.profile_user_color;
  const profileImage = slice?.primary?.profile_image;
  const profileImageSize = slice?.primary?.profile_image_size ?? 62;
  const btnType = slice?.primary?.button_follow_type;
  const instagramLink =
    prismicH.asLink(slice?.primary?.button_follow_link) ?? '/';
  const textColor = slice?.primary?.overlay_text_color;
  const instagramSpacing = slice?.primary?.space_between_images ?? 0;
  const imageSize = isMobile
    ? slice?.primary?.width_image_mobile
    : slice?.primary?.width_image;

  const handleLinkInstagram = () => {
    router.push(instagramLink);
    return;
  };

  return (
    <section
      className={classNames(styles.instagram, getSlicePadding(paddingSection), {
        spacing_bottom: marginBottom,
      })}
      style={{
        background: background!,
      }}
    >
      <Container
        className={classNames(styles.instagram__container, {
          [styles.instagram__container_full_width]: fullWidth,
        })}
      >
        <div
          className={classNames(styles.instagram__header, {
            [styles.instagram__header_non_space]: paddingSection !== 'small',
          })}
        >
          {profileImage?.url && (
            <figure>
              <PrismicNextImage
                fallbackAlt=""
                field={profileImage}
                width={profileImageSize}
              />
            </figure>
          )}
          {profileUser && (
            <span
              className={styles.instagram__header_username}
              style={{ color: profileUserColor! }}
            >
              {profileUser}
            </span>
          )}
          <Button
            color={btnType}
            onClick={() => {
              handleLinkInstagram();
            }}
          >
            <IconSvg name="instagram" />
            <span>FOLLOW</span>
          </Button>
        </div>
        <Slider
          className={styles.instagram__body}
          showSliderHeader={false}
          showSlidesOutsideContainer
          slidesPerView={'auto'}
          spaceBetween={instagramSpacing}
        >
          {slice?.items?.map((item, index) => {
            const netLink = prismicH.asLink(item?.link) ?? '/';
            return (
              <>
                <Link
                  className={classNames(
                    'post__image_link',
                    styles.post__image_link,
                  )}
                  href={netLink}
                  key={index}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <figure
                    className={classNames(styles.post__image, 'post__image')}
                    style={{
                      width: `calc(${imageSize}px)`,
                      maxWidth: `calc(${imageSize}px)`,
                    }}
                  >
                    <PrismicNextImage fallbackAlt="" field={item?.image} />
                  </figure>
                  <div
                    className={styles.post__content}
                    style={{
                      background: `rgba(${overlay?.r}, ${overlay?.g}, ${overlay?.b} , ${overlayOpacity})`,
                    }}
                  >
                    {(item?.comments_count || item?.reactions_count) && (
                      <div
                        className={classNames(
                          styles.post__stats,
                          'post__stats',
                        )}
                      >
                        <div className={styles.post__stats_item}>
                          <IconSvg name="favorite" />
                          <span>{item?.reactions_count ?? 0}</span>
                        </div>
                        <div className={styles.post__stats_item}>
                          <IconSvg name="comments" />
                          <span>{item?.comments_count ?? 0}</span>
                        </div>
                      </div>
                    )}
                    {asText(item?.caption) && (
                      <div className={styles.post__text}>
                        <PrismicRichText
                          components={htmlSerializerWithProps({
                            color: textColor,
                          })}
                          field={item?.caption}
                        />
                      </div>
                    )}
                  </div>
                </Link>
                <style>
                  {`
                    .post__stats {
                      &_item {
                        color: ${textColor};

                        svg {
                          * {
                            stroke: ${textColor};
                          }
                        }
                      }
                    }
                  `}
                </style>
              </>
            );
          })}
        </Slider>
      </Container>
    </section>
  );
};

export default Instagram;
