import { FC, useEffect } from 'react';
import classNames from 'classnames';
import {
  useFeaturedFlag,
  useIsMobile,
  useProductsByCategory,
  useSelectedStore,
  useSpacingBetweenProductCard,
} from '@/hooks';
import { useAnalytics } from '@/data';
import {
  Container,
  Heading,
  Icon,
  Link,
  Product,
  ProductPlaceholder,
  Slider,
} from '@/components';
import { GProduct, SortBySelectOption } from '@/types';

import style from './ProductSideBySide.module.scss';
import { alignContent, getSlicePadding, stripHtml } from 'utils/utils';
import { ColorField, ImageField } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { TEMPLATE_BASE_PATH } from 'constants/routes';

interface IProductSideBySideProps {
  applyShadowToImage?: boolean;
  title?: string;
  titleColor?: ColorField;
  analyticsListName?: string;
  products: GProduct[];
  totalCount?: number;
  seeAllHref?: string;
  seeMoreText?: string;
  seeAllLinkColor?: ColorField;
  image?: ImageField;
  imagePosition?: 'left' | 'right';
  imageHeigthOnMobile?: number;
  imageHeigthOnDesktop?: number;
  hideOnMobile?: boolean;
  backgroundSection?: ColorField;
  imageBackgroundColor?: ColorField;
  verticalPosition?: 'start' | 'center' | 'end' | undefined;
  horizontalPosition?: 'start' | 'center' | 'end' | undefined;

  // limit and sort
  limit?: number;
  sortBy?: SortBySelectOption | undefined;
  fullWidth?: boolean;
  filterByStore?: boolean;
  filterByCategory?: string; // filter by category
  filterByBrand?: string; // filter by brand
  filterByGroupId?: string; // filter by group id
  filterByOnSale?: boolean; // filter by on sale
  spacingBottom?: boolean;
  paddingSection?: 'small' | 'medium' | 'large';
}

const ProductSideBySide: FC<IProductSideBySideProps> = ({
  title,
  titleColor,
  analyticsListName,
  totalCount: _totalCount = 0,
  seeAllHref,
  products: serverProducts,
  seeMoreText = 'See All',
  seeAllLinkColor,
  image,
  imageHeigthOnMobile,
  imageHeigthOnDesktop,
  imagePosition = 'left',
  verticalPosition = 'start',
  horizontalPosition = 'start',
  backgroundSection,
  imageBackgroundColor,
  hideOnMobile,
  applyShadowToImage,

  limit = 20,
  sortBy,
  fullWidth,

  filterByStore = false,
  filterByCategory,
  filterByBrand,
  filterByGroupId,
  filterByOnSale,
  paddingSection,
  spacingBottom,
}) => {
  const { measureProductImpressions, measureViewItemsListGA4 } = useAnalytics();
  const { isMobile } = useIsMobile();
  const {
    breakpoints,
    defaultSlidePerView,
    defaultSpaciBetween,
    spacingClass,
  } = useSpacingBetweenProductCard();
  const { store } = useSelectedStore();

  const { isProductCardLargImage } = useFeaturedFlag();

  useEffect(() => {
    measureProductImpressions(products, analyticsListName as string);
    measureViewItemsListGA4(products, analyticsListName as string);
  }, []);

  const { products, isLoading, total, isError, error } = useProductsByCategory({
    dispensaryName: store?.shortName ?? '',
    sortBy,
    limit,
    products: serverProducts,
    filterByStore,
    filterByBrand,
    filterByCategory,
    filterByGroupId,
    filterByOnSale,
  });

  const showContentInfo = !(hideOnMobile && isMobile);

  if (isError) {
    // eslint-disable-next-line no-console
    console.log(error);

    return null;
  }

  if (!isLoading && !products?.length) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.product__sidebyside,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: spacingBottom,
        },
      )}
      style={{ background: backgroundSection! }}
      //data-slice-type={slice.slice_type}
      //data-slice-variation={slice.variation}
    >
      <div
        className={classNames({ [style.product__sidebyside_body]: fullWidth })}
      >
        <Container
          className={classNames(style.product__sidebyside_container, {
            [style.product__sidebyside_container_invested]:
              imagePosition === 'right',
          })}
          style={{ height: !isMobile ? imageHeigthOnDesktop : undefined }}
        >
          {showContentInfo && (
            <div
              className={classNames(style.product__sidebyside_info_content, {
                [style.product__sidebyside_info_content_not_fullwidth]:
                  !fullWidth,
              })}
              style={{
                alignItems: alignContent(verticalPosition ?? 'start'),
                height: isMobile ? imageHeigthOnMobile : undefined,
              }}
            >
              <div
                className={classNames(style.info, {
                  [style.info__full_width]: fullWidth,
                })}
                style={{
                  alignItems: alignContent(horizontalPosition ?? 'start'),
                }}
              >
                <h2
                  className={style.product__sidebyside_title}
                  dangerouslySetInnerHTML={{
                    __html: title!,
                  }}
                  style={{
                    color: titleColor!,
                  }}
                />
                <Link
                  className={style.products__see_all}
                  href={seeAllHref ?? TEMPLATE_BASE_PATH.SHOP}
                  style={{ color: seeAllLinkColor! }}
                >
                  {seeMoreText}
                  <Icon name="arrow-right" />
                </Link>
              </div>
              <div
                className={classNames(style.image_section, {
                  [style.image_section__full_width_left]:
                    imagePosition === 'left' && fullWidth,
                  [style.image_section__full_width_rigth]:
                    imagePosition === 'right' && fullWidth,
                  [style.image_section_border_radius]: !fullWidth,
                })}
                style={{ background: imageBackgroundColor! }}
              >
                <PrismicNextImage
                  fallbackAlt=""
                  field={image}
                  fill
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                  }}
                />
              </div>
              {applyShadowToImage && (
                <span
                  className={classNames(style.overlay, {
                    [style.overlay__full_width_left]:
                      imagePosition === 'left' && fullWidth,
                    [style.overlay__full_width_rigth]:
                      imagePosition === 'right' && fullWidth,
                    [style.overlay__border_radius]: !fullWidth,
                  })}
                />
              )}
            </div>
          )}
          {!isMobile && (
            <div
              className={classNames(
                style.product__sidebyside_content_product,
                spacingClass,
                {
                  [style.product__sidebyside_content_product_large]:
                    isProductCardLargImage,
                },
              )}
            >
              {isLoading
                ? new Array(20)
                    .fill(null)
                    .map((_, index) => (
                      <ProductPlaceholder
                        key={index}
                        largeCard={isProductCardLargImage}
                      />
                    ))
                : products?.map((prod, index) => (
                    <Product
                      className={style.content__product}
                      key={index}
                      product={prod}
                    />
                  ))}
            </div>
          )}
          {isMobile && (
            <Slider
              activatedHover
              breakpoints={breakpoints}
              centeredSlides={false}
              className={style.products__carousel}
              freeMode
              isNotPaddingMobile={false}
              seeAllElement={
                hideOnMobile && (
                  <Link
                    className={style.products__see_all}
                    href={seeAllHref ?? '/deals'}
                  >
                    {seeMoreText}
                    {total > 0 && <span>({total})</span>}
                  </Link>
                )
              }
              showSliderCardLink={true}
              showSlidesOutsideContainer={true}
              sliderHeading={
                hideOnMobile ? (
                  <Heading level={2}>{stripHtml(title)}</Heading>
                ) : undefined
              }
              slidesPerView={defaultSlidePerView}
              spaceBetween={defaultSpaciBetween}
            >
              {isLoading
                ? Array.from({ length: 20 }).map((_, index) => (
                    <ProductPlaceholder
                      key={index}
                      largeCard={isProductCardLargImage}
                    />
                  ))
                : products.map(product => (
                    <Product
                      analyticsListName={analyticsListName}
                      className={style.slide__main}
                      key={product.id}
                      product={product}
                    />
                  ))}
              <Link
                className={classNames(
                  style.slide__main,
                  style.product__shop_all_slider,
                )}
                href={seeAllHref ?? '/deals'}
              >
                <Icon name="right" />
                <p>{seeMoreText}</p>
              </Link>
            </Slider>
          )}
        </Container>
      </div>
    </section>
  );
};

export default ProductSideBySide;
