import { useEffect, useState } from 'react';

const useWindowHeight = () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window.innerHeight);
    window.addEventListener('resize', () => {
      const currentHeight = window.innerHeight;
      if (height !== currentHeight) {
        setHeight(window.innerHeight);
      }
    });
  }, []);

  return { height };
};

export { useWindowHeight };
