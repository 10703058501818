import { MedRecSwitch, SearchHeader, StoreLocator } from '@/components';

import {
  HeaderBurgerNav,
  HeaderContainer,
  HeaderFavorites,
  HeaderLogo,
  HeaderNav,
  HeaderProfile,
  HeaderRow,
  HeaderSection,
  HeaderShopping,
} from '../Header.components';

import styles from './HeaderWithTopLocatorAndBottomNav.module.scss';

const HeaderWithTopLocatorAndBottomNav = () => (
  <>
    <HeaderWithTopLocatorAndBottomNavMobile />
    <HeaderWithTopLocatorAndBottomNavDesktop />
  </>
);

const HeaderWithTopLocatorAndBottomNavMobile = () => (
  <HeaderContainer.Mobile>
    <HeaderRow borderless type="small">
      <HeaderSection justify="start">
        <HeaderBurgerNav />
        <HeaderLogo fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow borderless relativePosition type="flat">
      <HeaderSection justify="center">
        <SearchHeader className={styles.search__header} />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow type="small">
      <HeaderSection justify="center" spaceBetweenOnMultipleChildren>
        <StoreLocator />
        <MedRecSwitch />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Mobile>
);

const HeaderWithTopLocatorAndBottomNavDesktop = () => (
  <HeaderContainer.Desktop>
    <HeaderRow className={styles.search__header_top_row} type="small">
      <HeaderSection justify="start">
        <StoreLocator />
        <MedRecSwitch />
      </HeaderSection>
      <HeaderSection justify="end">
        <HeaderProfile showLabel />
        <HeaderFavorites showLabel />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow
      className={styles.search__header_center_row}
      relativePosition
      type="small"
    >
      <HeaderSection justify="start">
        <HeaderLogo fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <SearchHeader className={styles.search__header} />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow type="flat" withNavbar>
      <HeaderSection justify="start">
        <HeaderNav />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Desktop>
);

export { HeaderWithTopLocatorAndBottomNav };
