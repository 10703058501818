import { TabReview } from '@/components';

import { useReviewsFilters } from './ReviewsFilterProvider';

const FilteredTabReviews = () => {
  const reviews = useReviewsFilters();

  return (
    <TabReview
      className={'details__tab-reviews'}
      ref={reviews?.reviewsRef}
      reviews={reviews?.filteredReviews ?? []}
    />
  );
};

export { FilteredTabReviews };
