import Head from 'next/head';
import React, { FC } from 'react';

import { SeoProps } from '@/types';

import buildTags from './buildTags';
import ProductJsonLd from './jsonld/product';
import ArticleJsonLd from './jsonld/article';
import BreadCrumbJsonLd from './jsonld/breadcrumb';
import LocalBusinessJsonLd from './jsonld/localBusiness';
import SiteLinksSearchBoxJsonLd from './jsonld/siteLinksSearchBox';
import CarouselJsonLd from './jsonld/carousel';

// For testing: https://search.google.com/test/rich-results
// Google structured data: https://developers.google.com/search/docs/advanced/structured-data/search-gallery
// https://github.com/garmeeh/next-seo#readme

const Seo: FC<SeoProps> = ({
  title,
  noindex = false,
  nofollow,
  robotsProps = { maxImagePreview: 'large' },
  description,
  canonical,
  openGraph,
  facebook,
  twitter,
  additionalMetaTags,
  titleTemplate,
  defaultTitle,
  mobileAlternate,
  languageAlternates,
  additionalLinkTags,
  articleJsonLdProps,
  breadCrumbJsonLdProps,
  localBusinessJsonLdProps,
  productJsonLdProps,
  siteLinksSearchBoxJsonLdProps,
  carouselJsonLdProps,
}) => (
  <>
    <Head>
      {buildTags({
        additionalLinkTags,
        additionalMetaTags,
        canonical,
        defaultTitle,
        description,
        facebook,
        languageAlternates,
        mobileAlternate,
        nofollow,
        noindex,
        openGraph,
        robotsProps,
        title,
        titleTemplate,
        twitter,
      })}
    </Head>
    {articleJsonLdProps && <ArticleJsonLd {...articleJsonLdProps} />}
    {breadCrumbJsonLdProps && <BreadCrumbJsonLd {...breadCrumbJsonLdProps} />}
    {localBusinessJsonLdProps && (
      <LocalBusinessJsonLd {...localBusinessJsonLdProps} />
    )}
    {productJsonLdProps && <ProductJsonLd {...productJsonLdProps} />}
    {siteLinksSearchBoxJsonLdProps && (
      <SiteLinksSearchBoxJsonLd {...siteLinksSearchBoxJsonLdProps} />
    )}
    {carouselJsonLdProps && <CarouselJsonLd {...carouselJsonLdProps} />}
  </>
);

export { Seo };
