'use client';

import { FC, useEffect, useState } from 'react';
import { getSlicePadding, textAlign } from '@/utils';
import { Button } from '../Button';
import { Container } from '../Container';
import classNames from 'classnames';
import styles from './pwabutton.module.scss';
import { BeforeInstallPromptEvent, PwaButtonSlice } from '@/types';

const PwaButton: FC<PwaButtonSlice> = slice => {
  const buttonText = slice?.primary?.button_text!;
  const buttonType = slice?.primary?.button_color;
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const alignment = slice?.primary?.horizontal_position ?? 'center';

  // Initialize deferredPrompt for use later to show browser install prompt.
  const [promptEvent, setPromptEvent] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleEvent = (e: BeforeInstallPromptEvent) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.

      setPromptEvent(e);
    };

    window.addEventListener('beforeinstallprompt', handleEvent);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleEvent);
    };
  }, []);

  if (!promptEvent) {
    return null;
  }

  const handleClick = () => {
    promptEvent!.prompt();

    // We've used the prompt and can't use it again, throw it away
    setPromptEvent(null);
  };

  return (
    <Container
      as="section"
      className={classNames(styles.pwabutton, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{ justifyContent: textAlign(alignment) }}
    >
      <Button color={buttonType} onClick={handleClick}>
        {buttonText}
      </Button>
    </Container>
  );
};

export { PwaButton };
