import { FC } from 'react';
import * as prismicH from '@prismicio/helpers';
import classNames from 'classnames';
import { Container } from '@/components';
import { useIsMobile, useShowByLocationSlug } from '@/hooks';
import { CodeEmbedSlice } from '@/types';
import { getSlicePadding } from '@/utils';

import styles from './codeembed.module.scss';

const CodeEmbed: FC<CodeEmbedSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);
  const { isMobile } = useIsMobile();

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const backgroundColor = slice?.primary?.section_background_color;
  const horizontalAlign = slice?.primary?.horizontal_align;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;
  const maxWidth = isMobile
    ? slice?.primary?.max_width_mobile || '100%'
    : slice?.primary?.max_width_desktop || '100%';

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'Left':
        return 'flex-start';

      case 'Right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  return (
    <section
      className={classNames(
        styles.code_embed,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: backgroundColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container
        className={styles.code_embed__container}
        style={{
          justifyContent: textAlign(horizontalAlign),
        }}
      >
        <div
          className={styles.code_embed__container_content}
          dangerouslySetInnerHTML={{
            __html: prismicH.asText(slice.primary.plain_text),
          }}
          style={{ maxWidth: `${maxWidth}px` }}
        />
      </Container>
    </section>
  );
};

export default CodeEmbed;
