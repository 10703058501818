import { isEqual } from 'lodash';

import { EcomState, UiState, WishListState } from '@/types';

import {
  initialEcomState,
  initialUiState,
  initiaWishListState,
} from './variables';
import { createPersistanceStore, createStore } from './createStore';

const ecomStore = createPersistanceStore<EcomState>(
  initialEcomState,
  `@gapcommerce/${process?.env?.NEXT_PUBLIC_STORE_ID}`,
  process?.env?.NEXT_PUBLIC_STORAGE_VERSION as string,
);

const useEcomStore = (): [
  EcomState,
  (_arg: Partial<EcomState>) => void,
  () => void,
] => {
  const state = ecomStore<EcomState>(state => state, isEqual);

  const set = ecomStore.setState;

  const reset = () => set(initialEcomState);

  return [state as EcomState, set, reset];
};

const useEcomStoreSelector = <T extends keyof EcomState>(dependencies: T[]) => {
  const state: PickKeys<EcomState, T> = ecomStore(
    state => pickProperties(state, dependencies),
    isEqual,
  );

  const setState = ecomStore.setState;

  const reset = () => setState(initialEcomState);

  return { ...state, setState, reset };
};

const wishListStore = createPersistanceStore(
  initiaWishListState,
  `@gapcommerce/wl/${process?.env?.NEXT_PUBLIC_STORE_ID}`,
  process?.env?.NEXT_PUBLIC_STORAGE_VERSION as string,
);

const useWishListStore = (): [
  WishListState,
  // eslint-disable-next-line no-unused-vars
  (arg: Partial<WishListState>) => void,
] => {
  const state = wishListStore(state => state, isEqual);

  const set = wishListStore.setState;

  return [state, set];
};

const uiStore = createStore(initialUiState);

const useUiStore = (): [
  UiState,
  (_arg: Partial<UiState>) => void,
  () => void,
] => {
  const state = uiStore(state => state, isEqual);

  const set = uiStore.setState;

  const reset = () => set(initialUiState);

  return [state, set, reset];
};

const useUiStoreSelector = <T extends keyof UiState>(dependencies: T[]) => {
  const state: PickKeys<UiState, T> = uiStore(
    state => pickProperties(state, dependencies),
    isEqual,
  );

  const setState = uiStore.setState;

  const reset = () => setState(initialUiState);

  return { ...state, setState, reset };
};

type PickKeys<T, K extends keyof T> = {
  [P in K]: T[P];
};

const pickProperties = <T extends {}, K extends keyof T>(
  obj: T,
  keys: K[],
): PickKeys<T, K> => {
  const pickedProperties = {} as PickKeys<T, K>;
  keys.forEach(key => {
    pickedProperties[key] = obj[key];
  });
  return pickedProperties;
};

export {
  useEcomStore,
  useWishListStore,
  useUiStore,
  useEcomStoreSelector,
  useUiStoreSelector,
};
