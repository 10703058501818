import { useMemo } from 'react';
import { useSelectedStore } from '@/hooks';
import { TreezClient } from '../client';

const useTreezClient = () => {
  const { store } = useSelectedStore();
  // this for in case we need to use token generated by the Treez IdP system
  // it wiil be send on the request as a Bearer token
  const token = undefined;

  const client = useMemo(
    () =>
      new TreezClient(
        store?.shortName ?? process.env.NEXT_PUBLIC_TREEZ_DEFAULT_STORE_NAME,
        token,
      ),
    [store],
  );

  return client;
};

export { useTreezClient };
