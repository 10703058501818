import { CSSProperties, FC } from 'react';

import classNames from 'classnames';

interface IPlacehoderProps {
  className?: string;
  style?: CSSProperties;
}

const Placeholder: FC<IPlacehoderProps> = ({ className, style }) => {
  return <div className={classNames('placeholder', className)} style={style} />;
};

export default Placeholder;
