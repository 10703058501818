/**
 * IMPORTANT NOTE:
 * If the structure of these two objects is changed,
 * then you must to update the versioning of
 * localStorage by setting the variable:
 * @var process.env.NEXT_PUBLIC_STORAGE_VERSION
 * Make sure you are following the Semantic Version Standard.
 */

import {
  EnumCustomerType as CustomerType,
  EcomState,
  UiState,
  WishListState,
} from '@/types';
import { PaymentType } from '@/types';

const initiaWishListState: WishListState = {
  fav_products: [],
};

const customerType =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_MED_REC_SELECTION_ACTIVE === 'true'
    ? CustomerType.ADULT
    : CustomerType.NOT_SET;

const initialEcomState: EcomState = {
  addresses: [],
  berbix_verified: false,
  buyer_accepts_marketing: true,
  checkout_identity_verification: false,
  delivery_address: {
    address1: '',
    address2: '',
    city: '',
    city_code: '',
    country: '',
    country_code: '',
    first_name: '',
    last_name: '',
    latitude: 0,
    longitude: 0,
    phone: '',
    province: '',
    province_code: '',
    zip: '',
  },
  delivery_details: {
    deliver_after: '',
    deliver_before: '',
    delivery: false,
    pickup: false,
  },
  discount_items: [],
  email: '',
  entity_id: '',
  is_crm_modal_open: false,
  treez_store_name: '',
  key: '',
  line_items: [],
  next_shop_mode: null,
  next_store_id: null,
  notes: '',
  payment_details: {
    type: PaymentType.Cash,
  },
  subtotal_price: 0,
  terms_accepted: false,
  terms_accepted_date: null,
  total_delivery: 0,
  total_discounts: 0,
  provider_inventory_location_id: null,
  total_line_items_price: 0,
  total_price: 0,
  total_service_fee: 0,
  total_tax: 0,
  date_of_birth: '',
  customer_type: customerType,
  next_customer_type: null,
  medical_id: '',
  medical_id_expiration_date: '',
  idscan_attempts: 0,
  alpineiq_template_id: '',
  alpineiq_redemption_url: '',
};

const initialUiState: UiState = {
  is_cart_open: false,
  is_filter_blog_open: false,
  is_filter_store_open: false,
  is_info_open: false,
  is_login_open: false,
  is_megamenu_open: false,
  is_menu_user_open: false,
  is_regulation_modal_open: false,
  is_nav_open: false,
  is_promotionbar_open: true,
  is_search_open: false,
  is_store_location_open: false,
  options_filter: [
    { options: [], type: 'deals' },
    { options: [], type: 'type' },
    { options: [], type: 'want' },
  ],
};

export { initiaWishListState, initialEcomState, initialUiState };
