// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  ads: dynamic(() => import('./Ads')),
  banner: dynamic(() => import('./Banner')),
  blog_posts: dynamic(() => import('./BlogPosts')),
  brands: dynamic(() => import('./Brands')),
  card_feature_text: dynamic(() => import('./CardFeatureText')),
  carousel: dynamic(() => import('./Carousel')),
  categories: dynamic(() => import('./Categories')),
  centered_button: dynamic(() => import('./CenteredButton')),
  centered_header: dynamic(() => import('./CenteredHeader')),
  centered_text: dynamic(() => import('./CenteredText')),
  cities: dynamic(() => import('./Cities')),
  code_embed: dynamic(() => import('./CodeEmbed')),
  contact_us: dynamic(() => import('./ContactUs')),
  content_embed: dynamic(() => import('./ContentEmbed')),
  cta_feature: dynamic(() => import('./CtaFeature')),
  custom_width_side_by_side: dynamic(() => import('./CustomWidthSideBySide')),
  dual_text: dynamic(() => import('./DualText')),
  faq: dynamic(() => import('./Faq')),
  feature_text: dynamic(() => import('./FeatureText')),
  first_section: dynamic(() => import('./FirstSection')),
  footer_section: dynamic(() => import('./FooterSection')),
  footer_sections: dynamic(() => import('./FooterSections')),
  general_card: dynamic(() => import('./GeneralCard')),
  group_link: dynamic(() => import('./GroupLink')),
  group_picture: dynamic(() => import('./GroupPicture')),
  header: dynamic(() => import('./Header')),
  hero_image: dynamic(() => import('./HeroImage')),
  icons_row_with_title_and_description: dynamic(
    () => import('./IconsRowWithTitleAndDescription'),
  ),
  image_gallery: dynamic(() => import('./ImageGallery')),
  image_row: dynamic(() => import('./ImageRow')),
  image_row_with_heading_and_button: dynamic(
    () => import('./ImageRowWithHeading'),
  ),
  image_with_text_side_by_side: dynamic(
    () => import('./ImageWithTextSideBySide'),
  ),
  image_with_title: dynamic(() => import('./ImageWithTitle')),
  instagram: dynamic(() => import('./Instagram')),
  links_carousel: dynamic(() => import('./LinksCarousel')),
  logo_row: dynamic(() => import('./LogoRow')),
  logo_text: dynamic(() => import('./LogoText')),
  map: dynamic(() => import('./Map')),
  picture_section: dynamic(() => import('./PictureSection')),
  product_card: dynamic(() => import('./ProductCard')),
  product_list: dynamic(() => import('./ProductList')),
  product_row: dynamic(() => import('./ProductRow')),
  product_side_by_side: dynamic(() => import('./ProductSideBySide')),
  products_carousel: dynamic(() => import('./ProductsCarousel')),
  promo_carousel: dynamic(() => import('./PromoCarousel')),
  pwa_button: dynamic(() => import('./PwaButton')),
  reviews: dynamic(() => import('./Reviews')),
  rich_text_content: dynamic(() => import('./RichTextContent')),
  round_shaped_pictures_with_description: dynamic(
    () => import('./RoundShapedPicturesWithDescription'),
  ),
  seo_content: dynamic(() => import('./SeoContent')),
  separator: dynamic(() => import('./Separator')),
  side_by_side: dynamic(() => import('./SideBySide')),
  social_section: dynamic(() => import('./SocialSection')),
  stats: dynamic(() => import('./Stats')),
  subscribe: dynamic(() => import('./Subscribe')),
  team: dynamic(() => import('./Team')),
  testimonials: dynamic(() => import('./Testimonials')),
  twitter_card: dynamic(() => import('./TwitterCard')),
  video: dynamic(() => import('./Video')),
  zip_codes: dynamic(() => import('./ZipCodes')),
};
