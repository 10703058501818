import { FC } from 'react';
import { format } from '@react-input/mask';

import { default as Input, MaskInputProps } from './InputMask';

const PHONE_MASK = '+1 (___) ___-____';
const PHONE_REPLACEMENT = { _: /\d/ };

const PhoneNumberInput: FC<MaskInputProps> = props => {
  const phoneValue = formatPhoneValue(props.value);

  return (
    <Input
      {...props}
      inputMode="tel"
      mask={PHONE_MASK}
      replacement={PHONE_REPLACEMENT}
      value={phoneValue}
    />
  );
};

const formatPhoneValue = (value?: string | number | readonly string[]) => {
  if (!value) return '';
  return format(String(value).replace(/\+1/g, ''), {
    mask: PHONE_MASK,
    replacement: PHONE_REPLACEMENT,
  });
};

export { PhoneNumberInput };
