import { FC } from 'react';
import classNames from 'classnames';
import { Cards, Link } from '@/components';

interface ICopyrightProps {
  business: string;
  showCards?: boolean;
  by?: string;
  url?: string;
  className?: string;
  colorText?: string;
}

const Copyright: FC<ICopyrightProps> = ({
  showCards,
  business,
  by,
  url,
  className,
  colorText,
  ...props
}) => {
  return (
    <div className={classNames('copyright', className)} {...props}>
      <p className="text-sm text-gray-500" style={{ color: colorText }}>
        {business}. Copyright &copy; {new Date().getFullYear()}. All rights
        reserved. Powered with love by&nbsp;
        <Link
          href={url || 'https://www.treez.io/meet-treez-ecommerce'}
          rel="noopener noreferrer"
          target="_blank"
        >
          {by || 'TREEZ'}
        </Link>
      </p>
      {showCards && <Cards />}
    </div>
  );
};

export default Copyright;
