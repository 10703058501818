import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useRouter, useSearchParams } from 'next/navigation';
import { format } from 'date-fns';
import { PAGE_ROUTES, TEMPLATE_BASE_PATH } from '@/constants';
import { AuthContainer, Heading, Link, Spinner, useAuth } from '@/components';
import { useCustomerOrderHistory } from '@/data';
import { currencyFormat, formatAddress } from '@/utils';
import { Order } from './OrderDetail';

const Orders = () => {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { isAuth, email } = useAuth();
  const { data } = useCustomerOrderHistory({
    skip: !isAuth,
    variables: {
      email,
    },
  });
  const router = useRouter();
  const query = useSearchParams();
  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>(
    undefined,
  );

  const handleViewDetail = (entity: string) => {
    setSelectedOrderId(entity);
    router?.push(`${PAGE_ROUTES.ACCOUNT}?section=orders&orderId=${entity}`);
  };

  const handleGoBack = () => {
    setSelectedOrderId(undefined);
    router?.replace(`${PAGE_ROUTES.ACCOUNT}?section=orders`);
  };

  useEffect(() => {
    setFilteredOrders(
      (data?.orders ?? []).filter(
        order => !!order.status && order.status !== 'ABANDONED_CART',
      ),
    );
  }, [data]);

  useEffect(() => {
    setSelectedOrderId(query?.get('orderId') as string);
  }, [query]);

  const renderRow = (
    address,
    created_at,
    delivery,
    entity,
    price,
    order_number,
  ) => {
    const addressLabel = formatAddress({ address, format: 'short' });

    return (
      <tr className="order__table_link" key={order_number}>
        <td data-label="Order placed">
          <span className="cell_label">Date placed</span>
          <span className="cell_value">
            {format(new Date(created_at), 'PP')}
          </span>
        </td>
        <td data-label="Total">
          <span className="cell_label">Total</span>
          <span className="cell_value">
            <b>$ {currencyFormat(price)}</b>
          </span>
        </td>
        <td data-label="Ship To">
          <span className="cell_label">
            {delivery?.delivery ? 'Ship to' : 'Pickup at'}
          </span>
          {address?.address1 || address?.city ? (
            <span className="cell_value">{addressLabel}</span>
          ) : (
            <span className="cell_value">Not Assigned yet</span>
          )}
        </td>
        <td data-label="Order #">
          <span className="cell_label">Order #</span>
          <span className="cell_value">
            <span onClick={() => handleViewDetail(entity)} role="button">
              {order_number ?? 'Not Assigned yet'}
            </span>
          </span>
        </td>
        <td className="orders__btn-actions">
          <span className="cell_label"></span>
          <span
            className="actions__link"
            onClick={() => {
              handleViewDetail(entity);
            }}
            role="button"
          >
            View order details
          </span>
        </td>
      </tr>
    );
  };

  if (selectedOrderId) {
    return <Order goBack={handleGoBack} />;
  }

  return (
    <div className="orders">
      <Heading level={2}>Order History</Heading>
      <AuthContainer>
        <div
          className="tab__list_orders_active"
          id="tab-all-orders"
          key="tab-all-orders"
          role="tabpanel"
        >
          {!filteredOrders.length && !data && (
            <div className="orders__history-loading ">
              <Spinner>Loading...</Spinner>
            </div>
          )}
          {!filteredOrders.length && !!data && (
            <div className="orders__history-blank">
              <p className="orders__list_blanck">
                No recent orders.{' '}
                <Link className="orders__link" href={TEMPLATE_BASE_PATH.SHOP}>
                  Add some items to get started.
                </Link>
              </p>
            </div>
          )}
          {filteredOrders.length > 0 && (
            <table>
              <tbody>
                {orderBy(filteredOrders, ['created_at'], ['desc']).map(
                  ({
                    created_at,
                    delivery_address,
                    delivery_details,
                    entity_id,
                    total_price,
                    treez_order_number,
                  }) =>
                    renderRow(
                      delivery_address,
                      created_at,
                      delivery_details,
                      entity_id,
                      total_price,
                      treez_order_number,
                    ),
                )}
              </tbody>
            </table>
          )}
        </div>
      </AuthContainer>
    </div>
  );
};

export { Orders };
