'use client';

import { useSearchParams } from 'next/navigation';

import { useDeepLinkStore } from '@/hooks';

const DeepLinkStore = () => {
  const query = useSearchParams();
  useDeepLinkStore({ storeName: query?.get('store') as string });

  return null;
};

export { DeepLinkStore };
