import { FC } from 'react';
import {
  Container,
  FiltersTreezPlaceholder,
  Placeholder,
  ProductPlaceholder,
} from '@/components';
import style from './SpecialPlaceholder.module.scss';

const SpecialPlaceholder: FC = () => {
  return (
    <main>
      <Container className={style.placeholder__page_ecommerce}>
        <FiltersTreezPlaceholder />
        <Container as="section" className={style.placeholder__container}>
          <Placeholder className={style.placeholder__banner} />
          <div className={style.placeholder__product_container}>
            <div className={style.placeholder__product_list}>
              {new Array(10).fill(null).map((_, index) => (
                <ProductPlaceholder
                  className={style.placeholder_product}
                  key={index}
                />
              ))}
            </div>
            <div className={style.placeholder__pagination}>
              <Placeholder className={style.placeholder__actions} />
              <ul className={style.placeholder__container_pages}>
                {new Array(5).fill(null).map((_, index) => (
                  <Placeholder
                    className={style.placeholder__page}
                    key={index}
                  />
                ))}
              </ul>
              <Placeholder className={style.placeholder__actions} />
            </div>
          </div>
        </Container>
      </Container>
    </main>
  );
};

export { SpecialPlaceholder };
