import { FC, useEffect, useState } from 'react';
import BerbixVerify from 'berbix-react';
import { useRouter } from 'next/navigation';

import {
  //getBerbixTransactionResult,
  initializeApollo,
  useCreateBerbixClientToken,
  useEcomStore,
} from '@/data';
import { useNotify, useSelectedStore } from '@/hooks';
import { Alert, Button, Heading, Spinner, useAuth } from '@/components';

import style from './berbix.module.scss';

interface IBerbixProps {
  // eslint-disable-next-line no-unused-vars
  onComplete?: (_) => void;
}

const Berbix: FC<IBerbixProps> = () => {
  const [_tokens, setTokens] = useState<any>(null);
  const [clientToken, setClientToken] = useState(null);
  const { result, createBerbixClientToken } = useCreateBerbixClientToken();
  const { notify } = useNotify();
  const { user } = useAuth();
  const { store } = useSelectedStore();
  const [errorOcurred, setErrorOcurred] = useState(false);
  const [showInfoText, setShowInfoText] = useState(true);
  const _client = initializeApollo();
  const [, _setStoredState] = useEcomStore();
  const router = useRouter();
  const input = {
    user_id: user?.attributes?.sub ?? 'empty_user_id',
  };

  useEffect(() => {
    try {
      createBerbixClientToken({
        variables: {
          input: input,
        },
      });
    } catch (error) {
      notify('error', `${error}`);
    }
  }, []);

  useEffect(() => {
    if (result.data) {
      setClientToken(result?.data?.createBerbixClientToken?.client_token);
      setTokens(result?.data);
    }
  }, [result]);

  const handleBerbixComplete = async () => {
    setShowInfoText(false);

    // const {
    //   data: {
    //     getBerbixTransactionResult: { valitated_user },
    //   },
    //   error,
    // } = await getBerbixTransactionResult(client, {
    //   variables: {
    //     access_token: tokens?.createBerbixClientToken?.access_token,
    //     refresh_token: tokens?.createBerbixClientToken?.refresh_token,
    //   },
    // });

    // if (error) {
    //   setErrorOcurred(true);
    //   return;
    // }

    // setStoredState({
    //   berbix_verified: valitated_user,
    // });

    // if (!valitated_user) {
    //   setErrorOcurred(true);
    //   return;
    // }

    // updateUserAttributes({
    //   'custom:BERBIX_VERIFY': 'true',
    // });

    // notify('success', 'ID verification completed successfully');
  };

  const handleError = () => {
    setErrorOcurred(true);
  };

  const handleTryAgain = () => {
    router.refresh();
  };

  const handleGoBack = () => {
    router.push('/');
  };

  return (
    <div className={style.berbix__container}>
      {clientToken ? (
        <>
          <Heading level={2}>Verify your Identity</Heading>
          {errorOcurred && (
            <Alert error={true} isOpen={true} toggle={() => {}}>
              ID verification has failed, please try again. If this issue
              persist please contact support at {store?.phone}
            </Alert>
          )}
          {showInfoText && (
            <p>
              Please use the following prompt to verify your ID and selfie.
              Please note that the photo of your identification must be clear
              and is best taken against a solid background, also, must not be
              expired. Your selfie must be a picture that is taken at the time
              of verification and is best taken with adequate lighting.
            </p>
          )}
          <BerbixVerify
            clientToken={clientToken}
            onComplete={handleBerbixComplete}
            onError={handleError}
          />

          {errorOcurred && (
            <div className={style.berbix_buttons}>
              <Button color="primary" onClick={handleTryAgain}>
                Try again
              </Button>
              <Button color="secondary" onClick={handleGoBack}>
                Go back
              </Button>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
export default Berbix;
