'use client';
import { FC } from 'react';
import { useScreenWidth } from '@/hooks';
import { PrismicNextImage } from '@prismicio/next';
import { Badge, Container, IconSvg, Sharing } from '@/components';
import styles from './bannerlocation.module.scss';
import classNames from 'classnames';
import { ColorField, ImageField, NumberField } from '@prismicio/client';
import { convertHexadecimalFormatToRGB } from '@/utils';

const BannerLocation: FC<IBannerLocation> = banner => {
  const { width } = useScreenWidth();
  const image = banner?.image;
  const title = banner?.title;
  const textColor = banner?.textColor;
  const isOpen = banner?.isOpen;
  const message = banner?.message;
  const fullWidth = banner?.fullWidth;
  const maxHeigthDesktop = banner?.maxHeigthDesktop;
  const maxHeigthMobile = banner?.maxHeigthMobile;
  const showEffect = banner?.showEffect;
  const slug = banner?.slug ?? 'test';
  const opacity = banner?.opacity;

  const sizes = `(min-width: 2048px)  2008px, (min-width: 1536px)  1496px,
        (min-width: 1280px)  1240px, (min-width: 1024px) 984px,
        (min-width: 768px) 728px, 100vw`;

  const borderRadius =
    width > 640 && !fullWidth ? `${banner?.borderRadius}px` : 0;

  const overlay = banner?.overlay
    ? convertHexadecimalFormatToRGB(banner?.overlay)
    : null;

  const getMaxHeight = () => {
    const paddingTop = banner?.fullWidth ? '28.13%' : '26.1%';

    if (width > 640 && maxHeigthDesktop) {
      return `min(${maxHeigthDesktop}px, ${paddingTop})`;
    }

    if (maxHeigthMobile && width < 640) {
      return `min(${maxHeigthMobile}px, ${paddingTop})`;
    }

    return;
  };

  const sharingStr = `${process.env.NEXT_PUBLIC_STORE_DOMAIN}/dispensary/${slug}`;

  const renderImage = (banner, sizes, classCss) => {
    return (
      <figure
        className={classNames(classCss, {
          [styles.placeholder]: !banner?.url,
        })}
        style={{
          borderRadius,
          paddingTop: getMaxHeight(),
        }}
      >
        {banner && banner?.url && (
          <PrismicNextImage
            fallbackAlt=""
            field={banner}
            fill
            priority
            sizes={sizes}
            style={{
              borderRadius,
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        )}
        {!banner?.url && !banner?.overlay && (
          <div>
            <IconSvg name="image" />
            <p>
              <b>There is no banner</b>
              <br />
              Kindly establish a banner
            </p>
          </div>
        )}
      </figure>
    );
  };

  return (
    <div
      className={classNames(styles.location_banner__section, {
        [styles.location_banner__section_fullwidth]: fullWidth,
      })}
    >
      <Container
        className={classNames(styles.location_banner__container, {
          [styles.location_banner__container_fullwidth]: fullWidth,
        })}
      >
        {renderImage(image, sizes, styles.location_banner__desktop)}
        {renderImage(
          image?.mobile,
          '(min-width: 640px) 610px, 100wv',
          styles.location_banner__mobile,
        )}
        {(overlay || showEffect) && (
          <span className={styles.overlay__container}>
            <span
              className={classNames(styles.overlay, {
                [styles.overlay_effect_shadow]: showEffect,
              })}
              style={{
                backgroundColor:
                  overlay && !showEffect
                    ? `rgba(${overlay?.r}, ${overlay?.g}, ${overlay?.b}, ${opacity})`
                    : undefined,
                borderRadius,
              }}
            />
          </span>
        )}
        {title && (
          <Container className={styles.location_banner_body}>
            <div
              className={classNames(styles.location_banner_text, {
                [styles.text__with_maximum_height]: maxHeigthDesktop,
              })}
            >
              {title && (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                  style={{
                    color: textColor!,
                  }}
                />
              )}
              <div className={styles.location_banner_info}>
                <div className={styles.location_banner__status}>
                  <Badge
                    className={classNames(
                      styles.location_banner__status_badge,
                      {
                        [styles.location_banner__status_badge_closed]: !isOpen,
                      },
                    )}
                  >
                    {isOpen ? 'OPEN' : 'CLOSED'}
                  </Badge>
                  <span
                    className={styles.location_banner__status_message}
                    style={{
                      color: textColor!,
                    }}
                  >
                    {message}
                  </span>
                </div>
                <Sharing
                  className={styles.location_banner_sharing}
                  iconStyle={{ color: textColor }}
                  include={['facebook', 'twitter', 'whatsapp']}
                  media={undefined}
                  url={sharingStr}
                />
              </div>
            </div>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default BannerLocation;

export interface IBannerLocation {
  image?: ImageField<'mobile'>;
  textColor?: ColorField;
  title?: string;
  isOpen?: boolean;
  message?: string;
  fullWidth?: boolean;
  borderRadius?: NumberField;
  slug?: string;
  maxHeigthDesktop?: NumberField;
  maxHeigthMobile?: NumberField;
  showEffect?: boolean;
  overlay?: ColorField;
  opacity?: NumberField;
}
