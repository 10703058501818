import { useMutation } from '@apollo/client';
import { SUBSCRIBE_USER_MUTATION } from './schema';

export function useSubscribeUser(options) {
  const [subscribeUser, result] = useMutation(SUBSCRIBE_USER_MUTATION, options);

  return {
    subscribeUser,
    result,
  };
}
