import { CSSProperties, FC } from 'react';

import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import classNames from 'classnames';

import {
  DefaultComponentSection,
  textAlign,
} from '../ImageWithTextSideBySide.components';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';
import { Slider } from '@/components';
import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceWithWideSocialIcons,
} from '@/types';

import style from './withwidesocialiconsvariation.module.scss';

export const WithWideSocialIconsVariation: FC<{
  slice: ImageWithTextSideBySideSliceWithWideSocialIcons &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);
  const breakpoints = {
    640: {
      spaceBetween: 32,
    },
  };

  const itemImageCustomWidth = slice?.primary?.item_image_custom_width;
  return (
    <DefaultComponentSection slice={slice}>
      {filteredItems && (
        <div
          className={style.items_section}
          style={
            {
              '--content-align': textAlign(
                slice.primary.content_horizontal_align as string,
              ),
            } as CSSProperties
          }
        >
          <Slider
            breakpoints={breakpoints}
            className={classNames(style.item_slider, {
              [style.item__slider_align_right]:
                slice.primary.content_horizontal_align === 'right',
            })}
            showSliderButtons={false}
            showSlidesOutsideContainer
            spaceBetween={16}
          >
            {filteredItems?.map((item, index) => (
              <div className={style.item} key={index}>
                {item.social_image.url && (
                  <PrismicNextLink field={item.link}>
                    <figure
                      className={classNames(style.image, {
                        [style.image__custom_width]: !!itemImageCustomWidth,
                      })}
                      style={{
                        width: `${itemImageCustomWidth}px`,
                      }}
                    >
                      <PrismicNextImage
                        fallbackAlt=""
                        field={item.social_image}
                        style={{ objectFit: 'contain' }}
                      />
                    </figure>
                  </PrismicNextLink>
                )}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </DefaultComponentSection>
  );
};
