import { FC } from 'react';
import classNames from 'classnames';
import { Container, Heading } from '@/components';
import { LogoTextSlice } from '@/types';
import { PrismicRichText } from '@prismicio/react';
import {
  asText,
  getMaxWidthForDevice,
  getSlicePadding,
  htmlSerializerWithProps,
} from '@/utils';
import { PrismicNextImage } from '@prismicio/next';
import { useScreenWidth, useShowByLocationSlug } from '@/hooks';

import styles from './logotext.module.scss';

const LogoText: FC<LogoTextSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);

  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;
  const textColor = slice?.primary?.text_color;
  const backgroundColor = slice?.primary?.background_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  const descriptionMaxWidthDesktop =
    slice?.primary?.description_max_width_desktop;
  const descriptionMaxWidthMobile =
    slice?.primary?.description_max_width_mobile;
  const titleMaxWidthDesktop = slice?.primary?.title_max_width_desktop;
  const titleMaxWidthMobile = slice?.primary?.title_max_width_mobile;

  const textAlign = slice?.primary?.text_align ?? 'center';

  const logoSize = slice?.primary?.logo_size
    ? parseFloat(slice?.primary?.logo_size)
    : 75;

  const { width } = useScreenWidth();

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  const descriptionMaxWidth = getMaxWidthForDevice(
    width,
    descriptionMaxWidthDesktop!,
    descriptionMaxWidthMobile!,
  );

  const titleMaxWidth = getMaxWidthForDevice(
    width,
    titleMaxWidthDesktop!,
    titleMaxWidthMobile!,
  );

  return (
    <section
      className={classNames(styles.logo_text, getSlicePadding(paddingSection), {
        [styles.logo_text_background]: backgroundColor,
        ['spacing_bottom']: marginBottom,
        ['background__fixed_cover']: backgroundStyleFixed,
        ['background__contained']: !backgroundStyleFixed,
      })}
      style={{
        background: backgroundColor!,
        color: textColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container
        className={classNames(styles.logo_text__container, 'content')}
        style={{ textAlign: textAlign }}
      >
        {asText(slice?.primary?.title) && (
          <Heading
            color={textColor!}
            level={2}
            style={{ maxWidth: titleMaxWidth }}
          >
            {asText(slice?.primary?.title)}
          </Heading>
        )}
        <div className={styles.logo_container} style={{ width: logoSize }}>
          <PrismicNextImage
            fallbackAlt=""
            field={slice?.primary?.logo}
            height={slice?.primary?.logo?.dimensions?.height}
            quality={100}
            style={{
              objectFit: 'contain',
              width: '100%',
              height: 'auto',
            }}
            width={slice?.primary?.logo?.dimensions?.width}
          />
        </div>
        <div
          className={styles.logo_description}
          style={{ width: descriptionMaxWidth, textAlign: textAlign }}
        >
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: textColor,
              width: descriptionMaxWidth,
              textAlign: textAlign,
            })}
            field={slice?.primary?.description}
          />
        </div>
      </Container>
    </section>
  );
};

export default LogoText;
