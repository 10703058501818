import { FC, MouseEventHandler, ReactNode, useEffect } from 'react';

import classNames from 'classnames';

import { IconSvg } from '@/components';

interface IAlertProps {
  children: ReactNode;
  className?: string;
  error?: boolean;
  floatingAction?: boolean;
  info?: boolean;
  isOpen: Boolean;
  noAction?: Boolean;
  toggle?: MouseEventHandler<HTMLButtonElement>;
  warning?: boolean;
  showIcon?: boolean;
}

const Alert: FC<IAlertProps> = ({
  children,
  className,
  error,
  floatingAction = false,
  info,
  isOpen = false,
  noAction = false,
  showIcon = true,
  toggle,
  warning,
}) => {
  useEffect(() => {
    if (noAction && isOpen) {
      // when the component is mounted, the alert is displayed for 3 seconds
      setTimeout(() => {
        toggle;
      }, 5000);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const getIconName = () => {
    if (warning) {
      return 'alert-warning';
    }

    if (error) {
      return 'alert-error';
    }

    if (info) {
      return 'alert-info';
    }

    return 'alert-success';
  };

  return (
    <div
      className={classNames(
        'alert',
        'alert_success',
        {
          ['alert_error']: error,
          ['alert_info']: info,
          ['alert_warning']: warning,
          ['alert_layout_icon']: showIcon,
          ['alert_layout_action']: !noAction && !floatingAction && toggle,
          ['alert_layout_clasic']: showIcon && !noAction && toggle,
        },
        className,
      )}
      role="alert"
    >
      {showIcon && (
        <figure>
          <IconSvg name={getIconName()} />
        </figure>
      )}
      <div>{children}</div>
      {toggle && (
        <button
          className={classNames({
            ['floating']: floatingAction,
            ['floating--none']: noAction,
          })}
          data-dismiss="alert"
          onClick={toggle}
        >
          <IconSvg name="close" />
        </button>
      )}
    </div>
  );
};

export default Alert;
