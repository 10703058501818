import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ACCOUNT_MUTATION,
  CREATE_BERBIX_CLIENT_TOKEN,
  CUSTOMER_ORDER_HISTORY_QUERY,
  GET_ACCOUNT_BY_EMAIL_QUERY,
  GET_ACCOUNT_PHOTO_QUERY,
  GET_BERBIX_TRANSACTION,
  SET_TREEZ_CUSTOMER_UNVERIFIED,
  UPDATE_ACCOUNT_MUTATION,
  UPLOAD_ACCOUNT_PHOTO_MUTATION,
} from './schema';

export function useCustomerOrderHistory(options) {
  const { data, error, loading } = useQuery(
    CUSTOMER_ORDER_HISTORY_QUERY,
    options,
  );

  return {
    data: data?.customerOrderHistory,
    error: error,
    loading: loading,
  };
}

export function useGetAccount(options) {
  const { data, error, loading, refetch } = useQuery(
    GET_ACCOUNT_BY_EMAIL_QUERY,
    options,
  );

  return {
    data: data?.getAccountByEmail,
    error: error,
    loading: loading,
    refetch,
  };
}

export function useCreateAccount(options) {
  const [createAccount, result] = useMutation(CREATE_ACCOUNT_MUTATION, options);

  return {
    createAccount,
    result,
  };
}

export function useUpdateAccount(options) {
  const [updateAccount, result] = useMutation(UPDATE_ACCOUNT_MUTATION, options);

  return {
    result,
    updateAccount,
  };
}

export function useUploadAccountPhoto(options) {
  const [uploadAccountPhoto, result] = useMutation(
    UPLOAD_ACCOUNT_PHOTO_MUTATION,
    options,
  );

  return {
    result,
    uploadAccountPhoto,
  };
}

export function useGetAccountPhoto(options) {
  const { data, error, loading } = useQuery(GET_ACCOUNT_PHOTO_QUERY, options);

  return {
    data: data?.getAccountPhoto,
    error: error,
    loading: loading,
  };
}

export const useCreateBerbixClientToken = options => {
  const [createBerbixClientToken, result] = useMutation(
    CREATE_BERBIX_CLIENT_TOKEN,
    options,
  );

  return {
    createBerbixClientToken,
    result,
  };
};

export const useGetBerbixTransactionResult = options => {
  const { data, error, loading } = useQuery(GET_BERBIX_TRANSACTION, options);

  return {
    data,
    error,
    loading,
  };
};

export const useSetTreezCustomerUnverified = options => {
  const [setTreezCustomerUnverified, result] = useMutation(
    SET_TREEZ_CUSTOMER_UNVERIFIED,
    options,
  );

  return { setTreezCustomerUnverified, result };
};
