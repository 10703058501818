import { gql } from '@apollo/client';

export const MIGRATE_USER_MUTATION = gql`
  mutation migrateUser(
    $account_id: String!
    $store_id: String!
    $username: String!
    $password: String!
  ) {
    migrateUser(
      account_id: $account_id
      store_id: $store_id
      username: $username
      password: $password
    )
  }
`;
