/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import Script from 'react-load-script';
import { MAP_PIN_SVG } from '@/constants';

function OrderRouteMap({ address1, address2, className }) {
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);

  const createMarker = (latLng, title, map, bounds) => {
    const info = new google.maps.InfoWindow({
      content: title,
    });

    const svgMarker = {
      ...MAP_PIN_SVG,
      anchor: new google.maps.Point(15, 30),
    };

    const marker = new google.maps.Marker({
      icon: svgMarker,
      map,
      position: {
        lat: latLng.latitude,
        lng: latLng.longitude,
      },
      title: title,
    });

    marker.addListener('click', () => {
      info.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });
    bounds.extend(marker.getPosition());
  };

  useEffect(() => {
    if (Script.loadedScripts[process.env.NEXT_PUBLIC_GOOGLE_MAP_API]) {
      if (!address1.latitude || !address1.longitude) {
        return;
      }

      const map = new google.maps.Map(ref.current, {
        disableDefaultUI: true,
        draggable: false,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
        zoom: 14,
        ...(!address2
          ? {
              center: {
                lat: address1.latitude,
                lng: address1.longitude,
              },
            }
          : {}),
      });
      const bounds = new google.maps.LatLngBounds();

      createMarker(address1, address1.title, map, bounds);

      if (address2) {
        createMarker(address2, address2.title, map, bounds);
        map.fitBounds(bounds);
      }
    }
  }, [loading]);

  return (
    <figure className={`map ${className ?? ''}`} ref={ref}>
      {!Script.loadedScripts[process.env.NEXT_PUBLIC_GOOGLE_MAP_API] && (
        <Script
          onLoad={() => {
            setLoading(false);
          }}
          url={process.env.NEXT_PUBLIC_GOOGLE_MAP_API}
        />
      )}
    </figure>
  );
}

export default OrderRouteMap;
