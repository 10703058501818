import type { AggregateOffer } from '@/types';

import { setOffers } from './setOffers';

export function setAggregateOffer(aggregateOffer?: AggregateOffer) {
  if (aggregateOffer) {
    return {
      '@type': 'AggregateOffer',
      highPrice: aggregateOffer.highPrice,
      lowPrice: aggregateOffer.lowPrice,
      offerCount: aggregateOffer.offerCount,
      offers: setOffers(aggregateOffer.offers),
      priceCurrency: aggregateOffer.priceCurrency,
    };
  }
  return undefined;
}
