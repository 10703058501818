/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Script from 'react-load-script';

function Map({
  googleMapApi,
  title,
  latitude,
  longitude,
  className,
  ...props
}) {
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);
  const instance = useRef(null);
  const marker = useRef(null);

  useEffect(() => {
    if (!Script.loadedScripts[googleMapApi]) {
      return;
    }

    const latLng = { lat: latitude, lng: longitude };

    instance.current = new google.maps.Map(ref.current, {
      center: latLng,
      disableDefaultUI: true,
      draggable: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ],
      zoom: 15,
    });

    const info = new google.maps.InfoWindow({
      content: title,
    });
    marker.current = new google.maps.Marker({
      map: instance.current,
      position: latLng,
    });

    marker.current.addListener('click', () => {
      info.open({
        anchor: marker,
        map: instance.current,
        shouldFocus: false,
      });
    });
  }, [loading]);

  useEffect(() => {
    if (loading) {
      return;
    }

    instance.current.panTo({ lat: latitude, lng: longitude });
    marker.current.setPosition({ lat: latitude, lng: longitude });
  }, [loading, latitude, longitude]);

  return (
    <figure className={classNames('map', className)} ref={ref} {...props}>
      {!Script.loadedScripts[googleMapApi] && (
        <Script
          onLoad={() => {
            setLoading(false);
          }}
          url={googleMapApi}
        />
      )}
    </figure>
  );
}

export default Map;
