import { FC } from 'react';
import classNames from 'classnames';
import { PrismicLink } from '@prismicio/react';
import { getSlicePadding } from '@/utils';
import { asText } from '@prismicio/client';
import { LogoRowSlice } from '@/types';
import { Container, Heading, Image } from '@/components';
import {
  useFilteredSliceItemsByLocationSlug,
  useShowByLocationSlug,
} from '@/hooks';
import styles from './logorow.module.scss';

const LogoRow: FC<LogoRowSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);

  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;
  const backgroundColor = slice?.primary?.background_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(
        styles.logo_row__section,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: backgroundColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={styles.container}>
        {asText(slice?.primary?.header) && (
          <Heading
            className={styles.container__header}
            color={slice?.primary?.header_color as string}
            level={2}
          >
            {asText(slice?.primary?.header)}
          </Heading>
        )}
        <div className={styles.container__logos}>
          {filteredItems?.map((logo, index) => {
            return (
              <span className={styles.logo} key={index}>
                <PrismicLink field={logo?.link}>
                  <Image
                    alt={logo?.logo?.alt!}
                    fill
                    src={logo?.logo?.url!}
                    style={{ objectFit: 'contain' }}
                  />
                </PrismicLink>
              </span>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default LogoRow;
