import classNames from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';
import { Collapse } from '../../atoms/collapse';

const Accordion: FC<IAccordionProps> = ({
  children,
  isOpen,
  toggle,
  onMouseEnter,
  onMouseLeave,
  title,
  className,
  style,
  ...props
}) => {
  return (
    <div
      className={classNames('accordion', className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="button"
      style={style}
      {...props}
    >
      <div className="accordion__title" onClick={toggle} role="button">
        {title}
      </div>
      <Collapse className={undefined} isOpen={isOpen}>
        <div className="accordion__body">{children}</div>
      </Collapse>
    </div>
  );
};

interface IAccordionProps {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  title: string | ReactNode;
  style?: CSSProperties;
  // eslint-disable-next-line no-unused-vars
  toggle: (e: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default Accordion;
