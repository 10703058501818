import { useState } from 'react';
import { useSendContactEmail } from '../data';
import { Alert, Contact as ContactForm, Heading, Socials } from '../components';

function Contacting({ store }) {
  const [error, setError] = useState({
    isError: false,
    isOpen: false,
    message: '',
  });

  const { sendContactEmail } = useSendContactEmail({
    onCompleted: /*data*/ () => {
      setError({
        isError: false,
        isOpen: true,
        message:
          // eslint-disable-next-line max-len
          'Your message was sent successfully. We will get back to you as soon as possible.',
      });
    },
    onError: /*err*/ () => {
      setError({
        isError: true,
        isOpen: true,
        message: 'An error occurred. Please, try again later.',
      });
    },
  });

  const handleContact = ({ name, email, message }) =>
    sendContactEmail({
      variables: {
        input: {
          email,
          message,
          name,
        },
      },
    });

  return (
    <section className="contacting" id="contact">
      <figure className="contacting__image">
        {/* <img
                    className="lazyload"
                    disableLibraryParam={true}
                    src={image}
                    sizes="(max-width: 991px) 100%, 50%"
                    alt= "About the business"
                /> */}
        Put an image here...
      </figure>
      <div className="contacting__body">
        <Heading level={2}>Contact us</Heading>
        <p>Lorem ipsum dolor...</p>
        <Socials
          networks={{
            facebook: store.facebook,
            instagram: store.instagram,
            twitter: store.twitter,
            yelp: store.yelp,
            youtube: store.youtube,
          }}
        />
        <Alert
          error={error.isError}
          isOpen={error.isOpen}
          toggle={() => setError({ isOpen: false })}
        >
          {error.message}
        </Alert>
        <ContactForm onContact={handleContact} />
      </div>
    </section>
  );
}

export default Contacting;
