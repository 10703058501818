'use client';

import { FC, HTMLAttributes, useLayoutEffect } from 'react';
import classNames from 'classnames';

import { useFeaturedFlag, useIsServerSide } from '@/hooks';
import { useEcomStoreSelector } from '@/data';
import { EnumCustomerType as CustomerType, HeaderSlice } from '@/types';

import styles from './MedRecSwitch.module.scss';
import { useData } from '../DataProvider';

const MedRecSwitch = () => {
  const { isMedAndRecSelectionActive } = useFeaturedFlag();
  const { customer_type, line_items, setState } = useEcomStoreSelector([
    'customer_type',
    'line_items',
    'medical_id',
  ]);
  const { isServerSide } = useIsServerSide();

  const {
    header: { data: header },
  } = useData();

  const activeSlice = (header?.slices || []).find(
    (s: HeaderSlice) => s?.primary?.active,
  );

  useLayoutEffect(() => {
    if (isMedAndRecSelectionActive && customer_type === CustomerType.ALL) {
      setState({ customer_type: CustomerType.ADULT });
    }
  }, [isMedAndRecSelectionActive, customer_type]);

  if (!isMedAndRecSelectionActive) {
    return null;
  }

  const handleRecSelection = () => {
    if (line_items.length) {
      setState({ next_customer_type: CustomerType.ADULT });
      return;
    }
    setState({ customer_type: CustomerType.ADULT });
  };

  const handleMedSelection = () => {
    setState({ next_customer_type: CustomerType.MEDICAL });
  };

  return (
    <div
      className={classNames(styles.switch, {
        [styles.switch__alternate_header_variation]:
          activeSlice?.variation === 'withCenteredLogoAndLocatorFix',
      })}
    >
      <div className={classNames(styles.switch_container)}>
        {!isServerSide && (
          <div
            className={classNames(styles.switch__selected_background, {
              [styles.right_checked]: customer_type === CustomerType.MEDICAL,
              [styles.switch__selected_background__alternate_header_variation]:
                activeSlice?.variation === 'withCenteredLogoAndLocatorFix',
            })}
          />
        )}
        <SwitchButton
          onClick={handleRecSelection}
          selected={!isServerSide && customer_type === CustomerType.ADULT}
          text="REC"
        />
        <SwitchButton
          onClick={handleMedSelection}
          selected={!isServerSide && customer_type === CustomerType.MEDICAL}
          text="MED"
        />
      </div>
    </div>
  );
};

interface SwitchButtonProps extends HTMLAttributes<HTMLButtonElement> {
  text: string;
  selected: boolean;
}

const SwitchButton: FC<SwitchButtonProps> = ({ text, selected, ...props }) => {
  const {
    header: { data: header },
  } = useData();

  const activeSlice = (header?.slices || []).find(
    (s: HeaderSlice) => s?.primary?.active,
  );

  return (
    <button
      className={classNames(styles.switch__button, {
        [styles.switch__button_selected]: selected,
        [styles.switch__button__alternate_header_variation]:
          activeSlice?.variation === 'withCenteredLogoAndLocatorFix',
      })}
      {...props}
    >
      {text}
    </button>
  );
};

export { MedRecSwitch };
