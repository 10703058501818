import { gql } from '@apollo/client';

export const SUBSCRIBE_USER_MUTATION = gql`
  mutation subscribeUser(
    $account_id: String!
    $store_id: String!
    $input: SubscriptionInput!
  ) {
    subscribeUser(account_id: $account_id, store_id: $store_id, input: $input)
  }
`;
