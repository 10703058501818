import { FC } from 'react';

import { default as Input, MaskInputProps } from './InputMask';

const DateInput: FC<MaskInputProps> = ({
  placeholder = 'mm/dd/yyyy',
  ...props
}) => {
  return (
    <Input
      {...props}
      inputMode="numeric"
      mask="__/__/____"
      placeholder={placeholder}
      replacement={{ _: /\d/ }}
    />
  );
};

export { DateInput };
