import { compareAsc, format, formatRelative, set } from 'date-fns';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { Icon } from '../../components';
import { useEcomStore } from '../../data';
import style from './infoaddress.module.scss';

const InfoAddress = ({
  hideSpeed,
  hideMethod,
  hideAddress,
  abrAsap,
  className,
  isInvalid,
  feedback,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [storedState, setStoredState] = useEcomStore();

  const address = useMemo(
    () => storedState.delivery_address,
    [
      storedState.delivery_address.address1,
      storedState.delivery_address.address2,
    ],
  );
  const scheduleString = useMemo(() => {
    const schedule = new Date(
      storedState.delivery_details.deliver_after || null,
    );

    return compareAsc(
      schedule,
      set(new Date(), {
        milliseconds: 0,
        minutes: 0,
        seconds: 0,
      }),
    ) <= 0
      ? abrAsap
        ? 'ASAP'
        : 'As soon as possible'
      : capitalize(
          formatRelative(schedule, new Date()).replace(
            /at .+/g,
            `at ${format(schedule, 'h:mmaaa')}`,
          ),
        );
  }, [storedState.delivery_details.deliver_after]);

  return (
    <>
      <Icon name={'location'} />
      <p
        className={`${style.info} ${
          isInvalid ? style['info_is-invalid'] : ''
        } ${className ?? ''}`}
        {...props}
      >
        {!hideSpeed && <span>{scheduleString}</span>}
        {!hideMethod && (
          <span className={style.method}>
            {storedState.delivery_details.delivery ? 'Delivery' : 'Pickup'}
          </span>
        )}
        {!hideAddress && (
          <span className={style.address}>
            {/* <Icon name="location" /> */}
            {address.address1
              ? // ? `${address.address1}, ${address.city}, ${address.province} ${address.zip}, ${address.country_code}`
                `${address.address1}${
                  address.address2 ? ` ${address.address2}` : ''
                }, ${address.city}, ${address.province} ${address.zip ?? ''}, ${
                  address.country_code
                }`
              : `Select your ${
                  storedState.delivery_details.delivery ? 'delivery' : 'pickup'
                } address`}
          </span>
        )}
        {isInvalid && (
          <span className="input__feedback">
            <Icon name="info" /> {feedback}
          </span>
        )}
      </p>
    </>
  );
};
export default InfoAddress;
