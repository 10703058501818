import { FC } from 'react';
import classNames from 'classnames';
import style from './bannerfeaturedposts.module.scss';

const BannerFeaturePostsPlaceholder: FC<{ darkMode?: boolean }> = ({
  darkMode,
}) => {
  return (
    <div
      className={classNames(style.placeholder__blog_featured, {
        [style.placeholder__blog_featured_dark]: darkMode,
      })}
    >
      <figure className={style.placeholder__blog_banner} />
      <ol>
        {Array.from({ length: 8 }).map((_, index) => {
          return (
            <li key={index}>
              <figure />
              <div>
                <h5 />
                <p />
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default BannerFeaturePostsPlaceholder;
