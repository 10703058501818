import { Link, useData } from '@/components';
import { PrismicNextImage } from '@prismicio/next';
import { ImageField } from '@prismicio/client';

import style from './centeredlogo.module.scss';
import classNames from 'classnames';

const CenteredLogo = ({ className }) => {
  const { header, ageGate } = useData();

  const getValidLogo = (logos: ImageField[]) => {
    return logos.find(el => el?.url) ?? logos?.[logos?.length - 1];
  };

  const logo = getValidLogo([
    header?.data?.alternative_logo,
    ageGate?.data?.logo,
    header?.data?.logo,
  ]);

  return (
    <div className={classNames(style.container, className)}>
      <Link className={style.logo} href={'/'}>
        <figure className={style.logo__main}>
          <PrismicNextImage
            fallbackAlt=""
            field={logo}
            fill
            quality={100}
            style={{ objectFit: 'contain' }}
          />
        </figure>
      </Link>
    </div>
  );
};

export default CenteredLogo;
