import { FC } from 'react';
import classNames from 'classnames';

import { Container, Heading } from '@/components';
import { EmptyImageFieldImage, FilledImageFieldImage } from '@prismicio/client';
import { RichTextField } from '@prismicio/client';
import { getScaleColor, htmlSerializerWithProps } from '@/utils';
import { PrismicNextImage } from '@prismicio/next';
import style from './aboutbrand.module.scss';
import { PrismicRichText } from '@prismicio/react';

const AboutBrand: FC<IAboutBrand> = ({
  infoBrand,
  logo,
  colorBrandInfo,
  colorLine,
  logoBrandColor,
  brandSectionTitleColor,
  itemsPerChunk,
}) => {
  const getFirstColumn = () => {
    if (infoBrand?.length) {
      if (itemsPerChunk && itemsPerChunk <= infoBrand?.length) {
        return infoBrand?.slice(0, itemsPerChunk);
      }
      return infoBrand?.slice(0, Math.floor(infoBrand?.length / 2));
    }

    return [];
  };

  const getSecondColumn = () => {
    if (infoBrand?.length) {
      if (itemsPerChunk && itemsPerChunk <= infoBrand?.length) {
        return infoBrand?.slice(itemsPerChunk, infoBrand?.length);
      }
      return infoBrand?.slice(
        Math.floor(infoBrand?.length / 2),
        infoBrand?.length,
      );
    }

    return [];
  };

  return (
    <Container as="section" className={style.aboutbrand}>
      <Heading
        className={style.aboutbrand__title}
        level={2}
        style={{ color: brandSectionTitleColor }}
      >
        About The Brand
      </Heading>
      <div
        className={classNames(style.aboutbrand__container)}
        style={{ borderTop: colorLine ? `2px solid ${colorLine}` : undefined }}
      >
        <div className={style.aboutbrand__left}>
          <figure>
            <PrismicNextImage
              fallbackAlt=""
              field={logo}
              height={logo?.dimensions?.height}
              style={{
                filter: logoBrandColor
                  ? `grayscale(${getScaleColor(logoBrandColor)}%)`
                  : undefined,
              }}
              width={logo?.dimensions?.width}
            />
          </figure>
          {getFirstColumn() && (
            <PrismicRichText
              components={htmlSerializerWithProps({ color: colorBrandInfo })}
              field={getFirstColumn() as RichTextField}
            />
          )}
        </div>
        {getSecondColumn() && (
          <div className={style.aboutbrand__right}>
            <PrismicRichText
              components={htmlSerializerWithProps({ color: colorBrandInfo })}
              field={getSecondColumn() as RichTextField}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default AboutBrand;

interface IAboutBrand {
  logo: EmptyImageFieldImage | FilledImageFieldImage;
  logoBrandColor?: string;
  brandSectionTitleColor?: string;
  colorBrandInfo?: string;
  colorLine?: string;
  infoBrand?: RichTextField;
  itemsPerChunk?: number;
}
