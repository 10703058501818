import { CSSProperties, FC, useState } from 'react';
import { useForm, useSendContactEmail, useValidator } from '@/data';
import { useNotify } from '@/hooks';
import { IMarkerStyle } from '@/components/Maps/Map';
import {
  addressToSlug,
  convertHexadecimalFormatToRGB,
  htmlSerializerWithProps,
} from '@/utils';
import { formatEnUSPhoneNumber } from '@/utils';
import {
  ContactUsSliceDefault,
  ContactUsSliceFormCentered,
  ContactUsSliceFormSideBySide,
  ContactUsSliceIconsRow,
  ContactUsSliceMapLeft,
  ContactUsSliceMapTop,
} from '@/types';
import { Button, Heading, Icon, Input, Link, Maps } from '@/components';
import classNames from 'classnames';
import styles from './contactus.module.scss';
import { asText } from '@prismicio/helpers';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';

const ContactUsForm: FC<
  | ContactUsSliceDefault
  | ContactUsSliceMapLeft
  | ContactUsSliceMapTop
  | ContactUsSliceFormCentered
  | ContactUsSliceFormSideBySide
> = slice => {
  const { notify } = useNotify();
  const { result, sendContactEmail } = useSendContactEmail();
  const { inputs, inputChange, setInputs } = useForm();
  const { validator, setIsSubmited, isInvalid } = useValidator(
    [
      {
        field: 'email',
        message: 'Email is required.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'email',
        message: 'The email format is incorrect.',
        method: 'isEmail',
        validWhen: true,
      },
      {
        field: 'message',
        message: 'Message is required.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        args: [{ min: 1 }],
        field: 'message',
        message: 'The message is too short',
        method: 'isLength',
        validWhen: true,
      },
      {
        field: 'name',
        message: 'Name is required.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        args: [{ min: 1 }],
        field: 'name',
        message: 'The name is not correct',
        method: 'isLength',
        validWhen: true,
      },
    ],
    inputs,
  );
  const [isLoading, setIsLoading] = useState(false);
  const mapVariation =
    slice?.variation === 'default' || slice?.variation === 'mapLeft';

  const handleAction = () => {
    setIsSubmited(true);
    setIsLoading(true);

    if (isInvalid) {
      setIsLoading(false);
      return;
    }

    try {
      sendContactEmail({
        variables: {
          input: inputs,
        },
      });

      if (result?.error) {
        notify('error', 'Message not sent');
      }

      if (!result?.error) {
        notify('success', 'Message sent successfully');
      }
    } catch (error) {
      notify('error', `${error}`);
    }

    setIsSubmited(false);
    setIsLoading(false);
    setInputs({});
  };

  return (
    <div
      className={classNames(styles.contactus__form, {
        [styles.mapleft__form]: slice.variation === 'mapLeft',
        [styles.mapdefault__form]: slice.variation === 'default',
      })}
    >
      {mapVariation && <ContactUsHeader {...slice} />}
      <div
        className={classNames(styles.contactus__form__names, {
          [styles['contactus__form__names--mapTop']]:
            slice.variation === 'mapTop' ||
            slice.variation === 'formCentered' ||
            slice.variation === 'formSideBySide',
        })}
      >
        <Input
          feedback={validator.name.message}
          inputLabel="Name"
          isInvalid={validator.name.isInvalid}
          name="name"
          onChange={inputChange}
          placeholder="Name"
          required
          value={inputs.name ?? ''}
        />
        <Input
          feedback={validator.email.message}
          inputLabel="Email"
          isInvalid={validator.email.isInvalid}
          name="email"
          onChange={inputChange}
          placeholder="Email"
          required
          value={inputs.email ?? ''}
        />
      </div>
      <Input
        feedback={validator.message.message}
        inputLabel="Message"
        isInvalid={validator.message.isInvalid}
        name="message"
        onChange={inputChange}
        placeholder="Message"
        required
        rows={7}
        type="textarea"
        value={inputs.message ?? ''}
      />
      <Button
        className={classNames(styles.contactus__form__action, {
          [styles.maptop__form__action]: slice.variation === 'mapTop',
          [styles.sidebyside__form__action]:
            slice.variation === 'formSideBySide',
          [styles.default__form__action]:
            slice.variation === 'default' || slice.variation === 'mapLeft',
        })}
        color={slice?.primary?.button_bg_color}
        loading={isLoading}
        onClick={handleAction}
      >
        {slice?.primary?.button_text}
      </Button>
    </div>
  );
};

const ContactUsHeader: FC<
  | ContactUsSliceDefault
  | ContactUsSliceMapLeft
  | ContactUsSliceMapTop
  | ContactUsSliceIconsRow
  | ContactUsSliceFormCentered
  | ContactUsSliceFormSideBySide
> = slice => {
  const mapVariation =
    slice?.variation === 'mapLeft' || slice?.variation === 'default';
  if (!asText(slice?.primary?.title) && !asText(slice?.primary?.description)) {
    return;
  }

  return (
    <div
      className={classNames(styles.contactus__header, {
        [styles.contactus__header_sidebyside]:
          slice?.variation === 'formSideBySide',
        [styles.contactus__header_maptop]: slice?.variation === 'mapTop',
        [styles.contactus__header_mapleft]: mapVariation,
      })}
    >
      <Heading
        className={styles.heading}
        level={3}
        style={
          {
            color:
              slice?.variation === 'mapTop' || mapVariation
                ? slice?.primary?.title_color
                : slice?.primary?.primary_text_color,
          } as CSSProperties
        }
      >
        {asText(slice?.primary?.title)}
      </Heading>
      {slice?.variation !== 'iconsRow' && (
        <PrismicRichText
          components={htmlSerializerWithProps({
            color:
              slice?.variation === 'mapTop' || mapVariation
                ? slice?.primary?.description_color
                : slice?.primary?.secondary_text_color,
          })}
          field={slice?.primary?.description}
        />
      )}
    </div>
  );
};

const ContactUsMap: FC<
  ContactUsSliceDefault | ContactUsSliceMapLeft | ContactUsSliceMapTop
> = slice => {
  const mapAllVariation =
    slice?.variation === 'mapLeft' ||
    slice?.variation === 'mapTop' ||
    slice?.variation === 'default';
  const colorDefault = '#ea4335';
  const prismicPhoneField = slice?.primary?.phone_number ?? '';
  const mapOverlayMode =
    mapAllVariation && slice?.primary?.map_style === 'dark';
  const mapDarkMode = mapAllVariation && slice?.primary?.map_style === 'gray';
  const pinColor = slice?.primary?.pin_color;
  const customMarker = slice?.primary?.custom_pink;
  const applyFilter =
    mapAllVariation && slice?.primary?.info_blur
      ? slice?.primary?.info_blur
      : null;
  const backdrop = applyFilter ? `blur(${applyFilter}px)` : undefined;
  const primaryColor = slice?.primary?.primary_text_color;
  const secondaryColor = slice?.primary?.secondary_text_color;
  const emailColor = slice?.primary?.email_color;
  const mapVariation =
    slice.variation === 'mapLeft' || slice.variation === 'default';

  const mapStyleProps: IMarkerStyle = {
    titleColor: pinColor ?? colorDefault,
    deaultMarkerColor: pinColor ?? colorDefault,
  };

  const getBackground = () => {
    const background = slice?.primary?.info_background;
    const opacity = slice?.primary?.info_opacity;
    if (opacity && background) {
      const rgb = convertHexadecimalFormatToRGB(background);

      return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${opacity})`;
    }

    if (background) {
      return background;
    }

    return undefined;
  };

  return (
    <div
      className={classNames(styles.contactus__map, {
        [styles.mapleft__map]: slice.variation === 'mapLeft',
        [styles.mapdefault__map]: slice.variation === 'default',
      })}
    >
      <Maps
        centered={mapVariation ? 'left' : 'SE'}
        className={classNames(styles.contactus__map__maps, {
          [styles.contactus__map__maps_left]: mapVariation,
          [styles.contactus__map__maps_default]: slice.variation === 'default',
        })}
        darkMode={mapDarkMode}
        defaultMarker={customMarker?.url as string}
        hideInterestingPoints={true}
        locations={[
          {
            label: slice?.primary?.full_address ?? '',
            lat: slice?.primary?.location?.latitude,
            lng: slice?.primary?.location?.longitude,
            title: prismicPhoneField,
          },
        ]}
        markerStyle={mapStyleProps}
        openInfo={false}
        overlay={mapOverlayMode}
        showTitle={false}
        zoom={12}
      />
      <address
        className={classNames(
          'contactus__map__address',
          styles.contactus__map__address,
          {
            [styles.mapleft__map__address]: slice.variation === 'mapLeft',
          },
          {
            [styles.mapdefault__map__address]: slice.variation === 'default',
          },
          {
            [styles.maptop__map__address]: slice.variation === 'mapTop',
          },
        )}
      >
        <div>
          <Icon name="direction" />
          <span>
            <p className="contactus__map__address_title">Address</p>
            <p
              className={classNames(
                styles.contactus__map__address__dir,
                'contactus__map__address_data',
              )}
            >
              {slice?.primary?.full_address}
            </p>
          </span>
        </div>
        <div>
          <Icon name="email" style={{ fontSize: '18px' }} />
          <span>
            <p className="contactus__map__address_title">Email</p>
            <p
              className={classNames(
                styles.contactus__map__address__email,
                'contactus__map__address_email',
              )}
            >
              {slice?.primary?.email}
            </p>
          </span>
        </div>
        <div>
          <Icon name="phone-solid" />
          <span>
            <p className="contactus__map__address_title">Phone number</p>
            {prismicPhoneField?.includes('|') ? (
              prismicPhoneField?.split('|').map(phone => (
                <Link href={`tel:${formatEnUSPhoneNumber(phone)}`} key={phone}>
                  <p
                    className={classNames(
                      styles.contactus__map__address__dir,
                      'contactus__map__address_data',
                    )}
                  >
                    {phone}
                  </p>
                </Link>
              ))
            ) : (
              <Link href={`tel:${prismicPhoneField}`}>
                <p
                  className={classNames(
                    styles.contactus__map__address__dir,
                    'contactus__map__address_data',
                  )}
                >
                  {prismicPhoneField}
                </p>
              </Link>
            )}
          </span>
        </div>
      </address>
      <style>{`
            .contactus__map__address{                
              background: ${getBackground() as string};
              backdrop-filter: ${backdrop};

              .contactus__map__address_title {
                color: ${primaryColor} !important;
              }

              .contactus__map__address_data {
                color: ${secondaryColor} !important;
              }
              
              .contactus__map__address_email {
                color: ${emailColor} !important;
              }

              i {
                color: ${primaryColor}; 
              }
            }
          `}</style>
    </div>
  );
};

const IconsRow: FC<
  | ContactUsSliceIconsRow
  | ContactUsSliceFormCentered
  | ContactUsSliceFormSideBySide
> = slice => {
  const primary = slice?.primary;
  const rowVariation = slice?.variation === 'iconsRow';
  const sideBySideVariation = slice?.variation === 'formSideBySide';
  const formCenteredVariation = slice?.variation === 'formCentered';
  const withHeading =
    slice?.variation === 'iconsRow' || slice?.variation === 'formSideBySide';
  const addressIcon = (slice as ContactUsSliceIconsRow)?.primary?.address_icon;
  const emailIcon = (slice as ContactUsSliceIconsRow)?.primary?.email_icon;
  const phoneIcon = (slice as ContactUsSliceIconsRow)?.primary?.phone_icon;
  const dividerColor =
    (slice as ContactUsSliceFormCentered)?.primary?.divider_color || null;

  const renderSocials = () => {
    return (
      <div
        className={classNames(styles.icon_row__networks__items, {
          [styles['icon_row__networks__items--extended']]: sideBySideVariation,
        })}
      >
        {slice?.items?.map((item, index) => (
          <Link
            className={styles.socials__item}
            href={item?.link as string}
            key={index}
            rel="noopener noreferrer"
            target="_blank"
          >
            <figure>
              <PrismicNextImage
                fallbackAlt=""
                field={item.social_network}
                fill
                sizes="24px"
                style={{
                  objectFit: 'contain',
                }}
              />
            </figure>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.icon_row_section, {
        [styles['icon_row_section--form']]: !rowVariation,
        [styles['icon_row_section--extended']]: sideBySideVariation,
      })}
      style={{
        borderTop:
          !rowVariation && dividerColor
            ? `1px solid ${dividerColor}`
            : undefined,
      }}
    >
      <address
        className={classNames(styles.icon_row, {
          [styles['icon_row--form']]: !rowVariation,
          [styles['icon_row--compact']]: sideBySideVariation,
          [styles['icon_row--icons']]: rowVariation,
        })}
        style={{
          borderBottom:
            rowVariation && dividerColor
              ? `1px solid ${dividerColor}`
              : undefined,
        }}
      >
        {primary?.full_address && (
          <div
            className={classNames(styles.icon_row__content, {
              [styles['icon_row__content--full']]: sideBySideVariation,
              [styles['icon_row__content--form-centered']]:
                slice.variation === 'formCentered',
            })}
          >
            {rowVariation && addressIcon?.url && (
              <figure className={styles.icon_row__content__icon}>
                <PrismicNextImage fallbackAlt="" field={addressIcon} fill />
              </figure>
            )}
            {withHeading && (
              <Heading
                className={classNames(styles.heading, {
                  [styles['heading--full']]: sideBySideVariation,
                })}
                level={4}
                style={{ color: primary?.primary_text_color } as CSSProperties}
              >
                Address
              </Heading>
            )}
            <Link
              className={classNames(styles.link, {
                [styles['link--full']]: !rowVariation,
              })}
              href={`https://www.google.com/maps/dir/?api=1&query=${addressToSlug(
                primary?.full_address,
              )}`}
              style={{ color: primary?.secondary_text_color } as CSSProperties}
              target="_blank"
            >
              {primary?.full_address}
            </Link>
          </div>
        )}
        {primary?.email && (
          <div
            className={classNames(styles.icon_row__content, {
              [styles['icon_row__content--full']]: sideBySideVariation,
            })}
          >
            {rowVariation && emailIcon?.url && (
              <figure className={styles.icon_row__content__icon}>
                <PrismicNextImage fallbackAlt="" field={emailIcon} fill />
              </figure>
            )}
            {withHeading && (
              <Heading
                className={classNames(styles.heading, {
                  [styles['heading--full']]: sideBySideVariation,
                })}
                level={4}
                style={{ color: primary?.primary_text_color } as CSSProperties}
              >
                Email
              </Heading>
            )}
            <Link
              className={classNames(styles.link, {
                [styles['link--full']]: !rowVariation,
              })}
              href={`mailto:${primary?.email}`}
              style={{ color: primary?.email_color } as CSSProperties}
            >
              {primary?.email}
            </Link>
          </div>
        )}
        {primary?.phone_number && (
          <div
            className={classNames(styles.icon_row__content, {
              [styles['icon_row__content--full']]: sideBySideVariation,
            })}
          >
            {rowVariation && phoneIcon?.url && (
              <figure className={styles.icon_row__content__icon}>
                <PrismicNextImage fallbackAlt="" field={phoneIcon} fill />
              </figure>
            )}
            {withHeading && (
              <Heading
                className={classNames(styles.heading, {
                  [styles['heading--full']]: sideBySideVariation,
                })}
                level={4}
                style={{ color: primary?.primary_text_color } as CSSProperties}
              >
                Phone Number
              </Heading>
            )}
            <Link
              className={classNames(styles.link, {
                [styles['link--full']]: !rowVariation,
              })}
              href={`tel:${primary?.phone_number}`}
              style={{ color: primary?.secondary_text_color } as CSSProperties}
            >
              {primary?.phone_number}
            </Link>
          </div>
        )}
        {sideBySideVariation && (
          <div
            className={classNames(styles.icon_row__content, {
              [styles['icon_row__content--full']]: sideBySideVariation,
            })}
          >
            <Heading
              className={classNames(styles.heading, styles['heading--full'])}
              level={4}
              style={{ color: primary?.primary_text_color } as CSSProperties}
            >
              Social
            </Heading>
            {renderSocials()}
          </div>
        )}
      </address>
      {rowVariation && !sideBySideVariation && (
        <div
          className={classNames(styles.icon_row__description, {
            [styles['icon_row__description--small']]: !rowVariation,
            [styles['icon_row__description--full']]: sideBySideVariation,
          })}
        >
          {rowVariation && (
            <p
              style={{ color: primary?.secondary_text_color } as CSSProperties}
            >
              {asText(primary.description)}
            </p>
          )}
          <div
            className={classNames(styles.icon_row__networks, {
              [styles['icon_row__networks--flex']]: sideBySideVariation,
              [styles['icon_row__networks--compact']]: formCenteredVariation,
            })}
          >
            {!sideBySideVariation && renderSocials()}
          </div>
        </div>
      )}
    </div>
  );
};

export { ContactUsForm, ContactUsMap, IconsRow, ContactUsHeader };
