import { FC, ReactNode, RefObject, useRef } from 'react';

import classNames from 'classnames';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { Heading, Icon } from '@/components';

import style from './tabreview.module.scss';

interface IClose {
  close: (
    // eslint-disable-next-line no-unused-vars
    focusableElement?: HTMLElement | RefObject<HTMLElement>,
  ) => void;
}

interface ISelectProps {
  children: (_close: IClose) => ReactNode;
  title: string;
  text: string;
  icon?: ReactNode;
  className?: string;
  filteractive?: boolean;
}

const Select: FC<ISelectProps> = ({
  children,
  title,
  text,
  icon,
  className,
  filteractive,
}) => {
  const button = useRef<HTMLButtonElement>(null);
  const panel = useRef<HTMLDivElement>(null);
  const { styles, attributes } = usePopper(button.current, panel.current, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
    ],
    placement: 'bottom-start',
  });

  return (
    <Popover className={style.filters__item}>
      <PopoverButton
        className={classNames(
          style['filters__item-button'],
          { className: className || false },
          { [style['filters__item-button_active']]: filteractive },
        )}
        ref={button}
      >
        {icon}
        {title}
        <Icon name="arrow_drop" />
      </PopoverButton>
      <PopoverPanel
        className={style['filters__item-panel']}
        ref={panel}
        style={styles.popper}
        {...attributes.popper}
      >
        {({ close }) => (
          <>
            <Heading className={style.filters__title_panel_list} level={6}>
              {text}
            </Heading>
            <div
              className={classNames(style['filters__item-panel-content'], {
                className: className || false,
              })}
            >
              {children({ close })}
            </div>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
};

export default Select;
