import { LIST_STORE_APPS_QUERY } from './schema';

export async function listStoreApps(client, options = {}) {
  const response = await client.query({
    query: LIST_STORE_APPS_QUERY,
    ...options,
  });

  return {
    data: response.data?.listStoreApps,
    error: response.error,
  };
}

export function getAppsByCategory(apps, category) {
  return apps.filter(app => app.category === category);
}

export function getAppByHandler(apps, handlerName) {
  return apps.find(app => app.handler === handlerName);
}
