import { useEcomStore } from '@/data';

const useDeliveryDetails = () => {
  const [storedState] = useEcomStore();

  const { delivery_details } = storedState;

  return { ...delivery_details };
};

export { useDeliveryDetails };
