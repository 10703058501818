import { findIndex } from 'lodash';
import { useData } from '@/components';

type TAttribute = 'deals' | 'want' | 'type';

export interface IOptions {
  count: number;
  value: string;
}

const useStoreList = (attribute: TAttribute) => {
  const { treezStores } = useData();
  const options = [] as IOptions[];

  const pushOptions = (value: string) => {
    options.push({ count: 1, value: value });
  };

  const updateOptions = (index: number) => {
    options[index] = {
      ...options[index],
      count: options[index].count + 1,
    };
  };

  switch (attribute) {
    case 'deals': {
      treezStores.map(store => {
        store?.current_deals?.map(deals => {
          if (deals) {
            const index = findIndex(options, o => o.value === deals);
            if (index === -1) {
              pushOptions(deals);
              return;
            }
            updateOptions(index);
          }
        });
      });
      break;
    }
    case 'type': {
      treezStores.map(store => {
        if (store.medical) {
          const index = findIndex(options, o => o.value === 'Medical');
          if (index === -1) {
            pushOptions('Medical');
            return;
          }
          updateOptions(index);
        }

        if (store.recreational) {
          const index = findIndex(options, o => o.value === 'Recreational');
          if (index === -1) {
            pushOptions('Recreational');
            return;
          }
          updateOptions(index);
        }
      });
      break;
    }
    case 'want': {
      treezStores.map(store => {
        let index = findIndex(options, o => o.value === store.open_now);
        if (store?.open_now === 'Open now' && index === -1) {
          pushOptions(store.open_now);
        }
        if (index !== -1) {
          updateOptions(index);
        }
        if (store?.delivery) {
          index = findIndex(options, o => o.value === 'Delivery');
          if (index !== -1) {
            updateOptions(index);
          }
          if (index === -1) {
            pushOptions('Delivery');
          }
        }
        if (store?.pickup) {
          index = findIndex(options, o => o.value === 'Pickup');
          if (index !== -1) {
            updateOptions(index);
          }
          if (index === -1) {
            pushOptions('Pickup');
          }
        }
      });
      break;
    }
    default:
      break;
  }

  return options;
};

export default useStoreList;
