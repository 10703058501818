import { FC } from 'react';
import { CitiesSlice } from '@/types';
import { CitiesCarousel, CitiesGrid } from './Cities.components';

const Cities: FC<CitiesSlice> = slice => {
  if (!slice?.primary?.active) {
    return null;
  }

  if (slice?.primary?.allow_search) {
    return <CitiesGrid {...slice} />;
  }
  return <CitiesCarousel {...slice} />;
};

export default Cities;
