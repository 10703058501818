import { FC, ReactEventHandler, useEffect, useState } from 'react';
import NextImage, { ImageProps } from 'next/image';
import classNames from 'classnames';

import style from '../styles/image.module.scss';

const Image: FC<IImageProps> = ({ className, src, fallback, ...props }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
  }, [src]);

  return (
    <NextImage
      className={classNames(`${style.image}`, className)}
      onError={setError as unknown as ReactEventHandler<HTMLImageElement>}
      onErrorCapture={
        setError as unknown as ReactEventHandler<HTMLImageElement>
      }
      src={error ? (fallback as string) : src}
      {...props}
    />
  );
};

interface IImageProps extends ImageProps {
  className?: string;
  fallback?: string;
}

export default Image;
