import { useEcomStore, useUpdateCart as useUpdateServerCart } from '../data';
import { omit } from 'lodash';
import { EcomState } from 'types/state';

const useUpdateCart = () => {
  const [state] = useEcomStore();
  const { updateCart } = useUpdateServerCart();

  return async newState => {
    const _state: EcomState = newState ?? state;

    return await updateCart({
      variables: {
        input: {
          order: {
            ...omit(_state, [
              'terms_accepted',
              'terms_accepted_date',
              'addresses',
              'next_store_id',
              'is_crm_modal_open',
              'next_shop_mode',
              'terms_accepted_date',
              'shipping_address.__typename',
              'billing_address.__typename',
              'delivery_details.__typename',
              'berbix_verified',
              'berbix_error',
              'berbix_id',
              'idscan_attempts',
              'checkout_identity_verification',
              '__typename',
            ]),
            discount_items: _state.discount_items?.map(discount =>
              omit(discount, ['__typename']),
            ) as any,
            line_items: _state.line_items.map(lineItem => ({
              brand: lineItem.brand_name,
              category: lineItem.category,
              id: lineItem?.id?.toString(),
              item_id: lineItem?.id?.toString(),
              name: lineItem.name,
              pos_id: lineItem.weightVariant.pos_id,
              price: lineItem.weightVariant.price,
              quantity: lineItem.quantity,
              sale_price: lineItem.sales_price,
              thumbnail: lineItem.asset?.image,
              variants: [
                {
                  id: lineItem?.weightVariant?.id?.toString(),
                  name: lineItem.weightVariant.name,
                  price: getVariantPrice(
                    lineItem?.weightVariant?.salePrice!,
                    lineItem?.weightVariant?.price!,
                  ),
                  type: lineItem.weightVariant.type,
                },
              ],
            })),
          },
        },
      },
    });
  };
};

const getVariantPrice = (salePrice: number, price: number): number => {
  if (!salePrice) {
    return price;
  }

  if (salePrice > price) {
    return price;
  }

  return salePrice;
};

export default useUpdateCart;
