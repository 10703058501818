import { gql } from '@apollo/client';

export const SUBSCRIBE_TO_CRM_MUTATION = gql`
  mutation subscribeToCRM(
    $account_id: String!
    $store_id: String!
    $input: SubscribeCRMInput!
  ) {
    subscribeToCRM(account_id: $account_id, store_id: $store_id, input: $input)
  }
`;
