import { useMemo } from 'react';
import { useSelectedStore } from '@/hooks';

/**
 * Hook to filter the items of a slice by the location_slug.
 */
const useFilteredSliceItemsByLocationSlug = items => {
  const { store } = useSelectedStore();

  const filteredItems = useMemo(() => {
    if (!Array.isArray(items)) return [];

    return items.filter(item => {
      if (!item?.location_slug || !store?.url_slug) return true;
      const slugs = item.location_slug.split(',').map(slug => slug.trim());
      return slugs?.includes(store?.url_slug);
    });
  }, [items, store]);

  return { filteredItems };
};

const useShowByLocationSlug = location_slug => {
  const { store } = useSelectedStore();

  const showContent = useMemo(() => {
    if (!location_slug || !store?.url_slug) return true;

    const slugs = location_slug.split(',').map(slug => slug.trim());

    return slugs?.includes(store?.url_slug);
  }, [location_slug, store]);

  return { showContent };
};

export { useFilteredSliceItemsByLocationSlug, useShowByLocationSlug };
