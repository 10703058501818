import {
  GET_BEST_SELLING_PRODUCTS_QUERY,
  GET_PRODUCT_BY_URL_QUERY,
  GET_PRODUCT_REVIEW_FROM_JANE_QUERY,
  LIST_PRODUCTS_QUERY,
  QUERY_GET_PRODUCT_FROM_JANE,
  SEARCH_PRODUCT_QUERY,
} from './schema';

export async function listProducts(client, options = {}) {
  const response = await client.query({
    query: LIST_PRODUCTS_QUERY,
    ...options,
  });

  return {
    data: response.data?.listProducts,
    error: response.error,
  };
}

export async function searchProduct(client, options = {}) {
  const response = await client.query({
    query: SEARCH_PRODUCT_QUERY,
    ...options,
  });

  return {
    data: response.data?.searchProduct,
    error: response.error,
  };
}

export async function getProductByPath(client, options = {}) {
  const response = await client.query({
    query: GET_PRODUCT_BY_URL_QUERY,
    ...options,
  });

  return {
    data: response.data?.getProductByPath,
    error: response.error,
  };
}

export async function getBestSellingProducts(client, options = {}) {
  const response = await client.query({
    query: GET_BEST_SELLING_PRODUCTS_QUERY,
    ...options,
  });

  return {
    data: response.data?.getBestSellingProducts,
    error: response.error,
  };
}

export async function getProductReviewFromJane(client, options = {}) {
  const response = await client.query({
    query: GET_PRODUCT_REVIEW_FROM_JANE_QUERY,
    ...options,
  });

  return {
    data: response.data?.getProductReviewFromJane,
    error: response.error,
  };
}

export async function getGCProductFromJane(client, options = {}) {
  const response = await client.query({
    query: QUERY_GET_PRODUCT_FROM_JANE,
    ...options,
  });

  return {
    data: response.data?.getProductFromJane,
    error: response.error,
  };
}
