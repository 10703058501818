import { FC } from 'react';
import classNames from 'classnames';

import { Heading, IconSvg } from '@/components';
import { LabResult } from '@/types';
import { CBD_COMPOUNDS, TERPENES, THC_COMPOUNDS } from '@/constants';

interface ILabResultsProps {
  labResults: LabResult[];
}

const LabResults: FC<ILabResultsProps> = ({ labResults }) => {
  if (!labResults || labResults.length === 0) {
    return null;
  }

  const allTerpenesAvailables = TERPENES.map(e => e.labId);
  const terpenes = labResults
    .filter(res => allTerpenesAvailables.includes(res.result_type))
    .sort(
      (a, b) =>
        parseFloat(b.amount.maximum_value) - parseFloat(a.amount.maximum_value),
    );

  const cannabinoids = labResults.filter(
    res => !allTerpenesAvailables.includes(res.result_type),
  );

  const cbdCompounds = CBD_COMPOUNDS.map(comp => {
    const cannabinoid = cannabinoids.find(c => c.result_type === comp);

    if (cannabinoid) {
      return cannabinoid;
    }
  }).filter(element => element !== undefined);

  const thcCompounds = THC_COMPOUNDS.map(comp => {
    const cannabinoid = cannabinoids.find(c => c.result_type === comp);

    if (cannabinoid) {
      return cannabinoid;
    }
  }).filter(element => element !== undefined);

  const onlyThcValue =
    thcCompounds?.length === 1 &&
    thcCompounds?.[0] &&
    thcCompounds?.[0].result_type === 'THC';
  const onlyCbdValue =
    cbdCompounds?.length === 1 &&
    cbdCompounds?.[0] &&
    cbdCompounds?.[0].result_type === 'CBD';

  const CannabinoidCompound = ({ compound, title }) => {
    if (
      !compound ||
      compound.length === 0 ||
      (title === 'THC' && onlyThcValue) ||
      (title === 'CBD' && onlyCbdValue)
    ) {
      return null;
    }

    return (
      <div className="cannabinoid_compound_section">
        <Heading className="list_title" level={3}>
          {title}
        </Heading>
        <div className="cannabinoid_list">
          {compound.map(c => (
            <div className="cannabinoid_item" key={c.result_type}>
              <p className="cannabinoid_name">{c.result_type}</p>
              <p className="cannabinoid_value">
                {c.amount.maximum_value}
                {c.amount_type === 'PERCENTAGE' && '%'}
                {c.amount_type === 'MG' && 'mg'}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const existsCbdCompounds = cbdCompounds && cbdCompounds.length > 0;
  const existsThcCompounds = thcCompounds && thcCompounds.length > 0;
  const existsTerpenes = terpenes && terpenes.length > 0;

  const oneElement =
    (!existsCbdCompounds && !existsThcCompounds) || !existsTerpenes;

  return (
    <div
      className={classNames('lab_results', {
        ['one_element']: oneElement,
      })}
    >
      {existsTerpenes && (
        <div className="terpenes">
          <Heading className="details__h3" level={3}>
            Terpenes
          </Heading>
          <div className={'terpene_list'}>
            {terpenes.map(t => {
              const terpene = TERPENES.find(ter => ter.labId === t.result_type);

              if (!terpene) {
                return null;
              }

              return (
                <div className="terpene_item" key={terpene.labId}>
                  {terpene.icon && (
                    <figure>
                      <IconSvg name={terpene.icon} />
                    </figure>
                  )}
                  <div className="terpene_info">
                    <div className="terpene_header">
                      <span className="terpene_name">{terpene.name}</span>
                      <span className="terpene_percentage">
                        {t.amount.maximum_value}{' '}
                        {t.amount_type === 'PERCENTAGE' && '%'}
                      </span>
                    </div>
                    {terpene?.aroma && terpene?.effects && (
                      <div className="terpene_description">
                        <span className="terpene_aroma">{terpene.aroma}: </span>
                        {terpene.effects}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {(existsCbdCompounds || existsThcCompounds) &&
        !(onlyThcValue || onlyCbdValue) && (
          <div className="cannabinoids">
            <Heading className="details__h3" level={3}>
              Cannabinoids
            </Heading>
            <div
              className={classNames('cannabinoids__container', {
                ['single_element']: !existsCbdCompounds || !existsThcCompounds,
              })}
            >
              <CannabinoidCompound compound={thcCompounds} title="THC" />
              <CannabinoidCompound compound={cbdCompounds} title="CBD" />
            </div>
          </div>
        )}
    </div>
  );
};

export { LabResults };
