import { useEffect, useMemo } from 'react';

import { useEcomStore } from '@/data';
import { formatWeightNumber, getCartItemsSubtotal } from '@/utils';
import { useIsServerSide } from '@/hooks';
import { initialEcomState } from '@/data';

import useProducts from './useProducts';

function useSummary() {
  const [data, setEcomState] = useEcomStore();
  const { isServerSide } = useIsServerSide();
  const ecomState = isServerSide ? initialEcomState : data;
  const { products } = useProducts();
  const gramsLimit = parseInt(process.env.NEXT_PUBLIC_GR_LIMIT);

  const subtotal = getCartItemsSubtotal(products);

  const preDiscountSubtotal = products.reduce((sum, p) => {
    const v = p?.weightVariant;

    return sum + v?.price * p?.quantity;
  }, 0);

  const totalGrams = useMemo(
    () =>
      products.reduce(
        (result, { weightVariant, quantity }) =>
          result + formatWeightNumber(weightVariant) * quantity,
        0,
      ),
    [products],
  );

  useEffect(() => {
    if (!products.length) {
      setEcomState({ total_tax: 0, total_price: 0, subtotal_price: 0 });
    }
  }, [products.length]);

  return {
    delivery: ecomState.total_delivery,
    discounts: ecomState.total_discounts,
    gramsLimit,
    moreAviability: ecomState.subtotal_price <= gramsLimit,
    subtotal,
    preDiscountSubtotal,
    serverSubtotal: ecomState.subtotal_price,
    tax: ecomState.total_tax,
    total: ecomState.total_price,
    totalGrams,
  };
}

export default useSummary;
