import { FC } from 'react';

import { Container } from '@/components';
import { CustomWidthSideBySideSlice } from '@/types';

import style from './customwidthsidebyside.module.scss';
import classNames from 'classnames';
import { PrismicRichText } from '@prismicio/react';

import * as prismicH from '@prismicio/helpers';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';

const CustomWidthSideBySide: FC<CustomWidthSideBySideSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const backgroundColor = slice?.primary?.background_color ?? '';
  const sections = slice?.items;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <>
      {sections.map((section, id) => {
        const color = section?.font_color;
        const horizontalBorder = section?.horizontal_border;
        const verticalBorder = section?.vertical_border;
        const leftWidth = section?.left_content_width_percentage;
        const leftContent = prismicH.asText(section?.left_content);
        const bothBorders = horizontalBorder && verticalBorder ? '8px' : '0px';
        const image = section.left_background_image?.url;

        return (
          <section
            className={classNames(
              style.customwidthsidebyside__container,
              getSlicePadding(paddingSection),
              {
                ['spacing_bottom']: marginBottom,
              },
            )}
            key={id}
            style={{ backgroundColor }}
          >
            <Container
              className={classNames(style.section, {
                [style.section_background]: backgroundColor,
              })}
              style={{
                borderBottom: horizontalBorder ?? '',
                borderLeft: verticalBorder ?? '',
                borderRadius: bothBorders,
                borderRight: verticalBorder ?? '',
                borderTop: horizontalBorder ?? '',
                width: `${leftWidth}%`,
              }}
            >
              <div
                className={classNames(style.section_content, {
                  [style.left__content_image_border_rounder]: !backgroundColor,
                })}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  color: color ?? '',
                }}
              >
                <div
                  className={classNames(style.left__content, {
                    [style.left__content_image_background]: image,
                    [style.left__content_image_border_rounder]:
                      !backgroundColor,
                  })}
                >
                  {leftContent ? (
                    <PrismicRichText
                      components={htmlSerializerWithProps()}
                      field={section.left_content}
                    />
                  ) : null}
                </div>
              </div>
              <div
                className={classNames(
                  style.right_content,
                  style.section_content,
                )}
              >
                {section?.right_content ? (
                  <PrismicRichText
                    components={htmlSerializerWithProps({
                      color,
                    })}
                    field={section.right_content}
                  />
                ) : null}
              </div>
            </Container>
          </section>
        );
      })}
    </>
  );
};

export default CustomWidthSideBySide;
