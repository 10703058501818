import { useQuery } from '@tanstack/react-query';

import { useEcomStoreSelector, useMenuService } from '@/data';
import { GProduct, LastKey } from '@/types';
import { useSelectedStore } from './useSelectedStore';

interface UseGetProductByGroupProps {
  productGroupId: string;
  isFilterByStore?: boolean;
}

export const useProductsByGroup = ({
  productGroupId,
  isFilterByStore,
}: UseGetProductByGroupProps) => {
  const { customer_type, provider_inventory_location_id } =
    useEcomStoreSelector(['customer_type', 'provider_inventory_location_id']);
  const { store } = useSelectedStore();

  const queryKey = [
    'productsByGroup',
    store?.shortName,
    customer_type,
    provider_inventory_location_id,
  ];

  const { searchProducts, searchProductsByGroupId } = useMenuService();

  const { data, isLoading, error, isError } = useQuery({
    queryKey,
    queryFn: async (): Promise<{
      products: GProduct[];
      lastKey: LastKey;
    }> => {
      const productGroup = await searchProductsByGroupId({
        id: productGroupId,
      });

      const productIds = productGroup.map(e => e.id);

      const data = await searchProducts({
        filters: {
          productId: { values: productIds },
        },
      });

      return data;
    },
    enabled: isFilterByStore,
  });

  return {
    error,
    isError,
    isLoading,
    products: data?.products ?? [],
    total: 0,
  };
};
