import { Counter } from '@/components';
import { NOT_DEFINED } from '@/constants';

import {
  getInfoPackValue,
  ProductActionFavorite,
  ProductBadgeContainer,
  ProductCategory,
  ProductImage,
  ProductInfo,
  ProductInfoCarousel,
  ProductName,
  ProductPrice,
  ProductVariants,
} from '../Product.components';

import style from '../product.module.scss';
import styleVariation from './ProductCardWithBtnHover.module.scss';

import { IProductCard } from '../Product';
import classNames from 'classnames';
import React from 'react';
import { startCase } from 'lodash';
//import { useFeaturedFlag } from 'hooks/useFeaturedFlag';

const ProductCardWithBtnHover: React.FC<IProductCard> = ({
  isSpecial,
  className,
  product,
  variants,
  analyticsListName,
  weightVariant,
  updateWeinghtVariant,
  isLargeCard,
}) => {
  const thcInfo = getInfoPackValue(product, false, true);
  const cbdInfo = getInfoPackValue(product, false, false);

  const availableInfo = [
    <ProductCategory
      className={classNames(
        styleVariation.variation__info_badge,
        styleVariation.variation__info_badge_border_less,
      )}
      info={product?.flower_type as string}
      key="category"
      type="outlined"
    />,
    thcInfo && (
      <ProductInfo
        className={classNames(
          style.info__filled,
          styleVariation.variation__info_badge,
        )}
        icon="thc"
        info={thcInfo}
        key="thc"
        type="filled"
      />
    ),
    cbdInfo && (
      <ProductInfo
        className={classNames(
          style.info__filled,
          styleVariation.variation__info_badge,
        )}
        icon="cbd"
        info={cbdInfo}
        key="cbd"
        type="filled"
      />
    ),
    <ProductInfo
      className={classNames(
        style.info__filled,
        styleVariation.variation__info_badge,
      )}
      info={product.brand_name || NOT_DEFINED}
      key="info-brand"
      type="default"
    />,
    <ProductInfo
      className={classNames(
        style.info__filled,
        styleVariation.variation__info_badge,
      )}
      info={startCase(product?.subcategory as string) || startCase(NOT_DEFINED)}
      key="subcategory"
      type="default"
    />,
  ].filter(Boolean);

  return (
    <div
      className={classNames(style.product, styleVariation.product, className)}
    >
      <div className={styleVariation.product__image_container}>
        <ProductImage
          analyticsListName={analyticsListName}
          className={styleVariation.product__image}
          product={product}
        />
        <Counter
          actionTheme="primary"
          className={classNames(styleVariation.actions__counter_hover, {
            [styleVariation.actions__counter_hover_largue_card]: isLargeCard,
          })}
          onCard
          product={product}
          selectedWeightVariant={weightVariant}
          showIcon
        />
      </div>
      <div className={style.product__body}>
        <ProductName analyticsListName={analyticsListName} product={product} />
        <ProductBadgeContainer
          className={styleVariation.product__badges_container}
          isSpecial={isSpecial}
          product={product}
          weightVariant={weightVariant}
        />
        <ProductInfoCarousel
          className={styleVariation.variation__info_carousel}
        >
          {availableInfo}
        </ProductInfoCarousel>
        <ProductPrice
          className={styleVariation.product__price_container}
          elementVariants={
            <ProductVariants
              type={'default'}
              updateWeightVariant={updateWeinghtVariant}
              variants={variants}
              weightVariant={weightVariant}
            />
          }
          isOverflow={variants?.length === 1}
          isSpecial={isSpecial}
          type="expanded"
          weightVariant={weightVariant}
        />
        <div className={styleVariation.actions__body}>
          <ProductActionFavorite
            className={classNames(
              style.favorite_action,
              styleVariation.favorite__button,
            )}
            product={product}
          />
        </div>
        <div className={styleVariation.product__actions_footer}>
          <Counter
            actionTheme="primary"
            className={styleVariation.actions__counter}
            onCard
            product={product}
            selectedWeightVariant={weightVariant}
            showIcon
          />
        </div>
      </div>
    </div>
  );
};

export { ProductCardWithBtnHover };
