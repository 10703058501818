import { useQuery } from '@apollo/client';
import { LIST_PAGES_BY_TEMPLATE } from './schema';

export function useListPages(options) {
  const { data, error, loading, fetchMore } = useQuery(
    LIST_PAGES_BY_TEMPLATE,
    options,
  );

  return {
    data: data?.listPages,
    error: error,
    fetchMore,
    loading: loading,
  };
}
