import { useData } from '@/components';
interface IUseSpacingBetweenProductCard {
  spacingClass: string | null;
  breakpoints: {};
  defaultSlidePerView: number;
  defaultSpaciBetween: number;
  spacing_between_card: ISpaceType['spacing'];
}

interface ISpaceType {
  spacing: 'small' | 'medium' | 'large' | 'none';
}

const useSpacingBetweenProductCard = (
  isSearchHeader?: boolean,
): IUseSpacingBetweenProductCard => {
  const { productCard } = useData();

  const spacing = productCard?.spacing_between_card as ISpaceType['spacing'];
  const isProductCardLarge = productCard?.product_card_large;

  const getClass = (spacing: ISpaceType['spacing']) =>
    `collections_${spacing ? spacing : 'none'}_spacing`;

  const spacingValues = {
    none: {
      default: 0,
      sm: 0,
      md: 0,
      xl: 0,
      xxl: 0,
    },
    small: {
      default: 6,
      sm: 6,
      md: 10,
      xl: 14,
      xxl: 14,
    },
    medium: {
      default: 8,
      sm: 10,
      md: 12,
      xl: 16,
      xxl: 16,
    },
    large: {
      default: 8,
      sm: 12,
      md: 16,
      xl: 20,
      xxl: 20,
    },
  };

  const selectedSpacing = spacingValues[spacing] || spacingValues.none;

  const adjustBreakpoints = breakpoints => {
    const adjustedBreakpoints = { ...breakpoints };

    Object.keys(adjustedBreakpoints).forEach(key => {
      if (key !== '542' && key !== '420') {
        const slidesPerView = adjustedBreakpoints[key].slidesPerView;

        adjustedBreakpoints[key].slidesPerView = slidesPerView - 1;
      }
    });

    return adjustedBreakpoints;
  };

  const getBreakpoints = () => {
    const breakpointsLargeCard = {
      1024: {
        slidesPerView: 3,
        spaceBetween: selectedSpacing.md,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: selectedSpacing.xl,
      },
      768: { slidesPerView: 3, spaceBetween: selectedSpacing.sm },
      420: {
        slidesPerView: 2,
        spaceBetween: selectedSpacing.default,
      },
    };

    const breakpoints = {
      1024: {
        slidesPerView: 4,
        spaceBetween: selectedSpacing.md,
      },
      1280: {
        slidesPerView: 5,
        spaceBetween: selectedSpacing.xl,
      },
      1536: {
        slidesPerView: 6,
        spaceBetween: selectedSpacing.xl,
      },
      2048: {
        slidesPerView: 8,
        spaceBetween: selectedSpacing.xxl,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: selectedSpacing.default,
      },
      542: {
        slidesPerView: 3,
        spaceBetween: selectedSpacing.default,
      },
    };

    if (isSearchHeader) {
      return adjustBreakpoints(
        isProductCardLarge ? breakpointsLargeCard : breakpoints,
      );
    }

    return isProductCardLarge ? breakpointsLargeCard : breakpoints;
  };

  const breakpoints = getBreakpoints();

  return {
    spacingClass: spacing !== 'none' ? getClass(spacing) : null,
    breakpoints: breakpoints,
    defaultSlidePerView: isProductCardLarge ? 1 : 2,
    defaultSpaciBetween: selectedSpacing.default,
    spacing_between_card: spacing,
  };
};

export { useSpacingBetweenProductCard };
