import { gql } from '@apollo/client';

export const SEND_CONTACT_EMAIL_MUTATION = gql`
  mutation sendContactEmail(
    $account_id: String!
    $store_id: String!
    $input: ContactInput!
  ) {
    sendContactEmail(
      account_id: $account_id
      store_id: $store_id
      input: $input
    )
  }
`;
