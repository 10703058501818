import { FC, useEffect, useMemo, useState } from 'react';
import { getDaysInMonth, isValid } from 'date-fns';
import { padStart } from 'lodash';

import { Select } from '@/components';

import styles from './datepicker.module.scss';

const MIN_YEAR_OPTION = 1900;

interface IDatePickerProps {
  label?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (_: Date) => void;
}

const DatePicker: FC<IDatePickerProps> = ({ label, onChange }) => {
  const [selectedDay, setSelectedDay] = useState<number>();
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [selectedYear, setSelectedYear] = useState<number>();
  const yearOptions = useMemo(getYearOptions, []);
  const monthOptions = useMemo(getMonthOptions, []);
  const dayOptions = useMemo(
    () => getDaysOptions(selectedMonth as number, selectedYear as number),
    [selectedMonth, selectedYear],
  );

  useEffect(() => {
    const date = new Date(
      selectedYear as number,
      (selectedMonth as number) - 1,
      selectedDay,
    );
    if (isValid(date)) {
      onChange(date);
    }
  }, [onChange, selectedDay, selectedMonth, selectedYear]);

  return (
    <div className={styles['date-picker']}>
      {label && <label className={styles['date-picker__label']}>{label}</label>}
      <div className={styles['date-picker__content']}>
        <Select
          className={styles['date-picker__input']}
          menuClassName={styles['date-picker__menu']}
          onChange={option => setSelectedMonth(option.value)}
          options={monthOptions}
          placeholder="MM"
          value={getOptionFromValue(selectedMonth)}
        />
        <Select
          className={styles['date-picker__input']}
          menuClassName={styles['date-picker__menu']}
          onChange={option => setSelectedDay(option.value)}
          options={dayOptions}
          placeholder="DD"
          value={getOptionFromValue(selectedDay)}
        />
        <Select
          className={styles['date-picker__input']}
          menuClassName={styles['date-picker__menu']}
          onChange={option => setSelectedYear(option.value)}
          options={yearOptions}
          placeholder="YYYY"
          value={getOptionFromValue(selectedYear)}
        />
      </div>
    </div>
  );
};

const getOptionFromValue = (value: any) =>
  value
    ? {
        label: padStart(String(value), 2, '0'),
        value,
      }
    : undefined;

const getDateOptions = (min = 1, max: number) => {
  const isYear = max >= MIN_YEAR_OPTION;
  const options = new Array(max - min + 1).fill(null).map((_, index) => {
    const value = index + min;

    return {
      label: getOptionLabel(value, isYear),
      value,
    };
  });

  if (isYear) {
    return options.sort((a, b) => b.value - a.value);
  }
  return options;
};

const getDaysOptions = (selectedMonth: number, selectedYear: number) => {
  return getDateOptions(
    1,
    selectedMonth
      ? getDaysInMonth(
          new Date(selectedYear ?? new Date().getFullYear(), selectedMonth - 1),
        )
      : 31,
  );
};

const getYearOptions = () =>
  getDateOptions(MIN_YEAR_OPTION, new Date().getFullYear());

const getMonthOptions = () => getDateOptions(1, 12);

const getOptionLabel = (value: number, isYear: boolean) => {
  return `0${value}`.slice(isYear ? -4 : -2);
};

export { DatePicker };
