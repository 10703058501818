import { useEffect, useMemo, useState } from 'react';
import { findIndex } from 'lodash';
import { isCurrentDateInRange } from '@/utils';
import { useScreenWidth, useSelectedStore } from '@/hooks';
import { useData } from '@/components';
import {
  LocationBannerAlertDocumentDataLocationItem,
  TreezStore,
} from '@/types';
import { LinkField } from '@prismicio/client';
import { format } from 'date-fns';

const useCurrentPromotion = () => {
  const { promotion } = useData();
  const { width } = useScreenWidth();
  const { store } = useSelectedStore();
  const [currentStore, setCurrentStore] = useState<TreezStore>();
  const [currentHeight, setCurrentHeight] = useState<number>(0);
  const currentDay = format(new Date(), 'eeee');

  useEffect(() => {
    if (store?.objectID) {
      setCurrentStore(store);
    }
  }, [store?.objectID]);

  const generatePromotion = (
    promotions: LocationBannerAlertDocumentDataLocationItem[],
    locationSlug: string,
  ): {
    locationSlug: string;
    messages: IMessages[];
  } =>
    promotions.reduce(
      (result, current) => {
        result.messages.push({
          active: current?.active,
          cta: current?.cta,
          dismissible: current?.dismissible,
          internalCta: current?.internal_cta ?? '',
          message: current?.message ?? '',
        });

        return {
          ...result,
        };
      },
      {
        locationSlug: locationSlug,
        messages: [] as IMessages[],
      },
    );

  const valitadePromotionByLocationAndPeriod = (
    promotion: LocationBannerAlertDocumentDataLocationItem,
  ) => {
    if (
      !promotion?.location_slug ||
      promotion.location_slug === currentStore?.url_slug
    ) {
      if (
        (!promotion?.day ||
          promotion.day === 'Everyday' ||
          promotion.day === currentDay) &&
        isCurrentDateInRange(
          promotion?.start_date as string,
          promotion?.end_date as string,
        )
      ) {
        return promotion;
      }
    }
  };

  const { locationSlug, messages } = useMemo(() => {
    const currentPromotionByLocationAndPeriod =
      promotion?.data?.location?.filter(valitadePromotionByLocationAndPeriod);

    if (
      findIndex(currentPromotionByLocationAndPeriod, item => item.active) !== -1
    ) {
      return generatePromotion(
        currentPromotionByLocationAndPeriod,
        currentStore?.url_slug || '',
      );
    }

    return {
      locationSlug: '',
      messages: [
        {
          active: promotion?.data?.default_active,
          cta: promotion?.data?.default_cta,
          dismissible: promotion?.data?.global_dismissible,
          internalCta: promotion?.data?.default_internal_cta,
          message: promotion?.data?.default_message,
        },
      ],
    };
  }, [currentStore]);

  useEffect(() => {
    if (typeof window === 'object') {
      setCurrentHeight(
        document?.getElementById('promotion-id')?.clientHeight as number,
      );
    }
  }, [messages, width]);

  return {
    currentHeight,
    delay: promotion?.data?.delay,
    locationSlug,
    messages,
    setCurrentHeight,
  };
};

export interface IMessages {
  active: boolean;
  cta: LinkField;
  dismissible: boolean;
  internalCta: string;
  message: string;
}

export default useCurrentPromotion;
