import { findIndex } from 'lodash';
import { useWishListStore } from '@/data';

function useWishList() {
  const [state, setState] = useWishListStore();
  const favProducts = state.fav_products;

  const addFav = product => {
    setState({ fav_products: [...favProducts, product] });
  };

  const removeFav = id => {
    const newProducts = [...favProducts];
    const index = findIndex(newProducts, item => {
      return item.id === id;
    });
    newProducts.splice(index, 1);

    setState({ fav_products: newProducts });
  };

  return { addFav, favProducts, removeFav };
}

export default useWishList;
