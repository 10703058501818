import { format, isAfter, isBefore } from 'date-fns';
import { getDistance, initializeApollo, IStoreMetadata } from '@/data';
import { PLACEHOLDER, TEMPLATE_BASE_PATH } from '@/constants';
import { stringToSlug } from './utils';
import data from './../public/ssr-cache.json';
import startCase from 'lodash/startCase';
import {
  Asset,
  CannabisType,
  Component,
  Discount,
  Geoloc,
  GProduct,
  PriceType,
  ProductList,
  PromoCarouselDocumentDataCarouselItemsItemWithID,
  QueryFilters,
  SubCat,
  TierMethod,
  TierPricingDetails,
  TreezFilters,
  TreezProduct,
  TreezSpecials,
  TreezStore,
  Variant,
} from '@/types';
import { TimestampField } from '@prismicio/client';

const client = initializeApollo();

const mapStoreToLocation = ({
  name,
  _geoloc: { lat, lng },
  google_map_url,
  photo,
  url_slug,
}): {
  label: string;
  lat: number;
  lng: number;
  title: string;
  google_map_url?: string;
  photo?: string;
  url_slug: string;
} => ({
  label: name,
  lat,
  lng,
  title: name,
  google_map_url,
  photo,
  url_slug,
});

const compareDistances = (firstStore, secondStore) => {
  return firstStore.distance - secondStore.distance;
};

const mapStoreToDistance =
  (currentLocation: Geoloc) => async (store: TreezStore) => {
    const metricSystem = process.env.NEXT_PUBLIC_DISTANCE_MEASUREMENT_UNIT;
    const { lat, lng } = mapStoreToLocation(store);

    const { data, error } = await getDistance(client, {
      variables: {
        from: { latitude: lat, longitude: lng },
        to: {
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
        },
      },
    });

    if (error) {
      // eslint-disable-next-line no-console
      console.error('getDistance', error);
      return { ...store };
    }

    const currentDimension = data.find(
      d => d.dimension.toUpperCase() === metricSystem?.toUpperCase(),
    );

    if (!currentDimension) {
      return { ...store };
    }

    return { ...store, distance: currentDimension.value };
  };

// eslint-disable-next-line no-unused-vars
const checkIfStoreIsOpen = (openingTime: string, closingTime: string) => {
  return true;
};

const getHoursAndMinutes = (time: string | null) => {
  if (!time) {
    return {
      hours: 10,
      minutes: 10,
    };
  }

  const [stringHours, stringMinutes] = time.split(':');
  return {
    hours: parseInt(stringHours, 10),
    minutes: parseInt(stringMinutes, 10),
  };
};

const transformMilitaryTime = (militaryFormatTime: string): string => {
  if (!militaryFormatTime || !militaryFormatTime.length) {
    return '';
  }

  const { hours: militaryFormatHours, minutes: militaryMinutes } =
    getHoursAndMinutes(militaryFormatTime);

  let time: string = '';

  // get hours
  militaryFormatHours === 0 && (time = '12');
  militaryFormatHours > 12 && (time = '' + (militaryFormatHours - 12));
  militaryFormatHours > 0 &&
    militaryFormatHours <= 12 &&
    (time = '' + militaryFormatHours);

  // get minutes
  time += militaryMinutes < 10 ? ':0' + militaryMinutes : ':' + militaryMinutes;

  // get AM/PM
  time += militaryFormatHours >= 12 ? ' pm' : ' am';

  return time;
};

const MapProducts = (products: TreezProduct[]): GProduct[] => {
  return products?.map(mapProduct);
};

// eslint-disable-next-line no-unused-vars
const sanitizeDescription = (description: string) => {
  if (!description) {
    return '';
  }

  return description.replace(/[\r\n]+/g, ' ');
};

const formatProductTitle = (productTitle: string) => {
  try {
    const signalsToIgnore = [
      'CBD-',
      'CBD',
      'Grams',
      'OG',
      'THC-',
      'THC',
      'CBN-',
      'CBN',
      'CBG',
      'CBG-',
      'CBC',
      'CBC-',
      'THCA',
      'THCA-',
      'CBDA',
      'CBDA-',
      'SFV',
      'SFV-',
    ];

    const containsAny = (str: string, arr: string[]) => {
      return arr.some(subStr => str.includes(subStr));
    };

    return productTitle
      .split(' ')
      .map(word => {
        if (!isNaN(+word.charAt(0)) || containsAny(word, signalsToIgnore)) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
      })
      .join(' ');
  } catch (error) {
    return productTitle;
  }
};

const findCategoryForSubtype = (subCat: SubCat, subtype: string) => {
  for (const [category, subtypes] of Object.entries(subCat)) {
    if (subtypes.includes(subtype)) {
      return stringToSlug(category);
    }
  }
  return '';
};

const getDescription = (product: TreezProduct): string => {
  if (product?.description) {
    return product.description;
  }

  if (product?.longDescription) {
    return product.longDescription;
  }

  return product?.shortDescription;
};

const PRODUCT_URL_PATH = '/product/';

const priceType = (product: TreezProduct): string => {
  const type = product.productList?.[0]?.pricing?.price_type ?? 'FLAT';

  if (
    type === 'TIER' &&
    product.subTypes &&
    product.subTypes.length > 0 &&
    ['BULK FLOWER', 'SHAKE', 'STRAIN SPECIFIC SHAKE', 'PRE-PACK'].includes(
      product.subTypes[0],
    )
  ) {
    return 'TIER';
  }

  return 'FLAT';
};

const mapProduct = (product: TreezProduct): GProduct => {
  const asset = mapProductPhotoAsset(product);
  const trezPriceType = priceType(product);

  const inStock = (product.productList?.[0]?.inStock ?? 0) > 0;
  const outStock = product?.liveInventory === 0;

  return {
    id: product.productList?.[0]?.productId,
    // TODO lets make altMenuTitle or productName on a feature flag
    name:
      product?.menuTitle ||
      formatProductTitle(product.productList?.[0].productName),
    url: `${PRODUCT_URL_PATH}${product.productList?.[0].seoProductName}`,
    slug: product.productList?.[0]?.seoProductName ?? '',
    enabled: true, //product.productList?.[0]?.active, // We commnet this out becasue the treez sandbox has a wrong data this is only for the demo
    description: getDescription(product),
    category: startCase(product.type.toLocaleLowerCase()),
    subcategory: product.subTypes?.[0],
    category_id: product.categoryId,
    thc: getLabValue(product.thc),
    cbd: getLabValue(product.cbd),
    qty: product?.liveInventory,
    brand_name: product.brand,
    brand_id: product.brand,
    brand_subtype: startCase(
      product.productList?.[0]?.productGroup.toLocaleLowerCase(),
    ),
    // TODO add general_attr to our backend product model
    flavors: product.flavors,
    effects: product.effects,
    // @ts-ignore TODO add flavors to our backend product model
    // remove ingredients? it doesn't come from treez api
    ingredients: product.attributeMap?.INGREDIENT,
    in_stock: inStock,
    sales_price: product.productList?.[0]?.priceSell * 100,
    flower_type: startCase(product.classifications?.[0].toLocaleLowerCase()),
    cannabis_type: product.classifications?.[0] as CannabisType,
    available_for_delivery: true,
    available_for_pickup: true,
    asset: asset,
    assets: [asset],
    price_type: trezPriceType as PriceType,
    variants: mapProductVariants(product),
    discounts: f(product?.productList?.[0]?.discounts),
    labResults: product.productList?.[0]?.labResults || [],
    isOutStock: outStock,
    isLowStock: !inStock && !outStock,
    base_weight: product?.productList?.[0]?.weight,
    tier_method: product?.productList?.[0]?.pricing?.tier_method as TierMethod,
  };
};

// For a BOGO that has not met it’s schedule condition, the API returns discount_affinity=cart.
// This is actually a bug that should be fixed, as discount_affinity should
// be “pre-cart” for a bogo. However because this bug is consistent,
// we can build logic around it for a short-term
// fix until we add API logic to filter out inactive BOGOS from the response.
const f = (v?: Discount[]): Discount[] => {
  if (!v) {
    return [];
  }

  return v.filter(
    discount =>
      discount.discount_method !== 'BOGO' ||
      (discount.discount_method === 'BOGO' &&
        discount.discount_affinity !== 'Cart'),
  );
};

const getLabValue = (v?: string): Component => {
  let percentage = 0;
  let amount = 0;

  if (!v) {
    return { percentage, amount };
  }

  if (v.includes('%')) {
    percentage = parseFloat(v);
  }

  if (v?.toLowerCase()?.includes('mg')) {
    amount = parseFloat(v);
  }

  return { percentage, amount };
};

const getStockItem = (stockList, searchParam) => {
  if (!stockList?.length) {
    return null;
  }

  const stockItem = stockList?.find(
    stock => stock?.name?.toLocaleLowerCase() === searchParam,
  );

  return stockItem;
};

const generateStockImage = product => {
  const productType = (data as unknown as IStoreMetadata)?.productCard;

  if (productType) {
    const category = product.type?.toLowerCase();
    const subCategory = product.subTypes[0]?.toLowerCase();

    const subCategoryStockImage = getStockItem(
      productType?.stock_image,
      subCategory,
    );

    if (subCategoryStockImage) {
      return {
        image: subCategoryStockImage?.default_product_image?.url,
        stock: true,
      };
    }

    const categoryStockImage = getStockItem(productType?.stock_image, category);

    if (categoryStockImage) {
      return {
        image: categoryStockImage?.default_product_image?.url,
        stock: true,
      };
    }
  }

  const imagePlaceholder = product?.image_placeholder?.url ?? PLACEHOLDER;

  return {
    image: imagePlaceholder,
    stock: true,
  };
};

const mapProductPhotoAsset = (product: TreezProduct): Asset => {
  if (product?.largeImage) {
    return { image: product.largeImage };
  }

  const stockImage = generateStockImage(product);

  if (stockImage) {
    return stockImage;
  }

  // fallback image
  return { image: PLACEHOLDER };
};

const mapProductVariants = (product: TreezProduct): Variant[] => {
  if (!product.productList) {
    return [];
  }
  const p: ProductList = product?.productList[0]; // it will only be one product, treez notes: Yes that's correct. Yeah I'm not sure why it's designed that way - part of the original version product from years ago. Wouldn't do it that way if I was redoing from scratch today
  const priceType = p?.pricing?.price_type ?? 'FLAT';

  if (priceType === 'FLAT') {
    return [mapVariant(p)];
  }

  if (priceType === 'TIER') {
    const t = p.pricing.tier_pricing_detail
      ?.filter(t => t.start_value > 0) // skip the first tier
      .map(t => mapTierVariant(p, t));

    return t;
  }

  return [];
};

const mapVariant = (product: ProductList): Variant => {
  const price = calculatePrice(String(product.displayPriceSell));
  const salePrice = getSalePrice(String(product.discountedPrice), price);

  const discount_amount = calculatePrice(
    String(product?.pricing?.discount_amount ?? '0'),
  );
  const discount_percent = parseFloat(
    product?.pricing?.discount_percent?.toFixed(2) ?? '0',
  );

  return {
    id: product.productId,
    name: product.productName,
    pos_id: product.productId,
    price: price,
    salePrice: salePrice,
    discount_amount,
    discount_percent,
    type: mapVariantType(product),
  };
};

const mapTierVariant = (
  product: ProductList,
  tierPricingDetail: TierPricingDetails,
): Variant => {
  // tier base price
  const price = calculatePrice(
    String(tierPricingDetail.price_per_value * tierPricingDetail.start_value),
  );
  const salePrice = price;

  const discount_amount = calculatePrice(
    String(product?.pricing?.discount_amount ?? '0'),
  );
  const discount_percent = parseFloat(
    product?.pricing?.discount_percent?.toFixed(2) ?? '0',
  );

  return {
    id: `${product.productId}-${tierPricingDetail.start_value}`, // we are adding the weight to the id to make it unique
    name: product.productName,
    pos_id: product.productId,
    price: price,
    salePrice: salePrice,
    discount_amount,
    discount_percent,
    type: mapVariantType({ ...product, weight: tierPricingDetail.start_value }),
  };
};

const mapVariantType = (product: ProductList): string => {
  if (
    product.unitOfMeasurement === 'MILLIGRAMS' ||
    product.unitOfMeasurement === 'UNIT'
  ) {
    return 'each';
  }

  switch (product.weight) {
    case 0.5:
      return 'half_gram';
    case 1:
      return 'gram';
    case 2:
      return 'two_gram';
    case 3.5:
      return 'eighth_ounce';
    case 7:
      return 'quarter_ounce';
    case 14:
      return 'half_ounce';
    case 28:
      return 'ounce';
    default:
      return `${product.weight}_${product.unitOfMeasurement.toLowerCase()}`;
  }
};

function formatWeightLabel(type: string): string {
  return mapWeightLabelToGrams(type);
}

const mapWeightLabelToGrams = (type: string): string =>
  ({
    gram: '1G',
    half_gram: '0.5G',
    two_gram: '2G',
    ounce: '28G',
    half_ounce: '14G',
    quarter_ounce: '7G',
    eighth_ounce: '3.5G',
    each: 'each',
    'Not provided': 'Not provided',
  }[type] ?? generateLabel(type));

const generateLabel = (type: string): string => {
  if (!type) {
    return undefined as unknown as string;
  }
  return type.split('_')[1] === 'grams'
    ? `${type.split('_')[0]}G`
    : type.replace('_', ' ');
};

const getSalePrice = (v: string | null, price: number): number => {
  if (!v) {
    return price;
  }

  return calculatePrice(v);
};

const calculatePrice = (value: string): number => {
  const parsedValue = parseFloat(value);

  if (isNaN(parsedValue)) {
    return 0;
  }

  // Round to 2 decimal places and convert to string
  const roundedValue = parsedValue?.toFixed(2);

  // Remove the decimal point and convert to number
  const cents = parseInt(roundedValue?.replace('.', ''), 10);

  return cents;
};

const getStoresFilter = () => {
  const storeIds = process?.env?.NEXT_PUBLIC_JANE_STORES_IDS?.split(',');
  return storeIds?.map(id => `objectID:${id}`).join(' OR ');
};

const getInitialStoresFilter = () => {
  const storeIds = process?.env?.NEXT_PUBLIC_JANE_STORES_IDS?.split(',');
  return `(${storeIds?.map(id => `store_id:${id}`).join(' OR ')})`;
};

const getReviewsFeelingsCount = (reviews: any[]) =>
  Object.entries(
    reviews.reduce(
      (acc, current) => {
        const temp = acc;
        current.feelings.forEach(feeling => {
          temp[feeling] = (temp[feeling] ?? 0) + 1;
        });
        return temp;
      },
      { Blissful: 0, Creative: 0, Energetic: 0 },
    ),
  );

const getReviewsActivitiesCount = (reviews: any[]) =>
  Object.entries(
    reviews.reduce(
      (acc, current) => {
        const temp = acc;
        current.activities.forEach(activity => {
          temp[activity] = (temp[activity] ?? 0) + 1;
        });
        return temp;
      },
      {
        'Ease my mind': 0,
        'Get Active': 0,
        'Stimulate my mind': 0,
      },
    ),
  );

const feelingsIconsNameMap = {
  blissful: 'blissful',
  creative: 'creative',
  energetic: 'energetic',
  hungry: 'hungry',
  not_high: 'sentiment_satisfied',
  pain_free: 'pain_free',
  relaxed: 'relaxed',
  sleepy: 'sleepy',
};

const reviewsIconsNameMap = {
  'Ease my mind': 'ease_mind',
  'Get Active': 'vital_signs',
  'Get intimate': 'intimate',
  'Get relief': 'relief',
  'Get some sleep': 'some_sleep',
  'Hang with friends': 'hang_with_friends',
  'Stimulate my mind': 'light_bulb',
};

const getFeelingIconName = (name: string) => {
  const transformedName = name.toLowerCase().replace(/ /g, '_');
  return feelingsIconsNameMap[transformedName] ?? 'cannabis';
};

const getActivityIconName = (name: string) =>
  reviewsIconsNameMap[name] ?? 'cannabis';

const generateQueryParams = (queryParams: {
  [key: string]: string | number | boolean;
}) => {
  return Object.entries(queryParams)
    .reduce((params, [key, value]) => {
      return value ? [...params, `${key}=${value}`] : params;
    }, [])
    .join('&');
};

const getProductItemLocId = (storeId: string, weightVariant: Variant) => {
  const treezIds = new Map(
    process?.env?.NEXT_PUBLIC_TREEZ_ID_PER_STORE &&
      JSON.parse(process?.env?.NEXT_PUBLIC_TREEZ_ID_PER_STORE),
  );
  const currentTreezId = treezIds.get(storeId);

  if (!currentTreezId) {
    return `${weightVariant?.pos_id}`;
  }

  return `${currentTreezId}|${weightVariant?.pos_id}`;
};

const isBogoBetweenTheDateRange = (
  startDate: Date,
  endDate: Date | undefined,
): { currentDate: Date; isActive: boolean } => {
  const currentDate = new Date().toLocaleString('en-US', {
    hourCycle: 'h23',
    timeZone: 'America/Los_Angeles', // TODO get the info from the GC store
  });
  const formatCurrentDate = new Date(currentDate);

  const inRange = endDate ? isBefore(formatCurrentDate, endDate) : true;

  const isActive = isAfter(formatCurrentDate, startDate);

  return {
    currentDate: formatCurrentDate,
    isActive: inRange && isActive,
  };
};

const dataInDayWeek = (
  startDate: Date,
  endDate: Date | undefined,
  activeDays?: { repeatOn: { [key: string]: boolean }[] },
) => {
  const { currentDate, isActive } = isBogoBetweenTheDateRange(
    startDate,
    endDate,
  );

  if (!isActive) {
    return false;
  }

  if (!activeDays) {
    const today = format(currentDate, 'eeee');
    const start = format(startDate, 'eeee');

    return today === start;
  }

  const dayWeek = format(currentDate, 'eeee').toUpperCase().slice(0, 3);
  return activeDays.repeatOn[dayWeek];
};

const dateInRangeBy = (
  startDate: Date,
  endDate: Date | undefined,
  by: 'ANNUAL' | 'MOUNTH_DAY' | 'WEEK_DAY',
) => {
  const { currentDate, isActive } = isBogoBetweenTheDateRange(
    startDate,
    endDate,
  );

  if (!isActive) {
    return false;
  }

  if (by === 'WEEK_DAY') {
    const currentWeekDay = currentDate.getDay();
    return currentWeekDay != 0 && currentWeekDay != 6; // 0 and 6 and Sat and Sunday
  }

  // get info about Day
  const currentDay = currentDate.getUTCDate();
  const initDay = startDate.getUTCDate();

  // get info about Hours
  const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
  const startTime = `${startDate.getHours()}:${startDate.getMinutes()}`;

  if (by === 'MOUNTH_DAY') {
    return currentDay === initDay && currentTime >= startTime;
  }

  // get indo about Mounth
  const currentMounth = currentDate.getUTCMonth();
  const initMount = startDate.getUTCMonth();
  return (
    currentMounth === initMount &&
    currentDay === initDay &&
    currentTime >= startTime
  );
};

const dateInRange = (startDate: Date, endDate: Date | undefined) => {
  const { isActive } = isBogoBetweenTheDateRange(startDate, endDate);
  return isActive;
};

const isCurrentDateInRange = (
  start: string | TimestampField,
  end: string | TimestampField,
) => {
  const currentDate = new Date().toLocaleString('en-US', {
    hourCycle: 'h23',
    timeZone: 'America/Los_Angeles', // TODO get the info from the GC store
  });
  const formatCurrentDate = new Date(currentDate);
  const startDate = start && new Date(start);
  const endDate = end && new Date(end);

  const inRange = endDate ? isBefore(formatCurrentDate, endDate) : true;

  const isActive = startDate ? isAfter(formatCurrentDate, startDate) : true;

  return inRange && isActive;
};

const convertHexadecimalFormatToRGB = (hex: string) => {
  const hexParse = hex.replace(/^#/, '');

  const r = parseInt(hexParse.substring(0, 2), 16);
  const g = parseInt(hexParse.substring(2, 4), 16);
  const b = parseInt(hexParse.substring(4, 6), 16);

  return { r, g, b };
};

const getScaleColor = (color: string) => {
  const rgb = convertHexadecimalFormatToRGB(color);

  // Calculate luminance
  const luminance = 0.2126 * rgb?.r + 0.7152 * rgb?.g + 0.0722 * rgb?.b;

  const grayscaleAmount = Math.round(((255 - luminance) / 255) * 100);

  return grayscaleAmount;
};

const TreezFilterKeys = [
  'price',
  'totalThcPercent',
  'productTypeName',
  'classification',
  'effect',
  'flavor',
  'subType',
  'brand',
  'generals',
  'locationId',
  'deals',
];
const getOnlyTreezFilters = (
  filters: QueryFilters | TreezFilters,
  toIncludes: string[] = [],
) =>
  Object.entries(filters).reduce((result, [key, value]) => {
    if (TreezFilterKeys.includes(key) || toIncludes.includes(key)) {
      return { ...result, [key]: value };
    }

    return result;
  }, {});

const calculateDiscountPercent = (value: number, total: number) => {
  return 100 - Math.floor((value / total) * 100);
};

const getPreCartTreezDiscounts = (specials: TreezSpecials[]) =>
  specials?.filter(s => !s.cart);

const getSpecialLink = (
  s: TreezSpecials | PromoCarouselDocumentDataCarouselItemsItemWithID,
) =>
  `${TEMPLATE_BASE_PATH.SPECIALS}${stringToSlug(
    s?.title ??
      (s as PromoCarouselDocumentDataCarouselItemsItemWithID)?.pos_title_name,
  )}?special_id=${s?.id}`;

const getExternalStores = () => {
  // this external store config is to support a use case for Airfield store
  // so they want to be able to select the store on the store selector
  // component and redirect customers to respective cross-domain
  // store
  const s = process?.env?.NEXT_PUBLIC_EXTERNAL_STORES;
  if (!s) {
    return [];
  }

  return JSON.parse(s);
};

export {
  calculateDiscountPercent,
  getOnlyTreezFilters,
  getProductItemLocId,
  compareDistances,
  mapStoreToDistance,
  mapStoreToLocation,
  checkIfStoreIsOpen,
  getHoursAndMinutes,
  findCategoryForSubtype,
  MapProducts,
  getInitialStoresFilter,
  transformMilitaryTime,
  formatWeightLabel,
  getStoresFilter,
  getReviewsFeelingsCount,
  getReviewsActivitiesCount,
  getFeelingIconName,
  getActivityIconName,
  generateQueryParams,
  dateInRange,
  dataInDayWeek,
  dateInRangeBy,
  isCurrentDateInRange,
  convertHexadecimalFormatToRGB,
  getScaleColor,
  getPreCartTreezDiscounts,
  getSpecialLink,
  getExternalStores,
};
