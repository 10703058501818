import { FC } from 'react';

import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';

import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceWithSocialIcons,
} from '@/types';

import style from './withsocialiconsvariation.module.scss';
import {
  DefaultComponentSection,
  textAlign,
} from '../ImageWithTextSideBySide.components';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';

export const WithSocialIconsVariation: FC<{
  slice: ImageWithTextSideBySideSliceWithSocialIcons &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);
  return (
    <DefaultComponentSection slice={slice}>
      {filteredItems && (
        <div
          className={style.items_section}
          style={{
            justifyContent: textAlign(
              slice.primary.content_horizontal_align as string,
            ),
          }}
        >
          {filteredItems?.map((item, index) => (
            <div className={style.item} key={index}>
              {item.social_image.url && (
                <PrismicNextLink field={item.link}>
                  <figure className={style.image}>
                    <PrismicNextImage
                      fallbackAlt=""
                      field={item.social_image}
                    />
                  </figure>
                </PrismicNextLink>
              )}
            </div>
          ))}
        </div>
      )}
    </DefaultComponentSection>
  );
};
