import { FC, Ref, useEffect, useState } from 'react';

import classNames from 'classnames';
import sortBy from 'lodash/sortBy';

import { Button, Container, Heading } from '@/components';

import Review from './Review';
import {
  DateOptions,
  IOption,
  PaginationOptions,
  RatingOptions,
} from './FilterOptions';
import Select from './Select';

import style from './tabreview.module.scss';
import { CustomerReview } from '@/types';

const DefaultPageSize = { label: '3 reviews', value: 3 };

const dateRange = (value: string): Date => {
  const today = new Date();

  switch (value) {
    case 'today':
      return new Date(today.setHours(0, 0, 0));
    case 'yesterday':
      return new Date(today.setDate(today.getDate() - 1));
    case 'treedays':
      return new Date(today.setDate(today.getDate() - 3));
    case 'week':
      return new Date(today.setDate(today.getDate() - 7));
    case 'fifteen':
      return new Date(today.setDate(today.getDate() - 15));
    case 'month':
      return new Date(today.setMonth(today.getMonth() - 1));
    case 'treemonth':
      return new Date(today.setMonth(today.getMonth() - 3));
    case 'sismonth':
      return new Date(today.setMonth(today.getMonth() - 6));
    case 'year':
      return new Date(today.setFullYear(today.getFullYear() - 1));
    default:
      return undefined as unknown as Date;
  }
};

interface ITabReviewProps {
  reviews: CustomerReview[];
  className?: string;
  ref?: Ref<HTMLDivElement | null>;
}

const TabReview: FC<ITabReviewProps> = ({ reviews, ref }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [pageSize, setPageSize] = useState<IOption>(DefaultPageSize);
  const [filteredReviews, setFilteredReview] = useState(reviews);
  const [rating, setRating] = useState<IOption | null>(null);
  const [range, setRange] = useState<IOption | null>(null);
  const [totalVisiblesReviews, setTotalVisiblesReviews] = useState<number>(
    DefaultPageSize.value,
  );

  useEffect(() => {
    setFilteredReview(reviews);
  }, [reviews]);

  const handleResetFilters = () => {
    setRating(null);
    setRange(null);
    setFilteredReview(reviews);
  };

  const handleFilterByRating = (option: IOption) => {
    setFilteredReview(reviews.filter(review => review.rating == option.value));
    setRating(option);
  };

  const handleFilterByDate = (option: IOption) => {
    const today = dateRange(option?.value as string);
    setFilteredReview(
      reviews.filter(review => {
        const date = new Date(review.updated_at);
        return date >= (today as Date);
      }),
    );
    setRange(option);
  };

  const handlePagination = (option: IOption) => {
    setPageSize(option);
    setTotalVisiblesReviews(option.value as number);
  };

  const handleLoadMoreEvent = () => {
    const l = filteredReviews.length;

    // reset the visibles reviews
    if (l == totalVisiblesReviews) {
      setTotalVisiblesReviews(DefaultPageSize.value);
      return;
    }

    const newTotal =
      totalVisiblesReviews + (pageSize?.value as number) ||
      DefaultPageSize.value;

    if (newTotal > l) {
      setTotalVisiblesReviews(filteredReviews.length);
      return;
    }
    setTotalVisiblesReviews(newTotal);
  };

  const tabs = [
    {
      id: 1,
      label: 'All REVIEWS',
      onClick: setActiveTab,
    },
  ];

  const showLoadMoreButton = filteredReviews.length > DefaultPageSize.value;

  const generateFilterOptions = (
    type: 'rating' | 'date' | 'pagination',
    options: IOption[],
    filterEvent,
    close,
  ) => {
    let selectedValue = '';
    switch (type) {
      case 'rating':
        selectedValue = rating?.value as string;
        break;
      case 'date':
        selectedValue = range?.value as string;
        break;
      case 'pagination':
        selectedValue = `${pageSize?.value}`;
        break;
    }

    return (
      <ol className={style['filters__list-container']}>
        {options.map(type => (
          <li
            className={classNames(style['filters__list-container-label'], {
              [style['filters__list-container-label-active']]:
                selectedValue === type.value,
            })}
            key={type.value}
            onClick={() => {
              filterEvent(type);
              close();
            }}
            role="button"
          >
            {type.label}
          </li>
        ))}
      </ol>
    );
  };

  return (
    <div className={style.tabreview} ref={ref}>
      <Container>
        <div
          aria-label="Details"
          className={style.details__tabs_list}
          role="tablist"
        >
          {tabs.map(tab => (
            <button
              aria-controls={`${tab.id}`}
              className={classNames({
                [style.details__tab_active]: activeTab === tab.id,
              })}
              key={tab.id}
              onClick={() => tab.onClick(tab.id)}
              role="tab"
              type="button"
            >
              {tab.label} <span>{`(${filteredReviews.length})`}</span>
            </button>
          ))}
        </div>
        <section
          className={style.details__tab_reviews_active}
          id="tab-reviews"
          key="tab-reviews"
          role="tabpanel"
        >
          {reviews.length > 0 ? (
            <>
              <div className={style.filters}>
                <div className={style.filters__container_main}>
                  <Heading className={style.filters__container_h3} level={3}>
                    Filter by
                  </Heading>
                  <div className={style.filters__container}>
                    <Select
                      className={style.filters__list}
                      filteractive={!!rating?.value}
                      text="Ratings"
                      title={`${rating?.label || 'Rating'}`}
                    >
                      {({ close }) =>
                        generateFilterOptions(
                          'rating',
                          RatingOptions,
                          handleFilterByRating,
                          close,
                        )
                      }
                    </Select>
                    <Select
                      className={style.filters__list}
                      filteractive={!!range?.value}
                      text={'Filter by Date'}
                      title={`${range?.label || 'Date'}`}
                    >
                      {({ close }) =>
                        generateFilterOptions(
                          'date',
                          DateOptions,
                          handleFilterByDate,
                          close,
                        )
                      }
                    </Select>
                    {range?.value || rating?.value ? (
                      <Button
                        className={`
                                        ${style['filters__item-button-clear']}
                                        `}
                        color="primary"
                        onClick={handleResetFilters}
                        size="sm"
                      >
                        Clear
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
              {filteredReviews.length > 0 ? (
                sortBy(filteredReviews)
                  .slice(0, totalVisiblesReviews)
                  .map((review: CustomerReview) => (
                    <Review key={review.objectID} review={review} />
                  ))
              ) : (
                <div className={style.review__filter_empty}>
                  <strong>No result</strong>
                  <p>No reviews were found with this criterial.</p>
                </div>
              )}
              {filteredReviews.length > 0 && (
                <div className={style.filters}>
                  <div className={style.filters__container_main}>
                    <Heading
                      className={`
                                            ${style.filters__container_h3} 
                                            ${style['reviews__text-show']}
                                        `}
                      level={3}
                    >
                      Show
                    </Heading>
                    <div className={style.filters__container}>
                      <Select
                        className={style.filters__list}
                        text={'# of reviews'}
                        title={`${pageSize?.label || '# of reviews'}`}
                      >
                        {({ close }) =>
                          generateFilterOptions(
                            'pagination',
                            PaginationOptions,
                            handlePagination,
                            close,
                          )
                        }
                      </Select>
                    </div>
                    {showLoadMoreButton && (
                      <button
                        className={`
                                    ${style['filters__item-button']} 
                                    ${style['review__btn-load-more']}
                                    `}
                        onClick={handleLoadMoreEvent}
                        type="button"
                      >
                        {totalVisiblesReviews < filteredReviews.length
                          ? 'Load more'
                          : 'Show less'}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className={style.review__empty}>
              <strong>No reviews yet</strong>
              <p>Be the first, give us your opinion about this product!</p>
            </div>
          )}
        </section>
      </Container>
    </div>
  );
};

export default TabReview;
