import classNames from 'classnames';

function Badge({ children, className, ...props }) {
  return (
    <span className={classNames('badge', className)} {...props}>
      {children}
    </span>
  );
}

export default Badge;
