'use client';

/* eslint-disable max-len */
import { FC } from 'react';
import { useFixedBodyOnPopup } from '@/hooks';
import { useUiStoreSelector } from '@/data';
import { Button, Heading, IconSvg, Image, Modal } from '@/components';

import styles from './RegulationModal.module.scss';

const regulationImageMobile = '/images/regulation/SB-540-BW_mobile.png';
const regulationImage = '/images/regulation/SB-540-BW_desktop.png';
const regulationDocument = '/images/regulation/SB-540-BW.pdf';

const RegulationModal: FC = () => {
  const { is_regulation_modal_open, setState: setRegulationState } =
    useUiStoreSelector(['is_regulation_modal_open']);
  useFixedBodyOnPopup({ isPopupOpen: is_regulation_modal_open });

  const handleDownload = async () => {
    try {
      const response = await fetch(regulationDocument);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'SB-540-BW';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
      //window.open(regulationDocument, '_blank');
    }
  };

  const handleCloseModal = () => {
    setRegulationState({ is_regulation_modal_open: false });
  };

  if (!is_regulation_modal_open) {
    return null;
  }

  return (
    <Modal className={styles.regulation} isOpen={is_regulation_modal_open} lg>
      <div className={styles.regulation__header}>
        <Heading level={2}>SB 540: Cannabis Products Health Warnings.</Heading>
        {/*    <p>It includes specified warnings about the safety of cannabis use.</p> */}
      </div>
      <div className={styles.regulation__body}>
        <div className={styles.regulation__body_scroll}>
          <figure className={styles.regulation__image_desktop}>
            <Image
              alt="SB540: Cannabis Products health warnings"
              fallback="/images/product-placeholder.svg"
              height={2218}
              src={regulationImage}
              style={{
                objectFit: 'contain',
                width: '100%',
              }}
              width={2840}
            />
          </figure>
          <figure className={styles.regulation__image_mobile}>
            <Image
              alt="SB540: Cannabis Products health warnings"
              fallback="/images/product-placeholder.svg"
              height={4408}
              src={regulationImageMobile}
              style={{
                objectFit: 'contain',
                width: '100%',
              }}
              width={704}
            />
          </figure>
        </div>
      </div>
      <div className={styles.regulation__footer}>
        <Button
          className={styles.regulation__download_btn}
          color="link"
          onClick={handleDownload}
          type="button"
        >
          DONWLOAD
          <IconSvg name="download" />
        </Button>
        <Button
          className={styles.regulation__close_btn}
          color="primary"
          onClick={handleCloseModal}
          size="sm"
          type="button"
        >
          CLOSE
        </Button>
      </div>
    </Modal>
  );
};

export default RegulationModal;
