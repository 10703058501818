/* eslint-disable max-len */
import classNames from 'classnames';
import { currencyFormat, formatAddress } from '@/utils';
import { Heading, Icon } from '@/components';
import { orderLastStatus, orderStatus } from './constant';
import ItemList from './ItemList';
import OrderRouteMap from './OrderRouteMap';
import style from './orderdetail.module.scss';

function OrderDetail({ order }) {
  const { delivery_address: address, status, delivery_details } = order;
  let stateActive = false;

  const getOrderStatus = (status, delivery_details) => {
    const orderFinishedStateDefault = orderLastStatus.filter(
      state => state.default,
    );
    const orderFinishedStateActivate = orderLastStatus.filter(
      s => s.name === status,
    );

    if (orderFinishedStateActivate?.length > 0) {
      stateActive = true;
    }

    if (delivery_details.delivery) {
      return orderStatus.delivery.concat(
        orderFinishedStateActivate.length
          ? orderFinishedStateActivate
          : orderFinishedStateDefault,
      );
    }

    return orderStatus.pickup.concat(
      orderFinishedStateActivate.length
        ? orderFinishedStateActivate
        : orderFinishedStateDefault,
    );
  };

  const states = getOrderStatus(status, delivery_details);

  const indexProgress = states.findIndex(state => {
    return state == status;
  }, 0);

  const client = `${address.first_name} ${address.last_name}`;

  const renderStatus = (state, pos) => {
    const renderDots = pos < states.length - 1;
    const active =
      state.name === status ||
      (indexProgress === -1 && pos === 0 && !stateActive);

    return (
      <>
        <li
          className={classNames({
            [style.active]: active,
          })}
          key={state.name}
        >
          <figure
            className={classNames({
              [style.active]: active,
            })}
          >
            <Icon name={state.icon} />
          </figure>
          {state.label}
        </li>
        {renderDots && (
          <li
            className={style.dotted}
            key={`dot ${state.name}`}
            style={{
              backgroundImage: `radial-gradient(${colorDots(
                pos,
              )} 2.5px, transparent 0)`,
            }}
          />
        )}
      </>
    );
  };

  const colorDots = pos => {
    if (pos < indexProgress) {
      return '#0b3b4a';
    }

    return '#b6c4c9';
  };

  const addressLabel = formatAddress({ address, format: 'long' });

  return (
    <div className={style.order}>
      <div className={style.thankyou__fieldset}>
        <div className={style['order-details__status']}>
          <ol>{states.map((state, i) => renderStatus(state, i))}</ol>
        </div>
      </div>
      <Heading className={style['order__list-title']} level={3}>
        Ordered items
      </Heading>
      <ItemList items={order.line_items} />
      <Heading className={style['order__payment-title']} level={3}>
        Payment Details
      </Heading>
      <div className={style.order__summary}>
        <div className={style['order__summary-body']}>
          <p className={style['order__summary-item']}>
            <strong>Sub-total</strong>
            <span>{`$${currencyFormat(order.subtotal_price)}`}</span>
          </p>
          {delivery_details?.pickup ? (
            <p className={style['order__summary-item']}>
              <strong>Service fee</strong>
              <span>
                {order.total_service_fee
                  ? `$${currencyFormat(order.total_service_fee)}`
                  : 'Free'}
              </span>
            </p>
          ) : (
            <p className={style['order__summary-item']}>
              <strong>Delivery fee</strong>
              <span>
                {order.total_delivery
                  ? `$${currencyFormat(order.total_delivery)}`
                  : 'Free'}
              </span>
            </p>
          )}
          <p className={style['order__summary-item']}>
            <strong>Taxes</strong>
            <span>
              {order.total_tax
                ? `$${currencyFormat(order.total_tax)}`
                : 'Included'}
            </span>
          </p>
          {order.total_discounts > 0 ? (
            <p className={style['order__summary-item-discounts']}>
              <strong>Discounts</strong>
              <span>{`- $${currencyFormat(order.total_discounts)}`}</span>
            </p>
          ) : null}
          <p className={style['order__summary-item']}>
            <strong>Total paid</strong>
            <span>{`$${currencyFormat(order.total_price)}`}</span>
          </p>
        </div>
      </div>
      <div className={style['order__info-route-map']}>
        <div className={style.order__information}>
          <div className={style['order__info-thankyou']}>
            <Heading level={5}>Billing information</Heading>
            <p>{client}</p>
            <p>{address.phone}</p>
            <p>
              <Icon name="location" />
              {addressLabel}
            </p>
          </div>
          <div className={style['order__info-thankyou']}>
            <Heading level={5}>Shipping information</Heading>
            <p>{client}</p>
            <p>{address.phone}</p>
            <p>
              <Icon name="location" />
              {addressLabel}
            </p>
          </div>
        </div>
        <OrderRouteMap
          address1={{
            ...order.delivery_address,
            title: 'Destination of your order',
          }}
          address2={
            order.delivery_details && order.delivery_details.delivery
              ? {
                  // TODO: Change the value of latitude and longitude
                  // whith the information of driver position.
                  latitude: order.delivery_address.latitude - 0.05,
                  longitude: order.delivery_address.longitude - 0.05,
                  title: 'Current position of your order',
                }
              : null
          }
          className={style.order__map}
        />
      </div>
    </div>
  );
}

export default OrderDetail;
