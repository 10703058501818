import { FC } from 'react';
import {
  StatsSlice,
  StatsSliceWithImagesAndDescriptions,
} from 'prismicio-types';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';

import { Container } from '@/components';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';

import style from './withimagesanddescriptionsvariation.module.scss';
import classNames from 'classnames';

export const WithImagesAndDescriptionsVariation: FC<{
  slice: StatsSliceWithImagesAndDescriptions & StatsSlice;
}> = ({ slice }) => {
  const background = slice?.primary?.background_color as string;
  const marginBottom = slice?.primary?.bottom_spacing;
  const borderTop = slice?.primary?.border_section as string;
  const borderBottom = slice?.primary?.border_section as string;
  const paddingSection = slice?.primary?.padding_section;

  return (
    <Container
      as="section"
      className={classNames(
        style.stats__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      style={{ background, borderTop, borderBottom }}
    >
      {slice?.primary?.title && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              alignSelf: slice.primary.title_align,
              textAlign: slice.primary.title_align,
            },
            style.stats__title,
          )}
          field={slice.primary.title}
        />
      )}
      <dl className={style.stats__list}>
        {slice?.items.map((item, index) => (
          <div
            className={style.stats__element}
            key={`${item.stat_title}-${index}`}
            style={{ alignItems: item.stat_align }}
          >
            <div
              className={classNames(style.stats__element_header, {
                [style.stats__element_header_row]: slice.primary.center_stats,
              })}
            >
              <div className={style.stats__element_image}>
                <PrismicNextImage fallbackAlt="" field={item.stat_image} />
              </div>
              <dt className={style.stats__element_title}>{item.stat_title}</dt>
              <dd className={style.stats__element_value}>{item.stat_value}</dd>
            </div>

            <dd
              className={style.stats__element_description}
              style={{ textAlign: item.stat_align }}
            >
              {item.stat_description}
            </dd>
          </div>
        ))}
      </dl>
    </Container>
  );
};
