'use client';

import React from 'react';

import { Container } from '@/components';

import style from './ErrorFallback.module.scss';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <Container className={style.fallback_container}>
      <section className={style.fallback_info}>
        <h1> Something went wrong:</h1>
        <p>{error.message}</p>
      </section>
      <button onClick={resetErrorBoundary}>Retry</button>
    </Container>
  );
};

export default ErrorFallback;
