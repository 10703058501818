/* eslint-disable max-len */
'use client';

// import { usePathname, useSearchParams } from 'next/navigation';
// import { useEffect } from 'react';

// const Listrack = () => {
//   const pathname = usePathname();
//   const searchParams = useSearchParams();

//   useEffect(() => {
//     if (typeof _ltk_util === 'undefined') {
//       return;
//     }

//     // eslint-disable-next-line no-undef
//     const ltkUtil = _ltk_util;

//     if (!ltkUtil) {
//       return;
//     }

//     const ltkSessionDepth = ltkUtil.getCookie('ltkpopup-session-depth');

//     if (!ltkSessionDepth) {
//       ltkUtil.setCookie(
//         'ltkpopup-session-depth',
//         `1-${window?.history?.length}`,
//         30,
//         ltkUtil.getCookieDomain(),
//         '/',
//       );
//       return;
//     }

//     ltkUtil.setCookie(
//       'ltkpopup-session-depth',
//       `${parseInt(ltkSessionDepth?.split('-')?.[0]) + 1}-${
//         window?.history?.length
//       }`,
//       30,
//       ltkUtil.getCookieDomain(),
//       '/',
//     );

//     const ltkSessionDepthValues = ltkSessionDepth?.split('-');

//     if (
//       parseInt(ltkSessionDepthValues?.[0]) >= 2 &&
//       parseInt(ltkSessionDepthValues?.[1]) >= 3
//     ) {
//       const ltkSubscribe = ltkUtil.getCookie('ltk-subscribed');
//       // Don't Show Button if user is already subscribed
//       if (!ltkSubscribe) {
//         // Setup Wrapper HTML
//         const ltkWrap = document.createElement('div');
//         ltkWrap.id = 'ltk-custom-button-container';

//         // Setup Button HTML
//         const ltkButton = document.createElement('button');
//         ltkButton.id = 'ltk-custom-button';
//         ltkButton.type = 'button';
//         ltkButton.innerHTML = 'Get 15% Off';
//         ltkButton.setAttribute('tabindex', '0');
//         ltkButton.setAttribute(
//           'onclick',
//           '_ltk.Popup.openManualByName("Caliva Manual Button")',
//         );

//         // Setup Button Styling
//         const style = document.createElement('style');
//         style.innerHTML =
//           // eslint-disable-next-line max-len
//           '#ltk-custom-button-container {position: fixed;bottom: 0px;left: 0px;width: 100%;background: transparent;text-align: center;z-index: 99999;pointer-events: none;}button#ltk-custom-button {background: #2ba7b2;color: #ffffff;font-size: 18px;padding: 15px 24px;border-radius: 0px;margin: 18px;border: 0px;outline: none;appearance: button;pointer-events: auto;font-weight: 700;text-transform: uppercase;}';

//         // Append Styles to Head Tag
//         document.getElementsByTagName('head')[0].appendChild(style);

//         // Add Button HTML to Wrapper
//         ltkWrap.append(ltkButton);

//         // Add Button to Site
//         document.body.appendChild(ltkWrap);
//       }
//     }
//   }, [pathname, searchParams]);

//   return null;
// };

const Listrack = () => {
  return null;
};

export default Listrack;
