import { FC, startTransition, useRef, useState } from 'react';
import classNames from 'classnames';

import { CartLineItem } from '@/types';
import { currencyFormat, formatWeightLabel } from '@/utils';
import { Button, IconSvg, Image } from '@/components';
import { useProducts } from '@/hooks';

import { Price } from '../atoms/price';

import styles from './BundleProductsMenu.module.scss';

interface BundleProductsMenuProps {
  bundleProducts: CartLineItem[];
}

const BundleProductsMenu: FC<BundleProductsMenuProps> = ({
  bundleProducts,
}) => {
  const { products, update } = useProducts();

  const bundleId = bundleProducts?.[0]?.bundle_id;

  const productItems = bundleProducts.map((item, index) => (
    <ProductPreviewItem item={item} key={item.id ?? index} />
  ));

  if (!bundleProducts.length) return <EmptyBundleMessage />;

  const removeAllBundleProducts = () => {
    const newProducts = products.filter(
      ({ bundle_id }) => bundle_id !== bundleId,
    );

    update(newProducts);
  };

  return (
    <div className={styles.products_preview_container}>
      <Button
        className={styles.clear_all_button}
        onClick={removeAllBundleProducts}
      >
        Clear all
      </Button>
      <ul className={styles.products_preview}>{productItems}</ul>
    </div>
  );
};

const EmptyBundleMessage = () => (
  <div className={styles.bundle_empty_message}>
    <IconSvg name="gift-box" />
    <h4 className={styles.bundle_empty_message__title}>
      Unlock your bundle deal!
    </h4>
    <p className={styles.bundle_empty_message__subtitle}>
      Start adding products to your cart and enjoy exclusive savings.
    </p>
  </div>
);

const ProductPreviewItem: FC<{ item: CartLineItem }> = ({ item }) => {
  const isSpecial = item.weightVariant?.salePrice! < item.weightVariant?.price!;

  return (
    <div className={styles.preview_item}>
      <div className={styles.preview_item__left}>
        <ProductImage item={item} />
        <div>
          <ProductInfo item={item} />
          <ProductCounter item={item} />
        </div>
      </div>
      <div className={styles.preview_item__right}>
        <ProductRemoveButton item={item} />
        <Price
          className={styles.preview_item__price}
          price={`$${currencyFormat(item.weightVariant?.price!)}`}
          salePrice={`$${currencyFormat(item.weightVariant?.salePrice!)}`}
          special={isSpecial}
        />
      </div>
    </div>
  );
};

const ProductImage: FC<{ item: CartLineItem }> = ({ item }) => (
  <figure className={styles.preview_item__image}>
    <Image
      alt={item?.name ?? ''}
      fallback="/images/product-placeholder.svg"
      height={96}
      priority
      src={item?.asset?.image ?? ''}
      style={{
        objectFit: 'contain',
      }}
      width={96}
    />
  </figure>
);

const ProductInfo: FC<{ item: CartLineItem }> = ({
  item: { name, weightVariant },
}) => (
  <div className={styles.preview_item__info}>
    <div className={styles.preview_item__info_name}>
      {name}{' '}
      {weightVariant?.type !== 'each'
        ? formatWeightLabel(weightVariant?.type!)
        : ''}
    </div>
  </div>
);

const ProductCounter: FC<{ item: CartLineItem }> = ({
  item: { quantity, id },
}) => {
  const { products, update } = useProducts();
  const changeTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [counterQty, setCounterQty] = useState(quantity);

  const updateQuantity = (qty: number) => {
    const newProducts = products.map(product =>
      product.id === id ? { ...product, quantity: qty } : product,
    );

    update(newProducts);
  };

  const change = (operation: 'minus' | 'plus') => {
    const _quantity = operation === 'minus' ? counterQty - 1 : counterQty + 1;
    setCounterQty(_quantity);

    clearTimeout(changeTimeoutRef.current);

    changeTimeoutRef.current = setTimeout<number[]>(() => {
      startTransition(() => {
        updateQuantity(_quantity);
      });
    }, 300);
  };

  return (
    <div className={styles.preview_item__counter}>
      <Button
        aria-label="Decrement quantity"
        className={styles.counter__operation}
        color="link"
        disabled={counterQty <= 1}
        icon
        onClick={() => change('minus')}
      >
        <IconSvg name="minus" />
      </Button>
      <div className={styles.counter__count}>{counterQty}</div>
      <Button
        aria-label="Increment quantity"
        className={classNames(
          styles.counter__operation,
          styles.counter__operation_rigth,
        )}
        color="link"
        icon
        onClick={() => change('plus')}
      >
        <IconSvg name="plus" />
      </Button>
    </div>
  );
};

const ProductRemoveButton: FC<{ item: CartLineItem }> = ({ item }) => {
  const { products, update } = useProducts();

  const handleRemove = () => {
    const newLineItems = products?.filter(p => {
      return p?._id !== (item as CartLineItem)._id;
    });

    update(newLineItems);
  };

  return (
    <Button className={styles.remove_button} onClick={handleRemove}>
      <IconSvg name="delete" />
    </Button>
  );
};

export { BundleProductsMenu };
