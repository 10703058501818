import { Counter } from '@/components';
import { NOT_DEFINED } from '@/constants';

import {
  ProductActionFavorite,
  ProductBadgeContainer,
  ProductCategory,
  ProductIconShopping,
  ProductImage,
  ProductInfo,
  ProductInfoPack,
  ProductName,
  ProductPrice,
  ProductVariants,
} from '../Product.components';

import style from '../product.module.scss';
import styleVariation from '../variations/ProductCardDefault.module.scss';

import { IProductCard } from '../Product';
import classNames from 'classnames';
import React from 'react';
import { startCase } from 'lodash';
import { useFeaturedFlag } from 'hooks/useFeaturedFlag';

const ProductCardDefault: React.FC<IProductCard> = ({
  quantity,
  isSpecial,
  className,
  product,
  variants,
  analyticsListName,
  weightVariant,
  updateWeinghtVariant,
}) => {
  const { isTaxAppliedMessage } = useFeaturedFlag();
  return (
    <div className={classNames(style.product, className)}>
      <ProductBadgeContainer
        isSpecial={isSpecial}
        product={product}
        weightVariant={weightVariant}
      />
      <ProductImage analyticsListName={analyticsListName} product={product} />
      <div className={style.product__body}>
        <div className={style.product__name_container}>
          <ProductInfo
            className={style.brand}
            info={product.brand_name || NOT_DEFINED}
            type="default"
          />
          <ProductName
            analyticsListName={analyticsListName}
            product={product}
          />
        </div>
        <div className={style.info__brand_category}>
          <ProductInfo
            className={style.category}
            info={
              startCase(product?.subcategory as string) ||
              startCase(NOT_DEFINED)
            }
            type="default"
          />
          <ProductCategory
            info={product?.flower_type as string}
            type="outlined"
          />
        </div>
        <ProductInfoPack product={product} />
        <div className={style.info__price_variation}>
          <ProductPrice
            isOverflow={variants?.length === 1}
            isSpecial={isSpecial}
            weightVariant={weightVariant}
          />
          <ProductVariants
            type={'default'}
            updateWeightVariant={updateWeinghtVariant}
            variants={variants}
            weightVariant={weightVariant}
          />
          {isTaxAppliedMessage && (
            <span className={style.tax_message}>(tax. included)</span>
          )}
        </div>
        <div className={style.product__actions_footer}>
          <Counter
            className={classNames(
              style.actions__counter,
              styleVariation.actions__counter_mobile,
            )}
            onCard
            product={product}
            selectedWeightVariant={weightVariant}
          />
          <div
            className={classNames(
              style.actions__body,
              styleVariation.action__body_mobile,
            )}
          >
            <ProductIconShopping quantity={quantity} />
            <ProductActionFavorite product={product} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductCardDefault };
