import { FC, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { Button, Container, Icon, Input } from '@/components';

import style from './zipcodes.module.scss';
import { ZipCodesSlice, ZipCodesSliceDefaultItem } from '@/types';
import { PrismicRichText } from '@prismicio/react';
import { getSlicePadding, htmlSerializer } from '@/utils';
import classNames from 'classnames';

const LOADZIP: number = 90;

const Zipcodes: FC<ZipCodesSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing ?? '';
  const paddingSection = slice?.primary?.padding_section ?? '';
  const borderColor = slice?.primary?.pill_border_color ?? '';
  const pillBackgroundColor = slice?.primary?.pill_background_color ?? '';
  const backgroundColor = slice?.primary?.background_color ?? '';
  const color = slice?.primary?.pill_text_color ?? '';
  const borderRadius = slice?.primary?.pill_border_radius ?? '';

  const content: string[] = useMemo(
    () => getZipCodesItems(slice?.items) as string[],
    [slice.items],
  );
  const [loadedZip, setLoadedZip] = useState(LOADZIP);
  const [filterQueryZip, setFilterQueryZip] = useState('');
  const [filterZip, setFilterZip] = useState(content);

  const handleSearchClickZip = e => {
    e.preventDefault();

    const newZips = content.filter(zip =>
      zip.toString().includes(filterQueryZip),
    );

    setFilterZip(newZips);
  };

  useEffect(() => {
    if (filterQueryZip === '') {
      setFilterZip(content);
    }
  }, [filterQueryZip]);

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(style.zipcodes_section, {
        ['spacing_bottom']: marginBottom,
      })}
      style={{ background: backgroundColor }}
    >
      <Container
        className={classNames(
          style.zipcodes_container,
          getSlicePadding(paddingSection),
        )}
      >
        <PrismicRichText
          components={htmlSerializer}
          field={slice.primary.zipcodes_header}
        />
        <form
          className={style['zipcodes-page__filter']}
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Input
            className={style.input}
            name="zipcodes-search"
            onChange={e => setFilterQueryZip(e.target.value)}
            type="search"
            value={filterQueryZip}
          >
            Search zip codes
          </Input>
          <Button
            aria-label="Search zip codes"
            className={style.button}
            color="primary"
            onClick={handleSearchClickZip}
            type="submit"
          >
            Search
          </Button>
          <Icon className={style.icon} name="search" />
        </form>

        <div className={style.zipcodes__description} />
        <ol className={style['zipcodes-page__list']}>
          {sortBy(filterZip)
            .slice(0, loadedZip)
            .map(code => (
              <li
                key={code}
                style={{
                  border: `1px solid ${borderColor}`,
                  backgroundColor: pillBackgroundColor,
                  borderRadius,
                  color,
                }}
              >
                {code}
              </li>
            ))}
        </ol>
        {loadedZip < content.length && filterZip.length > 0 && (
          <Button
            className={style['collection__load-more']}
            color="link"
            onClick={() => setLoadedZip(loadedZip + LOADZIP)}
          >
            Load more zip codes
          </Button>
        )}
      </Container>
    </section>
  );
};

const getZipCodesItems = (items: ZipCodesSliceDefaultItem[]) =>
  Array.from(
    new Set(
      items
        .map(({ zipcode_id }) =>
          zipcode_id?.split(',').map(item => item.trim()),
        )
        .flat()
        .filter(e => e),
    ),
  );

export default Zipcodes;
