import { Placeholder } from '@/components';

import styles from './SummaryTotalSkeleton.module.scss';

const SummaryTotalSkeleton = () => {
  return (
    <div className={styles.summary_skeleton__container}>
      <div className={styles.summary_skeleton}>
        <Placeholder className={styles.summary_skeleton__item_large} />
        <Placeholder className={styles.summary_skeleton__item} />
      </div>
      <div className={styles.summary_skeleton}>
        <Placeholder className={styles.summary_skeleton__item_large} />
        <Placeholder className={styles.summary_skeleton__item} />
      </div>
      <div className={styles.summary_skeleton}>
        <Placeholder className={styles.summary_skeleton__item_large} />
        <Placeholder className={styles.summary_skeleton__item} />
      </div>
    </div>
  );
};

export { SummaryTotalSkeleton };
