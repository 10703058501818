import { gql } from '@apollo/client';

export const LIST_BRANDS_QUERY = gql`
  query listBrands($account_id: String!, $store_id: String!) {
    listBrands(account_id: $account_id, store_id: $store_id) {
      id
      name
      phone_number
      website
      vendors
      enabled
      created_at
      updated_at
      slug
      asset {
        thumbnail
        image
        image_large_size
        image_medium_size
        image_extra_large_size
      }
    }
  }
`;
