import { FC, useRef, useState } from 'react';
import * as prismicH from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import { useForm, useSubscribeToCRM, useValidator } from '@/data';
import {
  getCrmListIds,
  getSlicePadding,
  htmlSerializerWithProps,
} from '@/utils';
import {
  Alert,
  Button,
  Container,
  Input,
  useAuth,
  useData,
} from '@/components';
import classNames from 'classnames';
import styles from './subscribeslice.module.scss';
import { SubscribeSlice as SubscribeSliceType } from '@/types';

const SUCCESS_MESSAGE = 'Thank you for subscribing!';
const ERROR_MESSAGE = 'An error occurred. Please, try again later.';

const SubscribeSlice: FC<SubscribeSliceType> = slice => {
  const { updateUserAttributes } = useAuth();
  const { crm } = useData();
  const ref = useRef<HTMLFormElement>(null);
  const { inputs, inputChange, genericChange } = useForm({ email: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    isError: false,
    isOpen: false,
    message: '',
  });
  const { primary } = slice;
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const borderTop = !primary?.disable_border_top
    ? (primary?.border_section as string)
    : undefined;
  const borderBottom = primary?.border_section as string;
  const contentAlign = primary?.content_align?.toLowerCase() || 'left';
  const maxHeaderWidth = primary?.max_header_width
    ? `${primary?.max_header_width}%`
    : undefined;
  const maxDescriptionWidth = primary?.max_description_width
    ? `${primary?.max_description_width}%`
    : undefined;

  const { setIsSubmited, isInvalid } = useValidator(
    [
      {
        field: 'email',
        message: 'Email is required.',
        method: 'isEmpty',
        validWhen: false,
      },
      {
        field: 'email',
        message: 'The email format is incorrect.',
        method: 'isEmail',
        validWhen: true,
      },
    ],
    inputs,
  );

  const { subscribeToCRM } = useSubscribeToCRM({
    onCompleted: () => {
      updateUserAttributes({
        'custom:ACCEPT_MARKETING': 'true',
      });
      setAlert({
        isError: false,
        isOpen: true,
        message: SUCCESS_MESSAGE,
      });
      setIsSubmited(false);
      setIsLoading(false);
      genericChange('email', '');
    },
    onError: () => {
      setIsLoading(false);
      setAlert({
        isError: true,
        isOpen: true,
        message: ERROR_MESSAGE,
      });
    },
  });

  const onSubmit = e => {
    if (ref?.current?.checkValidity()) {
      e.preventDefault();
      sendForm();
    }
  };

  const sendForm = async () => {
    if (isInvalid) {
      setAlert({
        isError: true,
        isOpen: true,
        message: 'The email format is incorrect.',
      });
      setIsSubmited(true);
      return;
    }

    setIsLoading(true);

    try {
      const crmListIds = getCrmListIds(crm);
      subscribeToCRM({
        variables: {
          input: {
            email: inputs.email,
            lists: crmListIds,
            source: 'FOOTER',
          },
        },
      });
    } catch (error) {
      setIsLoading(false);
      setAlert({
        isError: true,
        isOpen: true,
        message: ERROR_MESSAGE,
      });
    }
  };

  const renderTextSlice = (
    text: any,
    color: string,
    maxWidth: string | undefined,
  ) => {
    if (!prismicH.asText(text)) {
      return null;
    }

    return (
      <PrismicRichText
        components={htmlSerializerWithProps({
          color: color,
          maxWidth: maxWidth,
        })}
        field={text}
      />
    );
  };

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{
        background: primary?.background_color ?? '',
        borderTop,
        borderBottom,
      }}
    >
      <Container
        className={classNames(
          styles.subscribe__container,
          styles[`subscribe__container_align_${contentAlign}`],
        )}
      >
        {(prismicH.asText(primary?.header) ||
          prismicH.asText(primary?.description)) && (
          <div className={styles.header}>
            {renderTextSlice(
              primary?.header,
              primary?.header_color ?? '',
              maxHeaderWidth,
            )}
            {renderTextSlice(
              primary?.description,
              primary?.description_color ?? '',
              maxDescriptionWidth,
            )}
          </div>
        )}
        <div className={styles.content}>
          <Alert
            error={alert.isError}
            isOpen={alert.isOpen}
            toggle={() =>
              setAlert({
                isError: false,
                isOpen: false,
                message: '',
              })
            }
          >
            {alert.message}
          </Alert>
          <form className={styles.subscribe__form} ref={ref}>
            <Input
              name="email"
              onChange={inputChange}
              placeholder={primary?.email_placeholder ?? ''}
              required
              type="email"
              value={inputs.email}
            />
            <Button
              className={styles.subscribe__button}
              color={primary?.cta_type ?? 'primary'}
              loading={isLoading}
              onClick={onSubmit}
              type="submit"
            >
              {primary?.cta_text ?? 'Subscribe'}
            </Button>
          </form>
          {prismicH.asText(primary?.subscription_text) && (
            <div className={styles.content__message}>
              {renderTextSlice(
                primary?.subscription_text,
                primary?.description_color ?? '',
                undefined,
              )}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default SubscribeSlice;
