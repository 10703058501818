/* eslint-disable max-len */
import { FC, Fragment } from 'react';
import { useRouter } from 'next/router';
import { useGetOrder } from '@/data';
import { Icon, useAuth } from '@/components';
import { orderLastStatus, orderStatus } from './constant';
import classNames from 'classnames';
import styles from './status.module.scss';

const Status: FC = () => {
  const { isAuth } = useAuth();
  const router = useRouter();

  const { data: order } = useGetOrder({
    notifyOnNetworkStatusChange: true,
    skip: !isAuth || !router.query.id,
    variables: {
      entity_id: router.query.id as string,
    },
  });

  const stateDefault = orderLastStatus.filter(state => state.default);
  const stateFinalActivate = orderLastStatus.filter(s => s.name === status);

  const states = orderStatus.concat(
    stateFinalActivate.length ? stateFinalActivate : stateDefault,
  );
  const indexProgress = states.findIndex(state => {
    return state.name == order?.status;
  }, 0);

  const colorDots = (pos: number) => {
    if (pos < indexProgress) {
      return '#0b3b4a';
    }

    return '#b6c4c9';
  };

  return (
    <div className={styles['order-details__status']}>
      <ol>
        {states.map((state, pos) => {
          const renderDots = pos < states.length - 1;

          return (
            <Fragment key={pos}>
              <li
                className={classNames({
                  [styles.active]: state.name === status,
                })}
              >
                <figure
                  className={classNames({
                    [styles.active]: state.name === status,
                  })}
                >
                  <Icon name={state.icon} />
                </figure>
                {state.name}
              </li>
              {renderDots && (
                <div
                  className={styles.dotted}
                  style={{
                    backgroundImage: `radial-gradient(${colorDots(
                      pos,
                    )} 2.5px, transparent 0)`,
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </ol>
    </div>
  );
};
export default Status;
