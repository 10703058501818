export const PaginationOptions: IOption[] = [
  { label: '3 reviews', value: 3 },
  { label: '5 reviews', value: 5 },
  { label: '7 reviews', value: 7 },
  { label: '10 reviews', value: 10 },
];

export const RatingOptions: IOption[] = [
  { label: '5 Stars', value: 5 },
  { label: '4 Stars', value: 4 },
  { label: '3 Stars', value: 3 },
  { label: '2 Stars', value: 2 },
  { label: '1 Star', value: 1 },
];

export const DateOptions: IOption[] = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Three days ago', value: 'treedays' },
  { label: 'Last week', value: 'week' },
  { label: 'Last fifteen days', value: 'fifteen' },
  { label: 'Last month', value: 'month' },
  { label: 'Last three months', value: 'treemonth' },
  { label: 'Last six months', value: 'sismonth' },
  { label: 'Last year', value: 'year' },
];

export interface IOption {
  label: string;
  value: string | number;
}
