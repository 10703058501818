import { FC } from 'react';
import { useScreenWidth } from '@/hooks';
import { PrismicNextImage } from '@prismicio/next';
import { Container, IconSvg, Sharing } from '@/components';
import styles from './bannerblog.module.scss';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ColorField } from '@prismicio/client';
import { convertHexadecimalFormatToRGB } from '@/utils';

const BannerBlog: FC<IBannerBlog> = postBanner => {
  const { width } = useScreenWidth();
  const {
    headerImage,
    headerTextColor,
    headerText,
    publishDate,
    publishDateColor,
    fullWidth,
    maxHeigthDesktop,
    maxHeigthMobile,
    showEffect,
    slug,
    opacity,
  } = postBanner;

  const sizes = `(min-width: 2048px)  2008px, (min-width: 1536px)  1496px,
        (min-width: 1280px)  1240px, (min-width: 1024px) 984px,
        (min-width: 768px) 728px, 100vw`;

  const borderRadius =
    width > 640 && !fullWidth ? `${postBanner?.borderRadius}px` : 0;

  const overlay = postBanner?.overlay
    ? convertHexadecimalFormatToRGB(postBanner?.overlay)
    : null;

  const getMaxHeight = () => {
    const paddingTop = postBanner?.fullWidth ? '25.78%' : '23.75%';

    if (width > 640 && maxHeigthDesktop) {
      return `min(${maxHeigthDesktop}px, ${paddingTop})`;
    }

    if (maxHeigthMobile && width < 640) {
      return `min(${maxHeigthMobile}px, ${paddingTop})`;
    }

    return;
  };

  const sharingStr = `${process.env.NEXT_PUBLIC_STORE_DOMAIN}/blog/${slug}`;

  const renderImage = (banner, sizes, classCss) => {
    return (
      <figure
        className={classNames(classCss, {
          [styles.placeholder]: !banner?.url,
        })}
        style={{
          borderRadius,
          paddingTop: getMaxHeight(),
        }}
      >
        {banner && banner?.url && (
          <PrismicNextImage
            fallbackAlt=""
            field={banner}
            fill
            priority
            sizes={sizes}
            style={{
              borderRadius,
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        )}
        {!banner?.url && !postBanner?.overlay && (
          <div>
            <IconSvg name="image" />
            <p>
              <b>There is no banner</b>
              <br />
              Kindly establish a banner
            </p>
          </div>
        )}
      </figure>
    );
  };

  return (
    <div
      className={classNames(styles.page_banner__section, {
        [styles.page_banner__section_fullwidth]: fullWidth,
      })}
    >
      <Container
        className={classNames(styles.page_banner__container, {
          [styles.page_banner__container_fullwidth]: fullWidth,
        })}
      >
        {renderImage(headerImage, sizes, styles.page_banner__desktop)}
        {renderImage(
          headerImage['Normal Size'],
          '(min-width: 640px) 610px, 100wv',
          styles.page_banner__mobile,
        )}
        {(overlay || showEffect) && (
          <span className={styles.overlay__container}>
            <span
              className={classNames(styles.overlay, {
                [styles.overlay_effect_shadow]: showEffect,
              })}
              style={{
                backgroundColor:
                  overlay && !showEffect
                    ? `rgba(${overlay?.r}, ${overlay?.g}, ${overlay?.b}, ${opacity})`
                    : undefined,
                borderRadius,
              }}
            />
          </span>
        )}
        {headerText && (
          <Container className={styles.page__banner_body}>
            <div
              className={classNames(styles.page__banner_text, {
                [styles.text__with_maximum_height]: maxHeigthDesktop,
              })}
            >
              {headerText && (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: headerText,
                  }}
                  style={{
                    color: headerTextColor!,
                  }}
                />
              )}
              {publishDate && (
                <div className={styles.page__banner_info}>
                  <time
                    className={styles.page__banner_timer}
                    dateTime={publishDate.toString() || ''}
                    style={{
                      color: publishDateColor!,
                    }}
                  >
                    {format(new Date(publishDate), 'PPP')}
                  </time>
                  <Sharing
                    className={styles.page__banner_sharing}
                    iconStyle={{ color: publishDateColor }}
                    include={['facebook', 'twitter', 'whatsapp']}
                    media={undefined}
                    url={sharingStr}
                  />
                </div>
              )}
            </div>
          </Container>
        )}
      </Container>
    </div>
  );
};

export default BannerBlog;

export interface IBannerBlog {
  headerImage?: any;
  headerTextColor?: ColorField;
  headerText?: string;
  publishDate?: string;
  publishDateColor?: ColorField;
  fullWidth?: boolean;
  borderRadius?: number;
  slug: string;
  maxHeigthDesktop?: number;
  maxHeigthMobile?: number;
  showEffect?: boolean;
  overlay?: string;
  opacity?: number;
}
