import { gql } from '@apollo/client';

export const LIST_NAVIGATIONS_QUERY = gql`
  query listNavigations($account_id: String!, $store_id: String!) {
    listNavigations(account_id: $account_id, store_id: $store_id) {
      id
      title
      slug
      status
      menu_items {
        id
        parent_menu_item_id
        name
        position
        target
        url
      }
    }
  }
`;
