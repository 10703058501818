import { Product } from '@/types';

const getProductPrice = (product: Product) =>
  product?.variants?.[0]?.price !== product?.variants?.[0]?.salePrice &&
  !!product?.variants?.[0]?.salePrice
    ? product?.variants?.[0]?.salePrice
    : product?.variants?.[0]?.price;

const priceFormat = (value: number) => Math.round(value + Number.EPSILON) / 100;

export { getProductPrice, priceFormat };
