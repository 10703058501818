import { FC } from 'react';

import { capitalize } from 'lodash';
import { getBogoSpecialURL } from '@/utils';
import { Heading, Icon, IconSvg, Link, Slider } from '@/components';
import { Discount, GProduct } from '@/types';
import styles from './productdetails.module.scss';
import { useIsMobile } from 'hooks/useIsMobile';
interface IProductBogo {
  product: GProduct;
}

const ProductBogo: FC<IProductBogo> = ({ product }) => {
  const bogoList = product?.discounts?.filter(
    item => item?.discount_method?.toUpperCase() === 'BOGO',
  );

  if (!bogoList?.length) {
    return null;
  }

  return (
    <section className={styles.bogo_list}>
      <Heading className={styles.title} level={3}>
        Active Deals
      </Heading>
      <div className={styles.content}>
        {bogoList?.map((bogo, index) => (
          <Link
            className={styles.link}
            href={getBogoSpecialURL(bogo)}
            key={index}
          >
            <figure>
              <IconSvg name="gift" />
            </figure>
            <span>{capitalize(bogo?.discount_title)}</span>
          </Link>
        ))}
      </div>
    </section>
  );
};

const NewProductBogo: FC<IProductBogo> = ({ product }) => {
  const { isMobile } = useIsMobile();

  const bogoList = product?.discounts?.filter(
    item => item?.discount_method?.toUpperCase() === 'BOGO',
  );

  if (!bogoList?.length) {
    return null;
  }

  return (
    <section className={styles.active_bogo_section}>
      <Slider
        navigation={false}
        pagination={false}
        showSliderButtons={false}
        showSliderHeader={false}
        showSlidesOutsideContainer
        spaceBetween={isMobile ? 4 : 12}
      >
        {bogoList?.map((bogo, index) => (
          <BogoCard bogo={bogo} key={index} />
        ))}
      </Slider>
    </section>
  );
};

interface IBogoCard {
  bogo: Discount;
}

const BogoCard: FC<IBogoCard> = ({ bogo }) => {
  const BOGO_LABEL_TITLE = 'SHOP DEAL';

  return (
    <Link className={styles.bogo_container} href={getBogoSpecialURL(bogo)}>
      <figure className={styles.bogo_card}>
        <IconSvg name="bogo-card" />
      </figure>
      <div className={styles.bogo_name}>
        <span>{capitalize(bogo?.discount_title)}</span>
      </div>
      <div className={styles.bogo_link}>
        <span>{BOGO_LABEL_TITLE}</span>
        <Icon name="arrow-right" />
      </div>
      <span className={styles.bogo_deal_label}>DEAL</span>
    </Link>
  );
};

export { ProductBogo, NewProductBogo };
