import { FC } from 'react';

import { PrismicRichText } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';

import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceWithFeaturesList,
} from '@/types';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';
import { htmlSerializerWithProps } from '@/utils';
import {
  DefaultComponentSection,
  textAlign,
} from '../ImageWithTextSideBySide.components';

import style from './withfeatureslistvariation.module.scss';

export const WithFeaturesListVariation: FC<{
  slice: ImageWithTextSideBySideSliceWithFeaturesList &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);

  return (
    <DefaultComponentSection slice={slice}>
      {slice.primary.feature_title && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              color: slice.primary.feature_title_color,
            },
            style.feature__title,
          )}
          field={slice.primary.feature_title}
        />
      )}
      {filteredItems?.[0] && (
        <div className={style.items_section}>
          {filteredItems?.map((item, index) => (
            <div className={style.item} key={index}>
              {slice.primary.list_icon.url && (
                <figure
                  className={style.image}
                  style={{
                    alignSelf: textAlign(
                      slice.primary.content_horizontal_align as string,
                    ),
                  }}
                >
                  <PrismicNextImage
                    fallbackAlt=""
                    field={slice.primary.list_icon}
                  />
                </figure>
              )}
              {item.feature_text && (
                <p
                  className={style.title}
                  style={{
                    color: slice.primary.feature_description_color as string,
                  }}
                >
                  {item.feature_text}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </DefaultComponentSection>
  );
};
