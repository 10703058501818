'use client';

import { FC, useRef } from 'react';
import { usePathname } from 'next/navigation';

import { Icon, Link, MegaMenuCustom, useData } from '@/components';

import style from './nav.module.scss';

const Nav: FC = () => {
  const { navigation } = useData();
  const pathname = usePathname();
  const navbarRef = useRef<HTMLElement | null>(null);

  return (
    <nav className={style.navbar} ref={navbarRef}>
      <ul className={style.navbar_list} key={pathname}>
        {navigation.data?.links?.map((item: any, index: number) => {
          const { type, uid } = item.external_link;

          if (['custom_mega_menu', 'mega_nav'].includes(type)) {
            return (
              <li className={style.navbar_list__item} key={index}>
                <h4>{item?.label}</h4>
                <Icon name="angle-up" />
                {!!uid && (
                  <MegaMenuCustom
                    className={style.navbar_menu}
                    itemID={uid}
                    navbarRef={navbarRef}
                  />
                )}
              </li>
            );
          }

          return (
            <li className={style.navbar_list__item} key={index}>
              <Link href={item?.link}>{item?.label}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
