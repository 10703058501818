import React from 'react';

import { PotentialAction, SiteLinksSearchBoxJsonLdProps } from '@/types';

import { JsonLd } from './jsonld';

function SiteLinksSearchBoxJsonLd({
  type = 'WebSite',
  keyOverride,
  potentialActions,
  ...rest
}: SiteLinksSearchBoxJsonLdProps) {
  function setPotentialAction(action: PotentialAction) {
    if (action) {
      const { target, queryInput } = action;
      return {
        '@type': 'SearchAction',
        'query-input': `required name=${queryInput}`,
        target: `${target}={${queryInput}}`,
      };
    }
    return undefined;
  }

  const data = {
    ...rest,
    potentialAction: potentialActions.map(setPotentialAction),
  };

  return (
    <JsonLd
      keyOverride={keyOverride}
      type={type}
      {...data}
      scriptKey="jsonld-siteLinksSearchBox"
    />
  );
}

export default SiteLinksSearchBoxJsonLd;
