import { useSyncExternalStore } from 'react';

const useIsServerSide = () => {
  const isServerSide = useSyncExternalStore(
    subscribe,
    getSnapshot,
    getServerSnapshot,
  );

  return { isServerSide };
};

const getSnapshot = () => {
  return false;
};

const getServerSnapshot = () => {
  return true;
};

const subscribe = (callback: () => void) => {
  window.onload = callback;

  return () => (window.onload = null);
};

export { useIsServerSide };
