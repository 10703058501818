import { useEffect } from 'react';
import Head from 'next/head';
import { useData } from '@/components';

function Pwa({ business, themeColor }) {
  const { header } = useData();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/sw.js')
          .then(
            /*registration*/ () => {
              // eslint-disable-next-line no-console
              console.info('Service worker registered.');
            },
          )
          .catch(e => {
            // eslint-disable-next-line no-console
            console.error('Service worker error:', e);
          });
      });
    }
  }, []);

  return (
    <Head>
      <meta content={themeColor} name="msapplication-TileColor" />
      <meta content={business} name="application-name" />
      <meta content={themeColor} name="apple-mobile-web-app-status-bar-style" />
      <meta content={business} name="apple-mobile-web-app-title" />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta content="yes" name="mobile-web-app-capable" />
      <meta content={themeColor} name="theme-color" />
      <link href="/manifest.json" rel="manifest" />
      <link href={header?.data?.favicon?.url} rel="shortcut icon" />
      <link
        href={header?.data?.icon_72?.url}
        rel="apple-touch-icon"
        sizes="72x72"
      />
      <link
        href={header?.data?.icon_192?.url}
        rel="apple-touch-icon"
        sizes="192x192"
      />
      <link
        href={header?.data?.icon_512?.url}
        rel="apple-touch-icon"
        sizes="512x512"
      />
      <link
        href={header?.data?.icon_72?.url}
        rel="icon"
        sizes="72x72"
        type="image/png"
      />
      <link
        href={header?.data?.icon_192?.url}
        rel="icon"
        sizes="192x192"
        type="image/png"
      />
      <link
        href={header?.data?.icon_512?.url}
        rel="icon"
        sizes="512x512"
        type="image/png"
      />
    </Head>
  );
}

export default Pwa;
