import { FC, useState } from 'react';
import { UPLOAD_SUCCESS_MESSAGE } from '@/constants';
import { mapUpdateAccountInput } from '../Account'; // TODO - Fix this import to export form account module
import { useEcomStore, useForm, useGetAccount, useUpdateAccount } from '@/data';
import { AccountPhoto, Alert, Container, Heading, useAuth } from '@/components';
import { useNotify } from '@/hooks';

import styles from './verifyuploadingdlphoto.module.scss';
interface IVerifyUploadingDLPhotoProp {
  email: string;
}

const title =
  process.env.NEXT_PUBLIC_ID_VERIFICATION_TITLE || 'Verify your Identity';
const description =
  process.env.NEXT_PUBLIC_ID_VERIFICATION_DESCRIPTION ||
  // eslint-disable-next-line max-len
  'Please use the following prompt to verify your ID. Please note that the photo of your identification must be clear and is best taken against a solid background, also, must not be expired.';

const VerifyUploadingDLPhoto: FC<IVerifyUploadingDLPhotoProp> = ({ email }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [_, setStoredState] = useEcomStore();
  const { updateAccount } = useUpdateAccount();
  const { inputs, setInputs } = useForm();
  const { isAuth, updateUserAttributes, goToCheckout } = useAuth();
  const { notify } = useNotify();

  const [feedback, setFeedback] = useState<{
    error?: boolean;
    isOpen?: boolean;
    message?: string;
  }>({});

  const { loading } = useGetAccount({
    onCompleted: res => {
      setInputs({
        ...res.getAccountByEmail,
      });
    },
    fetchPolicy: 'network-only',
    skip: !isAuth,
    variables: {
      email,
    },
  });

  const showError = error => {
    setFeedback({
      error: true,
      isOpen: true,
      message:
        error.message ?? 'Account information not updated. Please, try again.',
    });

    // eslint-disable-next-line no-console
    console.error(error);
  };

  const updateVerificationStatusAndGoToCheckout = async (message?: string) => {
    setIsLoading(true);

    setStoredState({
      berbix_verified: true,
    });

    await updateUserAttributes({
      'custom:BERBIX_VERIFY': 'true',
    });

    if (message) {
      notify('success', message);
    }

    setIsLoading(false);

    goToCheckout();
  };

  const handleImageChange = (field, url, error) => {
    if (error) {
      showError({ message: error });
      return;
    }

    setIsLoading(true);

    const input = mapUpdateAccountInput({ ...inputs, [field]: url });

    updateAccount({
      variables: {
        input,
      },
    })
      .then(() => {
        updateVerificationStatusAndGoToCheckout(UPLOAD_SUCCESS_MESSAGE);
      })
      .catch(e => showError({ message: e }))
      .finally(() => setIsLoading(false));
  };

  if (inputs.driver_license_photo) {
    updateVerificationStatusAndGoToCheckout();

    return null;
  }

  const l = loading || isLoading;
  return (
    <Container className={styles.verify__container}>
      <div className={styles.verify__container_body}>
        <Heading level={1}>{title}</Heading>
        <p>{description}</p>
        <form>
          <Alert
            error={!!feedback.error}
            isOpen={!!feedback.isOpen}
            toggle={() => setFeedback({})}
          >
            {feedback.message}
          </Alert>
          {l && <p>Loading...</p>}
          {!l && (
            <AccountPhoto
              alt="Driver licence picture"
              field="driver_license_photo"
              onChange={handleImageChange}
              title="Upload a photo of your drivers license or government issued ID."
              value={inputs.driver_license_photo}
            />
          )}
        </form>
      </div>
    </Container>
  );
};

export default VerifyUploadingDLPhoto;
