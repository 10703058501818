import { FC } from 'react';

import { startCase, upperCase } from 'lodash';

import { Product, Variant } from '@/types';
import { Heading, Link, sortVariantByPrice } from '@/components';
import { NOT_DEFINED, TEMPLATE_BASE_PATH } from '@/constants';
import { formatWeightLabel, stringToSlug } from '@/utils';

interface IAboutProductProps {
  product: Product & { brand_url: string; category_url: string };
}

const AboutProduct: FC<IAboutProductProps> = ({ product }) => {
  const brandName = startCase(product.brand_name || NOT_DEFINED);
  const category = startCase(product.category || NOT_DEFINED);
  const flowerType = startCase(product?.flower_type || NOT_DEFINED);
  const subCategory = startCase(product?.subcategory || NOT_DEFINED);

  const flowerTypeTextStyle =
    product?.flower_type === 'cbd' ? upperCase : startCase;

  const availableWeights = sortVariantByPrice(product?.variants as Variant[])
    .map(elem => formatWeightLabel(elem?.type as string))
    .join(', ');

  const renderCannabinoidInfo = (type, value, unit) => {
    if (!value) {
      return null;
    }

    return (
      <p>
        <strong> {type}:</strong>
        {value}
        {unit}
      </p>
    );
  };

  return (
    <div className="details__data_product">
      <div className={'details__about_product'}>
        <Heading className={'details__h3'} level={3}>
          About this product
        </Heading>
        <div className={'details__item-about'}>
          <div className={'details__item-column'}>
            <div>
              <strong>Licenced Producer:</strong>{' '}
              {!product?.brand_name && startCase(NOT_DEFINED)}
              {product?.brand_name && (
                <Link
                  className="details__link"
                  href={product.brand_url}
                  title={brandName}
                >
                  {brandName}
                </Link>
              )}
            </div>
            <div>
              <strong>Category:</strong>{' '}
              {!product?.category && startCase(NOT_DEFINED)}
              {product?.category && (
                <Link
                  className="details__link"
                  href={product.category_url}
                  title={category}
                >
                  {category}
                </Link>
              )}
            </div>
            {product?.flower_type ? (
              <div>
                <strong>Flower Type: </strong>
                {product?.flower_type !== 'not specified' ? (
                  <Link
                    className="details__link"
                    // eslint-disable-next-line max-len
                    href={`${TEMPLATE_BASE_PATH.STRAIN}${product?.flower_type}`}
                    title={flowerType}
                  >
                    {flowerTypeTextStyle(product?.flower_type || '')}
                  </Link>
                ) : (
                  startCase(product?.flower_type || NOT_DEFINED)
                )}
              </div>
            ) : null}

            {renderCannabinoidInfo('THC', product?.thc?.percentage, '%')}
            {renderCannabinoidInfo('THC', product?.thc?.amount, 'MG')}
            {renderCannabinoidInfo('CBD', product?.cbd?.percentage, '%')}
            {renderCannabinoidInfo('CBD', product?.cbd?.amount, 'MG')}

            {product?.brand_subtype ? (
              <p>
                <strong>Package Info:</strong>
                {product?.brand_subtype}
              </p>
            ) : null}
            {availableWeights && availableWeights !== 'each' ? (
              <div>
                <strong>Available Weights:</strong>
                {availableWeights}
              </div>
            ) : null}
            <div>
              <strong>SubCategory: </strong>
              {product?.subcategory ? (
                <Link
                  className="details__link"
                  // eslint-disable-next-line max-len
                  href={`${product?.category_url}/${stringToSlug(
                    product?.subcategory,
                  )}`}
                  title={subCategory}
                >
                  {flowerTypeTextStyle(product?.subcategory || '')}
                </Link>
              ) : (
                startCase(product?.subcategory || NOT_DEFINED)
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AboutProduct };
