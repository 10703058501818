import { Icon, Rating } from '../../components';
import style from './ratingStats.module.scss';
import classNames from 'classnames';

/**
 *  This func returns the rating stats of the product
 *      map -> makes an object that contains a count of every rate
 *      e.g. { rating_5 : 3 ratings,
 *             rating_4 : 3 ratings,
 *             rating_3 : 3 ratings,
 *             rating_2 : 3 ratings,
 *             rating_1 : 3 ratings }
 *      the for loop inserts into the array all the
 *      ratings in the format of { rating, percentage, count}
 *      e.g. with 10 reviews total
 *           [ {rating_5, represents_40%,  4_ratings},
 *             {rating_4, represents_30%,  3_ratings},
 *             {rating_3, represents_20%,  2_ratings},
 *             {rating_2, represents_10%,  1_ratings},
 *             {rating_1, represents_0%,  0_ratings} ]
 */
function defineStats(reviews, totalReviews) {
  const map = reviews.reduce((allReviews, review) => {
    allReviews[review.rating] = (allReviews[review.rating] || 0) + 1;
    return allReviews;
  }, {});

  const stats = [];

  for (let rating = 5; rating >= 1; rating--) {
    if (!map[rating]) {
      stats.push({ count: 0, percentage: '0%', rating });
      continue;
    }
    stats.push({
      count: map[rating],
      percentage: `${(
        (map[rating] / totalReviews).toFixed(2) * 100
      ).toString()}%`,
      rating,
    });
  }
  return stats;
}

function RatingStats({ reviews, total_reviews, review_rating, className }) {
  const stats = defineStats(reviews, total_reviews);

  return (
    <div className={classNames(style.ratingreviews, className)}>
      <h2 className={style.rating__heading}>Rating and reviews</h2>
      <div className={style.rating__puntuation}>
        <strong>{parseFloat(review_rating)}</strong>
        <Rating value={review_rating} />
        {`${total_reviews} reviews`}
      </div>
      <ol>
        {stats.map((review, i) => (
          <li className={style['rating__li-review']} key={(review, i)}>
            <Icon name="star" />
            <span className={style.rating__number}>{5 - i}</span>
            <div
              aria-label={`Rating for ${5 - i}`}
              aria-valuemax="100"
              aria-valuemin="0"
              aria-valuenow={review.percentage}
              className={style['rating__meter-bar']}
              role="meter"
            >
              <div
                className={classNames(style.rating__progress, {
                  [style.rating__progress_full]: review.percentage === '100%',
                })}
                style={{ width: review.percentage }}
              />
            </div>
            <span>{review.count}</span>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default RatingStats;
