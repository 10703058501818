import { FC } from 'react';

import { Container, Heading, Link } from '@/components';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { FeatureTextSlice } from '@/types';
import classNames from 'classnames';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';
import style from './featuretext.module.scss';

/* eslint-disable-next-line max-len */
export const FeatureText: FC<FeatureTextSlice> = slice => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);

  const backgroundColor = slice?.primary?.background_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;
  const marginBottom = slice?.primary?.border_bottom;
  const paddingSection = slice?.primary?.padding_section;

  const renderHeadingSlice = (
    text: [],
    level: number,
    color: string,
    classCSS: string,
    style?: object,
  ) => {
    if (!prismicH.asText(text)) {
      return null;
    }

    return (
      <Heading className={classCSS} color={color} level={level} style={style}>
        {prismicH.asText(text)}
      </Heading>
    );
  };

  const renderDescriptionSlice = (description: [], color: string) => {
    if (!prismicH.asText(description)) {
      return null;
    }

    return (
      <PrismicRichText
        components={htmlSerializerWithProps({
          color: color,
          textTransform: 'none',
        })}
        field={description}
      />
    );
  };

  const renderCTA = (
    type: string,
    link: string,
    text: string,
    background: string,
    textColor: string,
  ) => {
    if (!text) {
      return null;
    }

    return (
      <Link
        className={classNames({
          [style.body__button_primary]: type === 'Button',
          [style.body__button_secondary]: type === 'Secondary',
        })}
        href={link}
        style={{
          background: background,
          border: type !== 'link' ? `1px solid ${background}` : undefined,
          color: textColor,
        }}
      >
        {text}
      </Link>
    );
  };

  /* eslint-disable max-len */
  const getVariation = slice => {
    if (
      slice.variation === 'default' ||
      slice.variation === 'featureTextWithTwoColumns'
    ) {
      const title_small = slice.primary?.title_size === '24px';
      const item_heading_small = slice.primary?.item_title_size === '20px';
      const title_uppercase = !!slice.primary?.title_uppercase;

      return (
        <div
          className={classNames(style.content, {
            [style.content__two_columns]:
              slice.variation === 'featureTextWithTwoColumns',
          })}
          style={{
            background: slice.primary?.background_color,
          }}
        >
          {renderHeadingSlice(
            slice.primary?.title,
            2,
            slice.primary?.title_color,
            title_small ? style.headding__small : style.headding,
            {
              textTransform: title_uppercase ? 'uppercase' : 'unset',
            },
          )}
          <div
            className={classNames(style.body__contents, {
              [style.body__contents_default]: slice.variation === 'default',
              [style.body__contents_two_columns]:
                slice.variation === 'featureTextWithTwoColumns',
            })}
          >
            {filteredItems.map((feature, index: number) => {
              return (
                <div className={style.body__item} key={index}>
                  {renderHeadingSlice(
                    feature.heading,
                    4,
                    slice.primary?.item_title_color,
                    item_heading_small
                      ? style.heading__item
                      : style.headding__small,
                    { textTransform: 'none' },
                  )}
                  {renderDescriptionSlice(
                    feature.description,
                    slice.primary?.item_description_color,
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (
      slice.variation === 'titleWithFourFeatureTextColumns' ||
      slice.variation === 'headingAndFeatureText'
    ) {
      const separator =
        slice.variation === 'headingAndFeatureText' &&
        slice.primary?.separator_color
          ? `4px solid ${slice.primary?.separator_color}`
          : 'none';
      const border =
        slice.variation === 'headingAndFeatureText' && slice.primary?.border
          ? slice.primary?.border
          : 'none';
      return (
        <div
          className={classNames(style.content__four_column, {
            [style.content__heading_and_feature]:
              slice.variation === 'headingAndFeatureText',
            [style.content__heading_and_feature_spacing]: border != 'none',
          })}
          style={{
            background: slice.primary?.background_color,
            border: border,
          }}
        >
          <div className={style.content__text}>
            {renderHeadingSlice(
              slice.primary?.heading,
              3,
              slice.primary?.heading_color,
              style.text__heading,
            )}
            {renderHeadingSlice(
              slice.primary?.title,
              2,
              slice.primary?.title_color,
              style.heading__large,
            )}
            {renderDescriptionSlice(
              slice.primary?.description,
              slice.primary?.description_color,
            )}
          </div>
          <div
            className={classNames(style.body__contents, {
              [style.body__contents_four_columns]:
                slice.variation === 'titleWithFourFeatureTextColumns',
              [style.body__contents_feature]:
                slice.variation === 'headingAndFeatureText',
              [style.body__contents_feature_spacing]: separator != 'none',
            })}
            style={{ borderLeft: separator }}
          >
            {filteredItems?.map(feature => {
              const cardLink =
                slice.variation === 'titleWithFourFeatureTextColumns' &&
                !feature.button_text &&
                feature.button_link;

              const content = (
                <div
                  className={style.body__item_card}
                  style={{
                    borderLeft: `4px solid ${slice.primary?.separator_color}`,
                  }}
                >
                  {renderHeadingSlice(
                    feature.heading,
                    4,
                    slice.primary?.item_title_color,
                    style.heading__item,
                    { textTransform: 'none' },
                  )}
                  {renderDescriptionSlice(
                    feature.description,
                    slice.primary?.item_description_color,
                  )}
                  {renderCTA(
                    'link',
                    feature.button_link,
                    feature.button_text,
                    '',
                    slice.primary?.item_button_text_color,
                  )}
                </div>
              );
              return (
                <>
                  {cardLink ? (
                    <Link className={style.card_link} href={cardLink}>
                      {content}
                    </Link>
                  ) : (
                    content
                  )}
                </>
              );
            })}
            {slice.variation === 'headingAndFeatureText' && (
              <>
                {renderDescriptionSlice(
                  slice.primary?.feature,
                  slice.primary?.feature_color,
                )}
                <div className={style.body__actions}>
                  {renderCTA(
                    slice.primary?.button_type,
                    slice.primary?.button_link,
                    slice.primary?.button_text,
                    slice.primary?.button_background,
                    slice.primary?.button_text_color,
                  )}
                  {renderCTA(
                    'Secondary',
                    slice.primary?.secondary_button_link,
                    slice.primary?.secondary_button_text,
                    slice.primary?.button_background,
                    slice.primary?.button_background,
                  )}
                </div>
              </>
            )}
          </div>
          {slice.variation === 'titleWithFourFeatureTextColumns' &&
            renderCTA(
              slice.primary?.button_type,
              slice.primary?.button_link,
              slice.primary?.button_text,
              slice.primary?.button_background,
              slice.primary?.button_text_color,
            )}
        </div>
      );
    }
  };
  /* eslint-enable max-len */

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.feature_text__section,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: backgroundColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={style.container}>{getVariation(slice)}</Container>
    </section>
  );
};
