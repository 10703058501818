import { gql } from '../../../__generated__';

export const GET_ORDER_QUERY = gql(/* GraphQL */ `
  query getOrder(
    $account_id: String!
    $store_id: String!
    $entity_id: String!
  ) {
    getOrder(
      account_id: $account_id
      store_id: $store_id
      entity_id: $entity_id
    ) {
      entity_id
      key
      status
      created_at
      delivery_details {
        pickup
        delivery
        started_at
        finished_at
      }
      delivery_address {
        address1
        address2
        city
        province
        zip
        country_code
        latitude
        longitude
        first_name
        last_name
        phone
      }
      line_items {
        item_id
        name
        quantity
        price
        in_stock
        thumbnail
        variants {
          price
          salePrice
          name
          type
        }
      }
      payment_details {
        type
        card_type
        last_four
        refunded
        refund_id
      }

      notes
      order_number
      treez_order_number

      subtotal_price
      total_discounts
      total_line_items_price
      total_price
      total_tax
      total_delivery
      total_service_fee
    }
  }
`);

export const UPDATE_CART_MUTATION = gql(/* GraphQL */ `
  mutation updateCart(
    $account_id: String!
    $store_id: String!
    $input: updateCartInput!
  ) {
    updateJaneCart(
      account_id: $account_id
      store_id: $store_id
      input: $input
    ) {
      entity_id
      key
      discount_items {
        id
        blaze_id
        discountType
        discount_rate_type
        discount_apply_type
        promo_code
        rate
      }
      line_items {
        item_id
        quantity
        price
        thumbnail
        variants {
          id
          name
          price
          type
        }
      }
      subtotal_price
      total_line_items_price
      total_discounts
      total_price
      total_tax
      total_delivery
      total_service_fee
      jane_store_full_address
      jane_store_phone_number
    }
  }
`);

export const SUBMIT_CART_MUTATION = gql(/* GraphQL */ `
  mutation submitCart(
    $account_id: String!
    $store_id: String!
    $entity_id: String!
  ) {
    submitCart(
      account_id: $account_id
      store_id: $store_id
      entity_id: $entity_id
    )
  }
`);

export const UPDATE_TREEZ_CART_MUTATION = gql(/* GraphQL */ `
  mutation updateTreezCart(
    $account_id: String!
    $store_id: String!
    $input: updateCartInput!
  ) {
    updateTreezCart(
      account_id: $account_id
      store_id: $store_id
      input: $input
    ) {
      status
      specials {
        title
        amount
      }
      order {
        entity_id
        key
        status
        email
        date_of_birth
        delivery_address {
          address1
          address2
          city
          country
          first_name
          last_name
          phone
          province
          zip
          province_code
          country_code
          city_code
          latitude
          longitude
        }
        discount_items {
          id
          blaze_id
          discountType
          discount_rate_type
          discount_apply_type
          promo_code
          rate
        }
        line_items {
          item_id
          name
          quantity
          price
          sale_price
          weight
          length
          height
          width
          product_width
          product_height
          weight_unit
          length_unit
          width_unit
          height_unit
          thumbnail
          variants {
            id
            name
            price
            type
          }
          barcodes
          brand
          category
          pos_id
        }
        notes
        buyer_accepts_marketing
        payment_details {
          type
        }
        delivery_details {
          deliver_after
          deliver_before
          delivery
          pickup
        }
        jane_cart_id
        jane_order_id
        jane_store_id
        jane_store_name
        subtotal_price
        total_line_items_price
        total_discounts
        total_price
        total_tax
        total_delivery
        total_service_fee
      }
      error {
        code
        message
      }
    }
  }
`);

export const SUBMIT_KIOSK_CHECKOUT_MUTATION = gql(/* GraphQL */ `
  mutation submitKioskCheckout(
    $account_id: String!
    $store_id: String!
    $input: updateCartInput!
  ) {
    submitKioskCheckout(
      account_id: $account_id
      store_id: $store_id
      input: $input
    )
  }
`);

export const CUSTOMER_CANCEL_ORDER_MUTATION = gql(`
  mutation customerCancelOrder( 
    $account_id: String!
    $store_id: String!
    $entity_id: String!
    ){
    customerCancelOrder( 
      account_id: $account_id
      store_id: $store_id
      entity_id: $entity_id) {
       entity_id
        key
    }
  }
`);
