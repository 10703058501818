import { FC } from 'react';

import classNames from 'classnames';

import { Icon } from '../../atoms/icon';

type StarType = 'star' | 'star-half-alt' | 'star-empty';

interface IRating {
  id: number;
  starType: StarType;
}

const getRating = (value: number) => {
  const rating: IRating[] = [];
  const full = Math.floor(value);
  const floating = value % 1;
  let isFull = true;

  for (let i = 0; i < 5; i++) {
    if (i < full) {
      rating[i] = { id: i, starType: 'star' };
      continue;
    }
    if (floating && isFull) {
      rating[i] = { id: i, starType: 'star-half-alt' };
      isFull = false;
      continue;
    }
    rating[i] = { id: i, starType: 'star-empty' };
  }

  return rating;
};

interface IRatingProps {
  value: number;
  className?: string;
}

const Rating: FC<IRatingProps> = ({ value, className }) => {
  const rating = getRating(value);

  return (
    <div className={classNames('rating', className)}>
      {rating.map(rating => (
        <Icon key={rating.id} name={rating.starType} />
      ))}
    </div>
  );
};

export default Rating;
