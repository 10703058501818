import { FC } from 'react';
import * as prismicH from '@prismicio/helpers';
import { format } from 'date-fns';
import {
  getSlicePadding,
  htmlSerializerWithProps,
  numberToShortString,
} from '@/utils';
import { PrismicRichText } from '@prismicio/react';
import { Container, Icon, Link } from '@/components';
import classNames from 'classnames';
import style from './blog.module.scss';
import { PrismicNextImage } from '@prismicio/next';
import * as prismicT from '@prismicio/client';
import { ImageField } from '@prismicio/client';
import { BlogPostsSlice, BlogPostsSliceFullImageWithShadow } from '@/types';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';

const Blog: FC<BlogPostsSlice> = slice => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);

  const sliceVariation = slice.variation ?? 'default';

  const variationDefault = sliceVariation === 'default';
  const variationColumns = sliceVariation === 'columns';
  const variationHeading = sliceVariation === '3Columns';
  const variationFullImage = sliceVariation === 'fullImageWithShadow';

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const backgroundColor = slice?.primary?.background_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  if (!slice?.primary?.active) {
    return null;
  }

  const renderSliceVariation = (
    title: prismicT.RichTextField,
    description: prismicT.RichTextField,
    date: prismicT.DateField,
    link: prismicT.KeyTextField,
    id: string,
    image?: prismicT.ImageField,
    total_views?: prismicT.NumberField,
    total_comments?: prismicT.NumberField,
    user_image?: prismicT.ImageField,
    full_name?: prismicT.KeyTextField,
    position?: prismicT.KeyTextField,
    mode?: 'Default' | 'Text Top' | 'Text Bottom',
  ) => {
    const publishAt = date ? prismicH.asDate(date) : null;

    let titleColor;
    let descriptionColor;
    let iconsColor;
    let countsColor;

    if (variationFullImage) {
      titleColor =
        mode === 'Default'
          ? (slice as BlogPostsSliceFullImageWithShadow)?.primary?.title_color!
          : '#FFFFFF';
      descriptionColor =
        mode === 'Default'
          ? (slice as BlogPostsSliceFullImageWithShadow)?.primary
              ?.description_color!
          : '#CCD5E3';
      iconsColor =
        mode === 'Default'
          ? (slice as BlogPostsSliceFullImageWithShadow)?.primary?.icons_color!
          : '#FFFFFF';
      countsColor =
        mode === 'Default'
          ? (slice as BlogPostsSliceFullImageWithShadow)?.primary?.counts_color!
          : '#CCD5E3';
    }

    return (
      <article key={id}>
        <Link
          className={classNames(style.article, {
            [style.article_heading]: variationHeading || variationFullImage,
            [style.article_hibryd]: variationColumns,
            [style.article_fullWidth]: variationFullImage && mode !== 'Default',
          })}
          href={link ?? ''}
        >
          <blockquote
            className={classNames({
              [style.blockquote_absolute]:
                variationFullImage && mode !== 'Default',
              [style.blockquote_absolute__top]: mode === 'Text Top',
            })}
          >
            {variationFullImage && publishAt && (
              <time
                className={classNames({ [style.time]: variationFullImage })}
                dateTime={publishAt.toString() || ''}
              >
                {format(new Date(publishAt), 'PPP')}
              </time>
            )}
            <PrismicRichText
              components={htmlSerializerWithProps({
                color: titleColor,
              })}
              field={title}
            />
            <PrismicRichText
              components={htmlSerializerWithProps({
                color: descriptionColor,
              })}
              field={description}
            />
            {
              // eslint-disable-next-line max-len
              (variationDefault || variationFullImage) &&
                (total_views || total_comments) && (
                  <div className={style.stats}>
                    <span
                      style={{
                        color: countsColor,
                      }}
                    >
                      <Icon
                        name="visualizations"
                        style={{
                          color: iconsColor,
                        }}
                      />
                      {numberToShortString(total_views as number)}
                    </span>
                    <span
                      style={{
                        color: countsColor,
                      }}
                    >
                      <Icon
                        name="comments"
                        style={{
                          color: iconsColor,
                        }}
                      />
                      {numberToShortString(total_comments as number)}
                    </span>
                  </div>
                )
            }
            {variationColumns && (
              <div className={style.user}>
                <p>
                  {full_name}
                  <br />
                  <span>{position}</span>
                </p>
                <figure className={style.article__figure}>
                  {user_image && user_image?.url ? (
                    <PrismicNextImage
                      fallbackAlt=""
                      field={user_image as ImageField}
                    />
                  ) : (
                    <Icon name="perfil-user" />
                  )}
                </figure>
              </div>
            )}
          </blockquote>
          {!variationColumns && (
            <figure
              className={classNames(style.article__figure, {
                [style.article__figure_fullWidthVariation]: variationFullImage,
                [style['article__figure_fullWidthVariation--absolute']]:
                  variationFullImage && mode !== 'Default',
              })}
            >
              {image && image?.url ? (
                <PrismicNextImage
                  fallbackAlt=""
                  field={image as ImageField}
                  fill
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center top',
                  }}
                />
              ) : (
                <Icon name="image" />
              )}
            </figure>
          )}
          {!variationHeading && !variationFullImage && publishAt && (
            <time dateTime={publishAt.toString() || ''}>
              {format(new Date(publishAt), 'PPP')}
            </time>
          )}
          {variationFullImage && mode !== 'Default' && (
            <div
              className={classNames(style.overlap, {
                [style['overlap--inverse']]: mode === 'Text Top',
              })}
            />
          )}
        </Link>
      </article>
    );
  };

  return (
    <section
      className={classNames(style.blogs, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
        ['background__fixed_cover']: backgroundStyleFixed,
        ['background__contained']: !backgroundStyleFixed,
      })}
      style={{
        background: backgroundColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={classNames(style.blogs__container)}>
        {slice.variation === '3Columns' && (
          <PrismicRichText field={slice.primary?.section_title} />
        )}
        <div
          className={classNames(style.blogs__articles, {
            [style.blogs__articles_default]: variationDefault,
            [style.blogs__articles_column_heading]:
              variationHeading || variationFullImage,
            [style.blogs__articles_column_hybrid]: variationColumns,
          })}
        >
          {filteredItems.map((value, index: number) =>
            renderSliceVariation(
              value.title,
              value.description,
              value.date,
              value.link,
              `${sliceVariation}-${index}`,
              value.image,
              value.total_views,
              value.total_comments,
              value.user_image,
              value.full_name,
              value.position,
              value.mode,
            ),
          )}
        </div>
      </Container>
    </section>
  );
};

export default Blog;
