import { FC } from 'react';
import classNames from 'classnames';
import style from './articlelist.module.scss';
import React from 'react';

interface IPPlaceholder {
  className?: string;
}

const ArticlePlaceholder: FC<IPPlaceholder> = ({ className }) => {
  return (
    <div className={classNames(style.article__placeholder, className)}>
      <figure className={style.article__image_placeholder} />
      <div className={style.article__body}>
        <h5 className={style.article__name} />
        <div className={style.article__info}>
          <span className={style.article__time} />
          <div className={style.article__sharing}>
            <span className={style.article__sharing_social} />
            <span className={style.article__sharing_social} />
            <span className={style.article__sharing_social} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ArticlePlaceholder };
