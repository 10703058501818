import { LIST_CATEGORY_QUERY } from './schema';

export async function listCategory(client, options = {}) {
  const response = await client.query({
    query: LIST_CATEGORY_QUERY,
    ...options,
  });

  return {
    data: response.data?.listCategory,
    error: response.error,
  };
}
