import { useQuery } from '@apollo/client';

import { GET_STORE_QUERY } from './schema';

export function useGetStore(options = {}) {
  const { data, error, loading } = useQuery(GET_STORE_QUERY, options);

  return {
    loading: loading,
    data: data?.getStore,
    error: error,
  };
}
