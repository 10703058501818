import { FC } from 'react';
import { findIndex } from 'lodash';
import { useUiStore } from '@/data';
import {
  FiltersStoreList,
  FilterStoreContainer,
} from './FiltersStore.components';
import { Button } from '@/components';
import styles from './filterstore.module.scss';

const FiltersStore: FC = () => {
  const [state, setState] = useUiStore();
  const filterSelection: IFilterSelections[] = state.options_filter;
  const initValue: IFilterSelections[] = [
    { options: [], type: 'deals' },
    { options: [], type: 'type' },
    { options: [], type: 'want' },
  ];

  const handleFilterSelection = (
    id: 'deals' | 'ownerships' | 'type' | 'want',
    options: any,
  ) => {
    const index = findIndex(filterSelection, o => o.type === id);

    if (index === -1) {
      filterSelection.push({ options: options, type: id });
      return;
    }

    filterSelection[index] = { options: options, type: id };
  };

  const handleActions = (action: 'clear' | 'success') => {
    if (action === 'clear') {
      setState({ options_filter: initValue });
    }
    if (action === 'success') {
      setState({ options_filter: filterSelection });
    }
    setState({ is_filter_store_open: false });
  };

  return (
    <FilterStoreContainer>
      <FiltersStoreList handleFilterSelection={handleFilterSelection} />
      <div className={styles.actions}>
        <Button color={'secondary'} onClick={() => handleActions('clear')}>
          Clear filters
        </Button>
        <Button color={'primary'} onClick={() => handleActions('success')}>
          See results
        </Button>
      </div>
    </FilterStoreContainer>
  );
};

export interface IFilterSelections {
  options: string[];
  type: 'deals' | 'ownerships' | 'type' | 'want';
}

export default FiltersStore;
