'use client';

import { useEffect } from 'react';
import { Amplify, ResourcesConfig } from 'aws-amplify';

import { AccountSetting, AwsPinpointAnalytics } from '__generated__/graphql';

function useAmplify({
  accountSettings,
  analyticsSettings,
}: {
  accountSettings: AccountSetting;
  analyticsSettings: AwsPinpointAnalytics;
}) {
  const { user_pools_id, user_pools_web_client_id } = accountSettings;
  const {
    active: analyticsActive,
    region: analyticsRegion,
    // identity_pool_id,
    app_id,
  } = analyticsSettings;

  const f: ResourcesConfig = {
    ...(accountSettings?.active
      ? {
          Auth: {
            Cognito: {
              identityPoolId: '',
              userPoolId: user_pools_id ?? '',
              userPoolClientId: user_pools_web_client_id ?? '',
            },

            // region: cognito_region,
          },
        }
      : {}),
    ...(analyticsActive
      ? {
          Analytics: {
            // autoSessionRecord: true,
            Pinpoint: {
              appId: app_id ?? '',
              region: analyticsRegion ?? '',
            },
            // disabled: false,
          },
        }
      : {}),
  };

  useEffect(() => {
    Amplify.configure(f);
  }, []);
}

export default useAmplify;
