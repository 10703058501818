import { toNumber } from 'lodash';

import { getCartItemsCount, getCartItemsSubtotal, stringToSlug } from '@/utils';
import { CartLineItem, GProduct, ISpecial, TreezSpecials } from '@/types';

interface ISpecialProduct extends GProduct {
  bundle_id: string;
  bundle_title: string;
  bundle_slug: string;
}

const formatSpecial = (
  currentBundleProducts: CartLineItem[],
  currentSpecial: TreezSpecials | undefined,
  products: {
    [key: string]: GProduct[];
  },
  isBogoLoading: boolean,
): ISpecial => {
  if (!currentSpecial || isBogoLoading) {
    return undefined as unknown as ISpecial;
  }

  const allowDiscountProducts = !currentSpecial?.groupsRequired?.length;

  const dependentSettings = currentSpecial?.discountRules?.find(
    rule => rule?.ruleName === 'GET_COUNT',
  );

  const independentSettings = currentSpecial?.discountRules?.find(
    rule => rule.ruleName === 'BUY_COUNT',
  );

  const dependentProductsRequired = dependentSettings?.ruleValue
    ? toNumber(dependentSettings?.ruleValue)
    : Infinity;

  const totalIndProdsRequired = independentSettings?.ruleValue
    ? toNumber(independentSettings?.ruleValue)
    : 0;

  const specialType = currentSpecial?.method ?? '';

  const depProducts = Object.entries(products).reduce(
    (result, [key, value]: [key: string, value: GProduct[]]) => {
      if (!key.includes('independent')) {
        return [...result, ...value];
      }
      return result;
    },
    [],
  );

  const indProducts = Object.entries(products).reduce(
    (result, [key, value]: [key: string, value: GProduct[]]) => {
      if (key.includes('independent')) {
        return [...result, ...value];
      }
      return result;
    },
    [],
  );

  let independentProductsRequired = totalIndProdsRequired;
  if (allowDiscountProducts) {
    independentProductsRequired = 0;
  }

  const totalProductsRequired =
    dependentProductsRequired + independentProductsRequired;
  let bundleComplete = totalProductsRequired === currentBundleProducts.length;

  const purchaseRequirementRule = getSpecialRuleByName(
    currentSpecial,
    'PURCHASE_REQUIREMENT',
  );
  const purchaseRequirement = purchaseRequirementRule?.ruleValue;

  const buyCountRule = getSpecialRuleByName(currentSpecial, 'BUY_COUNT');
  const buyCount = buyCountRule?.ruleValue
    ? Number(buyCountRule?.ruleValue)
    : undefined;

  if (buyCount) {
    if (purchaseRequirement === 'UNIT_COUNT') {
      bundleComplete = getCartItemsCount(currentBundleProducts) === buyCount;
    } else if (purchaseRequirement === 'RETAIL_VALUE') {
      const totalRetailValue = getCartItemsSubtotal(currentBundleProducts);
      bundleComplete = totalRetailValue >= buyCount * 100;
    }
  }

  return {
    allowDiscountProducts,
    bundleComplete,
    depProducts: mapBundleProds(depProducts, currentSpecial),
    dependentProductsRequired,
    indProducts: mapBundleProds(indProducts, currentSpecial),
    independentProductsRequired: totalIndProdsRequired,
    method: currentSpecial?.method,
    specialId: currentSpecial?.id,
    specialType,
    specialSlug: stringToSlug(currentSpecial?.title),
    title: currentSpecial?.title,
    totalProductsRequired,
    purchaseRequirement,
    buyCount,
  };
};

const getSpecialRuleByName = (special: TreezSpecials, name: string) =>
  special?.discountRules?.find(({ ruleName }) => ruleName === name);

const mapBundleProds = (
  prods: GProduct[],
  special: TreezSpecials,
): ISpecialProduct[] =>
  prods.map(prod => ({
    ...prod,
    bundle_id: special.id,
    bundle_title: special?.title ?? '',
    bundle_slug: stringToSlug(special?.title ?? ''),
  }));

export { formatSpecial, mapBundleProds };
export type { ISpecialProduct };
