import { useData } from '@/components';
import { HeaderSlice } from 'prismicio-types';

const useActiveHeaderSlice = (): HeaderSlice | undefined => {
  const { header } = useData();

  return (header?.data?.slices || []).find(
    (s: HeaderSlice) => s?.primary?.active,
  );
};

export { useActiveHeaderSlice };
