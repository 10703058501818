import { CSSProperties, FC, JSX, ReactNode, Ref } from 'react';

import styles from './container.module.scss';

const Container: FC<IContainerProps> = ({
  as = 'div',
  children,
  className,
  style = {},
  id = '',
  ref,
}) => {
  const htmlTag = { element: as };

  return (
    <htmlTag.element
      className={`${styles.container} ${className ?? ''}`}
      id={id}
      ref={ref}
      style={style}
    >
      {children}
    </htmlTag.element>
  );
};

interface IContainerProps {
  as?: Element;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  id?: string;
  ref?: Ref<HTMLDivElement | null>;
}

type Element = keyof Pick<JSX.IntrinsicElements, 'div' | 'section'>;

export default Container;
