import { createWithEqualityFn as create } from 'zustand/traditional';
import { persist } from 'zustand/middleware';
import merge from 'deepmerge';

export function createPersistanceStore<T extends object>(
  store: T,
  name: string,
  version: string,
) {
  const createdStore = create<T, [['zustand/persist', T]]>(
    persist(
      (set, get) => {
        return {
          ...store,
          get,
          set: (value: Partial<T>) =>
            set(state =>
              merge(state, value, {
                arrayMerge: (_, sourceArray) => sourceArray,
              }),
            ),
        };
      },
      {
        name,
        version: parseInt(version),
      },
    ),
  );
  return createdStore;
}

export function createStore<T extends object>(store: T) {
  const createdStore = create<T, [['zustand/persist', T]]>((set, get) => {
    return {
      ...store,
      get,
      set: value => set(value),
    };
  });
  return createdStore;
}
