import { useUiStore } from '../data';
import { Heading, Icon, Link } from '../components';
import Image from '../components/Image';

function Cta({ description }) {
  // eslint-disable-next-line no-unused-vars
  const [_, setGlobalState] = useUiStore();

  return (
    <section className="cta">
      <div className="cta__info">
        <Heading level={2}>{description}</Heading>
        <p>Get the best delivery times, products, and brands right to.</p>
        <div className="cta__actions">
          <Link className="cta__action" href="/deals">
            <Icon name="cart-solid" /> Shop now
          </Link>
          <button
            className="cta__action"
            onClick={() => setGlobalState({ is_login_open: true })}
            type="button"
          >
            <Icon name="user-solid" /> Sign in
          </button>
        </div>
      </div>
      <figure>
        <Image
          alt="App on mobile device"
          height={278}
          sizes="
                    (min-width: 992px) 417px, 
                    (min-width: 768px) 317px, 
                    (min-width: 392px) 343px, 100wv"
          src="/images/cta/phones.png"
          width={417}
        />
      </figure>
    </section>
  );
}

export default Cta;
