import { CSSProperties, FC, Fragment } from 'react';
import {
  StatsSlice,
  StatsSliceWithBordersAndSeparators,
} from 'prismicio-types';
import { PrismicRichText } from '@prismicio/react';

import { Container } from '@/components';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';

import style from './withbordersandseparatorsvariation.module.scss';
import classNames from 'classnames';

export const WithBordersAndSeparatorsVariation: FC<{
  slice: StatsSliceWithBordersAndSeparators & StatsSlice;
}> = ({ slice }) => {
  const border = `${slice.primary.border_width}px solid ${slice.primary.border_color}`;

  const borderDynamicStyles = {
    borderTop: slice.primary.border_top ? border : 'none',
    borderRight: slice.primary.border_right ? border : 'none',
    borderBottom: slice.primary.border_bottom ? border : 'none',
    borderLeft: slice.primary.border_left ? border : 'none',
  };
  const background = slice?.primary?.background_color as string;
  const marginBottom = slice?.primary?.bottom_spacing;
  const borderTop = slice?.primary?.border_section as string;
  const borderBottom = slice?.primary?.border_section as string;
  const paddingSection = slice?.primary?.padding_section;

  return (
    <Container
      as="section"
      className={classNames(
        style.stats__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      style={{ background, borderTop, borderBottom }}
    >
      {slice?.primary?.title && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              alignSelf: slice.primary.title_align,
              textAlign: slice.primary.title_align,
            },
            style.stats__title,
          )}
          field={slice.primary.title}
        />
      )}
      <dl className={style.stats__list} style={borderDynamicStyles}>
        {slice?.items.map((item, index) => (
          <Fragment key={`${item.stat_title}-${index}`}>
            <div
              className={style.stats__element}
              style={{ alignItems: item.stat_align }}
            >
              <dt
                className={style.stats__element_title}
                style={{ textAlign: item.stat_align }}
              >
                {item.stat_title}
              </dt>
              <dd className={style.stats__element_value}>{item.stat_value}</dd>
            </div>
            {index < slice?.items.length - 1 && (
              <hr
                className={style.stats__separator}
                style={
                  {
                    '--separator-width': `${slice.primary.separator_width}px`,
                    backgroundColor: slice.primary.separator_color,
                  } as CSSProperties
                }
              />
            )}
          </Fragment>
        ))}
      </dl>
      {slice?.primary?.bottom_description && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              alignSelf: slice.primary.bottom_description_align,
              textAlign: slice.primary.bottom_description_align,
            },
            style.stats__bottom_description,
          )}
          field={slice.primary.bottom_description}
        />
      )}
    </Container>
  );
};
