import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useIsServerSide, useScreenWidth, useSelectedStore } from '@/hooks';
import { alignContent, isCurrentDateInRange, isNullish } from '@/utils';
import { PrismicNextImage } from '@prismicio/next';
import { Container, IconSvg, Link } from '@/components';
import style from './heroimage.module.scss';
import { Swiper as ISwiper } from 'swiper/types';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  HeroImageSlice,
  HeroImageSliceDefaultItem,
  HeroImageSliceHeroImageWithHeadingAndDescripItem,
  Simplify,
} from '@/types';
import { useIsMobile } from '@/hooks';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import classNames from 'classnames';
import { asText } from '@prismicio/client';

const HeroImage: FC<HeroImageSlice> = slice => {
  const { isMobile } = useIsMobile();
  const { width } = useScreenWidth();
  const { store } = useSelectedStore();
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number | null>(
    null,
  );
  const { isServerSide } = useIsServerSide();
  const [currentUrlStore, setCurrentUrlStore] = useState<string>();
  const items = slice?.items;
  const backgroundColor = slice?.primary?.background_color ?? undefined;
  const fullWidth = slice?.primary?.full_width;
  const imgEffect = slice?.primary?.effect_zoom_image;
  const withousVerticalSpace = slice?.primary?.withous_vertical_space;
  const maxHeigthDesktop = slice?.primary?.max_height_desktop ?? 0;
  const maxHeigthMobile = slice?.primary?.max_height_mobile ?? 0;
  const bottomSpacing = slice?.primary?.bottom_spacing;
  const borderRadius =
    width > 767 && !fullWidth ? `${slice?.primary?.border_radius}px` : 0;

  const sizes = `(min-width: 2048px)  2008px, (min-width: 1536px)  1496px,
        (min-width: 1280px)  1240px, (min-width: 1024px) 984px,
        (min-width: 768px) 728px, 100vw`;

  useEffect(() => {
    if (store?.url_slug) {
      setCurrentUrlStore(store?.url_slug);
    }
  }, [store?.url_slug]);

  const filterCarouselItems = (item: Simplify<HeroImageSliceDefaultItem>) => {
    const currentDay = format(new Date(), 'eeee');
    const active = item.active;

    if (
      active &&
      (!item?.day || item.day === 'Everyday' || item.day === currentDay) &&
      isCurrentDateInRange(
        item?.start_date as string,
        item?.end_date as string,
      ) &&
      (item.location_slug === currentUrlStore || !item.location_slug)
    ) {
      return true;
    }

    return false;
  };

  const carouselItems = useMemo(
    () => items.filter(filterCarouselItems),
    [items, currentUrlStore],
  );

  if (!slice?.primary?.active) return null;

  const isVariationWithHeading =
    slice?.variation === 'heroImageWithHeadingAndDescrip';

  const handleSwiperInit = (swiper: ISwiper) => {
    setCurrentSlideIndex(swiper.realIndex);
  };

  const handleSlideChange = (swiper: ISwiper) => {
    setCurrentSlideIndex(swiper.realIndex);
  };

  const getMaxHeight = () => {
    const paddingTop = isMobile ? '58%' : '33.33%';

    if (isMobile && maxHeigthMobile) {
      return `min(${maxHeigthMobile}px, ${paddingTop})`;
    }

    if (!isMobile && maxHeigthDesktop) {
      return `min(${maxHeigthDesktop}px, ${paddingTop})`;
    }

    return undefined;
  };

  const renderImage = (banner, sizes, classCss) => {
    return (
      <figure
        className={classCss}
        style={{ borderRadius, paddingTop: getMaxHeight() }}
      >
        {banner?.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={banner}
            fill
            priority
            sizes={sizes}
            style={{ objectFit: 'cover', objectPosition: 'center center' }}
          />
        ) : (
          <IconSvg name="image" />
        )}
      </figure>
    );
  };

  return (
    <section
      className={classNames(style.hero, {
        [style.hero__spacing_bottom]: bottomSpacing,
      })}
      style={{ backgroundColor }}
    >
      <Container
        className={classNames(style.container__clear, {
          [style.container__full_width]: fullWidth,
          [style.container__withous_margin]: withousVerticalSpace,
          [style.container__with_margin]: !withousVerticalSpace,
          [style.container__effect_img]: imgEffect,
        })}
      >
        {isServerSide ? (
          <div className={style.swipper__hero_image}>
            <Link href={carouselItems?.[0]?.image_link ?? '/'}>
              {renderImage(
                carouselItems?.[0]?.hero_image,
                sizes,
                style.banner__figure,
              )}
              {renderImage(
                carouselItems?.[0]?.hero_image?.mobile,
                '(min-width: 640px) 610px, 100wv',
                style.banner__figure_mobile,
              )}
            </Link>
          </div>
        ) : (
          <Swiper
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            className={style.swipper__hero_image}
            effect="fade"
            loop
            modules={[Autoplay, EffectFade, Navigation, Pagination]}
            onAfterInit={handleSwiperInit}
            onSlideChange={handleSlideChange}
            pagination={{
              clickable: true,
            }}
            slidesPerView={1}
          >
            {carouselItems.map(
              (
                banner: HeroImageSliceHeroImageWithHeadingAndDescripItem,
                index,
              ) => {
                const heading = banner?.heading_text;
                const headingColor = banner?.heading_color as string;
                const title = banner?.title;
                const titleColor = banner?.title_color as string;
                const description = banner?.description;
                const descriptionColor = banner?.description_color as string;
                const buttonText = banner?.button_text;
                const maxContent =
                  banner?.content_max_width && width > 1024
                    ? `${banner?.content_max_width}%`
                    : undefined;
                const borderRadius = banner?.button_border_radius
                  ? `${banner?.button_border_radius}px`
                  : undefined;

                return (
                  <SwiperSlide key={index}>
                    {!isNullish(currentSlideIndex) && (
                      <>
                        {isVariationWithHeading &&
                          (asText(heading) ||
                            asText(title) ||
                            asText(description) ||
                            buttonText) && (
                            <Container
                              className={style.container__content}
                              style={{
                                alignItems: alignContent(
                                  banner?.horizontal_position ?? 'start',
                                ),
                                justifyContent: alignContent(
                                  banner?.vertical_position ?? 'start',
                                ),
                                maxWidth: maxContent,
                                textAlign: banner?.text_alignment ?? 'left',
                              }}
                            >
                              <div
                                className={style.content__description}
                                style={{
                                  alignItems: alignContent(
                                    banner?.horizontal_position ?? 'start',
                                  ),
                                  textAlign: banner?.text_alignment ?? 'left',
                                }}
                              >
                                {heading && (
                                  <h4
                                    className={
                                      style.content__description__heading
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: asText(heading),
                                    }}
                                    style={{ color: headingColor }}
                                  />
                                )}
                                {title?.[0]?.type === 'heading1' ? (
                                  <h1
                                    className={
                                      style.content__description__header
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: asText(title),
                                    }}
                                    style={{ color: titleColor }}
                                  />
                                ) : (
                                  <h2
                                    className={
                                      style.content__description__header
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: asText(title),
                                    }}
                                    style={{ color: titleColor }}
                                  />
                                )}
                                {asText(description) && (
                                  <p
                                    className={
                                      style.content__description__descrip
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: asText(description),
                                    }}
                                    style={{
                                      color: descriptionColor,
                                    }}
                                  />
                                )}
                                {buttonText && (
                                  <Link
                                    className={
                                      style.content__description__action
                                    }
                                    href={banner?.image_link ?? ''}
                                    style={
                                      {
                                        '--cta-background-color':
                                          banner?.button_background,
                                        '--cta-background-color-hover':
                                          banner?.button_background_hover,
                                        '--cta-text-color':
                                          banner?.button_text_color,
                                        '--cta-text-color-hover':
                                          banner?.button_text_color_hover,
                                        '--border-style':
                                          banner?.button_border_style,
                                        '--border-style-hover':
                                          banner?.button_border_style_hover,
                                        '--border-radius': borderRadius,
                                      } as CSSProperties
                                    }
                                  >
                                    {buttonText}
                                  </Link>
                                )}
                              </div>
                            </Container>
                          )}
                        <Link href={banner?.image_link ?? '/'}>
                          {renderImage(
                            banner?.hero_image,
                            sizes,
                            style.banner__figure,
                          )}
                          {renderImage(
                            banner?.hero_image?.mobile,
                            '(min-width: 640px) 610px, 100wv',
                            style.banner__figure_mobile,
                          )}
                        </Link>
                      </>
                    )}
                  </SwiperSlide>
                );
              },
            )}
          </Swiper>
        )}
      </Container>
    </section>
  );
};

export default HeroImage;
