import { gql } from '@apollo/client';

export const GET_ACCOUNT_BY_EMAIL_QUERY = gql`
  query getAccountByEmail(
    $account_id: String!
    $store_id: String!
    $email: String!
    $provider_store: ProviderStore
  ) {
    getAccountByEmail(
      account_id: $account_id
      store_id: $store_id
      email: $email
      provider_store: $provider_store
    ) {
      photo
      email
      date_of_birth
      entity_id
      first_name
      last_name
      phone
      driver_license_id
      driver_license_photo
      driver_license_id_expiration_date
      address1
      address2
      province
      province_code
      city
      city_code
      country
      country_code
      zip
      latitude
      longitude
      accept_marketing
      has_account
      checkin_from_dispensary
      active
      created_at
      updated_at
      loyalty_points
      type
      gender
      alpineiq_verified
      alpineiq_contact_id
      loyalty_points_time_line {
        date
        points
        note
      }
      treez_ids {
        store_id
        id
      }
      medical_id {
        number
        expiration_date
      }
    }
  }
`;

export const CUSTOMER_ORDER_HISTORY_QUERY = gql`
  query customerOrderHistory(
    $account_id: String!
    $store_id: String!
    $cursor: String
    $email: String!
    $total_price: Int
  ) {
    customerOrderHistory(
      account_id: $account_id
      store_id: $store_id
      cursor: $cursor
      email: $email
      total_price: $total_price
    ) {
      cursor
      orders {
        entity_id
        status
        created_at
        delivery_details {
          delivery
          pickup
        }
        delivery_address {
          address1
          address2
          city
          province
          zip
          country_code
        }
        line_items {
          id
          item_id
          name
          quantity
          variants {
            id
            name
            price
            salePrice
            type
          }
        }
        order_number
        treez_order_number
        total_price
      }
    }
  }
`;

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation createAccount(
    $account_id: String!
    $store_id: String!
    $input: CreateAccountInput!
  ) {
    createAccount(account_id: $account_id, store_id: $store_id, input: $input) {
      email
    }
  }
`;

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccount(
    $account_id: String!
    $store_id: String!
    $input: UpdateAccountInput!
  ) {
    updateAccount(account_id: $account_id, store_id: $store_id, input: $input) {
      email
    }
  }
`;

export const UPLOAD_ACCOUNT_PHOTO_MUTATION = gql`
  mutation uploadAccountPhoto(
    $account_id: String!
    $store_id: String!
    $input: AccountUploadPhotoInput!
  ) {
    uploadAccountPhoto(
      account_id: $account_id
      store_id: $store_id
      input: $input
    ) {
      file_name
      url
      headers {
        key
        value
      }
    }
  }
`;

export const GET_ACCOUNT_PHOTO_QUERY = gql`
  query getAccountPhoto($filename: String!) {
    getAccountPhoto(filename: $filename)
  }
`;

export const CREATE_BERBIX_CLIENT_TOKEN = gql`
  mutation createBerbixClientToken(
    $account_id: String!
    $store_id: String!
    $input: BerbixClientTokenInput!
  ) {
    createBerbixClientToken(
      account_id: $account_id
      store_id: $store_id
      input: $input
    ) {
      access_token
      client_token
      expires_at
      refresh_token
    }
  }
`;

export const GET_BERBIX_TRANSACTION = gql`
  query getBerbixTransactionResult(
    $account_id: String!
    $store_id: String!
    $access_token: String!
    $refresh_token: String!
  ) {
    getBerbixTransactionResult(
      account_id: $account_id
      store_id: $store_id
      access_token: $access_token
      refresh_token: $refresh_token
    ) {
      id
      valitated_user
      reason_not_validated
    }
  }
`;

export const VALIDATE_DIVE_TRANSACTION = gql`
  mutation idScanVerification(
    $account_id: String!
    $store_id: String!
    $input: IDScanValidationInput!
  ) {
    idScanVerification(
      account_id: $account_id
      store_id: $store_id
      input: $input
    ) {
      success
      error {
        code
        text
      }
    }
  }
`;

export const SET_TREEZ_CUSTOMER_UNVERIFIED = gql`
  mutation setTreezCustomerUnverified {
    setTreezCustomerUnverified
  }
`;
