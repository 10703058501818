import type { AggregateRating } from '@/types';

export function setAggregateRating(aggregateRating?: AggregateRating) {
  if (aggregateRating) {
    return {
      '@type': 'AggregateRating',
      bestRating: aggregateRating.bestRating,
      ratingCount: aggregateRating.ratingCount,
      ratingValue: aggregateRating.ratingValue,
      reviewCount: aggregateRating.reviewCount,
    };
  }
  return undefined;
}
