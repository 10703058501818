import { CSSProperties, FC } from 'react';
import { useIsMobile } from '@/hooks';
import * as prismicH from '@prismicio/helpers';
import { alignContent, getSlicePadding } from '@/utils';
import { asText } from '@prismicio/helpers';
import { Container, Link } from '@/components';
import classNames from 'classnames';
import style from './herovideovariation.module.scss';
import { VideoSlice, VideoSliceHeroVideo } from 'prismicio-types';

export const HeroVideoVariation: FC<{
  slice: VideoSlice & VideoSliceHeroVideo;
}> = ({ slice }) => {
  const { isMobile } = useIsMobile(639);

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const background = slice?.primary?.section_background_color ?? '';

  const contentDirection = isMobile
    ? slice?.primary?.content_direction_mobile
    : slice?.primary?.content_direction_desktop;

  const getAligmentItems = () => {
    const { content_alignment, invert_content_alignment_desktop } =
      slice.primary;
    if (!invert_content_alignment_desktop) {
      return alignContent(slice?.primary?.text_alignment);
    }
    switch (content_alignment) {
      case 'start': {
        if (isMobile) {
          return alignContent('start');
        }
        return alignContent('end');
      }
      case 'end': {
        if (isMobile) {
          return alignContent('end');
        }
        return alignContent('start');
      }

      default:
        return alignContent('center');
    }
  };

  const ctaBackgroundColor = slice?.primary?.cta_background_color ?? '';
  const ctaBackgroundColorHover =
    slice?.primary?.cta_background_color_hover ?? '';
  const ctaTextColor = slice?.primary?.cta_text_color ?? '';
  const ctaTextColorHover = slice?.primary?.cta_text_color_hover ?? '';
  const borderRadius = slice?.primary?.cta_border_radius;
  const maxHeight = slice?.primary?.max_height
    ? `${slice?.primary?.max_height}px`
    : undefined;
  const borderStyle = isMobile
    ? `calc(0.5px + 0.5vw) solid ${slice?.primary?.cta_border_color}`
    : `calc(0.1px + 0.3vw) solid ${slice?.primary?.cta_border_color}`;
  const borderStyleHover = isMobile
    ? `calc(0.5px + 0.5vw) solid ${slice?.primary?.cta_border_color_hover}`
    : `calc(0.1px + 0.3vw) solid ${slice?.primary?.cta_border_color_hover}`;

  if (!slice?.primary?.active) {
    return;
  }

  const renderVideoContent = () => (
    <>
      <Container className={style.content}>
        {asText(slice?.primary?.title) ||
        slice?.primary?.description ||
        slice?.primary?.cta_button_text ? (
          <div
            className={classNames(style.content__description, {
              [style.content__description_large]:
                slice?.primary?.hidden_button_mobile,
            })}
            style={{
              alignItems: getAligmentItems(),
              alignSelf: alignContent(slice?.primary?.vertical_position),
              color: slice?.primary?.text_color ?? '',
              flexDirection: contentDirection,
              justifyContent: slice?.primary?.justify_content,
              justifySelf: slice?.primary?.horizontal_position,
              textAlign: slice?.primary?.text_alignment,
              width: `${slice?.primary?.content_width}%`,
            }}
          >
            {slice?.primary?.heading_text && (
              <p
                className={style.content__description__heading}
                dangerouslySetInnerHTML={{
                  __html: slice?.primary?.heading_text as string,
                }}
                style={{
                  // eslint-disable-next-line max-len
                  color: slice.primary.heading_color ?? '',
                }}
              />
            )}
            {slice?.primary?.title[0]?.type === 'heading1' ? (
              <h1
                className={style.content__description__header}
                dangerouslySetInnerHTML={{
                  __html: asText(slice?.primary?.title),
                }}
                style={{
                  // eslint-disable-next-line max-len
                  color: slice?.primary?.title_color!,
                  lineHeight: `${slice?.primary?.title_line_height}px`,
                }}
              />
            ) : (
              <h2
                className={style.content__description__header}
                dangerouslySetInnerHTML={{
                  __html: asText(slice?.primary?.title),
                }}
                style={{
                  // eslint-disable-next-line max-len
                  color: slice?.primary?.title_color!,
                  lineHeight: `${slice?.primary?.title_line_height}px`,
                }}
              />
            )}
            <p
              className={classNames(style.content__description__descrip)}
              dangerouslySetInnerHTML={{
                __html: slice?.primary?.description as string,
              }}
              style={{
                // eslint-disable-next-line max-len
                lineHeight: `${slice?.primary?.description_line_height}px`,
              }}
            />
            {slice?.primary?.cta_button_text && (
              <Link
                className={classNames(style.content__description__action, {
                  [style.content__description__action_hidden_mobile]:
                    slice?.primary?.hidden_button_mobile,
                })}
                href={slice?.primary?.link ?? ''}
                style={
                  {
                    '--cta-background-color': ctaBackgroundColor,
                    '--cta-background-color-hover': ctaBackgroundColorHover,
                    '--cta-text-color': ctaTextColor,
                    '--cta-text-color-hover': ctaTextColorHover,
                    '--border-style': borderStyle,
                    '--border-style-hover': borderStyleHover,
                    '--border-radius': `${borderRadius}px`,
                  } as CSSProperties
                }
              >
                {slice?.primary?.cta_button_text}
              </Link>
            )}
          </div>
        ) : null}
      </Container>
      <Link
        className={style.container__video_wrapper}
        href={slice?.primary?.link ?? '/'}
      >
        <video
          autoPlay
          className={style.video}
          loop
          muted
          playsInline
          src={prismicH.asLink(slice?.primary?.video_content) || ''}
          style={{
            filter: `contrast(${
              slice?.primary?.video_contrast || 100
            }%) brightness(${slice?.primary?.video_brightness || 100}%)`,
            maxHeight,
          }}
        />
      </Link>
    </>
  );

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        [style.hero__custom_dimensions_margin]: marginBottom,
      })}
      style={{ background }}
    >
      <Container
        className={classNames(style.hero__custom_dimensions, {
          [style['hero__custom_dimensions--fullWidth']]:
            slice?.primary?.video_on_full_width,
        })}
      >
        {renderVideoContent()}
      </Container>
    </section>
  );
};
