import { useQuery } from '@apollo/client';
import { LIST_NAVIGATIONS_QUERY } from './schema';

export function useListNavigations(options) {
  const { data, error, loading } = useQuery(LIST_NAVIGATIONS_QUERY, options);

  return {
    loading: loading,
    data: data?.listNavigations,
    error: error,
  };
}
