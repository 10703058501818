import { FC } from 'react';

import { VideoSlice } from '@/types';

import DefaultVariation from './DefaultVariation';
import HeroVideoVariation from './HeroVideoVariation';

const Video: FC<VideoSlice> = slice => {
  if (slice.variation === 'heroVideo') {
    return <HeroVideoVariation slice={slice} />;
  }

  return <DefaultVariation slice={slice} />;
};

export default Video;
