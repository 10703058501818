import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import classNames from 'classnames';

import {
  Button,
  Close,
  DiscountsSection,
  Heading,
  Portal,
  useProductsContext,
} from '@/components';

import { FiltersList } from '../FiltersTreez.components';
import { FiltersModalFooter } from './FiltersModal.components';

import styles from './FiltersModal.module.scss';
import { useIsMobile } from 'hooks/useIsMobile';

interface IFiltersModal {
  isOpen: boolean;
  showDealsFilter?: boolean;
  onClose: () => void;
  priceFilterActive: boolean;
  setPriceFilterActive: Dispatch<SetStateAction<boolean>>;
  setThcFilterActive: Dispatch<SetStateAction<boolean>>;
  thcFilterActive: boolean;
}

const FiltersModal: FC<IFiltersModal> = ({
  showDealsFilter,
  onClose,
  isOpen,
  priceFilterActive,
  thcFilterActive,
  setPriceFilterActive,
  setThcFilterActive,
}) => {
  const { isMobile } = useIsMobile();
  const [rootNode, setRootNode] = useState<HTMLElement>();
  const { clearFilters } = useProductsContext();

  useEffect(() => {
    setRootNode(document.body);
  }, []);

  if (!rootNode) {
    return null;
  }

  return (
    <>
      <Portal
        activeSnapPoint={1}
        classContent={styles.filters_modal}
        direction={isMobile ? 'bottom' : 'left'}
        ignoreOutsideClick={!isMobile}
        isOpen={isOpen}
        nested={!isMobile}
        snapPoints={[1]}
        toggle={onClose}
      >
        <div className={styles.filters_header}>
          <Heading className={styles.filters_header__title} level={2}>
            Filters
          </Heading>
          <div className={styles.filters_header__actions}>
            <Button
              className={styles.filters_header__clear}
              onClick={clearFilters}
            >
              CLEAR ALL
            </Button>
            <Close onClick={() => onClose()} />
          </div>
        </div>
        <div className={styles.filters_body}>
          <DiscountsSection />
          <FiltersList
            className={classNames(styles.filters_list__wrapper, {
              [styles.hidden]: !isOpen,
            })}
            priceFilterActive={priceFilterActive}
            setPriceFilterActive={setPriceFilterActive}
            setThcFilterActive={setThcFilterActive}
            showDealsFilter={showDealsFilter}
            thcFilterActive={thcFilterActive}
          />
        </div>
        <FiltersModalFooter onClose={onClose} />
      </Portal>
      {!isMobile && (
        <div
          className={classNames(styles.cart_nav__overlap, {
            [styles.cart_nav__overlap_open]: isOpen,
          })}
          onClick={() => onClose()}
          role="button"
        />
      )}
    </>
  );
};

export { FiltersModal };
