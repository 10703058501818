import { Placeholder } from '@/components';
import styles from './FilterTreezPlaceholder.module.scss';

const FiltersTreezPlaceholder = () => {
  return (
    <main>
      <section className={styles.filter__section_mobile_placeholder}>
        <div className={styles.filter__mobile_placeholder}>
          <Placeholder />
          <Placeholder />
        </div>
      </section>
      <section className={styles.filter__section_placeholder}>
        <Placeholder className={styles.placeholder__dicount_badge} />

        <ul style={{ padding: '0 10px' }}>
          {new Array(3).fill(null).map((_, index) => (
            <Placeholder className={styles.placeholder__discount} key={index} />
          ))}
        </ul>

        <div className={styles.placerholder__divider} />

        {new Array(4).fill(null).map((_, index) => (
          <div
            className={styles.placeholder__filter_container}
            key={`filter-${index}`}
          >
            <Placeholder className={styles.placeholder__filter_title} />

            <ul className={styles.placeholder_refinementList__filters}>
              {new Array(3).fill(null).map((_, index) => (
                <li
                  className={styles.placeholder_refinementList__filters_item}
                  key={index}
                >
                  <Placeholder className={styles.placeholder__filter} />
                </li>
              ))}
            </ul>

            <div className={styles.placerholder__divider} />
          </div>
        ))}
      </section>
    </main>
  );
};

export { FiltersTreezPlaceholder };
