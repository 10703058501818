import { createContext, Dispatch, SetStateAction, use } from 'react';

import { ILocalAddress } from '@/components';
import { TreezStore } from '@/types';

interface IStoreLocatorContext {
  singleStore: boolean;
  onlyDelivery: boolean;
  onlyPickup: boolean;
  deliveryAndPickup: boolean;
  isDelivery: boolean;
  isPickup: boolean;
  isDeliveryAddressInvalid: boolean;
  isPickupInvalid: boolean;
  selectedStoreId: string;
  storeAddress: string;
  deliveryAddress: ILocalAddress;
  stores: TreezStore[];
  setStores: Dispatch<SetStateAction<TreezStore[]>>;
  setStoreAddress: (_storeAddress: string) => void;
  setDeliveryAddress: (_deliveryAddress?: ILocalAddress) => void;
  setIsDelivery: Dispatch<SetStateAction<boolean>>;
  setIsPickup: Dispatch<SetStateAction<boolean>>;
  setIsDeliveryAddressInvalid: Dispatch<SetStateAction<boolean>>;
  setIsPickupInvalid: Dispatch<SetStateAction<boolean>>;
  setSelectedStoreId: (_storeId: string) => void;
  showOverlay: boolean;
  setShowOverlay: Dispatch<SetStateAction<boolean>>;
  externalStores: TreezStore[];
  isDeliveryFromEconStore: boolean;
}

const StoreLocatorContext = createContext<IStoreLocatorContext | undefined>(
  undefined,
);

StoreLocatorContext.displayName = 'StoreLocatorContext';

const useStoreLocatorContext = () => {
  const context = use(StoreLocatorContext);

  if (!context) {
    throw new Error(
      // eslint-disable-next-line max-len
      'useStoreLocatorContext should be used within an StoreLocatorProvider',
    );
  }

  return context;
};

export { StoreLocatorContext, useStoreLocatorContext };
