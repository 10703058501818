'use client';

import { FC, useMemo, useState } from 'react';
import { differenceInYears } from 'date-fns';

import { Button, DatePicker, Input, Link, Modal, useData } from '@/components';
import { useEcomStoreSelector, useForm } from '@/data';

import styles from './age.module.scss';
import { PrismicRichText } from '@prismicio/react';
import { AgeGateDocumentDataAgreementsItem } from '@/types';
import { PrismicNextImage } from '@prismicio/next';
import { useFixedBodyOnPopup } from '@/hooks';

const AgeGate: FC = () => {
  const { terms_accepted, setState } = useEcomStoreSelector(['terms_accepted']);
  useFixedBodyOnPopup({ isPopupOpen: !terms_accepted });

  const [birthDate, setBirthDate] = useState<Date>();

  const { header, ageGate, crmModal } = useData();

  const [hasIncompleteFields, setHasIncompleteFields] = useState(false);
  const [isYounger, setIsYounger] = useState(false);
  const [agreementsAccepted, setAgreementsAccepted] = useState(true);

  const type = ageGate?.data.type || '';
  const buttonType = ageGate?.data?.button_type;
  const agreements = ageGate?.data?.agreements || [];
  const rememberText = `Remember me for ${ageGate?.data?.remember_me} days.`;
  const allowedAge = ageGate?.data?.allowed_age || 0;
  const delay =
    parseInt(process?.env?.NEXT_PUBLIC_DELAY_CRMMODAL ?? '100') || 100;

  const initialAgreements = {};

  if (agreements.length) {
    agreements?.forEach((agreement: AgeGateDocumentDataAgreementsItem, id) => {
      initialAgreements[`agreement_${id}`] = agreement.default_state;
    });
  }

  const { inputs, genericChange } = useForm(initialAgreements);

  const errorMessage = useMemo(() => {
    if (hasIncompleteFields) {
      return 'Please enter a valid date of birth.';
    }
    if (isYounger) {
      return `You must be at least ${allowedAge} years old.`;
    }
    if (!agreementsAccepted) {
      return 'You must agree to our terms before continuing.';
    }
    return null;
  }, [hasIncompleteFields, isYounger, agreementsAccepted]);

  const handleSubmit = event => {
    event.preventDefault();

    if (!areAgreementsAccepted()) {
      return;
    }

    setState({
      terms_accepted: true,
    });

    if (crmModal) {
      setTimeout(() => {
        setState({ is_crm_modal_open: true });
      }, delay);
    }
  };

  const handleSubmitDate = event => {
    event.preventDefault();

    if (!birthDate) {
      setHasIncompleteFields(true);
      clearErrorMessage();
      return;
    }

    if (differenceInYears(new Date(), birthDate) < allowedAge) {
      setIsYounger(true);
      clearErrorMessage();
      return;
    }

    if (!areAgreementsAccepted()) {
      return;
    }

    setState({
      terms_accepted: true,
    });

    if (crmModal) {
      setTimeout(() => {
        setState({ is_crm_modal_open: true });
      }, delay);
    }
  };

  const areAgreementsAccepted = () => {
    if (!inputs) {
      return true;
    }

    const accepted = Object.values(inputs).every(v => v);
    setAgreementsAccepted(accepted);

    if (!accepted) {
      clearErrorMessage();
      return false;
    }

    return true;
  };

  const handleAgreementChange = event => {
    genericChange(event.target.name, event.target.checked);
  };

  const clearErrorMessage = () => {
    setTimeout(() => {
      setHasIncompleteFields(false);
      setIsYounger(false);
      setAgreementsAccepted(true);
    }, 4000);
  };

  const logo = ageGate?.data?.logo?.url
    ? ageGate?.data?.logo
    : header?.data?.logo;

  return (
    <Modal
      bgImage={ageGate?.data?.background_image}
      className={styles.age}
      isOpen={!terms_accepted}
      overlayBgColor={ageGate?.data?.overlay_background_color as string}
      overlayOpacity={ageGate?.data?.overlay_opacity as number}
      popupBgColor={ageGate?.data?.popup_background_color as string}
      sm
    >
      <figure className={styles.age__image}>
        <PrismicNextImage
          fallbackAlt=""
          field={logo}
          height={60}
          style={{ objectFit: 'contain' }}
          width={300}
        />
      </figure>
      <div className={styles.age__message}>
        <PrismicRichText
          components={{
            heading2: ({ children }) => (
              <h2 className={styles.age__heading}>{children}</h2>
            ),
          }}
          field={ageGate?.data.agreement_text}
        />
      </div>
      {type === 'Date of Birth' && (
        <DatePicker onChange={d => setBirthDate(d)} />
      )}
      {ageGate?.data?.remember_me && (
        <>
          <Input
            className={styles.option}
            name="remember_me"
            onChange={() => {}}
            type="checkbox"
          >
            <label>{rememberText}</label>
          </Input>
        </>
      )}
      {ageGate?.data?.remember_me && agreements && (
        <hr className={styles.separator} />
      )}
      {agreements && (
        <form>
          {agreements.map(
            (cl, id) =>
              cl?.text && (
                <Input
                  checked={inputs[`agreement_${id}`]}
                  className={styles.option}
                  key={`agreement_${id}`}
                  name={`agreement_${id}`}
                  onChange={handleAgreementChange}
                  type="checkbox"
                >
                  <label>{cl.text}</label>
                </Input>
              ),
          )}
        </form>
      )}
      <div className={styles.age__options}>
        {errorMessage && (
          <div className={styles.age__error}>{errorMessage || <br />}</div>
        )}
        <Button
          color={buttonType ?? 'primary'}
          onClick={type === 'Date of Birth' ? handleSubmitDate : handleSubmit}
        >
          {ageGate?.data.button_text}
        </Button>
        <Link
          className="button button_sm button_color-link age__exit"
          href="about:blank"
        >
          Exit the site
        </Link>
      </div>
    </Modal>
  );
};

export default AgeGate;
