import { FC, HTMLAttributes, Ref, useEffect, useState } from 'react';
import { TEMPLATE_BASE_PATH } from '@/constants';
import { stringToSlug } from '@/utils';
import { useSpecialsContext } from '@/context';
import { Button, Icon, Link } from '@/components';
import style from './BundlePopup.module.scss';
import classNames from 'classnames';
import { JaneSpecial, Product } from '@/types';

interface IBundlePopupProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onDetail?: boolean;
  bundleProducts: Product[];
  bundleJaneSpecial: JaneSpecial;
  bundleId?: string;
  onClose: () => void;
  ref?: Ref<HTMLDivElement | null>;
}

const BundlePopup: FC<IBundlePopupProps> = ({
  open,
  onDetail,
  bundleId,
  onClose,
  ref,
  ...props
}) => {
  const { isLoading, specials } = useSpecialsContext();
  const [currentSpecial, setCurrentSpecials] = useState<JaneSpecial>();

  useEffect(() => {
    const specialsFiltered = specials?.find(spe => bundleId === spe?.id);

    setCurrentSpecials(specialsFiltered);
  }, [isLoading, bundleId]);

  if (!open) return null;

  const renderBundleContent = () => (
    <Link
      className={style.bundle_panel__product_link}
      href={`${TEMPLATE_BASE_PATH?.SPECIALS}${stringToSlug(
        currentSpecial?.title ?? '',
      )}?special_id=${currentSpecial?.id}`}
    >
      Click here to claim: {currentSpecial?.title}
    </Link>
  );

  return (
    <div
      className={classNames(style.bundle, {
        [style.on_detail]: onDetail,
      })}
      {...props}
    >
      <div className={style.bundle_panel} ref={ref}>
        <Button className={style.bundle_panel__close} icon onClick={onClose}>
          <Icon name="close" />
        </Button>
        <p className={style.bundle_panel__title}>
          You are on your way to a Bundle Special!
        </p>
        {renderBundleContent()}
      </div>
    </div>
  );
};

export { BundlePopup };
