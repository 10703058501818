import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceCarouselWithImageAndInfo,
} from 'prismicio-types';
import { CSSProperties, FC } from 'react';
import { useRouter } from 'next/navigation';
import { ImageField } from '@prismicio/client';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

import { PrismicRichText } from '@prismicio/react';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';
import { htmlSerializerWithProps } from '@/utils';
import { Button, Icon, Slider } from '@/components';
import { TEMPLATE_BASE_PATH } from '@/constants';

import style from './carouselWithImageAndInfo.module.scss';

export const CarouselWithImageAndInfoVariation: FC<{
  slice: ImageWithTextSideBySideSliceCarouselWithImageAndInfo &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);

  const {
    content_background_color,
    content_horizontal_align,
    content_vertical_align,
    description_color,
    heading_color,
    image_border_radius,
    icon_as_heading_max_width,
    layout_direction,
    title_color,
    autoplay_image_carousel,
    section_background_color,
  } = slice.primary;

  const router = useRouter();

  const layoutDirection = layout_direction ?? 'left';
  const alignText = content_horizontal_align ?? 'center';

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'left':
        return 'flex-start';

      case 'right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  const bodyVariables = {
    '--content-padding-left': layoutDirection === 'left' ? '13.6%' : '0px',
    '--content-padding-right': layoutDirection === 'left' ? '0px' : '13.6%',
  };

  const handleCtaBtn = (cta_link?: string) => {
    if (cta_link) {
      router.push(cta_link);
      return;
    }
    router.push(TEMPLATE_BASE_PATH.SHOP);
  };

  const renderImage = (image?: ImageField) => {
    return (
      <figure
        className={classNames(style.image, {
          [style.image__default]: !image?.url,
        })}
        style={{
          borderRadius: image_border_radius ?? 0,
        }}
      >
        {image?.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={image}
            height={image?.dimensions?.height}
            style={{ objectFit: 'cover' }}
            width={image?.dimensions?.width}
          />
        ) : (
          <Icon name="image" />
        )}
      </figure>
    );
  };

  if (!filteredItems?.length) {
    return null;
  }

  return (
    <Slider
      autoplay={
        autoplay_image_carousel
          ? {
              delay: 5000,
              disableOnInteraction: false,
              stopOnLastSlide: false,
            }
          : false
      }
      className={style.swiper_container}
      effect="fade"
      loop
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      pagination={{
        clickable: true,
      }}
      showSliderButtons={true}
      showSliderHeader={false}
      showSlidesOutsideContainer={true}
      slidesPerView={1}
      spaceBetween={16}
    >
      {filteredItems?.map((item, index) => {
        return (
          <div className={style.carousel_swiper_container} key={index}>
            <div
              className={style.image_carousel_swiper}
              key={index}
              style={{ background: section_background_color ?? undefined }}
            >
              {renderImage(item?.image)}
            </div>
            <div
              className={style.body}
              style={
                {
                  ...bodyVariables,
                } as CSSProperties
              }
            >
              <div
                className={classNames(style.content, {
                  [style.content_with_background]: !!content_background_color,
                })}
                style={{
                  alignItems: textAlign(content_horizontal_align as string),
                  textAlign: alignText,
                  alignSelf: textAlign(content_vertical_align),
                  background:
                    content_background_color ||
                    section_background_color ||
                    undefined,
                  borderRadius:
                    !!content_background_color && image_border_radius
                      ? image_border_radius
                      : 0,
                }}
              >
                {item?.icon_as_heading?.url && (
                  <figure
                    className={style.body__heading_icon}
                    style={{ maxWidth: icon_as_heading_max_width ?? '' }}
                  >
                    <PrismicNextImage
                      fallbackAlt=""
                      field={item?.icon_as_heading}
                      height={item?.icon_as_heading?.dimensions?.height}
                      style={{ objectFit: 'cover' }}
                      width={item?.icon_as_heading?.dimensions?.width}
                    />
                  </figure>
                )}
                {item?.heading && (
                  <PrismicRichText
                    components={htmlSerializerWithProps(
                      {
                        color: heading_color,
                      },
                      style.body__text_heading,
                    )}
                    field={item?.heading}
                  />
                )}
                {item?.title && (
                  <div
                    className={style.body__title_container}
                    style={
                      {
                        '--icon-order': alignText === 'right' ? 0 : 1,
                        '--title-order': alignText === 'right' ? 1 : 0,
                      } as CSSProperties
                    }
                  >
                    <PrismicRichText
                      components={htmlSerializerWithProps(
                        {
                          color: title_color,
                        },
                        style.body__title,
                      )}
                      field={item?.title}
                    />
                  </div>
                )}
                {item?.description && (
                  <div className={style.description_section}>
                    <PrismicRichText
                      components={htmlSerializerWithProps({
                        color: description_color,
                      })}
                      field={item?.description}
                    />
                  </div>
                )}
                {item?.cta_text && (
                  <Button
                    className={style.cta}
                    color="secondary"
                    onClick={() => handleCtaBtn(item?.cta_link ?? '')}
                  >
                    {item?.cta_text}
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
