import { FC } from 'react';
import { formatWeightLabel } from '../Counter/Utils';
import styles from './weigthvariants.module.scss';
import { Button } from '../Button';
import classNames from 'classnames';
import { GProduct, Variant } from '@/types';

import { currencyFormat } from '@/utils';
import { Heading } from '../atoms/heading';
import { useFeaturedFlag, useProducts } from '@/hooks';

interface IWeightVariants {
  currentWeightVariant: Variant;
  product: GProduct;
  handleWeightVariatChange: (_: Variant) => void;
}

const WeightVariants: FC<IWeightVariants> = ({
  currentWeightVariant,
  handleWeightVariatChange,
  product,
}) => {
  const { products } = useProducts();
  const { showStriketroughPrice } = useFeaturedFlag();

  const variantActives = products?.reduce((result, current) => {
    if (current?.id !== product.id) {
      return result;
    }
    return `${current?.weightVariant?.id},${result}`;
  }, '');

  return (
    <>
      <Heading
        className={styles.counter__weight_variant_title_weights}
        level={3}
      >
        Available variants
      </Heading>
      <div className={styles.counter__weight_variant_container}>
        {product?.variants?.map(variant => {
          const id = variant?.id!;
          const priceLabel = currencyFormat(variant?.salePrice!);
          const discountLabel = currencyFormat(variant?.price!);

          const weight = formatWeightLabel(variant!);

          const label = weight?.endsWith('G')
            ? `${weight.split('G')[0]} gram`
            : weight;

          return (
            <Button
              className={classNames(styles.weight_variant_btn, {
                [styles.weight_variant_btn_active]:
                  currentWeightVariant?.id == id ||
                  variantActives.includes(`${id},`),
              })}
              key={id}
              onClick={() => handleWeightVariatChange(variant!)}
            >
              <div className={styles.weight_variant_btn_content}>
                <span className={styles.weight_variant_btn_label}>{label}</span>
                <div className={styles.weight_variant_btn_price_container}>
                  <span
                    className={classNames(styles.weight_variant_btn_price, {
                      [styles.weight_variant_btn_price_active]:
                        currentWeightVariant?.id == id ||
                        variantActives.includes(`${id},`),
                    })}
                  >
                    ${priceLabel}
                  </span>
                  {showStriketroughPrice &&
                    !(variant?.price === variant?.salePrice) && (
                      <span className={styles.weight_variant_btn_discount}>
                        {discountLabel}
                      </span>
                    )}
                </div>
              </div>
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default WeightVariants;
