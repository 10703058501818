import classNames from 'classnames';

function Separator({ children, className, ...props }) {
  return (
    <div className={classNames('separator', className)} {...props}>
      {children && <span className="separator__label">{children}</span>}
    </div>
  );
}

export default Separator;
