import classNames from 'classnames';
import { FC, ReactNode } from 'react';

interface ISpinnerProps {
  children?: ReactNode;
  sm?: boolean;
  className?: string;
}

const Spinner: FC<ISpinnerProps> = ({ children, sm, className }) => {
  return (
    <div className={classNames('spinner', { spinner_sm: sm }, className)}>
      <div className="spinner__animation" />
      {children && <p className="spinner__label">{children}</p>}
    </div>
  );
};

export default Spinner;
