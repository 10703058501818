import classNames from 'classnames';
import {
  FacebookShareButton,
  InstapaperShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { Icon } from '@/components';

import styles from './sharing.module.scss';
import { FC } from 'react';

const Sharing: FC<SharingProps> = ({
  url,
  media,
  include,
  className,
  iconStyle,
}) => {
  const commonProps = {
    resetButtonStyle: false,
    url,
  };

  const isIncluded = net => !include || include.includes(net);

  return (
    <div className={classNames(styles.sharing, 'sharing', className)}>
      {isIncluded('facebook') && (
        <FacebookShareButton {...commonProps}>
          <Icon name="facebook" style={iconStyle} />
        </FacebookShareButton>
      )}
      {isIncluded('twitter') && (
        <TwitterShareButton {...commonProps}>
          <Icon name="twitter" style={iconStyle} />
        </TwitterShareButton>
      )}
      {isIncluded('pinterest') && (
        <PinterestShareButton {...commonProps} media={media as string}>
          <Icon name="pinterest" style={iconStyle} />
        </PinterestShareButton>
      )}
      {isIncluded('whatsapp') && (
        <WhatsappShareButton {...commonProps}>
          <Icon name="whatsapp" style={iconStyle} />
        </WhatsappShareButton>
      )}
      {isIncluded('instapaper') && (
        <InstapaperShareButton {...commonProps}>
          <Icon name="instapaper" style={iconStyle} />
        </InstapaperShareButton>
      )}
    </div>
  );
};

interface SharingProps {
  url: string;
  media?: string;
  include: string[];
  className?: string;
  iconStyle?: object;
}

export default Sharing;
