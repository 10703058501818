import { MapSlice as MapSliceType } from 'prismicio-types';
import { CSSProperties, FC, useMemo } from 'react';
import { useIsMobile, useScreenWidth } from '@/hooks';
import { Container, ILocation, Maps, useData } from '@/components';
import {
  alignContent,
  convertHexadecimalFormatToRGB,
  getSlicePadding,
  htmlSerializerWithProps,
  mapStoreToLocation,
} from '@/utils';
import classNames from 'classnames';
import style from './mapslice.module.scss';
import { asText } from '@prismicio/client';
import Link from 'next/link';
import { PrismicRichText } from '@prismicio/react';

const MapSlice: FC<MapSliceType> = slice => {
  const { treezStores, storeLocator } = useData();
  const { width } = useScreenWidth();
  const { isMobile } = useIsMobile(768);

  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.bottom_spacing;
  const fullWidth = slice?.primary?.full_width;
  const ctaBackgroundColor = slice?.primary?.cta_background_color ?? '';
  const ctaBackgroundColorHover =
    slice?.primary?.cta_background_color_hover ?? '';
  const ctaTextColor = slice?.primary?.cta_text_color ?? '';
  const ctaTextColorHover = slice?.primary?.cta_text_color_hover ?? '';
  const ctaText = slice?.primary?.cta_button_text;
  const maxHeight = slice?.primary?.map_height;
  const blur = slice?.primary?.content_background_blur
    ? `blur(${slice?.primary?.content_background_blur}px)`
    : undefined;
  const borderStyle = `1px solid ${slice?.primary?.cta_border_color}`;
  const borderStyleHover = `1px solid ${slice?.primary?.cta_border_color_hover}`;
  const verticalPosition = slice?.primary?.vertical_position ?? 'center';
  const horizontalPosition = slice?.primary?.horizontal_position ?? 'center';
  const contentMinWidth = slice?.primary?.content_width
    ? `${slice?.primary?.content_width}%`
    : undefined;
  const textAlign = slice?.primary?.text_alignment ?? undefined;
  const textColor = slice?.primary?.text_color ?? undefined;
  const heading = slice?.primary?.heading_text;
  const headingColor = slice.primary.heading_color ?? undefined;
  const title = slice?.primary?.title;
  const titleColor = slice?.primary?.title_color ?? undefined;
  const description = slice?.primary?.description;
  const showCTA = slice?.primary?.has_cta_button;
  const applyShadow = slice?.primary?.apply_shadow_to_text;

  const storesLocations = useMemo(
    () => treezStores.map(mapStoreToLocation),
    [treezStores.length],
  );

  if (!slice?.primary?.active) {
    return null;
  }

  const getMaxHeight = () => {
    const contentMaxHeigth = 380;

    if (width && !isMobile && maxHeight) {
      return `${maxHeight}px`;
    }

    if (width && isMobile && maxHeight) {
      const maxHeightResult = maxHeight + contentMaxHeigth;
      return `${maxHeightResult}px`;
    }

    return;
  };

  const getBackground = () => {
    const background = slice?.primary?.content_background_color;
    const opacity = slice?.primary?.content_background_opacity;
    if (opacity && background) {
      const rgb = convertHexadecimalFormatToRGB(background);

      return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${opacity})`;
    }

    if (background) {
      return background;
    }

    return undefined;
  };

  const getSpacingLeft = () => {
    const breakpoints = {
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
      '3xl': 2048,
    };
    const closestBreakpoint = Object.values(breakpoints).reduce((acc, curr) => {
      return curr <= width ? curr : acc;
    }, 0);

    const resultClosestBreakpoint = closestBreakpoint - 40;

    const spacing = width - resultClosestBreakpoint;

    return spacing / 2;
  };

  const contentPaddingLeft =
    fullWidth && !isMobile ? getSpacingLeft() : undefined;

  const getZoom = () => {
    if (slice?.primary?.map_zoom) {
      return slice?.primary?.map_zoom;
    }

    if (storesLocations?.length === 1) {
      return 10;
    }

    return 0;
  };

  return (
    <section
      className={classNames(style.mapslice, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
    >
      <Container
        className={classNames(style.mapslice__container, {
          [style.mapslice__container_full_width]: fullWidth,
        })}
        style={{
          height: getMaxHeight(),
        }}
      >
        <div
          className={classNames(style.content, {
            [style.content__full]: fullWidth,
          })}
          style={{
            background: getBackground(),
            backdropFilter: blur,
            alignItems: alignContent(verticalPosition),
            justifyContent: horizontalPosition,
            minWidth: contentMinWidth,
            paddingLeft: contentPaddingLeft,
          }}
        >
          {asText(title) || asText(description) || heading || ctaText ? (
            <div
              className={classNames(style.content__description, {
                [style.content__description_container]: fullWidth,
                [style.content__description_shadow]: applyShadow,
              })}
              style={{
                alignItems: horizontalPosition,
                textAlign: textAlign,
              }}
            >
              {heading && (
                <h4
                  className={style.content__description__heading}
                  dangerouslySetInnerHTML={{
                    __html: heading as string,
                  }}
                  style={{
                    color: headingColor,
                  }}
                />
              )}
              {title?.[0]?.type === 'heading1' ? (
                <h1
                  className={style.content__description__header}
                  dangerouslySetInnerHTML={{
                    __html: asText(title),
                  }}
                  style={{ color: titleColor }}
                />
              ) : (
                <h2
                  className={style.content__description__header}
                  dangerouslySetInnerHTML={{
                    __html: asText(title),
                  }}
                  style={{ color: titleColor }}
                />
              )}
              {asText(description) && (
                <div className={style.content__description__descrip}>
                  <PrismicRichText
                    components={htmlSerializerWithProps({ color: textColor })}
                    field={description}
                  />
                </div>
              )}
              {showCTA && (
                <Link
                  className={style.content__description__action}
                  href={slice?.primary?.link ?? ''}
                  style={
                    {
                      '--cta-background-color': ctaBackgroundColor,
                      '--cta-background-color-hover': ctaBackgroundColorHover,
                      '--cta-text-color': ctaTextColor,
                      '--cta-text-color-hover': ctaTextColorHover,
                      '--border-style': borderStyle,
                      '--border-style-hover': borderStyleHover,
                    } as CSSProperties
                  }
                >
                  {ctaText}
                </Link>
              )}
            </div>
          ) : null}
        </div>
        <Maps
          centered="rigth"
          className={style.mapslice__map}
          darkMode={slice?.primary?.map_style === 'gray'}
          defaultMarker={storeLocator.custom_marker.url!}
          hideInterestingPoints
          locations={storesLocations as ILocation[]}
          markerStyle={{
            titleColor: storeLocator.marker_title_color,
            deaultMarkerColor: storeLocator.default_map_marker_color,
          }}
          openInfo={false}
          overlay={slice?.primary?.map_style === 'dark'}
          zoom={getZoom()}
        />
      </Container>
    </section>
  );
};

export default MapSlice;
