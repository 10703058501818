import { gql } from '@apollo/client';

export const SET_EMAIL_PREFERENCE_MUTATION = gql`
  mutation setEmailPreference(
    $account_id: String!
    $store_id: String!
    $input: EmailPreferenceInput!
  ) {
    setEmailPreference(
      account_id: $account_id
      store_id: $store_id
      input: $input
    ) {
      status
      pinpoint_status
      error
    }
  }
`;
