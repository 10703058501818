import { gql } from '@apollo/client';

export const LIST_CATEGORY_QUERY = gql`
  query listCategory($account_id: String!, $store_id: String!) {
    listCategory(account_id: $account_id, store_id: $store_id) {
      id
      name
      enabled
      is_cannabis
      created_at
      updated_at
      priority
      unit_type
      description
      short_description
      cannabis_type
      wm_category
      low_threshold
      slug
      asset {
        thumbnail
        image
        image_large_size
        image_medium_size
        image_extra_large_size
      }
    }
  }
`;
