import { FC } from 'react';

import { format } from 'date-fns';

import { Icon, Image, Rating } from '@/components';
import { getActivityIconName, getFeelingIconName } from '@/utils';
import { CustomerReview } from '@/types';

import style from './review.module.scss';

interface IReviewProps {
  review: CustomerReview;
  className?: string;
}

const Review: FC<IReviewProps> = ({
  review: { nickname, comment, rating, updated_at, feelings, activities },
  className,
}) => {
  return (
    <div className={`${style.reviews} ${className ?? ''}`}>
      <div className={style.review_user}>
        <figure>
          <Image
            alt="avatar"
            height={40}
            src="/images/user-icon.svg"
            width={40}
          />
        </figure>
        <div className={style.review_user__info}>
          <cite>{nickname}</cite>
          <span className={style.review_verified}>
            <Icon name="ready-icon" />
            Verified purchase
          </span>
        </div>
      </div>

      <div className={style.review__content}>
        <Rating value={rating} />
        <blockquote>{comment}</blockquote>
        <div className={style.filters_icons}>
          {feelings.map((feeling, index) => (
            <Icon
              className={style.filters_icons__item}
              key={index}
              name={getFeelingIconName(feeling)}
              title={feeling}
            />
          ))}
          {activities.map((activity, index) => (
            <Icon
              className={style.filters_icons__item}
              key={index}
              name={getActivityIconName(activity)}
              title={activity}
            />
          ))}
        </div>
      </div>
      <time dateTime={new Date(updated_at).toLocaleString()}>
        {format(new Date(updated_at), 'MMMM dd, yyyy')}
      </time>
    </div>
  );
};

export default Review;
