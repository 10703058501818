import {
  MedRecSwitch,
  Promotion,
  SearchHeader,
  StoreLocator,
} from '@/components';

import {
  HeaderBurgerNav,
  HeaderContainer,
  HeaderFavorites,
  HeaderLogo,
  HeaderNav,
  HeaderProfile,
  HeaderRow,
  HeaderSection,
  HeaderShopping,
} from '../Header.components';

const HeaderWithCenteredLogoAndLocatorFix = () => (
  <>
    <HeaderWithCenteredLogoAndLocatorFixMobile />
    <HeaderWithCenteredLogoAndLocatorFixDesktop />
  </>
);

const HeaderWithCenteredLogoAndLocatorFixMobile = () => (
  <HeaderContainer.Mobile>
    <HeaderRow borderless differentBackground type="small">
      <HeaderSection justify="center" spaceBetweenOnMultipleChildren>
        <StoreLocator />
        <MedRecSwitch />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow borderless fullWidth type="flat">
      <Promotion location="header" />
    </HeaderRow>
    <HeaderRow borderless type="small">
      <HeaderSection justify="start">
        <HeaderBurgerNav />
        <HeaderLogo fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <SearchHeader withEmbeddedInput />
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Mobile>
);

const HeaderWithCenteredLogoAndLocatorFixDesktop = () => (
  <HeaderContainer.Desktop>
    <HeaderRow borderless differentBackground type="flat">
      <HeaderSection justify="center">
        <StoreLocator />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow borderless fullWidth type="flat">
      <Promotion location="header" />
    </HeaderRow>
    <HeaderRow withNavbar>
      <HeaderSection justify="start">
        <HeaderNav />
      </HeaderSection>
      <HeaderSection justify="center">
        <HeaderLogo center fixHeight fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <SearchHeader withEmbeddedInput />
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
        <MedRecSwitch />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Desktop>
);

export { HeaderWithCenteredLogoAndLocatorFix };
