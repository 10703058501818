'use client';
import { useState } from 'react';
import classNames from 'classnames';

import { Collapse, Icon } from '@/components';
import { currencyFormat } from '@/utils';
import style from './cartnavigation.module.scss';
import { TreezSpecial } from '@/types';
import { useFeaturedFlag } from '@/hooks';

const DiscountsInfo = ({
  specials,
  discounts,
}: {
  specials?: TreezSpecial[];
  discounts: number;
}) => {
  const [isDiscountsOpen, setIsDiscountsOpen] = useState(false);
  const { isTaxAppliedMessage } = useFeaturedFlag();

  const getFormatedTitle = (title: string) => {
    const lowerCaseTitle = title.split('-').join(' ').toLowerCase();
    return lowerCaseTitle[0].toUpperCase() + lowerCaseTitle.slice(1);
  };

  if (isTaxAppliedMessage) {
    return (
      <div className={style.footer__discount_section__discount_included}>
        <span className={style.heading}>Discounts</span>
        <span className={style.grams}>-${currencyFormat(discounts)}</span>
      </div>
    );
  }

  return (
    <div className={style.footer__discount_section}>
      <button
        aria-controls="accordion-content-iEffects"
        className={style.footer__discounts_btn}
        onClick={() => {
          setIsDiscountsOpen(prev => !prev);
        }}
      >
        <div className={style.heading_section}>
          <span className={style.heading}>Discounts</span>
          <Icon name={isDiscountsOpen ? 'angle-up' : 'angle-down'} />
        </div>

        <span className={style.grams}>-${currencyFormat(discounts)}</span>
      </button>
      <Collapse
        className={classNames('accordion__content', {
          ['accordion__content_collapse']: isDiscountsOpen,
        })}
        id="accordion-content-iWarning"
        isOpen={isDiscountsOpen}
      >
        <div className={style.footer__discounts_container}>
          {specials?.map((item, index) => {
            return (
              <div className={style.discount_item} key={index}>
                <span>{getFormatedTitle(item?.title)}</span>
                <span
                  className={style.discount_item_amount}
                >{`-$${currencyFormat(item?.amount)}`}</span>
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default DiscountsInfo;
