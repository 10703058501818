import { groupBy, sortBy } from 'lodash';
import { formatWeightLabel as formatLabel, formatWeightNumber } from '@/utils';
import { Variant } from '@/types';

export function sumVariantsPrices(variants) {
  // Calculate the sum of all prices from selected
  // variants.
  return variants.reduce((prev, curr) => prev + curr.price, 0);
}

export function selectDefaultVariants(variantsByType) {
  // Set default selection for variants
  // taking one selection by type,
  // assuming that all variants type are
  // like radio buttons. This implementation
  // must be updated if any other kind of
  // variants are present.
  return Object.entries(variantsByType).map(type => type?.[1]?.[0]);
}

export function variantsByType(variants) {
  // Making the variants list filtering only those
  // with prices > 0 only for type "Gram",
  // next sorting by price and finally grouping by type.
  return groupBy(
    sortBy(
      (variants ?? []).filter(variant => variant.price > 0),
      ['price'],
    ),
    'type',
  );
}

export function sortVariantByPrice(variants: Variant[]): Variant[] {
  const variantsWithValidPrice = (variants ?? []).filter(
    variant => (variant?.price as number) > 0,
  );

  return sortBy(variantsWithValidPrice, ['price']);
}

export function totalQuantityInCart(productId, products) {
  return products
    .filter(item => item.id === productId)
    .reduce((prev, curr) => prev + curr.quantity, 0);
}

export function formatWeightLabel(variant: Variant): string {
  return formatLabel(variant?.type ?? '');
}

export function calculateTotalGrams(products) {
  const total = products.reduce((result, p) => {
    return result + formatWeightNumber(p.weightVariant) * p.quantity;
  }, 0);

  return total;
}

/**
 * This validation is to prevent customers from adding unlimited penny items
 * after the bundle is complete
 */
export const canAddPennyToCart = ({
  bundleComplete,
  price = Infinity,
}: {
  bundleComplete?: boolean;
  price: number;
}) => {
  const pennyValue =
    process.env.NEXT_PUBLIC_DISCOUNT_MINIMUM_THRESHOLD_VALUE ?? '0';

  if (!bundleComplete) {
    return true;
  }

  return price > parseInt(pennyValue);
};
