import { gql } from '@apollo/client';

export const LIST_PAGES_QUERY = gql`
  query listPages($account_id: String!, $store_id: String!) {
    listPages(account_id: $account_id, store_id: $store_id) {
      id
      slug
      template
      created_at
      updated_at
      publish_at
      html_body
      metadata
      image
      status
      seo {
        url
        title
        meta_description
        meta_robots
        include_local_schema
        meta_image
      }
    }
  }
`;

export const GET_PAGE_BY_SLUG = gql`
  query getPageBySlug(
    $account_id: String!
    $store_id: String!
    $slug: String!
  ) {
    getPageBySlug(account_id: $account_id, store_id: $store_id, slug: $slug) {
      id
      slug
      template
      created_at
      updated_at
      publish_at
      html_body
      metadata
      image
      status
      seo {
        url
        title
        meta_description
        meta_robots
        include_local_schema
        meta_image
      }
    }
  }
`;

export const GET_PAGE_BY_TEMPLATE = gql`
  query getPageByTemplate(
    $account_id: String!
    $store_id: String!
    $template: Template!
  ) {
    getPageByTemplate(
      account_id: $account_id
      store_id: $store_id
      template: $template
    ) {
      id
      slug
      template
      created_at
      updated_at
      publish_at
      html_body
      metadata
      image
      status
      seo {
        url
        title
        meta_description
        meta_robots
        include_local_schema
        meta_image
      }
    }
  }
`;

export const GET_PAGE_BY_PATH = gql`
  query getPageByPath(
    $account_id: String!
    $store_id: String!
    $path: String!
  ) {
    getPageByPath(account_id: $account_id, store_id: $store_id, path: $path) {
      id
      slug
      template
      created_at
      updated_at
      publish_at
      html_body
      metadata
      image
      status
      seo {
        url
        title
        meta_description
        meta_robots
        include_local_schema
        meta_image
      }
    }
  }
`;

export const LIST_PAGES_BY_TEMPLATE = gql`
  query listPagesByTemplate(
    $account_id: String!
    $store_id: String!
    $template: Template!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    listPagesByTemplate(
      account_id: $account_id
      store_id: $store_id
      template: $template
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          slug
          template
          created_at
          updated_at
          publish_at
          html_body
          metadata
          image
          status
          seo {
            url
            title
            meta_description
            meta_robots
            include_local_schema
            meta_image
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
