import { Button } from '../../components';
import style from './method.module.scss';

function Method({ inputs, setInputs, className }) {
  return (
    <div className={`${style.method} ${className ?? ''}`} role="radiogroup">
      {[
        ['delivery', 'Delivery'],
        ['pickup', 'Pickup'],
      ].map(([key, label]) => (
        <Button
          aria-checked={inputs.delivery_details[key]}
          className={
            inputs.delivery_details[key] ? style.secondary : style.primary
          }
          key={key}
          onClick={() => {
            setInputs({
              ...inputs,
              delivery_details: {
                [key]: true,

                [key === 'delivery' ? 'pickup' : 'delivery']: false,
              },
            });
          }}
          role="radio"
          sm
        >
          {label}
        </Button>
      ))}
    </div>
  );
}

export default Method;
