'use client';

import { useMemo, useState } from 'react';
import { format, isAfter, isValid, parseISO } from 'date-fns';

import {
  Alert,
  Button,
  Close,
  DateInput,
  Heading,
  Input,
  Modal,
} from '@/components';
import { useEcomStoreSelector, useForm, useValidator } from '@/data';
import { EnumCustomerType as CustomerType } from '@/types';

import styles from './MedRecValidator.module.scss';

const MedRecValidator = () => {
  const {
    customer_type,
    next_customer_type,
    medical_id,
    medical_id_expiration_date,
    setState,
  } = useEcomStoreSelector([
    'customer_type',
    'next_customer_type',
    'medical_id',
    'medical_id_expiration_date',
  ]);
  const [showAlert, setShowAlert] = useState(true);

  const { inputs, setInputs, inputChange, dateChange } = useForm();

  const { validator, isInvalid, setIsSubmited } = useValidator(
    [
      {
        field: 'medical_id_expiration_date',
        message: 'Invalid date',
        method: current => {
          const parsedDate = new Date(current);
          const isValidDate = isValid(parsedDate);
          const today = new Date();
          return isValidDate ? isAfter(parsedDate, today) : false;
        },
        validWhen: true,
      },
      {
        args: [{ min: 5 }],
        field: 'medical_id',
        message: 'Write at least 5 characters.',
        method: 'isLength',
        validWhen: true,
      },
    ],
    inputs,
  );

  useMemo(() => {
    let formatedExpDate = '';
    if (medical_id_expiration_date) {
      formatedExpDate = format(
        parseISO(medical_id_expiration_date),
        'MM-dd-yyyy',
      );
    }
    setInputs({
      medical_id,
      medical_id_expiration_date: formatedExpDate,
    });
  }, []);

  const isModalVisible = ![customer_type, null].includes(next_customer_type);

  const confirmCustomerTypeChange = async () => {
    setIsSubmited(true);

    if (isInvalid && next_customer_type === CustomerType.MEDICAL) {
      return;
    }

    const {
      medical_id,
      medical_id_expiration_date: input_medical_id_expiration_date,
    } = inputs;

    let formatedExpDate = '';
    if (input_medical_id_expiration_date) {
      formatedExpDate = format(
        new Date(input_medical_id_expiration_date),
        'yyyy-MM-dd',
      );
    }

    setState({
      line_items: [],
      customer_type: next_customer_type!,
      next_customer_type: null,
      medical_id: medical_id,
      medical_id_expiration_date: formatedExpDate,
    });
  };

  const confirmKeepCustomerType = () => {
    setState({
      next_customer_type: null,
    });
  };

  return (
    <Modal className={styles.modal} isOpen={isModalVisible} md>
      <div className={styles.store_validator}>
        <div className={styles.store_validator__header}>
          <Heading className={styles.header_title} level={2}>
            Are you sure you want to switch modes?
          </Heading>
          <Close onClick={confirmKeepCustomerType} />
        </div>
        <Alert
          className={styles.warning_message}
          isOpen={showAlert}
          toggle={() => {
            setShowAlert(false);
          }}
          warning={true}
        >
          <p>
            Be careful! You are about to change shopping mode. Your items will
            be removed from your shopping cart.
          </p>
        </Alert>
        {next_customer_type === CustomerType.MEDICAL && (
          <div className={styles.med_form__row}>
            <Input
              feedback={validator.medical_id.message}
              inputLabel="Medical ID"
              isInvalid={validator.medical_id.isInvalid}
              name="medical_id"
              onChange={inputChange}
              placeholder="Medical ID"
              value={inputs.medical_id ?? ''}
            />
            <DateInput
              autoComplete="off"
              feedback={validator.medical_id_expiration_date.message}
              inputLabel="Medical ID expiration date"
              isInvalid={validator.medical_id_expiration_date.isInvalid}
              name="medical_id_expiration_date"
              onChange={dateChange}
              placeholder="Medical ID expiration date"
              type="date"
              value={inputs.medical_id_expiration_date ?? ''}
            />
          </div>
        )}
        <div className={styles.store_validator__footer}>
          <Button color="primary" onClick={confirmCustomerTypeChange}>
            remove items & change mode
          </Button>
          <Button color="secondary" onClick={confirmKeepCustomerType}>
            Keep items & don’t change mode
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { MedRecValidator };
