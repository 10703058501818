import * as prismicH from '@prismicio/helpers';
import * as prismicT from '@prismicio/client';

const asText = (richText: prismicT.TitleField | prismicT.RichTextField) => {
  if (!richText) {
    return '';
  }

  const array = Object.keys(richText).map(
    key => richText[key],
  ) as prismicT.TitleField;

  return prismicH.asText(array);
};

const convertToRichTextField = (text: string) => {
  return [
    {
      type: 'paragraph',
      text,
      spans: [],
    },
  ];
};

export { asText, convertToRichTextField };
