'use client';

import { FC, memo } from 'react';

import { PrismicNextImage } from '@prismicio/next';
import * as prismicH from '@prismicio/helpers';

import {
  Container,
  Copyright,
  getVariation,
  Link,
  useData,
} from '@/components';

import style from './simpleFooter.module.scss';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializerWithProps } from 'utils/serializer';
import { RichTextField } from '@prismicio/client';

const SimpleFooter: FC = memo(() => {
  const { footer } = useData();

  const footerVariation = getVariation(footer?.data?.body);

  if (!footerVariation) {
    return (
      <div className={style.footer_empty}>
        <h3>
          Regrettably, the footer section <br />
          is currently unavailable for display
        </h3>
        <span>
          The footer section is either inactive or has not been created. Kindly
          establish an active footer section.
        </span>
      </div>
    );
  }

  const {
    logo,
    warning_text,
    warning_text_color,
    license,
    license_text_color,
    company,
    rights_text_color,
  } = footerVariation.primary;

  const isWarning = prismicH.asText(warning_text);

  const isLicense = prismicH.asText(license);

  const isTerm =
    footerVariation.variation === 'default' ||
    footerVariation.variation === 'withBackgroundImage' ||
    footerVariation.variation === 'linksAndSubscribeCenter'
      ? prismicH.asText(footerVariation?.primary?.term_and_use)
      : undefined;

  const term_and_use =
    footerVariation.variation === 'default' ||
    footerVariation.variation === 'withBackgroundImage' ||
    footerVariation.variation === 'linksAndSubscribeCenter'
      ? footerVariation?.primary?.term_and_use
      : '';

  const term_and_use_text_color =
    footerVariation.variation === 'default' ||
    footerVariation.variation === 'withBackgroundImage' ||
    footerVariation.variation === 'linksAndSubscribeCenter'
      ? footerVariation?.primary?.term_and_use_text_color
      : '';

  return (
    <footer
      className={style.footer}
      id="appFooter"
      style={{
        background: footerVariation?.primary.background_color || undefined,
      }}
    >
      <Container className={style.footer__container}>
        <Link className={style.logo} href="/">
          <figure>
            <PrismicNextImage
              fallbackAlt=""
              field={logo}
              height={logo?.dimensions?.height}
              quality={100}
              style={{
                objectFit: 'contain',
                width: '100%',
                height: 'auto',
              }}
              width={logo?.dimensions?.width}
            />
          </figure>
        </Link>
        <div className={style.footer__info_legal}>
          {isTerm && (
            <PrismicRichText
              components={htmlSerializerWithProps({
                color: term_and_use_text_color,
              })}
              field={term_and_use as RichTextField}
            />
          )}

          {isWarning && (
            <PrismicRichText
              components={htmlSerializerWithProps({
                color: warning_text_color,
              })}
              field={warning_text}
            />
          )}

          {isLicense && (
            <ul>
              {license?.map((item, index) => {
                return (
                  <li key={index} style={{ color: license_text_color ?? '' }}>
                    <span>{item?.text}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <Copyright
          business={company ?? ''}
          className={style.copyright}
          colorText={rights_text_color ?? ''}
          showCards={true}
        />
      </Container>
    </footer>
  );
});

export default SimpleFooter;
