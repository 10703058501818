import { UpdateAccountInput } from '@/types';

const formatDate = (date?: string) => {
  if (!date) return '';
  const [year, month, day] = date?.split('T')[0].split('-') || [];
  return `${month}/${day}/${year}`;
};

const mapUpdateAccountInput = (inputs): UpdateAccountInput => {
  const phoneNumber = inputs?.phone && `+${inputs?.phone?.replace(/\D/g, '')}`;

  const input: UpdateAccountInput = {
    // PII
    email: inputs.email,
    first_name: inputs.first_name,
    last_name: inputs.last_name,
    phone: phoneNumber,
    date_of_birth: new Date(inputs.date_of_birth).toISOString(),

    photo: inputs.photo,

    gender: inputs.gender,

    // Preferences
    accept_marketing: inputs.accept_marketing,

    // address
    address1: inputs.address1,
    address2: inputs.address2,
    city: inputs.city,
    city_code: inputs.city_code,
    country: inputs.country,
    country_code: inputs.country_code,
    province: inputs.province,
    province_code: inputs.province_code,
    latitude: inputs.latitude,
    longitude: inputs.longitude,
    zip: inputs.zip,

    // DL
    driver_license_id: inputs.driver_license_id,
    driver_license_photo: inputs.driver_license_photo,

    // Medical info
    type: inputs.type,
    medical_id: inputs.medical_id,

    // Other
    tags: inputs?.tags,
  };

  if (
    inputs.driver_license_id_expiration_date &&
    !inputs.driver_license_id_expiration_date?.includes('undefined') // our date input default the value to "undefined/undefined/undefined"
  ) {
    input.driver_license_id_expiration_date = new Date(
      inputs.driver_license_id_expiration_date,
    ).toISOString();
  } else {
    input.driver_license_id_expiration_date = undefined;
  }

  return input;
};

export { formatDate, mapUpdateAccountInput };
