import { FC } from 'react';

import { TreezStore } from '@/types';

import styles from './treezstore.module.scss';

interface IStoreRadioItemProps {
  store: TreezStore;
  isSelected?: boolean;
  isOpen?: boolean;
  onSelected: () => {};
}

const StoreRadioItem: FC<IStoreRadioItemProps> = ({
  store,
  isSelected,
  onSelected,
}) => {
  return (
    <div className={styles.store_radio_item} onClick={onSelected} role="button">
      <div className={styles.store_radio_header}>
        <input
          checked={isSelected}
          className={styles.store_radio}
          onChange={onSelected}
          type="radio"
        />
        <p>{`${store.name} - ${store.city}, ${store.state}`}</p>
      </div>
      <div className={styles.store_content}>
        <span className={styles.store_content_address}>
          {store.full_address.split(',')[0]}
        </span>
        {/*  <span
          className={classNames(styles.status, {
            [styles.closed]: !isOpen,
          })}
        >
          {isOpen ? 'Open now' : 'Closed'}
        </span> */}
      </div>
    </div>
  );
};

export { StoreRadioItem };
