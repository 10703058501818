import { useState } from 'react';
import { Alert, Heading, Subscribe } from '../components';
import { useSubscribeUser } from '../data';

function Subscription({ description }) {
  const [error, setError] = useState({
    isError: false,
    isOpen: false,
    message: '',
  });

  // Subscription
  const { subscribeUser } = useSubscribeUser({
    // eslint-disable-next-line no-unused-vars
    onCompleted: res => {
      setError({
        isError: false,
        isOpen: true,
        message:
          // eslint-disable-next-line max-len
          'Your request was sent successfully. We will get back to you as soon as possible.',
      });
    },
    // eslint-disable-next-line no-unused-vars
    onError: err => {
      setError({
        isError: true,
        isOpen: true,
        message: 'An error occurred. Please, try again later.',
      });
    },
  });

  const onSubscribe = email =>
    subscribeUser({
      variables: {
        input: { email },
      },
    });

  return (
    <section className="subscription">
      <div className="subscription__body">
        <div className="subscription__text">
          <Heading level={2}>{description}</Heading>
          <p>...</p>
        </div>
        <Alert
          error={error.isError}
          isOpen={error.isOpen}
          toggle={() => setError({ isOpen: false })}
        >
          {error.message}
        </Alert>
        <Subscribe onSubscribe={onSubscribe} />
      </div>
    </section>
  );
}

export default Subscription;
