import { FC, useState } from 'react';
import {
  Button,
  Heading,
  Icon,
  IconSvg,
  Input,
  useData,
  useProductsContext,
} from '@/components';

import { RefinementListItem } from './RefinementList.components';
import classNames from 'classnames';
import styles from './RefinementList.module.scss';
import { getFilterItems } from '@/utils';

const INITIAL_ITEMS_TO_SHOW = 10;

interface RefinementListProps {
  attribute: string;
  className: string;
  configValue?: string;
  hasSearchBar?: boolean;
  hasScrollBar?: boolean;
  searchBarPlaceholder?: string;
}

const RefinementList: FC<RefinementListProps> = ({
  attribute,
  configValue,
  className,
  hasSearchBar = false,
  hasScrollBar = false,
  searchBarPlaceholder,
}) => {
  const { treezStores } = useData();
  const { activeFilters, refine } = useProductsContext();
  const [searchValue, setSearchValue] = useState('');
  const [moreCount, setMoreCount] = useState(1);

  const items = getFilterItems(treezStores, attribute, configValue);
  const showedItemsCount = moreCount * INITIAL_ITEMS_TO_SHOW;
  const hasReachedLimit = showedItemsCount > items?.length;

  // Split items into checked and unchecked arrays
  const checkedItems: string[] = [];
  const uncheckedItems: string[] = [];

  items?.forEach((value: string) => {
    if (activeFilters[attribute]?.[value]) {
      checkedItems.push(value);
    } else {
      uncheckedItems.push(value);
    }
  });

  // Filter unchecked items based on the search input
  const filteredUncheckedItems = uncheckedItems.filter(value =>
    value?.toLowerCase()?.includes(searchValue.toLowerCase()),
  );
  // Concatenate the checked array followed by the unchecked array
  const orderedItems = [
    ...checkedItems.sort(),
    ...filteredUncheckedItems.sort(),
  ];
  const hasMoreItems = orderedItems.length > INITIAL_ITEMS_TO_SHOW;

  const handleChange = (value: string) => {
    refine(attribute, value, !activeFilters[attribute]?.[value]);
  };

  const handleShowMore = () => {
    setMoreCount(prev => prev + 1);
  };

  const handleShowLess = () => {
    setMoreCount(1);
  };

  const itemsToShow = hasScrollBar
    ? orderedItems
    : orderedItems?.slice(0, showedItemsCount);

  if (orderedItems?.length === 0 && !searchValue) {
    return null;
  }

  return (
    <div className={classNames(className, styles.filters_list)}>
      {hasSearchBar && (
        <>
          <div className={styles.search_box}>
            <Input
              autoComplete="off"
              className={styles.search_box__input}
              inputLabel={searchBarPlaceholder}
              isSmall
              name={`${attribute}-search-input`}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={searchBarPlaceholder}
              type="search"
              value={searchValue}
            />
            <Button
              className={styles.search_box__button}
              color="primary"
              onClick={() => {}}
            >
              <IconSvg name="search" />
            </Button>
          </div>
          {orderedItems?.length === 0 && (
            <div className={styles.input_feedback}>
              <Icon name="search" />
              <Heading level={5}>No search results found</Heading>
              <p>No results match the criteria</p>
            </div>
          )}
        </>
      )}
      <div
        className={classNames({
          [styles.filters_list__with_scrollbar]: hasScrollBar,
        })}
      >
        {itemsToShow?.map((value: string) => (
          <RefinementListItem
            handleChange={() => handleChange(value)}
            isChecked={activeFilters[attribute]?.[value] || false}
            key={`key_${value}`}
            value={value}
          />
        ))}
      </div>
      {!hasScrollBar && hasMoreItems && (
        <button
          className={styles.show_more}
          onClick={hasReachedLimit ? handleShowLess : handleShowMore}
        >
          {hasReachedLimit ? '- Less' : '+ More'}
        </button>
      )}
    </div>
  );
};

export { RefinementList };
