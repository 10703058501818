import { FC, JSX, useEffect, useState } from 'react';
import { TEMPLATE_BASE_PATH } from '@/constants';
import { useEcomStoreSelector, useUiStore } from '@/data';
import { find, startCase } from 'lodash';
import { currencyFormat } from '@/utils';
import { useFeaturedFlag, useWishList } from '@/hooks';
import {
  Alert,
  //BundlePopup,
  Button,
  Counter,
  Icon,
  IconSvg,
  Link,
  Placeholder,
  Price,
  useData,
  WeightVariants,
} from '@/components';
import classNames from 'classnames';
import { GProduct, Variant } from '@/types';

interface IProductControlsProps {
  product: GProduct;
  weightVariant: Variant;
  isSpecial: boolean;
  isProductAvailable?: boolean;
}

const ProductControls: FC<IProductControlsProps> = ({
  product,
  weightVariant,
  isSpecial,
  isProductAvailable,
}) => {
  const [, setState] = useUiStore();
  const { customer_type } = useEcomStoreSelector(['customer_type']);

  const { treezStores } = useData();
  const { keepShoppingActive, wishlistActive, isMedAndRecSelectionActive } =
    useFeaturedFlag();

  const [isFavorite, setIsFavorite] = useState(false);
  const { favProducts, addFav, removeFav } = useWishList();
  const inStock = !product?.isOutStock && !product?.isLowStock;
  //const [bundleJaneSpecial, setBundleJaneSpecial] = useState<JaneSpecial>();
  //const [bundleProducts, setBundleProducts] = useState<GProduct[]>([]);
  //const [showBundlePopup, setShowBundlePopup] = useState(false);
  //const [bundleId, setBundleId] = useState<string>();
  const [currentWeightVariant, setCurrentWeightVariant] = useState<Variant>();

  useEffect(() => {
    setCurrentWeightVariant(weightVariant);
  }, [weightVariant]);

  //const bundlePanelRef = useRef<HTMLDivElement>(null);

  /* const handleBundleCallback = (
    bundleSpecial: JaneSpecial,
    bundleProducts: GProduct[],
  ) => {
    setBundleJaneSpecial(bundleSpecial);
    setBundleProducts(bundleProducts);
    setShowBundlePopup(true);
  }; */

  const showStoresSelect = event => {
    event.stopPropagation();
    setState({ is_store_location_open: true });
  };

  const handleFavorite = (product: GProduct) => {
    if (isFavorite) {
      removeFav(product.id);
      return;
    }

    addFav(product);
  };

  useEffect(() => {
    const p = find(favProducts, p => p.id == product.id);
    if (p !== undefined) {
      setIsFavorite(true);
      return;
    }
    setIsFavorite(false);
  }, [favProducts, product]);

  /*   const handleBundlePopup = (id: string) => {
    setBundleId(id);
    setShowBundlePopup(true);
  }; */

  const isCheckingAvailability = isProductAvailable === undefined;

  const warningMessagge = (isSingleStore: boolean): string => {
    let warning = 'Unavailable Product';

    if (isSingleStore && isMedAndRecSelectionActive) {
      return `This product is unavailable in your selected ${startCase(
        customer_type?.toLowerCase(),
      )} inventory type`;
    }

    warning = 'This product is unavailable in your selected store';

    if (isMedAndRecSelectionActive) {
      warning += `or ${startCase(customer_type?.toLowerCase())} inventory type`;
    }

    return warning;
  };

  const UnavailableMessage = (): JSX.Element => {
    if (isProductAvailable || isCheckingAvailability) {
      return <></>;
    }

    const isSingleStore = treezStores.length === 1;

    const warning = warningMessagge(isSingleStore);

    return (
      <div className={'details__availability'}>
        <Alert isOpen warning>
          <div className="details__availability_message">
            {warning}
            {!isSingleStore && (
              <div className="details__availability_message__actions">
                <Button
                  className="location_button"
                  color="link"
                  onClick={showStoresSelect}
                  size="sm"
                >
                  Change location
                </Button>
              </div>
            )}
          </div>
        </Alert>
      </div>
    );
  };

  const priceAndFavAction = (
    <div className={'detail__product_actions'}>
      <Price
        price={`$${currencyFormat(currentWeightVariant?.price!)}`}
        salePrice={`$${currencyFormat(currentWeightVariant?.salePrice!)}`}
        special={isSpecial}
      />

      {wishlistActive && (
        <Button
          className="favorite_icon"
          color="tertiary"
          onClick={() => {
            handleFavorite(product);
          }}
        >
          <Icon name={isFavorite ? 'favorite-solid' : 'favorite'} />
        </Button>
      )}
    </div>
  );

  const continueShopping = (
    <Link className={'details__item-link'} href={TEMPLATE_BASE_PATH.SHOP}>
      Continue Shopping
      <IconSvg name="arrow-right" />
    </Link>
  );

  return (
    <div className={'details__item-control'}>
      {isCheckingAvailability && (
        <div
          className={classNames('availability_placeholders', {
            hidden: !isCheckingAvailability,
          })}
        >
          <Placeholder />
          <Placeholder />
        </div>
      )}
      {!isCheckingAvailability && (
        <>
          <div
            className={classNames('details__item', {
              hidden: !isProductAvailable || isCheckingAvailability,
            })}
          >
            {inStock && (
              <Counter
                actionTheme={'primary'}
                //callbackIfBundle={handleBundleCallback}
                //handleBundlePopup={handleBundlePopup}
                onDetail
                product={product}
                renderContinueShopping={keepShoppingActive && continueShopping}
                renderPriceFavAction={priceAndFavAction}
                renderWeightVariant={
                  <WeightVariants
                    currentWeightVariant={currentWeightVariant!}
                    handleWeightVariatChange={setCurrentWeightVariant}
                    product={product}
                  />
                }
                selectedWeightVariant={currentWeightVariant}
                showIcon={true}
              />
            )}
            {/*  <BundlePopup
          bundleId={bundleId}
          bundleJaneSpecial={bundleJaneSpecial as JaneSpecial}
          bundleProducts={bundleProducts}
          onClose={() => {
            setShowBundlePopup(false);
            setBundleId(undefined);
          }}
          open={showBundlePopup}
          ref={bundlePanelRef}
        /> */}
          </div>
          <UnavailableMessage />
        </>
      )}
    </div>
  );
};

export { ProductControls };
