import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import { Button, Icon, IconSvg } from '@/components';

import style from './input.module.scss';

interface InputProps {
  autoComplete?: 'off' | 'new-password' | 'old-password' | '' | undefined;
  checked?: boolean;
  children?: ReactNode | string;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  feedback?: string;
  id?: string;
  inFooter?: boolean;
  required?: boolean;
  placeholder?: string;
  inputLabel?: string;
  isClearable?: boolean;
  isVisibilityControl?: boolean;
  isInvalid?: boolean;
  rows?: number;
  icon?: ReactNode;
  name?: string;
  isSmall?: boolean;
  isStandard?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClear?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  type?:
    | 'checkbox'
    | 'email'
    | 'password'
    | 'radio'
    | 'search'
    | 'textarea'
    | 'tel'
    | 'date'
    | 'textdate';
  value?: any;
  ref?: Ref<InputElement | null>;
}

type InputElement = HTMLInputElement | HTMLTextAreaElement;

const Input: FC<InputProps> = ({
  autoComplete = '',
  checked,
  children,
  rows,
  type,
  disabled,
  isInvalid,
  feedback,
  className,
  containerClassName,
  inFooter,
  id,
  icon,
  placeholder = '',
  inputLabel,
  isVisibilityControl,
  value,
  name,
  isClearable,
  required = false,
  isStandard = false,
  isSmall,
  onChange,
  onClear,
  onKeyDown,
  onFocus,
  onBlur,
  ref,
}) => {
  const innerRef = useRef<InputElement | undefined>(undefined);

  const [inputType, setInputType] = useState(type);

  const InputTag = inputType === 'textarea' ? 'textarea' : 'input';

  const isTypeCheck = ['radio', 'checkbox'].includes(type as string);

  const [inputValue, setInputValue] = useState('');

  const isShowLabel = (inputLabel || children) && (inputValue || value);

  useImperativeHandle(ref, () => innerRef.current as InputElement);

  const handleClear = () => {
    setInputValue('');
    onClear?.();
  };

  const handleShowPass = () => {
    if (inputType === 'password') {
      setInputType(undefined);
      return;
    }

    setInputType('password');
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onFocusEvent = () => {
    if (inputType === 'textdate') {
      setInputType('date');
      (innerRef.current as InputElement).placeholder = '';
    }
    onFocus && onFocus();
  };

  const handleBlur = () => {
    if ((!inputValue || !value) && inputType === 'date') {
      setInputType('textdate');
      (innerRef.current as InputElement).placeholder = 'Date of birth';
    }
    onBlur?.();
  };

  return (
    <div className={classNames(containerClassName, style.input_container)}>
      <label
        className={classNames(style.input, className, {
          [style.input__small]: isSmall,
          [style.input__standard]: isStandard,
          [style.input__textarea]: inputType === 'textarea',
          [style.input__disabled]: disabled,
          [style.input__invalid]: isInvalid,
          [style.input__footer]: inFooter,
          [style.input__footer_standard]: inFooter && isStandard,
          [style.input__type_text]: !isTypeCheck,
        })}
      >
        {icon && <span className={style.input__addortment}>{icon}</span>}
        {(inputLabel || children) &&
          (inputValue || value || inputType === 'checkbox') && (
            <span
              className={classNames(style.input__label, {
                [style.input__label_search]: inputType === 'search',
                [style.input__label_checkbox]: inputType === 'checkbox',
                [style.input__label_icon]: icon,
                [style.input__label_small]: isSmall,
              })}
            >
              {inputLabel || children}
            </span>
          )}
        <InputTag
          autoComplete={autoComplete}
          checked={checked}
          className={classNames(style.input__control, {
            [style.input__control_icon]: icon,
            [style.input__control_btn]: isClearable || isVisibilityControl,
            [style.input__control_btn_icon]:
              (isClearable || isVisibilityControl) && icon,
            [style.input__control_pressed]: isShowLabel,
            [style.input__control_icon_pressed]: isShowLabel && icon,
            [style.input__control_btn_pressed]:
              (isClearable || isVisibilityControl) && isShowLabel,
            [style.input__control_btn_icon_pressed]:
              (isClearable || isVisibilityControl) && isShowLabel && icon,
            [style.input__control_small]: isSmall,
            [style.input__control_icon_small]: icon && isSmall,
            [style.input__control_btn_small]:
              (isClearable || isVisibilityControl) && isSmall,
            [style.input__control_btn_icon_small]:
              (isClearable || isVisibilityControl) && icon && isSmall,
            [style.input__control_pressed_small]: isShowLabel && isSmall,
            [style.input__control_icon_pressed_small]:
              isShowLabel && icon && isSmall,
            [style.input__control_btn_pressed_small]:
              (isClearable || isVisibilityControl) && isShowLabel && isSmall,
            [style.input__control_btn_icon_pressed_small]:
              (isClearable || isVisibilityControl) &&
              isShowLabel &&
              icon &&
              isSmall,
          })}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={onFocusEvent}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          ref={innerRef as Ref<HTMLTextAreaElement> & Ref<HTMLInputElement>}
          required={required}
          rows={rows}
          type={inputType !== 'textarea' ? inputType : undefined}
          value={isClearable ? inputValue : value}
        />
        {isClearable && (
          <Button
            className={classNames(style.input__btn, {
              [style.input__btn_small]: isSmall,
            })}
            color="link"
            onClick={handleClear}
          >
            <IconSvg name="close" />
          </Button>
        )}
        {isVisibilityControl && (
          <Button
            className={classNames(style.input__btn, {
              [style.input__btn_small]: isSmall,
            })}
            onClick={handleShowPass}
          >
            <IconSvg
              name={inputType === 'password' ? 'showpass' : 'hidepass'}
            />
          </Button>
        )}
      </label>
      {isInvalid && feedback && (
        <p className={style.input__feedback}>
          <Icon name="info" /> {feedback}
        </p>
      )}
    </div>
  );
};

export default Input;
