import { FC, useEffect, useState } from 'react';
import {
  Article,
  ArticlePlaceholder,
  Button,
  Heading,
  Icon,
} from '@/components';
import * as prismic from '@prismicio/client';

import style from './articlelist.module.scss';
import { BlogPostDocument } from '@/types';
import { createClient } from 'prismicio';

const ArticleList: FC<ArticleListProps> = ({
  articlesList,
  hasPagination = true,
  numberPostPerPage,
  searchTerm,
}) => {
  const [page, setPage] = useState(1);
  const [articles, setArticle] = useState<BlogPostDocument[]>(articlesList);

  //check because the pagination is not working

  const [hasNextPage, setHasNextPage] = useState(hasPagination);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  const handleSearch = async searchTerm => {
    if (!searchTerm) {
      setArticle(articlesList);
      setIsLoading(false);
      return;
    }

    try {
      const prismicClient = createClient();

      const postsList = await prismicClient.getAllByType('blog_post', {
        predicates: [
          prismic.filter.fulltext('my.blog_post.header_text', searchTerm),
        ],
        pageSize: numberPostPerPage,
      });

      setArticle(postsList);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error searching posts:', error);
      setArticle([]);
    }

    setIsLoading(false);
  };

  const fetchNextPage = async p => {
    if (!hasNextPage) {
      return;
    }

    setIsLoading(true);

    try {
      const prismicClient = createClient();

      const newResults = await prismicClient.getByType('blog_post', {
        orderings: {
          direction: 'desc',
          field: 'my.blog_post.publish_date',
        },
        page: p,
        pageSize: numberPostPerPage,
      });

      setArticle([...(articles ?? []).concat(newResults.results)]);
      newResults.next_page ? setPage(page + 1) : setHasNextPage(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    setIsLoading(false);
  };

  if (!articles?.length && !isLoading) {
    return (
      <div className="blog__container_empty">
        <div className="blog__empty">
          <Icon name="search" />
          <Heading level={5}>No articles found.</Heading>
          <p>Thank you for checking our blog.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={style.articles__collection}>
        {isLoading &&
          Array.from({ length: numberPostPerPage }).map((_, index) => (
            <ArticlePlaceholder key={index} />
          ))}
        {!isLoading &&
          articles?.map(article => (
            <Article
              article={article}
              classCss={style.articles__collection_post_article}
              key={article.id}
            />
          ))}
      </div>
      {hasNextPage && articles?.length > 0 ? (
        <Button
          className={style['articles__load-more']}
          color="link"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => fetchNextPage(page + 1)}
        >
          Load more articles
        </Button>
      ) : null}
    </>
  );
};

interface ArticleListProps {
  articlesList: BlogPostDocument<string>[];
  hasPagination?: boolean;
  numberPostPerPage: number;
  searchTerm: string;
}

export { ArticleList };
