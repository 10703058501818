import { useEcomStoreSelector } from '@/data';
import { getDeliveryScheduleByAddress } from '@/utils';
import { useGapCommerceStore, useSelectedStore } from './useSelectedStore';
import { useIsServerSide } from './useIsServerSide';
import { ScheduleZone } from '@/types';

const defaultMinAmount = 5000;

interface ICurrentMinimumAmount {
  currentMinAmount: number;
  delivery_schedules: ScheduleZone[];
}

const useCurrentMinimumAmount = (): ICurrentMinimumAmount => {
  const { delivery_address } = useEcomStoreSelector(['delivery_address']);
  let { store } = useGapCommerceStore(); // gap commerce store
  const { store: treezStore } = useSelectedStore(); // treez store
  const { isServerSide } = useIsServerSide();

  if (isServerSide) {
    return { currentMinAmount: defaultMinAmount, delivery_schedules: [] };
  }

  try {
    store = {
      ...store,
      address1: treezStore?.address1,
      address2: treezStore?.address2,
      city: treezStore?.city,
      country: treezStore?.country,
      province_code: treezStore?.state,
      zip: treezStore?.zip,
      latitude: treezStore?._geoloc?.lat,
      longitude: treezStore?._geoloc?.lng,
    };

    const ds = getDeliveryScheduleByAddress(
      {
        latitude: delivery_address?.latitude || 0,
        longitude: delivery_address?.longitude || 0,
        zip: delivery_address?.zip || '',
      },
      store,
    );

    return {
      currentMinAmount: ds?.min ?? defaultMinAmount,
      delivery_schedules: [ds],
    };
  } catch (error) {
    return {
      currentMinAmount: defaultMinAmount,
      delivery_schedules: [],
    };
  }
};

export { useCurrentMinimumAmount };
