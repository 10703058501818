import { useEffect, useMemo, useRef, useState } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { sortBy } from 'lodash';
import { usePopper } from 'react-popper';
import ReactSlider from 'react-slider';

import { Button, Heading, Icon, Input } from '@/components';

import style from './filters.module.scss';

function Filter({
  children,
  title,
  name,
  icon,
  className,
  onClear,
  onApply,
  filterApplied,
  ...props
}) {
  const [button, setButton] = useState(null);
  const [panel, setPanel] = useState(null);
  const { styles, attributes } = usePopper(button, panel, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
    ],
    placement: 'bottom-start',
  });

  return (
    <Popover className={style.filters__item}>
      <PopoverButton
        as={Button}
        className={`${style['filters__item-button']} ${
          filterApplied.includes(name)
            ? style['filters__item-button_active']
            : ''
        }`}
        ref={setButton}
        sm
      >
        {icon}
        {title}
        <Icon name="arrow_drop" />
      </PopoverButton>
      <PopoverPanel
        className={style['filters__item-panel']}
        ref={setPanel}
        style={styles.popper}
        {...attributes.popper}
      >
        {({ close }) => (
          <>
            <Heading className={style['filters__item-panel-title']} level={4}>
              {title}
            </Heading>
            <div
              className={`${style['filters__item-panel-content']} ${
                className ?? ''
              }`}
              {...props}
            >
              {children}
            </div>
            <div className={style['filters__item-panel-footer']}>
              <Button
                color="link"
                onClick={() => {
                  onClear(name);
                  close();
                }}
                sm
              >
                Clear
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  onApply([
                    ...filterApplied.filter(filter => filter !== name),
                    name,
                  ]);
                  close();
                }}
                sm
              >
                View results
              </Button>
            </div>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
}

function FilterSlider({ label, value, onChange }) {
  return (
    <ReactSlider
      ariaLabel={label}
      ariaValuetext={state => `${state.valueNow}%`}
      className={style.filters__slider}
      markClassName={style['filters__slider-mark']}
      marks={[0, 20, 40, 60, 80, 100]}
      onChange={onChange}
      renderMark={props => (
        <p
          {...props}
          className={`${style['filters__slider-mark']} ${
            value <= props.key ? style['filters__slider-mark_active'] : ''
          }`}
        >
          <span>{props.key}</span>
        </p>
      )}
      step={20}
      thumbClassName={style['filters__slider-thumb']}
      trackClassName={style['filters__slider-track']}
      value={value}
    />
  );
}

function Filters({
  // categoriesPage,
  // categories,
  // trackingCategories,
  brands,
  // Supported excludes as array:
  // -------------------
  // 'strain', 'thc', 'cbd', 'prices', 'brands'.
  exclude,
  onChange,
  // Supported query params:
  // -----------------------
  // 'strain': An array or value with "Hybrid", "Indica", "Sativa" and "" for Others.
  // 'thc': A Int or Float for start value.
  // 'cbd': A Int or Float for start value.
  // 'prices': An array or value with "VERY_LOW", "LOW", "MEDIUM", "HIGHT".
  // 'brands': An array or value with Brands names and "" for Others.
  queryParams,
}) {
  // const [isAtTop, setIsAtTop] = useState(false);
  const ref = useRef(null);
  // const { scrollY } = useScroll();

  // General stuffs.
  const fitlersNames = {
    BRANDS: 'brands',
    CBD: 'cbd',
    MENU: 'menu',
    PRICES: 'prices',
    STRAIN: 'strain',
    THC: 'thc',
  };
  const params = queryParams || {};
  const [filterApplied, setFilterApplied] = useState(
    Object.keys(params).filter(param =>
      Object.values(fitlersNames).includes(param),
    ),
  );
  useEffect(() => {
    if (filterApplied.length > 0) {
      const query = getQueryFromFilters(filterApplied);

      onChange(query);
    }
  }, [queryParams]);

  // Categories filter stuffs.
  // const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  // useEffect(() => {
  //     Router.events.on("routeChangeStart", (url) =>
  //         setIsCategoriesOpen(false),
  //     );
  // }, []);

  // Strain type filter stuffs.
  const queryStrainTypes = useMemo(
    () =>
      params.strain
        ? (!Array.isArray(params.strain) ? [params.strain] : params.strain).map(
            strain =>
              `${strain.substr(0, 1).toUpperCase()}${strain
                .substr(1)
                .toLowerCase()}`,
          )
        : null,
    [params.strain],
  );
  const defaultStrainType = ['Hybrid', 'Indica', 'Sativa', ''];
  const [strainTypeFilter, setStrainTypeFilter] = useState(
    queryStrainTypes ?? defaultStrainType,
  );
  const clearStrainType = () => setStrainTypeFilter(defaultStrainType);
  const handleUnselectAllStrainTypesClick = () => setStrainTypeFilter([]);
  const handleStrainTypeChange = e => {
    const strainType =
      e.target.name === 'strain-type-others' ? '' : e.target.name;
    const newStrainTypes = strainTypeFilter.filter(
      newStrainType => newStrainType !== strainType,
    );

    if (!strainTypeFilter.includes(strainType)) {
      newStrainTypes.push(strainType);
    }

    setStrainTypeFilter(newStrainTypes);
  };

  // THC filter stuffs.
  const queryThc = params.thc
    ? parseFloat(decodeURIComponent(params.thc))
    : null;
  const defaultThc = 0;
  const [thcFilter, setThcFilter] = useState(queryThc || defaultThc);
  const clearThc = () => setThcFilter(defaultThc);

  // CBD filter stuffs.
  const queryCbd = params.cbd
    ? parseFloat(decodeURIComponent(params.cbd))
    : null;
  const defaultCbd = 0;
  const [cbdFilter, setCbdFilter] = useState(queryCbd || defaultCbd);
  const clearCbd = () => setCbdFilter(defaultCbd);

  // Price filter stuffs.
  // This ranges may be calculated dynamically but it is
  // better to write it manually because computational
  // concerns.
  const queryPrices = useMemo(
    () =>
      params.prices
        ? (!Array.isArray(params.prices) ? [params.prices] : params.prices).map(
            price => price.toUpperCase(),
          )
        : null,
    [params.prices],
  );
  const prices = {
    HIGHT: [6100, 999900, '> 60'],
    LOW: [2100, 4099, '21-40'],
    MEDIUM: [4100, 6099, '41-60'],
    VERY_LOW: [0, 2099, '< 21'],
  };
  const defaultPrices = ['VERY_LOW', 'LOW', 'MEDIUM', 'HIGHT'];
  const [priceFilter, setPriceFilter] = useState(queryPrices ?? defaultPrices);
  const clearPrice = () => setPriceFilter(defaultPrices);
  const handlePriceClick = price => {
    const newPrices = priceFilter.filter(newPrice => newPrice !== price);

    if (!priceFilter.includes(price)) {
      newPrices.push(price);
    }

    setPriceFilter(newPrices);
  };

  // Brands filter stuffs.
  const queryBrands = useMemo(
    () =>
      params.brands
        ? brands
            .filter(brand =>
              (!Array.isArray(params.brands) ? [params.brands] : params.brands)
                .map(brand => brand.toLowerCase())
                .includes(brand.name.toLowerCase()),
            )

            .map(brand => brand.id)
        : null,
    [params.brands, brands],
  );
  //const defaultBrands = [...brands.map((brand) => brand.id), ""];
  const defaultBrands = [];
  const [brandsFilter, setBrandsFilter] = useState(
    queryBrands ?? defaultBrands,
  );
  const clearBrands = () => setBrandsFilter(defaultBrands);
  const handleUnselectAllBrandsClick = () => setBrandsFilter([]);
  const [searchBrands, setSearchBrands] = useState('');
  const brandsFound = useMemo(
    () =>
      brands.filter(brand =>
        brand.name.toLowerCase().includes(searchBrands.toLowerCase()),
      ),
    [searchBrands, brands],
  );
  const handleBrandChange = e => {
    const brand = e.target.name === 'brand-others' ? '' : e.target.name;
    const newBrands = brandsFilter.filter(newBrand => newBrand !== brand);

    if (!brandsFilter.includes(brand)) {
      newBrands.push(brand);
    }

    setBrandsFilter(newBrands);
  };

  // Calculate the position of filters bar in
  // Y axis respect to the viewport.
  // useEffect(() => {
  //     if (ref.current) {
  //         const choords = ref.current.getBoundingClientRect();
  //         const media = window.matchMedia("(min-width: 768px)"); //md
  //         // This value depend of media size. In mobile, the header has
  //         // 123px of height. In desktop the header has 64px of height.
  //         const position = media.matches ? 76 : 138;

  //         ref.current.style.top = `${position}px`;
  //         setIsAtTop(choords.top <= position);
  //     }
  // }, [scrollY]);

  // // Close categories popover when categories
  // // section is visible.
  // useEffect(() => {
  //     if (!isAtTop && trackingCategories) {
  //         setIsCategoriesOpen(false);
  //     }
  // }, [isAtTop]);

  const getQueryFromFilters = filters => {
    const query = [];

    //Apply filter strain types
    if (filters.includes(fitlersNames.STRAIN)) {
      const strainQuery = ['false'];

      strainTypeFilter.forEach(strain => {
        strainQuery.push(
          strain === ''
            ? // eslint-disable-next-line max-len
              `(flower_type == '' || !contains('${defaultStrainType.join()}', flower_type))`
            : `flower_type == '${strain}'`,
        );
      });

      query.push(`(${strainQuery.join('||')})`);
    }

    //Apply filter thc
    if (filters.includes(fitlersNames.THC)) {
      query.push(`thc_percentage >= ${thcFilter * 100}`);
    }

    //Apply filter cbd
    if (filters.includes(fitlersNames.CBD)) {
      query.push(`cbd_percentage >= ${cbdFilter * 100}`);
    }

    //Apply filter price
    if (filters.includes(fitlersNames.PRICES)) {
      const priceQuery = ['false'];

      priceFilter.forEach(priceKey => {
        priceQuery.push(
          // eslint-disable-next-line max-len
          `(variants_0_price >= ${prices[priceKey][0]} && variants_0_price <= ${prices[priceKey][1]})`,
        );
      });

      query.push(`(${priceQuery.join('||')})`);
    }

    //Apply filter brands
    if (filters.includes(fitlersNames.BRANDS)) {
      const brandsQuery = [
        'false',
        ...brandsFilter.map(brand => `brand_id == '${brand}'`),
      ];

      query.push(`(${brandsQuery.join('||')})`);
    }

    return query;
  };

  const handleApply = filters => {
    const query = getQueryFromFilters(filters);

    setFilterApplied(filters);
    onChange(query);
  };

  const handleClear = (clearFunction, name) => {
    clearFunction();
    handleApply(filterApplied.filter(filter => filter !== name));
  };

  const handleClearAllClick = () => {
    // Set default values to all filter states.
    setStrainTypeFilter(defaultStrainType);
    setThcFilter(defaultThc);
    setCbdFilter(defaultCbd);
    setPriceFilter(defaultPrices);
    setBrandsFilter(defaultBrands);

    setFilterApplied([]);
    onChange([]);
  };

  return (
    <section
      className={style.filters}
      // className={`filters ${isAtTop ? "filters_top" : ""}`}
      ref={ref}
    >
      <div className={style.filters__container_main}>
        <Heading className={style.filters__title} level={4}>
          Filters by
        </Heading>
        <div className={style.filters__container}>
          {/* <div
                        className={`filters__item filters__item-categories ${
                            !isAtTop && trackingCategories
                                ? "filters__item_top"
                                : ""
                        }`}
                    >
                        <Button
                            sm
                            color="secondary"
                            className={`filters__item-button ${
                                trackingCategories
                                    ? "filters__item-categories-button"
                                    : ""
                            }`}
                            onClick={() =>
                                setIsCategoriesOpen(!isCategoriesOpen)
                            }
                            aria-expanded={isCategoriesOpen}
                        >
                            Categories
                            <Icon name="angle-down" />
                        </Button>
                        <PopoverPanel
                            className="filters__item-panel"
                            isOpen={isCategoriesOpen}
                            toggle={setIsCategoriesOpen}
                        >
                            <Heading level={4}>Categories</Heading>
                            <div className="filters__item-panel-content">
                                <CategoriesList
                                    categoriesPage={categoriesPage}
                                    categories={categories}
                                    isFilter={true}
                                />
                            </div>
                        </PopoverPanel>
                    </div> */}
          <Filter
            className={style.filters__list}
            filterApplied={filterApplied}
            icon={<Icon name="cannabis" />}
            name={fitlersNames.STRAIN}
            onApply={handleApply}
            onClear={name => handleClear(clearStrainType, name)}
            title="Type"
          >
            <Button color="link" onClick={clearStrainType} sm>
              <Icon name="check-all" /> Select all
            </Button>
            <Button color="link" onClick={handleUnselectAllStrainTypesClick} sm>
              <Icon name="uncheck-all" /> Unselect all
            </Button>
            <div className={style['filters__list-container']}>
              {defaultStrainType.map(type => (
                <Input
                  checked={strainTypeFilter.includes(type || '')}
                  className={style['filters__list-container-label']}
                  key={type || 'others'}
                  name={type || 'strain-type-others'}
                  onChange={handleStrainTypeChange}
                  type="checkbox"
                >
                  {type || 'Others'}
                </Input>
              ))}
            </div>
          </Filter>
          <Filter
            filterApplied={filterApplied}
            icon={<Icon name="inverted" />}
            name={fitlersNames.THC}
            onApply={handleApply}
            onClear={name => handleClear(clearThc, name)}
            title="THC"
          >
            Over {thcFilter}%
            <FilterSlider
              label="THC"
              onChange={setThcFilter}
              value={thcFilter}
            />
          </Filter>
          <Filter
            filterApplied={filterApplied}
            icon={<Icon name="drop" />}
            name={fitlersNames.CBD}
            onApply={handleApply}
            onClear={name => handleClear(clearCbd, name)}
            title="CBD"
          >
            Over {cbdFilter}%
            <FilterSlider
              label="CBD"
              onChange={setCbdFilter}
              value={cbdFilter}
            />
          </Filter>
          <Filter
            className={style.filters__price}
            filterApplied={filterApplied}
            name={fitlersNames.PRICES}
            onApply={handleApply}
            onClear={name => handleClear(clearPrice, name)}
            title="Price"
          >
            {Object.entries(prices).map((price, i) => (
              <Button
                aria-checked={priceFilter.includes(price[0])}
                className={style.filters__price_button}
                color="primary"
                key={price[0]}
                onClick={() => handlePriceClick(price[0])}
                role="checkbox"
                sm
              >
                {'$'.repeat(i + 1)}
                <small>{price[1][2]}</small>
              </Button>
            ))}
          </Filter>
          {(!exclude || !exclude.includes(fitlersNames.BRANDS)) && (
            <Filter
              className={style.filters__list}
              filterApplied={filterApplied}
              name={fitlersNames.BRANDS}
              onApply={handleApply}
              onClear={name => handleClear(clearBrands, name)}
              title="Brands"
            >
              {/*  <Button sm color="link" onClick={clearBrands}>
                            <Icon name="check-all" /> Select all
                        </Button> */}
              <Button color="link" onClick={handleUnselectAllBrandsClick} sm>
                <Icon name="uncheck-all" /> Unselect all
              </Button>
              <Input
                className={style['filters__list-search']}
                name="search-brands"
                onChange={e => setSearchBrands(e.target.value)}
                type="search"
                value={searchBrands}
              >
                Search brand
              </Input>
              <div className={style['filters__list-container']}>
                {sortBy(brandsFound, ['name']).map(brand => (
                  <Input
                    checked={brandsFilter.includes(brand.id)}
                    className={style['filters__list-container-label']}
                    key={brand.id}
                    name={brand.id}
                    onChange={handleBrandChange}
                    type="checkbox"
                  >
                    {brand.name}
                  </Input>
                ))}
                <Input
                  checked={brandsFilter.includes('')}
                  className={style['filters__list-container-label']}
                  name="brand-others"
                  onChange={handleBrandChange}
                  type="checkbox"
                >
                  Others
                </Input>
              </div>
            </Filter>
          )}
          {filterApplied.length > 0 && (
            <Button
              className={style['filters__item-button-clear']}
              //color="secondary"
              onClick={handleClearAllClick}
              sm
            >
              Clear
            </Button>
          )}
        </div>
      </div>
      <Heading
        className={`${style.filters__title} 
                    ${style['filters__container_sorted-by']}`}
        level={4}
      >
        Sort by
      </Heading>
      <div className={style['filters__container_all-product']}>
        <Filter
          className={style.filters__list}
          filterApplied={filterApplied}
          name={fitlersNames.STRAIN}
          onApply={handleApply}
          onClear={name => handleClear(clearStrainType, name)}
          title="All products"
        ></Filter>
      </div>
    </section>
  );
}

export default Filters;

export function useFilters(initialQuery) {
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [query, setQuery] = useState(initialQuery);

  const executeFilter = newQuery => {
    if (Array.isArray(newQuery) && newQuery.length > 0) {
      setIsFilterApplied(true);
      setQuery(`${initialQuery} && ${newQuery.join('&&')}`);
    } else {
      setIsFilterApplied(false);
      setQuery(initialQuery);
    }
  };

  return {
    executeFilter,
    isFilterApplied,
    query,
  };
}
