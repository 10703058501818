import {
  ChangeEventHandler,
  FC,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { InputMask, InputMaskProps } from '@react-input/mask';

import { Button, Icon, IconSvg } from '@/components';

import style from './input.module.scss';

export interface MaskInputProps extends InputMaskProps {
  autoComplete?: 'off' | 'new-password' | 'old-password' | '' | undefined;
  checked?: boolean;
  children?: ReactNode | string;
  className?: string;
  disabled?: boolean;
  feedback?: string;
  id?: string;
  inFooter?: boolean;
  required?: boolean;
  placeholder?: string;
  inputLabel?: string;
  isClearable?: boolean;
  isVisibilityControl?: boolean;
  isInvalid?: boolean;
  rows?: number;
  icon?: ReactNode;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClear?: () => void;
  type?:
    | 'checkbox'
    | 'email'
    | 'password'
    | 'radio'
    | 'search'
    | 'textarea'
    | 'tel'
    | 'date'
    | 'textdate';
  value?: any;
  mask?: string;
  ref?: RefObject<HTMLInputElement>;
}

const Input: FC<MaskInputProps> = ({
  autoComplete = '',
  checked,
  children,
  type,
  disabled,
  isInvalid,
  feedback,
  className,
  inFooter,
  id,
  icon,
  placeholder = '',
  inputLabel,
  isVisibilityControl,
  value,
  name,
  isClearable,
  required = false,
  onChange,
  onClear,
  mask = '',
  inputMode,
  ref,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);

  const isTypeCheck = ['radio', 'checkbox'].includes(
    type as
      | 'search'
      | 'textarea'
      | 'checkbox'
      | 'radio'
      | 'tel'
      | 'email'
      | 'date'
      | 'password'
      | 'textdate',
  );

  const [inputValue, setInputValue] = useState('');

  const isShowLabel = (inputLabel || children) && (inputValue || value);
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);

  const handleClear = () => {
    setInputValue('');
    onClear?.();
  };

  const handleShowPass = () => {
    if (inputType === 'password') {
      setInputType(undefined);
      return;
    }

    setInputType('password');
  };

  const handleFocus = () => {
    if (type === 'date') {
      setInputPlaceholder('mm/dd/yyyy');
    }
  };

  const handleBlur = () => {
    if (type === 'date') {
      setInputPlaceholder(placeholder);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={style.input_container}>
      <label
        className={classNames(style.input, className, {
          [style.input__textarea]: inputType === 'textarea',
          [style.input__disabled]: disabled,
          [style.input__invalid]: isInvalid,
          [style.input__footer]: inFooter,
          [style.input__type_text]: !isTypeCheck,
        })}
      >
        {icon && <span className={style.input__addortment}>{icon}</span>}
        {(inputLabel || children) &&
          (inputValue || value || inputType === 'checkbox') && (
            <span
              className={classNames(style.input__label, {
                // eslint-disable-next-line max-len
                [style.input__label_search]: inputType === 'search',
                // eslint-disable-next-line max-len
                [style.input__label_checkbox]: inputType === 'checkbox',
                [style.input__label_icon]: icon,
              })}
            >
              {inputLabel || children}
            </span>
          )}
        <InputMask
          autoComplete={autoComplete}
          checked={checked}
          className={classNames(style.input__control, {
            [style.input__control_icon]: icon,
            [style.input__control_btn]: isClearable || isVisibilityControl,
            [style.input__control_btn_icon]:
              (isClearable || isVisibilityControl) && icon,
            [style.input__control_pressed]: isShowLabel,
            [style.input__control_icon_pressed]: isShowLabel && icon,
            [style.input__control_btn_pressed]:
              (isClearable || isVisibilityControl) && isShowLabel,
            [style.input__control_btn_icon_pressed]:
              (isClearable || isVisibilityControl) && isShowLabel && icon,
          })}
          disabled={disabled}
          id={id}
          inputMode={type === 'date' ? 'numeric' : inputMode}
          mask={mask}
          name={name}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          placeholder={inputPlaceholder}
          ref={ref}
          required={required}
          type={type !== 'date' ? inputType : undefined}
          value={isClearable ? inputValue : value}
          {...props}
        />
        {isClearable && (
          <Button
            className={style.input__btn}
            color="link"
            onClick={handleClear}
          >
            <IconSvg name="close" />
          </Button>
        )}
        {isVisibilityControl && (
          <Button className={style.input__btn} onClick={handleShowPass}>
            <IconSvg
              name={inputType === 'password' ? 'showpass' : 'hidepass'}
            />
          </Button>
        )}
      </label>
      {isInvalid && feedback && (
        <p className={style.input__feedback}>
          <Icon name="info" /> {feedback}
        </p>
      )}
    </div>
  );
};

export default Input;
