import startCase from 'lodash/startCase';

class TreezError extends Error {
  constructor(
    message: string,
    {
      cause,
    }: { cause: { data: any; status: number; req: any; endpoint: string } },
  ) {
    super(message);
    this.name = this.constructor.name;
    this.cause = cause;
  }
}

export class TreezClient {
  private _apiUrl: string;
  private _token?: string;

  constructor(dispensaryName?: string, token?: string) {
    this._apiUrl = getTreezApiUrl(dispensaryName);
    this._token = token;
  }

  private request = async (
    endpoint: string,
    // eslint-disable-next-line no-undef
    { method, body, headers: customHeaders, ...customConfig }: RequestInit,
  ) => {
    const config: RequestInit = {
      body,
      headers: {
        client_id: process?.env?.NEXT_PUBLIC_TREEZ_CLIENT_ID as string,
        client_secret: process?.env?.NEXT_PUBLIC_TREEZ_CLIENT_SECRET as string,
        ...(body ? { 'Content-Type': 'application/json' } : {}),
        ...(this._token ? { Authorization: `Bearer ${this._token}` } : {}),
        'Cache-Control':
          'max-age=0, no-cache, must-revalidate, proxy-revalidate',
        ...customHeaders,
      },
      method,
      ...customConfig,
      next: { tags: ['treez'] },
      cache: 'force-cache',
    };

    const response = await fetch(`${this._apiUrl}/${endpoint}`, config).catch(
      (reason): Promise<Response> => Promise.reject(reason),
    );

    const data = await response.json().catch(() => {});

    if (response.ok) {
      return data;
    }

    throw new TreezError(
      startCase(
        (data.detail ?? data.description)
          ?.replace(/[_]/g, ' ')
          ?.toLocaleLowerCase(),
      ),
      {
        cause: { data, status: response.status, req: config, endpoint },
      },
    );
  };

  delete = async <T extends Object>(endpoint: string) => {
    const data = await this.request(endpoint, {
      method: 'DELETE',
    });

    return data as T;
  };

  get = async <T extends Object>(endpoint: string, signal?: AbortSignal) => {
    const data = await this.request(endpoint, {
      method: 'GET',
      signal,
    });

    return data as T;
  };

  patch = async <T extends Object>(endpoint: string, resource: Partial<T>) => {
    const data = await this.request(endpoint, {
      body: JSON.stringify(resource),
      method: 'PATCH',
    });

    return data as T;
  };

  post = async <T extends Object>(endpoint: string, resource?: T) => {
    const data = await this.request(endpoint, {
      ...(resource ? { body: JSON.stringify(resource) } : {}),
      method: 'POST',
    });
    return data;
  };

  put = async <T extends Object>(endpoint: string, resource?: T) => {
    const data = await this.request(endpoint, {
      ...(resource ? { body: JSON.stringify(resource) } : {}),
      method: 'PUT',
    });

    return data as T;
  };
}

const defaultStoreName = process.env.NEXT_PUBLIC_TREEZ_DEFAULT_STORE_NAME;

const getTreezApiUrl = (dispensaryName?: string) => {
  const dispensary = dispensaryName || defaultStoreName;

  const url =
    process?.env?.NEXT_PUBLIC_TREEZ_HEADLESS_API_URL ??
    'https://headless.treez.io'; // default to prod url

  return `${url}/v2.0/dispensary/${dispensary}/ecommerce`;
};
