import { FC, useEffect, useState } from 'react';
import { useUiStore } from '@/data';
import { Input } from '@/components';
import { IOptions } from './useStoreFilterList';
import classNames from 'classnames';
import styles from './filterstorelist.module.scss';

const FiltersStore: FC<IFiltersStore> = ({
  className,
  handleFilterSelection,
  options,
  type,
}) => {
  const [select, setSelect] = useState<string[]>([]);
  const [state] = useUiStore();
  let currentSelect: string[] = [];

  useEffect(() => {
    const mySelection = state.options_filter.filter(o => o.type === type)[0]
      .options;
    currentSelect = mySelection;
    setSelect(mySelection);
  }, []);

  const getIsChecked = (option: string) => {
    return select.includes(option);
  };

  const handleChange = (checked: boolean, value: any) => {
    if (checked) {
      if (!select.includes(value)) {
        const supportArray = Array.from(select);
        supportArray.push(value);
        setSelect(supportArray);
        currentSelect = supportArray;
      }
    }

    if (!checked) {
      if (select.includes(value)) {
        const supportArray = Array.from(select);
        const arrayFilter = supportArray.filter(o => o !== value);
        setSelect(arrayFilter);
        currentSelect = arrayFilter;
      }
    }

    handleFilterSelection(type, currentSelect);
  };

  return (
    <div className={classNames(styles.filters, className)}>
      {options.map((opt, index) => {
        const isChecked = getIsChecked(opt.value);

        return (
          <Input
            checked={isChecked}
            id={opt.value}
            key={index}
            name={opt.value}
            onChange={e =>
              handleChange((e.target as HTMLInputElement).checked, opt.value)
            }
            type="checkbox"
          >
            <span>{opt.value}</span>
            <span className={styles.count}>{opt.count}</span>
          </Input>
        );
      })}
    </div>
  );
};

interface IFiltersStore {
  className?: string;
  // eslint-disable-next-line no-unused-vars
  handleFilterSelection: (id: string, options: any) => void;
  options: IOptions[];
  type: 'deals' | 'want' | 'type';
}

export default FiltersStore;
