'use client';

import { FC, ReactNode, useEffect, useState } from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { IPrismicImageType } from '@/types';

import { Close } from '../../atoms/close';

interface IModalProps {
  isAgeGate?: boolean;
  children: ReactNode;
  isOpen: boolean;
  sm?: boolean;
  bgImage?: IPrismicImageType;
  popupBgColor?: string;
  overlayBgColor?: string;
  overlayOpacity?: number;
  md?: boolean;
  lg?: boolean;
  className?: string;
  toggle?: () => void;
}

const Modal: FC<IModalProps> = ({
  children,
  isOpen = false,
  toggle,
  sm,
  md,
  lg,
  className,
  bgImage,
  popupBgColor,
  overlayBgColor,
  overlayOpacity,
  ...props
}) => {
  const [localDocument, setLocalDocument] = useState<Document | null>(null);
  const overlay = {
    background: overlayBgColor ?? 'black',
    opacity: overlayOpacity ?? 0.2,
  };

  useEffect(() => {
    setLocalDocument(document);
    const { clientWidth, style } = document.body;
    const overflow = window.innerWidth - clientWidth;

    if (isOpen && !overflow) {
      return;
    }

    style.overflowY = isOpen ? 'hidden' : 'unset';
    style.paddingRight = isOpen ? `${Math.abs(overflow)}px` : '0';
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  if (!localDocument) return null;

  return createPortal(
    <div
      className={classNames(
        'modal',
        { modal_md: md, modal_sm: sm, modal_lg: lg },
        className,
      )}
      role="dialog"
      {...props}
    >
      {bgImage && (
        <PrismicNextImage
          className="image__overlay"
          fallbackAlt=""
          field={bgImage}
          fill
          style={{ objectFit: 'cover', objectPosition: 'center center' }}
        />
      )}
      <div
        className="modal__overlay"
        onClick={toggle}
        role="button"
        style={overlay}
        title="modalOverlay"
      />

      <div
        className={classNames('modal__content', {
          modal__content_closable: toggle,
        })}
        role="document"
        style={{
          background: popupBgColor,
        }}
      >
        {toggle && (
          <Close className={'close'} data-dismiss="dialog" onClick={toggle} />
        )}
        <div className="modal__body">{children}</div>
      </div>
    </div>,
    localDocument?.body,
  );
};

export default Modal;
