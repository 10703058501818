import React from 'react';
import Script from 'next/script';

import { toJson } from '@/utils';
import { JsonLdProps } from '@/types';

function JsonLd({
  type = 'Thing',
  keyOverride,
  scriptKey,
  scriptId = undefined,
  ...rest
}: JsonLdProps & { scriptKey: string }) {
  return (
    <Script
      dangerouslySetInnerHTML={toJson(type, { ...rest })}
      id={scriptId}
      key={`jsonld-${scriptKey}${keyOverride ? `-${keyOverride}` : ''}`}
      type="application/ld+json"
    />
  );
}

export { JsonLd };
