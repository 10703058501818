import { FC } from 'react';
import { CategoriesCarousel, CategoriesGrid } from './Categories.components';
import { CategoriesSlice } from '@/types';

const Categories: FC<CategoriesSlice> = slice => {
  if (!slice?.primary?.active) {
    return null;
  }

  if (slice?.variation === 'grid') {
    return <CategoriesGrid {...slice} />;
  }
  return <CategoriesCarousel {...slice} />;
};

export default Categories;
