export const TEMPLATE_BASE_PATH = {
  BLOG: '/blog/',
  BLOG_TAG: '/blog/tag/',
  BLOGS: '/blog',
  BRAND: '/brand/',
  BRANDS: '/brands',
  CATEGORY: '/collection/',
  CITY: '/weed-delivery/',
  DEALS: '/deals',
  LOCATION: '/dispensary/',
  ORDER: '/orders/',
  PRODUCT: '/product/',
  SHOP: '/shop',
  SPECIALS: '/special/',
  PROMOTIONS: '/promotions/',
  STORE_LOCATOR: '/store-locator',
  STRAIN: '/strain/',
  TAGS: '/tags/',
  WISHLIST: '/wishlist',
};

export const PAGE_SLUG = {
  BLOG: 'blog',
  BRANDS: 'brands',
  CATALOG: 'catalog',
  MENU: 'menu',
  PRODUCT: 'product',
};

export const PAGE_TEMPLATE = {
  ABOUT: 'ABOUT',
  ARTICLE: 'ARTICLE',
  BLOG: 'BLOG',
  BRAND: 'BRAND',
  BRANDS_LIST: 'BRANDS_LIST',
  CATALOG: 'CATALOG',
  CITY: 'CITY',
  CONTACT: 'CONTACT',
  HOME: 'HOME',
  LOCATION: 'LOCATION',
  MENU: 'MENU',
  PRODUCT: 'PRODUCT',
  SPECIALS: 'SPECIALS',
  TEXT: 'TEXT',
};

export const PAGE_ROUTES = {
  ACCOUNT: '/account',
  CHECKOUT: '/checkout',
  FORGOT: '/forgot',
  PRODUCT: '/product',
  RESET: '/reset',
  SHOP: '/shop',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  VERIFY_EMAIL: '/verify-email',
  STORE_LOCATOR: '/store-locator',
};

export const AUTH_ROUTES_LIST = [
  PAGE_ROUTES.SIGNIN,
  PAGE_ROUTES.SIGNUP,
  PAGE_ROUTES.RESET,
  PAGE_ROUTES.FORGOT,
  PAGE_ROUTES.VERIFY_EMAIL,
];
