import { FC, MouseEvent, ReactNode, useEffect, useState } from 'react';

import { ERROR_ADD_PENNY_PRODUCT_MESSAGE } from '@/constants';
import classNames from 'classnames';
import { cloneDeep, find, findIndex } from 'lodash';

import { useAnalytics, useEcomStoreSelector, useUiStore } from '@/data';
import { Button, Icon, IconSvg, Select, useData } from '@/components';
import { useBundleSpecial, useNotify, useProducts } from '@/hooks';
import { CartLineItem, GProduct, JaneSpecial, Variant } from '@/types';
import { useSpecialsContext } from '@/context';
import {
  getBundledProducts,
  getProductBundleSpecial,
  getProductItemLocId,
} from '@/utils';

import CounterTestIds from './CounterTestIds';
import {
  // calculateTotalGrams,
  canAddPennyToCart,
  formatWeightLabel,
  sortVariantByPrice,
} from './Utils';
import { ISelectOption } from '../Select/Select';

import style from './counter.module.scss';
import { CounterActions } from './CounterActions';

/**
 * The attr listed below are attr added to the ecom products
 * to be able to implement the cart funcionality
 * - item_id
 * - quantity
 * - weightVariant
 */

interface ICounterProps {
  product: CartLineItem | GProduct;
  onCard?: boolean;
  onDetail?: boolean;
  onShopping?: boolean;
  onKiosk?: boolean;
  showIcon?: boolean;
  omitCheckAvilibility?: boolean;
  actionTheme?: 'primary' | 'secondary' | 'tertiary' | 'link';
  selectedWeightVariant?: Variant;
  /* callbackIfBundle?: (
    _bundleSpecial: JaneSpecial,
    _bundleProducts: GProduct[],
  ) => void; */
  //handleBundlePopup?: (_id: string) => void;
  onKioskAddClicked?: () => void;
  renderWeightVariant?: ReactNode;
  renderPriceFavAction?: ReactNode;
  renderContinueShopping?: ReactNode;
  className?: string;
  classNameCounter?: string;
}

const Counter: FC<ICounterProps> = ({
  product,
  onCard,
  onDetail,
  onShopping,
  onKiosk,
  showIcon,
  omitCheckAvilibility,
  selectedWeightVariant,
  actionTheme = 'link',
  // callbackIfBundle,
  // handleBundlePopup,
  onKioskAddClicked,
  renderWeightVariant,
  renderPriceFavAction,
  renderContinueShopping,
  className,
  classNameCounter,
}) => {
  const [, setState] = useUiStore();
  const {
    addAndRemoveProductAttributes,
    measureSurfsideAction,
    measureAddToCartGA4,
  } = useAnalytics();
  const { productCard } = useData();
  const { products, update, add } = useProducts();
  const { notify } = useNotify();
  const [theProductIsAlreadyInTheCart, setTheProductIsAlreadyInTheCart] =
    useState(false);
  const [variantsSelectOptions, setVariantsSelectOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [index, setIndex] = useState(-1);
  const [quantity, setQuantity] = useState(0);
  const [weightVariant, setWeightVariant] = useState<ISelectOption<string>>();
  const { treez_store_name } = useEcomStoreSelector(['treez_store_name']);
  const [isSelectedStore, setIsSelectedStore] = useState(false);
  const [, setUiState] = useUiStore();
  const { specials } = useSpecialsContext();

  const bogoDiscounts = product?.discounts?.find(
    discount => discount.discount_method === 'BOGO',
  );

  // eslint-disable-next-line max-len
  // const GRAM_LIMIT_EXCEEDED_ERROR = `It is not allowed to buy more than ${gramsLimit} grams`;

  const bundleId =
    (product as CartLineItem)?.bundle_id ?? bogoDiscounts?.discount_id;

  const {
    bundleProducts: specialProducts,
    dependentProducts,
    independentProducts,
    special,
  } = useBundleSpecial({
    id: bundleId ?? '',
  });

  useEffect(() => {
    setIsSelectedStore(!!treez_store_name);
  }, [treez_store_name]);

  useEffect(() => {
    const index = findIndex(
      products,
      (p: CartLineItem) =>
        p?._id === (product as CartLineItem)?._id ||
        (p.id == product.id &&
          p?.weightVariant?.id === selectedWeightVariant?.id),
    );

    const p = products?.[index];

    setTheProductIsAlreadyInTheCart(p !== undefined);

    setIndex(index);
    setQuantity(p?.quantity ?? 0);

    if (!p) {
      const defaultVariant = sortVariantByPrice(
        product?.variants as Variant[],
      )?.[0];

      setWeightVariant({
        label: formatWeightLabel(selectedWeightVariant ?? defaultVariant),
        value: selectedWeightVariant?.id ?? defaultVariant?.id ?? '',
      });
    }

    if (p !== undefined) {
      setWeightVariant({
        label: formatWeightLabel(p?.weightVariant),
        value: p?.weightVariant?.id ?? '',
      });
    }

    const productsById = products.filter(prod => prod?.id === product?.id);
    const selectWeightVariants = productsById?.map(
      prod => prod?.weightVariant?.id,
    );
    setVariantsSelectOptions(
      sortVariantByPrice(product?.variants as Variant[])
        ?.filter(variants => !selectWeightVariants.includes(variants?.id))
        .map(v => {
          return { label: formatWeightLabel(v), value: v?.id ?? '' };
        }),
    );
  }, [products, product, selectedWeightVariant]);

  const addItemToTheCart = async (
    event: MouseEvent<Element, globalThis.MouseEvent>,
  ) => {
    event.stopPropagation();

    if (!isSelectedStore) {
      setUiState({ is_store_location_open: true });
      return;
    }

    const bundleSpecial = getProductBundleSpecial(
      specials,
      product,
    ) as JaneSpecial;

    const bundleProducts = await getBundledProducts(
      bundleSpecial,
      product?.id ?? '',
    );

    const selectVariant = product?.variants?.find(
      variant => variant?.id === weightVariant?.value,
    );

    const currentWeightVariant =
      selectVariant ?? sortVariantByPrice(product?.variants as Variant[])[0];

    const itemLocId = getProductItemLocId(
      treez_store_name,
      currentWeightVariant,
    );

    // save the product on the
    // persisted store
    let item: CartLineItem = {
      ...product,
      _id: `${product.id}-${Math.random()}`,
      bundleProducts,
      bundleSpecial,
      itemLocId,
      quantity: 1,
      weightVariant: currentWeightVariant,
    };

    // if product does not have a bundle_id then we need to set the
    // bundle info on the product
    if (!(product as CartLineItem)?.bundle_id && bundleId) {
      item = {
        ...item,
        bundle_id: bundleId,
        bundle_title: special?.title,
        bundle_slug: special?.specialSlug,
      };
    }

    if (
      (special?.totalProductsRequired ?? 0) > specialProducts?.length &&
      bundleId
    ) {
      notify(
        'success',
        <p>
          {independentProducts?.length + 1 ===
          special?.independentProductsRequired ? (
            <>
              DEAL {bundleSpecial?.title} UNLOCKED! <br />
              {`Added ${product?.name}`}
            </>
          ) : dependentProducts?.length + independentProducts?.length <
            special?.totalProductsRequired! ? (
            `Added ${product?.name} to Deal ${special?.title}`
          ) : null}
        </p>,
      );
    }

    // This validation is to prevent customers from adding unlimited
    // penny items after the bundle is complete
    if (
      !canAddPennyToCart({
        bundleComplete: special?.bundleComplete,
        price: item.weightVariant?.salePrice!,
      })
    ) {
      notify('error', ERROR_ADD_PENNY_PRODUCT_MESSAGE);
      return;
    }

    add(item);

    measureAddToCartGA4(item, 1);
    measureSurfsideAction([item], 'add');

    // if (bundleSpecial) {
    //   callbackIfBundle && callbackIfBundle(bundleSpecial, bundleProducts);
    //   return;
    // }

    ((product?.bundle_id && special?.bundleComplete) || !product?.bundle_id) &&
      setState({ is_cart_open: true });
  };

  const updateWeightVariant = (v: ISelectOption<string>) => {
    const newWeightVariant = find(
      product.variants,
      p => p?.id == v.value,
    ) as Variant;

    // save the product on the
    // persisted store
    const newProducts = cloneDeep(products);
    newProducts[index].weightVariant = newWeightVariant;
    newProducts[index].itemLocId = getProductItemLocId(
      treez_store_name,
      newWeightVariant,
    );

    // Disable it for now
    // if (calculateTotalGrams(newProducts) > gramsLimit) {
    //   notify('error', GRAM_LIMIT_EXCEEDED_ERROR);
    //   return;
    // }

    setWeightVariant({
      label: formatWeightLabel(newWeightVariant),
      value: newWeightVariant?.id ?? '',
    });
    update(newProducts);
  };

  const addToCart = (
    <Button
      className={classNames(
        style['counter__btn-responsibe'],
        { [style['counter__btn-update-cart']]: onDetail },
        { [style['counter__btn-check']]: !isSelectedStore },
      )}
      color={`${actionTheme}`}
      data-testid={CounterTestIds.ADD_TO_CART_BUTTON}
      onClick={e => {
        addItemToTheCart(e);
        onKioskAddClicked?.();
      }}
      {...(addAndRemoveProductAttributes(
        'addToCart',
        product as CartLineItem,
      ) as any)}
    >
      {isSelectedStore || omitCheckAvilibility ? (
        <>
          {showIcon && (
            <figure className={style['counter__btn-icon']}>
              <IconSvg name="shopping" />
            </figure>
          )}
          <span className={style.counter__text}>Add To Cart</span>
        </>
      ) : (
        <span className={style.counter__text}>
          {productCard?.check_avilibility || 'Choose your Store'}
        </span>
      )}
    </Button>
  );

  const getUIElements = () => {
    if (onKiosk && onCard) {
      return (
        <Button
          className={classNames({
            [style.on_cart]: theProductIsAlreadyInTheCart,
          })}
          color={theProductIsAlreadyInTheCart ? 'secondary' : 'primary'}
          data-testid={CounterTestIds.ADD_TO_CART_BUTTON}
          onClick={e => {
            if (theProductIsAlreadyInTheCart) {
              setState({ is_cart_open: true });
              return;
            }
            addItemToTheCart(e);
          }}
          {...(addAndRemoveProductAttributes(
            'addToCart',
            product as CartLineItem,
          ) as any)}
        >
          <Icon name={theProductIsAlreadyInTheCart ? 'shopping' : 'plus'} />
        </Button>
      );
    }

    // This case is for when it is a complete
    // new product
    if (!theProductIsAlreadyInTheCart && !onDetail) {
      return addToCart;
    }

    // This case is for when it is a complete
    // new product in the Detail page
    if (onDetail) {
      return (
        <div className={style?.counter__product_detail}>
          {renderWeightVariant}
          <div className={style.counter__product_detail_actions}>
            {renderPriceFavAction}
            {!theProductIsAlreadyInTheCart && addToCart}
            {theProductIsAlreadyInTheCart && (
              <div
                className={classNames(style.counter__container, {
                  [style.counter__container_shopping]: onShopping,
                  [style.counter__container_detail]: onDetail,
                })}
              >
                <CounterActions
                  index={index}
                  onKiosk={onKiosk}
                  product={product as CartLineItem}
                  quantity={quantity}
                  weightVariant={weightVariant?.value}
                />
              </div>
            )}
          </div>
          {renderContinueShopping}
        </div>
      );
    }

    // This case is for when the product is already on the
    // shipping cart but the counter component is beeing
    // display on the Product Card component
    if (theProductIsAlreadyInTheCart && onCard) {
      return (
        <CounterActions
          className={classNameCounter}
          index={index}
          onKiosk={onKiosk}
          product={product as CartLineItem}
          quantity={quantity}
          weightVariant={weightVariant?.value}
        />
      );
    }

    // The default value is for product datail and
    // shipping cart
    return (
      <form
        className={classNames(style.counter__container, {
          [style.counter__container_shopping]: onShopping,
          [style.counter__container_detail]: onDetail,
        })}
      >
        <CounterActions
          index={index}
          onKiosk={onKiosk}
          product={product as CartLineItem}
          quantity={quantity}
          weightVariant={weightVariant?.value}
        />
        <div>
          <Select
            className={classNames(style['counter__variant-select'], {
              [style.counter__variant_select_shopping]: onShopping,
              [style.counter__variant_select_detail]: onDetail,
            })}
            defaultValue={weightVariant}
            menuClassName={style['variant-select__menu']}
            name="weight_variant"
            onChange={updateWeightVariant}
            options={variantsSelectOptions}
            value={weightVariant}
          />
        </div>
      </form>
    );
  };

  // this is to hide the add to cart button when
  // the product is out of stock
  if (!onDetail) {
    if (product?.isLowStock) {
      return (
        <span className={classNames(style.counter__warning, className)}>
          In Store Only
        </span>
      );
    }
    if (product?.isOutStock) {
      return (
        <span className={classNames(style.counter__warning, className)}>
          Out of Stock
        </span>
      );
    }
  }

  return (
    <div className={classNames(style.counter, className)}>
      {getUIElements()}
    </div>
  );
};

export default Counter;
