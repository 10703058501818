import { FC } from 'react';

import { PrismicNextImage } from '@prismicio/next';

import { Rating, Slider } from '@/components';
import { getSlicePadding, htmlSerializer } from '@/utils';
import { PrismicRichText } from '@prismicio/react';
import { ReviewsSlice } from '@/types';

import style from './sliderreviews.module.scss';
import classNames from 'classnames';

const SliderReviews: FC<ReviewsSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const background = slice?.primary?.background_color ?? '';
  const borderTop = slice?.primary?.border_section ?? '';
  const borderBottom = slice?.primary?.border_section ?? '';
  const paddingSection = slice?.primary?.padding_section;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
      style={{ borderTop, borderBottom, background }}
    >
      <Slider
        freeMode
        sliderHeading={
          <div className={style['location__reviews-title']}>
            <PrismicRichText
              components={htmlSerializer}
              field={slice.primary.header}
            />
          </div>
        }
      >
        {slice.items.map((review, id) => (
          <div className={style['reviews-card']} key={id}>
            <div className={style.reviews_title}>
              <PrismicRichText
                components={htmlSerializer}
                field={review.review_title}
              />
            </div>
            <Rating
              className={style.card_stars}
              value={review.rating as number}
            />
            <blockquote className={style.card_desc}>
              <PrismicRichText
                components={htmlSerializer}
                field={review.review}
              />
            </blockquote>
            <div className={style.person_info}>
              <div className={style.person_img}>
                <PrismicNextImage
                  fallbackAlt=""
                  field={review.image}
                  fill
                  style={{ objectFit: 'contain' }}
                />
              </div>
              <div className={style.person_desc}>
                <div className={style.person_name}>
                  <PrismicRichText
                    components={htmlSerializer}
                    field={review.name}
                  />
                </div>
                <div className={style.person_position}>
                  <PrismicRichText
                    components={htmlSerializer}
                    field={review.position}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default SliderReviews;
