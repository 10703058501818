import { GET_BERBIX_TRANSACTION, VALIDATE_DIVE_TRANSACTION } from './schema';

export async function getBerbixTransactionResult(client, options = {}) {
  const response = await client.query({
    query: GET_BERBIX_TRANSACTION,
    ...options,
  });

  return {
    data: response.data,
    error: response.error,
  };
}

export async function validateDIVETransaction(client, options = {}) {
  try {
    const response = await client.mutate({
      mutation: VALIDATE_DIVE_TRANSACTION,
      ...options,
    });

    return {
      data: response.data?.idScanVerification,
      error: response.error,
    };
  } catch (err) {
    return {
      data: { error: '', success: false },
      error: err,
    };
  }
}
