import { gql } from '@apollo/client';

export const GET_DISTANCE_QUERY = gql`
  query getDistance($from: Coordinates!, $to: Coordinates!) {
    getDistance(from: $from, to: $to) {
      dimension
      value
    }
  }
`;
