'use client';

import { memo, ReactElement } from 'react';
import { HeaderSlice } from '@/types';

import { HeaderContainer } from './Header.components';
import {
  HeaderDefault,
  HeaderWithCenteredLogoAndLocator,
  HeaderWithCenteredLogoAndLocatorFix,
  HeaderWithCenteredLogoAndNav,
  HeaderWithRightStoreLocator,
  HeaderWithTopLocatorAndBottomNav,
} from './variations';
import { useActiveHeaderSlice } from '@/hooks';

const Header = memo(() => {
  const activeSlice = useActiveHeaderSlice();

  return (
    <>
      <HeaderContainer>
        {renderHeaderVariation(activeSlice?.variation ?? 'default')}
      </HeaderContainer>
    </>
  );
});

type HeaderVariation = HeaderSlice['variation'];

const ComponentsByVariations: Record<HeaderVariation, ReactElement<any>> = {
  default: <HeaderDefault />,
  withCenteredLogoAndNav: <HeaderWithCenteredLogoAndNav />,
  withCenteredLogoAndLocator: <HeaderWithCenteredLogoAndLocator />,
  withCenteredLogoAndLocatorFix: <HeaderWithCenteredLogoAndLocatorFix />,
  withTopLocatorAndBottomNav: <HeaderWithTopLocatorAndBottomNav />,
  withRightStoreLocator: <HeaderWithRightStoreLocator />,
};

const renderHeaderVariation = (variation: HeaderVariation) =>
  ComponentsByVariations[variation] ?? <HeaderDefault />;

export { Header, renderHeaderVariation };
