import { createContext, ReactNode, use } from 'react';

import { IStoreMetadata } from '@/data';

import {
  AgeGateDocument,
  CrmModalDocument,
  FooterDocument,
  HeaderDocument,
  LocationBannerAlertDocument,
  NavigationDocument,
  ProductDocumentData,
  PromoCarouselDocumentData,
  ShoppingCartDocumentData,
  SideNavigationDocument,
  StorelocatorPageDocumentData,
} from 'prismicio-types';
import { MinZipByCode, Store } from '@/types';

const DataContext = createContext<IStoreMetadata>({
  ageGate: undefined as unknown as AgeGateDocument,
  apps: [],
  crm: undefined,
  crmModal: undefined as unknown as CrmModalDocument,
  footer: undefined as unknown as FooterDocument,
  header: undefined as unknown as HeaderDocument,
  metafield: undefined,
  minZipByCode: undefined as unknown as MinZipByCode,
  navigation: undefined as unknown as NavigationDocument,
  promoCarousel: undefined as unknown as PromoCarouselDocumentData,
  promotion: undefined as unknown as LocationBannerAlertDocument,
  store: undefined as unknown as Store,
  sideNavigation: undefined as unknown as SideNavigationDocument,
  treezStores: [],
  productCard: undefined as unknown as ProductDocumentData,
  shoppingCart: undefined as unknown as ShoppingCartDocumentData,
  storeLocator: undefined as unknown as StorelocatorPageDocumentData,
});

/**
 * Provide access to props data to all child components and
 * will also provide helper functions to manipulate the
 * data ex. sort, find as needed.
 *
 * Graphql API schema documentation
 * https://bkk3r4yhk4.execute-api.us-west-1.amazonaws.com/prod/graphiql
 *
 * Check the /data directory for example data
 *
 */

export default function DataProvider({
  children,
  data,
}: {
  children: ReactNode;
  data?: IStoreMetadata;
}) {
  return (
    <DataContext value={data as unknown as IStoreMetadata}>
      {children}
    </DataContext>
  );
}

export function useData() {
  return use(DataContext);
}
