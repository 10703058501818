import { FC, memo, startTransition, useRef } from 'react';
import { ERROR_ADD_PENNY_PRODUCT_MESSAGE } from '@/constants';

import classNames from 'classnames';
import { cloneDeep } from 'lodash';

import { useAnalytics, useEcomStoreSelector, useUiStoreSelector } from '@/data';
import { CartLineItem } from '@/types';
import { useBundleSpecial, useNotify, useProducts } from '@/hooks';

import { IconSvg } from '../IconSvg';
import { Button } from '../Button';

import style from './counter.module.scss';
import { canAddPennyToCart } from './Utils';

interface CounterActionsProps {
  product: CartLineItem;
  onKiosk?: boolean;
  index: number;
  quantity: number;
  weightVariant?: string;
  className?: string;
}

const CounterActions: FC<CounterActionsProps> = ({
  product,
  onKiosk,
  index,
  quantity,
  weightVariant,
  className,
}) => {
  const {
    addAndRemoveProductAttributes,
    measureAddToCartGA4,
    measureRemoveFromCartGA4,
    measureSurfsideAction,
  } = useAnalytics();
  const { notify } = useNotify();
  const { line_items } = useEcomStoreSelector(['line_items']);
  const { setState: setUiState } = useUiStoreSelector([]);
  const { products, update } = useProducts();
  const changeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const currentProduct = line_items?.[index];

  const bundleId = currentProduct?.bundle_id;

  const { dependentProducts, independentProducts, special } = useBundleSpecial({
    id: bundleId ?? '',
  });

  const updateQuantity = (qty: number) => {
    measureAddToCartGA4(product, qty);

    const newProducts = cloneDeep(products);
    newProducts[index].quantity = qty;

    // Disable it for now
    // if (calculateTotalGrams(newProducts) > gramsLimit) {
    //   notify('error', GRAM_LIMIT_EXCEEDED_ERROR);
    //   return;
    // }

    update(newProducts);
  };

  const change = (operation: 'minus' | 'plus') => {
    const _quantity = operation === 'minus' ? quantity - 1 : quantity + 1;

    if (
      operation === 'plus' &&
      !canAddPennyToCart({
        bundleComplete: special?.bundleComplete,
        price: currentProduct?.weightVariant?.salePrice!,
      })
    ) {
      notify('error', ERROR_ADD_PENNY_PRODUCT_MESSAGE);
      return;
    }

    if (operation === 'plus') {
      measureSurfsideAction([{ ...product, quantity: 1 }], 'add');
    }

    if (operation === 'minus') {
      measureSurfsideAction([{ ...product, quantity: 1 }], 'remove');
    }

    if (_quantity === 0) {
      measureRemoveFromCartGA4(product as CartLineItem);

      const newLineItems = line_items?.filter(p => {
        const _id = product?._id;
        return _id
          ? p?._id !== _id
          : weightVariant
          ? p?.id !== product?.id && p?.weightVariant?.id !== weightVariant
          : p?.id !== product?.id;
      });

      // update cart line items
      update(newLineItems);

      // close cart if no items in cart
      if (newLineItems?.length === 0) {
        setUiState({ is_cart_open: false });
      }

      return;
    }

    clearTimeout(changeTimeoutRef.current as any);

    // this is to notify user when they add a product
    // to a bundle
    if (
      bundleId &&
      operation === 'plus' &&
      dependentProducts?.length + independentProducts?.length <
        special?.totalProductsRequired!
    ) {
      notify(
        'success',
        <p>
          {independentProducts?.length + 1 ===
          special?.independentProductsRequired ? (
            <>
              DEAL {special?.title} UNLOCKED! <br />
              {`Added ${product?.name}`}
            </>
          ) : (
            `Added ${product?.name} to Deal ${special?.title}`
          )}
        </p>,
      );
    }

    if (onKiosk) {
      changeTimeoutRef.current = setTimeout(() => {
        startTransition(() => {
          updateQuantity(_quantity);
        });
      }, 300);

      return;
    }

    startTransition(() => {
      updateQuantity(_quantity);
    });
  };

  return (
    <div className={classNames(style.counter__value, className)}>
      <Button
        aria-label="Decrement quantity"
        className={style.counter__operation}
        color="link"
        icon
        onClick={() => change('minus')}
        {...(addAndRemoveProductAttributes(
          'removeFromCart',
          product as CartLineItem,
        ) as any)}
      >
        <IconSvg name={quantity === 1 ? 'delete' : 'minus'} />
      </Button>
      <div className={style.counter__count}>{quantity}</div>
      <Button
        aria-label="Increment quantity"
        className={classNames(
          style.counter__operation,
          style.counter__operation_rigth,
        )}
        color="link"
        icon
        onClick={() => change('plus')}
        {...(addAndRemoveProductAttributes(
          'addToCart',
          product as CartLineItem,
        ) as any)}
      >
        <IconSvg name="plus" />
      </Button>
    </div>
  );
};

const memoizedCounterActions = memo(CounterActions);

export { memoizedCounterActions as CounterActions };
