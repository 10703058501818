/* eslint-disable max-len */
import { useMemo } from 'react';
import {
  EnumCustomerType as CustomerType,
  GProduct,
  LastKey,
  SearchSuggestions,
  SortBySelectOption,
  TreezFilters,
  TreezGroupProduct,
  TreezProduct,
  TreezSpecials,
} from '@/types';
import { currencyFormat, generateQueryParams, MapProducts } from '@/utils';
import { useEcomStoreSelector } from '@/data';
import { TreezClient, useTreezClient } from '../api';
import { omit } from 'lodash';
export class MenuService {
  private _client: TreezClient;
  private _name = 'menu';
  private _customerType: CustomerType;
  private _inventoryIds: string[];

  constructor(
    client?: TreezClient,
    customerType?: CustomerType,
    inventoryIds?: string[],
  ) {
    this._client = client ?? new TreezClient();
    this._customerType = customerType ?? CustomerType.NOT_SET;
    this._inventoryIds = inventoryIds ?? [];
  }

  getProductsByType = async (
    type: string,
    pageNumber: number,
    pageSize: number,
    /*
     *dispensaryName: string, It is necessary to include a logic to evaluate
     *depending on the selected store
     */
  ): Promise<ProductsByTypeResponse> => {
    const { menuSize, totalPages, productGrouplist } =
      await this._client.get<TreezProductsByTypeResponse>(
        `${this._name}/type/${type}/page/${pageNumber}/pagesize/${pageSize}/list?customerType=${this._customerType}&strictMedicalCustomerType=false`,
      );

    return {
      menuSize,
      products: MapProducts(productGrouplist),
      totalPages,
    };
  };

  getSpecials = async (): Promise<TreezSpecials[]> => {
    const result = await this._client.get<GetSpecialsResponse>(
      'discounts?excludeInactive=true&hideUnset=true&includeProdInfo=true',
    );

    return result?.data;
  };

  /**
   * Endpoint to get product groups
   * @param {string} id The product group ID
   */

  searchProductsByGroupId = async ({
    id,
  }: {
    id: string;
  }): Promise<TreezGroupProduct[]> => {
    const result = await this._client.get<SearchProductsByGroupIdResponse>(
      `product/groups/${id}`,
    );

    return result?.data?.filter(p => !p?.disabled);
  };

  /**
   * Endpoint search and filter products
   *
   * @param {string} searchTerm The search query
   * @param {number} pageSize The number of products to retrieve by page.
   * @param {string} customerType The customer type
   * @param {string} lastKey Last product key for pagination
   * @param {any} filters The list of active filters
   */
  searchProducts = async ({
    searchTerm = '',
    pageSize = 25,
    lastKey,
    filters = {},
    sortBy,
    seoProductName,
    displayOnSale = false,
    showOutOfStock = process.env
      .NEXT_PUBLIC_FEATURE_FLAG_DISPLAY_OUT_OF_STOCK_PRODUCTS === 'true',
    includePromoItems = false,
  }: SearchProductsRequest): Promise<{
    products: GProduct[];
    lastKey: LastKey;
  }> => {
    const onSale = filters?.deals ? !!filters.deals : displayOnSale;

    const customerType =
      this._customerType === CustomerType.NOT_SET && onSale
        ? CustomerType.ALL
        : this._customerType;

    // Generate query parameters
    const queryParams = generateQueryParams({
      customerType,
      pageSize,
      searchTerm,
      displayOnSale: onSale,
      showOutOfStock,
    });

    // Construct request payload
    const requestPayload: any = {
      strictMedicalCustomerType: false,
    };

    // Add optional properties to request payload
    if (lastKey) {
      requestPayload.afterKey = lastKey;
    }

    const resultFilters = omit(filters, ['deals', 'group_id']);

    const applyPriceFilter = f => {
      const pennyValue =
        process.env.NEXT_PUBLIC_DISCOUNT_MINIMUM_THRESHOLD_VALUE;

      const priceFilter = f?.price?.value ?? 500000; // maximun price cap

      // if not include promo items is true and the penny value is set then we filter out the product
      // where the price is more than penny value
      if (!!pennyValue && !includePromoItems) {
        f.price = {
          between: {
            min: currencyFormat(Number(pennyValue)),
            max: priceFilter,
            includeMin: true,
            includeMax: true,
          },
        };
      }

      return f;
    };

    applyPriceFilter(resultFilters);

    const renameProperty = (filters, key, newKey) => {
      if (filters?.[key]) {
        filters[newKey] = filters[key];
        delete filters[key];
      }
    };

    renameProperty(resultFilters, 'effect', 'effects');
    renameProperty(resultFilters, 'flavor', 'flavors');

    requestPayload.filters = {
      ...(Object.values(resultFilters).length > 0 ? resultFilters : {}),
      ...(this._inventoryIds.length > 0
        ? { locationId: { values: this._inventoryIds } }
        : {}),
    };

    if (seoProductName) {
      requestPayload.seoProductName = seoProductName;
    }

    if (sortBy && sortBy.value !== 'default') {
      requestPayload.sortBy = {
        field: sortBy.value.field,
        descending: sortBy.value.order === 'desc',
      };
    }

    // Make API call
    const { data: responseData, lastKey: pointer } = await this._client.post(
      `${this._name}/searchProducts?${queryParams}`,
      requestPayload,
    );

    // Map products
    const products = MapProducts(responseData ?? []);

    return { lastKey: pointer, products };
  };

  /**
   * Endpoint to search products by search term
   *
   * @param {string} searchTerm The search query
   * @param {number} pageSize The number of products to retrieve by page.
   */
  searchProductsSuggestions = (
    searchTerm: string,
    pageSize: number,
  ): Promise<SearchSuggestions> => {
    const queryParams = generateQueryParams({
      customerType: this._customerType,
      pageSize,
      searchTerm,
    });

    return this._client.post(`${this._name}/searchSuggestions?${queryParams}`, {
      strictMedicalCustomerType: this._customerType === CustomerType.MEDICAL,
    });
  };

  /**
   * Endpoint get product by name
   *
   * @param {string} name The search query
   * @param {string} customerType The customer type
   */
  getProductBySlug = async ({
    slug = '',
    showOutOfStock = false,
  }: {
    slug?: string;
    showOutOfStock?: boolean;
  }): Promise<{
    product: GProduct | undefined;
  }> => {
    const queryParams = generateQueryParams({
      customerType: this._customerType,
      // this is set to more the one becasue the search by term will retunr all product
      // that the slug contains provided slug
      pageSize: 5,
      searchTerm: encodeURIComponent(slug),
      showOutOfStock,
    });

    const { data } = await this._client.post(
      `${this._name}/searchProducts?${queryParams}`,
      {
        strictMedicalCustomerType: this._customerType === CustomerType.MEDICAL,
      },
    );

    // we have to the the find the comment discrbied above
    // with the page size set to 5
    return {
      product: MapProducts(data ?? []).find(product => product.slug === slug),
    };
  };

  checkIfProductsBelongsToStore = async (_products, _storeId) => {
    return {
      hasOutsideProducts: true, // TODO implement this feature using Treez API
    };
  };

  getInsideStoreProducts = async (_products, _storeId) => {
    return []; // TODO implement using the Treez API
  };

  getOutsideProducts = async (products, _storeId) => {
    return products; // TODO implement using the Treez API
  };
}

export const useMenuService = () => {
  const client = useTreezClient();
  const { customer_type, provider_inventory_location_id } =
    useEcomStoreSelector(['customer_type', 'provider_inventory_location_id']);

  const menuService = useMemo(
    () =>
      new MenuService(
        client,
        customer_type,
        provider_inventory_location_id?.split(','),
      ),
    [client, customer_type, provider_inventory_location_id],
  );

  return menuService;
};

interface TreezProductsByTypeResponse {
  productGrouplist: TreezProduct[];
  totalPages: number;
  menuSize: number;
}

interface ProductsByTypeResponse {
  products: GProduct[];
  totalPages: number;
  menuSize: number;
}

interface GetSpecialsResponse {
  data: TreezSpecials[];
}

interface SearchProductsByGroupIdResponse {
  data: TreezGroupProduct[];
}

export interface SearchProductsRequest {
  searchTerm?: string;
  pageSize?: number;
  lastKey?: LastKey;
  sortBy?: SortBySelectOption;
  filters?: TreezFilters;
  seoProductName?: string | string[];
  displayOnSale?: boolean;
  showOutOfStock?: boolean;
  includePromoItems?: boolean;
}
