import {
  createContext,
  FC,
  ReactNode,
  RefObject,
  use,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CustomerReview } from '@/types';

interface IReviewsFilterProviderProps {
  reviews: CustomerReview[];
  children: ReactNode;
}

const ReviewsFilterProvider: FC<IReviewsFilterProviderProps> = ({
  reviews,
  children,
}) => {
  const [feelingsFilter, setFeelingsFilter] = useState<string[]>([]);
  const [activitiesFilter, setActivitiesFilter] = useState<string[]>([]);
  const filteredReviews = useMemo(() => {
    const containsFeelings = (review: CustomerReview) =>
      review.feelings.some(feeling => feelingsFilter.includes(feeling));

    const containsActivities = (review: CustomerReview) =>
      review.activities.some(activity => activitiesFilter.includes(activity));

    const filtered = reviews?.filter(review => review.has_comment);

    if (!feelingsFilter.length && !activitiesFilter.length) {
      return filtered;
    }

    return filtered?.filter(
      review => containsFeelings(review) || containsActivities(review),
    );
  }, [reviews, feelingsFilter, activitiesFilter]);

  const reviewsRef = useRef<HTMLDivElement>(null);

  const handleFeelingsFilterChange = (feeling: string) => {
    if (feelingsFilter.includes(feeling)) {
      setFeelingsFilter(feelings => feelings.filter(feel => feel !== feeling));
      return;
    }

    setFeelingsFilter(feelings => [...feelings, feeling]);

    reviewsRef?.current?.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const handleActivitiesFilterChange = (activity: string) => {
    if (activitiesFilter.includes(activity)) {
      setActivitiesFilter(activities =>
        activities.filter(act => act !== activity),
      );
      return;
    }

    setActivitiesFilter(activities => [...activities, activity]);

    reviewsRef?.current?.scrollTo({ behavior: 'smooth', top: 0 });
  };

  return (
    <ReviewsContext
      value={{
        activitiesFilter,
        feelingsFilter,
        filteredReviews,
        handleActivitiesFilterChange,
        handleFeelingsFilterChange,
        reviewsRef: reviewsRef as RefObject<HTMLDivElement>,
      }}
    >
      {children}
    </ReviewsContext>
  );
};

interface ReviewsContextState {
  filteredReviews: CustomerReview[];
  feelingsFilter: string[];
  activitiesFilter: string[];
  reviewsRef: RefObject<HTMLDivElement>;
  handleFeelingsFilterChange: (_feeling: string) => void;
  handleActivitiesFilterChange: (_activity: string) => void;
}

const ReviewsContext = createContext<ReviewsContextState | undefined>(
  undefined,
);

const useReviewsFilters = () => use(ReviewsContext);

export { ReviewsFilterProvider, useReviewsFilters };
