import { FC, ReactNode, Ref, useState } from 'react';

import { Drawer } from 'vaul';

import styles from './portal.module.scss';
import classNames from 'classnames';
import { IconSvg } from '../IconSvg';
import { Icon } from '../atoms/icon';

interface IPortalProps {
  children: ReactNode;
  direction?: 'right' | 'left' | 'top' | 'bottom';
  isOpen: boolean;
  toggle?: () => void;
  ignoreOutsideClick?: boolean;
  showIconTouch?: boolean;
  showIconMore?: boolean;
  classOverlay?: string;
  classContent?: string | undefined;
  noBodyStyles?: boolean;
  snapPoints?: Array<number | string>;
  activeSnapPoint?: number | string;
  disablePreventScroll?: boolean;
  nested?: boolean;
  ref?: Ref<HTMLDivElement | null>;
}

const Portal: FC<IPortalProps> = ({
  children,
  isOpen,
  toggle,
  direction,
  classOverlay,
  classContent,
  ignoreOutsideClick = false,
  showIconTouch = true,
  showIconMore = false,
  nested = false,
  snapPoints = ['100%', '75%', '50%', '25%'],
  activeSnapPoint,
  ref,
  ...props
}) => {
  const [snap, setSnap] = useState<number | string | null>(
    activeSnapPoint ?? snapPoints[0],
  );

  const convertToPx = (value: string | number | null) => {
    const referenceValue = window.innerHeight;
    if (typeof value === 'number') {
      return value * referenceValue;
    }
    if (typeof value === 'string' && value.endsWith('px')) {
      return parseFloat(value);
    }
    if (typeof value === 'string' && value.endsWith('%')) {
      const percentageValue = parseFloat(value.replace('%', ''));
      return (percentageValue / 100) * referenceValue;
    }
    return Infinity;
  };

  const getMinSnap = () => {
    return Math.min(
      ...snapPoints.map(snap => {
        return convertToPx(snap);
      }),
    );
  };

  return (
    <>
      <Drawer.Root
        activeSnapPoint={snap}
        direction={direction}
        modal={!ignoreOutsideClick}
        nested={nested}
        onClose={toggle}
        open={isOpen}
        setActiveSnapPoint={newSnap => {
          setSnap(newSnap);
        }}
        shouldScaleBackground={false}
        snapPoints={snapPoints}
        {...props}
      >
        <Drawer.Portal>
          <Drawer.Overlay
            className={classNames(styles.drawer__overlay, classOverlay)}
            onClick={!ignoreOutsideClick ? toggle : () => {}}
          />
          <Drawer.Content
            className={classNames(styles.drawer__content, classContent)}
            ref={ref}
          >
            <div className={classNames(styles.drawer__content_body)}>
              {showIconTouch && (
                <span className={styles.drawer__content_icon} />
              )}
              {showIconMore && (
                <div className={styles.drawer__content_thouch}>
                  <div className={styles.drawer__content_more_info}>
                    <IconSvg name="shape-container" />
                    <span
                      className={classNames(
                        styles.drawer__content_more_info_arrow,
                        {
                          [styles.rotated]: convertToPx(snap) !== getMinSnap(),
                        },
                      )}
                    >
                      <Icon name="angle-up" />
                    </span>
                  </div>
                </div>
              )}
              {children}
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </>
  );
};

export default Portal;
