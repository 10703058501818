import { FC, Ref, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useParams, usePathname } from 'next/navigation';
import { Params } from 'next/dist/server/request/params';

import {
  useBundleSpecial,
  useFeaturedFlag,
  useSpacingBetweenProductCard,
} from '@/hooks';
import {
  Button,
  Container,
  Heading,
  Icon,
  Placeholder,
  Product,
  ProductPlaceholder,
  SpecialBanner,
  SpecialCounter,
  useProductsContext,
} from '@/components';
import { useAnalytics } from '@/data';

import styles from './CollectionTreez.module.scss';
interface ICollectionTreez {
  bundleId?: string;
  onSpecials?: boolean;
}

const CollectionTreez: FC<ICollectionTreez> = ({
  bundleId,
  onSpecials = false,
}) => {
  const { measureViewItemsListGA4 } = useAnalytics();
  const { isProductCardLargImage } = useFeaturedFlag();
  const { spacingClass } = useSpacingBetweenProductCard();
  const pathname = usePathname();
  const params = useParams();
  const {
    products,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useProductsContext();

  const isLoadingProducts = bundleId ? isFetching : isLoading;
  const {
    initDepProducts,
    initIndProducts,
    processingDependents,
    processingIndependents,
    special,
  } = useBundleSpecial({ id: bundleId! });

  const sectionRef = useRef<HTMLDivElement | undefined>(undefined);
  // const pageRef = useRef(results.page);

  // useEffect(() => {
  //     if (sectionRef) {
  //         if (results.page !== pageRef.current) {
  //             animateScroll.scrollTo(sectionRef.current.offsetTop - 200);
  //             pageRef.current = results.page;
  //         }
  //     }
  // }, [sectionRef, results.page]);

  useEffect(() => {
    const analyticsListName = getListName(pathname as string, params as Params);
    measureViewItemsListGA4(products, analyticsListName);
  }, [products]);

  const getListName = (pathname: string, params: Params) => {
    const listTitle = pathname.split('/')[1];
    const listSubtitle = params?.slug;
    return `${listTitle}${listSubtitle ? `_${listSubtitle}` : ''}`;
  };

  const renderV = () => {
    if (isLoadingProducts) {
      return (
        <section className={styles.placeholder__container}>
          {onSpecials && isLoading && (
            <Placeholder className={styles.placeholder__banner} />
          )}
          {onSpecials && !isLoading && special && (
            <div className={styles.special_description}>
              <SpecialBanner bundleId={bundleId!} />
              <SpecialCounter bundleId={bundleId!} />
            </div>
          )}
          <div className={classNames(styles.placeholder__product_container)}>
            <div
              className={classNames(
                styles.placeholder__product_list,
                spacingClass,
                {
                  [styles.placeholder__product_list_card_large]:
                    isProductCardLargImage,
                },
              )}
            >
              {new Array(10).fill(null).map((_, index) => (
                <ProductPlaceholder
                  key={index}
                  largeCard={isProductCardLargImage}
                />
              ))}
            </div>
            <div className={styles.placeholder__pagination}>
              <Placeholder className={styles.placeholder__actions} />
              <ul className={styles.placeholder__container_pages}>
                {new Array(5).fill(null).map((_, index) => (
                  <Placeholder
                    className={styles.placeholder__page}
                    key={index}
                  />
                ))}
              </ul>
              <Placeholder className={styles.placeholder__actions} />
            </div>
          </div>
        </section>
      );
    }

    if (
      (!isLoadingProducts && !products.length) ||
      (processingDependents && initDepProducts?.length === 0) ||
      (processingIndependents && initIndProducts?.length === 0)
    ) {
      return (
        <>
          <div className={styles.hits__empty}>
            <Icon name="search" />
            <Heading level={5}>No search results found</Heading>
            <p>
              No results match the filter criteria. Remove a filter or clear all
              filters to search again
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        {onSpecials && special && (
          <div className={styles.special_description}>
            <SpecialBanner bundleId={bundleId!} />
            <SpecialCounter bundleId={bundleId!} />
          </div>
        )}

        <div
          className={classNames(styles.collection_hits__list, spacingClass, {
            [styles.collection_hits__list_card_large]: isProductCardLargImage,
          })}
        >
          {products.map(item => (
            <Product
              className={styles.collection_hits__list_item}
              key={item.id}
              product={item}
            />
          ))}
        </div>
        {!bundleId && hasNextPage && (
          <Button
            className={styles.collection_load_more}
            loading={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            Load More
          </Button>
        )}
      </>
    );
  };

  return (
    <Container
      as="section"
      className={classNames(styles.collection_container, {
        [styles.container__no_side_spaces]: spacingClass,
      })}
    >
      <div
        className={classNames(styles.collection, {
          [styles['collection_hits--onSpecials']]: onSpecials,
        })}
        ref={sectionRef as Ref<HTMLDivElement> | undefined}
      >
        {renderV()}
      </div>
    </Container>
  );
};

export { CollectionTreez };
