const UPLOAD_SUCCESS_MESSAGE = 'Your identity photo was updated successfully.';

const TYPE = {
  DEFAULT: 'default',
  ERROR: 'error',
  SUCCESS: 'success',
  WARN: 'warn',
};

export { UPLOAD_SUCCESS_MESSAGE, TYPE };
