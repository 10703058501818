import { useEffect } from 'react';

import { useEcomStore } from '@/data';
import { useUpdateQueryParams } from '@/hooks';
import { useData } from '@/components';
import { CollectionQueryParams } from '@/types';

const useDeepLinkStore = ({ storeName }: { storeName?: string }) => {
  const [, setStoredState] = useEcomStore();
  const { updateQueryParams } = useUpdateQueryParams();
  const { treezStores } = useData();

  useEffect(() => {
    if (!storeName) {
      return;
    }

    const validStore = treezStores?.some(
      store => store?.shortName === storeName,
    );

    if (!validStore) {
      return;
    }

    setStoredState({
      delivery_details: {
        delivery: false,
        pickup: true,
      },
      treez_store_name: storeName,
    });

    updateQueryParams(undefined as unknown as CollectionQueryParams);
  }, [storeName]);
};

export { useDeepLinkStore };
