import { FC, useEffect, useState } from 'react';
import { IconSvg } from '@/components';
import { useUiStore } from '@/data';
import { useProducts, useSummary } from '@/hooks';
import style from './grlimit.module.scss';

const GrLimit: FC = () => {
  const { products } = useProducts();
  const { gramsLimit, totalGrams } = useSummary();
  const [localGrams, setLocalGrams] = useState(0);
  const [productsLength, setProductsLength] = useState(0);
  const [, setState] = useUiStore();

  useEffect(() => {
    setLocalGrams(totalGrams);
  }, [totalGrams]);

  useEffect(() => {
    setProductsLength(products.length);
  }, [products]);

  return (
    <div className={style['content-limit']}>
      <p className={style.content}>
        <span className={style.current}>{`${localGrams} gr`}</span>
        <span className={style.description}>{`/ ${gramsLimit} gr limit`}</span>
      </p>
      <button
        className={style['content-icon']}
        onClick={() => {
          setState({ is_cart_open: true });
        }}
      >
        <IconSvg name="shopping" />
        <span className={style.counter}>{productsLength}</span>
      </button>
    </div>
  );
};

export default GrLimit;
