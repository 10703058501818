import {
  FooterSectionsSlice,
  FooterSectionsSliceSubscribeBottom,
} from 'prismicio-types';
import { FC, useState } from 'react';
import {
  FooterAddressSection,
  FooterCopyright,
  FooterInfoLegalSection,
  FooterLink,
  FooterLogo,
  FooterSocialSection,
  FooterSubscribeSection,
  IFooterSection,
} from './Footer.components';
import { getCrmListIds } from '@/utils';
import { useData } from '@/components';
import { useSubscribeToCRM } from '@/data';

import style from './footer.module.scss';

export const SubscribeBottomVariation: FC<{
  slice: FooterSectionsSliceSubscribeBottom & FooterSectionsSlice;
  sections: IFooterSection[];
}> = ({ slice, sections }) => {
  const { crm } = useData();
  const isShowSubscribe = crm?.length > 0;

  const {
    logo,
    header_color,
    address_location,
    address_email,
    address_section_title,
    address_phone,
    header_color_hover,
    label_color,
    label_color_hover,
  } = slice.primary;

  const [error, setError] = useState({
    isError: false,
    isOpen: false,
    message: '',
  });

  // Subscription logic
  const { subscribeToCRM } = useSubscribeToCRM({
    onCompleted: () => {
      setError({
        isError: false,
        isOpen: true,
        message: 'Thank you for subscribing!',
      });
    },
    onError: () => {
      setError({
        isError: true,
        isOpen: true,
        message: 'An error occurred. Please, try again later.',
      });
    },
  });

  const onSubscribe = email => {
    // return only the list of ids becasue the subscribe
    // endpoint only accepted the list of ids
    const crmListIds = getCrmListIds(crm);

    subscribeToCRM({
      variables: {
        input: { email, lists: crmListIds, source: 'FOOTER' },
      },
    });
  };

  return (
    <>
      <div className={style.subscribe_bottom_variation__footer__body}>
        <FooterLogo logo={logo} variation={slice.variation} />
        <div
          className={
            style.subscribe_bottom_variation__footer__container_sections
          }
        >
          <FooterInfoLegalSection slice={slice} />
          <div className={style.footer__sections}>
            {sections.map((section, index) => (
              <div
                className={style.footer__section}
                key={`${section.header?.label}-${index}`}
              >
                <FooterLink
                  header_color={header_color}
                  item={section.header}
                  label_color={label_color}
                />
                {section.links &&
                  section.links.map((nav, linkIndex) => {
                    return (
                      <FooterLink
                        header_color={header_color}
                        item={nav}
                        key={linkIndex}
                        label_color={label_color}
                      />
                    );
                  })}
              </div>
            ))}
            <style>{`
                .footer_section_link:hover {
                  color: ${label_color_hover} !important;
                }
                .footer_section_title:hover {
                  color: ${header_color_hover} !important;
                }
              `}</style>
            <div
              className={style.subscribe_bottom_variation__address_container}
            >
              <FooterAddressSection
                address_email={address_email}
                address_location={address_location}
                address_phone={address_phone}
                address_section_title={address_section_title}
                header_color={header_color}
                header_color_hover={header_color_hover}
                label_color={label_color}
                label_color_hover={label_color_hover}
              />
              <FooterSocialSection slice={slice} />
            </div>
          </div>

          {isShowSubscribe && (
            <FooterSubscribeSection
              error={error}
              onSubscribe={onSubscribe}
              setError={setError}
              slice={slice}
            />
          )}
        </div>
      </div>

      <FooterCopyright slice={slice} />
    </>
  );
};
