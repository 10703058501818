import {
  Container,
  Heading,
  Link,
  Maps,
  Socials,
  //Socials
} from '@/components';

import { FC } from 'react';
import { ILocationInfo } from '@/types';
import classNames from 'classnames';

import style from './locationinfo.module.scss';
import {
  formatEnUSPhoneNumber,
  htmlSerializerWithProps,
  transformMilitaryTime,
} from '@/utils';
import { PrismicRichText } from '@prismicio/react';
import { asText, ColorField } from '@prismicio/client';

const LocationInfo: FC<ILocationInfoProps> = ({ info, styleProps }) => {
  const socials: any = info.socials;
  const networks = socials?.reduce((networks, network) => {
    if (network.social_link.link_type === 'Any') {
      return null;
    }
    return { ...networks, [network.social_id]: network.social_link.url };
  }, {});

  const minWidhtSocials = Object.keys(networks || {}).length * 52.5;

  return (
    <section className={style.location__info}>
      <Container className={style.location__info_grid_container}>
        {/*  <div
          className={classNames(style.location__info_title, {
            [style.location__info_title_no_description]: !info.description,
          })}
        >
          <Heading level={1}>{info.heading}</Heading>
          {networks && (
            <Socials
              className={style.grid__element_info_socials}
              networks={networks}
              style={{ minWidth: `${minWidhtSocials}px` }}
            />
          )}
        </div> */}
        <div className={style.location__info_map}>
          {asText(info.prismicDescription) && (
            <div className={style.location__info_description}>
              <PrismicRichText
                components={htmlSerializerWithProps({
                  color: styleProps?.infoTextColor,
                })}
                field={info.prismicDescription}
              />
            </div>
          )}
          <Maps
            className={style.location__info_map_container}
            darkMode={info?.mapDarkMode}
            defaultMarker={info?.defaultMarker?.url!}
            hideInterestingPoints
            locations={[
              {
                label: info?.heading,
                lat: info.geoLoc.lat,
                lng: info.geoLoc.lng,
                title: info?.heading,
                photo: info?.storeImage,
              },
            ]}
            markerStyle={{
              titleColor: info?.markerTitleColor!,
              deaultMarkerColor: info?.defaultPinColor!,
            }}
          />
        </div>
        <div className={style.location__info_content}>
          <div className={style.location__info_body}>
            <Heading
              className={style.info__title}
              level={2}
              style={{ color: styleProps?.sectionTitleColor ?? undefined }}
            >
              Working Hours
            </Heading>
            <ol className={style.location__info_list}>
              {info.workingHours?.length === 0 && (
                <li
                  className={classNames(
                    style.location__info_list_item,
                    style.location__info_list_empty,
                  )}
                  key="empty"
                >
                  <h5
                    style={{ color: styleProps?.infoLabelColor ?? undefined }}
                  >
                    There is no data
                  </h5>
                  <p style={{ color: styleProps?.infoTextColor ?? undefined }}>
                    Working hours have not been configured
                  </p>
                </li>
              )}
              {info.workingHours?.map((day, id) => {
                return (
                  <li
                    className={style.location__info_list_item}
                    key={id}
                    style={{
                      background: styleProps?.infoBackgroundColor ?? undefined,
                    }}
                  >
                    <h5
                      style={{ color: styleProps?.infoLabelColor ?? undefined }}
                    >
                      {day?.day}
                    </h5>
                    <span
                      className={classNames({
                        [style.message__closed]: day.closeReason,
                      })}
                      style={{ color: styleProps?.infoTextColor ?? undefined }}
                    >
                      {day.closeReason && `${day.closeReason}`}
                      {!day.closeReason &&
                        (!day.period?.from || !day.period?.to) &&
                        'Not Provider'}
                      {day.period?.from &&
                        day.period?.to &&
                        `${transformMilitaryTime(
                          day.period?.from,
                        )} - ${transformMilitaryTime(day.period?.to)}`}
                    </span>
                  </li>
                );
              })}
            </ol>
          </div>
          <div className={style.location__info_body}>
            <Heading
              className={style.info__title}
              level={2}
              style={{ color: styleProps?.sectionTitleColor ?? undefined }}
            >
              Get in touch
            </Heading>
            <address className={style.location__info_list}>
              <span className={style.location__info_list_item_contact}>
                <h5>Phone Number</h5>
                <Link href={`tel:${info.phone}`}>
                  {formatEnUSPhoneNumber(info.phone)}
                </Link>
              </span>
              <span className={style.location__info_list_item_contact}>
                <h5>Email</h5>
                <Link href={`mailto:${info.contactEmail}`}>
                  {info?.contactEmail}
                </Link>
              </span>
              <span className={style.location__info_list_item_contact}>
                <h5>Address</h5>
                <Link href={info.googleMapUrl ?? '/'} target="_blank">
                  {info.fullAddress}
                </Link>
              </span>
              <span className={style.location__info_list_item_contact}>
                <h5>Socials</h5>
                <Socials
                  className={classNames(style.grid__element_info_socials)}
                  networks={networks}
                  style={{ minWidth: `${minWidhtSocials}px` }}
                />
              </span>
            </address>
          </div>
        </div>
      </Container>
    </section>
  );
};

interface ILocationStyleProps {
  backgroundPage?: ColorField;
  sectionTitleColor?: ColorField;
  infoBackgroundColor?: ColorField;
  infoLabelColor?: ColorField;
  infoTextColor?: ColorField;
}

interface ILocationInfoProps {
  info: ILocationInfo;
  styleProps?: ILocationStyleProps;
}

export default LocationInfo;
