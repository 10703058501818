import { useMutation } from '@apollo/client';
import { SUBSCRIBE_TO_CRM_MUTATION } from './schema';

export const useSubscribeToCRM = options => {
  const [subscribeToCRM, result] = useMutation(
    SUBSCRIBE_TO_CRM_MUTATION,
    options,
  );

  return {
    result,
    subscribeToCRM,
  };
};
