import { useCallback } from 'react';
import { maxBy } from 'lodash';

import { useIsServerSide } from '@/hooks';

import { useEcomStoreSelector } from '../data';

function useProducts() {
  const { isServerSide } = useIsServerSide();
  const { line_items, setState } = useEcomStoreSelector(['line_items']);

  const products = isServerSide ? [] : line_items;

  const update = line_items => {
    setState({ line_items });
  };

  const add = useCallback(
    product => {
      setState({ line_items: [...products, product] });
    },
    [products],
  );

  const duplicate = index => {
    const duplicated = {
      ...products[index],
      item_id: `${(maxBy(products, 'item_id')?.item_id ?? 0) * 1 + 1}`,
      quantity: 1,
    };
    const newProducts = [...products];

    newProducts.splice(index + 1, 0, duplicated);

    setState({ line_items: newProducts });
  };

  const remove = index => {
    const newProducts = [...products];

    newProducts.splice(index, 1);

    setState({ line_items: newProducts });
  };

  const removeItem = item => {
    setState({ line_items: line_items.filter(i => i.id !== item.id) ?? [] });
  };

  return { add, duplicate, products, remove, update, removeItem };
}

export default useProducts;
