import { useQuery } from '@apollo/client';
import { GET_DISTANCE_QUERY } from './schema';

export function useGetDistance(options) {
  const { data, error, loading } = useQuery(GET_DISTANCE_QUERY, options);

  return {
    data: data?.getDistance,
    error: error,
    loading: loading,
  };
}
