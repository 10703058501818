import { FC, ReactNode } from 'react';
import {
  CtaFeatureSlice,
  CtaFeatureSliceCenteredButton,
  CtaFeatureSliceDefault,
  CtaFeatureSliceImageWithLogoAndButton,
  CtaFeatureSliceImageWithTitleAndButton,
  CtaFeatureSliceImageWithTwoCta,
  CtaFeatureSliceTitleWithCta,
  CtaFeatureSliceTitleWithTwoLinks,
} from '@/types';
import * as prismicH from '@prismicio/helpers';
import { convertHexadecimalFormatToRGB } from '@/utils';
import { Button, IconSvg, Link } from '@/components';
import classNames from 'classnames';
import { htmlSerializerWithProps } from '@/utils';
import { PrismicLink, PrismicRichText } from '@prismicio/react';
import styles from './CtaFeature.module.scss';
import { PrismicNextImage } from '@prismicio/next';
import {
  EmptyImageFieldImage,
  FilledImageFieldImage,
  LinkField,
} from '@prismicio/client';
import { useRouter } from 'next/navigation';

const RenderCta: FC<CtaFeatureSlice> = slice => {
  if (slice.variation === 'titleWithTwoLinks') {
    return <TitleWithTwoLinksVariation slice={slice} />;
  }

  if (slice.variation === 'titleWithCta') {
    return <TitleWithCtaVariation slice={slice} />;
  }

  if (slice.variation === 'centeredButton') {
    return <CenteredButtonVariation slice={slice} />;
  }

  if (slice.variation === 'imageWithTwoCta') {
    return <ImageWithTwoCtaVariation slice={slice} />;
  }

  if (slice.variation === 'imageWithTitleAndButton') {
    return <ImageWithTitleAndButtonVariation slice={slice} />;
  }

  if (slice.variation === 'imageWithLogoAndButton') {
    return <ImageWithLogoAndButtonVariation slice={slice} />;
  }

  return <DefaultVariation slice={slice} />;
};

const CenteredButtonVariation: FC<{
  slice: CtaFeatureSliceCenteredButton & CtaFeatureSlice;
}> = ({ slice }) => {
  const primary = slice?.primary;

  return (
    <CTAButton
      className={styles.cta__action_button}
      ctaType={primary.cta_type ?? 'primary'}
      external={primary?.external_link}
      link={primary?.internal_link ?? ''}
    >
      {primary?.cta_text}
    </CTAButton>
  );
};

const DefaultVariation: FC<{
  slice: CtaFeatureSliceDefault & CtaFeatureSlice;
}> = ({ slice }) => {
  const item = slice?.items?.[0];
  const primary = slice?.primary;
  const maxWidthHeader = item?.max_width_header
    ? `${item?.max_width_header}px`
    : '100%';
  const maxWidthBlurb = item?.max_width_blurb
    ? `${item?.max_width_blurb}px`
    : '100%';
  const borderRadius = primary?.cta_full_width
    ? '0px'
    : `${primary?.image_border_radius}px`;

  const background = item?.content_background_color
    ? convertHexadecimalFormatToRGB(item?.content_background_color)
    : null;

  const ctaBanner = (
    <>
      <figure
        className={classNames(styles.banner__horizontal, {
          [styles.banner__horizontal_not_placeholder]: item?.show_content,
        })}
        style={{
          borderRadius,
          paddingTop: getApectRatio(
            item?.image?.dimensions?.width as number,
            item?.image?.dimensions?.height as number,
            primary?.percentage_respect_width as number,
            false,
          ),
        }}
      >
        {<RenderImage borderRadius={borderRadius} fill image={item?.image} />}
      </figure>
      <figure
        className={classNames(styles.banner__vertical, {
          [styles.banner__vertical_not_placeholder]: item?.show_content,
        })}
        style={{
          paddingTop: getApectRatio(
            item?.image?.mobile?.dimensions?.width as number,
            item?.image?.mobile?.dimensions?.height as number,
            primary?.percentage_respect_to_width_mobile as number,
            true,
          ),
        }}
      >
        {<RenderImage borderRadius={'0px'} fill image={item?.image.mobile} />}
      </figure>
    </>
  );

  return (
    <>
      {item?.show_content && (
        <div
          className={classNames(styles.info, {
            [styles.info__text_mobile_left_aligned]:
              item?.text_mobile_aligned_left,
            [styles.info_align_left]: item.content_align === 'left',
            [styles.info_align_right]: item.content_align === 'right',
            [styles.info_align_center]:
              item.content_align === 'center' || !item.content_align,
          })}
          style={{
            backgroundColor: item?.content_background_color
              ? `rgba(${background?.r}, ${background?.g}, ${background?.b}, ${item?.content_background_opacity})`
              : undefined,
          }}
        >
          {renderTextSlice(
            item?.header,
            item?.header_color ?? '',
            maxWidthHeader,
          )}
          {renderTextSlice(item?.blurb, item?.blurb_color ?? '', maxWidthBlurb)}
          <CTAButton
            className={styles.cta__action_button}
            ctaType={item.cta_type ?? 'primary'}
            external={item?.external_link}
            link={item?.link ?? ''}
          >
            {item?.cta_text}
          </CTAButton>
        </div>
      )}
      {(item?.link || item?.external_link?.link_type === 'Any') && (
        <Link className={styles.cta__image_link} href={item?.link || '/'}>
          {ctaBanner}
          {item?.apply_effect_to_cta && item?.image?.url && (
            <div className={styles.cta__overlay} />
          )}
        </Link>
      )}
      {!item?.link && item?.external_link?.link_type !== 'Any' && (
        <PrismicLink
          className={styles.cta__image_link}
          field={item?.external_link}
        >
          {ctaBanner}
          {item?.apply_effect_to_cta && item?.image?.url && (
            <div className={styles.cta__overlay} />
          )}
        </PrismicLink>
      )}
    </>
  );
};

const ImageWithTwoCtaVariation: FC<{
  slice: CtaFeatureSliceImageWithTwoCta & CtaFeatureSlice;
}> = ({ slice }) => {
  const titleColor = slice?.primary?.title_color;
  const image = slice?.primary?.image;

  return (
    <>
      <figure className={styles.image_section}>
        {<RenderImage image={image} />}
      </figure>
      <div className={styles.content_section}>
        <PrismicRichText
          components={htmlSerializerWithProps({ color: titleColor })}
          field={slice?.primary?.title}
        />
        <div className={styles.cta_section}>
          {renderCta(
            slice?.primary?.first_cta_link,
            slice?.primary?.first_cta_image,
          )}
          {renderCta(
            slice?.primary?.second_cta_link,
            slice?.primary?.second_cta_image,
          )}
        </div>
      </div>
    </>
  );
};

const TitleWithCtaVariation: FC<{
  slice: CtaFeatureSliceTitleWithCta & CtaFeatureSlice;
}> = ({ slice }) => {
  const titleColor = slice?.primary?.title_color;
  const ctaType = slice?.primary?.cta_type;

  return (
    <>
      <div className={styles.title}>
        <PrismicRichText
          components={htmlSerializerWithProps({ color: titleColor })}
          field={slice?.primary?.title}
        />
      </div>
      <CTAButton
        ctaType={ctaType ?? 'primary'}
        external={slice?.primary?.external_link}
        link={slice?.primary?.internal_link ?? ''}
      >
        {slice?.primary?.button_text}
      </CTAButton>
    </>
  );
};

const TitleWithTwoLinksVariation: FC<{
  slice: CtaFeatureSliceTitleWithTwoLinks & CtaFeatureSlice;
}> = ({ slice }) => {
  const titleColor = slice?.primary?.title_color;
  const descriptionColor = slice?.primary?.description_color;

  return (
    <div className={styles.title_two_link_variation_body}>
      <div className={styles.info}>
        <PrismicRichText
          components={htmlSerializerWithProps({ color: titleColor })}
          field={slice?.primary?.title}
        />
        <PrismicRichText
          components={htmlSerializerWithProps({ color: descriptionColor })}
          field={slice?.primary?.description}
        />
      </div>
      <div className={styles.cta_section}>
        {renderCta(
          slice?.primary?.first_cta_link,
          slice?.primary?.first_cta_image,
        )}
        {renderCta(
          slice?.primary?.second_cta_link,
          slice?.primary?.second_cta_image,
        )}
      </div>
    </div>
  );
};

const ImageWithTitleAndButtonVariation: FC<{
  slice: CtaFeatureSliceImageWithTitleAndButton & CtaFeatureSlice;
}> = ({ slice }) => {
  const titleColor = slice?.primary?.title_color;
  const image = slice?.primary?.image;
  const ctaType = slice?.primary?.cta_type;

  return (
    <div className={styles.image_title_button_variation_body}>
      <div className={styles.image_container}>
        <figure className={styles.image_wrapper}>
          {<RenderImage fill image={image} />}
        </figure>
      </div>

      <div className={styles.content_section}>
        <PrismicRichText
          components={htmlSerializerWithProps({ color: titleColor })}
          field={slice?.primary?.title}
        />
        <CTAButton
          ctaType={ctaType ?? 'primary'}
          external={slice?.primary?.external_link}
          link={slice?.primary?.internal_link ?? ''}
        >
          {slice?.primary?.cta_button_text ?? ''}
        </CTAButton>
      </div>
    </div>
  );
};

const ImageWithLogoAndButtonVariation: FC<{
  slice: CtaFeatureSliceImageWithLogoAndButton & CtaFeatureSlice;
}> = ({ slice }) => {
  const item = slice?.items?.[0];
  const primary = slice?.primary;
  const maxWidthHeader = item?.max_width_header
    ? `${item?.max_width_header}px`
    : '100%';
  const borderRadius = primary?.cta_full_width
    ? '0px'
    : `${primary?.image_border_radius}px`;

  const background = item?.content_background_color
    ? convertHexadecimalFormatToRGB(item?.content_background_color)
    : null;

  const logoSize = item?.logo_size ? `${item?.logo_size}px` : 80;

  const ctaBanner = (
    <>
      <figure
        className={classNames(styles.banner__horizontal, {
          [styles.banner__horizontal_not_placeholder]: item?.show_content,
        })}
        style={{
          borderRadius,
          paddingTop: getApectRatio(
            item?.image?.dimensions?.width as number,
            item?.image?.dimensions?.height as number,
            primary?.percentage_respect_width as number,
            false,
          ),
        }}
      >
        {<RenderImage borderRadius={borderRadius} fill image={item?.image} />}
      </figure>
      <figure
        className={classNames(styles.banner__vertical, {
          [styles.banner__vertical_not_placeholder]: item?.show_content,
        })}
        style={{
          paddingTop: getApectRatio(
            item?.image?.mobile?.dimensions?.width as number,
            item?.image?.mobile?.dimensions?.height as number,
            primary?.percentage_respect_to_width_mobile as number,
            true,
          ),
        }}
      >
        {<RenderImage borderRadius={'0px'} fill image={item?.image.mobile} />}
      </figure>
    </>
  );

  return (
    <>
      {item?.show_content && (
        <div
          className={classNames(styles.info, {
            [styles.info__text_mobile_left_aligned]:
              item?.text_mobile_aligned_left,
            [styles.info_align_left]: item.content_align === 'left',
            [styles.info_align_right]: item.content_align === 'right',
            [styles.info_align_center]:
              item.content_align === 'center' || !item.content_align,
          })}
          style={{
            backgroundColor: item?.content_background_color
              ? `rgba(${background?.r}, ${background?.g}, ${background?.b}, ${item?.content_background_opacity})`
              : undefined,
          }}
        >
          {item?.logo && (
            <div className={styles.logo_container} style={{ width: logoSize }}>
              <PrismicNextImage
                fallbackAlt=""
                field={item.logo}
                height={item?.logo?.dimensions?.height}
                quality={100}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: 'auto',
                }}
                width={item?.logo?.dimensions?.width}
              />
            </div>
          )}
          {renderTextSlice(
            item?.header,
            item?.header_color ?? '',
            maxWidthHeader,
          )}
          <CTAButton
            className={styles.cta__action_button}
            ctaType={item.cta_type ?? 'primary'}
            external={item?.external_link}
            link={item?.link ?? ''}
          >
            {item?.cta_text}
          </CTAButton>
        </div>
      )}
      {(item?.link || item?.external_link?.link_type === 'Any') && (
        <Link className={styles.cta__image_link} href={item?.link || '/'}>
          {ctaBanner}
          {item?.apply_effect_to_cta && item?.image?.url && (
            <div className={styles.cta__overlay} />
          )}
        </Link>
      )}
      {!item?.link && item?.external_link?.link_type !== 'Any' && (
        <PrismicLink
          className={styles.cta__image_link}
          field={item?.external_link}
        >
          {ctaBanner}
          {item?.apply_effect_to_cta && item?.image?.url && (
            <div className={styles.cta__overlay} />
          )}
        </PrismicLink>
      )}
    </>
  );
};

const getApectRatio = (
  width: number,
  height: number,
  percentage: number,
  mobile: boolean,
) => {
  if (
    !percentage ||
    (mobile && percentage <= 58.32) ||
    (!mobile && percentage <= 20.42)
  ) {
    return undefined;
  }

  const aspectRatioImage = (height / width) * 100;
  const aspectRatioPercentage = (percentage * aspectRatioImage) / 100;

  return `${aspectRatioPercentage}%`;
};

const RenderImage = ({
  image,
  borderRadius = '0px',
  fill,
}: {
  image: EmptyImageFieldImage | FilledImageFieldImage;
  borderRadius?: string;
  fill?: boolean;
}) => {
  if (!image?.url) {
    return <IconSvg name="image" />;
  }

  if (fill) {
    return (
      <PrismicNextImage
        fallbackAlt=""
        field={image}
        fill
        quality={100}
        style={{ borderRadius, objectFit: 'cover', objectPosition: 'center' }}
      />
    );
  }

  return (
    <PrismicNextImage
      fallbackAlt=""
      field={image}
      height={image?.dimensions?.height}
      quality={100}
      style={{
        borderRadius: borderRadius,
        objectFit: 'contain',
      }}
      width={image?.dimensions?.width}
    />
  );
};

const renderTextSlice = (text: any, color: string, maxWidth?: string) => {
  if (!prismicH.asText(text)) {
    return;
  }

  return (
    <PrismicRichText
      components={htmlSerializerWithProps({
        color: color,
        maxWidth: maxWidth,
      })}
      field={text}
    />
  );
};

const CTAButton: FC<ICtaButtonProps> = ({
  external,
  link,
  children,
  className,
  ctaType,
}) => {
  const router = useRouter();

  if (!children) {
    return null;
  }

  const handleClic = (route?: string) => {
    if (!route) {
      return;
    }
    router.push(route);
  };

  if (link || external?.link_type === 'Any') {
    return (
      <Button
        className={classNames(styles.cta__button, className)}
        color={ctaType ?? 'primary'}
        onClick={() => handleClic(link)}
        type="button"
      >
        {children}
      </Button>
    );
  }

  return (
    <Link
      className={classNames(styles.cta__link, className)}
      href={(external as any)?.url ?? '/'}
      target="_blank"
    >
      {children}
    </Link>
  );
};

function renderCta(ctaLink, ctaImage) {
  if (!ctaImage?.url) {
    return null;
  }

  const image = (
    <PrismicNextImage
      className={styles.cta_logo}
      fallbackAlt=""
      field={ctaImage}
      height={ctaImage?.dimensions?.height}
      width={ctaImage?.dimensions?.width}
    />
  );

  if (!ctaLink?.url) {
    return <Link href={ctaLink || '/'}>{image}</Link>;
  }

  return <PrismicLink field={ctaLink}>{image}</PrismicLink>;

  /*  {!item?.link && item?.external_link?.link_type !== 'Any' && (
        <PrismicLink
          className={styles.cta__image_link}
          field={item?.external_link}
        >
          {ctaBanner}
          {item?.apply_effect_to_cta && item?.image?.url && (
            <div className={styles.cta__overlay} />
          )}
        </PrismicLink>
      )} */
}

interface ICtaButtonProps {
  link?: string;
  className?: string;
  children?: ReactNode;
  external?: LinkField;
  ctaType?: 'link' | 'primary' | 'secondary';
}

export { RenderCta };
