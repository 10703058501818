import { ApolloProvider as _ApolloProvider } from '@apollo/client';
import { useApollo } from './client';

function ApolloProvider({ pageProps, ...props }) {
  const client = useApollo(pageProps);

  return <_ApolloProvider client={client} {...props} />;
}

export default ApolloProvider;
