import { CSSProperties, FC, JSX, ReactElement } from 'react';
import classNames from 'classnames';

import { PrismicRichText } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import * as prismicH from '@prismicio/helpers';

import { Icon, Link } from '@/components';
import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceDefault,
  ImageWithTextSideBySideSliceWithCtaOnImageSide,
  ImageWithTextSideBySideSliceWithFeaturesList,
  ImageWithTextSideBySideSliceWithFlatImage,
  ImageWithTextSideBySideSliceWithSocialIcons,
  ImageWithTextSideBySideSliceWithWideSocialIcons,
} from '@/types';
import { htmlSerializerWithProps } from '@/utils';

import style from './components.module.scss';
import { useIsMobile } from 'hooks/useIsMobile';
import { FilledImageFieldImage } from '@prismicio/client';

export const DefaultComponentSection: FC<{
  slice: (
    | ImageWithTextSideBySideSliceWithSocialIcons
    | ImageWithTextSideBySideSliceWithWideSocialIcons
    | ImageWithTextSideBySideSliceWithFeaturesList
    | ImageWithTextSideBySideSliceDefault
    | ImageWithTextSideBySideSliceWithFlatImage
  ) &
    ImageWithTextSideBySideSlice;
  children?: ReactElement<any> | ReactElement<any>[];
}> = ({ slice, children }) => {
  const { isMobile } = useIsMobile(1024);
  const {
    content_background_color,
    content_horizontal_align,
    content_vertical_align,
    description,
    description_color,
    heading,
    heading_color,
    image,
    icon_as_heading,
    icon_on_title,
    all_images_border_radius,
    image_bottom_left_border_radius,
    image_bottom_right_border_radius,
    image_top_left_border_radius,
    image_top_right_border_radius,
    layout_direction,
    title,
    title_color,
  } = slice.primary;

  const icon_on_title_custom_width = (
    slice as ImageWithTextSideBySideSliceDefault
  )?.primary?.icon_on_title_custom_width;
  const icon_as_heading_custom_width = (
    slice as ImageWithTextSideBySideSliceDefault
  )?.primary?.icon_as_heading_custom_width;

  let mainImage: FilledImageFieldImage | null = null;

  if (image?.url) {
    if (isMobile && image?.mobile?.url) {
      mainImage = image.mobile;
    } else {
      mainImage = image;
    }
  }

  const layoutDirection = layout_direction ?? 'left';
  const alignText = content_horizontal_align ?? 'center';

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'left':
        return 'flex-start';

      case 'right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  const bodyVariables = {
    '--content-padding-left': layoutDirection === 'left' ? '13.6%' : '0px',
    '--content-padding-right': layoutDirection === 'left' ? '0px' : '13.6%',
  };

  return (
    <>
      <div
        className={style.body}
        style={
          {
            ...bodyVariables,
          } as CSSProperties
        }
      >
        <div
          className={classNames(style.content, {
            [style.content_with_background]: !!content_background_color,
          })}
          style={{
            alignItems: textAlign(content_horizontal_align as string),
            textAlign: alignText,
            alignSelf: textAlign(content_vertical_align),
            background: content_background_color as string,
            borderTopLeftRadius:
              !!content_background_color && all_images_border_radius
                ? (all_images_border_radius as number)
                : (image_top_right_border_radius as number),
            borderBottomLeftRadius:
              !!content_background_color && all_images_border_radius
                ? (all_images_border_radius as number)
                : (image_bottom_right_border_radius as number),
            borderTopRightRadius:
              !!content_background_color && all_images_border_radius
                ? (all_images_border_radius as number)
                : (image_top_left_border_radius as number),
            borderBottomRightRadius:
              !!content_background_color && all_images_border_radius
                ? (all_images_border_radius as number)
                : (image_bottom_left_border_radius as number),
          }}
        >
          {icon_as_heading?.url && (
            <figure
              className={classNames(style.body__heading_icon, {
                [style.body__heading_icon__custom_width]:
                  !!icon_as_heading_custom_width,
              })}
              style={{ maxWidth: `${icon_as_heading_custom_width}px` }}
            >
              <PrismicNextImage
                fallbackAlt=""
                field={icon_as_heading}
                style={{ objectFit: 'contain' }}
              />
            </figure>
          )}
          {title && (
            <div
              className={style.body__title_container}
              style={
                {
                  '--icon-order': alignText === 'right' ? 0 : 1,
                  '--title-order': alignText === 'right' ? 1 : 0,
                } as CSSProperties
              }
            >
              <PrismicRichText
                components={htmlSerializerWithProps(
                  {
                    color: title_color,
                  },
                  style.body__title,
                )}
                field={title}
              />
              {icon_on_title?.url && alignText !== 'center' && (
                <figure
                  className={classNames(style.body__title_icon, {
                    [style.body__title_icon__custom_width]:
                      !!icon_on_title_custom_width,
                  })}
                  style={{ maxWidth: `${icon_on_title_custom_width}px` }}
                >
                  <PrismicNextImage
                    fallbackAlt=""
                    field={icon_on_title}
                    style={{
                      objectFit: 'contain',
                      maxWidth: `${icon_on_title_custom_width}px`,
                    }}
                  />
                </figure>
              )}
            </div>
          )}
          {icon_on_title?.url && alignText === 'center' && (
            <figure
              className={classNames(style.body__title_icon_centered, {
                [style.body__title_icon_centered__custom_width]:
                  !!icon_on_title_custom_width,
              })}
              style={{ maxWidth: `${icon_on_title_custom_width}px` }}
            >
              <PrismicNextImage
                fallbackAlt=""
                field={icon_on_title}
                style={{
                  objectFit: 'contain',
                }}
              />
            </figure>
          )}
          {heading && (
            <PrismicRichText
              components={htmlSerializerWithProps(
                {
                  color: heading_color,
                },
                style.body__text_heading,
              )}
              field={heading}
            />
          )}
          {description && (
            <PrismicRichText
              components={htmlSerializerWithProps(
                {
                  color: description_color,
                },
                style.description,
              )}
              field={description}
            />
          )}
          {children}
          {(slice.variation === 'withFeaturesList' ||
            slice.variation === 'withSocialIcons' ||
            slice.variation === 'withWideSocialIcons') &&
          slice?.primary?.cta_text ? (
            <div
              className={classNames(style.cta_section, {
                [style.cta_with_social_icons]:
                  slice.variation === 'withSocialIcons' ||
                  slice.variation === 'withWideSocialIcons',
              })}
              style={
                {
                  '--cta-section-align': slice.primary.content_horizontal_align,
                } as CSSProperties
              }
            >
              <CtaSection slice={slice} />
            </div>
          ) : (
            <CtaSection
              isFlatImage={slice.variation === 'withFlatImage'}
              isSingleButton
              slice={slice}
            />
          )}
        </div>
      </div>
      <figure
        className={classNames(style.body__figure, {
          [style.body__figure__with_flat_image]:
            slice.variation === 'withFlatImage',
        })}
        style={{
          borderTopLeftRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_top_left_border_radius as number),
          borderBottomLeftRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_bottom_left_border_radius as number),
          borderTopRightRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_top_right_border_radius as number),
          borderBottomRightRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_bottom_right_border_radius as number),
        }}
      >
        {mainImage ? (
          <PrismicNextImage
            fallbackAlt=""
            field={mainImage}
            fill
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <Icon name="image" />
        )}
      </figure>
    </>
  );
};

const CtaElement = ({
  ctaType,
  ctaLink,
  ctaBackground,
  ctaTextColor,
  ctaBorder,
  ctaBackgroundOnHover,
  ctaBorderOnHover,
  ctaTextColorOnHover,
  ctaText,
  isSingleButton = false,
  isFlatImage = false,
}) => (
  <Link
    className={classNames(style.cta, {
      [style.body__button_primary]: ctaType === 'primary',
      [style.single_cta]: isSingleButton,
      [style.single_cta_flat]: isFlatImage,
    })}
    href={ctaLink}
    style={
      {
        background: ctaType !== 'link' && ctaBackground,
        color: ctaTextColor,
        border: ctaType !== 'link' && ctaBorder,
        '--cta-bg-on-hover': ctaType !== 'link' && ctaBackgroundOnHover,
        '--cta-border-on-hover': ctaType !== 'link' && ctaBorderOnHover,
        '--cta-color-on-hover': ctaTextColorOnHover,
      } as CSSProperties
    }
  >
    {ctaText}
  </Link>
);

export const CtaSection = ({
  slice,
  isSingleButton = false,
  isFlatImage = false,
}: {
  slice: (
    | ImageWithTextSideBySideSliceWithFeaturesList
    | ImageWithTextSideBySideSliceDefault
    | ImageWithTextSideBySideSliceWithFlatImage
    | ImageWithTextSideBySideSliceWithCtaOnImageSide
    | ImageWithTextSideBySideSliceWithSocialIcons
    | ImageWithTextSideBySideSliceWithWideSocialIcons
  ) &
    ImageWithTextSideBySideSlice;
  isSingleButton?: boolean;
  isFlatImage?: boolean;
}): JSX.Element => {
  if (slice.variation !== 'withFeaturesList' && !slice.primary.cta_text) {
    return <></>;
  }

  return (
    <>
      {slice.primary.cta_text && (
        <CtaElement
          ctaBackground={slice.primary.cta_background}
          ctaBackgroundOnHover={slice.primary.cta_background_on_hover}
          ctaBorder={slice.primary.cta_border}
          ctaBorderOnHover={slice.primary.cta_border_on_hover}
          ctaLink={slice.primary.cta_link}
          ctaText={slice.primary.cta_text}
          ctaTextColor={slice.primary.cta_text_color}
          ctaTextColorOnHover={slice.primary.cta_text_color_on_hover}
          ctaType={slice.primary.cta_type === 'button' ? 'primary' : 'link'}
          isFlatImage={isFlatImage}
          isSingleButton={isSingleButton}
        />
      )}
      {(slice.variation === 'withFeaturesList' ||
        slice.variation === 'withSocialIcons') &&
        slice.primary.secondary_cta_text && (
          <CtaElement
            ctaBackground={slice.primary.secondary_cta_background}
            ctaBackgroundOnHover={
              slice.primary.secondary_cta_background_on_hover
            }
            ctaBorder={slice.primary.secondary_cta_border}
            ctaBorderOnHover={slice.primary.secondary_cta_border_on_hover}
            ctaLink={slice.primary.secondary_cta_link}
            ctaText={slice.primary.secondary_cta_text}
            ctaTextColor={slice.primary.secondary_cta_text_color}
            ctaTextColorOnHover={
              slice.primary.secondary_cta_text_color_on_hover
            }
            ctaType={
              slice.primary.secondary_cta_type === 'button' ? 'primary' : 'link'
            }
          />
        )}
    </>
  );
};

export const textAlign = (alignment: string) => {
  switch (alignment) {
    case 'left':
      return 'flex-start';

    case 'right':
      return 'flex-end';

    default:
      return 'center';
  }
};

export const TextSlice = ({
  text,
  color,
  maxWidth,
  centeredText,
}: {
  text: any;
  color: string;
  maxWidth?: string;
  centeredText?: boolean;
  className?: string;
}) => {
  if (!prismicH.asText(text)) {
    return null;
  }

  return (
    <PrismicRichText
      components={htmlSerializerWithProps({
        color: color,
        maxWidth: maxWidth,
        textAlign: centeredText && 'center',
      })}
      field={text}
    />
  );
};
