import { useMutation } from '@apollo/client';
import { MIGRATE_USER_MUTATION } from './schema';

export function useMigrateUser(options) {
  const [migrateUser, result] = useMutation(MIGRATE_USER_MUTATION, options);

  return {
    migrateUser,
    result,
  };
}
