import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';

interface IIconProps extends HTMLAttributes<HTMLElement> {
  onClick?: () => void;
  name: string;
  className?: string;
}

const Icon: FC<IIconProps> = ({ name, className, onClick, ...props }) => {
  return (
    <i
      className={classNames('icon', `icon_${name}`, className)}
      onClick={onClick}
      role="button"
      {...props}
    />
  );
};

export default Icon;
