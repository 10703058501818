import { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import { asText, RichTextField } from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';

import { IconSvg } from '../IconSvg';
import { currencyFormat } from 'utils/utils';

import styles from './FreeDeliveryAlert.module.scss';

interface IFreeDeliveryAlert {
  minDeliveryAmount: number;
  totalAmount: number;
  messageCompleted?: RichTextField;
  message?: RichTextField;
  classname: string;
}
const FreeDeliveryAlert = ({
  minDeliveryAmount,
  totalAmount,
  message,
  messageCompleted,
  classname,
}: IFreeDeliveryAlert) => {
  if (!minDeliveryAmount) return null;

  const porcentProgress = (totalAmount * 100) / minDeliveryAmount;

  const processCompleted = totalAmount >= minDeliveryAmount;

  const freeDeliveryThreshold = minDeliveryAmount - totalAmount;

  const amountFormated = `$${currencyFormat(freeDeliveryThreshold)}`;

  type PropsWithChildren = {
    children?: ReactNode;
    [key: string]: unknown;
  };

  const replaceAmount = (children: ReactNode, amount: string): ReactNode => {
    // 1. Case string
    if (typeof children === 'string') {
      return children.replace(/\{\{amount\}\}/g, amount);
    }

    // 2. Case array
    if (Array.isArray(children)) {
      return children.map(child => replaceAmount(child, amount));
    }

    // 3. React Case Element
    if (isValidElement(children)) {
      const element = children as ReactElement<PropsWithChildren>;
      const props = {
        ...element.props,
        children: element.props.children
          ? replaceAmount(element.props.children, amount)
          : undefined,
      };

      return cloneElement(element, props);
    }

    // 4. Others case (numbers, booleans, etc)
    return children;
  };

  return (
    <div className={classNames(styles.free_delivery_progress, classname)}>
      {processCompleted && (
        <div
          className={classNames(
            styles.free_delivery_progress__message,
            styles.free_delivery_progress__message_completed,
          )}
        >
          <IconSvg name="alert-success" />
          {asText(messageCompleted) ? (
            <PrismicRichText field={messageCompleted} />
          ) : (
            <p>
              Congrats! You’ve unlocked <strong>free delivery!</strong>
            </p>
          )}
        </div>
      )}
      {!processCompleted && (
        <div className={styles.free_delivery_progress__message}>
          {asText(message) ? (
            <PrismicRichText
              components={{
                paragraph: ({ children }) => (
                  <p>{replaceAmount(children, amountFormated)}</p>
                ),
              }}
              field={message}
            />
          ) : (
            <p>
              Spend <strong>{amountFormated}</strong> more & get free delivery!
            </p>
          )}
        </div>
      )}
      <div className={styles.free_delivery_progress}>
        <progress
          className={classNames(styles.free_delivery_progress__bar, {
            [styles.free_delivery_progress__complete]: processCompleted,
          })}
          max={100}
          value={porcentProgress}
        />
      </div>
    </div>
  );
};

export default FreeDeliveryAlert;
