import { useMutation } from '@apollo/client';
import { REQUEST_MEASURE_MUTATION } from './schema';

export function useRequestMeasure(options) {
  const [requestMeasure, result] = useMutation(
    REQUEST_MEASURE_MUTATION,
    options,
  );

  return {
    requestMeasure,
    result,
  };
}
