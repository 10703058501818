import {
  CustomerAddress,
  SignupDetails,
  TreezAgreement,
  TreezUser,
} from '@/types';

import { TreezClient, useTreezClient } from '../api';

export class CustomerService {
  private _client: TreezClient;
  private _name = 'customer';

  constructor(client?: TreezClient) {
    this._client = client ?? new TreezClient();
  }

  /**
   * Endpoint to collect customer details during signup.
   *
   * @param {SignupDetails} details Customer's details
   */
  sendSignupDetails = (details: SignupDetails): Promise<TreezUser> =>
    this._client.post(`${this._name}/signup`, details);

  /**
   * Retrieve the membership agreement for new customer acceptance during the signup process.
   */
  getSignupAgreement = (): Promise<TreezAgreement> =>
    this._client
      .get(`${this._name}/agreement`)
      .then(({ data }: { data: TreezAgreement }) => data);

  /**
   * Endpoint to request an one-time authorization code as part of the new customer signup process.
   * The code will be sent to the email address that was input by the customer.
   *
   *@param {string} userId The {id} value returned in the response from the "/signup" endpoint.
   */
  getSignupRequestCode = (userId: string) =>
    this._client.get(`${this._name}/${userId}/code`);

  /**
   * Endpoint to validate one-time authorization code for new customer signup.
   *
   * @param {string} userId The {id} value returned in the response from the "/signup" endpoint.
   * @param {string} code The one-time code sent to the customer via email.
   * */
  verifySignupCode = ({ userId, code }: { userId: string; code: string }) =>
    this._client.get(`${this._name}/${userId}/code/${code}`);

  /**
   * Endpoint to save and activate new customer.
   *
   * @param {string} password Password input by user.
   * @param {string} comment Use to save referral source.
   * */
  activateCustomer = ({
    password,
    comment,
  }: {
    password: string;
    comment?: string;
  }) =>
    this._client.post(`${this._name}/activate`, {
      ...(comment ? { comment } : {}),
      password,
    });

  /**
   * Endpoint to upload customer documentation
   *
   * @param {string} docType Type of customer document to be uploaded:
   *      Driver's License (dl), Doctor Recommendation (rec), Miscellaneous (misc),
   *      Membership Agreement (membership), Customer Signature (signature)
   * @param {string} file User file to be uploaded. Include the path where
   *      the file is located. Supported formats: jpg, gif, bmp, png.
   * */
  uploadCustomerDocument = (
    docType: 'dl' | 'rec' | 'misc' | 'membership' | 'signature',
    file: string,
  ) =>
    this._client.post(`${this._name}/upload?docType${docType}`, {
      file,
    });

  /**
   * Endpoint to retrieve customer's profile.
   */
  getCostumerProfile = (): Promise<TreezUser> =>
    this._client.get(`${this._name}/profile`);

  /**
   * Endpoint to retrieve customer's purchase history.
   *
   * @param {string} pageNumber Page number to retrieve.
   */
  getCostumerPurchaseHistory = (pageNumber: string) =>
    this._client.get(`${this._name}/tickets/page/${pageNumber}`);

  /**
   * Endpoint to allow a logged in customer to change their password
   *
   * @param {string} oldPassword
   * @param {string} newPassword
   */
  saveNewPassword = ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) =>
    this._client.put(`${this._name}/password`, {
      newPassword,
      oldPassword,
    });

  /**
   * Endpoint to check whether the new email address entered by the user already exists.
   * Upon successful validation, sends verification code to the email address provided.
   *
   * @param {string} email The customer's email address
   */
  verifyCustomerEmail = (email: string) =>
    this._client.get(`${this._name}/verifyemail?email=${email}`);

  /**
   * Endpoint to save a users new email address.
   *
   * @param {string} emailRetrieveToken The one-time code sent to the new email address input by the user.
   */
  saveCustomerEmail = (emailRetrieveToken: string) =>
    this._client.put(`${this._name}/updateemail/${emailRetrieveToken}`);

  /**
   * Endpoint for verifying that a customer is not banned from purchase
   * and has not exceeded daily purchase limits. Use prior to checkout.
   *
   * @param {string} customerId This field is returned in the response from the "/profile" endpoint.
   */
  verifyPurchaseEligibility = (customerId: string) =>
    this._client.post(
      `${this._name}/${customerId}/validateLicenseAndViolationBan`,
    );

  /**
   * Endpoint to allow a logged-in user to add a new address
   *
   * @param {Address} address User's new address
   */
  createCustomerAddress = (address: CustomerAddress) =>
    this._client.post(`${this._name}/address`, address);

  /**
   * Endpoint to allow a logged-in user to identify their primary address.
   * Usually used to specify address for delivery.
   *
   * @param {string} type Address type, ex:DELIVERY.
   * @param {number} addressId Address ID.
   */
  setCustomerPrimaryAddress = (type: string, addressId: number) =>
    this._client.post(`${this._name}/primaryaddress`, { addressId, type });

  /**
   * Endpoint to allow a logged-in customer to change their customer type
   *
   * @param {string} type Customer type ex: ADULT, MEDICAL, ALL.
   */
  changeCustomerType = (type: string) =>
    this._client.post(`${this._name}/type/${type}`);
}

export const useCustomerService = () => {
  const client = useTreezClient();

  const customerService = new CustomerService(client);

  return customerService;
};
