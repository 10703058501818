import { MedRecSwitch, SearchHeader, StoreLocator } from '@/components';

import {
  HeaderBurgerNav,
  HeaderContainer,
  HeaderFavorites,
  HeaderLogo,
  HeaderNav,
  HeaderProfile,
  HeaderRow,
  HeaderSection,
  HeaderShopping,
} from '../Header.components';
import { PAGE_ROUTES } from 'constants/routes';

import styles from './HeaderWithRightStoreLocator.module.scss';

const HeaderWithRightStoreLocator = () => (
  <>
    <HeaderDefaultMobile />
    <HeaderDefaultDesktop />
  </>
);

const HeaderDefaultMobile = () => (
  <HeaderContainer.Mobile>
    <HeaderRow borderless type="small">
      <HeaderSection justify="start">
        <HeaderBurgerNav />
        <HeaderLogo fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <HeaderShopping />
        <HeaderFavorites />
        <HeaderProfile />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow borderless type="flat">
      <HeaderSection justify="center">
        <SearchHeader seeAllHref={PAGE_ROUTES.SHOP} />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow type="small">
      <HeaderSection justify="center" spaceBetweenOnMultipleChildren>
        <StoreLocator />
        <MedRecSwitch />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Mobile>
);

const HeaderDefaultDesktop = () => (
  <HeaderContainer.Desktop>
    <HeaderRow
      borderless
      className={styles.search__header_center_row}
      type="medium"
    >
      <HeaderSection justify="start">
        <HeaderLogo fixHeight fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <MedRecSwitch />
        <StoreLocator />
        <SearchHeader withEmbeddedInput />
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow type="flat" withNavbar>
      <HeaderSection justify="start">
        <HeaderNav />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Desktop>
);

export { HeaderWithRightStoreLocator };
