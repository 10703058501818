import { FC, useRef, useState } from 'react';

import { Container, Image } from '@/components';
import { VideoSlice, VideoSliceDefault } from '@/types';

import * as prismicH from '@prismicio/helpers';

import style from './video.module.scss';
import classNames from 'classnames';

import playIcon from '../../public/images/video-icons/play.svg';
import pauseIcon from '../../public/images/video-icons/pause.svg';
import { getSlicePadding } from '@/utils';
import { useIsMobile } from 'hooks/useIsMobile';

const DefaultVariation: FC<{
  slice: VideoSlice & VideoSliceDefault;
}> = ({ slice }) => {
  const { isMobile } = useIsMobile();

  const marginBottom = slice?.primary?.bottom_spacing;
  const videoSrc = prismicH.asLink(slice?.primary?.video_content);
  const poster = prismicH.asImageSrc(slice?.primary?.preview_image);
  const maxHeight = isMobile
    ? slice?.primary?.max_height_mobile ?? 'auto'
    : slice?.primary?.max_height_desktop ?? 'auto';
  const paddingSection = slice?.primary?.padding_section;
  const controls = slice?.primary?.controls;
  const background = slice?.primary?.section_background_color ?? '';
  const autoplay = slice?.primary?.autoplay || controls === 'Hide';
  const videoOnFullWidth = slice?.primary.video_on_full_width;

  const [play, setPlay] = useState(autoplay);
  const videoRef = useRef<HTMLVideoElement>(null);

  const ytVideo = videoSrc?.includes('youtube');

  const handleClick = () => {
    if (controls !== 'Simplified') {
      return;
    }
    if (!play) {
      videoRef?.current?.play();
      setPlay(true);
      return;
    }
    setPlay(false);
    videoRef?.current?.pause();
  };

  if (!slice?.primary?.active) {
    return null;
  }

  const renderVideoContent = () => {
    if (ytVideo) {
      const ytVideoSrc = autoplay ? `${videoSrc}?autoplay=1&mute=1` : videoSrc;

      return (
        <div style={{ height: maxHeight }}>
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height={maxHeight}
            src={ytVideoSrc || ''}
            title="YouTube video player"
          ></iframe>
        </div>
      );
    }

    return (
      <div onClick={handleClick} role="button" style={{ maxHeight }}>
        <video
          autoPlay={autoplay}
          className={classNames({
            [style.video__disabled_interaction]: controls === 'Hide',
          })}
          controls={controls === 'Detailed'}
          loop
          muted={autoplay}
          poster={poster || ''}
          ref={videoRef}
          src={videoSrc || ''}
        />
        {controls !== 'Hide' && (
          <div
            className={classNames(style.overlay, {
              [style.play__state]: play,
              [style.pause__state]: !play,
              [style.has__controls]: controls === 'Detailed',
            })}
          >
            <Image
              alt="play icon"
              className={style.icon}
              src={play ? pauseIcon : playIcon}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <section
      className={classNames(
        style.video__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      style={{ background }}
    >
      {videoOnFullWidth ? (
        renderVideoContent()
      ) : (
        <Container>{renderVideoContent()}</Container>
      )}
    </section>
  );
};

export default DefaultVariation;
