import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  use,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePathname } from 'next/navigation';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import {
  getOnlyTreezFilters,
  toCollectionQueryParams,
  toQuerySortBy,
  toTreezFilters,
} from '@/utils';
import { useEcomStoreSelector, useMenuService } from '@/data';
import { useDebounced, useSelectedStore, useUpdateQueryParams } from '@/hooks';
import { GProduct, LastKey, QueryFilters, SortBySelectOption } from '@/types';

interface ProductContext {
  activeFilters: QueryFilters;
  activeFiltersGroupCount: { [key: string]: number };
  products: GProduct[];
  activeSort: SortBySelectOption;
  applySort(_sort: SortBySelectOption): void;
  hasNextPage: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  searchTerm: string;
  activeFiltersCount: number;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  fetchNextPage(): void;
  refine(
    _attribute: string,
    _value: string,
    _isActive: boolean,
    _isNumericFilter?: boolean,
  ): void;
  clearFilters(): void;
  clearFiltersGroup(_group: string): void;
}

interface ProductProviderProps {
  bundleId?: string;
  bundleTitle?: string;
  bundleSlug?: string;
  children: ReactNode;
  includePromoItems?: boolean;
  initialFilters: QueryFilters;
  initialSortBy?: SortBySelectOption;
  initialSearchTerm?: string;
  initialData?: GProduct[];
  initialIsDealsActive?: boolean;
  defaultQueryFilter?: any;
  initialLastKey?: {
    productId: string;
    customerType: string;
  };
  pageSize?: number;
}

const ProductsContext = createContext<ProductContext | undefined>(undefined);

ProductsContext.displayName = 'ProductContext';

export function useProductsContext() {
  const context = use(ProductsContext);

  if (!context) {
    throw new Error(
      'useProductsContext should be used within a ProductsProvider',
    );
  }

  return context;
}

const DEFAULT_SORT_BY: SortBySelectOption = {
  label: 'Sort By',
  value: 'default',
};

const PAGE_SIZE = 20;

export default function ProductsProvider({
  bundleId,
  bundleTitle,
  bundleSlug,
  children,
  includePromoItems,
  initialFilters,
  initialSortBy,
  initialSearchTerm,
  initialData,
  initialLastKey,
  initialIsDealsActive,
  defaultQueryFilter,
  pageSize,
}: ProductProviderProps) {
  const { store } = useSelectedStore();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm ?? '');
  const debouncedSearchTerm = useDebounced(searchTerm, 300);
  const pathname = usePathname();
  const { updateQueryParams } = useUpdateQueryParams();
  const { searchProducts } = useMenuService();
  const { customer_type, provider_inventory_location_id } =
    useEcomStoreSelector(['customer_type', 'provider_inventory_location_id']);

  const [activeFilters, setActiveFilters] =
    useState<QueryFilters>(initialFilters);

  const [activeSort, setActiveSort] = useState<SortBySelectOption>(
    initialSortBy ?? DEFAULT_SORT_BY,
  );

  const activeFiltersGroupCount = useMemo(() => {
    const filterCounts = Object.entries(activeFilters).reduce(
      (acc, [key, values]) => {
        const count = values ? Object.keys(values).length : 0;
        return { ...acc, [key]: count };
      },
      {},
    );
    return filterCounts;
  }, [activeFilters]);

  const [treezFilters, setTreezFilters] = useState({
    ...defaultQueryFilter,
    ...toTreezFilters(toCollectionQueryParams(initialFilters)),
  });

  useEffect(() => {
    manageRoute(activeFilters, activeSort);
  }, [debouncedSearchTerm]);

  const dispensaryName = store?.shortName ?? '';

  const queryKey = [
    'products',
    pathname,
    activeFilters,
    activeSort.value,
    debouncedSearchTerm,
    dispensaryName,
    customer_type,
    provider_inventory_location_id,
  ];

  const {
    data,
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<ProductsQueryData, Error>({
    queryKey,
    queryFn: ({ pageParam }) => {
      return searchProducts({
        displayOnSale: initialIsDealsActive,
        filters: getOnlyTreezFilters(treezFilters, ['productId']),
        includePromoItems,
        lastKey: pageParam as LastKey,
        pageSize: pageSize ?? PAGE_SIZE,
        searchTerm: debouncedSearchTerm,
        sortBy: activeSort,
      });
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage: ProductsQueryData, _pages) => {
      const productsCount = lastPage.products.length;
      return productsCount < PAGE_SIZE ? undefined : lastPage.lastKey;
    },
    initialData: {
      pageParams: [],
      pages: [
        {
          lastKey: initialLastKey,
          products: initialData ? initialData : [],
        },
      ],
    },
    placeholderData: keepPreviousData,
  });

  const products = data?.pages?.flatMap(page => page.products) ?? [];

  const manageRoute = (prevFilters: QueryFilters, sort: SortBySelectOption) => {
    const queryParams = toCollectionQueryParams(prevFilters);
    const querySort = toQuerySortBy(sort);

    updateQueryParams({
      ...queryParams,
      ...(searchTerm ? { query: searchTerm } : {}),
      ...(querySort ? { sort: querySort } : {}),
    });

    setTreezFilters({
      ...defaultQueryFilter,
      ...prevFilters,
      ...toTreezFilters(queryParams),
    });
  };

  const clearFilters = () => {
    setActiveFilters({});
    setActiveSort(DEFAULT_SORT_BY);
    manageRoute({}, DEFAULT_SORT_BY);
  };

  const clearFiltersGroup = (group: string) => {
    setActiveFilters((prevFilters: QueryFilters) => {
      const updatedFilters = structuredClone(prevFilters);
      delete updatedFilters[group];
      manageRoute(updatedFilters, activeSort);
      return updatedFilters;
    });
  };

  const applySort = (sort: SortBySelectOption) => {
    setActiveSort(sort);
    manageRoute(activeFilters, sort);
  };

  const refine = (
    attribute: string,
    value: string,
    isActive: boolean,
    isNumericFilter: boolean = false,
  ) => {
    setActiveFilters((prevFilters: QueryFilters) => {
      const filters = structuredClone(prevFilters);

      if (isNumericFilter) {
        if (isActive) {
          filters[attribute] = { [value]: true };
        } else {
          delete filters[attribute];
        }
        manageRoute(filters, activeSort);
        return filters;
      }

      if (isActive) {
        filters[attribute] = { ...(filters[attribute] ?? {}), [value]: true };
      } else {
        delete filters?.[attribute]?.[value];
      }

      const hasAttributeFilters =
        filters?.[attribute] && !!Object?.keys(filters?.[attribute])?.length;

      if (!hasAttributeFilters) {
        delete filters[attribute];
      }

      manageRoute(filters, activeSort);
      return filters;
    });
  };

  const activeFiltersToCount = getOnlyTreezFilters(activeFilters);

  const activeFiltersCount = Object.values(activeFiltersToCount)
    // Select all active filters
    .flatMap(activeFilter => {
      if (!activeFilter) {
        return;
      }
      return Object.values(activeFilter);
    })
    // Remove undefined values
    .filter(element => element).length;

  return (
    <ProductsContext
      value={{
        activeFilters: getOnlyTreezFilters(activeFilters),
        activeSort,
        applySort,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
        products: products.map(prod => ({
          ...prod,
          bundle_id: bundleId,
          bundle_title: bundleTitle,
          bundle_slug: bundleSlug,
        })),
        refine,
        clearFilters,
        clearFiltersGroup,
        searchTerm,
        setSearchTerm,
        activeFiltersCount,
        activeFiltersGroupCount,
      }}
    >
      {children}
    </ProductsContext>
  );
}

interface ProductsQueryData {
  products: GProduct[];
  lastKey: LastKey;
}
