import { useSyncExternalStore } from 'react';

const MOBILE_BREAKPOINT = 640;

const useIsMobile = (customBreakPoint = MOBILE_BREAKPOINT) => {
  const isMobile = useSyncExternalStore(
    onStoreChange,
    getSnapshot(customBreakPoint),
    getServerSnapshop,
  );

  return { isMobile };
};

const onStoreChange = (callback: () => void) => {
  window.addEventListener('resize', callback);

  return () => {
    window.removeEventListener('resize', callback);
  };
};

const getSnapshot = (breakpoint: number) => () =>
  window.innerWidth < breakpoint;

const getServerSnapshop = () => false;

export { useIsMobile };
