import { LIST_BRANDS_QUERY } from './schema';

export async function listBrands(client, options = {}) {
  const response = await client.query({
    query: LIST_BRANDS_QUERY,
    ...options,
  });

  return {
    data: response.data?.listBrands,
    error: response.error,
  };
}
