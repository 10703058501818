import { useEffect } from 'react';
import { Button, Heading } from '../components';
import {
  add,
  clamp,
  compareAsc,
  eachDayOfInterval,
  eachHourOfInterval,
  format,
  isSameDay,
  isSameHour,
  set,
} from 'date-fns';
import { getOperatingHours } from '@/utils';

function Speed({ inputs, setInputs }) {
  // Service hours. The end is a hour before. This is
  // because each hour is a range. ex: 10pm - 10:59pm.
  // So the last interval of one hour includes the
  // last hour of service.
  const service = getOperatingHours();

  // The current datetime no minutes, no seconds and no ms.
  const now = set(new Date(), {
    milliseconds: 0,
    minutes: 0,
    seconds: 0,
  });

  // The minimum valid configurable datetime. All
  // datetimes before this one must be rejected.
  let minValidDate = clamp(now, {
    end: set(now, { hours: service[1] }),
    start: set(now, { hours: service[0] }),
  });

  if (
    compareAsc(now, minValidDate) === 1 ||
    compareAsc(now, set(now, { hours: service[1] })) === 0
  ) {
    minValidDate = add(now, { days: 1 });
    minValidDate = set(minValidDate, { hours: service[0] });
  }

  // Stored datetime.
  const schedule = new Date(inputs.delivery_details?.deliver_after || null);
  const isAsap =
    schedule.getHours() <=
    (isSameDay(schedule, now)
      ? minValidDate
      : set(schedule, { hours: service[0] })
    ).getHours();

  // Reset the schedule to a valid value when the
  // stored one has an expired or an invalid value.
  useEffect(() => {
    if (compareAsc(schedule, minValidDate) === -1) {
      setInputs({
        ...inputs,
        delivery_details: {
          ...inputs.delivery_details,
          deliver_after: minValidDate,
        },
      });
    }
  }, []);

  return (
    <div className="speed">
      <Heading level={3}>
        Select a {inputs.delivery_details.delivery ? 'delivery' : 'pickup'} date
      </Heading>
      <div className="speed__date" role="radiogroup">
        {eachDayOfInterval({
          end: add(now, { days: 5 }),
          start: now,
        }).map(day => {
          if (compareAsc(now, set(day, { hours: service[1] })) >= 0) {
            return null;
          }

          const isChecked = isSameDay(day, schedule);

          return (
            <Button
              aria-checked={isChecked}
              className="speed__date-option"
              color={isChecked ? 'primary' : 'secondary'}
              key={day.getDate()}
              onClick={() => {
                setInputs({
                  ...inputs,
                  delivery_details: {
                    ...inputs.delivery_details,
                    deliver_after: isSameDay(day, now)
                      ? minValidDate
                      : set(day, {
                          hours: service[0],
                        }),
                  },
                });
              }}
              role="radio"
              sm
            >
              <p>{isSameDay(day, now) ? 'Today' : format(day, 'iii')}</p>
              <strong>{day.getDate()}</strong>
            </Button>
          );
        })}
      </div>
      <Heading level={3}>
        Select a {inputs.delivery_details.delivery ? 'delivery' : 'pickup'} time
        <br />
        <small>
          Santa Clarita, Simi Valley, Thousand Oaks we begin servicing after
          4PM.
        </small>
      </Heading>
      <div className="speed__type" role="radiogroup">
        {[
          ['asap', 'ASAP'],
          ['schedule', 'Schedule for later'],
        ].map(([key, label]) => {
          const isKeyAsap = key === 'asap';
          const isChecked =
            (isKeyAsap && isAsap) || (key === 'schedule' && !isAsap);

          return (
            <Button
              aria-checked={isChecked}
              className="speed__type-option"
              color={isChecked ? 'primary' : 'secondary'}
              disabled={
                !isKeyAsap &&
                isSameDay(schedule, now) &&
                minValidDate.getHours() >= service[1] - 1
              }
              key={key}
              onClick={() => {
                let newTime = null;

                if (isKeyAsap) {
                  if (isSameDay(schedule, now)) {
                    newTime = minValidDate;
                  } else {
                    newTime = set(schedule, {
                      hours: service[0],
                    });
                  }
                } else if (isSameDay(schedule, now)) {
                  newTime = add(minValidDate, {
                    hours: 1,
                  });
                } else {
                  newTime = set(schedule, {
                    hours: service[0] + 1,
                  });
                }

                setInputs({
                  ...inputs,
                  delivery_details: {
                    ...inputs.delivery_details,
                    deliver_after: newTime.toISOString(),
                  },
                });
              }}
              role="radio"
              sm
            >
              {label}
            </Button>
          );
        })}
      </div>
      {!isAsap && (
        <div className="speed__time" role="radiogroup">
          {eachHourOfInterval({
            end: set(schedule, { hours: service[1] - 1 }),
            start: set(schedule, { hours: service[0] + 1 }),
          }).map(hour => {
            if (compareAsc(now, hour) >= 0) {
              return null;
            }

            const isChecked = isSameHour(hour, schedule);

            return (
              <Button
                aria-checked={isChecked}
                className="speed__time-option"
                color={isChecked ? 'primary' : 'secondary'}
                key={hour.getHours()}
                onClick={() => {
                  setInputs({
                    ...inputs,
                    delivery_details: {
                      ...inputs.delivery_details,
                      deliver_after: hour.toISOString(),
                    },
                  });
                }}
                role="radio"
                sm
              >
                {`${format(hour, 'h:mmaaa')} - ${format(hour, 'h:59aaa')}`}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Speed;
