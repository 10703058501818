import { CSSProperties, FC, Ref, useEffect, useRef, useState } from 'react';
import { StatsSlice, StatsSliceAsChronology } from 'prismicio-types';

import { Container } from '@/components';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';

import style from './chronologyvariation.module.scss';
import { PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';

export const ChronologyVariation: FC<{
  slice: StatsSliceAsChronology & StatsSlice;
}> = ({ slice }): any => {
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement | undefined>(undefined);
  const background = slice?.primary?.background_color as string;
  const marginBottom = slice?.primary?.bottom_spacing;
  const borderTop = slice?.primary?.border_section as string;
  const borderBottom = slice?.primary?.border_section as string;
  const paddingSection = slice?.primary?.padding_section;

  useEffect(() => {
    if (slice.items.length <= 1 || !containerRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setContainerHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const getVerticalGridTemplateAreas = () => {
    const areas: string[] = [];
    for (let i = 0; i < slice.items.length; i++) {
      let areaElement = `element_${i + 1}`;
      if (i % 2 === 0) {
        areaElement = "'" + areaElement + " .'";
      } else {
        areaElement = "'. " + areaElement + "'";
      }
      areas.push(areaElement);
    }

    return areas.join(' ');
  };

  const getHorizontalGridTemplateAreas = () => {
    const areas: string[] = [];
    const totalElements = slice.items.length;

    let firstRow = "'.";
    for (let i = 1; i <= totalElements; i++) {
      if (i < totalElements) {
        firstRow += i % 2 !== 0 ? ` element_${i + 1}` : ' .';
      } else {
        firstRow += "'";
      }
    }
    areas.push(firstRow);

    // Line separator row
    areas.push(`'${'line '.repeat(totalElements).trim()}'`);

    // Third row construction
    let thirdRow = "'element_1 .";
    for (let i = 2; i <= totalElements; i++) {
      if (i < totalElements) {
        thirdRow += i % 2 === 0 ? ` element_${i + 1}` : ' .';
      } else {
        thirdRow += "'";
      }
    }
    areas.push(thirdRow);

    return areas.join('\n');
  };

  if (slice.items.length <= 1) {
    return;
  }

  return (
    <Container
      as="section"
      className={classNames(
        style.stats__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      ref={containerRef as Ref<HTMLDivElement> | undefined}
      style={{
        background,
        borderTop,
        borderBottom,
      }}
    >
      {slice?.primary?.title && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              alignSelf: slice.primary.title_align,
              textAlign: slice.primary.title_align,
            },
            style.stats__title,
          )}
          field={slice.primary.title}
        />
      )}
      <dl
        className={style.stats__list}
        style={
          {
            '--line-color': slice.primary.line_color,
            '--vertical-grid-template-areas': getVerticalGridTemplateAreas(),
            '--horizontal-grid-template-areas':
              getHorizontalGridTemplateAreas(),
          } as CSSProperties
        }
      >
        {slice?.items.map((item, index) => (
          <div className={style.stats__element} key={`${item.date}-${index}`}>
            <dt className={style.stats__element_date}>{item.date}</dt>
            <dd className={style.stats__element_description}>
              {item.description}
            </dd>
          </div>
        ))}
        <hr
          className={style.chronology_line}
          style={
            { '--container-height': `${containerHeight}px` } as CSSProperties
          }
        />
      </dl>
    </Container>
  );
};
