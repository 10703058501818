import { Counter } from '@/components';

import {
  // getInfoPackValue,
  ProductActionFavorite,
  ProductBadgeContainer,
  ProductCategory,
  // ProductCategory,
  ProductImage,
  ProductInfo,
  //ProductInfo,
  // ProductInfoCarousel,
  ProductName,
  ProductPrice,
  ProductVariants,
} from '../Product.components';

import style from '../product.module.scss';
import styleVariation from './ProductCardWithHighlighted.module.scss';

import { IProductCard } from '../Product';
import classNames from 'classnames';
import React from 'react';
import { startCase } from 'lodash';
import { NOT_DEFINED } from 'constants/product';
//import { startCase } from 'lodash';

const ProductCardWithHighlighted: React.FC<IProductCard> = ({
  isSpecial,
  className,
  product,
  variants,
  analyticsListName,
  weightVariant,
  updateWeinghtVariant,
  isLargeCard,
}) => {
  return (
    <div
      className={classNames(style.product, styleVariation.product, className)}
    >
      <div className={styleVariation.product__image_container}>
        <ProductImage
          analyticsListName={analyticsListName}
          className={styleVariation.product__image}
          product={product}
        />
        <div className={styleVariation.actions__body}>
          <ProductActionFavorite
            className={classNames(style.favorite_action)}
            product={product}
          />
        </div>
      </div>
      <div
        className={classNames(
          style.product__body,
          styleVariation.product__body,
        )}
      >
        <ProductBadgeContainer
          className={styleVariation.product_badge__container}
          isSpecial={isSpecial}
          key="badge-container"
          product={product}
          type="default"
          weightVariant={weightVariant}
        />
        <ProductName analyticsListName={analyticsListName} product={product} />
        <div
          className={classNames(
            style.info__brand_category,
            styleVariation.info__brand_category,
          )}
        >
          <ProductInfo
            className={style.category}
            info={
              startCase(product?.subcategory as string) ||
              startCase(NOT_DEFINED)
            }
            type="default"
          />
          <ProductCategory
            info={product?.flower_type as string}
            type="outlined"
          />
        </div>
        <ProductPrice
          className={styleVariation.product__price_container}
          elementVariants={
            <ProductVariants
              type="default"
              updateWeightVariant={updateWeinghtVariant}
              variants={variants}
              weightVariant={weightVariant}
            />
          }
          isOverflow={variants?.length === 1}
          isSpecial={isSpecial}
          type="expanded-tax"
          weightVariant={weightVariant}
        />
      </div>
      <div
        className={classNames(styleVariation.product__actions_footer, {
          [styleVariation.product__actions_footer_disabled]:
            product?.isLowStock,
          [styleVariation.product__actions_footer_large_card]: isLargeCard,
        })}
      >
        <Counter
          actionTheme="link"
          className={classNames(styleVariation.actions__counter, {
            [styleVariation.actions__counter_large_card]: isLargeCard,
          })}
          classNameCounter={styleVariation.actions__counter_form}
          onCard
          product={product}
          selectedWeightVariant={weightVariant}
          showIcon
        />
      </div>
    </div>
  );
};

export { ProductCardWithHighlighted };
