import { FC } from 'react';
import classNames from 'classnames';
import { CenteredTextSlice } from '@/types';
import { PrismicRichText } from '@prismicio/react';
import { useScreenWidth, useShowByLocationSlug } from '@/hooks';
import {
  getMaxWidthForDevice,
  getSlicePadding,
  htmlSerializerWithProps,
} from '@/utils';
import { Container } from '@/components';
import styles from './centeredtext.module.scss';
import { asText } from '@prismicio/client';

const CenteredText: FC<CenteredTextSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);
  const { width } = useScreenWidth();

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const textMaxWidthDesktop = slice?.primary?.text_max_width_desktop;
  const textMaxWidthMobile = slice?.primary?.text_max_width_mobile;
  const headingMaxWidthDesktop = slice?.primary?.heading_max_width_desktop;
  const headingMaxWidthMobile = slice?.primary?.heading_max_width_mobile;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  const textMaxWidth = getMaxWidthForDevice(
    width,
    textMaxWidthDesktop!,
    textMaxWidthMobile!,
  );

  const headingMaxWidth = getMaxWidthForDevice(
    width,
    headingMaxWidthDesktop!,
    headingMaxWidthMobile!,
  );

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(
        styles.centered__text,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: slice?.primary?.background!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container
        className={styles.container}
        style={{
          textAlign: slice?.primary?.text_align,
          alignItems: slice?.primary?.text_align,
        }}
      >
        <PrismicRichText
          components={htmlSerializerWithProps({
            color: slice?.primary?.heading_color,
            maxWidth: headingMaxWidth,
          })}
          field={slice?.primary?.heading}
        />
        {asText(slice?.primary?.text) && (
          <div className={classNames(styles.content, 'content')}>
            <PrismicRichText
              components={htmlSerializerWithProps({
                maxWidth: textMaxWidth,
                color: slice?.primary?.font_color,
              })}
              field={slice?.primary?.text}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default CenteredText;
