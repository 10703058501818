import { useUiStore } from '@/data';
import { Container, Heading, Image, Link, useData } from '@/components';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import style from './megamenu.module.scss';
import { PAGE_ROUTES } from '@/constants';
import { FC } from 'react';
import * as prismicH from '@prismicio/helpers';

interface IMegaMenuProps {
  itemID: string;
  setMenuItemID: (_id: string | null) => void;
}

const Megamenu: FC<IMegaMenuProps> = ({ itemID, setMenuItemID }) => {
  const { navigation } = useData();
  const [, setState] = useUiStore();

  if (!itemID) {
    return <></>;
  }

  const megaNav: any = navigation?.data?.links.find((item: any) => {
    const { uid, type } = item.external_link;
    return uid === itemID && type === 'mega_nav';
  });

  if (!megaNav) {
    return <></>;
  }

  // megaNavBody is any because Prismic does not generate
  // the type for the external_link type
  const megaNavBody: any = megaNav?.external_link?.data?.body;

  const firstSection =
    megaNavBody.filter(item => item?.slice_type === 'first_section')[0] || [];

  const megaMenuNavLinks =
    megaNavBody.filter(item => item?.slice_type === 'group_link') || [];

  const megaMenuNavLinksSorted = sortBy(
    megaMenuNavLinks,
    o => o?.primary?.sort,
  );

  const megaMenuPictureSections =
    megaNavBody.filter(item => item?.slice_type === 'picture_section')[0] || [];

  const handleCloseMegaMenu = () => {
    setState({ is_megamenu_open: false });
    setMenuItemID(null);
  };

  const generateNav = (link, index) => {
    return (
      <nav key={index}>
        <Heading className={style.head} level={6}>
          {prismicH.asText(link?.primary?.group_link_label)}
        </Heading>
        {link.items.map((item, i) => {
          return (
            <Link href={item?.link} key={i} onClick={handleCloseMegaMenu}>
              {item?.label}
            </Link>
          );
        })}
      </nav>
    );
  };

  const generateLink = (item, index) => {
    return (
      <Link href={item?.link} key={index} onClick={handleCloseMegaMenu}>
        {item?.label}
      </Link>
    );
  };

  return (
    <div
      className={classNames(style.megamenu)}
      onMouseLeave={handleCloseMegaMenu}
      role="button"
    >
      <Container className={style.megamenu__container}>
        <nav className={style['section-one']}>
          {firstSection?.items?.map((item, index) => generateLink(item, index))}
          <Link
            className={style.button_link}
            href={PAGE_ROUTES.SHOP}
            onClick={handleCloseMegaMenu}
          >
            Shop all
          </Link>
        </nav>

        <div className={style['section-two']}>
          {megaMenuNavLinksSorted.map((link, index) => {
            if (link?.primary?.sort < 3) {
              return generateNav(link, index);
            }
          })}
        </div>
        <div className={style['section-three']}>
          {megaMenuNavLinksSorted.map((link, index) => {
            if (link?.primary?.sort > 2) {
              return generateNav(link, index);
            }
          })}
        </div>
        <div className={style['section-four']}>
          <Link
            href={megaMenuPictureSections?.primary?.link}
            onClick={handleCloseMegaMenu}
          >
            <figure>
              <Image
                alt={megaMenuPictureSections?.primary?.image?.alt}
                fill
                src={megaMenuPictureSections?.primary?.image?.url}
                style={{ objectFit: 'cover', objectPosition: 'right' }}
              />
            </figure>
            <div>
              <p className={style.title}>
                {megaMenuPictureSections?.primary?.title[0]?.text}
              </p>
              <p className={style.subtitle}>
                {megaMenuPictureSections?.primary?.description}
              </p>
            </div>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Megamenu;
