import { useMutation } from '@apollo/client';
import {
  CREATE_AUTHORIZE_PAYMENT_MUTATION,
  CREATE_STRIPE_PAYMENT_MUTATION,
  CREATE_WEBPAY_MEASURE_TRANSACTION_MUTATION,
  CREATE_WEBPAY_TRANSACTION_MUTATION,
} from './schema';

export function useCreateAuthorizePayment(options) {
  const [createAuthorizePayment, result] = useMutation(
    CREATE_AUTHORIZE_PAYMENT_MUTATION,
    options,
  );

  return {
    createAuthorizePayment,
    result,
  };
}

export function useCreateStripePayment(options) {
  const [createStripePayment, result] = useMutation(
    CREATE_STRIPE_PAYMENT_MUTATION,
    options,
  );

  return {
    createStripePayment,
    result,
  };
}

export function useCreateWebpayTransaction(options) {
  const [createWebPayTransaction, result] = useMutation(
    CREATE_WEBPAY_TRANSACTION_MUTATION,
    options,
  );

  return {
    createWebPayTransaction,
    result,
  };
}

export function useCreateWebpayMeasureTransaction(options) {
  const [createWebPayMeasureTransaction, result] = useMutation(
    CREATE_WEBPAY_MEASURE_TRANSACTION_MUTATION,
    options,
  );

  return {
    createWebPayMeasureTransaction,
    result,
  };
}
