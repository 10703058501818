import { Placeholder } from '@/components';
import style from './tabreview.module.scss';

const TabReviewPlaceholder = () => {
  return (
    <section className={style.placeholder__product_reviews}>
      <div className={style.placeholder__review_actions_filters}>
        <Placeholder className={style.placeholder__text} />
        <Placeholder className={style.placeholder__criteria_filter} />
        <Placeholder className={style.placeholder__criteria_filter} />
      </div>
      <ul className={style.placeholder__review_container}>
        {new Array(3).fill(null).map((_, index) => (
          <div className={style.placeholder__review_item} key={index}>
            <span className={style.placeholder__review_figure_costumer}>
              <Placeholder className={style.placeholder__review_figure} />
              <Placeholder className={style.placeholder__review_staus} />
            </span>
            <div className={style.placeholder__review_description}>
              {new Array(4).fill(null).map((_, index) => (
                <Placeholder className={style.placeholder__text} key={index} />
              ))}
            </div>
            <span className={style.placeholder__text_date}>
              <Placeholder className={style.placeholder__text} />
            </span>
          </div>
        ))}
      </ul>
    </section>
  );
};

export { TabReviewPlaceholder };
