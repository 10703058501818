import { TreezClient, useTreezClient } from '../api/';

class LoginService {
  private _client: TreezClient;
  private _name = 'login';

  constructor(client: TreezClient) {
    this._client = client;
  }

  /**
   * Endpoint to login an existing customer.
   * Session token and refresh token are included in the response.
   *
   * @param {string} email The user's email.
   * @param {string} password The user's password.
   */
  login = ({ email, password }: { email: string; password: string }) =>
    this._client.post(this._name, { email, password });

  /**
   * Endpoint for requesting a one-time code for password reset.
   * Code is sent to customer's email address.
   *
   * @param {string} email The user's email.
   */
  forgotPassword = (email: string) =>
    this._client.post(`${this._name}/forgotpassword`, { email });

  /**
   * Endpoint to save new password at the end of the forgotten password flow.
   * Code is sent to customer's email address.
   *
   * @param {string} email The user's email.
   * @param {string} code The one-time code sent to user's email.
   * @param {string} password The user's new password.
   */
  updatePassword = ({ code, password }: { code: string; password: string }) =>
    this._client.post(`${this._name}/newpassword`, {
      password,
      retrieveToken: code,
    });

  refreshSessionToken = ({ refreshToken }: { refreshToken: string }) =>
    this._client.get(`token/${refreshToken}`);
}

export const useLoginService = () => {
  const client = useTreezClient();

  const loginService = new LoginService(client);

  return loginService;
};
