import { FC } from 'react';

import { useUiStore } from '@/data';
import { Link, PopoverPanel, useAuth } from '@/components';
import { useNotify } from '@/hooks';

import style from './accountmenu.module.scss';

const AccountMenu: FC = () => {
  const [state, setState] = useUiStore();
  const { signOut, email } = useAuth();
  const { notify } = useNotify();

  const handleClick = () => {
    signOut().then(() => {
      notify('success', 'User logged out successfully');
    });
    setState({ is_menu_user_open: false });
  };

  const links = [
    {
      event: () => setState({ is_menu_user_open: false }),
      label: 'Account settings',
      url: '/account',
    },
    {
      event: () => setState({ is_menu_user_open: false }),
      label: 'Orders',
      url: '/orders',
    },
  ];

  return (
    <PopoverPanel
      className={style.account__menu__user}
      isOpen={state.is_menu_user_open}
      toggle={() => setState({ is_menu_user_open: false })}
    >
      <div className={style.peak}></div>
      <p>
        <span>Hi {email}</span>!
      </p>
      <div className={style.links}>
        {links.map((link, index) => {
          return (
            <Link href={link.url} key={index} onClick={link.event}>
              {link.label}
            </Link>
          );
        })}
      </div>
      <button className={style.signout} onClick={handleClick} type={'button'}>
        Sign out
      </button>
    </PopoverPanel>
  );
};
export default AccountMenu;
