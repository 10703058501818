import { Placeholder, ProductPlaceholder } from '@/components';

const RelatedProductPlaceholder = () => {
  return (
    <section className="placeholder__products_related">
      <div className="placeholder__related_header">
        <Placeholder className="placeholder__related_title" />
        <Placeholder className="placeholder__related_actions" />
      </div>
      <ul className={'placeholder__related_container'}>
        {new Array(8).fill(null).map((_, index) => (
          // eslint-disable-next-line max-len
          <ProductPlaceholder key={index} />
        ))}
      </ul>
    </section>
  );
};

export { RelatedProductPlaceholder };
