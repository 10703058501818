import { Placeholder } from '@/components';

const ProductInfoPlaceholder = () => {
  return (
    <section className={'placeholder__product_detail'}>
      <div className="placeholder__product_asset">
        <div className="placeholder__product_asset_container">
          <figure className="placeholder__figure">
            <Placeholder />
          </figure>
          {/*  <figure className="placeholder__figure_secondary">
            <Placeholder />
          </figure> */}
        </div>
        <ul className="placeholder__dot_container">
          {new Array(3).fill(null).map((_, index) => (
            <Placeholder className="placeholder__figure" key={index} />
          ))}
        </ul>
      </div>
      <div className={'placeholder__product_info'}>
        <Placeholder className={'placeholder__product_brand'} />
        <Placeholder className={'placeholder__product_name'} />
        <Placeholder className={'placeholder__product_category'} />
        <ol className={'placeholder__product_rating'}>
          {new Array(5).fill(null).map((_, index) => (
            <Placeholder className="placeholder__start_small" key={index} />
          ))}
        </ol>
        <Placeholder className={'placeholder__product_description_title'} />
        <div className={'placeholder__product_description'}>
          {new Array(7).fill(null).map((_, index) => (
            <Placeholder className="placeholder__text" key={index} />
          ))}
        </div>
        <Placeholder className={'placeholder__product_social_title'} />
        <div className={'placeholder__product_social'}>
          {new Array(4).fill(null).map((_, index) => (
            <Placeholder className="placeholder__social_item" key={index} />
          ))}
        </div>
        <div className={'placeholder__product_actions'}>
          <Placeholder className="placeholder__btn" />
          <Placeholder className="placeholder__price" />
          <Placeholder className="placeholder__favorite" />
        </div>
        <Placeholder className={'placeholder__product_about_title'} />
        <div className={'placeholder__product_about'}>
          {new Array(3).fill(null).map((_, index) => (
            <Placeholder className="placeholder__text" key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export { ProductInfoPlaceholder };
