import { useData } from '@/components';
import { useEcomStore } from '@/data';
import { Store, TreezStore } from '@/types';

interface ISelectedStore {
  store: TreezStore | undefined;
}

interface IGapCommerceStore {
  store: Store | undefined;
}

const useSelectedStore = (): ISelectedStore => {
  const { treezStores } = useData();
  const [storedState] = useEcomStore();

  return {
    store: treezStores?.find(
      store => store?.shortName === storedState?.treez_store_name,
    ),
  };
};

const useGapCommerceStore = (): IGapCommerceStore => {
  const { store } = useData();

  return {
    store,
  };
};

export { useSelectedStore, useGapCommerceStore };
