import classNames from 'classnames';

import { useBundleSpecial } from '@/hooks';
import { IconSvg, SpecialBundleProgress } from '@/components';

import styles from './specialcounter.module.scss';

interface ISpecialCounter {
  bundleId: string;
}
const SpecialCounter = ({ bundleId }: ISpecialCounter) => {
  const {
    dependentProducts,
    independentProducts,
    processingDependents,
    processingIndependents,
    special,
    currentBundleProducts,
  } = useBundleSpecial({ id: bundleId });

  if (!special) return null;

  if (special.specialType === 'BUNDLE') {
    return (
      <SpecialBundleProgress
        bundleProducts={currentBundleProducts}
        special={special}
      />
    );
  }

  const allowDiscountProducts = special?.allowDiscountProducts;

  const porcentProgress =
    ((dependentProducts.length + independentProducts.length) * 100) /
    special.totalProductsRequired;

  const processCompleted =
    special?.totalProductsRequired ===
    dependentProducts.length + independentProducts.length;

  return (
    <div
      className={classNames(styles.counter, {
        [styles['counter--allowDiscount']]: allowDiscountProducts,
        [styles['counter--active']]: !processingIndependents,
      })}
    >
      {!allowDiscountProducts && (
        <>
          <div
            className={classNames(styles.step, {
              [styles['step--process']]: processingIndependents,
              [styles['step--completed']]: !processingIndependents,
            })}
          >
            <IconSvg name="shopping-specials" />
            {!!independentProducts.length && (
              <span className={styles.counter}>
                {independentProducts.length}
              </span>
            )}
          </div>
          <span
            className={classNames(styles.divider, {
              [styles['divider--process']]: !processingIndependents,
            })}
            style={{
              // eslint-disable-next-line max-len
              backgroundImage: processCompleted
                ? `linear-gradient(to right, var(--progress-color-completed) ${porcentProgress}%, transparent 0%)`
                : `linear-gradient(to right, var(--progress-color) ${porcentProgress}%, transparent 0%)`,
            }}
          >
            <IconSvg name={!processingDependents ? 'unlocked' : 'locked'} />
          </span>
        </>
      )}
      <div
        className={classNames(styles.step, {
          [styles['step--process']]: processingDependents,
          [styles['step--completed']]: processCompleted,
        })}
      >
        <IconSvg name="offer" />
        {!!dependentProducts.length && (
          <span className={styles.counter}>{dependentProducts.length}</span>
        )}
      </div>
    </div>
  );
};

export default SpecialCounter;
