import { useQuery } from '@tanstack/react-query';

import { useSelectedStore } from '@/hooks';
import { useEcomStoreSelector, useMenuService } from '@/data';

export const useSearchProductsSuggestions = (searchTerm: string) => {
  const { store } = useSelectedStore();
  const { customer_type, provider_inventory_location_id } =
    useEcomStoreSelector(['customer_type', 'provider_inventory_location_id']);
  const { searchProductsSuggestions } = useMenuService();

  const dispensaryName = store?.shortName ?? '';
  const queryKey = [
    dispensaryName,
    customer_type,
    'products-suggestions',
    searchTerm,
    provider_inventory_location_id,
  ];

  const { data, isLoading, error, isError } = useQuery({
    queryKey,
    queryFn: () => searchProductsSuggestions(searchTerm, 50),
  });

  return { isLoading, error, isError, suggestions: data?.products ?? [] };
};
