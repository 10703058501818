import { FC } from 'react';

import { Container } from '@/components';
import { SideBySideSlice } from '@/types';

import style from './sidebyside.module.scss';
import { PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';

const SideBySide: FC<SideBySideSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const backgroundColor = slice?.primary?.background_color as string;
  const sections = slice?.items;
  const paddingSection = slice?.primary?.padding_section;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      as="section"
      className={classNames(
        style.sidebyside__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
    >
      {sections.map((section, id) => {
        const border = section.border as string;
        const color = section.font_color;

        return (
          <div
            className={classNames(style.section, {
              [style.section__border_rounder]: border,
              [style.section__img_border_rounder]: !backgroundColor,
            })}
            key={id}
            style={{ backgroundColor, border }}
          >
            <div
              className={classNames(style.left_content, style.section_content)}
            >
              {section?.left_content ? (
                <PrismicRichText
                  components={htmlSerializerWithProps({
                    color,
                  })}
                  field={section.left_content}
                />
              ) : null}
            </div>
            <div
              className={classNames(style.right_content, style.section_content)}
            >
              {section?.right_content ? (
                <PrismicRichText
                  components={htmlSerializerWithProps({
                    color,
                  })}
                  field={section.right_content}
                />
              ) : null}
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default SideBySide;
