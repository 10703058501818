import { orderBy } from 'lodash';
import { Image, Price } from '@/components';
import { currencyFormat, formatWeightLabel } from '@/utils';

function ItemList({ items }) {
  const tableHead = (
    <thead>
      <tr>
        <th>Picture</th>
        <th>Item name</th>
        <th>Quantity</th>
        <th>Gram</th>
        <th>Price</th>
      </tr>
    </thead>
  );

  const renderRow = (id, thumbnail, name, quantity, variants, price) => {
    return (
      <tr key={id}>
        <td data-label="Picture">
          <figure>
            <Image
              alt={name}
              fallback="/images/product-placeholder.svg"
              fill
              src={thumbnail ?? ''}
              style={{ objectFit: 'contain' }}
              title={name}
            />
          </figure>
        </td>
        <td data-label="Item name">{name}</td>
        <td data-label="Quantity">{quantity}</td>
        <td data-label="Amount of grams">
          {variants.map(v => formatWeightLabel(v.type))}
        </td>
        <td data-label="Price">
          <Price
            className="order__item-price"
            price={`$${currencyFormat(price)}`}
            salePrice={`$${currencyFormat(variants[0].price)}`}
            special={price !== variants[0].price}
          />
        </td>
      </tr>
    );
  };

  return (
    <table>
      {tableHead}
      <tbody>
        {orderBy(items, ['name'], ['desc']).map(item =>
          renderRow(
            item.item_id,
            item.thumbnail,
            item.name,
            item.quantity,
            item.variants,
            item.price,
          ),
        )}
      </tbody>
    </table>
  );
}

export default ItemList;
