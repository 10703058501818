import { useEffect, useState } from 'react';

import { getInitialStoresFilter } from '@/utils';
import { useEcomStoreSelector } from '@/data';

const useStoreFilter = () => {
  const { jane_store_id } = useEcomStoreSelector(['jane_store_id']);
  const [storeFilter, setStoreFilter] = useState(getInitialStoresFilter());

  useEffect(() => {
    if (jane_store_id) {
      setStoreFilter(`store_id:"${jane_store_id}"`);
    }
  }, [jane_store_id]);

  return { storeFilter };
};

export { useStoreFilter };
