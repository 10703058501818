/* eslint-disable max-len */
import Head from 'next/head';

function Fonts({ fonts }) {
  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                        WebFontConfig=${JSON.stringify(fonts)};
                        (function(d){
                        var wf=d.createElement('script'),s=d.scripts[0];
                        wf.src='https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
                        wf.async=true;
                        s.parentNode.insertBefore(wf,s);
                        })(document);`,
        }}
      />
    </Head>
  );
}

export default Fonts;
