import { Placeholder } from '@/components';
import style from './ratingreviews.module.scss';

const RatingReviewPlaceholder = () => {
  return (
    <section className={style.placeholder__rating_reviews_content}>
      <Placeholder className={style.placeholder__product_rating_review_title} />
      <div className={style.placeholder__product_rating_reviews}>
        <div className={style.placeholder__product_rating_container}>
          <div className={style.placeholder__rating_puntuation}>
            <Placeholder className={style.placeholder__puntuation} />
            <ol className={style.placeholder__product_rating}>
              {new Array(5).fill(null).map((_, index) => (
                <Placeholder
                  className={style.placeholder__start_small}
                  key={index}
                />
              ))}
            </ol>
          </div>
          <ol className={style.placeholder__product_description}>
            {new Array(5).fill(null).map((_, index) => (
              <Placeholder
                className={style.placeholder__progress}
                key={index}
              />
            ))}
          </ol>
        </div>
        <ul className={style.placeholder__product_status_container}>
          {new Array(13).fill(null).map((_, index) => (
            <Placeholder className={style.placeholder__state} key={index} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export { RatingReviewPlaceholder };
