import { Product, Store, TierMethod } from '__generated__/graphql';
import { PromoCarouselDocumentDataCarouselItemsItem } from 'prismicio-types';

export interface SiteConfig {
  flavors: string[];
  ECOMMERCE_CUSTOMER_TYPE_OPTION_ENABLED: string;
  PRIMARY_COLOR: string;
  ECOMMERCE_TYPE_EXPRESS: string;
  NEW_ORDER_PRINT_ENABLED: string;
  AGE_GATE: string;
  AGREEMENT_ADULT_USE: string;
  DEFAULT_ECOMMERCE_LAYOUT_VIEW: string;
  ECOMMERCE_CHECKOUT_PAY_LATER_BUTTON_LABEL: string;
  P65WARNINGS: string;
  menuEnabled: string;
  REWARD_MODE: string;
  HELD_ORDER: string;
  KIOSK_IDLE_TIMEOUT: string;
  FASTLY_SERVICE_ID: string;
  DELIVERY_COST: string;
  CONFIRMATION_REMOVED: string;
  collections: Collection[];
  ESTIMATED_TAX_DISCLAIMER_TEXT: string;
  NO_PRODUCT_IMAGE: string;
  COMPLETED_ORDER_PRINT_ENABLED: string;
  CLOSE_TIME: string;
  DEFAULT_PATIENT_TYPE: string;
  FORCE_BARCODE_SCAN: string;
  FACEBOOK_PIXEL: string;
  ecommerceFullstoryEnabled: boolean;
  IS_COLOR: string;
  NAV_BAR_LOGO: string;
  GOOGLE_ANALYTICS_TRACKING_ID: string;
  CATEGORY_FILTER_TEXT_COLOR: string;
  LOGO_URL: string;
  MINIMUM_DELIVERY_QUANTITY: string;
  DISPENSARY_LOGO_URL: string;
  SECONDARY_COLOR: string;
  FULFILLMENT_TICKET_LINE_SORTING_PARAMETERS: string;
  DELIVERY_MINIMUM_TOTAL: string;
  DISPENSARY_SHORT_NAME: string;
  ECOM_MED_CART_REC_ITEMS: string;
  ECOMMERCE_FORGOT_PASSWORD_BY_EMAIL_ENABLED: string;
  classifications: string[];
  EMAIL_VALIDATION_ENABLED: boolean;
  DISPLAY_PRICING_TIERS: string;
  FULFILLMENT_TYPES_ENABLED: string;
  PRODUCTS_BY_CUSTOMER_TYPE: string;
  SELF_SERVICE_KIOSK: string;
  menuRoundTax: string;
  ECOM_BULK_FLOWER: string;
  CONFIRMATION_AUTH_CODE_REQUEST: string;
  MISC_START_AMOUNT: string;
  shortName: string;
  ONLINEMENU: string;
  INDICA_COLOR: string;
  MENU_SEARCH_ICON_COLOR: string;
  ECOMMERCE_ADDRESS: EcommerceAddress;
  ECOMMERCE_TYPE: string;
  CBD_COLOR: string;
  REASON_LIST: string[];
  ECOMMERCE_CONTACT_PHONE: string;
  colors: Colors;
  DPOS: string;
  MENU_PURCHASE_LIMITS_ICON_COLOR: string;
  MEMBERSHIP_AGREEMENT_LOGO: string;
  KIOSK_ORDER_NOTES: string;
  MISC_MAX_AMOUNT: string;
  categories: TreezCategory[];
  P65WARNING_FEATURE_AVAILABLE: string;
  UPDATE_CACHE: string;
  ECOMMERCE_THANK_YOU_PAGE_MESSAGE: string;
  FASTLY_API: string;
  ACH_PROVIDER_ENVIRONMENT: string;
  ORDER_PROCESSED_AT: string;
  brands: string[];
  IS_ORDERING_HOURS: boolean;
  googleMapsApiKey: string;
  subTypes: string[];
  ECOMMERCE_NAVBAR_IMAGE_ID: string;
  RESET_PASSWORD_TTL: string;
  ORDER_PROCESSED_NOW: string;
  SBL_ENABLED: boolean;
  ORDER_PROCESSED_IN: string;
  KIOSK_MODE_ENABLED: string;
  PACKED_READY_ORDER_PRINT_ENABLED: string;
  EDITED_ORDER_PRINT_ENABLED: string;
  ALLOW_PHONE_NUMBER_LOGIN: string;
  ORDER_PROCESSED_NEXT_DAY: string;
  CUSTOMER_TYPE_ALLOW: string;
  MENU_CART_ICON_COLOR: string;
  AUTO_PRINT_ENABLED: string;
  PAYMENT_KIOSK_ENABLED: string;
  FORBIDDEN_ZIP_CODES: string;
  SI_COLOR: string;
  THANK_YOU_PAGE_TTL: string;
  ECOMMERCE_ADDRESSBAR_COLOR: string;
  CLEAR_ORDERS_FROM_COMPLETED_IN: string;
  ECOMMERCE_TYPE_DELIVERY: string;
  POTS_API: string;
  primaryColor: string;
  PURGE_ENABLE: string;
  ECOMMERCE_ADDRESSBAR_TEXT_COLOR: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
  ECOMMERCE_ADDRESS_RADIUS: string;
  ECOMMERCE_DELIVERY_NOTES_ALLOWED: string;
  CONFIRMATION_IN_PROCESS: string;
  ORDERING_DISABLED: string;
  MENU_HAMBURGER_ICON_COLOR: string;
  ECOMMERCE_SEARCH_SUGGESTIONS_BAR_ENABLED: string;
  ALLOW_ADULT_USE_SIGNUPS: string;
  STRONGHOLD_ECOMMERCE_ACH_PAYMENT_ENABLED: string;
  pickupAddresses: PickupAddress[];
  ALLOW_REWARDS_REDEMPTION_ECOMMERCE: string;
  MISC_DRIVER_CASH_LIMIT: string;
  SATIVA_COLOR: string;
  ECOMMERCE_DELIVERY_NOTES: string;
  OPEN_TIME: string;
  HOW_DID_YOU_HEAR_ABOUT_US_ENABLED: string;
  AGREEMENT_MEDICAL_USE: string;
  textColor: string;
  subCat: SubCat;
  PAYMENT_KIOSK: string;
  CATEGORY_THRESHOLD: string;
  effects: string[];
  EMAIL_VERIFICATION_REQUIRED: boolean;
  KUSH_COLOR: string;
  ACH_PROVIDER_JS_LIBRARY_URL: string;
  ADVANCED_FILTER_OPEN_VALUE: string;
  CATEGORY_FILTER_COLOR: string;
  REASONS: string;
  ACH_ENABLED: boolean;
  CURRENT_ACH_PROVIDER: string;
  CAPTCHA_VALIDATION_ENABLED: string;
  RESET_PASSWORD_TEXT: string;
  KIOSK_FEATURE_AVAILABLE: string;
  ECOMMERCE_TYPE_INSTORE: string;
  DISPENSARY_FULL_NAME: string;
  ALLOWED_ZIP_CODES: string;
  STRICT_MEDICAL_INVENTORY_RESTRICTION_ENABLED: string;
  categoryNames: any[];
  general: string[];
  MINIMUM_INVENTORY_QUANTITY: string;
  MENU_URL: string;
  DELIVERY_FEE_TAXABLE: string;
  DISPLAY_POST_TAX: string;
  MENU_ENABLED: string;
  TEXT_COLOR: string;
  TAX_BY_PACKAGE_CUSTOMER_TYPE: string;
  HYBRID_COLOR: string;
  PAYMENT_KIOSK_CONFIGURATION: string;
  ECOMMERCE_COLLECTION_BAR_ENABLED: string;
  MAINBOARD: string;
  text_number: string;
  ACH_PROVIDER_PUBLISHABLE_KEY: string;
  pickupLocation: string;
  WARNING_ICON: string;
  CONFIRMATION_CANCELED: string;
  POST_TAX_ENABLED: string;
  AGE_RESTRICTION: string;
  KIOSKSERVICE_ACCESS_TOKEN_EXPIRATION: string;
  PURCHASE_RESTRICTIONS: string;
  ECOMMERCE_ADDRESSBAR_QUESTION: string;
  ECOMMERCE_CHECKOUT_PAY_NOW_BUTTON_LABEL: string;
  STRICT_MEDICAL_INVENTORY_RESTRICTION_ENABLED_ALL_AGE: string;
  secondaryColor: string;
}

export enum ShippingType {
  // eslint-disable-next-line no-unused-vars
  DELIVERY = 'DELIVERY',
  // eslint-disable-next-line no-unused-vars
  PICKUP = 'PICKUP',
}

export interface TreezStore extends Store {
  distance?: string;
  delivery_radius: number;
  delivery_minimum: number;
  full_address: string;
  name: string;
  display_post_tax: boolean;
  recreational: boolean;
  reviews_count: number;
  city: string;
  state: string;
  time_zone_identifier: string;
  id_required: boolean;
  last_call_interval: number;
  pickup: boolean;
  product_count: number;
  allow_off_hours_ordering: boolean;
  avg_response_time: number;
  url_slug: string;
  allow_future_day_ordering: boolean;
  delivery_max_lead_time: number;
  delivery_min_lead_time: number;
  delivery_last_call_interval: number;
  pickup_max_lead_time: number;
  pickup_min_lead_time: number;
  description: string;
  medical: boolean;
  type: string[];
  metro_area: string;
  _geoloc: Geoloc;
  primary_gallery_image: any;
  google_map_url: string;
  phone: string;
  photo: string;
  rating: number;
  shortName: string;
  store_options: string[];
  working_hours: DayPeriod[];
  delivery_hours: DayPeriod[];
  pickup_hours: DayPeriod[];
  delivery_zipcodes: string[];
  open_now: string;
  open_now_boolean: boolean;
  current_deals: string[];
  ownership_identification: string[];
  marketplace_visible: boolean;
  custom_product_type_ranking: string[];
  optional_filters: any;
  objectID: string;
  categories: TreezCategory[];
  brands: string[];
  subTypes: string[];
  subCat: SubCat;
  classifications: string[];
  _highlightResult: HighlightResult;
  shipping_method: ShippingType[];
  asset?: string;
  ageRestriction: AgeRestriction;
  allowCustomDeliveryFee?: boolean;
}
export interface AgeRestriction {
  adult: number;
  medical: number;
}
export interface HighlightResult {
  store_notes?: HighlightResultItem;
  brand?: HighlightResultItem;
  root_types?: HighlightResultItem[];
  name?: HighlightResultItem;
  description?: HighlightResultItem;
  category?: HighlightResultItem;
  brand_subtype?: HighlightResultItem;
  full_address?: HighlightResultItem;
  city?: HighlightResultItem;
  type?: HighlightResultItem[];
  store_options?: HighlightResultItem[];
  product_id?: HighlightResultItem;
  comment?: HighlightResultItem;
  rating?: HighlightResultItem;
  created_at?: HighlightResultItem;
  updated_at?: HighlightResultItem;
  nickname?: HighlightResultItem;
  avatar?: HighlightResultItem;
  feelings?: HighlightResultItem[];
  activities?: HighlightResultItem[];
  upvote_count?: HighlightResultItem;
}

export interface HighlightResultItem {
  value: string;
  matchLevel: string;
  matchedWords: string[];
}
export interface Geoloc {
  lat: number;
  lng: number;
}

export interface Period {
  from: string;
  to: string;
}

export type Day =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export interface DayPeriod {
  day: Day;
  period: Period;
  closeReason?: string;
}

export interface Collection {
  id: number;
  type: string;
  name: string;
  icon: string;
  order: number;
  principal: boolean;
}

export interface EcommerceAddress {
  id: number;
  street: string;
  city: string;
  state: string;
  county: any;
  country: string;
  type: any;
  zip: string;
  placeId: string;
  comment: string;
  name: string;
  longitude: string;
  latitude: string;
  apt: any;
  primary: boolean;
}

export interface Colors {
  siColor: string;
  cbdColor: string;
  sativaColor: string;
  isColor: string;
  kushColor: string;
  hybridColor: string;
  indicaColor: string;
}

export interface TreezCategory {
  id: any;
  tenantId: any;
  name: string;
  isCannabis: boolean;
  customerTypes: string;
}

export interface PickupAddress {
  id: number;
  street: string;
  city: string;
  state: string;
  county: any;
  country: string;
  type: any;
  zip: string;
  placeId: string;
  comment: string;
  name: string;
  longitude: string;
  latitude: string;
  apt: any;
  primary: boolean;
}

export interface SubCat {
  PLANT: string[];
  MERCH: string[];
  'NON-INV': string[];
  TINCTURE: string[];
  MISC: string[];
  EDIBLE: string[];
  Tincture: string[];
  BEVERAGE: string[];
  PREROLL: string[];
  TOPICAL: string[];
  CARTRIDGE: string[];
  PILL: string[];
  FLOWER: string[];
  EXTRACT: string[];
}

export interface Discount {
  discount_id: string;
  discount_title: string;
  discount_affinity: string;
  discount_amount: number;
  discount_method: string;
  discount_stackable: string;
}
export interface TierPricingDetails {
  start_value: number;
  price_per_value: number;
}
export interface Pricing {
  price_type: 'TIER' | 'FLAT';
  tier_method: string;
  tier_name: string;
  discount_amount: number;
  discount_percent: number;
  tier_pricing_detail: TierPricingDetails[];
}

export interface GProduct extends Product {
  bundle_id?: string;
  bundle_title?: string;
  discounts: Discount[] | null;
  labResults: LabResult[];
  isOutStock?: boolean;
  isLowStock?: boolean;
  tier_method?: TierMethod;
  base_weight?: number;
}

export interface TreezProduct {
  afterKey: any;
  categoryId: string;
  categoryName: string;
  type: string;
  shortDescription: string;
  longDescription: string;
  croppedImage: string;
  largeImage: string;
  classifications: string[];
  generalAttrs: any[];
  flavors: string[];
  effects: string[];
  subTypes: string[];
  brand: string;
  thc: string;
  cbd: string;
  cbdPercent: any;
  totalCannabinoids: any;
  terps: any;
  cbdRatio: string;
  description: string;
  size: string;
  mgDose: string;
  medium: string;
  active: boolean;
  stockInventory: number;
  liveInventory: number;
  tier35priceSell: number;
  taxRate: number;
  secondaryTaxRate: number;
  labResultUrl: string;
  menuTitle: string;
  totalDoses: number;
  lastUpdateDate: any;
  productList: ProductList[];
  thresholdMap: any;
  discounts: any;
  unitOfMeasurement: string;
  attributeMap: any;
  customerTypes: string;
  terminalId: any;
  totalMgCbd: any;
  altMenuTitle: string;
}

export interface TreezGroupProduct {
  id: string;
  name: string;
  type: string;
  brand: string;
  subtype: string;
  size: string;
  classification: string;
  code: string;
  disabled: boolean | null;
  priceSell: null;
  tier: string;
}

export interface LabResult {
  result_type: string;
  amount: {
    minimum_value: string;
    maximum_value: string;
  };
  amount_type: string;
}
export interface ProductList {
  inStock: number;
  productId: string;
  categoryId: string;
  productName: string;
  productDescription: string;
  priceSell: number;
  displayPriceSell: number;
  discountedPrice: number;
  discountedPriceRounded: number;
  taxRate: number;
  weight: number;
  productGroup: string;
  productUnit: string;
  barcodes: any;
  active: boolean;
  type: string;
  unitOfMeasurement: string;
  terminalId: any;
  seoProductName: string;
  discounts: Discount[];
  pricing: Pricing;
  labResults: LabResult[];
}

export interface TreezUser {
  id: number;
  firstName: string;
  lastName: string;
  rewardPoints: string;
  redemeedPoints: any;
  rewardTier: string;
  addresses: any[];
  pickupAddresses: TreezUserPickupAddress[];
  deliveryDetail?: TreezUserDeliveryDetail;
  tokens?: TreezUserTokens;
  adult: boolean;
  customerType: string;
  rec: boolean;
  dl: boolean;
  misc: boolean;
  email: string;
  phone: string;
  birthdate: string;
  marketing: boolean;
  userId: number;
  currentSignUpStepIndex: number;
  isDetailSignUpLoadData: boolean;
  password: string;
}

export interface TreezAgreement {
  id: string;
  type: string;
  dateCreated: number;
  dateDestroyed?: number;
  html: string;
  currentFlag: boolean;
}

export interface TreezUserPickupAddress {
  id: number;
  street: string;
  city: string;
  state: string;
  county: any;
  country: string;
  type: any;
  zip: string;
  placeId: string;
  comment: string;
  name: string;
  longitude: string;
  latitude: string;
  apt: any;
  primary: boolean;
}

export interface TreezUserDeliveryDetail {
  type: string;
  addressId: number;
}

export interface TreezUserTokens {
  resultCode: any;
  resultReason: any;
  token: string;
  tokenExpiredIn: string;
  refreshToken: string;
  refreshTokenExpiredIn: string;
}

export interface TreezAddress {
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface SignupDetails {
  email: string;
  phone: string;
  firstName?: string;
  lastName?: string;
  addresses?: TreezAddress[];
  birthday?: string;
  customerType?: string;
  optIn?: boolean;
}

export interface CustomerAddress {
  isdefault?: boolean;
  type?: string;
  comment?: string;
  street: string;
  apt: string;
  city: string;
  state?: string;
  zip?: string;
  country?: string;
  latLng?: { lat: string; lng: string }[];
}

export type QueryFilters = {
  [attribute: string]: {
    [value: string]: boolean;
  };
};

export type TreezFilters = {
  [key: string]: {
    values: string[] | string;
    value?: string;
  };
};

export type CollectionQueryParams = {
  [key: string]: string | string[];
};

export interface SortByParams {
  field: SortByField;
  order: SortByOrder;
}

export type SortByField =
  | 'price'
  | 'productTypeName'
  | 'totalThcPercent'
  | 'brand'
  | 'subType'
  | 'classification'
  | 'generals'
  | 'effects'
  | 'flavors';

export type SortByOrder = 'asc' | 'desc';

export interface SortBySelectOption {
  label: string;
  value: SortByParams | 'default';
}

export interface ProductSuggestion {
  productId: string;
  name: string;
  seoProductName: string;
  type: string;
  brand: string;
}

export interface BrandSuggestion {
  productId: string;
  name: string;
  type: string;
  brand: string;
}

export interface SubtypeSuggestion {
  productId: string;
  name: string;
  type: string;
  brand: string;
}

export interface ClassificationSuggestion {
  productId: string;
  name: string;
  type: string;
  brand: string;
}
export interface TypeSuggestion {
  productId: string;
  name: string;
  type: string;
  brand: string;
}
export interface EffectSuggestion {
  productId: string;
  name: string;
  type: string;
  brand: string;
}
export interface FlavorSuggestion {
  productId: string;
  name: string;
  type: string;
  brand: string;
}

export interface GeneralSuggestion {
  productId: string;
  name: string;
  type: string;
  brand: string;
}

export interface SearchSuggestions {
  brands: BrandSuggestion[];
  subTypes: SubtypeSuggestion[];
  products: ProductSuggestion[];
  classifications: ClassificationSuggestion[];
  types: TypeSuggestion[];
  effects: EffectSuggestion[];
  flavors: FlavorSuggestion[];
  generals: GeneralSuggestion[];
}

interface TreezSpecialsDiscountRules {
  ruleCategory: string;
  ruleName: string;
  ruleValue: string;
}

export interface TreezSpecials {
  id: string;
  title: string;
  method: string;
  stackable: boolean;
  currentFlag: boolean;
  groups: string[];
  groupsRequired: string[];
  discountRules: TreezSpecialsDiscountRules[];
  cart: boolean;
  discountNumericType: string;
  requireCoupon: boolean;
  active: boolean;
  adjustment: boolean;
  rewardable: boolean;
  pos: boolean;
  brands: string[];
  categories: string[];
}

/* eslint-disable no-unused-vars */
export enum EnumCustomerType {
  ALL = 'ALL',
  ADULT = 'ADULT',
  MEDICAL = 'MEDICAL',
  NOT_SET = '', // When searching a product by search term the ALL attribute is not wokring for store with med/rec inventory separation
}

export type LastKey =
  | ({
      productId: string;
      customerType: string;
    } & AdditionalKey)
  | undefined;

export type PromoCarouselDocumentDataCarouselItemsItemWithID =
  PromoCarouselDocumentDataCarouselItemsItem & {
    id?: string;
    brands?: string[];
    categories?: string[];
  };

type AdditionalKey = { [_key in SortByField]?: string | number };
