import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import { TEMPLATE_BASE_PATH } from '@/constants';

/**
 * The project's Prismic repository name.
 */
export const repositoryName = prismic.getRepositoryName(
  process.env.NEXT_PUBLIC_PRISMIC_API_ENDPOINT ?? '',
);

const routes: prismic.ClientConfig['routes'] = [
  // homepage
  {
    type: 'homepage',
    path: '/',
  },

  // content
  {
    type: 'general_landing_page',
    path: '/:uid',
  },

  // blog
  { type: 'blog_post', path: `${TEMPLATE_BASE_PATH.BLOG}:uid` },
  {
    type: 'blog_page',
    path: `${TEMPLATE_BASE_PATH.BLOGS}`,
  },

  // Product listing pages
  { type: 'product_page', path: `${TEMPLATE_BASE_PATH.PRODUCT}:uid` },
  { type: 'product_listing_page', path: `${TEMPLATE_BASE_PATH.CATEGORY}:uid` },
  { type: 'brand_page', path: `${TEMPLATE_BASE_PATH.BRAND}:uid` },
  { type: 'strain_page', path: `${TEMPLATE_BASE_PATH.STRAIN}:uid` },
  { type: 'subcategory_page', path: `${TEMPLATE_BASE_PATH.STRAIN}:uid` }, // fix the path
  { type: 'brands_list_page', path: `${TEMPLATE_BASE_PATH.BRANDS}` },
  { type: 'search_page', path: `${TEMPLATE_BASE_PATH.SHOP}` },
  { type: 'deals_page', path: `${TEMPLATE_BASE_PATH.DEALS}` },

  // local seo
  {
    type: 'delivery_landing_page',
    path: `${TEMPLATE_BASE_PATH.CITY}:uid`,
  },
  { type: 'location_page', path: `${TEMPLATE_BASE_PATH.LOCATION}:uid` },
  { type: 'storelocator_page', path: `${TEMPLATE_BASE_PATH.STORE_LOCATOR}` },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */

export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(
    process.env.NEXT_PUBLIC_PRISMIC_API_ENDPOINT ?? '',
    {
      routes,
      fetchOptions:
        process.env.NODE_ENV === 'production'
          ? { next: { tags: ['prismic'] }, cache: 'force-cache' }
          : { next: { revalidate: 5 } },
      ...config,
    },
  );

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
