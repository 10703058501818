import React, { FC, useEffect, useState } from 'react';

import {
  Icon,
  IconSvg,
  Image,
  Rating,
  Rating as RatingReviews,
  Slider,
  Socials,
} from '@/components';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import { TestimonialsSlice } from '@/types';
import classNames from 'classnames';
import { SwiperOptions } from 'swiper/types';
import { Pagination } from 'swiper/modules';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';
import style from './testimonials.module.scss';

const Testimonials: FC<TestimonialsSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const background = slice?.primary?.background_color as string;
  const borderTop = slice?.primary?.border_section as string;
  const borderBottom = slice?.primary?.border_section as string;

  const sliceVariation = slice.variation ?? 'defaults';

  const showIcon = sliceVariation === 'default';
  const showControls =
    slice.variation === 'rating' ||
    slice.variation === 'ratingTopWithTitle' ||
    slice.variation === 'ratingTextCenter' ||
    slice.variation === 'ratingTop';

  const colorTheme = slice.primary?.color_theme ?? '';
  const paddingSection = slice?.primary?.padding_section;

  const getBreakpoints = (
    variation: string,
  ): {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  } => {
    switch (variation) {
      case 'ratingTextCenter':
        return {
          1024: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
          640: {
            slidesPerView: 2,
          },
        };
      case 'quoteWithHeading':
        return {
          1024: {
            slidesPerView: 2,
          },
          1536: {
            slidesPerView: 3,
          },
        };
      case 'featureLogos':
        return {
          1024: {
            slidesPerView: 2,
          },
        };
      default:
        return {};
    }
  };

  const renderLogos = () => {
    if (slice.variation !== 'featureLogos') {
      return;
    }

    const renderImage = image => {
      if (!image.url) {
        return null;
      }
      return (
        <div className={style.logo_image}>
          <Image
            alt={image?.alt}
            fill
            src={image?.url}
            style={{ objectFit: 'contain' }}
          />
        </div>
      );
    };
    return (
      <div className={style.testimonials__logos_container}>
        {slice?.primary?.logo_1 && renderImage(slice?.primary?.logo_1)}
        {slice?.primary?.logo_2 && renderImage(slice?.primary?.logo_2)}
        {slice?.primary?.logo_3 && renderImage(slice?.primary?.logo_3)}
        {slice?.primary?.logo_4 && renderImage(slice?.primary?.logo_4)}
        {slice?.primary?.logo_5 && renderImage(slice?.primary?.logo_5)}
        {slice?.primary?.logo_6 && renderImage(slice?.primary?.logo_6)}
      </div>
    );
  };

  const getSliderHeading = () => {
    if (
      slice.variation === 'quoteWithHeading' ||
      slice.variation === 'featureLogos'
    ) {
      const descriptionTextColor = slice?.primary?.description_text_color;

      return (
        <>
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: colorTheme,
            })}
            field={slice.primary?.section_title}
          />
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: descriptionTextColor,
            })}
            field={slice.primary?.section_description}
          />
        </>
      );
    }

    if (
      slice.variation === 'ratingTopWithTitle' ||
      slice.variation === 'ratingTextCenter' ||
      slice.variation === 'ratingTop'
    ) {
      return (
        <div
          className={
            slice.variation === 'ratingTop'
              ? style['location__rating_top-title']
              : style['location__reviews-title']
          }
        >
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: colorTheme,
            })}
            field={slice.primary.section_title}
          />
        </div>
      );
    }

    return undefined;
  };

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.testimonials__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      style={{ background, borderTop, borderBottom }}
    >
      <Slider
        breakpoints={getBreakpoints(slice?.variation)}
        className={classNames(style.testimonials, {
          [style.testimonials__default]: showIcon,
          [style.testimonials__rating]: sliceVariation === 'rating',
          [style.testimonials__rating_text_center]:
            sliceVariation === 'ratingTextCenter',
          [style.testimonials__quote_with_heading]:
            sliceVariation === 'quoteWithHeading',
          [style.testimonials__future_logo]: sliceVariation === 'featureLogos',
          [style.location__reviews]: slice.variation === 'ratingTopWithTitle',
          [style.testimonials_location__rating_top_title]:
            slice.variation === 'ratingTop',
        })}
        freeMode={slice.variation === 'ratingTopWithTitle'}
        modules={slice.variation !== 'ratingTopWithTitle' ? [Pagination] : []}
        pagination={{
          clickable: true,
        }}
        showSliderButtons={showControls}
        sliderHeading={getSliderHeading()}
        slidesPerView={
          slice.variation === 'ratingTopWithTitle' ||
          slice.variation === 'ratingTop'
            ? 'auto'
            : 1
        }
        spaceBetween={24}
      >
        {slice?.items?.map(
          (
            {
              description,
              description_title,
              full_name,
              image,
              social_1,
              social_2,
              social_3,
              position,
              rating,
            },
            index,
          ) =>
            RenderSliceVariation(
              description,
              description_title,
              full_name,
              image ?? {},
              social_1?.url ?? null,
              social_2?.url ?? null,
              social_3?.url ?? null,
              position,
              rating,
              `${sliceVariation}-${index}`,
              slice,
            ),
        )}
      </Slider>
      {renderLogos()}
    </section>
  );
};

const RenderSliceVariation = (
  description,
  description_title,
  full_name,
  image,
  social_1,
  social_2,
  social_3,
  position,
  rating,
  id,
  slice,
) => {
  const sliceVariation = slice.variation ?? 'defaults';
  const colorTheme = slice.primary?.color_theme ?? '';
  const descriptionTextColor = slice?.primary?.description_text_color;
  const nameTextColor = slice?.primary?.name_text_color;
  const positionTextColor = slice?.primary?.position_text_color;

  const showImgPerfil =
    sliceVariation === 'quoteWithHeading' || sliceVariation === 'featureLogos';
  const showSocials = sliceVariation === 'quoteWithHeading';
  const showIcon = sliceVariation === 'default';

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const [shouldShowButton, setShouldShowButton] = useState(false);

  const paragraphRef = React.useRef(undefined);

  // This logic is to check that the description text has more than 8 lines
  // in which case it will show the show more button
  useEffect(() => {
    if (paragraphRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(paragraphRef.current).lineHeight,
        10,
      );
      const maxHeight = lineHeight * 6;
      const actualHeight = (paragraphRef.current as any).scrollHeight;

      if (actualHeight > maxHeight) {
        setShouldShowButton(true);
      } else {
        setShouldShowButton(false);
      }
    }
  }, [description]);

  const getSocial = socials => {
    let socialLinks = {};

    socials.map(social => {
      if (!social) {
        return;
      }

      const url = new URL(social);
      const name = url.hostname.split('.')[0];

      socialLinks = {
        ...socialLinks,
        [name]: social,
      };
    });

    return socialLinks;
  };

  const networks = getSocial(new Array(social_1, social_2, social_3));

  if (slice.variation === 'ratingTopWithTitle') {
    const descriptionTitleTextColor =
      slice?.primary?.description_title_text_color;

    return (
      <div className={style['reviews-card']} key={id}>
        <div className={description_title[0] ? style.reviews_title : ''}>
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: descriptionTitleTextColor,
            })}
            field={description_title}
          />
        </div>
        <Rating className={style.card_stars} value={rating} />
        <blockquote className={style.card_desc}>
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: descriptionTextColor,
            })}
            field={description}
          />
        </blockquote>
        <div className={style.person_info}>
          <div className={style.person_img}>
            {image && image?.url ? (
              <PrismicNextImage fallbackAlt="" field={image} fill />
            ) : (
              <IconSvg name="user-profile" />
            )}
          </div>
          <div className={style.person_desc}>
            <div className={style.person_name}>
              <PrismicRichText
                components={htmlSerializerWithProps({
                  color: nameTextColor,
                })}
                field={full_name}
              />
            </div>
            <div className={style.person_position}>
              <PrismicRichText
                components={htmlSerializerWithProps({
                  color: positionTextColor,
                })}
                field={position}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (slice.variation === 'ratingTop') {
    return (
      <div className={style['reviews-card']} key={id}>
        <div className={style.rating_person_info}>
          <figure className={style.rating_person_img}>
            {image && image?.url ? (
              <PrismicNextImage fallbackAlt="" field={image} fill />
            ) : (
              <Icon name="perfil-user" />
            )}
          </figure>
          <div className={style.rating_person_desc}>
            <div className={style.person_name}>
              <PrismicRichText
                components={htmlSerializerWithProps({
                  color: nameTextColor,
                })}
                field={full_name}
              />
            </div>
            <Rating className={style.rating_card_stars} value={rating} />
          </div>
        </div>
        <blockquote className={style.card_desc}>
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: descriptionTextColor,
            })}
            field={description}
          />
        </blockquote>
      </div>
    );
  }

  return (
    <div
      className={style.card}
      key={id}
      style={{
        borderColor: colorTheme,
      }}
    >
      <blockquote className={style.info}>
        {slice.variation === 'quoteWithHeading' ? (
          <div className={style.description_container}>
            <p
              className={classNames(style.card_description, {
                [style.show_more]: isDescriptionExpanded,
              })}
              ref={paragraphRef as any}
              style={{ color: descriptionTextColor }}
            >
              {description[0]?.text}
            </p>
            {shouldShowButton && (
              <button
                className={style.show_more_button}
                onClick={() => {
                  setIsDescriptionExpanded(!isDescriptionExpanded);
                }}
              >
                {isDescriptionExpanded ? 'Show less' : 'Show more...'}
              </button>
            )}
          </div>
        ) : (
          <PrismicRichText
            components={htmlSerializerWithProps({
              color: descriptionTextColor,
            })}
            field={description}
          />
        )}

        {(sliceVariation === 'rating' ||
          sliceVariation === 'ratingTextCenter') && (
          <RatingReviews value={rating} />
        )}
        <div className={style.user}>
          <p style={{ color: nameTextColor }}>{full_name}</p>
          {!showSocials && (
            <span style={{ color: positionTextColor }}>{position}</span>
          )}
          {showSocials && (
            <Socials className={style.info_socials} networks={networks} />
          )}
        </div>
      </blockquote>
      {showImgPerfil && (
        <figure>
          {image && image?.url ? (
            <PrismicNextImage
              fallbackAlt=""
              field={image}
              fill
              style={{ objectFit: 'cover', objectPosition: 'center center' }}
            />
          ) : (
            <Icon name="perfil-user" />
          )}
        </figure>
      )}
      {(showIcon || showImgPerfil) && (
        <Icon
          className={style.quote__icon}
          name="quote"
          style={{ color: colorTheme }}
        />
      )}
    </div>
  );
};

export default Testimonials;
