import { CSSProperties, FC, useEffect, useState } from 'react';
import { useIsMobile, useScreenWidth, useSelectedStore } from '@/hooks';
import { PrismicNextImage } from '@prismicio/next';
import * as prismicT from '@prismicio/client';
import { Container, IconSvg, Image, Link } from '@/components';
import styles from './banner.module.scss';
import classNames from 'classnames';
import {
  alignContent,
  asText,
  convertHexadecimalFormatToRGB,
  getSlicePadding,
} from '@/utils';
import {
  BannerSlice,
  BannerSliceDefault,
  BannerSliceDefaultItem,
} from '@/types';

const DefaultVariation: FC<{
  slice: BannerSlice & BannerSliceDefault;
}> = ({ slice }) => {
  const { width } = useScreenWidth();
  const { isMobile } = useIsMobile(639);

  const { store: currentStore } = useSelectedStore();
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState<BannerSliceDefaultItem>();

  const primary = slice?.primary;
  const items = slice?.items;

  useEffect(() => {
    if (currentStore?.url_slug) {
      const locationBanner = items?.find(
        banner =>
          banner?.active && banner.location_slug === currentStore?.url_slug,
      );

      if (locationBanner) {
        setBanner(locationBanner);
        return;
      }
    }

    const defaultBanner = items?.find(
      banner => banner?.active && !banner.location_slug,
    );

    if (defaultBanner) {
      setBanner(defaultBanner);
      return;
    }

    setLoading(false);
  }, [items, currentStore]);

  if (!slice || !primary?.active) {
    return null;
  }

  const {
    background_color,
    border_radius,
    bottom_spacing,
    full_width,
    max_height,
    max_height_mobile,
  } = primary;

  const placeholder = '/images/brands/ambient-placeholder-treez.png';

  const paddingSection = slice?.primary?.padding_section;

  const contentPaddingOnBorders =
    (banner?.horizontal_position === 'start' ||
      banner?.horizontal_position === 'end') &&
    !full_width &&
    width > 640
      ? '40px'
      : '';

  const sizes = `(min-width: 2048px)  2008px, (min-width: 1536px)  1496px,
        (min-width: 1280px)  1240px, (min-width: 1024px) 984px,
        (min-width: 768px) 728px, 100vw`;

  const borderRadius = width > 640 && !full_width ? `${border_radius}px` : 0;

  const bannerActive = banner?.active || false;

  const title = banner?.title as prismicT.RichTextField;

  const buttonCTA = banner?.cta_button_text;

  const description = banner?.description as prismicT.RichTextField;

  const backgroundColor = bannerActive && background_color;

  const ctaBackgroundColor = banner?.cta_background_color ?? '';
  const ctaBackgroundColorHover = banner?.cta_background_color_hover ?? '';
  const ctaTextColor = banner?.cta_text_color ?? '';
  const ctaTextColorHover = banner?.cta_text_color_hover ?? '';
  const borderStyle = banner?.cta_border_style;
  const borderStyleHover = banner?.cta_border_style_hover;
  const buttonBorderRadius = banner?.cta_border_radius
    ? `${banner?.cta_border_radius}px`
    : undefined;

  const background = banner?.background_color
    ? convertHexadecimalFormatToRGB(banner?.background_color)
    : null;

  const getMaxHeight = () => {
    const paddingTop = full_width ? '22.27%' : '23.75%';

    if (width > 640 && max_height) {
      return `min(${max_height}px, ${paddingTop})`;
    }

    if (max_height_mobile && width < 640) {
      return `min(${max_height_mobile}px, ${paddingTop})`;
    }

    return;
  };

  const getMaxWidth = (maxWidthMobile, maxWhidth) => {
    if (width < 640) {
      return maxWidthMobile ? `${maxWidthMobile}%` : undefined;
    }

    return maxWhidth ? `${maxWhidth}%` : undefined;
  };

  const renderImage = (banner, sizes, classCss) => {
    return (
      <figure
        className={classNames(classCss, {
          [styles.placeholder]: !bannerActive,
        })}
        style={{
          borderRadius,
          backgroundColor: backgroundColor as string,
          paddingTop: getMaxHeight(),
        }}
      >
        {bannerActive && banner?.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={banner}
            fill
            priority
            sizes={sizes}
            style={{
              borderRadius,
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        ) : (
          bannerActive &&
          !background_color && (
            <Image
              alt="strain page banner"
              fill
              src={placeholder}
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          )
        )}
        {!bannerActive && !loading && (
          <div>
            <IconSvg name="image" />
            <p>
              <b>There is no active banner</b>
              <br />
              Kindly establish an active banner
            </p>
          </div>
        )}
      </figure>
    );
  };

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        styles.page_banner__section,
        getSlicePadding(paddingSection),
        {
          [styles.page_banner__section_fullwidth]: full_width,
          [styles.page_banner__section__spacing_bottom]: bottom_spacing,
        },
      )}
    >
      <Container
        className={classNames(styles.page_banner__container, {
          [styles.page_banner__container_fullwidth]: full_width,
        })}
      >
        {renderImage(banner?.image, sizes, styles.page_banner__desktop)}
        {renderImage(
          banner?.image?.mobile,
          '(min-width: 640px) 610px, 100wv',
          styles.page_banner__mobile,
        )}
        {bannerActive && (
          <span className={styles.overlay__container}>
            <span
              className={styles.overlay}
              style={{
                backgroundColor: banner?.background_color
                  ? `rgba(${background?.r}, ${background?.g}, ${background?.b}, ${banner?.opacity})`
                  : undefined,
              }}
            />
          </span>
        )}

        {(description || title || buttonCTA) && (
          <Container
            className={styles.page__banner_body}
            style={{
              alignItems:
                isMobile && banner?.center_align_content_mobile
                  ? 'center'
                  : alignContent(banner?.horizontal_position),
              justifyContent: alignContent(
                banner?.vertical_position ?? 'center',
              ),
              maxWidth: banner?.content_width
                ? `${banner?.content_width}%`
                : undefined,
            }}
          >
            <div
              className={classNames(styles.page__banner_text, {
                [styles.text__align_centered_mobile]:
                  banner?.center_align_content_mobile,
                [styles.text__with_maximum_height]: max_height,
              })}
              style={{
                alignItems: alignContent(
                  banner?.horizontal_position ?? 'start',
                ),
                textAlign: banner?.text_alignment ?? 'left',
                paddingLeft: contentPaddingOnBorders,
                paddingRight: contentPaddingOnBorders,
              }}
            >
              {banner?.title[0]?.type === 'heading1' ? (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: asText(title),
                  }}
                  style={{
                    color: banner?.title_color as string,
                    maxWidth: getMaxWidth(
                      banner?.title_max_width_mobile,
                      banner?.title_max_width,
                    ),
                  }}
                />
              ) : (
                <h2
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                  style={{
                    color: banner?.title_color as string,
                    maxWidth: getMaxWidth(
                      banner?.title_max_width_mobile,
                      banner?.title_max_width,
                    ),
                  }}
                />
              )}
              {asText(description) && (
                <p
                  className={styles.page__banner_description}
                  dangerouslySetInnerHTML={{
                    __html: asText(description),
                  }}
                  style={{
                    color: banner?.description_color as string,
                    maxWidth: getMaxWidth(
                      banner?.description_max_width_mobile,
                      banner?.description_max_width,
                    ),
                  }}
                />
              )}
              {banner?.cta_button_text && (
                <Link
                  className={classNames(styles.banner_content__action, {
                    [styles.banner_content__action_hidde_mobile]:
                      banner?.hidden_button_mobile,
                  })}
                  href={banner?.link ?? ''}
                  style={
                    {
                      '--cta-background-color': ctaBackgroundColor,
                      '--cta-background-color-hover': ctaBackgroundColorHover,
                      '--cta-text-color': ctaTextColor,
                      '--cta-text-color-hover': ctaTextColorHover,
                      '--border-style': borderStyle,
                      '--border-style-hover': borderStyleHover,
                      '--border-radius': buttonBorderRadius,
                    } as CSSProperties
                  }
                >
                  {banner?.cta_button_text}
                </Link>
              )}
            </div>
          </Container>
        )}
      </Container>
    </section>
  );
};

export default DefaultVariation;
