import { useEffect, useState } from 'react';
import { differenceInSeconds, parse } from 'date-fns';

const i = s => {
  return s > 0 ? s * 1000 : 0;
};

const useIsTimeWithinTimeInterval = ({
  start,
  end,
}: {
  start: string;
  end: string;
}): boolean => {
  const [isInTimeInterval, setIsInTimeInterval] = useState(false);

  useEffect(() => {
    if (!start || !end) {
      setIsInTimeInterval(false);
      return;
    }

    const now = new Date();
    const startTime = parse(start, 'HH:mm', now);
    const endTime = parse(end, 'HH:mm', now);

    const secondsToStart = differenceInSeconds(startTime, now);
    const t1 = setTimeout(() => {
      setIsInTimeInterval(true);
    }, i(secondsToStart));

    const secondsToend = differenceInSeconds(endTime, now);
    const t2 = setTimeout(() => {
      setIsInTimeInterval(false);
    }, i(secondsToend));

    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, [start, end]);

  return isInTimeInterval;
};

export { useIsTimeWithinTimeInterval };
