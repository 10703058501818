export const orderStatus = {
  delivery: [
    {
      color: '#FFD952',
      icon: 'cart',
      label: 'Pending',
      name: 'PENDING',
      tab: 1,
    },
    {
      color: '#56afea',
      icon: 'car',
      label: 'Started',
      name: 'DELIVERY_STARTED',
      tab: null,
    },
    {
      color: '#2b5cb0',
      icon: 'arrived-icon',
      label: 'Arrived',
      name: 'DELIVERY_FINISHED',
      tab: null,
    },
  ],
  pickup: [
    {
      color: '#FFD952',
      icon: 'cart',
      label: 'Pending',
      name: 'PENDING',
      tab: 1,
    },
    {
      color: '#4b5e66',
      icon: 'car',
      label: 'Processing',
      name: 'PROCESSING',
      tab: 2,
    },
    {
      color: '#03c004',
      icon: 'ready-icon',
      label: 'Ready',
      name: 'READY',
      tab: null,
    },
  ],
};

export const orderLastStatus = [
  {
    color: '#03c004',
    default: true,
    icon: 'home',
    label: 'Delivered',
    name: 'DELIVERED',
    tab: 6,
  },
  {
    color: '#E63E3E',
    default: false,
    icon: 'close',
    label: 'Declined',
    name: 'DECLINED',
    tab: null,
  },
  {
    color: '#bd68cc',
    default: false,
    icon: 'close',
    label: 'Refunded',
    name: 'REFUNDED',
    tab: null,
  },
];
