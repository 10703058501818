'use client';

import { FC, memo } from 'react';

import classNames from 'classnames';
import { PrismicNextImage } from '@prismicio/next';

import { useUiStore } from '@/data';
import { Container, Link, useData } from '@/components';

import style from './simpleheader.module.scss';
import { useIsMobile } from 'hooks/useIsMobile';

const SimpleHeader: FC = memo(() => {
  const { header } = useData();
  const { isMobile } = useIsMobile(1024);
  const [state] = useUiStore();

  const logo =
    isMobile && header?.data?.mobile_logo?.url
      ? header?.data?.mobile_logo
      : header?.data?.logo;

  return (
    <>
      <header
        className={classNames(style['main-header'], {
          [style['main-header_p-open']]: state.is_promotionbar_open,
        })}
      >
        <Container className={style.container__logo}>
          <Link href="/">
            <figure>
              <PrismicNextImage
                fallbackAlt=""
                field={logo}
                style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
              />
            </figure>
          </Link>
        </Container>
      </header>
    </>
  );
});

export default SimpleHeader;
