import { FC } from 'react';
import { PrismicLink } from '@prismicio/react';
import classNames from 'classnames';
import { getSlicePadding } from '@/utils';
import { useShowByLocationSlug } from '@/hooks';
import { CenteredButtonSlice } from '@/types';
import { Link } from '@/components';
import styles from './centeredbutton.module.scss';

const CenteredButton: FC<CenteredButtonSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(styles.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
    >
      <span
        style={{
          background: slice?.primary?.button_color ?? '',
          color: slice?.primary?.button_text_color ?? '',
        }}
      >
        {slice?.primary?.internal_link ? (
          <Link href={slice?.primary?.internal_link}>
            {slice?.primary?.button_text}
          </Link>
        ) : (
          <PrismicLink field={slice?.primary?.external_link}>
            {slice?.primary?.button_text}
          </PrismicLink>
        )}
      </span>
    </section>
  );
};

export default CenteredButton;
