import React from 'react';

import { ArticleJsonLdProps } from '@/types';

import { JsonLd } from './jsonld';
import { setAuthor } from '../schemas/setAuthor';
import { setPublisher } from '../schemas/setPublisher';

function ArticleJsonLd({
  type = 'Article',
  keyOverride,
  url,
  title,
  images,
  datePublished,
  dateModified,
  authorName,
  publisherName = undefined,
  publisherLogo = undefined,
  description,
}: ArticleJsonLdProps) {
  const data = {
    author: setAuthor(authorName),
    dateModified: dateModified || datePublished,
    datePublished,
    description,
    headline: title,
    image: images,
    mainEntityOfPage: {
      '@id': url,
      '@type': 'WebPage',
    },
    publisher: setPublisher(publisherName, publisherLogo),
  };
  return (
    <JsonLd
      keyOverride={keyOverride}
      type={type}
      {...data}
      scriptKey="article"
    />
  );
}

export default ArticleJsonLd;
