import { useQuery } from '@apollo/client';
import { SEARCH_PRODUCT_QUERY } from './schema';

export function useSearchProduct(options) {
  const { data, error, loading, fetchMore } = useQuery(
    SEARCH_PRODUCT_QUERY,
    options,
  );

  return {
    loading: loading,
    data: data?.searchProduct,
    error: error,
    fetchMore: fetchMore,
  };
}
