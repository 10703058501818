import { FC } from 'react';

import { Container, Heading, Icon, Link } from '@/components';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { RoundShapedPicturesWithDescriptionSlice as RounderShaped } from '@/types';
import classNames from 'classnames';
import style from './roundshapedpictureswithdescription.module.scss';

import { PrismicNextImage } from '@prismicio/next';
import { ImageFieldImage } from '@prismicio/client';
import { getSlicePadding } from '@/utils';
/* eslint-disable-next-line max-len */
export const RoundShapedPicturesWithDescription: FC<RounderShaped> = slice => {
  const marginBottom = slice?.primary?.border_bottom;
  const paddingSection = slice?.primary?.padding_section;

  const sizes =
    /* eslint-disable-next-line max-len */
    '(min-width: 2048px) 446px, (min-width: 1536px) 378px, (min-width: 1280px) 294px, (min-width: 1024px) 275px, (min-width: 768px) 190px, (min-width: 640px) 147px, 30wv';

  const renderHeadingSlice = (
    text: [],
    level: number,
    color: string,
    classCSS: string,
  ) => {
    if (!prismicH.asText(text)) {
      return null;
    }

    return (
      <Heading className={classCSS} color={color} level={level}>
        {prismicH.asText(text)}
      </Heading>
    );
  };

  const renderDescriptionSlice = (description: [], color: string) => {
    if (!prismicH.asText(description)) {
      return null;
    }

    return (
      <PrismicRichText
        components={{
          paragraph: ({ children }) => (
            <p
              style={{
                color: color,
              }}
            >
              {children}
            </p>
          ),
        }}
        field={description}
      />
    );
  };

  const renderCTA = (
    type: string,
    link: string,
    text: string,
    background: string,
    textColor: string,
  ) => {
    if (!text) {
      return null;
    }

    return (
      <Link
        className={classNames({
          [style.body__button_primary]: type === 'Button',
        })}
        href={link}
        style={{
          background: background,
          color: textColor,
        }}
      >
        {text}
      </Link>
    );
  };

  const renderSimpleImage = (
    image: ImageFieldImage,
    borderRadius: boolean,
    sizes: string,
    classCss: string,
  ) => {
    return (
      <figure
        className={classNames(classCss, {
          [style.body__figure_border_radius_under_sm]: borderRadius,
        })}
      >
        {image?.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={image}
            fill
            sizes={sizes}
            style={{ objectFit: 'cover', objectPosition: 'center center' }}
          />
        ) : (
          <Icon name="image" />
        )}
      </figure>
    );
  };
  /* eslint-disable max-len */
  const getVariation = slice => {
    if (
      slice.variation === 'default' ||
      slice.variation === 'ceteredIconRow' ||
      slice.variation === 'singleIconWithTitle' ||
      slice.variation === 'centerIconDescription'
    ) {
      return (
        <div
          className={style.content}
          style={{
            background: slice.primary?.background,
          }}
        >
          <div
            className={classNames(style.body, {
              [style.body__default]: slice.variation === 'default',
              [style.body__center_icon_row]:
                slice.variation === 'ceteredIconRow',
              [style.body__center_icon_row_single]:
                slice.variation === 'centerIconDescription',
              [style.body__center_icon_row_single_title]:
                slice.variation === 'singleIconWithTitle',
            })}
          >
            <div className={style.content__text}>
              {renderHeadingSlice(
                slice.primary?.title,
                2,
                slice.primary?.title_color,
                style.body__title,
              )}
              {renderHeadingSlice(
                slice.primary?.heading,
                3,
                slice.primary?.heading_color,
                style.body__text_heading,
              )}
            </div>
            {(slice.variation === 'singleIconWithTitle' ||
              slice.variation === 'centerIconDescription') &&
              renderSimpleImage(
                slice.primary?.image,
                false,
                '104px',
                style.body__figure_single,
              )}
            {slice.variation != 'singleIconWithTitle' &&
              slice.variation != 'centerIconDescription' && (
                <div
                  className={classNames(style.body__contents_images, {
                    [style.body__contents_images_small]:
                      slice.variation === 'ceteredIconRow',
                  })}
                >
                  {slice.items.map((img, index: number) => {
                    return (
                      <div className={style.body__item} key={index}>
                        <figure
                          className={classNames({
                            [style.body__figure_round]:
                              slice.variation === 'default',
                            [style.body__figure_icon_multiple]:
                              slice.variation === 'ceteredIconRow',
                          })}
                        >
                          {img.image?.url ? (
                            <PrismicNextImage
                              fallbackAlt=""
                              field={img.image}
                              fill
                              sizes={sizes}
                              style={{
                                objectFit: 'cover',
                                objectPosition: 'center center',
                              }}
                            />
                          ) : (
                            <Icon name="image" />
                          )}
                        </figure>
                        {renderHeadingSlice(
                          img.title,
                          4,
                          slice.primary?.item_title_color,
                          style.body__item_title,
                        )}
                        {renderDescriptionSlice(
                          img.description,
                          slice.primary?.item_description_color,
                        )}
                        {renderCTA(
                          slice.primary?.item_button_type,
                          img.button_link,
                          img.button_text,
                          slice.primary?.item_button_background_color,
                          slice.primary?.item_button_text_color,
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            {renderDescriptionSlice(
              slice.primary?.description,
              slice.primary?.description_color,
            )}
          </div>
        </div>
      );
    }

    if (
      slice.variation === 'leftAlignedIcon' ||
      slice.variation === 'leftAlignedIconLargeHeadline' ||
      slice.variation === 'rightAlignedIconLargeHeadline'
    ) {
      return (
        <div
          className={style.content}
          style={{
            background: slice.primary?.background,
          }}
        >
          <div
            className={classNames(style.body, {
              [style.body__left_aligned_large]:
                slice.variation === 'leftAlignedIcon',
              [style.body__left_aligned_large_headline]:
                slice.variation === 'leftAlignedIconLargeHeadline',
              [style.body__right_aligned_large]:
                slice.variation === 'rightAlignedIconLargeHeadline',
            })}
          >
            <div className={style.content__text_info}>
              {prismicH.asText(slice.primary?.heading) &&
                renderHeadingSlice(
                  slice.primary?.heading,
                  3,
                  slice.primary?.heading_color,
                  style.body__text_heading,
                )}
              <div className={style.content__header}>
                {renderHeadingSlice(
                  slice.primary?.title,
                  2,
                  slice.primary?.title_color,
                  style.body__title,
                )}
                {renderDescriptionSlice(
                  slice.primary?.description,
                  slice.primary?.description_color,
                )}
              </div>
              {renderCTA(
                slice.primary?.button_type,
                slice.primary?.button_link,
                slice.primary?.button_text,
                slice.primary?.button_background,
                slice.primary?.button_text_color,
              )}
            </div>
            {renderSimpleImage(
              slice.primary?.image,
              false,
              '104px',
              style.body__figure_single,
            )}
          </div>
        </div>
      );
    }
  };
  /* eslint-enable max-len */

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      as="section"
      className={classNames(style.container, getSlicePadding(paddingSection), {
        ['spacing_bottom']: marginBottom,
      })}
    >
      {getVariation(slice)}
    </Container>
  );
};
