'use client';
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import Script from 'next/script';
import { FC } from 'react';

/** 🚨🚨🚨🚨🚨
 * THIS COMPONENT CAN ONLY BE WHITIN THE
 * LAYOUTS DEFINITIONS IN APP DIRECTORY
 */

import { SURFSIDE_APP_ID } from '@/constants';

const SurfsideAnalytics: FC = () => {
  const ACCOUNT_ID = process?.env?.NEXT_PUBLIC_SURFSIDE_ACCOUNT_ID ?? '';
  const SOURCE_ID = process?.env?.NEXT_PUBLIC_SURFSIDE_SOURCE_ID ?? '';
  const DEFAULT_SELECTED_LOCATION =
    process?.env?.NEXT_PUBLIC_TREEZ_DEFAULT_STORE_NAME ?? '';

  return (
    <>
      <Script id="surf-ads" strategy="beforeInteractive">
        {`(function() {
          var script = document.createElement('script');
          script.src = "https://cdn.surfside.io/ads/2.0.0/r.js";
          document.head.appendChild(script);
      })();`}
      </Script>

      <Script id="surf-tracking" strategy="beforeInteractive">
        {`;(function(s,u,r,f,i,n,g){
          if(!s[i]){
            s.surfContext = s.surfContext || [];
            s.surfContext.push(i);
            s[i] = function() {
                (s[i].q = s[i].q || []).push(arguments)
            };
            s[i].q = s[i].q || [];
            n = u.createElement(r);
            g = u.getElementsByTagName(r)[0];
            n.async = 1;
            n.src = f;
            g.parentNode.insertBefore(n, g);
        }
        }(window, document, "script", "//cdn.surfside.io/sdk/1.0.0/edge.js", "surf"));

        surf('newTracker', '${SURFSIDE_APP_ID}', '//col.surfside.io', {
            appId: '${SURFSIDE_APP_ID}',
            cookieName: 'surf',
            discoverRootDomain: true,
            cookieSameSite: 'Lax',
        });

        surf('setLocation', { id: "${DEFAULT_SELECTED_LOCATION}" });

        surf('source', {
            accountId: '${ACCOUNT_ID}',
            sourceId: '${SOURCE_ID}',
        });

        surf('enableActivityTracking', {
            minimumVisitLength: 30,
            heartbeatDelay: 10,
        });

        surf('enableLinkClickTracking');
        surf('trackPageView');`}
      </Script>
    </>
  );
};

export default SurfsideAnalytics;
