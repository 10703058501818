import { MedRecSwitch, SearchHeader, StoreLocator } from '@/components';

import {
  HeaderBurgerNav,
  HeaderContainer,
  HeaderFavorites,
  HeaderLogo,
  HeaderNav,
  HeaderProfile,
  HeaderRow,
  HeaderSection,
  HeaderShopping,
} from '../Header.components';

const HeaderWithCenteredLogoAndLocator = () => (
  <>
    <HeaderWithCenteredLogoAndLocatorMobile />
    <HeaderWithCenteredLogoAndLocatorDesktop />
  </>
);

const HeaderWithCenteredLogoAndLocatorMobile = () => (
  <HeaderContainer.Mobile>
    <HeaderRow type="small">
      <HeaderSection justify="center" spaceBetweenOnMultipleChildren>
        <StoreLocator />
        <MedRecSwitch />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow borderless type="small">
      <HeaderSection justify="start">
        <HeaderBurgerNav />
        <HeaderLogo fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <SearchHeader withEmbeddedInput />
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Mobile>
);

const HeaderWithCenteredLogoAndLocatorDesktop = () => (
  <HeaderContainer.Desktop>
    <HeaderRow borderless type="small">
      <HeaderSection justify="center">
        <StoreLocator />
        <MedRecSwitch />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow type="small" withNavbar>
      <HeaderSection justify="start">
        <HeaderNav />
      </HeaderSection>
      <HeaderSection justify="center">
        <HeaderLogo center fixHeight fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <SearchHeader withEmbeddedInput />
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Desktop>
);

export { HeaderWithCenteredLogoAndLocator };
