'use client';

import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NotificationContainer() {
  return (
    <ToastContainer
      autoClose={3000}
      closeOnClick
      draggable
      hideProgressBar
      newestOnTop
      pauseOnFocusLoss
      pauseOnHover
      position="bottom-center"
      rtl={false}
      theme="colored"
      transition={Slide}
    />
  );
}

export default NotificationContainer;
