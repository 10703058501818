import { FC, memo } from 'react';
import classNames from 'classnames';

import { useUiStoreSelector } from '@/data';
import { PAGE_ROUTES } from '@/constants';

import PopoverPanel from '../PopoverPanel';
import Container from '../Container/Container';
import { SearchProvider } from './context';
import {
  CustomSearchBox,
  CustomSearchIcon,
  CustomSearchInput,
  SearchResultsBody,
} from './SearchHeader.components';

import styles from './searchheader.module.scss';

interface ISearchheaderProps {
  seeAllHref?: string;
  withEmbeddedInput?: boolean;
  className?: string;
}

const SearchHeader: FC<ISearchheaderProps> = memo(
  ({ seeAllHref = PAGE_ROUTES.SHOP, withEmbeddedInput, className }) => {
    const { is_search_open, setState } = useUiStoreSelector(['is_search_open']);

    return (
      <SearchProvider>
        {withEmbeddedInput ? <CustomSearchIcon /> : <CustomSearchInput />}
        <PopoverPanel
          className={classNames(
            styles.search__container,
            {
              [styles.embedded_input]: withEmbeddedInput,
            },
            className,
          )}
          ignoreOutsideClick
          isOpen={is_search_open}
          toggle={() => setState({ is_search_open: !is_search_open })}
        >
          {withEmbeddedInput && <div className={styles.line} />}
          <Container className={styles.search__body}>
            {withEmbeddedInput && <CustomSearchBox />}
            <SearchResultsBody
              closeButtonHidden={withEmbeddedInput}
              seeAllHref={seeAllHref}
            />
          </Container>
        </PopoverPanel>
      </SearchProvider>
    );
  },
);

export default SearchHeader;
