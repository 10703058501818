import { FC } from 'react';

import DefaultVariation from './DefaultVariation';
import WithLogoOnBorderVariation from './WithLogoOnBorderVariation';

import { BannerSlice } from '@/types';

const Banner: FC<{ slice?: BannerSlice }> = ({ slice }) => {
  if (!slice) {
    return null;
  }

  if (slice.variation === 'withLogoOnBorder') {
    return <WithLogoOnBorderVariation slice={slice} />;
  }

  return <DefaultVariation slice={slice} />;
};

export default Banner;
