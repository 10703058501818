import { Metadata } from 'next';
import { OpenGraph } from 'next/dist/lib/metadata/types/opengraph-types';

import {
  ArticleJsonLdProps,
  BlogPostDocument,
  BreadCrumbJsonLdProps,
  CarouselJsonLdProps,
  CustomerReview,
  GeneralCardSlice,
  IBreadcrumbItem,
  ItemListElements,
  LocalBusinessJsonLdProps,
  Product,
  ProductJsonLdProps,
  SeoContentSlice,
  TreezStore,
  TSeoProps,
  TwitterCardSlice,
} from '@/types';
import { TEMPLATE_BASE_PATH } from '@/constants';
import {
  createLocationUrl,
  currencyFormat,
  getTreezSeoAddress,
  getTreezSeoOpeningHours,
} from '@/utils';
import { Twitter } from 'next/dist/lib/metadata/types/twitter-types';

const seoContentBuilder = ({
  generalCard,
  seoContent,
  twitterCard,
}: ISeo): Metadata => ({
  description: seoContent?.primary?.description,
  openGraph: getOpenGraph(generalCard),
  title: seoContent?.primary?.title,
  twitter: getTwitterCard(twitterCard, generalCard?.primary?.site_name ?? ''),
});

type TSeoEl = TwitterCardSlice | SeoContentSlice | GeneralCardSlice;

const getSeoProps = (body: TSeoProps): ISeo => {
  const seo: ISeo = {};

  if (!body || typeof body !== 'object') {
    return seo;
  }

  Object?.values(body)?.forEach((el: TSeoEl) => {
    switch (el?.slice_type) {
      case 'seo_content':
        seo.seoContent = el;
        break;

      case 'general_card':
        seo.generalCard = el;
        break;

      case 'twitter_card':
        seo.twitterCard = el;
        break;

      default:
        break;
    }
  });

  return seo;
};

const checkEnvNoIndexNoFollow = () => {
  // this code is really dangerous it is for not tracking boot indexing
  // on the dev environments
  // https://developers.google.com/search/docs/crawling-indexing/block-indexing
  if (process.env.ENV && process.env.ENV === 'dev') {
    return { nofollow: true, noindex: true };
  }
  return null;
};

const getTwitterCard = (
  twitterCard?: TwitterCardSlice,
  siteName?: string,
): Twitter | undefined => {
  if (!twitterCard) {
    return undefined;
  }

  return {
    // TODO: add this types to prismic slice
    card: 'summary_large_image',
    images: [
      {
        alt: twitterCard.primary.image?.alt ?? '',
        height: twitterCard.primary.image?.dimensions?.height ?? 630,
        type: getImageTypeFromUrl(twitterCard.primary.image?.url),
        url: twitterCard.primary.image?.url ?? '',
        width: twitterCard.primary.image?.dimensions?.width ?? 1200,
      },
    ],
    site: siteName,
  };
};

const getOpenGraph = (
  generalCard?: GeneralCardSlice,
): OpenGraph | undefined => {
  if (!generalCard) {
    return undefined;
  }

  return {
    description: generalCard.primary?.description ?? '',
    images: [
      {
        alt: generalCard.primary?.image?.alt ?? '',
        height: generalCard.primary?.image?.dimensions?.height ?? 1250,
        url: generalCard.primary?.image?.url ?? '',
        width: generalCard.primary?.image?.dimensions?.width ?? 870,
      },
    ],
    siteName: generalCard.primary?.site_name ?? '',
    title: generalCard.primary?.title ?? '',
    type: 'website',
  };
};

const getProductOffer = (product: Product, storeName: string) => {
  return {
    availability: `http://schema.org/${
      product.in_stock ? 'InStock' : 'OutOfStock'
    }`,
    itemCondition: 'NewCondition',
    price: `${currencyFormat((product?.variants ?? [])[0]?.price ?? 100)}`,
    priceCurrency: 'USD',
    seller: {
      name: storeName,
    },
    url: `${process.env.NEXT_PUBLIC_STORE_DOMAIN}${product?.url}`,
  };
};

const seoCarouselJsonLdBuilder = (
  products: Product[],
  storeName: string,
): CarouselJsonLdProps => {
  const data = products?.map((p: Product) => {
    const ratingValue = p?.review_rating! < 1 ? 1 : p?.review_rating;
    const reviewCount = p?.reviews;
    const prop: ProductJsonLdProps = {
      aggregateRating: {
        ratingValue: `${ratingValue}`,
        ...(reviewCount! > 0 ? { reviewCount: `${reviewCount}` } : {}),
      },
      brand: p?.brand_name ?? '',
      description: p?.description ?? '',
      name: p?.name ?? '',
      // sku: p?.sku,
      offers: getProductOffer(p, storeName),
    };

    if (p?.asset?.image) {
      prop.image = p?.asset?.image;
    }

    return prop;
  });

  return { data, ofType: 'product' };
};

const seoBreadCrumbJsonLdBuilder = (
  items: IBreadcrumbItem[],
): BreadCrumbJsonLdProps => {
  return {
    itemListElements: items.map(
      (i: IBreadcrumbItem, index: number): ItemListElements => {
        return {
          ...(i.href ? { item: i.href } : {}), // TODO add base domain
          name: i.label,
          position: index + 1,
        };
      },
    ),
  };
};

const seoArticleJsonLdBuilder = (
  page: BlogPostDocument<string>,
): ArticleJsonLdProps => {
  const domain: string = process?.env?.NEXT_PUBLIC_STORE_DOMAIN ?? '';
  const blog: string = TEMPLATE_BASE_PATH?.BLOG;

  return {
    authorName: '',
    dateModified: page.last_publication_date,
    datePublished: page.first_publication_date,
    description: page?.data?._seo_content_?.[0]?.primary?.description ?? '',
    images: [],
    title: page?.data?._seo_content_?.[0]?.primary?.title ?? '',
    url: `${domain}/${blog}${page.uid}`,
  };
};

const seoProductJsonLdBuilder = (
  product: Product,
  reviews: CustomerReview[],
  storeName: string,
): ProductJsonLdProps => {
  const ratingValue = product?.review_rating! < 1 ? 1 : product.review_rating;
  const reviewCount = reviews.length;
  return {
    aggregateRating: {
      ratingValue: `${ratingValue}`,
      ...(reviewCount > 0 ? { reviewCount: `${reviewCount}` } : {}),
    },
    brand: product?.brand_name ?? '',
    description: product?.description ?? '',
    image: product.asset?.image ?? '',
    name: product.name ?? '',
    offers: getProductOffer(product, storeName),
    reviews: reviews.map(review => ({
      author: review.nickname,
      datePublished: review.created_at,
      reviewBody: review.comment,
      reviewRating: { ratingValue: `${ratingValue}` },
    })),
    // sku: product?.sku,
    type: product?.product_type ?? 'Product',
  };
};

const seoLocalBusinessJsonLdBuilder = (
  store: TreezStore,
): LocalBusinessJsonLdProps => {
  const ratingValue = store.rating < 1 ? 1 : store.rating;
  const reviewCount = store.reviews_count;
  return {
    address: getTreezSeoAddress(store),
    description: store.description,
    geo: {
      latitude: `${store._geoloc.lat}`,
      longitude: `${store._geoloc.lng}`,
    },
    id: store.objectID,
    name: store.name,
    openingHours: getTreezSeoOpeningHours(store),
    rating: {
      ratingValue: `${ratingValue}`,
      ...(reviewCount > 0 ? { reviewCount: `${reviewCount}` } : {}),
    },
    telephone: store.phone,
    type: 'Store',
    url: createLocationUrl(store.url_slug),
  };
};

const getImageTypeFromUrl = (url?: string | null) => {
  if (!url) {
    return undefined;
  }

  const regex = /(?<=\.)[^.?]*(?=\?)/;
  const match = url.match(regex);
  return `image/${match?.[0]}`;
};

export interface ISeo {
  generalCard?: GeneralCardSlice;
  twitterCard?: TwitterCardSlice;
  seoContent?: SeoContentSlice;
}

export {
  seoArticleJsonLdBuilder,
  seoContentBuilder,
  seoCarouselJsonLdBuilder,
  seoBreadCrumbJsonLdBuilder,
  seoProductJsonLdBuilder,
  seoLocalBusinessJsonLdBuilder,
  checkEnvNoIndexNoFollow,
  getSeoProps,
};
