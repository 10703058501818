import {
  IPrismicSlice,
  ProductGroupPageDocumentDataSlicesSlice,
  ProductListingPageDocumentDataSlicesSlice,
  SortBySelectOption,
} from '@/types';
import { MenuService, SearchProductsRequest } from '@/data';

let slice: IPrismicSlice;

type SliceType = typeof slice.slice_type;

const SORT_BY_MAP = {
  default: undefined,
  'Price (desc)': { value: { field: 'price', order: 'desc' } },
  'Price (asc)': { value: { field: 'price', order: 'asc' } },
};

export type SliceItems = {
  [key in SliceType]?: Extract<IPrismicSlice, { slice_type: key }>;
};

const getPrismicSlicesItems = (body: IPrismicSlice[]) => {
  let slices: SliceItems = {};

  Object.keys(body).forEach((key: string) => {
    const slice: IPrismicSlice = body[key];

    const value: SliceItems = { [slice.slice_type]: slice };

    slices = { ...slices, ...value };
  });

  return slices;
};

const getPrismicSlicesList = (slices: IPrismicSlice[]) =>
  Promise.all(
    slices.map(async (slice: IPrismicSlice) => {
      if (!slice.items && !slice.primary) {
        return;
      }

      const { searchProducts, searchProductsByGroupId } = new MenuService();

      if (
        slice.slice_type === 'products_carousel' ||
        slice.slice_type === 'product_side_by_side'
      ) {
        try {
          const {
            limit,
            type,
            sort_by,
            filter_by_brand,
            product_group_id,
            filter_by_on_sale,
          } = slice.primary;

          const q: SearchProductsRequest = {
            sortBy: SORT_BY_MAP?.[sort_by] as SortBySelectOption,
            pageSize: limit as number,
          };

          if (product_group_id) {
            const productGroup = await searchProductsByGroupId({
              id: product_group_id,
            });

            // filter by group id
            q.filters = {
              ...q.filters,
              productId: {
                values: productGroup.map((e: any) => e.id),
              },
            };
          }

          // filter by on sale
          if (filter_by_on_sale) {
            q.displayOnSale = true;
          }

          // filter by category
          if (type && type !== 'default') {
            q.filters = {
              productTypeName: { values: [type?.toUpperCase()!] },
            };
          }

          // filter by brand
          if (filter_by_brand && filter_by_brand !== 'default') {
            q.filters = {
              ...q.filters,
              brand: {
                values: [filter_by_brand?.replace(' ', '_').toUpperCase()!],
              },
            };
          }

          const data = await searchProducts(q);
          return { ...slice, products: data.products, totalCount: 0 };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);

          return { ...slice, products: [], totalCount: 0 };
        }
      }

      if (
        slice.slice_type === 'product_list' ||
        slice.slice_type === 'product_row'
      ) {
        const ids = slice?.items?.map(item =>
          item?.product_id?.trim(),
        ) as string[];

        const { products, lastKey } = await searchProducts({
          filters: {
            productId: { values: ids },
          },
        });

        return { ...slice, products, lastKey };
      }

      return slice;
    }),
  );

const mergeSlices = (currentSlices, defaultSlices) => {
  const sliceMap = new Map<
    string,
    | ProductListingPageDocumentDataSlicesSlice
    | ProductGroupPageDocumentDataSlicesSlice
  >();

  // Add slices from the current document to the map
  currentSlices.forEach(slice => {
    sliceMap.set(slice.slice_type, slice);
  });

  // Ensure all default slices are in the map (preserve current document slices)
  defaultSlices.forEach(slice => {
    if (!sliceMap.has(slice.slice_type)) {
      sliceMap.set(slice.slice_type, slice);
    }
  });

  // Return the merged slices as an array
  return Array.from(sliceMap.values());
};

export {
  getPrismicSlicesItems,
  getPrismicSlicesList,
  mergeSlices,
  SORT_BY_MAP,
};
