import { FC } from 'react';

import classNames from 'classnames';
import { useFeaturedFlag } from '@/hooks';

import styles from './price.module.scss';

interface ISalePrice {
  price: string;
  salePrice: string;
  special?: boolean;
  className?: string;
}

const Price: FC<ISalePrice> = ({ price, salePrice, special, className }) => {
  const { isTaxAppliedMessage, showStriketroughPrice } = useFeaturedFlag();

  return (
    <p className={classNames(styles.price, className)}>
      {special && showStriketroughPrice && <del>{price}</del>}
      <ins>{salePrice}</ins>
      {isTaxAppliedMessage && (
        <span className={styles.tax_message}>(tax. included)</span>
      )}
    </p>
  );
};

export default Price;
