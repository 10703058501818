import { FC, useRef, useState } from 'react';

import { useRouter } from 'next/navigation';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { Swiper } from 'swiper/types';
import { EffectFade, FreeMode, Pagination, Thumbs } from 'swiper/modules';
import { useScreenWidth } from '@/hooks';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';
import { Container, Heading, IconSvg, Link, Slider } from '@/components';
import { TeamSlice } from '@/types';
import classNames from 'classnames';

import style from './team.module.scss';

const Team: FC<TeamSlice> = slice => {
  const { width } = useScreenWidth();
  const router = useRouter();
  const thumbsSwiperRef = useRef<Swiper | undefined>(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  const marginBottom = slice?.primary?.bottom_spacing;
  const background = slice?.primary?.background_color as string;
  const borderTop = slice?.primary?.border_section as string;
  const borderBottom = slice?.primary?.border_section as string;
  const titleUppercase = slice?.primary?.title_uppercase;
  const descriptionUppercase = slice?.primary?.description_uppercase;
  const paddingSection = slice?.primary?.padding_section;
  const carouselDefault = slice?.variation === 'default';
  const membersLeftContent =
    slice?.variation === 'membersWithLeftAlignedContent';
  const membersCenterContent =
    slice?.variation === 'membersWithCenterAlignedInfo';
  const membersAt3Columns = slice?.variation === 'membersAt3Columns';
  const membersAt4Columns = slice?.variation === 'membersAt4Columns';
  const carouselIndividual = slice?.variation === 'carouselIndividualBriefing';
  const carouselMembersRight =
    slice?.variation === 'individualBriefingMembersRight';
  const colorLink = carouselIndividual
    ? slice?.primary?.teammate_link_text_color
    : '';

  const handleSlideChange = swiper => {
    setActiveIndex(swiper?.activeIndex);
  };

  const getStyleClass = size => {
    let classCSS = '';
    switch (size) {
      case '12px':
        classCSS = style.headding__small;
        break;
      case '16px':
        classCSS = style.headding__small_16;
        break;
      case '20px':
        classCSS = style.headding__medium_20;
        break;
      case '24px':
        classCSS = style.headding__medium_24;
        break;
      case '32px':
        classCSS = style.headding__medium_32;
        break;
      case '40px':
        classCSS = style.headding__large_40;
        break;
      default:
        classCSS = '';
        break;
    }

    return classCSS;
  };

  const renderHeadingSlice = (
    text: any,
    level: number,
    color: string,
    classCSS: string,
    style?: object,
  ) => {
    if (!prismicH.asText(text)) {
      return null;
    }

    return (
      <Heading className={classCSS} color={color} level={level} style={style}>
        {prismicH.asText(text)}
      </Heading>
    );
  };

  const renderDescriptionSlice = (
    description: any,
    color: string,
    uppercase: boolean,
  ) => {
    if (!prismicH.asText(description)) {
      return null;
    }

    return (
      <PrismicRichText
        components={htmlSerializerWithProps({
          color: color,
          textTransform: uppercase ? 'uppercase' : 'initial',
        })}
        field={description}
      />
    );
  };

  const renderImage = info => {
    if (!info?.teammate_image?.url) {
      if (membersAt3Columns || membersAt4Columns) {
        return <IconSvg name="image" />;
      }

      return <IconSvg name="user-profile" />;
    }

    return (
      <PrismicNextImage
        fallbackAlt=""
        field={info?.teammate_image}
        fill
        style={{ objectFit: 'cover', objectPosition: 'center center' }}
      />
    );
  };

  const getElementPosition = (index, after) => {
    let pos = index - 1;
    if (after) {
      if (pos <= slice?.items?.length - 1) {
        pos = index + 1;
      }
      if (pos > slice?.items?.length - 1) {
        pos = 0;
      }
    }

    if (!after && pos === -1) {
      pos = slice?.items?.length - 1;
    }

    if (pos < 0) {
      return null;
    }

    return slice?.items[pos];
  };

  const renderThumbs = (info, index, variation) => {
    return (
      <figure
        key={`thumbs-${variation}-${index}`}
        style={{ minHeight: '80px' }}
      >
        {!info?.teammate_image?.url ? (
          <IconSvg name="user-profile" />
        ) : (
          <PrismicNextImage
            fallbackAlt=""
            field={info?.teammate_image}
            fill
            style={{ objectFit: 'cover', objectPosition: 'center center' }}
          />
        )}
      </figure>
    );
  };

  const renderSocials = info => {
    if (!info?.social_1?.url && !info?.social_2?.url && !info?.social_2?.url) {
      return null;
    }

    return (
      <div className={style.teammate__socials}>
        {Array.from({ length: 3 }).map((item, index) => {
          const netLink = prismicH.asLink(info[`social_link_${index + 1}`]);
          const icon = info[`social_${index + 1}`];
          return icon?.url ? (
            <Link
              className={style.socials__item}
              href={`${netLink}`}
              key={index}
              onClick={() => event?.stopPropagation()}
              // rel="noopener noreferrer"
              target="_blank"
            >
              <figure>
                <PrismicNextImage
                  className={style.socials__icon}
                  fallbackAlt=""
                  field={icon}
                  fill
                />
              </figure>
            </Link>
          ) : null;
        })}
      </div>
    );
  };

  const renderLinkText = (info, color) => {
    if (!info?.link && !info?.link_to_info) {
      return null;
    }

    return (
      <Link
        href={`${info?.link_to_info}`}
        onClick={() => event?.stopPropagation()}
        // rel="noopener noreferrer"
        target="_blank"
      >
        <span style={{ color }}>{info?.link}</span>
      </Link>
    );
  };

  const handleLinkPage = url => {
    if (!url || carouselIndividual) {
      return;
    }

    router.push(url);
  };

  const renderTeammateInfo = (info, index, variation, colorLink) => {
    return (
      <li
        className={style.team__teammate_info}
        key={`${variation}-${index}`}
        onClick={() => handleLinkPage(info?.link_to_info)}
        role="button"
      >
        <div className={style.teammate__info_body}>
          {renderHeadingSlice(
            info?.teammate_name,
            3,
            slice?.primary?.teammate_name_color as string,
            getStyleClass(slice.primary?.teammate_name_size),
            {
              textTransform: undefined,
            },
          )}
          {renderHeadingSlice(
            info?.teammate_position,
            4,
            slice?.primary?.teammate_position_color as string,
            getStyleClass(slice?.primary?.teammate_position_size),
            {
              textTransform: undefined,
            },
          )}
          {renderDescriptionSlice(
            info.teammate_info,
            slice?.primary?.teammate_info_color as string,
            false,
          )}
          {renderSocials(info)}
          {renderLinkText(info, colorLink)}
        </div>
        {!carouselDefault && (
          <figure
            className={classNames({
              [style.teammate__figure]: membersAt3Columns || membersAt4Columns,
            })}
          >
            {renderImage(info)}
          </figure>
        )}
      </li>
    );
  };

  const getVariation = slice => {
    if (
      membersCenterContent ||
      membersLeftContent ||
      membersAt3Columns ||
      membersAt4Columns
    ) {
      return (
        <ul className={style.team__body} key={`team-${slice?.variation}`}>
          {slice?.items?.map((info, index) => {
            return renderTeammateInfo(info, index, slice?.variation, null);
          })}
        </ul>
      );
    }

    return (
      <div className={style.team__carousel_container}>
        {carouselDefault && (
          <div
            className={style.teammate__images}
            style={{ backgroundColor: slice?.primary?.background_color }}
          >
            <figure className={style.teammate__before}>
              {getElementPosition(activeIndex, false) &&
                renderImage(getElementPosition(activeIndex, false))}
            </figure>
            <div className={style.teammate_wrapper}>
              <figure className={style.teammate__main}>
                {renderImage(slice?.items[activeIndex])}
              </figure>
            </div>
            <figure className={style.teammate__after}>
              {getElementPosition(activeIndex, true) &&
                renderImage(getElementPosition(activeIndex, true))}
            </figure>
          </div>
        )}
        <Slider
          className={classNames(style.team__carousel)}
          key={`${slice?.variation}-main`}
          modules={[FreeMode, EffectFade, Pagination, Thumbs]}
          onSlideChange={swiper => handleSlideChange(swiper)}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          showSliderButtons={slice?.items?.length > 1}
          slidesPerView={1}
          spaceBetween={24}
          thumbs={{ swiper: thumbsSwiperRef.current }}
        >
          {slice?.items?.map((info, index) => {
            return renderTeammateInfo(info, index, slice?.variation, colorLink);
          })}
        </Slider>
        {carouselMembersRight && (
          <Slider
            activateThumbs={true}
            className={classNames(style.team__carrousel_thumbs)}
            direction={(width as number) > 1286 ? 'vertical' : 'horizontal'}
            key={`${slice?.variation}-thumbs`}
            modules={[EffectFade, Pagination, Thumbs]}
            onSwiper={swiper => (thumbsSwiperRef.current = swiper)}
            showSliderButtons={false}
            slidesPerView={carouselDefault ? 1 : 3}
            spaceBetween={carouselDefault ? 0 : 24}
          >
            {slice?.items?.map((info, index) => {
              return renderThumbs(info, index, slice?.variation);
            })}
          </Slider>
        )}
      </div>
    );
  };

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.team__section,
        {
          [style.team__section_carousel_members_default]: carouselDefault,
          [style.team__section_left_aligned_content]: membersLeftContent,
          [style.team__section_center_aligned_content]: membersCenterContent,
          [style.team__section_3columns]: membersAt3Columns,
          [style.team__section_4columns]: membersAt4Columns,
          [style.team__section_carousel]: carouselIndividual,
          [style.team__section_member_right]: carouselMembersRight,
          [style.team__section_effect_images]:
            slice?.primary?.effect_zoom_images,
          [style.team__section_effect_default_images]:
            !slice?.primary?.effect_zoom_images,
          ['spacing_bottom']: marginBottom,
        },
        getSlicePadding(paddingSection),
      )}
      key={`section-team-${slice?.variation}`}
      style={{
        background,
        borderTop,
        borderBottom,
      }}
    >
      <Container className={style.team__container}>
        {(prismicH.asText(slice?.primary?.title) ||
          prismicH.asText(slice?.primary?.description)) && (
          <div
            className={style.team__header}
            style={{ maxWidth: `${slice?.primary?.max_header_width}px` }}
          >
            {renderHeadingSlice(
              slice?.primary?.title,
              2,
              slice?.primary?.title_color as string,
              getStyleClass(slice.primary?.title_size),
              {
                textTransform: titleUppercase ? 'uppercase' : 'initial',
              },
            )}
            {renderDescriptionSlice(
              slice?.primary?.description,
              slice?.primary?.description_color as string,
              descriptionUppercase,
            )}
          </div>
        )}
        {getVariation(slice)}
      </Container>
    </section>
  );
};

export default Team;
