import { FC } from 'react';
import classNames from 'classnames';
import style from './cities.module.scss';
import React from 'react';

interface IPPlaceholder {
  className?: string;
}

const CityPlaceholder: FC<IPPlaceholder> = ({ className }) => {
  return (
    <div className={classNames(style.city__placeholder, className)}>
      <figure />
      <h3 />
    </div>
  );
};

export default CityPlaceholder;
