/* eslint-disable max-len */
import { FC } from 'react';

import style from './cards.module.scss';

import classNames from 'classnames';
import { Image, useData } from '@/components';
import { FooterDocumentDataPaymentMethodsItem } from '@/types';

interface ICardsProps {
  className?: string;
}

const Cards: FC<ICardsProps> = ({ className, ...props }) => {
  const {
    footer: { data: footer },
  } = useData();

  if (footer?.payment_methods?.length === 0) {
    return null;
  }

  return (
    <div className={classNames(style.cards, className)} {...props}>
      {footer?.payment_methods?.map(
        (card: FooterDocumentDataPaymentMethodsItem, index: number) => {
          if (!card?.card_icon?.url) {
            return null;
          }
          return (
            <div className={style.card_container} key={index}>
              <Image
                alt={`card_item_${index}`}
                fill
                key={`card_item_${index}`}
                src={card?.card_icon?.url ?? ''}
                style={{ objectFit: 'contain' }}
              />
            </div>
          );
        },
      )}
    </div>
  );
};

export default Cards;
