import { useMutation } from '@apollo/client';
import { SEND_CONTACT_EMAIL_MUTATION } from './schema';

export function useSendContactEmail(options) {
  const [sendContactEmail, result] = useMutation(
    SEND_CONTACT_EMAIL_MUTATION,
    options,
  );

  return {
    sendContactEmail,
    result,
  };
}
