import { FC } from 'react';

import { Article, Heading, Slider } from '@/components';

import styles from './relatedpost.module.scss';
import { BlogPostDocument } from '@/types';
import classNames from 'classnames';

const RelatedPosts: FC<IRelatedPosts> = ({ articlesList }) => {
  const breakpoints = {
    640: {
      spaceBetween: 32,
    },
  };

  return (
    <section
      className={classNames(
        styles.blog_article__related_post,
        'blog_article__related_post',
      )}
    >
      <Slider
        breakpoints={breakpoints}
        className={styles.blog_article__related_post_carousel}
        showSliderButtons
        showSliderHeader
        sliderHeading={<Heading level={2}>Related Post</Heading>}
        spaceBetween={16}
      >
        {articlesList?.map(item => (
          <Article
            article={item}
            classCss={styles.blog_article__related_post_article}
            key={item.id}
          />
        ))}
      </Slider>
    </section>
  );
};

interface IRelatedPosts {
  articlesList: BlogPostDocument[];
}

export { RelatedPosts };
