import { gql } from '@apollo/client';

export const CREATE_AUTHORIZE_PAYMENT_MUTATION = gql`
  mutation createAuthorizePayment($input: AuthorizeNetPaymentInput!) {
    createAuthorizePayment(input: $input) {
      status
      payment_detail {
        transaction_id
        avs_result_code
        credit_card_bin
        cvv_result_code
        credit_card_number
        credit_card_company
      }
      payment_error {
        message
      }
    }
  }
`;

export const CREATE_STRIPE_PAYMENT_MUTATION = gql`
  mutation createStripePayment($input: StripePaymentInput!) {
    createStripePayment(input: $input) {
      status
      payment_detail {
        balance_transaction
        charge_id
        payment_method
        credit_card_company
        credit_card_last_four
        credit_card_fingerprint
        refund_url
      }
      error_message
    }
  }
`;

export const CREATE_WEBPAY_TRANSACTION_MUTATION = gql`
  mutation createWebPayTransaction($input: CreateWebPayTransactionInput!) {
    createWebPayTransaction(input: $input) {
      status
      error
      response {
        token
        url
      }
    }
  }
`;

export const CREATE_WEBPAY_MEASURE_TRANSACTION_MUTATION = gql`
  mutation createWebPayMeasureTransaction(
    $account_id: String!
    $store_id: String!
  ) {
    createWebPayMeasureTransaction(
      account_id: $account_id
      store_id: $store_id
    ) {
      status
      error
      response {
        token
        url
      }
    }
  }
`;
