import { MedRecSwitch, SearchHeader, StoreLocator } from '@/components';

import {
  HeaderBurgerNav,
  HeaderContainer,
  HeaderFavorites,
  HeaderLogo,
  HeaderNav,
  HeaderProfile,
  HeaderRow,
  HeaderSection,
  HeaderShopping,
} from '../Header.components';

const HeaderWithCenteredLogoAndNav = () => (
  <>
    <HeaderWithCenteredLogoAndNavMobile />
    <HeaderWithCenteredLogoAndNavDesktop />
  </>
);

const HeaderWithCenteredLogoAndNavMobile = () => (
  <HeaderContainer.Mobile>
    <HeaderRow borderless type="small">
      <HeaderSection justify="start">
        <HeaderBurgerNav />
        <SearchHeader withEmbeddedInput />
      </HeaderSection>
      <HeaderSection justify="center">
        <HeaderLogo center fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow type="small">
      <HeaderSection justify="center" spaceBetweenOnMultipleChildren>
        <StoreLocator />
        <MedRecSwitch />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Mobile>
);

const HeaderWithCenteredLogoAndNavDesktop = () => (
  <HeaderContainer.Desktop>
    <HeaderRow borderless type="medium">
      <HeaderSection justify="start">
        <StoreLocator />
      </HeaderSection>
      <HeaderSection justify="center">
        <HeaderLogo center fullWidth />
      </HeaderSection>
      <HeaderSection justify="end">
        <MedRecSwitch />
        <SearchHeader withEmbeddedInput />
        <HeaderProfile />
        <HeaderFavorites />
        <HeaderShopping />
      </HeaderSection>
    </HeaderRow>
    <HeaderRow type="flat" withNavbar>
      <HeaderSection justify="center">
        <HeaderNav />
      </HeaderSection>
    </HeaderRow>
  </HeaderContainer.Desktop>
);

export { HeaderWithCenteredLogoAndNav };
