import { FC } from 'react';
import classNames from 'classnames';

import { PrismicNextImage } from '@prismicio/next';
import {
  ImageWithTextSideBySideSlice,
  ImageWithTextSideBySideSliceWithBlockquotes,
} from 'prismicio-types';

import { Heading, Icon } from '@/components';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';
import { textAlign } from '../ImageWithTextSideBySide.components';

import style from './withblockquotevariation.module.scss';

export const WithBlockquoteVariation: FC<{
  slice: ImageWithTextSideBySideSliceWithBlockquotes &
    ImageWithTextSideBySideSlice;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);
  const {
    content_background_color,
    content_horizontal_align,
    content_vertical_align,
    image,
    image_bottom_left_border_radius,
    image_bottom_right_border_radius,
    image_top_left_border_radius,
    image_top_right_border_radius,
    item_description_color,
    item_title_color,
    layout_direction,
    all_images_border_radius,
  } = slice.primary;

  const layoutDirection = layout_direction ?? 'left';

  const alignText = content_horizontal_align ?? 'center';

  const bodyVariables = {
    '--content-padding-left': layoutDirection === 'left' ? '13.6%' : '0px',
    '--content-padding-right': layoutDirection === 'left' ? '0px' : '13.6%',
  };

  if (!filteredItems?.length) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(style.body, {
          [style.body__left]: layoutDirection === 'left',
        })}
        style={{
          background: content_background_color as string,
          ...bodyVariables,
        }}
      >
        {filteredItems?.map((feature, index) => (
          <div
            className={classNames(style.body__item_grid_feature, {
              /* eslint-disable-next-line max-len */
              [style.body__item_grid_feature_border_right]:
                layoutDirection === 'left',
            })}
            key={index}
            style={{
              alignItems: textAlign(content_horizontal_align as string),
              textAlign: alignText,
              alignSelf: textAlign(content_vertical_align),
            }}
          >
            {feature.title && (
              <Heading
                className={style.title}
                level={5}
                style={{ color: item_title_color as string }}
              >
                {feature.title}
              </Heading>
            )}
            {feature.description && (
              <p
                className={style.description}
                style={{ color: item_description_color as string }}
              >
                {feature.description}
              </p>
            )}
          </div>
        ))}
      </div>
      <figure
        className={classNames(style.body__figure, {
          [style.body__figure__left]: layoutDirection === 'left',
        })}
        style={{
          borderTopLeftRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_top_left_border_radius as number),
          borderBottomLeftRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_bottom_left_border_radius as number),
          borderTopRightRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_top_right_border_radius as number),
          borderBottomRightRadius: all_images_border_radius
            ? (all_images_border_radius as number)
            : (image_bottom_right_border_radius as number),
        }}
      >
        {image.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={image}
            fill
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <Icon name="image" />
        )}
      </figure>
    </>
  );
};
