import { FC, useMemo } from 'react';

import classNames from 'classnames';
import { useSwiper } from 'swiper/react';

import styles from './Slider.module.scss';

interface ISliderButtonsProps {
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  loop: boolean;
}

const SliderButtons: FC<ISliderButtonsProps> = ({
  isNextDisabled,
  isPrevDisabled,
  loop,
}) => {
  const swiper = useSwiper();
  const prevDisabled = useMemo(() => {
    if (loop) {
      return false;
    }
    return isPrevDisabled;
  }, [loop, isPrevDisabled]);
  const nextDisabled = useMemo(() => {
    if (loop) {
      return false;
    }
    return isNextDisabled;
  }, [loop, isNextDisabled]);

  const handlePrevious = () => {
    if (prevDisabled) {
      return;
    }
    swiper.slidePrev();
  };

  const handleNext = () => {
    if (nextDisabled) {
      return;
    }
    swiper.slideNext();
  };

  return (
    <div className={styles.slider_buttons}>
      <button
        className={classNames('slick-arrow slick-prev', {
          ['slick-disabled']: prevDisabled,
        })}
        data-role="none"
        onClick={handlePrevious}
      />
      <button
        className={classNames('slick-arrow slick-next', {
          ['slick-disabled']: nextDisabled,
        })}
        data-role="none"
        onClick={handleNext}
      />
    </div>
  );
};

export { SliderButtons };
