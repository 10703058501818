import { useMutation } from '@apollo/client';
import { CREATE_SHIPMENT_MUTATION } from './schema';

export function useCreateShipment(options) {
  const [createShipment, result] = useMutation(
    CREATE_SHIPMENT_MUTATION,
    options,
  );

  return {
    createShipment,
    result,
  };
}
