/* eslint-disable max-len */
import { record } from '@aws-amplify/analytics';
import { updateEndpoint } from '@aws-amplify/core/internals/providers/pinpoint';
import { capitalize, isEmpty, omitBy } from 'lodash';
import { SURFSIDE_APP_ID } from '@/constants';

import { CartLineItem, EcomState, GProduct } from '@/types';
import { currencyFormat, getProductPrice, priceFormat } from '@/utils';

const UNSPECIFIED = 'Not specified';

const CURRENCY_CODE = process.env.NEXT_PUBLIC_CURRENCY_CODE ?? 'USD';

class AnalyticsRecorder {
  private _isPintpointActive = false;
  private _isSurfsideActive = false;
  private _isGtmActive = false;

  constructor(
    isPintpoinActive: boolean = false,
    isSurfsideActive: boolean = false,
    isGtmActive: boolean = false,
  ) {
    this._isPintpointActive = isPintpoinActive;
    this._isSurfsideActive = isSurfsideActive;
    this._isGtmActive = isGtmActive;
  }

  updateEndpoint = endpoint => {
    if (!this._isPintpointActive) {
      return;
    }
    const { userId = endpoint.address, ...attrs } = endpoint;

    return updateEndpoint({
      channelType: 'EMAIL',
      optOut: 'NONE',
      userId,
      ...attrs,
    });
  };

  recordPinpointEvent = data => {
    if (!this._isPintpointActive) {
      return;
    }
    try {
      record(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Event not recorded:', e);
    }
  };

  recordSignUp = (email: string, marketing: boolean) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.updateEndpoint({
      address: email,
      userAttributes: {
        specialOffersOptOut: [marketing ? 'NONE' : 'ALL'],
      },
    })?.then(() => {
      this.recordPinpointEvent({ name: 'signup' });
    });
  };

  recordCheckoutSteps = (email, step) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.updateEndpoint({
      address: email,
      attributes: {
        purchased: ['No'],
      },
    })?.then(() => {
      this.recordPinpointEvent({
        attributes: {
          step: `${step}`,
        },
        name: 'checkout',
      });
    });
  };

  recordPurchase = (
    email: string,
    entityId: string,
    lineItems: CartLineItem[],
  ) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.updateEndpoint({
      address: email,
      attributes: {
        purchased: ['Yes'],
      },
    })?.then(() => {
      lineItems.forEach(item => {
        const variant = (item?.variants ?? [])[0];
        const price = item?.weightVariant?.price ?? variant?.price;
        const salePrice = item?.weightVariant?.salePrice ?? variant?.salePrice;
        const finalPrice =
          (price !== salePrice && !!salePrice ? salePrice : price) ?? 0;

        this.recordPinpointEvent({
          attributes: {
            _currency: CURRENCY_CODE,
            _item_price_formatted: `$${currencyFormat(finalPrice)}`,
            _product_id: item.id,
            _transaction_id: entityId,
          },
          metrics: {
            _item_price: (finalPrice / 100).toFixed(2),
            _quantity: item.quantity,
          },
          name: '_monetization.purchase',
        });
      });
    });
  };

  // GTM push on data layer.
  pushOnDataLayer = data => {
    if (!this._isGtmActive) {
      return;
    }
    const dataLayer = (window as any).dataLayer;

    if (dataLayer) {
      const finalData = omitBy(
        data,
        data => Array.isArray(data) && !data.length,
      );

      if (!isEmpty(finalData)) {
        dataLayer.push(finalData);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Event not pushed. There is not GTM configuration.');
    }
  };

  addAndRemoveProductAttributes = (
    action: 'addToCart' | 'removeFromCart',
    GProduct: CartLineItem,
  ) => {
    if (!this._isPintpointActive) {
      return;
    }
    const variant = (GProduct?.variants ?? [])[0];
    const name = GProduct?.weightVariant?.name ?? variant?.name;
    const price = GProduct?.weightVariant?.price ?? variant?.price;
    const salePrice = GProduct?.weightVariant?.salePrice ?? variant?.salePrice;
    const finalPrice =
      (price !== salePrice && !!salePrice ? salePrice : price) ?? 0;

    return {
      'data-gc-id': action,
      'data-gc-track': 'click',
      'data-gc-details': `id:${GProduct.id};name:${
        GProduct.name
      };price:${currencyFormat(finalPrice)};brand:${
        GProduct.brand_name
      };category:${GProduct.category};variant:${
        name ?? 'Not defined' // FIXME variant[0]?.name ?? 'Not defined'
      };quantity:1`,
    };
  };

  measureProductDetailsPageView = (GProduct: GProduct) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      ecommerce: {
        detail: {
          actionField: { list: 'details page' },
          products: [
            {
              brand: GProduct.brand_name ?? 'Not specified',
              category: GProduct.category,
              id: GProduct.id,
              name: GProduct.name,
              // price: currencyFormat(
              //     isSpecial
              //         ? GProduct.variants[0]?.salePrice
              //         : GProduct.variants[0]?.price,
              // ),
              // variant: GProduct.variants[0]?.name,
            },
          ],
        },
      },
    });
    // Measure a view of GProduct details GA4.
    // this.pushOnDataLayer({ ecommerce: null });
    // this.pushOnDataLayer({
    //     event: "view_item",
    //     ecommerce: {
    //         items: [
    //             {
    //                 item_name: GProduct.name,
    //                 item_id: GProduct.id,
    //                 // price: we have not this info at this time,
    //                 item_brand:
    //                     GProduct.brand_name ?? "Not specified",
    //                 item_category: GProduct.category,
    //                 // item_variant: We have not this info at this time,
    //                 quantity: 1,
    //             },
    //         ],
    //     },
    // });
  };

  /*
   * Measure a view of GProduct details GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#view_item_details
   */
  measureViewItemGA4 = (GProduct: GProduct) => {
    if (!this._isGtmActive) {
      return;
    }
    const productPrice = priceFormat(getProductPrice(GProduct) as number);

    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'view_item',
      ecommerce: {
        currency: CURRENCY_CODE,
        value: productPrice,
        items: [
          {
            item_id: GProduct.id,
            item_name: GProduct.name,
            item_brand: GProduct.brand_name ?? 'Not specified',
            item_category: GProduct.category,
            item_variant: GProduct.variants?.[0]?.name,
            price: productPrice,
            quantity: 1,
          },
        ],
      },
    });
  };

  measureProductImpressions = (products: GProduct[], list: string) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      ecommerce: {
        currencyCode: CURRENCY_CODE,
        impressions: products.map((GProduct, i) => ({
          brand: GProduct.brand_name ?? 'Not specified',
          category: GProduct.category,
          id: GProduct.id,
          list: list ?? '',
          name: GProduct.name,
          position: i,
          // price: currencyFormat(
          //     GProduct.variants[0]?.price !==
          //         GProduct.variants[0]?.salePrice &&
          //         !!GProduct.variants[0]?.salePrice
          //         ? GProduct.variants[0]?.salePrice
          //         : GProduct.variants[0]?.price,
          // ),
          // variant: GProduct.variants[0]?.name,
        })),
      },
    });
    // Measure GProduct views / impressions GA4.
    // this.pushOnDataLayer({ ecommerce: null });
    // this.pushOnDataLayer({
    //     event: "view_item_list",
    //     ecommerce: {
    //         items: products.map((GProduct, i) => ({
    //             item_name: GProduct.name,
    //             item_id: GProduct.id,
    //             // price: We have not this info at this time,
    //             item_brand: GProduct.brand_name ?? "Not specified",
    //             item_category: GProduct.category,
    //             // item_variant: We have not this info at this time,
    //             item_list_name: "collection",
    //             index: i,
    //             quantity: 1,
    //         })),
    //     },
    // });
  };

  /*
   * Measure a view of products list GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list
   */
  measureViewItemsListGA4 = (products: GProduct[], list: string) => {
    if (!this._isGtmActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: list,
        item_list_name: capitalize(list),
        items: products?.map((GProduct, index) => ({
          item_id: GProduct?.id,
          item_name: GProduct?.name,
          item_brand: GProduct?.brand_name ?? UNSPECIFIED,
          item_category: GProduct?.category ?? UNSPECIFIED,
          item_variant: GProduct?.variants?.[0]?.name,
          index,
          price: priceFormat(getProductPrice(GProduct) as number),
          quantity: 1,
        })),
      },
    });
  };

  measureProductClick = (GProduct: GProduct, list: string) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      ecommerce: {
        click: {
          actionField: { list },
          products: [
            {
              brand: GProduct.brand_name,
              category: GProduct.category,
              id: GProduct.id,
              name: GProduct.name,
              position: 0,
              // price: currencyFormat(
              //     GProduct.variants[0]?.price !==
              //         GProduct.variants[0]?.salePrice &&
              //         !!GProduct.variants[0]?.salePrice
              //         ? GProduct.variants[0]?.salePrice
              //         : GProduct.variants[0]?.price,
              // ),
              // variant: GProduct.variants[0]?.name,
            },
          ],
        },
      },
      event: 'productClick',
    });
  };

  /*
   * Measure a GProduct selection from a list GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list
   */

  measureSelectItemGA4 = (GProduct: GProduct, list: string) => {
    if (!this._isGtmActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'select_item',
      ecommerce: {
        item_list_id: list,
        item_list_name: capitalize(list),
        items: [
          {
            item_id: GProduct.id,
            item_name: GProduct.name,
            item_brand: GProduct.brand_name ?? 'Not specified',
            item_category: GProduct.category,
            item_variant: GProduct.variants?.[0]?.name,
            price: priceFormat(getProductPrice(GProduct) as number),
            variant: GProduct?.variants?.[0]?.name,
            quantity: 1,
          },
        ],
      },
    });
  };

  measurePromotionView = (promotion, position) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      ecommerce: {
        promoClick: {
          promotions: [
            {
              creative: `banner${position}`,
              id: promotion.id,
              name: promotion.name ?? 'Not specified',
              position: `slot${position}`,
            },
          ],
        },
      },
      event: 'promotionClick',
    });
  };

  measureViewPromotionGA4 = (promotion, position) => {
    if (!this._isGtmActive) {
      return;
    }
    // eslint-disable-next-line no-console
    console.log(position);
  };

  measureCheckoutSteps = (step, method, items) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      ecommerce: {
        checkout: {
          actionField: {
            option: method,
            step,
          },
          products: items.map(item => ({
            brand: item.brand,
            category: item.category_name,
            id: item.id,
            name: item.name,
            price: currencyFormat(
              item.is_special ? item.salePrice : item.price,
            ),
            quantity: item.quantity,
            // variant: item.variants[0]?.name,
          })),
        },
      },
      event: 'checkout',
    });
    // Measure a purchage begin GA4.
    // this.pushOnDataLayer({ ecommerce: null });
    // this.pushOnDataLayer({
    //     event: "begin_checkout",
    //     ecommerce: {
    //         items: items.map((item, i) => ({
    //             item_name: item.name,
    //             item_id: item.id,
    //             price: item.price / 100,
    //             item_brand: item.brand,
    //             item_category: item.category_name,
    //             item_variant: item.variants.find(
    //                 (variant) => variant.type === "Gram",
    //             ).name,
    //             item_list_name: "cart",
    //             index: i,
    //             quantity: item.quantity,
    //         })),
    //     },
    // });
  };

  /*
   * Measure a view of GProduct details GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#initiate_the_checkout_process
   */
  measureBeginCheckoutGA4 = (products: CartLineItem[]) => {
    if (!this._isGtmActive) {
      return;
    }
    const value = products.reduce((acc, GProduct) => {
      const productPrice = getProductPrice(GProduct);

      return acc + (productPrice as number) * GProduct.quantity;
    }, 0);

    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'begin_checkout',
      ecommerce: {
        currency: CURRENCY_CODE,
        value: priceFormat(value),
        items: products.map(GProduct => {
          const productPrice = priceFormat(getProductPrice(GProduct) as number);

          return {
            item_name: GProduct.name,
            item_id: GProduct.id,
            price: productPrice,
            item_brand: GProduct.brand_name ?? 'Not specified',
            item_category: GProduct.category,
            item_variant:
              GProduct.weightVariant?.name ?? GProduct.variants?.[0]?.name,
            item_list_name: 'cart',
            quantity: GProduct.quantity,
          };
        }),
      },
    });
  };

  measurePurchase = (order: EcomState, items: CartLineItem[]) => {
    if (!this._isPintpointActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      ecommerce: {
        purchase: {
          actionField: {
            affiliation: 'Online Store',
            coupon: (order.discount_items ?? []).find(item => !!item.promo_code)
              ?.promo_code,
            id: order.entity_id,
            revenue: priceFormat(order.total_delivery),
            shipping: '0.00',
            tax: priceFormat(order.total_tax),
          },
          products: items.map(item => {
            const variant = (item?.variants ?? [])[0];
            const price = item?.weightVariant?.price ?? variant?.price;
            const salePrice =
              item?.weightVariant?.salePrice ?? variant?.salePrice;
            const finalPrice =
              (price !== salePrice && !!salePrice ? salePrice : price) ?? 0;

            return {
              brand: item.brand_name,
              category: item.category,
              id: item.id,
              name: item.name,
              price: currencyFormat(finalPrice),
              quantity: item.quantity,
              // variant: item.variants[0]?.name,
            };
          }),
        },
      },
    });
    // Measure a purchase GA4.
    // this.pushOnDataLayer({ ecommerce: null });
    // this.pushOnDataLayer({
    //     event: "purchase",
    //     ecommerce: {
    //         transaction_id: order.entity_id,
    //         affiliation: "Online store",
    //         value: currencyFormat(order.total_delivery),
    //         tax: currencyFormat(order.total_tax),
    //         shipping: "0.00",
    //         currency: "USD",
    //         coupon: order.discount_items.find(
    //             (item) => !!item.promo_code,
    //         )?.promo_code,
    //         items: items.map((item, i) => ({
    //             item_name: item.name,
    //             item_id: item.id,
    //             price: item.price / 100,
    //             item_brand: item.brand,
    //             item_category: item.category_name,
    //             item_variant: item.variants.find(
    //                 (variant) => variant.type === "Gram",
    //             ).name,
    //             item_list_name: "cart",
    //             index: i,
    //             quantity: item.quantity,
    //         })),
    //     },
    // });
  };

  /*
   * Measure a purchase GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#make_a_purchase_or_issue_a_refund
   */
  measurePurchaseGA4 = (order: EcomState, products: CartLineItem[]) => {
    if (!this._isGtmActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'purchase',
      ecommerce: {
        transaction_id: order.entity_id,
        affiliation: 'Online store',
        value: priceFormat(order.total_price),
        tax: priceFormat(order.total_tax),
        shipping: 0.0,
        currency: CURRENCY_CODE,
        coupon: order.discount_items.find(item => !!item.promo_code)
          ?.promo_code,
        items: products.map((GProduct, i) => ({
          item_name: GProduct.name,
          item_id: GProduct.id,
          price: priceFormat(getProductPrice(GProduct) as number),
          item_brand: GProduct.brand_name,
          item_category: GProduct.category,
          item_variant:
            GProduct.weightVariant?.name ?? GProduct?.variants?.[0]?.name,
          item_list_name: 'cart',
          index: i,
          quantity: GProduct.quantity,
        })),
      },
    });
  };

  /*
   * Measure the addition of a GProduct to cart GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_or_remove_an_item_from_a_shopping_cart
   */
  measureAddToCartGA4 = (GProduct: GProduct, quantity: number) => {
    if (!this._isGtmActive) {
      return;
    }
    const productPrice = getProductPrice(GProduct);

    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'add_to_cart',
      ecommerce: {
        currency: CURRENCY_CODE,
        value: priceFormat((productPrice as number) * quantity),
        items: [
          {
            item_name: GProduct.name,
            item_id: GProduct.id,
            price: priceFormat(productPrice as number),
            item_brand: GProduct.brand_name ?? 'Not specified',
            item_category: GProduct.category,
            item_variant: GProduct?.variants?.[0]?.name,
            item_list_name: 'cart',
            quantity,
          },
        ],
      },
    });
  };

  /*
   * Measure the addition of a GProduct to wishlist GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_or_remove_an_item_from_a_shopping_cart
   */
  measureAddToWishlistGA4 = (GProduct: GProduct) => {
    if (!this._isGtmActive) {
      return;
    }
    const productPrice = priceFormat(getProductPrice(GProduct) as number);

    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'add_to_wishlist',
      ecommerce: {
        currency: CURRENCY_CODE,
        value: productPrice,
        items: [
          {
            item_name: GProduct.name,
            item_id: GProduct.id,
            price: productPrice,
            item_brand: GProduct.brand_name ?? 'Not specified',
            item_category: GProduct.category,
            item_variant: GProduct?.variants?.[0]?.name,
            item_list_name: 'cart',
            quantity: 1,
          },
        ],
      },
    });
  };

  /*
   * Measure the cart view GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_or_remove_an_item_from_a_shopping_cart
   */
  measureViewCartGA4 = (products: CartLineItem[]) => {
    if (!this._isGtmActive) {
      return;
    }
    const value = products.reduce((acc, GProduct) => {
      const productPrice = getProductPrice(GProduct);

      return acc + (productPrice as number) * GProduct.quantity;
    }, 0);

    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'view_cart',
      ecommerce: {
        currency: CURRENCY_CODE,
        value: priceFormat(value),
        items: products.map(GProduct => {
          const productPrice = priceFormat(getProductPrice(GProduct) as number);

          return {
            item_name: GProduct.name,
            item_id: GProduct.id,
            price: productPrice,
            item_brand: GProduct.brand_name ?? 'Not specified',
            item_category: GProduct.category,
            item_variant:
              GProduct.weightVariant?.name ?? GProduct?.variants?.[0]?.name,
            item_list_name: 'cart',
            quantity: GProduct.quantity,
          };
        }),
      },
    });
  };

  /*
   * Measure GProduct remove from cart GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_or_remove_an_item_from_a_shopping_cart
   */
  measureRemoveFromCartGA4 = (GProduct: CartLineItem) => {
    if (!this._isGtmActive) {
      return;
    }
    const productPrice = priceFormat(getProductPrice(GProduct) as number);

    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'remove_from_cart',
      currency: CURRENCY_CODE,
      value: productPrice,
      ecommerce: {
        items: [
          {
            item_name: GProduct.name,
            item_id: GProduct.id,
            price: productPrice,
            item_brand: GProduct.brand_name ?? 'Not specified',
            item_category: GProduct.category,
            item_variant:
              GProduct.weightVariant?.name ?? GProduct?.variants?.[0]?.name,
            item_list_name: 'cart',
            quantity: 1,
          },
        ],
      },
    });
  };

  /*
   * Measure a view of GProduct details GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_payment_info
   */
  measureAddPaymentInfoGA4 = (order, items) => {
    if (!this._isGtmActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'add_payment_info',
      ecommerce: {
        currency: CURRENCY_CODE,
        value: currencyFormat(order.total_delivery),
        coupon: order.discount_items.find(item => !!item.promo_code)
          ?.promo_code,
        payment_type: 'Credit Card', // TODO: add payment type
        items: items.map((item, i) => ({
          item_name: item.name,
          item_id: item.id,
          affiliation: 'Online store',
          item_brand: item.brand,
          item_category: item.category_name,
          item_variant: item.variants.find(variant => variant.type === 'Gram')
            .name,
          index: i,
          quantity: item.quantity,
        })),
      },
    });
  };

  /*
   * Measure a view of GProduct details GA4.
   * Documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_shipping_info
   */
  measureAddShippingInfoGA4 = (order, items) => {
    if (!this._isGtmActive) {
      return;
    }
    this.pushOnDataLayer({ ecommerce: null });
    this.pushOnDataLayer({
      event: 'add_shipping_info',
      ecommerce: {
        currency: CURRENCY_CODE,
        value: currencyFormat(order.total_delivery),
        coupon: order.discount_items.find(item => !!item.promo_code)
          ?.promo_code,
        shipping_tier: 'Ground', // TODO: add shipping tier
        items: items.map((item, i) => ({
          item_name: item.name,
          item_id: item.id,
          affiliation: 'Online store',
          item_brand: item.brand,
          item_category: item.category_name,
          item_variant: item.variants.find(variant => variant.type === 'Gram')
            .name,
          index: i,
          quantity: item.quantity,
        })),
      },
    });
  };

  /**
   * Surfside Analytics
   * Documentation: https://docs.surfside.io/platform-guides/custom-ecommerce
   */

  surfsideActionProducts = (products: CartLineItem[] | GProduct[]) => {
    //we are checking window because in compilation time window is undefined
    if (!this._isSurfsideActive || typeof window == 'undefined') {
      return;
    }

    products.forEach(product => {
      const event = {
        id: product.id,
        name: product.name,
        list: '',
        brand: product.brand_name,
        category: product.category,
        variant:
          (product as CartLineItem)?.weightVariant?.type ??
          product?.variants?.[0]?.type,
        price: currencyFormat(product.sales_price!),
        quantity: (product as CartLineItem).quantity ?? 1,
        // coupon: product.coupon,
        // position: product.position,
        currency: CURRENCY_CODE,
      };

      window.surf(`addProduct:${SURFSIDE_APP_ID}`, event);
    });
  };

  // The transaction data for the purchase.
  addSurfsideTransaction = (state: EcomState) => {
    //we are checking window because in compilation time window is undefined
    if (!this._isSurfsideActive || typeof window == 'undefined') {
      return;
    }

    const event = {
      id: state.entity_id, // Transaction ID
      revenue: currencyFormat(
        state.total_price !== 0 ? state.total_price : state.subtotal_price,
      ), // Transaction Revenue order.total
      tax: currencyFormat(state.total_tax), // Transaction Tax
      shipping: currencyFormat(state.total_delivery),
      coupon: currencyFormat(state.total_discounts), // Transaction Coupon
      currency: CURRENCY_CODE, // Transaction Currency
    };

    window.surf(`addTransaction:${SURFSIDE_APP_ID}`, event);
  };

  measureAction = (action: tSurfsideActions) => {
    //we are checking window because in compilation time window is undefined
    if (!this._isSurfsideActive || typeof window == 'undefined') {
      return;
    }

    window.surf(`setCommerceAction:${SURFSIDE_APP_ID}`, `${action}`);
  };

  measureSurfsideCheckoutStep = (state: EcomState) => {
    this.addSurfsideTransaction(state);
    this.surfsideActionProducts(state.line_items);
    this.measureAction('checkout');
  };

  measureSurfsideTransaction = (state: EcomState) => {
    this.addSurfsideTransaction(state);
    this.surfsideActionProducts(state.line_items);
    this.measureAction('purchase');
  };

  measureSurfsideAction = (
    products: CartLineItem[] | GProduct[],
    action: tSurfsideActions,
  ) => {
    this.surfsideActionProducts(products);
    this.measureAction(action);
  };

  measureSurfsideChangeLocation = (storeId: string) => {
    //we are checking window because in compilation time window is undefined
    if (!this._isSurfsideActive || typeof window == 'undefined') {
      return;
    }

    window.surf('setLocation', { id: `${storeId}` });
  };
}

type tSurfsideActions =
  | 'add'
  | 'cart'
  | 'remove'
  | 'detail'
  | 'checkout'
  | 'purchase'
  | 'refound';

export { AnalyticsRecorder };
