import { FC } from 'react';
import { format } from 'date-fns';
import { useGetOrder } from '@/data';
import { useSearchParams } from 'next/navigation';
import {
  AuthContainer,
  CancelOrderButton,
  Heading,
  Icon,
  OrderDetail,
  Spinner,
  useAuth,
} from '@/components';
import { useFeaturedFlag } from '@/hooks';
interface IOrderProps {
  goBack: () => void;
}

const Order: FC<IOrderProps> = ({ goBack }) => {
  const searchParams = useSearchParams();
  const { isAuth } = useAuth();
  const { allowCancelOrder } = useFeaturedFlag();

  const { data, loading, refetch } = useGetOrder({
    notifyOnNetworkStatusChange: true,
    skip: !isAuth || !searchParams?.get('orderId'),
    variables: {
      entity_id: searchParams?.get('orderId') as string,
    },
  });

  const order = data;

  return (
    <section className="order-details" style={{ position: 'relative' }}>
      <AuthContainer>
        {!data || loading ? (
          <div className="account__auth-loading">
            <Spinner>Loading...</Spinner>
          </div>
        ) : (
          <>
            <button className="back_button" onClick={goBack}>
              <Icon name="left" />
              <span>Back to orders</span>
            </button>
            <Heading level={2}>Order details</Heading>
            <div className="order__num-order">
              <p className="order__number">
                Order Number{' '}
                {order?.treez_order_number
                  ? `#${order?.treez_order_number}`
                  : 'Not Assigned yet'}
              </p>
              <p>
                <strong>Created at:</strong>{' '}
                {format(new Date(order?.created_at), "PPPP 'at' h:mmaaa")}
              </p>

              {order?.delivery_details?.started_at && (
                <p>
                  <strong>
                    {order.delivery_details.delivery ? 'Delivery ' : 'Pickup '}
                    Started At:{' '}
                  </strong>
                  {format(
                    new Date(order.delivery_details.started_at),
                    " PPPP 'at' h:mmaaa",
                  )}
                </p>
              )}

              {order?.delivery_details?.finished_at && (
                <p>
                  <strong>
                    {order.delivery_details.delivery ? 'Delivery ' : 'Pickup '}
                    Finished At:
                  </strong>
                  {format(
                    new Date(order.delivery_details.finished_at),
                    " PPPP 'at' h:mmaaa",
                  )}
                </p>
              )}

              <p>
                <strong>Notes:</strong>{' '}
                {order?.notes || 'No additional notes specified.'}
              </p>
              {order?.status !== 'DECLINED' &&
                order?.status !== 'DELIVERED' &&
                allowCancelOrder && (
                  <CancelOrderButton
                    callback={() => {
                      refetch();
                    }}
                    className="order__btn_cancel"
                    color="secondary"
                    entityId={order?.entity_id!}
                  />
                )}
            </div>
            <OrderDetail order={order} />
          </>
        )}
      </AuthContainer>
    </section>
  );
};

export { Order };
