import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';

const PopoverPanel: FC<IPopoverPanelProps> = ({
  children,
  isOpen,
  toggle,
  onClose,
  ignoreOutsideClick,
  className,
  style,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // Handle outside click. The Popover component from
  // @headlessui/react package is a similar component
  // this information form popover but we can not
  // control the open/close state for elements outside
  // of popover component, ie: Google address autocomplete
  // (.pac-container) menu.
  const handleOutsideClick = useCallback(
    event => {
      if (isOpen) {
        if (!ref.current?.parentNode?.contains(event.target)) {
          if (!onClose || (onClose && onClose(event))) {
            toggle(false);
          }
        }
      }
    },
    [isOpen],
  );

  useEffect(() => {
    if (ignoreOutsideClick) {
      return;
    }

    setTimeout(() => {
      window.addEventListener('click', handleOutsideClick);
    }, 1);

    return () => window.removeEventListener('click', handleOutsideClick);
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={className} ref={ref} style={style}>
      {children}
    </div>
  );
};
interface IPopoverPanelProps {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  ignoreOutsideClick?: boolean;
  style?: CSSProperties;
  onClose?: (_e: any) => boolean;
  toggle: (_e: any) => void;
}

export default PopoverPanel;
