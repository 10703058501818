import { useEffect } from 'react';

import { useIsMobile } from './useIsMobile';

const useFixedBodyOnPopup = ({
  isPopupOpen,
  onlyOnMobile,
}: {
  isPopupOpen: boolean;
  onlyOnMobile?: boolean;
}) => {
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (onlyOnMobile && !isMobile) {
      return;
    }
    // When the popup is shown, we want a fixed body
    if (isPopupOpen) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.height = 'auto';
    document.body.style.overflow = 'unset';
  }, [isPopupOpen, isMobile, onlyOnMobile]);
};

export { useFixedBodyOnPopup };
