import { gql } from '../../../__generated__';

export const GET_STORE_QUERY = gql(/* GraphQL */ `
  query getStore($account_id: String!, $store_id: String!) {
    getStore(account_id: $account_id, store_id: $store_id) {
      name
      email
      logo
      logo_small
      instagram
      facebook
      twitter
      yelp
      youtube
      address1
      address2
      city
      country
      country_code
      country_name
      province
      province_code
      zip
      latitude
      longitude
      domain
      phone_number
      currency
      tax_active
      tax_type
      tax_ammont
      min_order_amount
      gtm_active
      gtm_id
      timezone
      store_feature {
        id
        status
        name
        position
        description
      }
      account_setting {
        active
        auto_verify_email
        cognito_identity_pool_id
        cognito_region
        user_pools_id
        user_pools_web_client_id
      }
      aws_pinpoint_analytics {
        active
        identity_pool_id
        auth_region
        app_id
        region
      }
    }
  }
`);
