import { FC } from 'react';

import { IconSvg } from '@/components';
import { useUiStoreSelector } from '@/data';
import { useIsServerSide, useProducts } from '@/hooks';
import { getCartItemsCount } from '@/utils';

import style from './shopping.module.scss';

const ShoppingHeader: FC = () => {
  const { products } = useProducts();
  const { isServerSide } = useIsServerSide();
  const { setState } = useUiStoreSelector([]);

  const counter = getCartItemsCount(products);

  return (
    <button
      className={style.shopping__action}
      onClick={() => {
        setState({ is_cart_open: true });
      }}
    >
      <IconSvg name="shopping" />
      {!isServerSide && counter !== 0 && (
        <span className={style.counter}>{counter}</span>
      )}
    </button>
  );
};

export default ShoppingHeader;
