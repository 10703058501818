import { FC } from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { Icon } from '../atoms/icon';
import { Link } from '../atoms/link';
import { Container } from '../Container';
import { Slider } from '../Slider';
import classNames from 'classnames';
import styles from './linkscarousel.module.scss';
import { LinksCarouselSlice } from '@/types';

const LinksCarousel: FC<{ slice?: LinksCarouselSlice }> = ({ slice }) => {
  const active = slice?.primary?.active;
  const activeAutoplay = slice?.primary?.autoplay;
  const delay = slice?.primary?.autoplay_delay ?? 2500;
  const waitForTransition = !!slice?.primary?.wait_for_transition;
  const disableOnInteraction =
    !!slice?.primary?.disable_autoplay_on_interaction;
  const speed = slice?.primary?.autoplay_speed ?? 1000;
  const background = slice?.primary?.background_color;
  const backgroundItems = slice?.primary?.background_items_color;
  const backgroundItemsHover = slice?.primary?.background_items_hover_color;
  const titleColor = slice?.primary?.title_color;
  const titleColorHover = slice?.primary?.title_color_hover;
  const borderColorItems = slice?.primary?.border_items_color;
  const borderColorItemsHover = slice?.primary?.border_items_hover_color;
  const borderBottom = slice?.primary?.border_bottom;
  const borderBottomWidth = slice?.primary?.border_bottom_width;
  const borderBottomColor = slice?.primary?.border_bottom_color;
  const borderItems = slice?.primary?.border_width_items;
  const borderRadiusItems = slice?.primary?.border_radius_items;
  const marginBottom = slice?.primary?.bottom_spacing;
  const showIcon = slice?.primary?.show_angle_icon;
  const disabledPadding = slice?.primary?.disable_section_padding;
  const borderInsideContainer = slice?.primary?.border_section_inside_container;
  const isItemLinkSimple = !borderItems && !backgroundItems;
  const spacing = isItemLinkSimple ? 16 : 8;
  const dimensions = slice?.primary?.dimension ?? 'small';

  const breakpointsDefault = {
    640: {
      spaceBetween: 16,
    },
  };

  const breakpointsSimple = {
    640: {
      spaceBetween: 24,
    },
  };

  const breakpoints = isItemLinkSimple ? breakpointsSimple : breakpointsDefault;

  if (!active || !slice?.items?.length) {
    return null;
  }

  return (
    <>
      <section
        className={classNames(styles.links, {
          [styles.links_with_spacing]:
            !disabledPadding && !borderInsideContainer,
          spacing_bottom: marginBottom,
        })}
        style={{
          background: background!,
          borderBottom:
            borderBottom && !borderInsideContainer
              ? `${borderBottomWidth}px solid ${borderBottomColor}`
              : undefined,
        }}
      >
        <Container
          className={classNames({
            [styles.links_container_with_spacing]:
              !disabledPadding && borderInsideContainer,
            [styles['links_container--full-width']]: slice?.primary?.full_width,
          })}
          style={{
            borderBottom:
              borderBottom && borderInsideContainer
                ? `${borderBottomWidth}px solid ${borderBottomColor}`
                : undefined,
          }}
        >
          <Slider
            activeAutoplay={activeAutoplay}
            autoplay={{ delay, disableOnInteraction, waitForTransition }}
            breakpoints={breakpoints}
            loop={activeAutoplay}
            showSliderHeader={false}
            showSlidesOutsideContainer
            spaceBetween={spacing}
            speed={speed}
          >
            {slice?.items?.map((item, index) => (
              <Link
                className={classNames(
                  'carousel_links__link',
                  styles.carousel_links__link,
                  styles[`carousel_links__link--${dimensions}`],
                  {
                    [styles.carousel_links__link_simple]: isItemLinkSimple,
                  },
                )}
                href={item?.link ?? '/'}
                key={index}
                style={{
                  background: backgroundItems!,
                  border: `${borderItems}px solid ${borderColorItems}`,
                  borderRadius: `${borderRadiusItems}px`,
                  color: titleColor!,
                }}
              >
                <figure
                  className={classNames(
                    styles.carousel_links__link__image,
                    styles[`carousel_links__link__image--${dimensions}`],
                  )}
                >
                  <PrismicNextImage fallbackAlt="" field={item?.icon} />
                </figure>
                {item?.title}
                {showIcon && <Icon name="angle-right" />}
              </Link>
            ))}
          </Slider>
        </Container>
      </section>
      <style>{`
        .carousel_links__link:hover {
          border-color: ${borderColorItemsHover} !important;
          background: ${backgroundItemsHover} !important;
          color: ${titleColorHover} !important;
        }
      `}</style>
    </>
  );
};

export { LinksCarousel };
