/* eslint-disable no-console */
import {
  AgeGateDocument,
  App,
  CrmModalDocument,
  FooterDocument,
  Store as GCStore,
  HeaderDocument,
  LocationBannerAlertDocument,
  MinZipByCode,
  NavigationDocument,
  ProductDocumentData,
  PromoCarouselDocumentData,
  ShoppingCartDocumentData,
  SideNavigationDocument,
  StorelocatorPageDocumentData,
  TreezStore,
} from '@/types';

import data from './../public/ssr-cache.json';

/**
 * This func return all the static data the website need
 * to function
 *
 */
export async function GetStoreMetadata(): Promise<IStoreMetadata> {
  const isBuilding = process.env.NEXT_PHASE === 'phase-production-build';
  const apiEndpoint = process.env.VERCEL_PROJECT_PRODUCTION_URL
    ? `https://${process.env.VERCEL_PROJECT_PRODUCTION_URL}`
    : 'http://localhost:3000';

  if (isBuilding) {
    console.info('[INFO] return data from local cache');
    return data as unknown as IStoreMetadata;
  }

  try {
    const r = await fetch(`${apiEndpoint}/api/ssr-cache`, {
      next: { tags: ['ssr-cache'] },
      cache: 'force-cache',
    });

    if (!r.ok) {
      return data as unknown as IStoreMetadata;
    }

    const cache = await r.json();

    console.info('[INFO] return data from network request');

    return cache;
  } catch (err) {
    console.info('[INFO] return data from local cache');
    return data as unknown as IStoreMetadata;
  }
}
export interface IStoreMetadata {
  apps: App[];
  treezStores: TreezStore[];
  ageGate: AgeGateDocument;
  crm: any;
  crmModal: CrmModalDocument;
  footer: FooterDocument;
  header: HeaderDocument;
  promotion: LocationBannerAlertDocument;
  navigation: NavigationDocument;
  sideNavigation: SideNavigationDocument;
  store: GCStore;
  promoCarousel: PromoCarouselDocumentData;
  productCard: ProductDocumentData;
  shoppingCart: ShoppingCartDocumentData;
  storeLocator: StorelocatorPageDocumentData;
  metafield: any; // deprecated
  minZipByCode: MinZipByCode; // deprecated
}
