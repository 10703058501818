import { FC } from 'react';
import { useRouter } from 'next/navigation';

import { addressAsString, Button, Icon, useData } from '@/components';
import {
  MenuService,
  useAnalytics,
  useEcomStoreSelector,
  useUiStore,
} from '@/data';
import { getDeliveryScheduleByAddress } from '@/utils';
import { useFeaturedFlag } from '@/hooks';

import { useStoreLocatorContext } from './context';

import styles from './storelocator.module.scss';

interface IStoreSelectorBottomProps {
  onShopActionFailure: () => void;
}

const StoreLocatorBottom: FC<IStoreSelectorBottomProps> = ({
  onShopActionFailure,
}) => {
  const {
    isDelivery,
    isPickup,
    selectedStoreId,
    deliveryAddress,
    isDeliveryAddressInvalid,
    setStoreAddress,
    setIsPickupInvalid,
    setIsDeliveryAddressInvalid,
    stores,
    externalStores,
    setShowOverlay,
  } = useStoreLocatorContext();
  const { measureSurfsideChangeLocation } = useAnalytics();
  const { isStartUpPlan } = useFeaturedFlag();
  const router = useRouter();

  const {
    line_items,
    delivery_details,
    treez_store_name,
    setState: setStoredState,
  } = useEcomStoreSelector([
    'line_items',
    'delivery_details',
    'treez_store_name',
  ]);
  const [, setState] = useUiStore();

  const { checkIfProductsBelongsToStore } = new MenuService();

  const { store } = useData();

  const showStoreValidator = (i?: string) => {
    setStoredState({
      next_shop_mode: isPickup ? 'pickup' : 'delivery',
      next_store_id: i ? i : selectedStoreId,
    });
  };

  const handleShopNow = async () => {
    const selectedExternalStore = externalStores?.find(
      store => store.shortName === selectedStoreId,
    );

    if (selectedExternalStore) {
      router.push(selectedExternalStore?.url_slug);
      setState({ is_store_location_open: false });
      setShowOverlay(false);
      return;
    }

    const selectedStore = stores.find(
      store => store.shortName === selectedStoreId,
    );

    // handle delivery
    if (isDelivery) {
      if (!deliveryAddress?.address1 || !deliveryAddress?.zip) {
        setIsDeliveryAddressInvalid(true);
        onShopActionFailure(); // scroll to top
        return;
      }

      const ds = getDeliveryScheduleByAddress(deliveryAddress, store);

      const providerStoreId = ds?.stores_provider_id;

      if (!providerStoreId) {
        setIsDeliveryAddressInvalid(true);
        onShopActionFailure(); // scroll to top
        return;
      }

      setStoreAddress(addressAsString(deliveryAddress));
      setStoredState({
        delivery_address: deliveryAddress ?? {
          address1: '',
          address2: '',
          city: '',
          city_code: '',
          country: '',
          country_code: '',
          province: '',
          province_code: '',
          zip: '',
        },
      });

      const isChangingStore = selectedStoreId !== treez_store_name;
      if (line_items.length && isChangingStore) {
        const { hasOutsideProducts } = await checkIfProductsBelongsToStore(
          line_items,
          selectedStoreId,
        );

        if (hasOutsideProducts) {
          setState({
            is_store_location_open: false,
          });
          showStoreValidator(providerStoreId);
          return;
        }
      }

      setStoreAddress(addressAsString(deliveryAddress));
      setStoredState({
        delivery_details: {
          ...delivery_details,
          delivery: !!deliveryAddress?.address1,
          pickup: false,
        },
        treez_store_name: providerStoreId,
      });

      setShowOverlay(false);
    }

    // handle pickup
    if (isPickup) {
      if (!selectedStoreId || !selectedStore?.pickup) {
        onShopActionFailure(); // scroll to top
        setIsPickupInvalid(true);
        return;
      }

      const isChangingStore = selectedStoreId !== treez_store_name;
      if (line_items.length && isChangingStore) {
        const { hasOutsideProducts } = await checkIfProductsBelongsToStore(
          line_items,
          selectedStoreId,
        );
        if (hasOutsideProducts) {
          setState({
            is_store_location_open: false,
          });
          showStoreValidator();
          return;
        }
      }

      setStoredState({
        provider_inventory_location_id: null,
        delivery_details: {
          ...delivery_details,
          delivery: false,
          pickup: !!selectedStoreId,
        },
        treez_store_name: selectedStoreId,
      });

      setShowOverlay(false);
    }

    measureSurfsideChangeLocation(selectedStoreId);
    // close the store locator
    setState({ is_store_location_open: false });
  };

  const handleLocationClick = () => {
    router.push('/store-locator');
    setState({ is_store_location_open: false });
  };

  const isButtonDisabled =
    !selectedStoreId ||
    (isDelivery && isDeliveryAddressInvalid) ||
    (isDelivery && !deliveryAddress?.address1);

  return (
    <div className={styles.store__locator_bottom}>
      <Button
        className={styles.shop_button}
        color="primary"
        disabled={isButtonDisabled}
        onClick={handleShopNow}
        size="md"
      >
        {`Shop ${isDelivery ? 'Delivery' : 'Pickup'}`}
      </Button>
      {!isStartUpPlan && (
        <Button
          className={styles.button_location}
          color="secondary"
          onClick={handleLocationClick}
          size="md"
        >
          <Icon name="pin_drop" />
          View all locations
        </Button>
      )}
    </div>
  );
};

export { StoreLocatorBottom };
