import Router from 'next/router';
import NProgress from 'nprogress';

NProgress.configure({ showSpinner: false });

Router.events.on(
  'routeChangeStart',
  (_url, { shallow }) => !shallow && NProgress.start(),
);
Router.events.on(
  'routeChangeComplete',
  (_url, { shallow }) => !shallow && NProgress.done(),
);
Router.events.on(
  'routeChangeError',
  (_url, { shallow }) => !shallow && NProgress.done(),
);

function Progress() {
  return null;
}

export default Progress;
