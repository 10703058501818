import { gql } from '@apollo/client';

export const LIST_STORE_APPS_QUERY = gql`
  query listStoreApps(
    $account_id: String!
    $store_id: String!
    $status: Boolean
    $category: AppCategoryType
  ) {
    listStoreApps(
      account_id: $account_id
      store_id: $store_id
      status: $status
      category: $category
    ) {
      id
      name
      handler
      status
      category
      ... on Metafield {
        metafield
      }
      ... on MinZipByCode {
        locations {
          name
          zips
          amount
        }
      }
      ... on Klaviyo {
        lists {
          name
          id
        }
      }
    }
  }
`;

export const REQUEST_MEASURE_MUTATION = gql`
  mutation requestMeasure(
    $account_id: String!
    $store_id: String!
    $input: RequestMeasureInput!
  ) {
    requestMeasure(
      account_id: $account_id
      store_id: $store_id
      input: $input
    ) {
      status
      error
    }
  }
`;
