import { useBundleSpecial } from '@/hooks';
import { evaluate } from '@/utils';
import { Heading, useData } from '@/components';
import styles from './specialbanner.module.scss';
import { PromoCarouselDocumentDataCarouselItemsItem } from 'prismicio-types';
import { asText } from '@prismicio/client';

interface ISpecialBanner {
  bundleId: string;
}

const SpecialBanner = ({ bundleId }: ISpecialBanner) => {
  const { promoCarousel } = useData();
  const { special: treezPreCartDiscount } = useBundleSpecial({ id: bundleId });

  if (!bundleId) {
    return null;
  }

  // find the discount item in the promo
  // carousel by title
  let cmsDiscountItem = promoCarousel.carousel_items.find(
    (s: PromoCarouselDocumentDataCarouselItemsItem) =>
      s?.pos_title_name === treezPreCartDiscount?.title,
  );

  // check if there is a default discount item
  if (!cmsDiscountItem) {
    cmsDiscountItem = promoCarousel.carousel_items.find(
      (s: PromoCarouselDocumentDataCarouselItemsItem) =>
        s?.pos_title_name === 'default',
    );
  }

  const evaluateSpecial = evaluate(cmsDiscountItem, treezPreCartDiscount);
  const title = evaluateSpecial?.title ?? evaluateSpecial?.pos_title_name;

  return (
    <div className={styles.specialbanner}>
      <Heading className={styles.specialbanner__title} level={1}>
        {title}
      </Heading>
      <p>{asText(evaluateSpecial?.description)}</p>
    </div>
  );
};

export default SpecialBanner;
