import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Input } from '@/components';

function Contact({ onContact, className, ...props }) {
  const ref = useRef(null);
  const [inputs, setInputs] = useState({
    email: '',
    message: '',
    name: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setInputs(oldInputs => ({
      ...oldInputs,
      [e.target.name]: e.target.value,
    }));
  };

  const handleContact = e => {
    if (ref.current.checkValidity()) {
      e.preventDefault();

      setLoading(true);

      Promise.resolve(onContact(inputs)).then(() => {
        setInputs({
          email: '',
          message: '',
          name: '',
        });
        setLoading(false);
      });
    }
  };

  return (
    <form className={classNames('contact', className)} ref={ref} {...props}>
      <Input name="name" onChange={handleChange} required value={inputs.name}>
        Your name
      </Input>
      <Input
        name="email"
        onChange={handleChange}
        required
        type="email"
        value={inputs.email}
      >
        Email address
      </Input>
      <Input
        name="message"
        onChange={handleChange}
        required
        rows={4}
        type="textarea"
        value={inputs.message}
      >
        Write your message here
      </Input>
      <Button
        color="primary"
        loading={loading}
        onClick={handleContact}
        type="submit"
      >
        Send message
      </Button>
    </form>
  );
}

export default Contact;
