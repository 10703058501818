import { useMutation, useQuery } from '@apollo/client';
import {
  APPLY_PROMO_CODE_MUTATION,
  LIST_ACTIVE_PROMOTIONS_QUERY,
} from './schema';

export function useListActivePromotions(options) {
  const { data, error, loading } = useQuery(
    LIST_ACTIVE_PROMOTIONS_QUERY,
    options,
  );

  return {
    loading: loading,
    data: data?.listActivePromotions,
    error: error,
  };
}

export function useApplyPromoCode(options) {
  const [applyPromoCode, result] = useMutation(
    APPLY_PROMO_CODE_MUTATION,
    options,
  );

  return {
    applyPromoCode,
    result,
  };
}
