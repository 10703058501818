import { FC } from 'react';

import { Product } from '@/types';
import { Heading } from '@/components';

interface IProductDescriptionProps {
  product: Product;
}

const ProductDescription: FC<IProductDescriptionProps> = ({ product }) => {
  const description = product?.description;

  if (!description) {
    return null;
  }

  return (
    <>
      <Heading className={'details__h3'} level={3}>
        Product descriptions
      </Heading>
      <div
        className={'details__description'}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </>
  );
};

export { ProductDescription };
