import { useEffect, useMemo, useState } from 'react';

import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react';
import { useFeaturedFlag } from '@/hooks';

import { JaneSpecial } from '@/types';
import { Heading, Icon, Placeholder } from '@/components';
import { useSpecialsContext } from '@/context';

import styles from './DiscountsPopup.module.scss';
import classNames from 'classnames';

const DiscountsPopup = () => {
  const { specials } = useSpecialsContext();

  const [isLoading, setIsLoading] = useState(true);

  const veteranDiscounts = useMemo(
    () => filterByType(specials, 'qualified_group'),
    [specials],
  );

  const bundleDiscounts = useMemo(
    () => filterByType(specials, 'bundle'),
    [specials],
  );

  const { isDiscountsList } = useFeaturedFlag();

  const renderPanel = (discounts, title) => {
    return (
      <PopoverPanel
        className={classNames(styles.discounts__panel, {
          [styles.discounts__panel_large]:
            veteranDiscounts?.length && bundleDiscounts?.length,
        })}
      >
        <Heading className={styles.discounts__title} level={3}>
          {title}
        </Heading>
        <ul className={styles.discounts__list}>
          {discounts.map(({ description, title }, index) => {
            return (
              <li className={styles.discounts__item} key={index}>
                <Icon name="offer" /> {description ? description : title}
              </li>
            );
          })}
        </ul>
        <div
          className={classNames(styles.peak, {
            [styles.peak_large]:
              veteranDiscounts?.length && bundleDiscounts?.length,
          })}
        ></div>
      </PopoverPanel>
    );
  };

  useEffect(() => {
    setIsLoading(false);
  }, [veteranDiscounts, bundleDiscounts]);

  if (
    (!isLoading && !veteranDiscounts?.length && !bundleDiscounts?.length) ||
    isDiscountsList
  ) {
    return null;
  }

  return (
    <div className={styles.discounts}>
      {isLoading ? (
        <Placeholder
          style={{
            borderRadius: '16px',
            height: '32px',
            marginBottom: '0.5rem',
            width: '180px',
          }}
        />
      ) : (
        <PopoverGroup className={styles.discounts_badge}>
          <Icon name="offer-solid" />
          {bundleDiscounts?.length ? (
            <Popover>
              <PopoverButton
                aria-label="Show bundle discounts"
                className={styles.discounts_badge__underline}
              >
                Bundle discounts
              </PopoverButton>
              {renderPanel(bundleDiscounts, 'Bundle discounts')}
            </Popover>
          ) : null}
          {veteranDiscounts?.length && bundleDiscounts?.length ? (
            <span>&</span>
          ) : null}
          {veteranDiscounts?.length ? (
            <Popover>
              <PopoverButton
                aria-label="Show veteran discounts"
                className={styles.discounts_badge__underline}
              >
                {`Veteran ${!bundleDiscounts.length ? 'discounts' : ''}`}
              </PopoverButton>
              {renderPanel(veteranDiscounts, 'Veteran discounts')}
            </Popover>
          ) : null}
        </PopoverGroup>
      )}
    </div>
  );
};

const filterByType = (
  specials: JaneSpecial[],
  type: 'qualified_group' | 'bundle',
) => specials?.filter(({ special_type }) => special_type === type);

export { DiscountsPopup };
