import { SortBySelectOption } from '@/types';

export const SORT_BY_FIELD_OPTION: SortBySelectOption[] = [
  {
    label: 'Price Low to High',
    value: { field: 'price', order: 'asc' },
  },
  {
    label: 'Price High to Low',
    value: { field: 'price', order: 'desc' },
  },
  {
    label: 'THC High to Low',
    value: { field: 'totalThcPercent', order: 'desc' },
  },
  {
    label: 'THC Low to High',
    value: { field: 'totalThcPercent', order: 'asc' },
  },
  // {
  //   label: 'Type A to Z',
  //   value: { field: 'productTypeName', order: 'asc' },
  // },
  // {
  //   label: 'Type Z to A',
  //   value: { field: 'productTypeName', order: 'desc' },
  // },
  {
    label: 'Brand A to Z',
    value: { field: 'brand', order: 'asc' },
  },
  {
    label: 'Brand Z to A',
    value: { field: 'brand', order: 'desc' },
  },
  // {
  //   label: 'Subtype (Asc)',
  //   value: { field: 'subType', order: 'asc' },
  // },
  // {
  //   label: 'Subtype (Desc)',
  //   value: { field: 'subType', order: 'desc' },
  // },
  {
    label: 'Classification A to Z',
    value: { field: 'classification', order: 'asc' },
  },
  {
    label: 'Classification Z to A',
    value: { field: 'classification', order: 'desc' },
  },
  // {
  //   label: 'Generals (Asc)',
  //   value: { field: 'generals', order: 'asc' },
  // },
  // {
  //   label: 'Generals (Desc)',
  //   value: { field: 'generals', order: 'desc' },
  // },
  // {
  //   label: 'Effects (Asc)',
  //   value: { field: 'effects', order: 'asc' },
  // },
  // {
  //   label: 'Effects (Desc)',
  //   value: { field: 'effects', order: 'desc' },
  // },
  // {
  //   label: 'Flavors (Asc)',
  //   value: { field: 'flavors', order: 'asc' },
  // },
  // {
  //   label: 'Flavors (Desc)',
  //   value: { field: 'flavors', order: 'desc' },
  // },
];
