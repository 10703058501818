import { FC } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { Container } from '@/components';
import { getSlicePadding, htmlSerializer } from '@/utils';
import { DualTextSlice } from '@/types';
import styles from './dualtext.module.scss';
import classNames from 'classnames';
import { useShowByLocationSlug } from 'hooks/useFilteredSlicesItemByLocationSlug';

const DualText: FC<DualTextSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const backgroundColor = slice?.primary?.background_color;
  const textColor = slice?.primary?.font_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(
        styles.dualtext__section,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: backgroundColor!,
        color: textColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={styles.container}>
        <div
          className={'content'}
          style={{
            // eslint-disable-next-line no-undef
            textAlign: slice?.primary?.align_block_left as CanvasTextAlign,
          }}
        >
          <PrismicRichText
            components={htmlSerializer}
            field={slice?.primary?.left_block}
          />
        </div>
        <div
          className={'content'}
          style={{
            // eslint-disable-next-line no-undef
            textAlign: slice?.primary?.align_block_right as CanvasTextAlign,
          }}
        >
          <PrismicRichText
            components={htmlSerializer}
            field={slice?.primary?.right_block}
          />
        </div>
      </Container>
    </section>
  );
};

export default DualText;
