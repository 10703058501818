import { FC, useState } from 'react';

import classNames from 'classnames';

import { Product } from '@/types';
import { Badge, Collapse, Heading, Icon } from '@/components';
import { getFeelingIconName } from 'utils/treez';

interface IProductExtraDescriptionsProps {
  product: Product;
}

const ProductExtraDescriptions: FC<IProductExtraDescriptionsProps> = ({
  product,
}) => {
  /* const [isGeneralOpen, setIsGeneralOpen] = useState(false); */
  const [isFlavorsOpen, setIsFlavorsOpen] = useState(true);
  const [isEffectsOpen, setIsEffectsOpen] = useState(true);

  /* const toggleGenerals = () => {
    setIsGeneralOpen(!isGeneralOpen);
  }; */
  const toggleFlavors = () => {
    setIsFlavorsOpen(!isFlavorsOpen);
  };
  const toggleEffects = () => {
    setIsEffectsOpen(!isEffectsOpen);
  };

  return (
    <>
      {/* {product.generalAttributes && product.generalAttributes.length > 0 && (
        <>
          <button
            aria-controls="accordion-content-iGeneral"
            className="bottom__warning"
            onClick={() => toggleGenerals()}
          >
            <Heading className={'extra_details__subtitle'} level={4}>
              General Attributes
            </Heading>
            <Icon name={isGeneralOpen ? 'angle-up' : 'angle-down'} />
          </button>
          <Collapse
            className={classNames('accordion__content', {
              ['accordion__content_collapse']: isGeneralOpen,
            })}
            id="accordion-content-iWarning"
            isOpen={isGeneralOpen}
          >
            <div className={'extra__content'}>
              {product.generalAttributes.map(general => (
                <Badge className={'extra_details__badge'} key={general}>
                  <span>{general}</span>
                </Badge>
              ))}
            </div>
          </Collapse>
        </>
      )} */}

      {product.flavors && product.flavors.length > 0 && (
        <>
          <button
            aria-controls="accordion-content-iFlavors"
            className="bottom__warning"
            onClick={() => toggleFlavors()}
          >
            <Heading
              className={classNames('details__h3', 'extra_details__subtitle')}
              level={3}
            >
              Flavors
            </Heading>
            <Icon name={isFlavorsOpen ? 'angle-up' : 'angle-down'} />
          </button>
          <Collapse
            className={classNames('accordion__content', {
              ['accordion__content_collapse']: isFlavorsOpen,
            })}
            id="accordion-content-iWarning"
            isOpen={isFlavorsOpen}
          >
            <div className={'extra__content'}>
              {product.flavors.map(flavor => (
                <Badge className={'extra_details__badge'} key={flavor}>
                  <span>{flavor}</span>
                </Badge>
              ))}
            </div>
          </Collapse>
        </>
      )}

      {product.effects && product.effects.length > 0 && (
        <>
          <button
            aria-controls="accordion-content-iEffects"
            className="bottom__warning"
            onClick={() => toggleEffects()}
          >
            <Heading
              className={classNames('details__h3', 'extra_details__subtitle')}
              level={3}
            >
              Effects
            </Heading>
            <Icon name={isEffectsOpen ? 'angle-up' : 'angle-down'} />
          </button>
          <Collapse
            className={classNames('accordion__content', {
              ['accordion__content_collapse']: isEffectsOpen,
            })}
            id="accordion-content-iWarning"
            isOpen={isEffectsOpen}
          >
            <div className={'extra__content'}>
              {product.effects.map(effect => (
                <Badge className={'extra_details__badge'} key={effect}>
                  <Icon
                    className="item_icon"
                    name={getFeelingIconName(effect)}
                  />
                  <span>{effect}</span>
                </Badge>
              ))}
            </div>
          </Collapse>
        </>
      )}
    </>
  );
};

export { ProductExtraDescriptions };
