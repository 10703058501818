import { createContext, FC, PropsWithChildren, use, useState } from 'react';

import { GProduct, ProductSuggestion } from '@/types';
import { useDebounced } from '@/hooks';

import {
  useSearchProductsByTerm,
  useSearchProductsSuggestions,
} from '../hooks';

interface SearchContextState {
  suggestions: ProductSuggestion[];
  isLoadingSuggestions: boolean;
  products: GProduct[];
  isLoadingProducts: boolean;
  searchTerm: string;
  errorProductsSuggestions: Error | null;
  isErrorProductsSuggestions?: boolean;
  isErrorProductsByTerm?: boolean;
  errorProductsByTerm: Error | null;

  updateSearchTerm(_term: string): void;
}

const SearchContext = createContext<SearchContextState | undefined>(undefined);

SearchContext.displayName = 'Search Context';

export const SearchProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounced(searchTerm, 300);

  const {
    suggestions,
    isLoading: isLoadingSuggestions,
    isError: isErrorProductsSuggestions,
    error: errorProductsSuggestions,
  } = useSearchProductsSuggestions(debouncedSearchTerm);

  const {
    products,
    isLoading: isLoadingProducts,
    isError: isErrorProductsByTerm,
    error: errorProductsByTerm,
  } = useSearchProductsByTerm(debouncedSearchTerm);

  return (
    <SearchContext
      value={{
        isLoadingProducts,
        isLoadingSuggestions,
        products,
        searchTerm,
        errorProductsSuggestions,
        isErrorProductsByTerm,
        isErrorProductsSuggestions,
        errorProductsByTerm,
        suggestions,
        updateSearchTerm: setSearchTerm,
      }}
    >
      {children}
    </SearchContext>
  );
};

const useSearchContext = (label: string) => {
  const searchContext = use(SearchContext);

  if (!searchContext) {
    throw new Error(`${label} must be used within a SearchProvider`);
  }

  return searchContext;
};

export const useSearchTerm = () => {
  const { searchTerm, updateSearchTerm } = useSearchContext('useSearchTerm');

  return { searchTerm, updateSearchTerm };
};

export const useSearchSuggestions = () => {
  const {
    suggestions,
    isLoadingSuggestions,
    isErrorProductsSuggestions,
    errorProductsSuggestions,
  } = useSearchContext('useSearchSuggestions');

  return {
    isLoadingSuggestions,
    suggestions,
    isErrorProductsSuggestions,
    errorProductsSuggestions,
  };
};

export const useSearchProducts = () => {
  const {
    products,
    isLoadingProducts,
    isErrorProductsByTerm,
    errorProductsByTerm,
  } = useSearchContext('useSearchProducts');

  return {
    isLoadingProducts,
    products,
    isErrorProductsByTerm,
    errorProductsByTerm,
  };
};
