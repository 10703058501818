/* eslint-disable sort-keys */
import { Icon, Rating } from '../../components';
import style from './ratingreviews.module.scss';

function RatingReviews({ /*reviews*/ className /*...props*/ }) {
  const rating = parseFloat(4.1);
  const countReviews = 50;
  const listReviews = [
    { id: 5, percentage: '80%', count: 20 },
    { id: 4, percentage: '10%', count: 5 },
    { id: 3, percentage: '5%', count: 5 },
    { id: 2, percentage: '2%', count: 5 },
    { id: 1, percentage: '3%', count: 5 },
  ];

  return (
    <div className={`${style.ratingreviews} ${className ?? ''}`}>
      <h2 className={style.rating__heading}>Rating and reviews</h2>
      <div className={style.rating__puntuation}>
        <strong>{rating}</strong>
        <Rating value={rating} />
        {`${countReviews} reviews`}
      </div>
      <ol>
        {listReviews.map((review, i) => (
          <li className={style['rating__li-review']} key={(review, i)}>
            <Icon name="star" />
            <span className={style.rating__number}>{5 - i}</span>
            <div
              aria-label={`Rating for ${5 - i}`}
              aria-valuemax="100"
              aria-valuemin="0"
              aria-valuenow={review.percentage}
              className={style['rating__meter-bar']}
              role="meter"
            >
              <div
                className={style.rating__progress}
                style={{ width: review.percentage }}
              />
            </div>
            <span>{review.count}</span>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default RatingReviews;
