import { FC } from 'react';

import { PrismicNextImage } from '@prismicio/next';
import { ImageRowSliceDefault } from '@/types';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';
import classNames from 'classnames';

import style from '../imagerow.module.scss';

export const DefaultVariation: FC<{
  slice: ImageRowSliceDefault;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);

  return filteredItems?.map(({ image }, index) => {
    return (
      <figure className={classNames(style.gallery_image__item)} key={index}>
        <PrismicNextImage
          fallbackAlt=""
          field={image}
          style={{ objectFit: 'cover', objectPosition: 'center center' }}
          width={428}
        />
      </figure>
    );
  });
};
