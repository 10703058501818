import { gql } from '@apollo/client';

export const GET_BEST_SELLING_PRODUCTS_QUERY = gql`
  query getBestSellingProducts(
    $account_id: String!
    $store_id: String!
    $count: Int
  ) {
    getBestSellingProducts(
      account_id: $account_id
      store_id: $store_id
      count: $count
    ) {
      id
    }
  }
`;

export const LIST_PRODUCTS_QUERY = gql`
  query listProducts($account_id: String!, $store_id: String!) {
    listProducts(account_id: $account_id, store_id: $store_id) {
      id
      sku
      name
      enabled
      description
      category
      category_id
      flower_type
      in_stock
      brand_id
      brand_name
      related_products
      review_rating
      slug
      thc {
        percentage
      }
      cbd {
        percentage
      }
      variants {
        id
        name
        price
        salePrice
        type
      }
      asset {
        thumbnail
        image
        image_medium_size
      }
    }
  }
`;

export const SEARCH_PRODUCT_QUERY = gql`
  query searchProduct(
    $account_id: String!
    $store_id: String!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $query: String
    $sortKey: ProductSortKeys
  ) {
    searchProduct(
      account_id: $account_id
      store_id: $store_id
      after: $after
      before: $before
      first: $first
      last: $last
      query: $query
      sortKey: $sortKey
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          sku
          name
          enabled
          description
          category
          category_id
          flower_type
          in_stock
          brand_id
          brand_name
          related_products
          review_rating
          slug
          thc {
            percentage
          }
          cbd {
            percentage
          }
          variants {
            id
            name
            price
            salePrice
            type
          }
          asset {
            thumbnail
            image
            image_medium_size
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const GET_PRODUCT_BY_URL_QUERY = gql`
  query getProductByPath(
    $account_id: String!
    $store_id: String!
    $path: String!
  ) {
    getProductByPath(
      account_id: $account_id
      store_id: $store_id
      path: $path
    ) {
      id
      sku
      name
      enabled
      description
      category
      category_id
      flower_type
      in_stock
      brand_id
      brand_name
      related_products
      review_rating
      slug
      thc {
        percentage
      }
      cbd {
        percentage
      }
      variants {
        id
        name
        price
        salePrice
        type
      }
      asset {
        thumbnail
        image
        image_medium_size
      }
    }
  }
`;

export const GET_PRODUCT_REVIEW_FROM_JANE_QUERY = gql`
  query getProductReviewFromJane(
    $account_id: String!
    $store_id: String!
    $id: String!
  ) {
    getProductReviewFromJane(
      account_id: $account_id
      store_id: $store_id
      id: $id
    ) {
      has_comment
      rating
      avatar
      upvote_count
      nickname
      comment
      created_at
      updated_at
    }
  }
`;

export const QUERY_GET_PRODUCT_FROM_JANE = gql`
  query getProductFromJane(
    $account_id: String!
    $store_id: String!
    $product_id: String!
    $stores: [String!]!
  ) {
    getProductFromJane(
      account_id: $account_id
      store_id: $store_id
      product_id: $product_id
      stores: $stores
    ) {
      id
      name
      enabled
      url
      slug
      description
      category
      category_id
      thc {
        percentage
      }
      thca {
        percentage
      }
      cbd {
        percentage
      }
      cbda {
        percentage
      }
      qty
      brand_name
      brand_id
      brand_subtype
      in_stock
      sales_price
      flower_type
      cannabis_type
      available_for_delivery
      available_for_pickup
      max_cart_quantity
      review_rating
      reviews
      asset {
        stock
        thumbnail
        image
        image_large_size
        image_medium_size
        image_extra_large_size
      }
      assets {
        stock
        thumbnail
        image
        image_large_size
        image_medium_size
        image_extra_large_size
      }
      variants {
        id
        name
        price
        salePrice
        type
      }
      dosage
      amount
      special_amount
      special_id
      special_title
    }
  }
`;
