import { GET_DISTANCE_QUERY } from './schema';

export async function getDistance(client, options = {}) {
  const response = await client.query({
    query: GET_DISTANCE_QUERY,
    ...options,
  });

  return {
    data: response.data?.getDistance,
    error: response.error,
  };
}
