import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './NumericMenu.module.scss';
import { useProductsContext } from '@/components';

const NumericMenu = ({
  items,
  hasNoResults = false,
  attribute,
  isActive,
  setActive,
}) => {
  const { activeFilters, refine } = useProductsContext();
  const [itemRefined, setItemRefined] = useState(items[0].label);

  useEffect(() => {
    if (!isActive && itemRefined !== items[0].label) {
      handleChange(items[0]);
    }
  }, [isActive]);

  const handleChange = item => {
    setItemRefined(item.label);

    if (item.label === items[0].label && isActive) {
      setActive(false);
    }

    if (item.label !== items[0].label && !isActive) {
      setActive(true);
    }

    if (!item.value) {
      refine(attribute, '', false, true);
    } else {
      refine(
        attribute,
        item.value,
        !activeFilters[attribute]?.[item.value],
        true,
      );
    }
  };

  return (
    <div
      className={classNames(
        styles.numeric_menu,
        'ais-NumericMenu',
        hasNoResults && 'ais-NumericMenu--noRefinement',
      )}
    >
      <ul className="ais-NumericMenu-list">
        {items.map(item => {
          const isItemRefined = item.label === itemRefined;

          return (
            <li
              className={classNames(
                styles.numeric_menu_item,
                'ais-NumericMenu-item',
                isItemRefined && 'ais-NumericMenu-item--selected',
              )}
              key={item.label}
            >
              <label className={styles.numeric_menu_label}>
                <input
                  checked={isItemRefined}
                  className={styles.numeric_menu_radio}
                  onChange={() => handleChange(item)}
                  type="radio"
                />
                <span className="ais-NumericMenu-labelText">{item.label}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { NumericMenu };
