/* eslint-disable max-len */
import { FC, useState } from 'react';

import Slider from 'react-slick';
import classNames from 'classnames';

import { Image } from '@/components';
import { Product } from '@/types';

interface IProductImagesProps {
  product: Product;
}

const ProductImages: FC<IProductImagesProps> = ({ product }) => {
  const [pictureSlideIndex, setPictureSlideIndex] = useState(0);
  const [load, setLoad] = useState(true);
  const stockPhoto = product?.asset?.stock ?? false;

  const loaderImage = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <div className={'details__images'}>
      <Slider
        adaptiveHeight={true}
        arrows={true}
        beforeChange={(_, next) => {
          setPictureSlideIndex(next);
        }}
        centerMode={false}
        centerPadding={'8px'}
        customPaging={i => {
          return (
            <figure key={`pagination-${i}`}>
              <Image
                alt={product?.name as string}
                fallback="/images/product-placeholder.svg"
                height={90}
                id={product?.id as string}
                sizes="(min-width: 1024px) 90px, 70px"
                src={product?.assets?.[i].image as string}
                style={{ objectFit: 'contain' }}
                width={90}
              />
            </figure>
          );
        }}
        dots={true}
        dotsClass={'slick-thumb'}
        fade={!((product?.assets?.length as number) > 1)}
        infinite={false}
        initialSlide={0}
        key={product?.id}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
        slidesToScroll={1}
        slidesToShow={2}
        speed={500}
      >
        {product?.assets?.map((asset, i) => (
          <figure
            className={classNames('slide_main', {
              ['slide_main__load']: load,
            })}
            hidden={pictureSlideIndex !== i ? true : undefined}
            key={`image-${i}`}
          >
            <Image
              alt={product.name as string}
              blurDataURL={
                "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 1 1'%3E%3Crect fill='#dedede' /%3E%3C/svg%3E\")"
              }
              fallback="/images/product-placeholder.svg"
              fill
              id={product.id as string}
              loader={loaderImage}
              onLoad={() => {
                setLoad(false);
              }}
              placeholder="blur"
              priority
              quality={100}
              src={asset?.image as string}
              //sizes="(min-width: 2048px) 468px, (min-width: 1536px) 464px, (min-width: 1280px) 408px, (min-width: 1024px) 438px, (min-width: 768px) 310px, (min-width: 640px) 600px, 100wv"
              style={{ mixBlendMode: 'multiply', objectFit: 'contain' }}
            />
            {stockPhoto && (
              <span className="product__in_stock">Stock photo</span>
            )}
          </figure>
        ))}
      </Slider>
    </div>
  );
};

export { ProductImages };
