import { FC } from 'react';

import { ArticleList, FiltersBlog, Heading } from '@/components';

import { BlogPostDocument, IBlogTags } from '@/types';
import classNames from 'classnames';
import { IBlogProps } from 'app/(Storefront)/(Shop)/(Content)/blog/page';

const CollectionPosts: FC<ICollectionPosts> = ({
  articlesList,
  filterOptions,
  props,
  hasPagination,
  numberPostPerPage,
  searchTerm,
}) => {
  return (
    <>
      <Heading
        className="blog__collection_title"
        level={2}
        style={{ color: props?.sectionTitleColor || undefined }}
      >
        {props?.postCollection || 'All Posts'}
      </Heading>
      <div
        className={classNames('blog__section', {
          blog__section__grid: Object.keys(filterOptions).length > 0,
        })}
      >
        <FiltersBlog filterOptions={filterOptions} />
        <ArticleList
          articlesList={articlesList}
          hasPagination={hasPagination}
          numberPostPerPage={numberPostPerPage}
          searchTerm={searchTerm}
        />
        <style>{`
          .article {
            h5 {
              color: ${props?.post_title_color} !important;
            }

            time, i {
              color: ${props?.textColor} !important;
            }
          }
          .blog__filters {            
            p, li, span, a, i {
              color: ${props?.textColor} !important;
            }

            h5 {
              color: ${props?.post_title_color} !important;
            }
          }
          
          .blog__container_empty {
             i, p {
              color: ${props?.textColor} !important;
             }
          }
        `}</style>
      </div>
    </>
  );
};

interface ICollectionPosts {
  articlesList: BlogPostDocument<string>[];
  filterOptions: IBlogTags;
  props: IBlogProps;
  numberPostPerPage: number;
  hasPagination: boolean;
  searchTerm: string;
}

export { CollectionPosts };
