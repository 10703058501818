import { fetchAuthSession } from 'aws-amplify/auth';
import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

export const storeMiddleware = new ApolloLink((operation, forward) => {
  operation.variables = {
    ...(operation.variables ?? {}),
    account_id: process.env.NEXT_PUBLIC_ACCOUNT_ID,
    store_id: process.env.NEXT_PUBLIC_STORE_ID,
  };

  return forward(operation);
});

export const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) =>
      console.error(`[GraphQL error]: ${message}`),
    );
  }

  if (networkError) {
    console.error(`[Network error]: ${JSON.stringify(networkError)}`);
  }
});

export const authMiddleware = setContext((_, { headers }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  // Get the authentication token from client if exists
  return fetchAuthSession()
    .then(session => {
      return {
        headers: {
          ...headers,
          ...(session?.tokens?.accessToken
            ? { Authorization: session?.tokens?.accessToken?.toString() }
            : {}),
        },
      };
    })
    .catch(() => null);
});
