/* eslint-disable max-len */
import { CSSProperties, FC, useState } from 'react';
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage,
} from 'react-compare-slider';
import { asText } from '@prismicio/client';
import classNames from 'classnames';
import { Heading } from '@/components';
import { ImageRowSliceImageComparisonSlider } from '@/types';
import { convertHexadecimalFormatToRGB } from '@/utils';
import { useFilteredSliceItemsByLocationSlug } from '@/hooks';

import styles from './imageComparisonSlider.module.scss';

export const ImageComparisonSlider: FC<{
  slice: ImageRowSliceImageComparisonSlider;
}> = ({ slice }) => {
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);
  const [labelOpacity, setLabelOpacity] = useState<number | null>(null);
  const itemPerRow = slice?.primary?.items_per_row ?? 3;
  const cardMaxWidth = slice?.primary?.card_max_width ?? 300;
  const realItemPerRow = itemPerRow > 5 ? 'auto-fill' : itemPerRow;
  const maxWidth = itemPerRow > 3 && cardMaxWidth > 300 ? '100%' : undefined;
  const afterLabel = slice?.primary?.after_label ?? 'After';
  const beforeLabel = slice?.primary?.before_label ?? 'Before';
  const separatorColor = slice?.primary?.separator_color || '#0d0d0d';
  const labelBackground = convertHexadecimalFormatToRGB(
    slice?.primary?.label_background || '#ffffff',
  );
  const labelColor = slice?.primary?.label_color || '#0d0d0d';

  const labelStyle = {
    color: labelColor,
    backgroundColor: `rgba(${labelBackground?.r}, ${labelBackground?.g}, ${labelBackground?.b}, 0.5)`,
  };

  return (
    <>
      <div
        style={{
          textAlign: slice?.primary?.header_text_alignment,
        }}
      >
        {asText(slice?.primary?.title) && (
          <Heading
            className={styles.title}
            color={slice?.primary?.title_color ?? ''}
            level={2}
          >
            {asText(slice?.primary?.title)}
          </Heading>
        )}
        {asText(slice?.primary?.description) && (
          <p className={styles.content__header_description}>
            {asText(slice?.primary?.description)}
          </p>
        )}
      </div>
      <div
        className={classNames(
          styles.image__comparison_container,
          'image__comparison_container',
        )}
      >
        {filteredItems?.map((item, index) => {
          return (
            <figure
              className={styles.image__comparison_item}
              key={index}
              style={
                {
                  '--icons-width': maxWidth,
                } as CSSProperties
              }
            >
              <ReactCompareSlider
                handle={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <ReactCompareSliderHandle
                      buttonStyle={{ borderColor: separatorColor! }}
                      style={{
                        color: separatorColor!,
                      }}
                    />
                    <span
                      className={styles.label}
                      style={
                        {
                          ...labelStyle,
                          opacity: labelOpacity === index ? 0 : 1,
                          translate: '-100% 0',
                          left: 0,
                        } as CSSProperties
                      }
                    >
                      {beforeLabel}
                    </span>
                    <span
                      className={styles.label}
                      style={
                        {
                          ...labelStyle,
                          opacity: labelOpacity === index ? 0 : 1,
                          translate: '100% 0',
                          right: 0,
                        } as CSSProperties
                      }
                    >
                      {afterLabel}
                    </span>
                  </div>
                }
                itemOne={
                  <ReactCompareSliderImage
                    alt={beforeLabel!}
                    className={styles.images}
                    src={item.before_image?.url!}
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    alt={afterLabel!}
                    className={styles.images}
                    src={item.after_image?.url!}
                  />
                }
                onPointerDown={() => setLabelOpacity(index)}
                onPointerUp={() => setLabelOpacity(null)}
              />
            </figure>
          );
        })}
      </div>
      <style>
        {`
          .image__comparison_container {
              @media (min-width: 1024px) {
                grid-template-columns: repeat(${realItemPerRow}, 1fr) !important;
              }
              @media (min-width: 1280px) {
                grid-template-columns: repeat(${realItemPerRow}, minmax(1fr, ${cardMaxWidth}px)) !important;
                max-width: ${maxWidth} !important;
              }
            }
        `}
      </style>
    </>
  );
};
