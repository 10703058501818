import { CSSProperties, FC } from 'react';
import classNames from 'classnames';
import { Container } from '@/components';
import { ContentEmbedSlice } from '@/types';
import { getSlicePadding } from '@/utils';
import { useIsMobile, useShowByLocationSlug } from '@/hooks';
import styles from './contentembed.module.scss';

const ContentEmbed: FC<ContentEmbedSlice> = slice => {
  const { isMobile } = useIsMobile();
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);

  const marginBottom = slice?.primary?.bottom_spacing ?? '';
  const paddingSection = slice?.primary?.padding_section ?? '';
  const background = slice?.primary?.background_color ?? '';
  const height = slice?.primary?.height ?? '';
  const horizontalAlign = slice?.primary?.horizontal_align ?? '';
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;

  const maxWidth = isMobile
    ? slice?.primary?.max_width_mobile ?? ''
    : slice?.primary?.max_width_desktop ?? '';

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  const textAlign = (alignment: string) => {
    switch (alignment) {
      case 'Left':
        return 'flex-start';

      case 'Right':
        return 'flex-end';

      default:
        return 'center';
    }
  };

  return (
    <section
      className={classNames(
        styles.content_embed,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
      }}
    >
      <Container
        className={styles.content_embed__container}
        style={{
          justifyContent: textAlign(horizontalAlign),
        }}
      >
        <div
          className={styles.content_embed__container_content}
          dangerouslySetInnerHTML={{
            __html: slice.primary.embed.html ?? '',
          }}
          style={
            {
              display: 'flex',
              justifyContent: textAlign(horizontalAlign),
              '--embedded-height': height,
              '--embedded-max-width': maxWidth,
            } as CSSProperties
          }
        />
      </Container>
    </section>
  );
};

export default ContentEmbed;
