export const ROUTERS_LIST_HEADER_OFFSET = [
  '/account',
  '/blog',
  '/blog/tag/',
  '/brands',
  '/orders/',
  '/product-group/',
  '/product/',
  '/special/',
  '/promotions/',
  '/store-locator',
  '/tags/',
  '/wishlist',
];
