'use client';
/* eslint-disable no-undef */
import { memo, useRef } from 'react';
import { useUiStoreSelector } from '@/data';
import { PopoverPanel, StoreLocatorProvider, useData } from '@/components';
import { HeaderSlice } from 'prismicio-types';
import { useFixedBodyOnPopup } from '@/hooks';

import {
  Overlay,
  StoreLocatorButton,
  StoreLocatorCenter,
  StoreLocatorTop,
} from './StoreLocator.components';

import style from './storelocator.module.scss';
import { StoreLocatorBottom } from './StoreLocatorBottom.components';
import classNames from 'classnames';

const StoreLocator = memo(() => {
  const { is_store_location_open, setState } = useUiStoreSelector([
    'is_store_location_open',
  ]);

  const {
    header: { data: header },
  } = useData();

  const activeSlice = (header?.slices || []).find(
    (s: HeaderSlice) => s?.primary?.active,
  );

  useFixedBodyOnPopup({
    isPopupOpen: is_store_location_open,
    onlyOnMobile: true,
  });

  const storesListRef = useRef<HTMLDivElement>(null);

  const scrollListToTop = () => {
    if (storesListRef) {
      storesListRef.current?.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  };

  const handlePopoverClose = event => {
    // Cancel the close returning false if the click
    // was over Google address autocomplete
    return (
      event.target.parentNode.className !== 'pac-item' &&
      event.target.parentNode.className !== 'pac-item-query' &&
      event.target.parentNode.className !== 'pac-container pac-logo' &&
      event.target.className !== 'pac-container pac-logo' &&
      event.target.className !== 'pac-matched'
    );
  };

  const handlePopoverToggle = () => setState({ is_store_location_open: false });

  return (
    <StoreLocatorProvider>
      <StoreLocatorButton />
      <PopoverPanel
        className={classNames(
          style.store__locator_form,
          style.store__locator_form__centered,
          {
            [style.store__locator_form__centered_logo_and_nav]:
              activeSlice?.variation === 'withCenteredLogoAndNav',
          },
        )}
        isOpen={is_store_location_open}
        onClose={handlePopoverClose}
        toggle={handlePopoverToggle}
      >
        <StoreLocatorTop />
        <StoreLocatorCenter
          ref={storesListRef}
          scrollListToTop={scrollListToTop}
        />
        <StoreLocatorBottom onShopActionFailure={scrollListToTop} />
      </PopoverPanel>
      <Overlay />
    </StoreLocatorProvider>
  );
});

export { StoreLocator };
