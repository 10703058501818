import { useEcomStore } from '@/data';
import {
  EcomState,
  Order,
  OrderAddress,
  OrderDelivery,
  OrderDiscountItem,
  PaymentDetail,
} from '@/types';

const useUpdateEcomStateFromOrder = () => {
  const [storedState, setStoredState] = useEcomStore();

  const updateEcomStateFromOrder = (order: Order): EcomState => {
    const updatedState = {
      entity_id: order?.entity_id ?? '',
      email: order?.email ?? '',

      // totals
      total_delivery: order?.total_delivery ?? 0,
      total_discounts: order?.total_discounts ?? 0,
      total_line_items_price: order?.total_line_items_price ?? 0,
      subtotal_price: order?.subtotal_price ?? 0,
      total_price: order?.total_price ?? 0,
      total_service_fee: order?.total_service_fee ?? 0,
      total_tax: order?.total_tax ?? 0,

      notes: order?.notes ?? '',

      date_of_birth: order?.date_of_birth,

      delivery_address: order?.delivery_address as OrderAddress,
      delivery_details: order.delivery_details as OrderDelivery,
      payment_details: order.payment_details as PaymentDetail,

      discount_items: order.discount_items as OrderDiscountItem[],

      medical_id_expiration_date: order.order_medical_id_expiration_date,
      medical_id: order.order_medical_id,
    };
    setStoredState(updatedState);

    return { ...storedState, ...updatedState };
  };

  return { updateEcomStateFromOrder };
};

export { useUpdateEcomStateFromOrder };
