import { Button, useProductsContext } from '@/components';

import styles from './FiltersModal.module.scss';

const FiltersModalFooter = ({ onClose }) => {
  const { products, clearFilters } = useProductsContext();

  return (
    <div className={styles.filters_footer}>
      <Button
        className={styles.clear_button}
        color="secondary"
        onClick={clearFilters}
      >
        Clear filters
      </Button>
      {!!products.length && (
        <Button
          className={styles.results_button}
          color="primary"
          onClick={onClose}
        >
          See results
        </Button>
      )}
    </div>
  );
};

export { FiltersModalFooter };
