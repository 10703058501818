import { FC } from 'react';
import classNames from 'classnames';
import style from './product.module.scss';
import React from 'react';

interface IPPlaceholder {
  className?: string;
  largeCard?: boolean;
  maxWidth?: string;
  height?: string;
}

const ProductPlaceholder: FC<IPPlaceholder> = ({
  className,
  largeCard = false,
  maxWidth,
  height,
}) => {
  return (
    <div
      className={classNames(
        style.product,
        style.product__placeholder,
        { [style.product__placeholder_large]: largeCard },
        className,
      )}
      style={{ maxWidth, height }}
    >
      <figure className={style.product__image_placeholder} />
      <div className={style.product__body}>
        <p className={style.product__brand} />
        <h5 className={style.product__names} />
        <div className={style.product__pack_info}>
          {/*  <p className={style.product__description} /> */}
          <span className={style.product__category} />
          {/*  <p className={style.product__type} /> */}
        </div>
        <span className={style.product__current_price} />
        <div className={style.product__actions_placeholder}>
          <span className={style.actions_placeholder} />
          <div>
            <span className={style.btn_placeholder} />
            <span className={style.type_placeholder} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPlaceholder;
