import { CSSProperties, FC, useEffect, useState } from 'react';
import { useIsMobile, useScreenWidth, useSelectedStore } from '@/hooks';
import { PrismicNextImage } from '@prismicio/next';
import { Container, IconSvg, Image } from '@/components';
import styles from './banner.module.scss';
import classNames from 'classnames';
import { getSlicePadding } from '@/utils';
import {
  BannerSlice,
  BannerSliceWithLogoOnBorder,
  BannerSliceWithLogoOnBorderItem,
} from '@/types';

const WithLogoOnBorderVariation: FC<{
  slice: BannerSlice & BannerSliceWithLogoOnBorder;
}> = ({ slice }) => {
  const { width } = useScreenWidth();
  const { isMobile } = useIsMobile(639);

  const { store: currentStore } = useSelectedStore();
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState<BannerSliceWithLogoOnBorderItem>();

  const primary = slice?.primary;
  const items = slice?.items;

  useEffect(() => {
    if (currentStore?.url_slug) {
      const locationBanner = items?.find(
        banner =>
          banner?.active && banner.location_slug === currentStore?.url_slug,
      );

      if (locationBanner) {
        setBanner(locationBanner);
        return;
      }
    }

    const defaultBanner = items?.find(
      banner => banner?.active && !banner.location_slug,
    );

    if (defaultBanner) {
      setBanner(defaultBanner);
      return;
    }

    setLoading(false);
  }, [items, currentStore]);

  if (!slice || !primary?.active) {
    return null;
  }

  const {
    background_color,
    border_radius,
    bottom_spacing,
    max_height,
    max_height_mobile,
  } = primary;

  const placeholder = '/images/brands/ambient-placeholder-treez.png';

  const paddingSection = slice?.primary?.padding_section;

  const sizes = `(min-width: 2048px)  2008px, (min-width: 1536px)  1496px,
        (min-width: 1280px)  1240px, (min-width: 1024px) 984px,
        (min-width: 768px) 728px, 100vw`;

  const borderRadius = width > 640 ? `${border_radius}px` : 0;

  const bannerActive = banner?.active || false;

  const backgroundColor = bannerActive && background_color;

  const defaultSideImageDimension = 76;

  const extraSpacingValue = banner?.side_image_height
    ? banner.side_image_height / 3
    : defaultSideImageDimension / 3;

  const extraSpacing =
    isMobile && banner?.center_side_image_on_mobile ? 0 : extraSpacingValue;

  const getMaxHeight = () => {
    const paddingTop = '23.75%';

    if (width > 640 && max_height) {
      return `min(${max_height}px, ${paddingTop})`;
    }

    if (max_height_mobile && width < 640) {
      return `min(${max_height_mobile}px, ${paddingTop})`;
    }

    return;
  };

  const renderImage = (banner, sizes, classCss) => {
    return (
      <figure
        className={classNames(classCss, {
          [styles.placeholder]: !bannerActive,
        })}
        style={{
          borderRadius,
          backgroundColor: backgroundColor as string,
          paddingTop: getMaxHeight(),
        }}
      >
        {bannerActive && banner?.url ? (
          <PrismicNextImage
            fallbackAlt=""
            field={banner}
            fill
            priority
            sizes={sizes}
            style={{
              borderRadius,
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        ) : (
          bannerActive &&
          !background_color && (
            <Image
              alt="strain page banner"
              fill
              src={placeholder}
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          )
        )}
        {!bannerActive && !loading && (
          <div>
            <IconSvg name="image" />
            <p>
              <b>There is no active banner</b>
              <br />
              Kindly establish an active banner
            </p>
          </div>
        )}
      </figure>
    );
  };

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        styles.page_banner__section,
        getSlicePadding(paddingSection),
        {
          [styles.page_banner__section__with_side_image_spacing_bottom]:
            bottom_spacing,
          [styles.page_banner__section__extra_spacing]: !bottom_spacing,
        },
      )}
      style={
        {
          '--extra-spacing': `${extraSpacing}px`,
          '--mobile-extra-spacing': `${defaultSideImageDimension / 3}px`,
        } as CSSProperties
      }
    >
      <Container className={styles.page_banner__container}>
        {renderImage(banner?.image, sizes, styles.page_banner__desktop)}
        {renderImage(
          banner?.image?.mobile,
          '(min-width: 640px) 610px, 100wv',
          styles.page_banner__mobile,
        )}

        {banner?.side_image?.url && (
          <div
            className={classNames(styles.page__aside_image, {
              [styles.page__aside_image__bottom_start]:
                banner.horizontal_position === 'start',
              [styles.page__aside_image__bottom_end]:
                banner.horizontal_position === 'end',
              [styles.page__aside_image__bottom_center]:
                banner.horizontal_position === 'center' ||
                (isMobile && banner.center_side_image_on_mobile),
            })}
            style={
              {
                width: banner.side_image_width ?? defaultSideImageDimension,
                height: banner.side_image_height ?? defaultSideImageDimension,
                borderRadius: banner.side_image_border_radius ?? '100px',
                '--border-dimension': `${banner.side_image_border_dimension}px`,
                '--border-color': banner.side_image_border_color,
              } as CSSProperties
            }
          >
            <PrismicNextImage
              fallbackAlt=""
              field={banner?.side_image}
              fill
              style={{
                borderRadius,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default WithLogoOnBorderVariation;
