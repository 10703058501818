import { CSSProperties, FC } from 'react';
import { useSpecialsContext } from '@/context';
import {
  Heading,
  Link,
  Placeholder,
  PromoCard,
  Slider,
  useData,
} from '@/components';
import styles from './specialsslider.module.scss';
import classNames from 'classnames';
import {
  getActivesPreCartDiscounts,
  getCSSpropety,
  getPreCartTreezDiscounts,
  getSlicePadding,
  getSpecialURL,
} from '@/utils';
import {
  PromoCarouselDocumentDataCarouselItemsItem,
  PromoCarouselDocumentDataCarouselItemsItemWithID,
  PromoCarouselSlice,
} from '@/types';
import { TEMPLATE_BASE_PATH } from '@/constants';
interface IPromoCarousel {
  slice?: PromoCarouselSlice;
}

const PromoCarousel: FC<IPromoCarousel> = ({ slice }) => {
  const { promoCarousel } = useData();
  const { isLoading, specials } = useSpecialsContext();

  // carousel props
  const activeAutoplay = !!promoCarousel?.autoplay;
  const delay = promoCarousel?.autoplay_delay ?? 2500;
  const waitForTransition = promoCarousel?.wait_for_transition;
  const disableOnInteraction = !!promoCarousel?.disable_autoplay_on_interaction;
  const speed = promoCarousel?.autoplay_speed ?? 1000;

  // slice props
  const background = slice?.primary.background_color;
  const paddingSection = slice?.primary.padding_section;
  const marginBottom = slice?.primary.bottom_spacing;
  const titleColor = slice?.primary.title_color;
  const cmsDiscountsItems = promoCarousel?.carousel_items;
  const title = slice?.primary?.title as string;

  const breakpoints = {
    1024: {
      spaceBetween: 16,
    },
  };

  if (!slice?.primary.active) {
    return null;
  }

  const preCartTreezDiscounts = getPreCartTreezDiscounts(specials);

  const activesPreCartDiscounts = getActivesPreCartDiscounts(
    cmsDiscountsItems as PromoCarouselDocumentDataCarouselItemsItem[],
    preCartTreezDiscounts,
  );

  const activesCount = Object.keys(activesPreCartDiscounts)?.length;

  if (!isLoading && !activesCount) {
    return null;
  }

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        spacing_bottom: marginBottom,
      })}
      style={{ background } as CSSProperties}
    >
      <Slider
        activatedHover={false}
        activeAutoplay={activeAutoplay}
        autoplay={{ delay, disableOnInteraction, waitForTransition }}
        breakpoints={breakpoints}
        centeredSlides={false}
        className={styles.specials}
        freeMode
        isNotPaddingMobile
        loop={activeAutoplay && activesCount > 3}
        seeAllElement={
          <Link
            className={styles.specials__see_all}
            href={TEMPLATE_BASE_PATH.PROMOTIONS}
          >
            {'SEE ALL'}
          </Link>
        }
        sliderHeading={
          title || promoCarousel?.title ? (
            <Heading
              className={styles.header}
              level={2}
              style={{ color: titleColor } as CSSProperties}
            >
              {title ?? promoCarousel?.title}
            </Heading>
          ) : undefined
        }
        spaceBetween={12}
        speed={speed}
      >
        {isLoading
          ? Array.from({ length: 15 }).map((_, index) => (
              <div className={styles.special__placeholder} key={index}>
                <Placeholder className={styles.special__placeholder_image} />
                <Placeholder className={styles.special__placeholder_title} />
                <Placeholder className={styles.special__placeholder_code} />
              </div>
            ))
          : (
              Object.values(
                activesPreCartDiscounts,
              ) as PromoCarouselDocumentDataCarouselItemsItemWithID[]
            ).map((special, index) => {
              const {
                background_color,
                title_color,
                description_color,
                cta_background,
                cta_text_color,
                cta_type,
              } = special;

              const backgroundColor = getCSSpropety(
                'backgroundColor',
                background_color,
              );
              const titleColor = getCSSpropety('color', title_color);
              const ctaBackgroundColor = getCSSpropety(
                'backgroundColor',
                cta_type === 'button solid' ? cta_background : undefined,
              );

              const ctaTextColor = getCSSpropety('color', cta_text_color);
              const descriptionColor = getCSSpropety(
                'color',
                description_color,
              );
              const ctaBorder = getCSSpropety(
                'border',
                cta_type === 'button outline'
                  ? `1px solid ${cta_text_color}`
                  : undefined,
              );

              return (
                <PromoCard
                  backgroundColor={backgroundColor}
                  className={styles.special}
                  ctaBackgroundColor={ctaBackgroundColor}
                  ctaBorder={ctaBorder as any}
                  ctaTextColor={ctaTextColor}
                  descriptionColor={descriptionColor}
                  key={index}
                  special={special}
                  specialUrl={getSpecialURL(special)}
                  titleColor={titleColor}
                />
              );
            })}
      </Slider>
    </section>
  );
};

export default PromoCarousel;
