import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { IBreadcrumbItem } from '@/types';
import { Link } from '@/components';

import style from './breadcrumb.module.scss';

const Breadcrumb: FC<IBreadcrumbProps> = ({ children, items, className }) => {
  return (
    <ol className={classNames(style.breadcrumb, className)}>
      {items?.map(({ id, href, label, active }) => (
        <li
          className={classNames(style.breadcrumb__item, {
            [style.breadcrumb__item_active]: active,
          })}
          key={id}
        >
          {active || typeof href === 'undefined' ? (
            label
          ) : typeof href === 'string' ? (
            <Link className={style.breadcrumb__link} href={href}>
              {label}
            </Link>
          ) : (
            <button
              className={style.breadcrumb__link}
              onClick={href}
              type="button"
            >
              {label}
            </button>
          )}
        </li>
      ))}
      {children && (
        <li
          className={classNames(
            style.breadcrumb__item,
            style.breadcrumb__item_active,
          )}
        >
          {children}
        </li>
      )}
    </ol>
  );
};

interface IBreadcrumbProps {
  children: ReactNode;
  items: IBreadcrumbItem[];
  className?: string;
}

export default Breadcrumb;
