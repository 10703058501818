import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  CUSTOMER_CANCEL_ORDER_MUTATION,
  GET_ORDER_QUERY,
  SUBMIT_CART_MUTATION,
  SUBMIT_KIOSK_CHECKOUT_MUTATION,
  UPDATE_CART_MUTATION,
  UPDATE_TREEZ_CART_MUTATION,
} from './schema';
import {
  CustomerCancelOrderMutation,
  CustomerCancelOrderMutationVariables,
  GetOrderQuery,
  GetOrderQueryVariables,
  SubmitCartMutation,
  SubmitCartMutationVariables,
  SubmitKioskCheckoutMutation,
  SubmitKioskCheckoutMutationVariables,
  UpdateCartMutation,
  UpdateCartMutationVariables,
  UpdateTreezCartMutation,
  UpdateTreezCartMutationVariables,
} from '@/types';

type GetOrderQueryOptions = QueryHookOptions<
  GetOrderQuery,
  Omit<GetOrderQueryVariables, 'account_id' | 'store_id'>
>;

export const useGetOrder = (options?: GetOrderQueryOptions) => {
  const { data, error, loading, refetch } = useQuery(GET_ORDER_QUERY, options);

  return {
    data: data?.getOrder,
    error: error,
    loading: loading,
    refetch: refetch,
  };
};

type UpdateCartOptions = MutationHookOptions<
  UpdateCartMutation,
  Omit<UpdateCartMutationVariables, 'account_id' | 'store_id'>
>;

export const useUpdateCart = (options?: UpdateCartOptions) => {
  const [updateCart, result] = useMutation(UPDATE_CART_MUTATION, options);

  return {
    result,
    updateCart,
  };
};

type SubmitCartOptions = MutationHookOptions<
  SubmitCartMutation,
  Omit<SubmitCartMutationVariables, 'account_id' | 'store_id'>
>;

export const useSubmitCart = (options?: SubmitCartOptions) => {
  const [submitCart, result] = useMutation(SUBMIT_CART_MUTATION, options);

  return {
    result,
    submitCart,
  };
};

type UpdateTreezCartOptions = MutationHookOptions<
  UpdateTreezCartMutation,
  Omit<UpdateTreezCartMutationVariables, 'account_id' | 'store_id'>
>;

export const useUpdateTreezCart = (options?: UpdateTreezCartOptions) => {
  const [updateCart, result] = useMutation(UPDATE_TREEZ_CART_MUTATION, options);

  return {
    result,
    updateCart,
  };
};

type SubmitKioskCheckoutOptions = MutationHookOptions<
  SubmitKioskCheckoutMutation,
  Omit<SubmitKioskCheckoutMutationVariables, 'account_id' | 'store_id'>
>;

export const useSubmitKioskCheckout = (
  options?: SubmitKioskCheckoutOptions,
) => {
  const [submitCheckout, result] = useMutation(
    SUBMIT_KIOSK_CHECKOUT_MUTATION,
    options,
  );

  return {
    result,
    submitCheckout,
  };
};

type CustomerCancelOrderOptions = MutationHookOptions<
  CustomerCancelOrderMutation,
  Omit<CustomerCancelOrderMutationVariables, 'account_id' | 'store_id'>
>;

export const useCustomerCancelOrder = (
  options?: CustomerCancelOrderOptions,
) => {
  const [cancelOrder, result] = useMutation(
    CUSTOMER_CANCEL_ORDER_MUTATION,
    options,
  );

  return {
    result,
    cancelOrder,
  };
};
