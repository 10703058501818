import { useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, IconSvg, Input } from '@/components';

function Subscribe({
  onSubscribe,
  className,
  placeholder,
  label,
  inFooter,
  isStandard,
  buttonIcon,
  inputIcon,
  ...props
}: {
  className?: string;
  label?: string;
  onSubscribe: (_email: any) => void;
  inFooter?: boolean;
  buttonIcon?: string;
  inputIcon?: boolean;
  isStandard?: boolean;
  placeholder?: string;
}) {
  const ref = useRef(null);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubscribe = e => {
    if ((ref?.current as any).checkValidity()) {
      e.preventDefault();

      setLoading(true);

      Promise.resolve(onSubscribe(email)).then(() => {
        setEmail('');
        setLoading(false);
      });
    }
  };

  return (
    <form className={classNames('subscribe', className)} ref={ref} {...props}>
      <Input
        icon={inputIcon ? <IconSvg name={'email'} /> : ''}
        inFooter={inFooter}
        isStandard={isStandard}
        name="email"
        onChange={e => setEmail(e.target.value)}
        placeholder={placeholder}
        required
        type="email"
        value={email}
      />
      <Button
        icon={!!buttonIcon}
        inFooter={inFooter}
        loading={loading}
        onClick={handleSubscribe}
        type="submit"
      >
        <>
          {buttonIcon && !loading && <IconSvg name={buttonIcon} />}
          {label || null}
        </>
      </Button>
    </form>
  );
}

export default Subscribe;
