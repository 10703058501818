import Script from 'next/script';

function OptinMonster({ ids }) {
  const { userId, accountId } = JSON.parse(ids);

  return (
    <Script
      onLoad={() => {
        /* 
            This site is converting visitors into subscribers and 
            customers with OptinMonster - https://optinmonster.com 
        */
        (function (d, u, ac) {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.src = 'https://a.omappapi.com/app/js/api.min.js';
          s.async = true;
          s.dataset.user = u;
          s.dataset.account = ac;
          d.getElementsByTagName('head')[0].appendChild(s);
        })(document, userId, accountId);
      }}
      src="https://a.omappapi.com/app/js/api.min.js"
      strategy="lazyOnload"
    />
  );
}

export default OptinMonster;
