import { gql } from '@apollo/client';

export const LIST_ACTIVE_PROMOTIONS_QUERY = gql`
  query listActivePromotions($account_id: String!, $store_id: String!) {
    listActivePromotions(account_id: $account_id, store_id: $store_id) {
      id
      name
      label
      never_expire
      start_date
      expiration_date
      priority
      broadcast
      broadcast_type
      broadcast_color
      discountType
      discount_rate_type
      rate
      discount_apply_type
      promo_code
      limit
      limit_quantity
      minimum_order_required
      minimum_order_amount
      status
    }
  }
`;

export const APPLY_PROMO_CODE_MUTATION = gql`
  mutation applyPromoCode(
    $account_id: String!
    $store_id: String!
    $order_id: String!
    $promo_code: String!
  ) {
    applyPromoCode(
      account_id: $account_id
      store_id: $store_id
      order_id: $order_id
      promo_code: $promo_code
    ) {
      id
      blaze_id
      discountType
      discount_rate_type
      discount_apply_type
      promo_code
      rate
    }
  }
`;
