import { CSSProperties, useEffect, useRef } from 'react';

interface SurfsideBannerProps {
  accountId: string;
  channelId: string;
  height: number;
  locationId: string;
  siteId: string;
  style?: CSSProperties;
  width: number;
  zoneId: string;
}

const SurfsideBanner = ({
  accountId,
  channelId,
  height = 1,
  locationId,
  siteId,
  width = 8,
  zoneId,
  style,
}: SurfsideBannerProps) => {
  const bannerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (bannerRef.current) {
      const banner = document.createElement('surf-banner') as any;
      banner.setAttribute('account-id', accountId);
      banner.setAttribute('channel-id', channelId);
      banner.setAttribute('height', height);
      banner.setAttribute('location-id', locationId);
      banner.setAttribute('site-id', siteId);
      banner.setAttribute('width', width);
      banner.setAttribute('zone-id', zoneId);

      bannerRef.current.replaceWith(banner);
    }
  }, [accountId, channelId, height, locationId, siteId, width, zoneId, style]);

  return <div ref={bannerRef} />;
};

export { SurfsideBanner };
