'use client';

import { FC, useState } from 'react';
import { useUiStore } from '@/data';
import { Accordion, Close, Heading, Icon, Link, useData } from '@/components';
import { sortBy } from 'lodash';
import { PrismicNextImage } from '@prismicio/next';
import { PAGE_ROUTES } from '@/constants';
import classNames from 'classnames';
import * as prismicH from '@prismicio/helpers';
import {
  SideNavigationDocumentDataFooterLinksItem,
  Simplify,
} from 'prismicio-types';
import { GroupField } from '@prismicio/client';

const SideNavigation: FC = () => {
  const [state, setState] = useUiStore();
  const [isOpen, setIsOpen] = useState({});
  const { header, navigation, sideNavigation } = useData();
  const hasSideNavigationDoc = !!sideNavigation?.data;
  const logo = header?.data?.logo;
  let sideNavItem = navigation?.data?.links || [];
  let sideNavFooterItem: GroupField<
    Simplify<SideNavigationDocumentDataFooterLinksItem>
  > = [];

  if (hasSideNavigationDoc) {
    sideNavItem = sideNavigation?.data?.links || [];
    sideNavFooterItem = sideNavigation?.data?.footer_links || [];
  }

  const makeElement = (item, index) => {
    if (item?.link) {
      return (
        <Link
          className={'side-nav__item'}
          href={item?.link}
          key={index}
          onClick={() => {
            setState({ is_nav_open: false });
          }}
        >
          {item?.label}
        </Link>
      );
    }

    const externalLink = item?.external_link;
    const uid = externalLink?.uid;

    let megaMenuNavLinks =
      externalLink?.data?.body?.filter(
        filter => filter.slice_type === 'group_link',
      ) || [];

    if (externalLink?.type === 'custom_mega_menu') {
      megaMenuNavLinks =
        externalLink?.data?.slices?.filter(
          filter => filter.slice_type === 'group_link',
        ) || [];
    }

    const megaMenuNavLinksSorted = sortBy(
      megaMenuNavLinks,
      o => o?.primary?.sort,
    );

    return (
      <Accordion
        isOpen={isOpen[uid]}
        key={index}
        title={
          <div className={'side-nav__item'}>
            {item?.label}
            <Icon
              className={'icon'}
              name={isOpen[uid] ? 'angle-up' : 'angle-down'}
            />
          </div>
        }
        toggle={() =>
          setIsOpen(oldIsOpen => ({
            ...oldIsOpen,
            [uid]: !oldIsOpen[uid],
          }))
        }
      >
        {isOpen[uid] &&
          megaMenuNavLinksSorted.map((item, index) => {
            return (
              <div key={index}>
                {item?.primary?.group_link_label?.[0] && (
                  <Heading className={'inmediate_child'} level={3}>
                    {prismicH.asText(item?.primary?.group_link_label)}
                  </Heading>
                )}

                {item.items.map((sChild, index) => {
                  return (
                    <Link
                      className={'secondary_child'}
                      href={sChild?.link}
                      key={index}
                      onClick={() => {
                        setState({
                          is_nav_open: false,
                        });
                      }}
                    >
                      {sChild?.label}
                    </Link>
                  );
                })}
              </div>
            );
          })}
      </Accordion>
    );
  };

  return (
    <div
      className={classNames('side-nav', {
        'side-nav_open': state.is_nav_open,
      })}
    >
      <div className={'side-nav__container'}>
        <div className={'side-nav__header'}>
          <Link className="logo" href={'/'}>
            <figure>
              <PrismicNextImage
                fallbackAlt=""
                field={logo}
                height={logo?.dimensions?.height}
                quality={100}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: 'auto',
                }}
                width={logo?.dimensions?.width}
              />
            </figure>
          </Link>
          <Close onClick={() => setState({ is_nav_open: false })} />
        </div>
        <nav className={'side-nav__body'}>
          <div className={'side-nav__main'}>
            {!hasSideNavigationDoc && (
              <Heading className={'side-nav__main-heading'} level={4}>
                Categories{' '}
              </Heading>
            )}
            {sideNavItem.map((item, index) => {
              return makeElement(item, index);
            })}
          </div>
          {!hasSideNavigationDoc && (
            <Link
              className={'button_location'}
              href={PAGE_ROUTES.STORE_LOCATOR}
              onClick={() => setState({ is_nav_open: false })}
            >
              <Icon name="pin_drop" />
              Store Location
            </Link>
          )}
          {hasSideNavigationDoc &&
            sideNavFooterItem?.map(
              (item: SideNavigationDocumentDataFooterLinksItem, index) => {
                const textTransform = item?.uppercase_label
                  ? 'uppercase'
                  : 'capitalize';

                return (
                  <Link
                    className={'button_location'}
                    href={item?.link as string}
                    key={index}
                    onClick={() => setState({ is_nav_open: false })}
                    style={{ textTransform }}
                  >
                    {item?.image?.url && (
                      <figure className={'button_location_image'}>
                        <PrismicNextImage
                          fallbackAlt=""
                          field={item?.image}
                          fill
                          style={{
                            objectFit: 'contain',
                          }}
                        />
                      </figure>
                    )}
                    {item?.label}
                  </Link>
                );
              },
            )}
        </nav>
      </div>
      <div
        className={'side-nav__overlap'}
        onClick={() => setState({ is_nav_open: false })}
        role="button"
      />
    </div>
  );
};

export default SideNavigation;
