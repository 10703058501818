import {
  PickupScheduleZone,
  ScheduleWorkingHours,
  ScheduleZone,
  TimeSlot,
  WeekDay,
} from '@/types';

const generateTimeSlot = (
  interval: number = 1,
  start: string = '09:00',
  end: string = '22:00',
): TimeSlot[] => {
  const time_slots: TimeSlot[] = [];
  const from = Number(start.split(':')[0]);
  const to = Number(end.split(':')[0]);
  [
    WeekDay.Monday,
    WeekDay.Tuesday,
    WeekDay.Wednesday,
    WeekDay.Thursday,
    WeekDay.Friday,
    WeekDay.Saturday,
    WeekDay.Sunday,
  ].forEach(day => {
    for (let hour = from; hour < to; hour++) {
      const startHour = hour.toString().padStart(2, '0') + ':00';
      const endHour = (hour + interval).toString().padStart(2, '0') + ':00';

      time_slots.push({
        start: startHour,
        end: endHour,
        cutoff_time: startHour,
        disable_for_today: false,
        disable: false,
        day_of_the_week: day,
      });
    }
  });

  return time_slots;
};

const generateWorkingHours = (
  start: string = '09:00',
  end: string = '22:00',
): ScheduleWorkingHours[] => {
  const working_hours: ScheduleWorkingHours[] = [];

  [
    WeekDay.Monday,
    WeekDay.Tuesday,
    WeekDay.Wednesday,
    WeekDay.Thursday,
    WeekDay.Friday,
    WeekDay.Saturday,
    WeekDay.Sunday,
  ].forEach(day => {
    working_hours.push({
      start,
      end,
      day_of_the_week: day,
    });
  });

  return working_hours;
};

const getDefaultSchedules = ({
  interval,
  start,
  end,
  isPickup,
}: {
  interval?: number;
  start?: string;
  end?: string;
  isPickup?: boolean;
}): ScheduleZone[] | PickupScheduleZone[] => {
  const time_slots = generateTimeSlot(interval, start, end);
  const working_hours = generateWorkingHours(start, end);

  const defaultSchedule = {
    name: isPickup ? 'Pickup' : 'Delivery',
    note: '',
    time_slots,
    working_hours,
    stores_provider_id: '',
  };

  if (!isPickup) {
    (defaultSchedule as ScheduleZone).delivery_fee = 0;
    (defaultSchedule as ScheduleZone).free_after = 500000;
    (defaultSchedule as ScheduleZone).min = 5000;
  }

  return [defaultSchedule];
};

export const DefaultPickupSchedules = getDefaultSchedules({ isPickup: true });
export const DefaultDeliverySchedules = getDefaultSchedules({
  isPickup: false,
});
