import { FC, useState } from 'react';

import { useAnalytics, useUiStore } from '@/data';
import {
  Badge,
  Close,
  Counter,
  Heading,
  Icon,
  IconSvg,
  Image,
  Link,
  Price,
  useData,
} from '@/components';
import {
  currencyFormat,
  formatProductDiscountText,
  getBogoSpecialURL,
} from '@/utils';
import { CartLineItem, GProduct } from '@/types';

import style from './cartnavigation.module.scss';
import { startCase } from 'lodash';
import { NOT_DEFINED } from 'constants/product';
import classNames from 'classnames';

interface IcartBodyProps {
  products: CartLineItem[];
}

const CartBody: FC<IcartBodyProps> = ({ products }) => {
  const [, setState] = useUiStore();
  const { measureProductClick } = useAnalytics();
  const [closeBogo, setCloseBogo] = useState(false);
  const { productCard } = useData();

  const trackingClick = (product: GProduct) =>
    measureProductClick(product, 'shopping cart');

  return (
    <ul className={style.cart__body_container}>
      {products?.map((product, index) => {
        const v = product?.weightVariant;
        const isSpecial = v?.salePrice! < v?.price!;
        const stockPhoto = product?.asset?.stock ?? false;

        //If the product has a bundle_id, check that id is from a special bogo
        const bogo = product?.discounts?.find(
          d => d?.discount_id === product?.bundle_id,
        );
        const isBogoActive =
          !!bogo && bogo?.discount_method?.toUpperCase() === 'BOGO';

        const price = (
          <Price
            price={`$${currencyFormat(v?.price!)}`}
            salePrice={`$${currencyFormat(v?.salePrice!)}`}
            special={isSpecial}
          />
        );

        return (
          <li
            className={classNames(style.cart__product, {
              [style.cart__product_active_bogo]: isBogoActive && !closeBogo,
            })}
            key={index}
          >
            <div
              className={classNames(style.cart__product_body, {
                [style.cart__product_body_active_bogo]:
                  isBogoActive && !closeBogo,
              })}
            >
              <Link
                className={style.cart__item_image}
                href={`${product.url}?item=${product.id}`}
                onClick={() => {
                  trackingClick(product);
                  setState({ is_cart_open: false });
                }}
              >
                <figure>
                  <Image
                    alt={product?.name ?? ''}
                    fallback="/images/product-placeholder.svg"
                    height={72}
                    src={product?.asset?.image ?? ''}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                    width={72}
                  />
                </figure>
                {stockPhoto && (
                  <span className={style.product__in_stock}>Stock photo</span>
                )}
              </Link>
              <div className={style.cart__detail_item}>
                <div className={style.cart__detail_brand}>
                  <p>
                    <span>{product?.brand_name ?? NOT_DEFINED}</span> •{' '}
                    {startCase(product.category as string) ||
                      startCase(NOT_DEFINED)}{' '}
                  </p>
                  {product.bundle_title || product?.isLowStock || isSpecial ? (
                    <div className={style.cart__product_badges}>
                      {product?.isLowStock && (
                        <Badge className={style.product__stock}>
                          Low Stock
                        </Badge>
                      )}
                      {isSpecial && (
                        <Badge className={style.product__sale}>
                          {formatProductDiscountText({
                            format: productCard?.discount_display,
                            value: product.weightVariant,
                          })}
                        </Badge>
                      )}
                      {product.bundle_title && (
                        <Badge className={style.product__bundle}>
                          <Icon name="offer-solid" />
                          &nbsp;{product?.bundle_title}
                        </Badge>
                      )}
                    </div>
                  ) : null}
                </div>
                <Link
                  className={style.cart__info_container}
                  // eslint-disable-next-line max-len
                  href={`${product.url}?item=${product.id}`}
                  onClick={() => {
                    trackingClick(product);
                    setState({
                      is_cart_open: false,
                    });
                  }}
                >
                  <Heading className={style.heading_level_4} level={4}>
                    {product.name}
                  </Heading>
                  <div className={style.price__container}>{price}</div>
                </Link>
                <div className={style.actions}>
                  <Counter onShopping product={product} />
                </div>
              </div>
            </div>
            {isBogoActive && !closeBogo && (
              <Badge className={style.cart_item_bogo} href={'/'} key={index}>
                <figure>
                  <IconSvg name="gift" />
                </figure>
                <div className={style.cart_item_bogo_message}>
                  <span>{'You are on your way to a Bundle Special!'}</span>
                  <Link
                    href={getBogoSpecialURL(bogo!)}
                    onClick={() => {
                      setState({ is_cart_open: false });
                    }}
                  >
                    Click here to complete
                  </Link>
                </div>
                <Close
                  className={style.cart_item_bogo_close}
                  onClick={() => {
                    setCloseBogo(true);
                  }}
                ></Close>
              </Badge>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default CartBody;
