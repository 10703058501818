import Link from 'next/link';
import { CSSProperties, FC } from 'react';
import { PromoCarouselDocumentDataCarouselItemsItemWithID } from '@/types';
import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import { asText } from '@prismicio/client';

import style from './promocard.module.scss';

type CssProp = {
  [x: string]: string;
};

interface IPromoCard {
  className?: string;
  specialUrl?: string;
  backgroundColor?: CssProp;
  special?: PromoCarouselDocumentDataCarouselItemsItemWithID;
  titleColor?: CssProp;
  descriptionColor?: CssProp;
  ctaBackgroundColor?: CssProp;
  ctaTextColor?: CssProp;
  ctaBorder?: CssProp;
}

const PromoCard: FC<IPromoCard> = ({
  className,
  specialUrl,
  backgroundColor,
  special,
  titleColor,
  descriptionColor,
  ctaBackgroundColor,
  ctaTextColor,
  ctaBorder,
}) => {
  const content = (
    <div
      className={classNames(style.promotion_card, className)}
      style={backgroundColor as CSSProperties}
    >
      <figure
        className={classNames(style.picture, {
          [style.picture_small]: !special?.pincture_only,
        })}
      >
        {special?.picture?.url && (
          <PrismicNextImage fallbackAlt="" field={special?.picture} fill />
        )}
      </figure>
      {!special?.pincture_only && (
        <div className={style.content} style={backgroundColor as CSSProperties}>
          <div className={style.description}>
            <h3
              style={titleColor as CSSProperties}
              title={special?.title ?? ''}
            >
              {special?.title}
            </h3>
            <div
              className={style.extra_description}
              title={asText(special?.description) ?? ''}
            >
              <p style={descriptionColor as CSSProperties}>
                {asText(special?.description)}
              </p>
            </div>
          </div>

          {special?.cta_text && (
            <div
              className={classNames(style.cta_button, {
                [style.cta_button_outlander]: !ctaBackgroundColor,
              })}
              style={{
                ...ctaBackgroundColor,
                ...ctaTextColor,
                ...ctaBorder,
              }}
            >
              {special?.cta_text}
            </div>
          )}
        </div>
      )}
    </div>
  );

  if (specialUrl) {
    return <Link href={specialUrl}>{content}</Link>;
  }

  return content;
};

export { PromoCard };
