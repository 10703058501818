'use client';

import { useEffect, useState } from 'react';

import { Alert, Button, Close, Heading, Modal } from '@/components';
import { MenuService, useEcomStoreSelector } from '@/data';

import { OutsideProducts } from './StoreValidator.components';

import styles from './StoreValidator.module.scss';

const StoreValidator = () => {
  const { line_items, next_store_id, next_shop_mode, setState } =
    useEcomStoreSelector(['line_items', 'next_store_id', 'next_shop_mode']);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outsideProducts, setOutsideProducts] = useState([]);
  const { getInsideStoreProducts, getOutsideProducts } = new MenuService();

  const confirmShopChange = async () => {
    let storeProducts = await getInsideStoreProducts(line_items, next_store_id);

    storeProducts = storeProducts.map(item => {
      const cartItem = line_items.find(s => s.id === item.id);
      return {
        ...item,
        quantity: cartItem.quantity,
        weightVariant: cartItem.weightVariant,
      };
    });

    setState({
      delivery_details: {
        delivery: next_shop_mode === 'delivery',
        pickup: next_shop_mode === 'pickup',
      },
      treez_store_name: next_store_id,
      line_items: storeProducts,
      next_store_id: null,
    });

    setOutsideProducts([]);
  };

  const confirmKeepShop = () => {
    setState({
      next_shop_mode: null,
      next_store_id: null,
    });
  };

  useEffect(() => {
    setIsModalOpen(!!next_store_id);
  }, [next_store_id]);

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    const loadOutsideProducts = async () => {
      const products = await getOutsideProducts(line_items, next_store_id);

      setOutsideProducts(products);
    };

    loadOutsideProducts();
  }, [isModalOpen]);

  return (
    <Modal className={styles.modal} isOpen={isModalOpen} md>
      <div className={styles.store_validator}>
        <div className={styles.store_validator__header}>
          <Heading className={styles.header_title} level={2}>
            Do you want to change shops?
          </Heading>
          <Close name="close" onClick={confirmKeepShop} />
        </div>
        <Alert className={styles.warning_message} isOpen={true} warning={true}>
          <p>
            Be careful! You are about to change the shop. Some of your items
            will be removed from your shopping cart
          </p>
        </Alert>
        <OutsideProducts products={outsideProducts} />
        <div className={styles.store_validator__footer}>
          <Button color="primary" onClick={confirmShopChange}>
            remove item & change shop
          </Button>
          <Button color="secondary" onClick={confirmKeepShop}>
            Keep item & don’t change shop
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { StoreValidator };
