import { CSSProperties, useEffect, useRef } from 'react';

interface SurfsideVideoProps {
  accountId: string;
  channelId: string;
  height: number;
  locationId: string;
  siteId: string;
  style?: CSSProperties;
  width: number;
  zoneId: string;
  minDuration?: number;
  maxDuration: number;
}

const SurfsideVideo = ({
  accountId,
  channelId,
  height = 1,
  locationId,
  siteId,
  width = 8,
  zoneId,
  style,
  minDuration,
  maxDuration,
}: SurfsideVideoProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      const video = document.createElement('surf-video') as any;
      video.setAttribute('account-id', accountId);
      video.setAttribute('channel-id', channelId);
      video.setAttribute('height', height);
      video.setAttribute('location-id', locationId);
      video.setAttribute('site-id', siteId);
      video.setAttribute('width', width);
      video.setAttribute('zone-id', zoneId);
      video.setAttribute('max-duration', maxDuration);
      video.setAttribute('min-duration', minDuration);

      videoRef.current.replaceWith(video);
    }
  }, [
    accountId,
    channelId,
    height,
    locationId,
    siteId,
    width,
    zoneId,
    style,
    minDuration,
    maxDuration,
  ]);

  return <div ref={videoRef} />;
};

export { SurfsideVideo };
