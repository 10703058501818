import { Icon } from '../../components';
import { useEcomStore, useUiStore } from '../../data';
import Address from '../Address/Address';
import Button from '../Button/Button';
import Method from '../Method/Method';
import PopoverPanel from '../PopoverPanel';
import style from './info.module.scss';
import InfoAddress from './InfoAddress';
import { useData } from '../DataProvider/DataProvider';

const Info = () => {
  const [state, setState] = useUiStore();
  const [storedState, setStoredState] = useEcomStore();
  const { store } = useData();

  return (
    <>
      <Button
        className={style['item-header-button']}
        onClick={() => {
          setState({ is_info_open: !state.is_info_open });
        }}
      >
        <div className={style['button-content']}>
          <InfoAddress hideSpeed />
          <Icon name={'arrow_drop'} />
        </div>
      </Button>
      <PopoverPanel
        className={style['header-info-form']}
        isOpen={state.is_info_open}
        onClose={event =>
          // Cancel the close returning false if the click
          // was over Google address autocomplete
          // (.pac-container) menu.
          !document.querySelector('.pac-container')?.contains(event.target)
        }
        toggle={() => setState({ is_info_open: false })}
      >
        <p className={style.description}>
          <span className={style['description-header']}>Select an action</span>
          <span className={style['description-body']}>
            For the most relevant products and content, please select an option
            below
          </span>
        </p>
        <Method inputs={storedState} setInputs={setStoredState} />
        <Address
          editable={storedState.delivery_details.delivery}
          id="header-address"
          // This prop defines where to load the addresses list.
          // For pickup locations we have only the store address
          // because for now we have not suport multi address in
          // the store iformation. In the future we must have
          // something like this: store.pickup_addresses instead
          // of [store].
          isDelivery={storedState.delivery_details.delivery}
          key={storedState.addresses}
          onChange={(address, addresses) => {
            setStoredState({
              delivery_address: address,
              // Update the addresses list only when it is editable.
              ...(storedState.delivery_details.delivery ? { addresses } : {}),
            });
          }}
          setState={setStoredState}
          state={
            storedState.delivery_details.delivery
              ? [...storedState.addresses]
              : [store]
          }
          value={storedState.delivery_address}
        />
      </PopoverPanel>
    </>
  );
};
export default Info;
