import { FC } from 'react';
import { asText } from '@prismicio/helpers';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import { Container, Heading, Icon, Link } from '@/components';
import { ImageWithTitleSlice } from '@/types';
import classNames from 'classnames';
import style from './imagewithtitle.module.scss';
import { getSlicePadding } from '@/utils';
import { useShowByLocationSlug } from 'hooks/useFilteredSlicesItemByLocationSlug';

const ImageWithTitle: FC<ImageWithTitleSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const backgroundColor = slice?.primary?.background_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  const Image =
    slice.primary && slice.primary.image?.url ? slice.primary.image : null;

  const ctaType =
    slice.primary && slice.primary?.cta_type === 'button' ? 'primary' : 'link';

  const ctaText =
    slice.primary && slice.primary?.cta_text ? slice.primary.cta_text : null;

  const heading = slice?.primary?.heading;

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.image_with_title,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: backgroundColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={style.container}>
        <div
          className={classNames(style.body, {
            [style.body__heading]: heading,
          })}
        >
          <Heading
            className={style.body__title}
            color={slice.primary?.text_color as string}
            level={2}
          >
            {asText(slice.primary?.title)}
          </Heading>
          {heading && (
            <Heading className={style.body__text_heading} level={3}>
              {asText(slice?.primary?.heading)}
            </Heading>
          )}
          <PrismicRichText field={slice.primary?.description} />
          <figure className={style.body__figure}>
            {Image ? (
              <PrismicNextImage
                fallbackAlt=""
                field={Image}
                fill
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center center',
                }}
              />
            ) : (
              <Icon name="image" />
            )}
          </figure>
          {ctaText && (
            <Link
              className={classNames({
                [style.body__button_primary]: ctaType === 'primary',
              })}
              href={slice.primary?.cta_link ?? ''}
              style={{
                background: slice.primary?.cta_background as string,
                color: slice.primary?.cta_text_color as string,
              }}
            >
              {ctaText}
            </Link>
          )}
        </div>
      </Container>
    </section>
  );
};

export default ImageWithTitle;
