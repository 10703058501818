import { FC, useMemo } from 'react';

import classNames from 'classnames';

import { GProduct, Variant } from '@/types';
import { Badge, useData } from '@/components';
import { formatProductDiscountText } from '@/utils';

interface IProductBadgeProps {
  product: GProduct;
  weightVariant: Variant;
  isSpecial: boolean;
}

const ProductBadges: FC<IProductBadgeProps> = ({ product, weightVariant }) => {
  const { productCard } = useData();

  const productDiscountText = useMemo(
    () =>
      formatProductDiscountText({
        format: productCard?.discount_display,
        value: weightVariant,
      }),
    [weightVariant],
  );

  if (!product?.isLowStock && !product?.isOutStock && !productDiscountText) {
    return null;
  }

  return (
    <div className="product__badges">
      {product?.isLowStock && (
        <Badge className={classNames('badge', 'product__stock')}>
          Low Stock
        </Badge>
      )}
      {product?.isOutStock && (
        <Badge className={classNames('badge', 'product__stock')}>
          Out of Stock
        </Badge>
      )}
      {productDiscountText && (
        <Badge className={classNames('badge', 'product__sale')}>
          {productDiscountText}
        </Badge>
      )}
    </div>
  );
};

export { ProductBadges };
