import { useEffect, useMemo } from 'react';
import { useMenuService } from '@/data';
import { useSpecialsContext } from '@/context';
import { useQueries } from '@tanstack/react-query';
import { useProducts, useSelectedStore } from '@/hooks';
import { CartLineItem, GProduct, ISpecial } from '@/types';
import { formatSpecial } from './utils';
import { cloneDeep, find, findIndex } from 'lodash';

interface IUseBundleSpecial {
  id: string;
}

interface IUseBunbleSpecialResponse {
  bundleProducts: CartLineItem[];
  currentBundleProducts: CartLineItem[];
  dependentProducts: CartLineItem[];
  independentProducts: CartLineItem[];
  initDepProducts: GProduct[];
  initIndProducts: GProduct[];
  isLoading: boolean;
  processingDependents: boolean;
  processingIndependents: boolean;
  special?: ISpecial;
}

const useBundleSpecial = ({
  id,
}: IUseBundleSpecial): IUseBunbleSpecialResponse => {
  const { store } = useSelectedStore();
  const { products, update } = useProducts();
  const { isLoading, specials } = useSpecialsContext();
  const { searchProductsByGroupId } = useMenuService();

  const accounting = products?.reduce((result, current) => {
    return result + current?.quantity;
  }, 0);

  const currentSpecial = specials?.find(sp => sp?.id === id);
  const currentBundleProducts =
    products?.filter(prod => prod?.bundle_id === currentSpecial?.id) ?? [];
  const dependentGroupIds = currentSpecial?.groupsRequired ?? [];
  const independentGroupIds = currentSpecial?.groups ?? [];

  const ids = [...dependentGroupIds, ...independentGroupIds];

  const { data, isLoading: isBogoLoading } = useQueries({
    queries: ids.map(id => ({
      queryKey: ['BogoProducts', id, store?.shortName],
      queryFn: () => searchProductsByGroupId({ id }),
    })),
    combine: results => ({
      data: results.reduce((result, current, index) => {
        const actives = current?.data; // we moved the active filter to inside the searchProductsByGroupId

        if (actives?.length) {
          if (index > dependentGroupIds.length - 1) {
            return {
              ...result,
              [`dependent-${index}`]: actives,
            };
          }

          return {
            ...result,
            [`independent-${index}`]: actives,
          };
        }

        return result;
      }, {}),
      isLoading: results.some(result => result.isPending),
    }),
  });

  const {
    bundleProducts,
    dependentProducts,
    independentProducts,
    initDepProducts,
    initIndProducts,
    processingDependents,
    processingIndependents,
    special,
  } = useMemo(() => {
    if (isLoading || !data) {
      return {
        bundleProducts: [],
        dependentProducts: [],
        independentProducts: [],
        processingDependents: false,
        processingIndependents: false,
        special: undefined,
      };
    }

    const formatedSpecial = formatSpecial(
      currentBundleProducts,
      currentSpecial,
      data,
      isBogoLoading,
    );

    const accountedProducts: CartLineItem[] = [];

    currentBundleProducts.map(prod => {
      let count = 0;

      while (count < prod?.quantity) {
        count++;
        accountedProducts.push(prod);
      }
    });

    const { dependentProductsInCart, independentProductsInCart } =
      accountedProducts.reduce(
        (result, current) => {
          // this are the products ids return by the group API call off
          // all dependent and independent products
          const depProducts = formatedSpecial?.depProducts;
          const indProducts = formatedSpecial?.indProducts;

          const isIndProdsAvailable =
            result?.independentProductsInCart?.length <
            formatedSpecial?.independentProductsRequired;

          if (current?.independent && isIndProdsAvailable) {
            result?.independentProductsInCart?.push(current);

            return result;
          }

          const prodInInd = find(
            indProducts,
            (d: GProduct) => d.id === current.id,
          );

          let index = findIndex(
            products,
            (p: CartLineItem) => p.id === current.id,
          );

          if (!!prodInInd && isIndProdsAvailable && index !== -1) {
            result?.independentProductsInCart?.push({
              ...current,
              dependent: !!prodInInd,
              independent: !prodInInd,
            });

            return result;
          }

          const isDepProdsAvailable =
            result?.dependentProductsInCart?.length <
            formatedSpecial?.dependentProductsRequired;

          if (current?.dependent && isDepProdsAvailable) {
            result?.dependentProductsInCart?.push(current);

            return result;
          }

          const prodInDep = find(
            depProducts,
            (d: GProduct) => d.id === current.id,
          );

          index = findIndex(products, (p: CartLineItem) => p.id === current.id);

          if (!!prodInDep && isDepProdsAvailable) {
            result?.dependentProductsInCart?.push({
              ...current,
              dependent: !!prodInDep,
              independent: !prodInDep,
            });
          }

          return result;
        },

        {
          dependentProductsInCart: [] as CartLineItem[],
          independentProductsInCart: [] as CartLineItem[],
        },
      );

    const i =
      formatedSpecial?.independentProductsRequired >
        independentProductsInCart?.length &&
      !formatedSpecial?.allowDiscountProducts;

    return {
      bundleProducts: independentProductsInCart.concat(dependentProductsInCart),
      dependentProducts: dependentProductsInCart,
      independentProducts: independentProductsInCart,
      initDepProducts: formatedSpecial?.depProducts,
      initIndProducts: formatedSpecial?.indProducts,
      processingDependents: !i,
      processingIndependents: i,
      special: formatedSpecial,
    };
  }, [accounting, specials.length, isBogoLoading]);

  useEffect(() => {
    if (!bundleProducts.length) return;

    const newProducts = cloneDeep(products);

    bundleProducts.forEach((prod: CartLineItem) => {
      const index = findIndex(products, (p: CartLineItem) => p.id === prod.id);

      if (index !== -1) {
        newProducts[index] = prod;
      }
    });

    update(newProducts);
  }, [bundleProducts]);

  return {
    bundleProducts,
    currentBundleProducts,
    dependentProducts,
    independentProducts,
    initDepProducts: initDepProducts!,
    initIndProducts: initIndProducts!,
    isLoading: isLoading || isBogoLoading,
    processingDependents,
    processingIndependents,
    special,
  };
};

export default useBundleSpecial;
