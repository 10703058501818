import { configureAutoTrack } from '@aws-amplify/analytics';

import { useData } from '@/components';
import { useFeaturedFlag } from '@/hooks';

import { AnalyticsRecorder } from './AnalyticsRecorder';

const useAnalytics = () => {
  const { store } = useData();
  const { isSurfsideActive } = useFeaturedFlag();
  const isActive = Boolean(store?.aws_pinpoint_analytics?.active);
  const isGtmActive = Boolean(store?.gtm_active);

  const { ...analyticsMethods } = new AnalyticsRecorder(
    isActive,
    isSurfsideActive,
    isGtmActive,
  );

  const initAnalytics = () => {
    if (!isActive) {
      return;
    }

    configureAutoTrack({ type: 'session', enable: true });
    configureAutoTrack({ type: 'pageView', enable: true });
    configureAutoTrack({ type: 'event', enable: true });
  };

  return { ...analyticsMethods, initAnalytics };
};

export { useAnalytics };
