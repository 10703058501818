import { gql } from '@apollo/client';

export const CREATE_SHIPMENT_MUTATION = gql`
  mutation createShipment(
    $account_id: String!
    $store_id: String!
    $shipment_date: String!
    $input: ShipmentInput!
  ) {
    createShipment(
      account_id: $account_id
      store_id: $store_id
      shipment_date: $shipment_date
      input: $input
    ) {
      status
      error
      shipment_date
      rates {
        provider
        fixed_shipping
        provider_rates {
          id
          service_level
          rate
          estimated_days
          additional_information
          ready_to_pickup
        }
      }
    }
  }
`;
