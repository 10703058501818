'use client';

import { FC } from 'react';
import { GoogleTagManager } from '@next/third-parties/google';
import { useData } from '@/components';

const Gtm: FC = () => {
  const { store } = useData();

  return (
    <>
      {store.gtm_active && <GoogleTagManager gtmId={store?.gtm_id || ''} />}

      {/*this is a gtm id for treez e-com*/}
      <GoogleTagManager gtmId={'GTM-P5FTW2Z7'} />
    </>
  );
};

export default Gtm;
