import { FC, useEffect } from 'react';
import classNames from 'classnames';
import {
  useFeaturedFlag,
  useProductsByCategory,
  useSelectedStore,
  useSpacingBetweenProductCard,
} from '@/hooks';
import { useAnalytics } from '@/data';
import {
  Heading,
  Icon,
  Link,
  Product,
  ProductPlaceholder,
  Slider,
} from '@/components';
import { GProduct, SortBySelectOption } from '@/types';
import { getSlicePadding } from '@/utils';

import style from './products.module.scss';

interface IProductsProps {
  title: string;
  analyticsListName?: string;
  products: GProduct[];
  totalCount?: number;
  seeAllHref?: string;
  seeMoreText?: string;

  // limit and sort
  limit?: number;
  sortBy?: SortBySelectOption | undefined;

  // filters
  filterByStore?: boolean;
  filterByCategory?: string; // filter by category
  filterByBrand?: string; // filter by brand
  filterByGroupId?: string; // filter by group id
  filterByOnSale?: boolean; // filter by on sale

  spacingBottom?: boolean;
  paddingSection?: 'small' | 'medium' | 'large';
}

const Products: FC<IProductsProps> = ({
  title,
  analyticsListName,
  totalCount: _totalCount = 0,
  seeAllHref,
  products: serverProducts,
  seeMoreText = 'See All',

  limit = 20,
  sortBy,

  filterByStore = false,
  filterByCategory,
  filterByBrand,
  filterByGroupId,
  filterByOnSale,

  spacingBottom: bottom_spacing = false,
  paddingSection = 'small',
}) => {
  const { measureProductImpressions, measureViewItemsListGA4 } = useAnalytics();
  const {
    breakpoints,
    defaultSlidePerView,
    defaultSpaciBetween,
    spacing_between_card,
  } = useSpacingBetweenProductCard();
  const { store } = useSelectedStore();

  const { isProductCardLargImage } = useFeaturedFlag();

  useEffect(() => {
    measureProductImpressions(products, analyticsListName as string);
    measureViewItemsListGA4(products, analyticsListName as string);
  }, []);

  const { products, isLoading, total, isError, error } = useProductsByCategory({
    dispensaryName: store?.shortName ?? '',
    sortBy,
    limit,
    products: serverProducts,
    filterByStore,
    filterByBrand,
    filterByCategory,
    filterByGroupId,
    filterByOnSale,
  });

  if (isError) {
    // eslint-disable-next-line no-console
    console.log(error);

    return null;
  }

  if (!isLoading && !products?.length) {
    return null;
  }

  return (
    <section
      className={classNames(getSlicePadding(paddingSection), {
        ['spacing_bottom']: bottom_spacing,
      })}
    >
      <Slider
        activatedHover
        breakpoints={breakpoints}
        centeredSlides={false}
        freeMode
        isNotPaddingMobile={spacing_between_card === 'none'}
        seeAllElement={
          <Link
            className={style.products__see_all}
            href={seeAllHref ?? '/deals'}
          >
            SHOP ALL {total > 0 && <span>({total})</span>}
          </Link>
        }
        showSliderCardLink={true}
        sliderHeading={<Heading level={2}>{title}</Heading>}
        slidesPerView={defaultSlidePerView}
        spaceBetween={defaultSpaciBetween}
      >
        {isLoading
          ? Array.from({ length: 20 }).map((_, index) => (
              <ProductPlaceholder
                key={index}
                largeCard={isProductCardLargImage}
              />
            ))
          : products.map(product => (
              <Product
                analyticsListName={analyticsListName}
                className={style.slide__main}
                key={product.id}
                product={product}
              />
            ))}
        <Link
          className={classNames(
            style.slide__main,
            style.product__shop_all_slider,
          )}
          href={seeAllHref ?? '/deals'}
        >
          <Icon name="right" />
          <p>{seeMoreText}</p>
        </Link>
      </Slider>
    </section>
  );
};

export default Products;
