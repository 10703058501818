import React from 'react';

import type { ProductJsonLdProps } from '@/types';

import { JsonLd } from './jsonld';

import { setOffers } from '../schemas/setOffers';
import { setReviews } from '../schemas/setReviews';
import { setAggregateRating } from '../schemas/setAggregateRating';
import { setAggregateOffer } from '../schemas/setAggregateOffer';
import { setManufacturer } from '../schemas/setManufacturer';
import { setBrand } from '../schemas/setBrand';

function ProductJsonLd({
  type = 'Product',
  keyOverride,
  brand,
  reviews,
  aggregateRating,
  manufacturerLogo,
  manufacturerName,
  offers,
  aggregateOffer,
  name,
  ...rest
}: ProductJsonLdProps) {
  const data = {
    ...rest,
    aggregateRating: setAggregateRating(aggregateRating),
    brand: setBrand(brand),
    manufacturer: setManufacturer({ manufacturerLogo, manufacturerName }),
    name,
    offers: offers ? setOffers(offers) : setAggregateOffer(aggregateOffer),
    review: setReviews(reviews),
  };

  return (
    <JsonLd
      keyOverride={keyOverride}
      type={type}
      {...data}
      scriptKey="Product"
    />
  );
}

export default ProductJsonLd;
