/* eslint-disable max-len */
/* eslint-disable no-undef */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Script from 'react-load-script';
import classNames from 'classnames';
import { useScreenWidth } from '@/hooks';
import styles from './Map.module.scss';
import Image from 'next/image';
import { MAP_PIN_SVG } from '@/constants';
import { ColorField } from '@prismicio/client';
import { omit } from 'lodash';

export interface ILocation {
  label: string;
  lat: number;
  lng: number;
  title: string;
  pin?: string;
  photo?: string;
  google_map_url?: string;
  url_slug?: string;
}

export interface IMarkerStyle {
  titleColor: ColorField;
  deaultMarkerColor: ColorField;
}
interface IMapsProps {
  actionClickMarker?: Dispatch<SetStateAction<google.maps.Marker>>;
  centered?: 'center' | 'left' | 'SE' | 'rigth';
  className?: string;
  hideInterestingPoints?: boolean;
  locations: ILocation[];
  openInfo?: boolean;
  overlay?: boolean;
  pinColor?: ColorField;
  darkMode?: boolean;
  defaultMarker?: string;
  markerStyle?: IMarkerStyle;
  zoom?: number;
  isLocation?: boolean;
  showTitle?: boolean;
}

const Maps: FC<IMapsProps> = ({
  actionClickMarker,
  locations,
  className,
  centered = 'center',
  hideInterestingPoints = false,
  openInfo = true,
  overlay = false,
  showTitle = true,
  pinColor,
  darkMode,
  defaultMarker,
  markerStyle,
  isLocation,
  zoom = 12,
}) => {
  const { width } = useScreenWidth();
  const [loading, setLoading] = useState(true);
  const ref = useRef<HTMLElement>(null);
  const isSingleLocation = useMemo(() => locations.length === 1, [locations]);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const mapRigth = centered === 'rigth';
  const mapSE = centered === 'SE';
  const mapLeft = centered === 'center' || centered === 'left';

  const createMarker = (
    lat: number,
    lng: number,
    label: string,
    map: google.maps.Map,
    bounds?: google.maps.LatLngBounds,
    pin?: string,
    locationImage?: string,
    google_map_url?: string,
    isLocation?: boolean,
  ) => {
    const pinSvg = {
      ...(pinColor
        ? { ...omit(MAP_PIN_SVG, 'fillColor'), fillColor: pinColor }
        : { ...MAP_PIN_SVG }),
    };

    const svgMarker = pin
      ? ({
          scaledSize: {
            height: 50,
            width: 40,
          },
          url: pin,
          labelOrigin: new google.maps.Point(15, 66),
        } as google.maps.Icon)
      : {
          anchor: new google.maps.Point(15, 30),
          ...pinSvg,
          labelOrigin: new google.maps.Point(15, 43),
          ...{
            ...MAP_PIN_SVG,
            fillColor: markerStyle?.deaultMarkerColor as string,
          },
        };

    const marker = new google.maps.Marker({
      icon: svgMarker,
      map,
      position: {
        lat,
        lng,
      },
      title: showTitle ? label : '',
      label: showTitle
        ? {
            text: label,
            color: markerStyle?.titleColor ?? '#000000',
            fontSize: '12px',
            fontWeight: 'bold',
          }
        : '',
    });

    marker.addListener('click', () => {
      if (actionClickMarker) {
        actionClickMarker(marker);
      }

      if (openInfo) {
        //const locationLink = `${TEMPLATE_BASE_PATH.LOCATION}${locationSlug}`;
        const maxWidth = isLocation ? '180px' : '150px';

        if (infoWindowRef.current) {
          infoWindowRef.current.close();
        }

        const info = new google.maps.InfoWindow({
          content: `
            <div style="font-size: 14px; color: #333; padding-top: 10px; min-width: 120px; max-width: ${maxWidth}">
              <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 6px;">
                <h3 style="margin: 0; font-size: 14px; font-weight:500; line-heigth: 20px; text-transform: uppercase">${label}</h3>
                <button id="closeButton" class="gm-ui-hover-effect" style="background: none; border: none; font-size: 16px; cursor: pointer;">✖</button>
              </div>
              <a href="${google_map_url ?? '/'}" target="_blank">
                <img src="${
                  locationImage ?? 'images/product-placeholder.svg'
                }" alt="${
            label ?? 'Dispensary'
          }" style="width: 100%; aspect-ratio:1/1;" />
              </a>
          </div>
          `,
        });

        infoWindowRef.current = info;

        info.open(map, marker);

        google.maps.event.addListenerOnce(info, 'domready', () => {
          document
            .getElementById('closeButton')
            ?.addEventListener('click', () => {
              info.close();
            });
        });
      }
    });

    try {
      bounds?.extend(
        marker.getPosition() as google.maps.LatLng | google.maps.LatLngLiteral,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getOffset = () => {
    if (width < 420) {
      return 0;
    }

    if (width < 768) {
      return 50;
    }

    if (width >= 768 && width < 900) {
      return 350;
    }

    if (width >= 900 && width < 1286) {
      return 450;
    }

    return 700;
  };

  useEffect(() => {
    if (
      locations.length &&
      Script.loadedScripts[process.env.NEXT_PUBLIC_GOOGLE_MAP_API]
    ) {
      const { lat, lng } = locations[0];
      const map = new google.maps.Map(ref.current as HTMLElement, {
        disableDefaultUI: true,
        ...(hideInterestingPoints && {
          styles: [
            {
              featureType: 'poi.attraction',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.government',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.medical',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.park',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.place_of_worship',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.school',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.sports_complex',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }),
        ...(overlay
          ? {
              styles: [
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  elementType: 'geometry',
                  stylers: [{ color: '#333333' }],
                },
                {
                  elementType: 'labels.text.stroke',
                  stylers: [{ color: '#333333' }],
                },
                {
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#686868' }],
                },
                {
                  elementType: 'labels.text.fill',
                  featureType: 'administrative.locality',
                  stylers: [{ color: '#686868' }],
                },
                {
                  elementType: 'geometry',
                  featureType: 'poi.park',
                  stylers: [{ color: '#263c3f' }],
                },
                {
                  elementType: 'labels.text.fill',
                  featureType: 'poi.park',
                  stylers: [{ color: '#6b9a76' }],
                },
                {
                  elementType: 'geometry',
                  featureType: 'road',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'geometry.stroke',
                  featureType: 'road',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'labels.text.fill',
                  featureType: 'road',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'geometry',
                  featureType: 'road.highway',
                  stylers: [{ color: '#746855' }],
                },
                {
                  elementType: 'geometry.stroke',
                  featureType: 'road.highway',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'labels.text.fill',
                  featureType: 'road.highway',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'geometry',
                  featureType: 'transit',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'labels.text.fill',
                  featureType: 'transit.station',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'geometry',
                  featureType: 'water',
                  stylers: [{ color: '#2b2b2b' }],
                },
                {
                  elementType: 'labels.text.fill',
                  featureType: 'water',
                  stylers: [{ color: '#515c6d' }],
                },
                {
                  elementType: 'labels.text.stroke',
                  featureType: 'water',
                  stylers: [{ color: '#2b2b2b' }],
                },
              ],
            }
          : {}),
        ...(darkMode
          ? {
              styles: [
                { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
                {
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#616161' }],
                },
                {
                  elementType: 'labels.text.stroke',
                  stylers: [{ color: '#f5f5f5' }],
                },
                {
                  featureType: 'administrative.land_parcel',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#bdbdbd' }],
                },
                {
                  featureType: 'poi',
                  elementType: 'geometry',
                  stylers: [{ color: '#eeeeee' }],
                },
                {
                  featureType: 'poi',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#757575' }],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'geometry',
                  stylers: [{ color: '#e5e5e5' }],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#9e9e9e' }],
                },
                {
                  featureType: 'road',
                  elementType: 'geometry',
                  stylers: [{ color: '#ffffff' }],
                },
                {
                  featureType: 'road.arterial',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#757575' }],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry',
                  stylers: [{ color: '#dadada' }],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#616161' }],
                },
                {
                  featureType: 'road.local',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#9e9e9e' }],
                },
                {
                  featureType: 'transit.line',
                  elementType: 'geometry',
                  stylers: [{ color: '#e5e5e5' }],
                },
                {
                  featureType: 'transit.station',
                  elementType: 'geometry',
                  stylers: [{ color: '#eeeeee' }],
                },
                {
                  featureType: 'water',
                  elementType: 'geometry',
                  stylers: [{ color: '#c9c9c9' }],
                },
                {
                  featureType: 'water',
                  elementType: 'labels.text.fill',
                  stylers: [{ color: '#9e9e9e' }],
                },
              ],
            }
          : {}),
        zoom,
        ...(width < 640 &&
          mapLeft &&
          isSingleLocation && {
            // Example adjustment for smaller screens
            center: {
              lat: centered === 'left' ? lat + 0.038 : lat,
              lng: centered === 'left' ? lng : lng,
            },
          }),
        ...(width >= 640 &&
          width <= 1024 &&
          mapLeft &&
          isSingleLocation && {
            center: {
              lat: centered === 'left' ? lat - 0.005 : lat,
              lng: centered === 'left' ? lng - 0.01 : lng,
            },
          }),
        ...(width >= 1024 &&
          mapLeft &&
          isSingleLocation && {
            center: {
              lat: centered === 'left' ? lat + 0.008 : lat,
              lng: centered === 'left' ? lng - 0.01 : lng,
            },
          }),
        ...(width >= 640 &&
          isSingleLocation &&
          mapSE && {
            center: {
              lat: lat - 0.005,
              lng: lng - 0.01,
            },
          }),
        ...(width < 640 &&
          mapSE &&
          isSingleLocation && {
            // Example adjustment for smaller screens
            center: {
              lat: lat + 0.038,
              lng: lng,
            },
          }),
        ...(width >= 768 &&
          mapRigth &&
          isSingleLocation && {
            center: {
              lat: lat,
              lng: lng - 0.3,
            },
          }),
        ...(width < 768 &&
          mapRigth &&
          isSingleLocation && {
            // Example adjustment for smaller screens
            center: {
              lat: lat + 0.04,
              lng: lng,
            },
          }),
        ...(!isSingleLocation && { zoom: 0 }),
      });

      const bounds = new google.maps.LatLngBounds();

      locations.forEach(location => {
        if (isSingleLocation) {
          createMarker(
            location.lat,
            location.lng,
            location.label,
            map,
            undefined,
            defaultMarker ?? location?.pin,
            location?.photo,
            location?.google_map_url,
            isLocation,
          );
          return;
        }
        createMarker(
          location.lat,
          location.lng,
          location.label,
          map,
          bounds,
          defaultMarker ?? location?.pin,
          location?.photo,
          location?.google_map_url,
        );
      });

      if (!isSingleLocation && !mapRigth) {
        map.fitBounds(bounds);
      }

      if (!isSingleLocation && mapRigth) {
        map.fitBounds(bounds, {
          top: 200,
          right: 50,
          bottom: 50,
          left: getOffset(),
        });
      }
    }
  }, [loading, locations, width]);

  return (
    <figure
      className={classNames(styles['map-multilocation'], className)}
      ref={ref}
    >
      {locations.length ? (
        !Script.loadedScripts[process.env.NEXT_PUBLIC_GOOGLE_MAP_API] && (
          <Script
            onLoad={() => {
              setLoading(false);
            }}
            url={process.env.NEXT_PUBLIC_GOOGLE_MAP_API}
          />
        )
      ) : (
        <>
          <Image
            alt="default map"
            className={styles.default_map}
            fill
            sizes="100vw"
            src={
              darkMode
                ? '/images/default_map_dark.png'
                : '/images/default_map.png'
            }
          />
          <div className={styles.empty_locations_square}>
            <i
              className={classNames('icon icon_search', styles.search_icon)}
            ></i>
            <span className={styles.title}>No stores available</span>
            <p className={styles.message}>
              There are no available stores in this area. Please try again to
              find a new store
            </p>
          </div>
        </>
      )}
    </figure>
  );
};

export default Maps;
