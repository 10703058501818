/* eslint-disable sort-keys */
export const ID_SCAN_URL = 'https://neurons.cdn.idscan.net/2.8.0-next';

const DefaultConfig = {
  autoContinue: true,
  chunkPublicPath: ID_SCAN_URL,
  el: 'videoCapturingEl',
  fixFrontOrientAfterUpload: false,
  hideDocumentTitle: false,
  isShowDocumentTypeSelect: false,
  isShowGuidelinesButton: false,
  language: 'en',
  licenseKey: process.env.NEXT_PUBLIC_ID_SCAN_DIVE_LICENSE_KEY,
  networkUrl: ID_SCAN_URL,
  processingImageFormat: 'jpeg',
  realFaceMode: 'auto',
  resizeUploadedImage: 1500,
  showSubmitBtn: false,
  useCDN: false,
  useHeic: false,
  documentTypes: [
    {
      type: 'ID',
      steps: [
        {
          type: 'front',
          name: 'Document Front',
          mode: { uploader: false, video: true },
          enableDesktopNotification: true,
        },
        {
          type: 'pdf',
          name: 'Document Back',
          mode: { uploader: false, video: true },
          enableDesktopNotification: true,
        },
        {
          type: 'face',
          name: 'Face',
          mode: { uploader: false, video: true },
        },
      ],
    },
    {
      type: 'Passport',
      steps: [
        {
          type: 'mrz',
          name: 'Passport Front',
          mode: { uploader: false, video: true },
        },
        {
          type: 'face',
          name: 'Face',
          mode: { uploader: false, video: true },
        },
      ],
    },
    {
      type: 'PassportCard',
      steps: [
        {
          type: 'front',
          name: 'Passport Card Front',
          mode: { uploader: false, video: true },
        },
        {
          type: 'mrz',
          name: 'Passport Card Back',
          mode: { uploader: false, video: true },
        },
        {
          type: 'face',
          name: 'Face',
          mode: { uploader: false, video: true },
        },
      ],
    },

    {
      type: 'InternationalId',
      steps: [
        {
          type: 'front',
          name: 'International ID Front',
          mode: { uploader: false, video: true },
        },
        {
          type: 'mrz',
          name: 'International ID Back',
          mode: { uploader: false, video: true },
        },
        {
          type: 'face',
          name: 'Face',
          mode: { uploader: false, video: true },
        },
      ],
    },
  ],
};

export default DefaultConfig;
