import { CSSProperties, FC } from 'react';
import { StatsSlice, StatsSliceAsPieChart } from 'prismicio-types';

import { Container } from '@/components';
import { PrismicRichText } from '@prismicio/react';
import {
  getSlicePadding,
  htmlSerializer,
  htmlSerializerWithProps,
} from '@/utils';

import style from './piechartvariation.module.scss';
import classNames from 'classnames';

export const PieChartVariation: FC<{
  slice: StatsSliceAsPieChart & StatsSlice;
}> = ({ slice }) => {
  // Function to calculate cumulative percentages
  const calculateCumulativePercentages = (total: number) => {
    const percentages: number[] = [];
    let cumulativePercentage = 0;
    for (const item of slice.items) {
      const percentage = ((item.stat_value ?? 0) / total) * 100;
      cumulativePercentage += percentage;
      percentages.push(cumulativePercentage);
    }
    return percentages;
  };
  const background = slice?.primary?.background_color as string;
  const marginBottom = slice?.primary?.bottom_spacing;
  const borderTop = slice?.primary?.border_section as string;
  const borderBottom = slice?.primary?.border_section as string;
  const paddingSection = slice?.primary?.padding_section;

  // Calculate total value
  let totalValue = 0;
  for (const item of slice.items) {
    totalValue += item.stat_value ?? 0;
  }

  // Calculate cumulative percentages
  const cumulativePercentages = calculateCumulativePercentages(totalValue);

  // Generate CSS variable string for conic gradient
  const conicGradient = cumulativePercentages
    .map((percent, i) => {
      const color = slice.items[i].stat_color;
      return `${color} ${
        i === 0 ? 0 : cumulativePercentages[i - 1]
      }% ${percent}%`;
    })
    .join(', ');

  return (
    <Container
      as="section"
      className={classNames(
        style.stats__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      style={{ background, borderTop, borderBottom }}
    >
      {slice?.primary?.title && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              alignSelf: slice.primary.title_align,
              textAlign: slice.primary.title_align,
            },
            style.stats__title,
          )}
          field={slice.primary.title}
        />
      )}
      <div className={style.stats__section}>
        <div
          className={style.stats__pie_chart}
          style={{ '--pie-colors': conicGradient } as CSSProperties}
        />
        <dl className={style.stats__list}>
          {slice?.items.map((item, index) => (
            <div
              className={style.stats__element}
              key={`${item.stat_title}-${index}`}
            >
              <dt className={style.stats__element_title}>{item.stat_title}</dt>
              <div className={style.stats__value_joint}>
                <div
                  className={style.stats__element_icon}
                  style={{ backgroundColor: item.stat_color as string }}
                />
                <dd className={style.stats__element_value}>
                  {`${(
                    ((item.stat_value ?? 0) / totalValue) *
                    100
                  ).toFixed()}%`}
                </dd>
              </div>
            </div>
          ))}
        </dl>
        <div className={style.stats__description}>
          <PrismicRichText
            components={htmlSerializer}
            field={slice.primary.description}
          />
        </div>
      </div>
    </Container>
  );
};
