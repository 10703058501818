import { useMemo } from 'react';
import {
  ApolloClient,
  createHttpLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { relayStylePagination } from '@apollo/client/utilities';
import { sha256 } from 'crypto-hash';
import merge from 'deepmerge';
import { isEqual } from 'lodash';
import fetch from 'cross-fetch';

import {
  authMiddleware,
  errorMiddleware,
  storeMiddleware,
} from './middlewares';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

function createApolloClient() {
  return new ApolloClient({
    cache: new InMemoryCache({
      // https://kamranicus.com/graphql-apollo-object-caching/
      dataIdFromObject: object => {
        switch (object.__typename) {
          case 'LineItem':
            return `${Math.random().toString(36).slice(-5)}-${object.id}`;
          default:
            return defaultDataIdFromObject(object);
        }
      },
      typePolicies: {
        Query: {
          fields: {
            searchProduct: relayStylePagination([
              'after',
              'first',
              'query',
              'sortKey',
            ]),
          },
        },
      },
    }),
    link: from([
      errorMiddleware,
      storeMiddleware,
      authMiddleware,
      createPersistedQueryLink({
        sha256,
        useGETForHashedQueries: true,
      }),
      createHttpLink({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_URL,
        fetch,
      }),
    ]),
    ssrMode: typeof window === 'undefined',
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}
