import { FC, useState } from 'react';

import {
  FilterButton,
  FiltersContainer,
  FiltersModal,
  OutsideFilters,
  SortByMenu,
} from './FiltersTreez.components';

interface IFiltersTreez {
  showDealsFilter?: boolean;
}

const FiltersTreez: FC<IFiltersTreez> = ({ showDealsFilter = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [priceFilterActive, setPriceFilterActive] = useState<boolean>(false);
  const [thcFilterActive, setThcFilterActive] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(open => !open);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <FiltersContainer>
        <FilterButton onClick={handleClick} />
        <OutsideFilters />
        <SortByMenu />
      </FiltersContainer>
      <FiltersModal
        isOpen={isOpen}
        onClose={handleClose}
        priceFilterActive={priceFilterActive}
        setPriceFilterActive={setPriceFilterActive}
        setThcFilterActive={setThcFilterActive}
        showDealsFilter={showDealsFilter}
        thcFilterActive={thcFilterActive}
      />
    </>
  );
};

export { FiltersTreez };
