'use client';
/* eslint-disable no-undef */
import { FC, useEffect, useState } from 'react';

import {
  Address as AddressInput,
  Alert,
  buildAddressFromComponents,
  Button,
  Heading,
  Icon,
  Input,
} from '@/components';
import { useForm, useValidator } from '@/data';
import { OrderAddress, Store } from '@/types';

import style from './address.module.scss';

const Address: FC<IAddressProps> = ({
  id,
  isDelivery,
  isAccount,
  state,
  setState,
  value,
  onChange,
  editable,
  setIsAddressOpen,
  className,
  noType,
  hideInput,
}) => {
  const [error, setError] = useState<IError>({
    isOpen: false,
    message: '',
  });
  const [isGeolocationAvailable] = useState(false);
  const [isGeolocationLoading, setIsGeolocationLoading] = useState(false);
  const { inputs, setInputs, inputChange } = useForm();

  const isEqual = (address1, address2) => {
    return (
      address1.latitude === address2.latitude &&
      address1.longitude === address2.longitude
    );
  };

  const handleChangeAddress = address => {
    // Add a new item to address list only when
    // the new address is not already on it.
    const exist = state.some(current => isEqual(current, address));

    setError({
      isOpen: false,
      message: '',
    });
    onChange(address, exist ? state : [address, ...state]);
  };

  const { validator, setIsSubmited, isInvalid } = useValidator(
    [
      {
        field: id,
        message: 'address is required.',
        method: 'isEmpty',
        validWhen: false,
      },
    ],
    inputs,
  );

  const handleSave = e => {
    e.preventDefault();

    setIsSubmited(true);

    if (isInvalid) {
      return;
    }

    setIsAddressOpen && setIsAddressOpen(false);
    handleChangeAddress(inputs);
  };

  const handleRemoveAddress = index => {
    const addresses = [...state];
    addresses.splice(index, 1);

    setState({ addresses });
  };

  const handleCurrentLoction = () => {
    setIsGeolocationLoading(true);

    navigator.geolocation.getCurrentPosition(position => {
      const geocoder = new google.maps.Geocoder();
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      try {
        geocoder.geocode(
          {
            location: {
              lat: latitude,
              lng: longitude,
            },
          },
          res => {
            const bestResult =
              res?.find(result => result.types.includes('street_address')) ??
              res?.[0];

            if (bestResult) {
              const address = {
                latitude,
                longitude,
                ...buildAddressFromComponents(bestResult),
              };

              handleChangeAddress(address);
            } else {
              setError({
                isOpen: true,
                message: 'Unable to get the current location.',
              });
            }
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsGeolocationLoading(false);
      }
    });
  };

  useEffect(() => {
    // Determine if the browser support geolocation
    if (navigator.geolocation) {
      // setIsGeolocationAvailable(true);
    }
  }, []);

  // Auto select the address to the first one in the list
  // by default as long as the address list change.
  useEffect(() => {
    const exist = state.some(current => isEqual(current, value));

    if (!exist && state.length > 0) {
      handleChangeAddress({
        address1: state[0].address1,
        address2: state[0].address2,
        city: state[0].city,
        city_code: state[0].city,
        country: state[0].country,
        country_code: state[0].country_code,
        latitude: state[0].latitude,
        longitude: state[0].longitude,
        province: state[0].province,
        province_code: state[0].province_code,
        zip: state[0].zip,
      });
    }
  }, [state, isDelivery]);

  return (
    <div
      className={`${style['address-picker']} ${
        editable ? style['address-picker_editable'] : ''
      } ${className ?? ''}`}
    >
      <Heading className={style['heading_level-3']} level={3}>
        {hideInput ? '' : 'Select '}
        {noType && !hideInput
          ? 'an address'
          : isDelivery
          ? 'a delivery address'
          : noType && hideInput
          ? ''
          : 'a pickup location'}
      </Heading>
      {!isAccount && state.length > 0 && (
        <address className={style.address_container}>
          {state.map((address, i) => (
            <div key={`${address.latitude}-${address.longitude}`}>
              <div className={style['address_select-address-container']}>
                <label className={style.address_item} htmlFor="address">
                  <input
                    checked={isEqual(address, value)}
                    className={style.input}
                    name="address"
                    onChange={() => handleChangeAddress(address)}
                    type="radio"
                  />
                  <div className={style.address_label}>
                    <span className={style.address1}>
                      {address.address1}
                      {address.address2 ? ` ${address.address2}` : ''}
                    </span>
                    <small className={style.city}>
                      {`
                                ${address.city}, 
                                ${address.province} 
                                ${address.zip},
                                ${address.country_code}`}
                    </small>
                  </div>
                </label>
                {editable && (
                  <Button
                    aria-label="Remove address"
                    className={style.btn_remove}
                    color="link"
                    disabled={isEqual(address, value)}
                    icon
                    onClick={() => handleRemoveAddress(i)}
                    size="sm"
                  >
                    <Icon name="trash" />
                  </Button>
                )}
              </div>
              {i < state.length - 1 && <hr className={style.divider} />}
            </div>
          ))}
        </address>
      )}
      {editable && (
        <div className={style['address-picker__editor']}>
          <Alert
            error
            isOpen={error.isOpen}
            toggle={() =>
              setError({
                isOpen: false,
                message: '',
              })
            }
          >
            {error.message}
          </Alert>
          {noType && !hideInput && (
            <AddressInput
              country={['us']}
              feedback={validator[id].message}
              id={id}
              isInvalid={validator[id].isInvalid}
              name={id}
              onAddressChange={address => {
                isAccount
                  ? setInputs({
                      ...inputs,
                      ...address,
                    })
                  : handleChangeAddress(address);
              }}
              placeholder="Address"
            />
          )}

          {noType && !hideInput && isAccount && (
            <>
              <Input
                className={style.input__apartment}
                inputLabel="Apartment or suite"
                name="address2"
                onChange={inputChange}
                placeholder="Apartment or suite (optional)"
                value={inputs.address2 ?? ''}
              />
              <div className={style.address__actions}>
                <Button
                  className={style.address__btn}
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  className={style.address__btn}
                  color="secondary"
                  onClick={() => {
                    setIsAddressOpen && setIsAddressOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}

          {isGeolocationAvailable && (
            <Button
              className={style['address-picker__current']}
              color="link"
              loading={isGeolocationLoading}
              onClick={handleCurrentLoction}
              size="sm"
            >
              <Icon name="crosshairs" /> Or pin your current location
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

interface IError {
  isOpen: boolean;
  message: string;
}

interface IAddressProps {
  className?: string;
  editable: boolean;
  hideInput?: boolean;
  id: string;
  isAccount?: boolean;
  isDelivery?: boolean;
  noType?: boolean;
  onChange: (_address: any, _addresses: any) => void;
  setIsAddressOpen?: (_arg: boolean) => void;
  setState: (_arg: any) => void;
  state: OrderAddress[] | Store[];
  value: OrderAddress;
}

export default Address;
