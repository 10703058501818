import classNames from 'classnames';

function Features({ items, className, ...props }) {
  const activeItems = items.filter(item => item.status);
  const sortedItems = activeItems.sort((a, b) => a.position - b.position);

  if (!activeItems.length) {
    return null;
  }

  return (
    <ul className={classNames('features', className)} {...props}>
      {sortedItems.map(({ id, name, description }) => (
        <li className="features__item" key={id}>
          <h2>{name}</h2>
          <p>{description}</p>
        </li>
      ))}
    </ul>
  );
}

export default Features;
