import { FC, MouseEventHandler } from 'react';

import classNames from 'classnames';

import { Icon } from '../icon';

import style from './close.module.scss';

interface ICloseProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const Close: FC<ICloseProps> = ({ className, onClick }) => {
  return (
    <button
      aria-label="close"
      className={classNames(style.close, className)}
      onClick={onClick}
      type="button"
    >
      <Icon name="close" />
    </button>
  );
};

export default Close;
