import classNames from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';

const Heading: FC<IHeadingProps> = ({
  children,
  level,
  color,
  className,
  style,
}) => {
  const Tag: any = `h${level}`;

  return (
    <Tag
      className={classNames('heading', `heading_level-${level}`, className)}
      style={{ color: `${color}`, ...style }}
    >
      {children}
    </Tag>
  );
};

interface IHeadingProps {
  children: ReactNode;
  className?: string;
  color?: string;
  level: number;
  style?: CSSProperties;
}

export default Heading;
