import { Ref, useMemo, useState } from 'react';

import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Placement } from '@popperjs/core';

import { Icon } from '@/components';

import styles from './select.module.scss';

export interface ISelectOption<T> {
  label: string;
  value: T;
}

interface ISelectProps<T> {
  options: ISelectOption<T>[];
  value?: ISelectOption<T>;
  defaultValue?: ISelectOption<T>;
  name?: string;
  placeholder?: string;
  className?: string;
  placeholderLabel?: string;
  menuClassName?: string;
  placeholderLabelClassName?: string;
  defaultSelectedLabelClassName?: string;
  menuOptionClassName?: string;
  selectLabelClassName?: string;
  feedback?: string;
  isInvalid?: boolean;
  placement?: Placement;
  onChange: (_option: ISelectOption<T>) => void;
}

const Select = <T,>({
  name = 'select',
  className,
  menuClassName,
  selectLabelClassName,
  placeholderLabelClassName,
  defaultSelectedLabelClassName,
  menuOptionClassName,
  options,
  placeholderLabel,
  placeholder = '',
  defaultValue = undefined,
  value,
  placement,
  isInvalid,
  feedback,
  onChange,
}: ISelectProps<T>) => {
  const selectedOption = useMemo<ISelectOption<T> | undefined>(
    () => defaultValue ?? value,
    [defaultValue, value],
  );
  const selectedLabel = useMemo(() => selectedOption?.label, [selectedOption]);
  const [button, setButton] = useState<HTMLButtonElement | undefined>();
  const [panel, setPanel] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(button, panel, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 16,
        },
      },
    ],
    placement: placement ?? 'bottom-start',
  });

  const handleSelection = (value: ISelectOption<T>) => {
    onChange(value);
    button?.click();
  };

  return (
    <Popover className={classNames(className, styles.select)}>
      <PopoverButton
        className={classNames(styles.select__trigger, {
          [styles.select__invalid]: isInvalid,
          [styles.selected]: selectedLabel,
        })}
        name={name}
        ref={setButton as Ref<HTMLButtonElement>}
      >
        <span
          className={classNames(selectLabelClassName, styles.select__label, {
            [defaultSelectedLabelClassName as string]:
              value?.value === 'default',
          })}
        >
          {placeholderLabel && value?.value !== 'default' && (
            <span className={placeholderLabelClassName}>
              {placeholderLabel}
            </span>
          )}
          {selectedLabel ?? placeholder}
        </span>
        <Icon className={styles.select__icon} name={'angle-down'} />
      </PopoverButton>
      <PopoverPanel
        className={classNames(styles.select__menu, menuClassName)}
        ref={setPanel as Ref<HTMLDivElement>}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        <div className={styles.select__menu_body}>
          {options.map((option, index) => {
            return (
              <div
                className={classNames(
                  menuOptionClassName,
                  styles['select__menu-item'],
                  {
                    [styles['is-selected']]:
                      option.value === selectedOption?.value,
                  },
                )}
                key={index}
                onClick={() => {
                  handleSelection(option);
                }}
                role="button"
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </PopoverPanel>
      {isInvalid && feedback && (
        <p className={styles.select__feedback}>
          <Icon name="info" /> {feedback}
        </p>
      )}
    </Popover>
  );
};

export { Select };
