import React from 'react';

import type {
  CarouselJsonLdProps,
  DefaultDataProps,
  ProductJsonLdProps,
} from '@/types';

import { JsonLd } from './jsonld';

function CarouselJsonLd({
  data,
  keyOverride,
  ofType,
  type = 'Carousel',
}: CarouselJsonLdProps) {
  function generateList(
    data: CarouselJsonLdProps['data'],
    ofType: CarouselJsonLdProps['ofType'],
  ) {
    switch (ofType) {
      case 'default':
        return (data as DefaultDataProps[])?.map((item, index) => ({
          '@type': 'ListItem',
          position: `${index + 1}`,
          url: item.url,
        }));

      case 'product':
        return (data as ProductJsonLdProps[])?.map((item, index) => ({
          '@type': 'ListItem',
          item: {
            '@context': 'https://schema.org',
            '@type': 'Product',
            ...item,
          },
          position: `${index + 1}`,
        }));
    }
  }

  const d = {
    '@type': 'ItemList',
    itemListElement: generateList(data, ofType),
  };

  return (
    <JsonLd keyOverride={keyOverride} type={type} {...d} scriptKey="Carousel" />
  );
}

export default CarouselJsonLd;
