'use client';

import { FC, ReactNode, useState } from 'react';

import classNames from 'classnames';

import { Icon } from '@/components';

import styles from './Accordion.module.scss';

interface IAccordionProps {
  children: ReactNode;
  className?: string;
  classNameHeader?: string;
  haveIconBefore?: boolean;
  isExpanded?: boolean;
  iconExpanded?: string;
  iconCollapsed?: string;
  label: ReactNode;
}

const Accordion: FC<IAccordionProps> = ({
  label,
  isExpanded = false,
  children,
  haveIconBefore,
  className,
  classNameHeader,
  iconExpanded,
  iconCollapsed,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);
  return (
    <section className={classNames(styles.accordion, className)}>
      <div
        className={classNames(styles.accordion_header, classNameHeader, {
          [styles.prepended_icon]: haveIconBefore,
        })}
        onClick={() => setExpanded(expanded => !expanded)}
        role="button"
      >
        <div className={styles.accordion_title}>{label}</div>
        <Icon
          className={styles.accordion_icon}
          name={
            expanded
              ? iconExpanded ?? 'angle-up'
              : iconCollapsed ?? 'angle-down'
          }
        />
      </div>
      <div aria-expanded={!expanded} className={styles.accordion_body}>
        {children}
      </div>
    </section>
  );
};

export { Accordion };
