import { createPortal } from 'react-dom';
import { FC, ReactNode, useEffect } from 'react';

const Notification: FC<INotificationProps> = ({
  children,
  isOpen,
  toggle,
  delay,
  isError,
}) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        toggle();
      }, delay ?? 5000);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div role="alert">
      <p className={isError ? 'error' : ''}>{children}</p>
      <style jsx>{`
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 20;

        p {
          background: #46d407;
          color: white;
          text-align: center;
          padding: 16px;
        }

        p.error {
          background: #f42828;
          color: white;
        }
      `}</style>
    </div>,
    document.body,
  );
};

interface INotificationProps {
  children: ReactNode;
  delay?: number;
  isError?: boolean;
  isOpen: boolean;
  toggle: () => void;
}

export default Notification;
