'use client';

import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';

import { TEMPLATE_BASE_PATH } from '@/constants';
import { GProduct } from '@/types';
import { Button, Heading, Product } from '@/components';
import { useWishList } from '@/hooks';
interface WishlistProps {
  title?: string;
  titleLevel?: number;
}

const Wishlist: FC<WishlistProps> = ({
  title = 'Favorite Products',
  titleLevel = 2,
}) => {
  const router = useRouter();
  const [fav, setFav] = useState<GProduct[]>([]);
  const { favProducts } = useWishList();

  useEffect(() => {
    setFav(favProducts);
  }, [favProducts]);

  const goToShop = () => {
    router.push(TEMPLATE_BASE_PATH.SHOP);
  };

  return (
    <div className={'wishlist__container_account'}>
      <Heading className="wishlist_title" level={titleLevel}>
        {title}
      </Heading>
      {!fav.length && (
        <div className="wishlist__blank_container">
          <div className="wishlist__blank">
            <div className="wishlist__blank_body">
              <span className="wishlist__blank__title">
                You have not added products to the wishlist?
              </span>
              <p>Add some items to get started</p>
            </div>
            <Button color="primary" onClick={goToShop}>
              Shop now
            </Button>
          </div>
        </div>
      )}
      {fav.length > 0 && (
        <div className="wishlist__content">
          {fav?.map(prod => {
            return (
              <Product
                className={'wishlist_product'}
                key={prod?.id as string}
                product={prod}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export { Wishlist };
