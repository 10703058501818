import { useEffect, useState } from 'react';
import {
  useApplyPromoCode,
  useEcomStore,
  useForm,
  useValidator,
} from '../data';
import { Alert, Button, Heading, Input, Placeholder } from '../components';
import { useSummary, useUpdateCart } from '@/hooks';
import { currencyFormat } from '@/utils';

function Summary({ loading, setLoading, placeOrderBtn }) {
  const [state, setState] = useEcomStore();
  const [applying, setApplying] = useState(false);
  const [error, setError] = useState({});
  const { total, subtotal, delivery, tax, discounts } = useSummary();
  const updateCart = useUpdateCart();
  const { inputs, inputChange, genericChange } = useForm();
  const { validator, isInvalid, setIsSubmited } = useValidator(
    [
      {
        field: 'promo_code',
        message: 'Invalid promotion code.',
        method: 'isEmpty',
        validWhen: false,
      },
    ],
    inputs,
  );

  const isPromoCodeApplied =
    state.discount_items.length > 0 && state.discount_items[0]?.promo_code;

  // Apply Promo Code
  // 1. If apply doesn't return null
  // 2. Update the discount_items for the local order
  // 3. Send the order to be update on the server
  useEffect(() => {
    if (isPromoCodeApplied) {
      genericChange('promo_code', state.discount_items[0].promo_code);
    }
  }, [inputs.promo_code]);

  const { applyPromoCode } = useApplyPromoCode({
    onCompleted: res => {
      if (res.applyPromoCode) {
        setError({});
        setState({
          discount_items: [res.applyPromoCode],
        });
        updateCart({
          ...state,
          discount_items: [res.applyPromoCode],
        }).then(() => {
          setApplying(false);
          setLoading(false);
        });
      } else {
        setError({
          isError: true,
          isOpen: true,
          message: 'Enter a valid discount code.',
        });
        setApplying(false);
        setLoading(false);
      }
    },
    onError: e => {
      setError({
        isError: true,
        isOpen: true,
        message: 'Enter a valid discount code.',
      });
      setApplying(false);
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(e);
    },
  });

  const onApplyPromoCode = () => {
    setIsSubmited(true);

    if (!isInvalid) {
      setLoading(true);

      if (isPromoCodeApplied) {
        genericChange('prmo_code', '');
        setState({
          discount_items: [],
        });
        updateCart({
          ...state,
          discount_items: [],
        }).finally(() => {
          setLoading(false);
        });
      } else {
        setApplying(true);
        applyPromoCode({
          variables: {
            order_id: state.entity_id,
            promo_code: inputs.promo_code,
          },
        });
      }
    }
  };

  const valuePlaceholder = (
    <Placeholder
      style={{
        display: 'block',
        height: 22,
        width: '100%',
      }}
    />
  );

  return (
    <aside>
      <Heading level={2}>Summary</Heading>
      <div>
        <table className="checkout__summary-information">
          <tbody>
            <tr>
              <td>Subtotal</td>
              <td>${currencyFormat(subtotal)}</td>
            </tr>
            <tr>
              <td>Delivery fee</td>
              <td>
                {loading
                  ? valuePlaceholder
                  : delivery > 0
                  ? `$${currencyFormat(delivery)}`
                  : 'Free'}
              </td>
            </tr>
            <tr>
              <td>Tax</td>
              <td>
                {loading
                  ? valuePlaceholder
                  : tax > 0
                  ? `$${currencyFormat(tax)}`
                  : 'Included'}
              </td>
            </tr>
            {discounts > 0 && (
              <tr className="checkout__summary_discount">
                <td>Discounts</td>
                <td>${currencyFormat(discounts)}</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr className="checkout__summary_total">
              <td scope="row">Total to pay</td>
              <td>
                {loading ? valuePlaceholder : `$${currencyFormat(total)}`}
              </td>
            </tr>
          </tfoot>
        </table>
        <Alert
          error={error.isError}
          isOpen={error.isOpen}
          toggle={() => setError({ isOpen: false })}
        >
          {error.message}
        </Alert>
        <form className="checkout__summary_promo">
          <div>
            <Input
              feedback={validator.promo_code.message}
              isInvalid={validator.promo_code.isInvalid}
              name="promo_code"
              onChange={inputChange}
              readOnly={isPromoCodeApplied}
              value={inputs.promo_code ?? ''}
            >
              Promotion code
            </Input>
          </div>
          <Button
            color="primary"
            disabled={loading}
            loading={applying}
            onClick={onApplyPromoCode}
          >
            {isPromoCodeApplied ? 'Reset' : 'Apply'}
          </Button>
        </form>
        {placeOrderBtn}
      </div>
    </aside>
  );
}

export default Summary;
