import { FC, ReactElement, useMemo, useState } from 'react';
import { useUiStore } from '@/data';
import { Button, Heading, Icon, Link, PopoverPanel } from '@/components';
import styles from './filtersblog.module.scss';
import classNames from 'classnames';
import { startCase } from 'lodash';
import { IBlogTags } from 'types/app_types';
import { TEMPLATE_BASE_PATH } from '@/constants';

interface IFiltersBlog {
  filterOptions: IBlogTags;
}

const FiltersBlog: FC<IFiltersBlog> = ({ filterOptions }) => {
  const [state, setState] = useUiStore();
  const [show, setShow] = useState(10);

  const keys = useMemo(() => {
    return Object.keys(filterOptions).slice(0, show);
  }, [filterOptions, show]);

  if (keys.length === 0) {
    return null as unknown as ReactElement<any>;
  }

  const Filters = (desktop?: boolean) => {
    return (
      <div className={styles.content}>
        {(desktop ? keys : Object.keys(filterOptions)).map((key, index) => {
          // eslint-disable-next-line max-len
          const url = `${TEMPLATE_BASE_PATH.BLOG_TAG}${filterOptions[key].slug}`;

          return (
            <Link className={styles.filters__links} href={url} key={index}>
              <span className={styles.text_underline}>{startCase(key)}</span>
              <span className={styles.count}>{filterOptions[key].count}</span>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classNames(styles.filters, 'blog__filters')}>
      <div className={styles.filters_mobile}>
        <Button
          className={styles.filters_mobile__button}
          onClick={() =>
            setState({
              is_filter_blog_open: !state.is_filter_blog_open,
            })
          }
        >
          <p className={'stores_list__content__filters__title'}>Tags</p>
          <Icon className={styles.filters_mobile__button__icon} name="tune" />
        </Button>
        <PopoverPanel
          className={styles.filters_mobile__menu}
          isOpen={state.is_filter_blog_open}
          toggle={() => setState({ is_filter_blog_open: false })}
        >
          {Filters(false)}
        </PopoverPanel>
      </div>
      <div className={styles.filters_desktop}>
        <Heading className={styles.filters_desktop__heading} level={5}>
          Filter by topic
        </Heading>
        <div className={styles.filters_desktop__menu}>
          <p>Tags</p>
          {Filters(true)}
          <Button
            className={classNames(styles.content__button, {
              [styles.content__button_disabled]:
                show >= Object.keys(filterOptions).length,
            })}
            disabled={false}
            onClick={() => {
              setShow(show + 10);
            }}
            type="button"
          >
            Show More
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FiltersBlog;
