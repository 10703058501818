import { useData } from '@/components';
import { useSelectedStore } from '@/hooks';
interface IUseFeaturedFlagResponse {
  isDiscountsList: boolean;
  isGRLimitActive: boolean;
  isProductCardLargImage: boolean;
  showStriketroughPrice: boolean;
  showRegulationModal: boolean;
  disableStoreRewards: boolean;
  keepShoppingActive: boolean;
  isTaxAppliedMessage: boolean;
  disableProductReviews: boolean;
  isSingleHeroBanner: string | undefined;
  wishlistActive: boolean;
  isMedAndRecSelectionActive: boolean;
  isAddToCartInKioskDisabled: boolean;
  isSurfsideActive: boolean;
  showProductWarning: boolean;
  isStartUpPlan: boolean;
  allowCancelOrder: boolean;
  allowIdVerifiationDelivery: boolean;
  allowIdVerifiationPickup: boolean;
  forceToSelectStore: boolean;
  allowMedicalSignUps: boolean;
  allowCurbsidePickup: boolean;
  curbsidePickupPlaceholder: string;
}

const useFeaturedFlag = (): IUseFeaturedFlagResponse => {
  const { productCard } = useData();
  const { store } = useSelectedStore();

  const isWishlistActive =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_WISH_LIST_ACTIVE ?? 'false';

  const isSingleHeroBanner =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_SINGLE_HERO_BANNER;

  const isDiscountsList =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DISCOUNTS_LIST_ACTIVE;

  const isGRLimitActive = process.env.NEXT_PUBLIC_FEATURE_FLAG_GRLIMIT_ACTIVE;

  const isProductCardLargImage = productCard?.product_card_large ?? false;

  const isMedAndRecSelectionActive =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_MED_REC_SELECTION_ACTIVE ?? 'false';

  const showStriketroughPrice =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_SHOW_STRIKETROUGH_PRICE ?? 'true';

  const disableStoreRewards =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DISABLE_STORE_REWARDS ?? 'false';

  const keepShoppingActive =
    process.env.NEXT_PUBLIC_KEEP_SHOPPING_BUTTON_ACTIVE ?? 'false';

  const disableProductReviews = true;
  // We are disabling process.env.NEXT_PUBLIC_FEATURE_FLAG_DISABLE_PRODUCT_REVIEWS feature flag until the
  // review module is deploy

  const isAddToCartInKioskDisabled =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DISABLE_ADD_TO_CART_IN_KIOSK ??
    'false';

  const surfsideActive = process.env.NEXT_PUBLIC_SURFSIDE_ACTIVE ?? 'false';

  const showProductWarning =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_P65_WARNING ?? 'true';

  const isStartUpPlan =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_IS_STARTUP_PLAN ?? 'false';

  const allowCancelOrder =
    process.env.NEXT_PUBLIC_ALLOW_CANCEL_ORDER_FROM_CONFIRMATION_PAGE ?? 'true';

  const allowIdVerifiationDelivery =
    process.env.NEXT_PUBLIC_ALLOW_ID_VERIFICATION_FOR_DELIVERY ?? 'false';

  const allowIdVerifiationPickup =
    process.env.NEXT_PUBLIC_ALLOW_ID_VERIFICATION_FOR_PICKUP ?? 'false';

  const forceToSelectStore =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_FORCE_TO_SELECT_STORE ?? 'false';

  const showRegulation =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_SB_540_REGULATION ?? 'false';

  const allowMedicalSignUps =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_ALLOW_MEDICAL_SIGN_UPS ?? 'false';

  const allowCurbsidePickup =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_ALLOW_CURBSIDE_PICKUP ?? 'false';

  const curbsidePickupPlaceholder =
    process.env.NEXT_PUBLIC_FEATURE_FLAG_CURBSIDE_PICKUP_PLACEHOLDER ?? '';

  return {
    isDiscountsList: isDiscountsList === 'true',
    isGRLimitActive: isGRLimitActive === 'true',
    isProductCardLargImage: isProductCardLargImage,
    showStriketroughPrice: showStriketroughPrice === 'true',
    disableStoreRewards: disableStoreRewards === 'true',
    keepShoppingActive: keepShoppingActive === 'true',
    disableProductReviews,
    isTaxAppliedMessage: store?.display_post_tax || false,
    isSingleHeroBanner,
    wishlistActive: isWishlistActive === 'true',
    isMedAndRecSelectionActive: isMedAndRecSelectionActive === 'true',
    isAddToCartInKioskDisabled: isAddToCartInKioskDisabled === 'true',
    isSurfsideActive: surfsideActive === 'true',
    showProductWarning: showProductWarning === 'true',
    isStartUpPlan: isStartUpPlan === 'true',
    allowCancelOrder: allowCancelOrder === 'true',
    allowIdVerifiationDelivery: allowIdVerifiationDelivery === 'true',
    allowIdVerifiationPickup: allowIdVerifiationPickup === 'true',
    forceToSelectStore: forceToSelectStore === 'true',
    showRegulationModal: showRegulation === 'true',
    allowMedicalSignUps: allowMedicalSignUps === 'true',
    allowCurbsidePickup: allowCurbsidePickup === 'true',
    curbsidePickupPlaceholder,
  };
};

export { useFeaturedFlag };
