/* eslint-disable max-len */
import { Badge, Button, Heading, Icon, Price } from '../components';
import { format } from 'date-fns';
import { currencyFormat } from '@/utils';

function Order({ order, details, loading, refetch }) {
  const { delivery_address: address, status } = order;
  const finalStates = ['DECLINED', 'VOID', 'REFUNDED'];
  const states = [
    'PENDING',
    'PROCESSING',
    finalStates.includes(status) ? status : 'DELIVERED',
  ];
  const icons = ['cart', 'car', states[2] === 'DELIVERED' ? 'home' : 'close'];
  const progress = ((2 * states.indexOf(status) + 1) / 6) * 100;

  return (
    <div className="order-details">
      <Heading level={2}>Status</Heading>
      <div className="thankyou__fieldset">
        {details && (
          <p className="order-details__order-number">
            <strong>Order number: {`${order.order_number}`}</strong>
          </p>
        )}
        Created at: {format(new Date(order.created_at), "PPPP 'at' h:mmaaa")}
        <div className="order-details__status">
          <div
            className="order-details__status-bar"
            style={{
              background: `
                            linear-gradient(
                                to right,
                                #059669 ${progress}% ,
                                 #e7e7e7 ${progress}%)
                                 `,
            }}
          />
          <ol>
            {states.map((state, i) => (
              <li className={`${state === status ? 'active' : ''}`} key={state}>
                <span>
                  <Icon name={icons[i]} />
                </span>
                {state === status && state.toLowerCase()}
              </li>
            ))}
          </ol>
        </div>
        {details && (
          <Button
            className="order__refresh-status"
            color="primary"
            loading={loading}
            onClick={() => refetch()}
            sm
          >
            Refresh state
          </Button>
        )}
      </div>
      {order.delivery_details && (
        <Heading level={2}>
          {order.delivery_details.delivery ? 'Delivery' : 'Pickup'} address
        </Heading>
      )}
      <div className="thankyou__fieldset">
        <p className="info">
          <span>
            <Icon name="location" /> {address.address1}
            {address.address2 ? ` ${address.address2}` : ''}, {address.city},{' '}
            {address.province} {address.zip}, {address.country_code}
          </span>
        </p>
        <p className="thankyou__notes">
          Notes: {order.notes || 'No additional notes specified.'}
        </p>
      </div>
      <Heading level={2}>Ordered items</Heading>
      <div className="thankyou__fieldset">
        <ul className="order-details__items">
          {order.line_items.map(item => (
            <li key={item.item_id}>
              <Badge className="order-details__item-qty">
                {item.quantity}x
              </Badge>
              {item.name}{' '}
              <Price
                className="order-details__item-price"
                price={`$${currencyFormat(item.price)}`}
                salePrice={`$${currencyFormat(item.variants[0].price)}`}
                special={item.price !== item.variants[0].price}
              />
              <Badge className="order-details__item-gram">
                Gram:{' '}
                {item.variants.find(variant => variant.type === 'Gram').name}
              </Badge>
              {item?.in_stock == false && details && (
                <Badge className="order-details__out-stock-badge">
                  Out of Stock
                </Badge>
              )}
            </li>
          ))}
        </ul>
        <p className="order-details__total">
          Total{' '}
          {['PENDING', 'PROCESSING'].includes(status) ? 'to pay' : 'payed'}: $
          {currencyFormat(order.total_price)}
        </p>
      </div>
    </div>
  );
}

export default Order;
