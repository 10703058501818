import { FC } from 'react';
import classNames from 'classnames';
import { CenteredHeaderSlice } from '@/types';
import { useIsMobile, useShowByLocationSlug } from '@/hooks';
import { Container } from '@/components';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';
import styles from './centeredheader.module.scss';
import { PrismicRichText } from '@prismicio/react';

const CenteredHeader: FC<CenteredHeaderSlice> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);
  const { isMobile } = useIsMobile(768);

  const padding = slice?.primary?.custom_bottom_spacing
    ? `${slice?.primary?.custom_bottom_spacing} 0`
    : 0;
  const paddingSection = slice?.primary?.padding_section;
  const backgroundColor = slice?.primary?.background_color;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;
  const maxWidth = isMobile
    ? slice?.primary?.max_width_mobile
    : slice?.primary?.max_width;
  const fontSize = slice?.primary?.font_size || undefined;

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(styles.container, getSlicePadding(paddingSection), {
        ['background__fixed_cover']: backgroundStyleFixed,
        ['background__contained']: !backgroundStyleFixed,
      })}
      style={{
        padding,
        backgroundColor: backgroundColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={styles.container__body}>
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              color: slice?.primary?.color,
              maxWidth: maxWidth,
            },
            `heading_fontSize__${fontSize}`,
          )}
          field={slice?.primary?.text}
        />
      </Container>
    </section>
  );
};

export default CenteredHeader;
