/* eslint-disable max-len */
export const stockImages = {
  beverage: '/images/product-placeholder.svg',
  pill: '/images/product-placeholder.svg',
  tincture: '/images/product-placeholder.svg',
  cartridge: '/images/product-placeholder.svg',
  preroll: '/images/product-placeholder.svg',
  flower: '/images/product-placeholder.svg',
  extract: '/images/product-placeholder.svg',
  edible: '/images/product-placeholder.svg',
  topical: '/images/product-placeholder.svg',
  merch: '/images/product-placeholder.svg',
};
