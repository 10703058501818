import React from 'react';

import type { BreadCrumbJsonLdProps } from '@/types';

import { setItemListElements } from '../schemas/setItemListElements';
import { JsonLd } from './jsonld';

function BreadCrumbJsonLd({
  type = 'BreadcrumbList',
  keyOverride,
  itemListElements,
}: BreadCrumbJsonLdProps) {
  const data = {
    itemListElement: setItemListElements(itemListElements),
  };

  return (
    <JsonLd
      keyOverride={keyOverride}
      type={type}
      {...data}
      scriptKey="breadcrumb"
    />
  );
}

export default BreadCrumbJsonLd;
