import {
  GET_PAGE_BY_PATH,
  GET_PAGE_BY_SLUG,
  GET_PAGE_BY_TEMPLATE,
  LIST_PAGES_BY_TEMPLATE,
  LIST_PAGES_QUERY,
} from './schema';

export async function listPages(client, options = {}) {
  const response = await client.query({
    query: LIST_PAGES_QUERY,
    ...options,
  });

  return {
    data: response.data?.listPages,
    error: response.error,
  };
}

export async function getPageBySlugFromServer(client, options = {}) {
  const response = await client.query({
    query: GET_PAGE_BY_SLUG,
    ...options,
  });

  return {
    data: response.data?.getPageByTemplate,
    error: response.error,
  };
}

export async function getPageByTemplateFromServer(client, options = {}) {
  const response = await client.query({
    query: GET_PAGE_BY_TEMPLATE,
    ...options,
  });

  return {
    data: response.data?.getPageByTemplate,
    error: response.error,
  };
}

export async function getPageByPathFromServer(client, options = {}) {
  const response = await client.query({
    query: GET_PAGE_BY_PATH,
    ...options,
  });

  return {
    data: response.data?.getPageByPath,
    error: response.error,
  };
}

export async function listPagesByTemplateFromServer(client, options = {}) {
  const response = await client.query({
    query: LIST_PAGES_BY_TEMPLATE,
    ...options,
  });

  return {
    data: response.data?.listPagesByTemplate,
    error: response.error,
  };
}
