/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useRouter } from 'next/navigation';
import * as prismicH from '@prismicio/helpers';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';
import { Container, Heading, Icon, IconSvg, Link } from '@/components';
import {
  ImageGallerySlice,
  ImageGallerySliceDefaultItem,
  ImageGallerySliceImageCorouselItem,
  ImageGallerySliceImageGridItem,
} from '@/types';
import { useFilteredSliceItemsByLocationSlug, useScreenWidth } from '@/hooks';
import { htmlSerializerWithProps } from '@/utils';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import style from './image_gallery.module.scss';

const GalleryVariation: FC<ImageGallerySlice> = ({
  variation,
  items,
  primary,
}) => {
  const router = useRouter();
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(items);
  const { width } = useScreenWidth();
  const [hovered, setHovered] = useState(null);
  const [activeTab, setActiveTab] = useState('tab 1');
  const [contentList, setContentList] = useState<
    | ImageGallerySliceDefaultItem[]
    | ImageGallerySliceImageGridItem[]
    | ImageGallerySliceImageCorouselItem[]
  >([]);

  const imageMaxWidth =
    variation === 'imageGridWithCustomWidth' ? primary?.image_max_width : 0;

  useEffect(() => {
    if (variation !== 'imageGridWithTextOver') {
      setContentList(items);
    }

    if (variation === 'imageGridWithTextOver') {
      const content = getOrderedContent().find(c => c.id === activeTab);
      setContentList(content?.items);
    }
  }, [filteredItems, activeTab]);

  const handleClick = link => {
    router.push(link);
  };

  const renderImage = image => {
    if (!image?.url) {
      return <IconSvg name="image" />;
    }

    return (
      <PrismicNextImage
        fallbackAlt=""
        field={image}
        fill
        style={{ objectFit: 'cover', objectPosition: 'center center' }}
      />
    );
  };

  const getSpacingBetween = () => {
    if (width < 768) {
      return 12;
    }

    if (width < 1024) {
      return 70;
    }

    if (width < 1280) {
      return 100;
    }

    return 130;
  };

  const renderContent = (item, index) => {
    if (variation === 'imageGridWithCustomWidth') {
      const { gallery_image } = item;

      const getAspectRatio = () => {
        const aspectRatio =
          gallery_image?.dimensions?.height / gallery_image?.dimensions?.width;

        return `${aspectRatio * 100}%`;
      };

      return (
        <figure
          className={classNames(
            style.gallery__images_item,
            style.gallery__images_item_custom_width,
          )}
          style={{ paddingTop: getAspectRatio() }}
        >
          {gallery_image?.url ? (
            <PrismicNextImage
              fallbackAlt=""
              field={gallery_image}
              fill
              style={{
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
            />
          ) : (
            <IconSvg name="image" />
          )}
        </figure>
      );
    }
    if (
      variation === 'default' ||
      variation === 'imageGridWithTextOver' ||
      variation === 'imageWithSquareDimensions' ||
      variation === 'imageGridWithoutTabs'
    ) {
      const {
        title_caption_color,
        heading_caption_color,
        description_caption_color,
        cta_type_caption,
        cta_background_color_caption,
        cta_background_color_hover_caption,
        cta_text_color_caption,
        cta_text_color_hover_caption,
        cta_border_caption,
        cta_border_hover_caption,
        background_color_caption,
        border_caption,
        disable_caption,
      } = primary;

      const {
        gallery_image,
        title_caption,
        heading_caption,
        description_caption,
        cta_text_caption,
        cta_link_caption,
      } = item;

      const hasCaption =
        prismicH.asText(title_caption) ||
        prismicH.asText(heading_caption) ||
        prismicH.asText(description_caption) ||
        cta_text_caption;

      const applyShadowEffect =
        (variation === 'imageGridWithTextOver' ||
          variation === 'imageGridWithoutTabs') &&
        primary?.apply_shadow_effect;

      return (
        <>
          {hasCaption && (
            <div
              className={classNames(style.caption__container, {
                [style.caption__container_disable]: disable_caption,
                [style.caption__container_tabs]:
                  variation === 'imageGridWithTextOver' ||
                  variation === 'imageGridWithoutTabs',
                [style.caption__container_tabs_gradient_effect]:
                  applyShadowEffect,
              })}
              style={{
                background: (!disable_caption
                  ? background_color_caption
                  : '') as string,
                border: (!disable_caption ? border_caption : '') as string,
              }}
            >
              {renderTextSlice(
                heading_caption,
                heading_caption_color ?? '',
                '100%',
                true,
              )}
              {renderTextSlice(
                title_caption,
                title_caption_color ?? '',
                '100%',
                true,
              )}
              {renderTextSlice(
                description_caption,
                description_caption_color ?? '',
                '100%',
                true,
              )}
              {cta_text_caption && (
                <Link
                  className={classNames(style.caption__cta_button, {
                    [style.caption__cta_button_primary]:
                      cta_type_caption === 'button',
                    [style.caption__cta_button_link]:
                      cta_type_caption === 'link',
                  })}
                  href={cta_link_caption ?? '/'}
                  onMouseEnter={() => {
                    setHovered(index);
                  }}
                  onMouseLeave={() => {
                    setHovered(null);
                  }}
                  style={{
                    backgroundColor: (hovered === index
                      ? cta_background_color_hover_caption
                      : cta_background_color_caption) as string,
                    color: (hovered === index
                      ? cta_text_color_hover_caption
                      : cta_text_color_caption) as string,
                    border: (hovered === index
                      ? cta_border_hover_caption
                      : cta_border_caption) as string,
                  }}
                >
                  {cta_text_caption}
                </Link>
              )}
            </div>
          )}
          <figure
            className={classNames(style.gallery__images_item, {
              [style.gallery__images_item_square]:
                variation === 'imageWithSquareDimensions',
            })}
          >
            {renderImage(gallery_image)}
          </figure>
        </>
      );
    }

    return;
  };

  const getOrderedContent = () => {
    if (variation !== 'imageGridWithTextOver') {
      return [];
    }
    const orderList: any = [];

    Array.from({ length: 4 }).forEach((_, index) => {
      const id = `tab ${index + 1}`;
      const title = primary[`title_tab_${index + 1}`] ?? `TAB ${index + 1}`;
      const content = filteredItems?.filter(
        i => i.grouped_in === id || (!i.grouped_in && index === 0),
      );

      if (
        content.length > 0 ||
        primary[`title_tab_${index + 1}`] ||
        index === 0
      ) {
        orderList.push({
          id,
          title,
          items: content,
        });
      }
    });

    return orderList;
  };

  const renderCarousel = (
    auto: boolean,
    centerSlice: boolean,
    classCarousel: string,
    classSwiper: string,
    spaceBetween: any,
  ) => {
    if (contentList.length === 0) {
      return null;
    }

    return (
      <Swiper
        autoplay={
          auto && {
            delay: 5000,
            disableOnInteraction: false,
          }
        }
        centeredSlides={
          variation === 'imageGridWithTextOver' ||
          variation === 'imageGridWithoutTabs'
            ? false
            : centerSlice
        }
        className={classCarousel}
        effect={'fade'}
        freeMode
        loop={
          variation !== 'imageGridWithTextOver' &&
          variation !== 'imageGridWithoutTabs'
        }
        modules={[Autoplay, Navigation, FreeMode]}
        navigation
        slidesPerView={'auto'}
        spaceBetween={spaceBetween ? spaceBetween : getSpacingBetween()}
      >
        {contentList?.map((item, index) => {
          const imageLink =
            variation === 'imageGridWithoutTabs' &&
            !item?.cta_text_caption &&
            item?.cta_link_caption
              ? item?.cta_link_caption
              : null;

          return (
            <SwiperSlide className={classSwiper} key={index}>
              {variation === 'imageCorousel' && (
                <figure className={style.gallery_carousel__slide}>
                  {renderImage(item?.gallery_image)}
                </figure>
              )}
              {(variation === 'imageGridWithTextOver' ||
                variation === 'imageGridWithoutTabs') &&
                (imageLink ? (
                  <Link href={imageLink}>{renderContent(item, index)}</Link>
                ) : (
                  renderContent(item, index)
                ))}
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  if (variation === 'imageGrid') {
    return (
      <div
        className={classNames(
          style.gallery_grid,
          style[`grid_${items.length}`],
        )}
      >
        {items.slice(0, 6).map((item, index) => (
          <figure
            className={classNames(
              style.gallery_grid__item,
              style[`grid_item__${index + 1}`],
            )}
            key={index}
          >
            {renderImage(item?.gallery_image)}
          </figure>
        ))}
      </div>
    );
  }

  if (
    variation === 'default' ||
    variation === 'imageGridWithTextOver' ||
    variation === 'imageWithSquareDimensions' ||
    variation === 'imageGridWithoutTabs' ||
    variation === 'imageGridWithCustomWidth'
  ) {
    return (
      <>
        {variation === 'imageGridWithTextOver' && (
          <Container className={style.gallery__images_tabs_container}>
            <ul
              className={classNames(style.gallery__images_tabs, {
                [style.gallery__images_tabs_centered]: primary?.centered_tabs,
              })}
              role="tablist"
            >
              {getOrderedContent()?.map((tab, index) => {
                return (
                  <li
                    aria-controls={`${tab.id}`}
                    className={classNames(style.gallery__images_tabs_tab, {
                      [style.gallery__images_tabs_tab_active]:
                        activeTab === tab.id,
                    })}
                    key={`tab_${index}`}
                    onClick={() => setActiveTab(tab.id)}
                    role="tab"
                  >
                    {tab.title}{' '}
                    <span
                      style={{ color: primary?.tab_counter_color ?? '' }}
                    >{`(${tab.items?.length})`}</span>
                  </li>
                );
              })}
            </ul>
          </Container>
        )}
        {contentList?.length > 0 ? (
          <>
            {(variation === 'imageGridWithTextOver' ||
              variation === 'imageGridWithoutTabs') &&
              renderCarousel(
                false,
                true,
                style.gallery__images_carousel,
                style.gallery__images_item_body,
                24,
              )}
            <div
              className={classNames(style.gallery__images, {
                [style.gallery__images_hidden]:
                  variation === 'imageGridWithTextOver' ||
                  variation === 'imageGridWithoutTabs',
                [style.gallery__images_custom_width]:
                  variation === 'imageGridWithCustomWidth',
              })}
            >
              {contentList?.map((item, index) => {
                const imageLink =
                  variation === 'imageGridWithoutTabs' &&
                  !item?.cta_text_caption &&
                  item?.cta_link_caption
                    ? item?.cta_link_caption
                    : null;

                return (
                  <div
                    className={classNames(style.gallery__images_item_body, {
                      [style.gallery__images_item_body_square]:
                        variation === 'imageWithSquareDimensions',
                      [style.gallery__images_item_body_custom_width]:
                        variation === 'imageGridWithCustomWidth',
                    })}
                    key={index}
                    style={{
                      maxWidth:
                        variation === 'imageGridWithCustomWidth'
                          ? (imageMaxWidth as number)
                          : '',
                    }}
                  >
                    {imageLink ? (
                      <Link href={imageLink}>{renderContent(item, index)}</Link>
                    ) : (
                      renderContent(item, index)
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className={style.gallery__images_empty_results}>
            <Icon name="search" />
            <Heading level={5}>There are no images to show</Heading>
            <p>{'No images have been entered in the selected category'}</p>
          </div>
        )}
      </>
    );
  }

  if (variation === 'imageHorizontalDimensions') {
    const { item_heading_color, item_title_color, item_description_color } =
      primary;

    return (
      <div
        className={classNames(style.gallery__images, {
          [style.horizontal_image]: variation === 'imageHorizontalDimensions',
        })}
      >
        {contentList?.map((item, index) => {
          const hasCaption =
            prismicH.asText(item?.item_heading) ||
            prismicH.asText(item?.item_title) ||
            prismicH.asText(item?.item_description);
          return (
            <div
              className={classNames(style.gallery__images_item_body, {
                [style.gallery__images_item_body_horizontal_image]:
                  variation === 'imageHorizontalDimensions',
              })}
              key={index}
              onClick={() => {
                handleClick(item?.link ?? '/');
              }}
              role="button"
            >
              <>
                {hasCaption && (
                  <div
                    className={classNames(
                      style.caption__container,
                      style.caption__container_disable,
                    )}
                  >
                    {renderTextSlice(
                      item?.item_heading,
                      item_heading_color ?? '',
                      '100%',
                      true,
                    )}
                    {renderTextSlice(
                      item?.item_title,
                      item_title_color ?? '',
                      '100%',
                      true,
                    )}
                    {renderTextSlice(
                      item?.item_description,
                      item_description_color ?? '',
                      '100%',
                      true,
                    )}
                  </div>
                )}
                <figure
                  className={classNames(style.gallery__images_item, {
                    [style.gallery__images_item_horizontal_image]:
                      variation === 'imageHorizontalDimensions',
                  })}
                >
                  {renderImage(item?.gallery_image)}
                </figure>
              </>
            </div>
          );
        })}
      </div>
    );
  }

  return renderCarousel(
    true,
    true,
    style.gallery_carousel,
    style.gallery__item_slice,
    null,
  );
};

const CTAButton: FC<ImageGallerySlice> = ({ primary }) => {
  const [hovered, setHovered] = useState(false);
  const {
    cta_text,
    cta_type,
    cta_text_color,
    cta_link,
    cta_background_color,
    cta_background_color_hover,
    cta_text_color_hover,
    cta_border,
    cta_border_hover,
  } = primary;

  if (!cta_text) {
    return null;
  }

  return (
    <div className={style.gallery_cta}>
      <Link
        className={classNames(style.cta_button, {
          [style.cta_button_primary]: cta_type === 'button',
        })}
        href={cta_link ?? ''}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        style={{
          backgroundColor: (hovered
            ? cta_background_color_hover
            : cta_background_color) as string,
          color: (hovered ? cta_text_color_hover : cta_text_color) as string,
          border: (hovered ? cta_border_hover : cta_border) as string,
        }}
      >
        {cta_text}
      </Link>
    </div>
  );
};

const renderTextSlice = (
  text: any,
  color: string,
  maxWidth?: string,
  centeredText?: boolean,
) => {
  if (!prismicH.asText(text)) {
    return null;
  }

  return (
    <PrismicRichText
      components={htmlSerializerWithProps({
        color: color,
        maxWidth: maxWidth,
        textAlign: centeredText ? 'center' : 'left',
      })}
      field={text}
    />
  );
};

export { CTAButton, GalleryVariation, renderTextSlice };
