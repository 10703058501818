import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { find, startCase } from 'lodash';
import classNames from 'classnames';
import {
  Badge,
  Button,
  Heading,
  Icon,
  IconSvg,
  Image,
  Link,
  Select,
  sortVariantByPrice,
  useData,
} from '@/components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Discount, GProduct, Variant } from '@/types';
import { NOT_DEFINED } from '@/constants';
import {
  currencyFormat,
  formatProductDiscountText,
  formatWeightLabel,
  getBogoFromProduct,
  getBogoSpecialURL,
} from '@/utils';
import { useAnalytics, useUiStore } from '@/data';
import { useFeaturedFlag, useWishList } from '@/hooks';

import { ISelectOption } from '../Select/Select';
import style from './product.module.scss';
import { IProductProps } from './Product';

const getInfoPackValue = (
  product: GProduct,
  isGetNull?: boolean,
  getTHC?: boolean,
) => {
  if (isGetNull) {
    if (
      product?.thc?.percentage ||
      product?.thc?.amount ||
      product?.cbd?.amount ||
      product?.cbd?.percentage
    ) {
      return null;
    }

    return 'true';
  }

  if (getTHC) {
    if (!product?.thc?.percentage && !product?.thc?.amount) {
      return null;
    }

    if (product?.thc?.percentage) {
      return `${product?.thc?.percentage}%`;
    }

    return product?.thc?.amount ? `${product?.thc?.amount}MG` : null;
  }

  if (!product?.cbd?.percentage && !product?.cbd?.amount) {
    return null;
  }

  if (product?.cbd?.percentage) {
    return `${product?.cbd?.percentage}%`;
  }

  return product?.cbd?.amount ? `${product?.cbd?.amount}MG` : null;
};

// Render Image of Product
const ProductImage: FC<IProductProps> = ({
  className,
  analyticsListName,
  product,
}) => {
  const { isProductCardLargImage } = useFeaturedFlag();
  const { measureProductClick, measureSelectItemGA4 } = useAnalytics();
  const [, setState] = useUiStore();
  const [load, setLoad] = useState(true);
  const stockPhoto = product?.asset?.stock ?? false;

  const trackingClick = () => {
    setState({
      is_search_open: false,
    });
    measureProductClick(product, 'products-cards');
    measureSelectItemGA4(product, analyticsListName ?? 'product-cards');
  };

  return (
    <Link
      className={classNames(style.product__link_image, className)}
      href={product?.url ?? ''}
      onClick={trackingClick}
    >
      <figure
        className={classNames({
          [style.product__image]: !isProductCardLargImage,
          [style.product__image_large]: isProductCardLargImage,
          [style.product__image__load]: load,
        })}
      >
        <Image
          alt={product?.name ?? ''}
          blurDataURL={
            // eslint-disable-next-line max-len
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 1 1'%3E%3Crect fill='#ededed' width='1' height='1'/%3E%3C/svg%3E\")"
          }
          fallback="/images/product-placeholder.svg"
          fill
          onLoad={() => {
            setLoad(false);
          }}
          placeholder="blur"
          src={product?.asset?.image as string}
          style={{ mixBlendMode: 'multiply', objectFit: 'contain' }}
          title={product?.name as string}
        />
        {stockPhoto && (
          <span className={style.product__in_stock}>Stock photo</span>
        )}
      </figure>
    </Link>
  );
};

// Render Name of Product
const ProductName: FC<IProductProps> = ({
  product,
  className,
  analyticsListName,
}) => {
  const { measureProductClick, measureSelectItemGA4 } = useAnalytics();
  const [, setState] = useUiStore();

  const trackingClick = () => {
    setState({
      is_search_open: false,
    });
    measureProductClick(product, 'products-cards');
    measureSelectItemGA4(product, analyticsListName ?? 'product-cards');
  };

  return (
    <Link href={product.url || ''} onClick={trackingClick}>
      <Heading className={classNames(style.product__name, className)} level={5}>
        {product.name}
      </Heading>
    </Link>
  );
};

// Render Badge Type
const ProductBadge: FC<BadgeProps> = ({
  link,
  showIcon,
  label,
  iconName,
  type,
  className,
}) => {
  return (
    <Badge
      className={classNames(style.badge, className, {
        [style.badge__discount]: type === 'discount',
        [style.badge__seller]: type === 'seller',
        [style.badge__specials]: type === 'specials',
        [style.badge__warning]: type === 'warning',
        [style.badge__bogo]: type === 'bogo',
      })}
    >
      {showIcon && iconName && <IconSvg name={iconName} />}
      {link && <Link href={link}>{label}</Link>}
      {!link && <span>{label}</span>}
    </Badge>
  );
};

// Render Active Badge of Product
const ProductBadgeContainer: FC<ProductBadgeContainerProps> = ({
  className,
  product,
  weightVariant,
  type = 'default',
}) => {
  const { productCard } = useData();
  const bogo: Discount | undefined = getBogoFromProduct(product);

  const productDiscountText = useMemo(
    () =>
      formatProductDiscountText({
        format: productCard?.discount_display,
        value: weightVariant,
      }),
    [weightVariant],
  );

  const badgeElements = (
    <>
      {product?.isLowStock && (
        <ProductBadge key="badge-low-stock" label="Low Stock" type="warning" />
      )}
      {product?.isOutStock && (
        <ProductBadge
          key="badge-low-in-stock"
          label="Out of Stock"
          type="warning"
        />
      )}
      {bogo && (
        <ProductBadge
          iconName="gift"
          key="badge-bogo"
          label={productCard?.bogo_badge_label ?? 'BOGO'}
          link={getBogoSpecialURL(bogo)}
          showIcon
          type="bogo"
        />
      )}
      {productDiscountText && (
        <ProductBadge
          key="badge-special"
          label={productDiscountText}
          type="specials"
        />
      )}
    </>
  );

  return type === 'list' ? (
    badgeElements
  ) : (
    <ul className={classNames(style.product__badges, className)}>
      {badgeElements}
    </ul>
  );
};

// Render Related Info of Product
const ProductInfo: FC<ProductInfoProps> = ({
  className,
  label,
  info,
  showLabel,
  icon,
  type,
}) => {
  if (showLabel) {
    return (
      <div className={classNames(style.info__container, className)}>
        <label>{label}</label>
        <p>{info || NOT_DEFINED}</p>
      </div>
    );
  }

  return (
    <span
      className={classNames(style.info, className, {
        [style.info__filled]: type === 'filled',
        [style.info__outlined]: type === 'outlined',
      })}
    >
      {icon && <IconSvg name={icon} />} {info || NOT_DEFINED}
    </span>
  );
};

// Render Category of Product
const ProductCategory: FC<ProductInfoProps> = ({
  className,
  label,
  info,
  showLabel,
}) => {
  const getFlowerType = flowerType => {
    const types = ['SI', 'IS'];
    const flowerTypeClean = flowerType.replace(/\s+/g, '');

    if (types.includes(flowerTypeClean)) {
      return flowerTypeClean;
    }

    return flowerType;
  };

  if (!info) {
    return null;
  }

  if (showLabel) {
    return (
      <div className={classNames(style.category__container, className)}>
        <label>{label}</label>
        <p>{info || NOT_DEFINED}</p>
      </div>
    );
  }

  return (
    <span
      className={classNames(
        style.flower__type,
        style.type__default,
        style[
          // eslint-disable-next-line max-len
          `type__${info?.toLocaleLowerCase().replace(/\s+/g, '')}`
        ],
        className,
      )}
      title={startCase(info)}
    >
      <strong>{getFlowerType(info)}</strong>
    </span>
  );
};

// Render THC and CBD Info of Product
const ProductInfoPack: FC<ProductInfoPackProps> = ({
  className,
  label,
  product,
  showIcon,
}) => {
  const renderCannabinoidInfo = (type, value, unit) => {
    if (!value) {
      return null;
    }

    return (
      <div
        className={classNames(style.info__pack, {
          [style.info__pack_badge]: !label,
        })}
      >
        {showIcon && <IconSvg name={type === 'THC' ? 'thc' : 'cbd'} />}
        <span> {type} </span>
        {value}
        {unit}
      </div>
    );
  };

  if (getInfoPackValue(product, true)) {
    return null;
  }

  return (
    <div className={classNames(style.info__container, className)}>
      {label && <label>{label}</label>}
      <div className={style.info__body}>
        {renderCannabinoidInfo('THC', product?.thc?.percentage, '%')}
        {renderCannabinoidInfo('THC', product?.thc?.amount, 'MG')}
        {renderCannabinoidInfo('CBD', product?.cbd?.percentage, '%')}
        {renderCannabinoidInfo('CBD', product?.cbd?.amount, 'MG')}
      </div>
    </div>
  );
};

// Render Price of Product
const ProductPrice: FC<ProductPriceProps> = ({
  className,
  weightVariant,
  elementVariants,
  isOverflow,
  isSpecial,
  type,
}) => {
  const { isTaxAppliedMessage, showStriketroughPrice } = useFeaturedFlag();

  const labelSalePrice = `$${currencyFormat(weightVariant?.salePrice ?? 0)}`;
  const labelPrice = `$${currencyFormat(weightVariant?.price ?? 0)}`;
  const discount = `${weightVariant?.discount_percent}%`;

  const priceMoreVariation = (
    <div
      className={classNames(style.price__ins_container, {
        [style.price__ins_container_overflow]: isOverflow,
      })}
    >
      <ins>{labelSalePrice}</ins>
      {elementVariants}
    </div>
  );

  const strikeTroughPrice =
    isSpecial && showStriketroughPrice ? <del>{labelPrice}</del> : null;

  const taxMessage = isTaxAppliedMessage ? (
    <span className={style.price__tax_message}>TAX INCL.</span>
  ) : null;

  const discountMessage = weightVariant?.discount_percent ? (
    <span className={style.price__discount}>{`-${discount}`}</span>
  ) : null;

  if (type === 'row') {
    return (
      <div
        className={classNames(style.price, style.price__row_type, className)}
      >
        {priceMoreVariation}
        {strikeTroughPrice}
        {isTaxAppliedMessage && taxMessage}
        {discountMessage}
      </div>
    );
  }

  if (type === 'expanded') {
    return (
      <div
        className={classNames(
          style.price,
          style.price__expanded_type,
          { [style.price__overflow]: isOverflow },
          className,
        )}
      >
        <div
          className={classNames(style.price__column, {
            [style.price__column_overflow]: isOverflow,
          })}
        >
          {priceMoreVariation}
          {isTaxAppliedMessage && taxMessage}
        </div>
        {strikeTroughPrice || discountMessage ? (
          <div className={style.price__column_right}>
            {strikeTroughPrice}
            {discountMessage}
          </div>
        ) : null}
      </div>
    );
  }

  if (type === 'expanded-tax') {
    return (
      <div
        className={classNames(
          style.price,
          style.price__expanded_tax_type,
          {
            [style.price__overflow]: isOverflow,
          },
          className,
        )}
      >
        <div
          className={classNames(style.price__column, {
            [style.price__column_overflow]: isOverflow,
          })}
        >
          {strikeTroughPrice}
          {priceMoreVariation}
        </div>
        {isTaxAppliedMessage && (
          <div className={style.price__column_right}>{taxMessage}</div>
        )}
      </div>
    );
  }

  if (type === 'column-container' || type === 'inverted') {
    return (
      <div
        className={classNames(style.price, style.price__column, className, {
          [style.price__inverted_type]: type === 'inverted',
        })}
      >
        {priceMoreVariation}
        <div className={style.price__secundary_info}>
          {strikeTroughPrice}
          {strikeTroughPrice && taxMessage && <div className={style.dot} />}
          {isTaxAppliedMessage && taxMessage}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(style.price, className)}>
      {strikeTroughPrice}
      <ins>{labelSalePrice}</ins>
    </div>
  );
};

// Render Weight Variants of Product
const ProductVariants: FC<ProductVariantsProps> = ({
  className,
  label,
  weightVariant,
  variants,
  updateWeightVariant,
  type,
}) => {
  const [variantsSelectOptions, setVariantsSelectOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [selectedVariantOption, setSelectedVariantOption] = useState<
    ISelectOption<string>
  >({
    label: formatWeightLabel(weightVariant?.type as string),
    value: weightVariant?.id!,
  });

  useEffect(() => {
    if (!variants) {
      return;
    }

    setVariantsSelectOptions(
      sortVariantByPrice(variants as Variant[]).map(v => {
        return {
          label: formatWeightLabel(v?.type as string),
          value: v.id as string,
        };
      }),
    );
  }, [variants]);

  const renderVariant = () => {
    if (
      typeof weightVariant?.type === 'undefined' ||
      weightVariant?.type === '0_unit'
    ) {
      return 'Not provided';
    }

    return formatWeightLabel(weightVariant?.type as string);
  };

  const renderVariationCarousel = () => {
    return variantsSelectOptions?.map(wv => (
      <Button
        className={classNames(style.variants__item, {
          [style.variants__item_active]:
            selectedVariantOption.value === wv.value,
        })}
        key={wv.value}
        onClick={() => handleSelectedVariation(wv)}
      >
        {wv.label}
      </Button>
    ));
  };

  const handleSelectedVariation = (option: ISelectOption<string>) => {
    setSelectedVariantOption(option);
    updateWeightVariant(option);
  };

  const isMultiVarians = variantsSelectOptions?.length > 1;

  return (
    <div
      className={classNames(style.variant_select_container, className, {
        [style.variant_select_container_overflow]: !isMultiVarians,
      })}
    >
      {type === 'default' && <span>/&nbsp;</span>}
      {variantsSelectOptions?.length > 1 ? (
        type === 'default' ? (
          <Select
            className={style.variant_select}
            defaultValue={selectedVariantOption}
            menuClassName={style.variant_select__menu}
            name="weight_variant"
            onChange={handleSelectedVariation}
            options={variantsSelectOptions}
            placement="top-start"
            value={selectedVariantOption}
          />
        ) : (
          <>
            <label>{label}</label>
            {renderVariationCarousel()}
          </>
        )
      ) : (
        <span className={style.variation__message}>{renderVariant()}</span>
      )}
    </div>
  );
};

// Render Carousel With Info Product
const ProductInfoCarousel: FC<{
  className?: string;
  children: ReactNode[];
}> = ({ className, children }) => {
  return (
    <Swiper
      className={classNames(style.product__info_carousel, className)}
      freeMode={true}
      grabCursor={true}
      mousewheel={true}
      simulateTouch={true}
      slidesPerView={'auto'}
      spaceBetween={4}
    >
      {children.map((child, index) => (
        <SwiperSlide key={index} style={{ width: 'auto' }}>
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

// Render Reviews and Rating of Product
const ProductReview: FC<ProductReviewProps> = ({
  className,
  label,
  rating,
  reviews,
}) => {
  return (
    <div className={classNames(style.review__container, className)}>
      {label && <label>{label}</label>}
      <div className={style.review__info}>
        <Icon name={rating > 0 ? 'icon_star' : 'start'} />
        <span>{rating}</span>
        <p>{` (${reviews} reviews)`}</p>
      </div>
    </div>
  );
};

// Render Favorite Action of Product
const ProductActionFavorite: FC<IProductProps> = ({ className, product }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const { wishlistActive } = useFeaturedFlag();
  const { measureAddToWishlistGA4 } = useAnalytics();
  const { favProducts, addFav, removeFav } = useWishList();

  useEffect(() => {
    const p = find(favProducts, p => p.id == product.id);
    if (p !== undefined) {
      setIsFavorite(true);
      return;
    }
    setIsFavorite(false);
  }, [favProducts]);

  const handleFavorite = (product: GProduct) => {
    if (isFavorite) {
      removeFav(product.id);
      return;
    }
    measureAddToWishlistGA4(product);
    addFav(product);
  };

  if (!wishlistActive) {
    return null;
  }

  return (
    <Button
      className={classNames(style.favorite_action, className)}
      color="link"
      onClick={() => {
        handleFavorite(product);
      }}
      size="sm"
    >
      <Icon name={isFavorite ? 'favorite-solid' : 'favorite'} />
    </Button>
  );
};

// Render The Counter Of Added Products
const ProductIconShopping: FC<{ className?: string; quantity: number }> = ({
  className,
  quantity,
}) => {
  return (
    <div className={classNames(style.icon__shopping, className)}>
      <IconSvg name="shopping" />
      {quantity ? <span className={style.qty__badge}>{quantity}</span> : null}
    </div>
  );
};

interface BadgeProps {
  link?: string;
  showIcon?: boolean;
  iconName?: string;
  label?: string;
  type: 'discount' | 'seller' | 'specials' | 'bogo' | 'warning' | 'info';
  className?: string;
}

interface ProductInfoProps {
  className?: string;
  label?: string;
  info?: string | null;
  icon?: string;
  showLabel?: boolean;
  type: 'default' | 'outlined' | 'filled';
}

interface ProductInfoPackProps {
  className?: string;
  product: GProduct;
  label?: string;
  showIcon?: boolean;
}
interface ProductPriceProps {
  className?: string;
  weightVariant: Variant;
  elementVariants?: ReactNode;
  isSpecial: boolean;
  isOverflow: boolean;
  type?:
    | 'default'
    | 'expanded'
    | 'row'
    | 'inverted'
    | 'column-container'
    | 'expanded-tax';
}
interface ProductVariantsProps {
  className?: string;
  label?: string;
  weightVariant: Variant;
  variants: Variant[];
  updateWeightVariant: (_option: ISelectOption<string>) => void;
  type: 'default' | 'carousel';
}
interface ProductReviewProps {
  className?: string;
  label?: string;
  rating: number;
  reviews: number;
}
interface ProductBadgeContainerProps {
  className?: string;
  product: GProduct;
  isSpecial: boolean;
  weightVariant: Variant;
  type?: 'list' | 'default';
}

export {
  ProductBadgeContainer,
  ProductImage,
  ProductName,
  ProductCategory,
  ProductPrice,
  ProductReview,
  ProductActionFavorite,
  ProductVariants,
  ProductInfoPack,
  ProductInfo,
  ProductIconShopping,
  ProductInfoCarousel,
  getInfoPackValue,
};
