const FILE_SIZE_ERROR_THRESHOLD = 16;
const FILE_SIZE_ERROR_THRESHOLD_VALUE = FILE_SIZE_ERROR_THRESHOLD * 1024 * 1024;
const RESIZE_THRESHOLD = 4.5 * 1024 * 1024;

const UPLOAD_ERROR_MESSAGE =
  'Please try again. If the error persists contact support.';

const UPLOAD_ERROR_MAX_SIZE_EXCEEDED = `File size must be ${FILE_SIZE_ERROR_THRESHOLD}MB or less.`;

const UPLOAD_ERROR_INVALID_FORMAT =
  'Unsupported format detected. Allowed formats: JPEG, JPG, PNG.';

export {
  UPLOAD_ERROR_INVALID_FORMAT,
  UPLOAD_ERROR_MAX_SIZE_EXCEEDED,
  UPLOAD_ERROR_MESSAGE,
  FILE_SIZE_ERROR_THRESHOLD_VALUE,
  RESIZE_THRESHOLD,
};
