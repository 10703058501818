import { FC } from 'react';
import { SeparatorSlice } from '@/types';
import { useScreenWidth } from '@/hooks';
import styles from './components.module.scss';
import classNames from 'classnames';

const ImageSeparator: FC<SeparatorSlice> = slice => {
  const { width } = useScreenWidth();
  const { primary } = slice;

  const svgBackground = primary?.pattern_image?.url ?? '';
  const backgroundColor = primary?.background_color ?? undefined;
  const backgroundSize = primary?.background_size ?? 'cover';
  const backgroundPosition = `left ${primary.background_position || 'center'}`;
  const offset = primary?.vertical_offset ?? 0;
  const rotation = primary?.image_rotation ?? 0;
  const overlap = primary?.overlap_adjacent;
  const sendToTheBack = primary?.send_to_the_back;

  const aspectRatio = primary.pattern_image?.dimensions
    ? primary.pattern_image.dimensions.height /
      primary.pattern_image.dimensions.width
    : 1;

  const height = width * aspectRatio;

  const marginTop =
    overlap === 'previous' ? `${-height + 1 + offset}px` : `${offset}px`;

  const marginBottom = overlap === 'next' ? `-${height - 1}px` : undefined;

  if (!primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(styles.separator, {
        ['spacing_bottom']: overlap === 'none' && slice?.primary?.margin_bottom,
      })}
      style={{
        minWidth: '100%',
        height: `${height}px`,
        backgroundColor,
        backgroundImage: `url(${svgBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize,
        backgroundPosition,
        position: 'relative',
        left: '0px',
        right: '0px',
        marginTop: marginTop,
        marginBottom: marginBottom,
        transform: `rotate(${rotation}deg)`,
        transformOrigin: 'center',
        zIndex: sendToTheBack ? 0 : 3,
      }}
    />
  );
};

export default ImageSeparator;
