import React, { FC, useEffect, useState } from 'react';

import { CitiesSlice } from '@/types';
import { PrismicNextImage } from '@prismicio/next';
import * as prismicH from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import { sortBy } from 'lodash';
import classNames from 'classnames';
import {
  Button,
  Container,
  Heading,
  Icon,
  IconSvg,
  Input,
  Link,
  Slider,
} from '@/components';
import { getSlicePadding, htmlSerializer, stringToSlug } from '@/utils';
import { TEMPLATE_BASE_PATH } from '@/constants';
import { useScreenWidth } from '@/hooks';
import CityPlaceholder from './CityPlaceholder';
import style from './cities.module.scss';

const CitiesCarousel: FC<CitiesSlice> = ({ items, primary }) => {
  const { width } = useScreenWidth();
  const [isLoading, setIsLoading] = useState(true);
  const {
    spacing_between,
    spacing_between_mobile,
    image_size,
    image_size_mobile,
    link_text_see_all,
    link_text_color_see_all,
    cities_header_color,
    cities_header,
    bottom_spacing,
    background_color,
    border_radius_of_image,
    text_color,
  } = primary;
  const borderRadius = border_radius_of_image
    ? `${border_radius_of_image}px`
    : '0px';
  const paddingSection = primary?.padding_section;
  const placeholderDarkMode = primary?.placeholder_dark_mode;
  const showEffect = primary?.show_image_effect;
  const backgroundImage = primary?.background_image?.url;
  const backgroundStyleFixed = primary?.background_style_fixed_cover;
  const minHeight = primary?.min_heigth;

  useEffect(() => {
    if (items?.length) {
      setIsLoading(false);
    }
  }, [items]);

  const getImageSize = (width: number) => {
    if (width < 1024) {
      return image_size_mobile as number;
    }

    return image_size as number;
  };

  const getSpaceBetween = width => {
    if (width < 1024) {
      return spacing_between_mobile;
    }

    return spacing_between;
  };

  return (
    <section
      className={classNames(
        style.cities__section,
        getSlicePadding(paddingSection),
        {
          [style.spacing_bottom]: bottom_spacing,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: background_color!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Slider
        activatedHover={false}
        className={style.cities__carousel}
        freeMode
        isNotPaddingMobile
        seeAllElement={
          <Link
            className={style['collections__see-all']}
            href="/shop"
            style={{ color: link_text_color_see_all ?? '' }}
          >
            {link_text_see_all || 'SHOP ALL'}
          </Link>
        }
        sliderHeading={
          <Heading level={2} style={{ color: cities_header_color ?? '' }}>
            {prismicH.asText(cities_header)}
          </Heading>
        }
        spaceBetween={getSpaceBetween(width) ?? 24}
      >
        {isLoading || !items?.length
          ? Array.from({ length: 15 }).map((_, index) => (
              <CityPlaceholder
                className={classNames(style.cities__carousel_placeholder, {
                  [style.cities__carousel_placeholder_darkMode]:
                    placeholderDarkMode,
                })}
                key={index}
              />
            ))
          : items?.map(city => (
              <Link
                className={style.slide__main}
                href={city?.city_link ?? ''}
                key={city.city_name}
                style={{ width: getImageSize(width) }}
              >
                {city.city_name && (
                  <h2
                    className={style.slide__main_title}
                    dangerouslySetInnerHTML={{
                      __html: city.city_name,
                    }}
                    style={{ color: text_color ?? '' }}
                  />
                )}
                <figure
                  className={classNames(style.city_image, {
                    [style.city_image_placeholder]: !city?.city_image?.url,
                  })}
                  style={{ borderRadius }}
                >
                  {city?.city_image?.url ? (
                    <PrismicNextImage
                      fallbackAlt=""
                      field={city.city_image}
                      sizes="(min-width: 1200px) 158px, 
                                        (min-width: 992px) 170px, 
                                        (min-width: 768px) 157px, 
                                        (min-width: 576px) 156px, 50vw"
                      style={{
                        borderRadius,
                        objectFit: 'cover',
                      }}
                      title={city?.city_name ?? ''}
                    />
                  ) : (
                    <IconSvg name="image" />
                  )}
                </figure>
                <span
                  className={classNames(style.slide__main_effect, {
                    [style.slide__main_effect_hide]: !showEffect,
                  })}
                  style={{ borderRadius }}
                />
              </Link>
            ))}
      </Slider>
    </section>
  );
};

const LOAD: number = 20;

const CitiesGrid: FC<CitiesSlice> = slice => {
  const [loaded, setLoaded] = useState<number>(LOAD);
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [filteredCities, setFilteredCities] = useState<any[]>(slice.items);

  const handleSearchClick = (e: React.MouseEvent) => {
    e.preventDefault();

    const newCity = slice.items.filter(item =>
      item?.city_name?.toLowerCase().includes(filterQuery.toLowerCase()),
    );

    setFilteredCities(newCity);
  };

  useEffect(() => {
    if (filterQuery === '') {
      setFilteredCities(slice.items);
    }
  }, [filterQuery, slice.items]);

  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const background = slice?.primary?.background_color ?? undefined;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  return (
    <section
      className={classNames(
        style.cities__section,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={style.grid_main}>
        <PrismicRichText
          components={htmlSerializer}
          field={slice.primary.cities_header}
        />
        <form
          className={style.filter}
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Input
            className={style.input}
            disabled={false}
            name="brands-search"
            onChange={e => setFilterQuery(e.target.value)}
            type="search"
            value={filterQuery}
          >
            Search cities
          </Input>
          <Button
            aria-label="More locations"
            className={style.button}
            color="primary"
            onClick={handleSearchClick}
            type="submit"
          >
            Search
          </Button>
          <Icon className={style.icon} name="search" />
        </form>
        <div className={style.list}>
          {sortBy(filteredCities, ['name'])
            .slice(0, loaded)
            .map(city => {
              return (
                <Link
                  className={style.item}
                  href={`${TEMPLATE_BASE_PATH.CITY}${stringToSlug(
                    city.city_name,
                  )}`}
                  key={city.city_name}
                >
                  <Heading level={3}>{city.city_name}</Heading>
                  <figure>
                    <PrismicNextImage
                      fallbackAlt=""
                      field={city.city_image}
                      fill
                      sizes="(min-width: 1200px) 158px, 
                                        (min-width: 992px) 170px, 
                                        (min-width: 768px) 157px, 
                                        (min-width: 576px) 156px, 50vw"
                      style={{ objectFit: 'cover' }}
                      title={city.city_name}
                    />
                  </figure>
                </Link>
              );
            })}
        </div>
        {loaded < slice.items.length && filteredCities.length > 0 && (
          <Button
            className={style.load_more}
            color="link"
            onClick={() => setLoaded(loaded + LOAD)}
          >
            Load more
          </Button>
        )}
      </Container>
    </section>
  );
};

export { CitiesCarousel, CitiesGrid };
