'use client';

import {
  createContext,
  FC,
  PropsWithChildren,
  use,
  useEffect,
  useState,
} from 'react';
import { DefaultError, useQuery } from '@tanstack/react-query';

import { useMenuService } from '@/data';
import { useSelectedStore } from '@/hooks';
import { TreezSpecials } from '@/types';

interface Specials {
  error: DefaultError | null;
  isError: boolean;
  isLoading: boolean;
  specials: TreezSpecials[];
}

const SpecialsContext = createContext<Specials>({
  error: null,
  isError: false,
  isLoading: false,
  specials: [],
});

const SpecialsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { store } = useSelectedStore();
  const { getSpecials } = useMenuService();
  const [specials, setSpecials] = useState<TreezSpecials[]>([]);

  const { data, isLoading, error, isError } = useQuery({
    queryKey: [store?.shortName],
    queryFn: () => getSpecials(),
  });

  useEffect(() => {
    setSpecials(data ?? []);
  }, [isLoading, store?.shortName]);

  return (
    <SpecialsContext value={{ isLoading, specials, error, isError }}>
      {children}
    </SpecialsContext>
  );
};

const useSpecialsContext = () => {
  const specialsContext = use(SpecialsContext);

  return specialsContext;
};

export { SpecialsProvider, useSpecialsContext };
