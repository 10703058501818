import { FC } from 'react';
import { CtaFeatureSlice } from '@/types';
import { Container } from '@/components';
import classNames from 'classnames';
import styles from './CtaFeature.module.scss';
import { RenderCta } from './CtaFeature.components';
import { getSlicePadding } from '@/utils';

const CtaFeature: FC<CtaFeatureSlice> = slice => {
  const background = slice?.primary?.background_color ?? '';
  const borderTop = slice?.primary?.border_section ?? '';
  const borderBottom = slice?.primary?.border_section ?? '';
  const paddingSection = slice?.primary?.padding_section;
  const fullWidth =
    slice?.variation === 'default' ? slice?.primary?.cta_full_width : false;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <section
      className={classNames(
        styles.cta_section,
        {
          [styles.cta_section__spacing_bottom]: slice?.primary?.bottom_spacing,
        },
        getSlicePadding(paddingSection),
      )}
      style={{ background, borderTop, borderBottom }}
    >
      <Container
        className={classNames({
          [styles.full_width_container]:
            fullWidth || slice.variation === 'imageWithLogoAndButton',
          [styles.centered_button_variation_container]:
            slice.variation === 'centeredButton',
          [styles.default_variation_container]: slice.variation === 'default',
          [styles.image_two_cta_variation_container]:
            slice.variation === 'imageWithTwoCta',
          [styles.title_cta_variation_container]:
            slice.variation === 'titleWithCta',
          [styles.title_two_link_variation_container]:
            slice.variation === 'titleWithTwoLinks',
          [styles.image_title_button_variation_container]:
            slice.variation === 'imageWithTitleAndButton',
          [styles.image_logo_button_variation_container]:
            slice.variation === 'imageWithLogoAndButton',
        })}
      >
        <RenderCta {...slice} />
      </Container>
    </section>
  );
};
export default CtaFeature;
