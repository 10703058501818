import { Link } from '../components';

function Warning() {
  return (
    <p className="warning">
      <strong>⚠ Government warning:</strong> This product contains cannabis, a
      schedule i controlled substance. Keep out of reach of children and
      animals. Cannabis products may only be possessed or consumed by persons 21
      years of age or older unless the person is a qualified patient. The
      intoxicating effects of cannabis products may be delayed up to two hours.
      Cannabis use while pregnant or breastfeeding may be harmful. Consumption
      of cannabis products impairs your ability to drive and operate machinery.
      please use extreme caution.
      <br />
      <Link
        href="https://www.p65warnings.ca.gov"
        style={{
          display: 'block',
          marginTop: 10,
        }}
        target="_blank"
      >
        <strong>Warning:</strong> Cancer and Reproductive Harm
        https://www.p65warnings.ca.gov/
      </Link>
    </p>
  );
}

export default Warning;
