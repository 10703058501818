import classNames from 'classnames';
import { startCase } from 'lodash';

import { Heading, Image, Link, Price } from '@/components';
import { currencyFormat } from '@/utils';

import styles from './StoreValidator.module.scss';

const OutsideProducts = ({ products }) => {
  return (
    <ul className={styles.outside_products}>
      {products?.map((product, i) => {
        const v = product?.weightVariant;
        const isSpecial = v?.salePrice < v?.price;
        const stockPhoto = product?.asset?.stock ?? false;

        return (
          <li
            className={classNames(styles.outside_product__item, {
              [styles.outside_product__item_bordered]: i < products.length - 1,
            })}
            key={product.item_id}
          >
            <Link
              className={styles.outside_product__item_image}
              href={`${product.url}?item=${product.item_id}`}
            >
              <figure>
                <Image
                  alt={product.name}
                  fallback="/images/product-placeholder.svg"
                  height={76}
                  src={product.asset?.image}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                  width={76}
                />
              </figure>

              {stockPhoto && (
                <span className={styles.product__in_stock}>Stock photo</span>
              )}
            </Link>
            <div className={styles.cart__item}>
              <div className={styles['cart__detail-item']}>
                <div className={styles['cart__item-description']}>
                  <p className={styles['cart__item-brand']}>
                    <span>{product?.brand_name ?? ('' || undefined)}</span> •
                    <span>
                      {startCase(product?.category) || startCase(undefined)}
                    </span>
                  </p>
                  <Link
                    // eslint-disable-next-line max-len
                    href={`${product.url}?item=${product.item_id}`}
                  >
                    <Heading className={styles.heading_level_4} level={4}>
                      {product.name}
                    </Heading>
                    <div className={styles.price__container}>
                      <Price
                        className={styles.price}
                        price={`$${currencyFormat(v.price)}`}
                        salePrice={`$${currencyFormat(v.salePrice)}`}
                        special={isSpecial}
                      />
                    </div>
                  </Link>
                  <div className={styles.product__feats}>
                    {product?.flower_type ? (
                      <span
                        className={classNames(
                          styles.flower__type,
                          styles.type__default,
                          styles[
                            // eslint-disable-next-line max-len
                            `type__${product?.flower_type.toLocaleLowerCase()}`
                          ],
                        )}
                        title={startCase(product?.flower_type)}
                      >
                        <strong>{product?.flower_type}</strong>
                      </span>
                    ) : null}
                    <p>
                      THC{' '}
                      <span>
                        {product?.thc?.percentage}% {' |'}
                      </span>
                    </p>
                    <p>
                      CBD <span> {product?.cbd?.percentage}%</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export { OutsideProducts };
