/* eslint-disable no-undef */
import { FC, Ref, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { Input } from '@/components';

export const buildAddressFromComponents = (
  result: google.maps.GeocoderResult,
  adr?: any,
) => {
  const address = {} as ILocalAddress;
  const components = result.address_components;

  components.forEach(component => {
    const fields = [
      // Format: [google field, gc field, short or long name]
      // Use this configuration for USA addresses.
      ['street_number', 'address1', 'long_name'],
      ['route', 'address1', 'long_name'],
      ['locality', 'city', 'long_name'],
      ['locality', 'city_code', 'short_name'],
      ['sublocality_level_1', 'city', 'long_name'],
      ['sublocality_level_1', 'city_code', 'short_name'],
      ['administrative_area_level_1', 'province', 'long_name'],
      ['administrative_area_level_1', 'province_code', 'short_name'],
      ['country', 'country', 'long_name'],
      ['country', 'country_code', 'short_name'],
      ['postal_code', 'zip', 'long_name'],
    ].filter(rel => rel[0] === component.types[0]);

    fields.forEach(field => {
      address[field[1]] = `${address[field[1]] ? `${address[field[1]]} ` : ''}${
        component[field[2]]
      }`;
    });
  });

  // Logic for city including neighborhoods.
  if (adr) {
    const fakeElement = document.createElement('div');
    fakeElement.innerHTML = adr;

    const city = fakeElement.querySelector('.locality');

    if (city) {
      address.city = city.textContent as string;
      address.city_code = city.textContent as string;
    }
  }

  const geometry = result.geometry;

  address.latitude = geometry?.location?.lat();
  address.longitude = geometry?.location?.lng();

  return address;
};

type InputElement = HTMLInputElement | HTMLTextAreaElement;

const Address: FC<IAddressProps> = ({
  className,
  country,
  feedback,
  id,
  isInvalid,
  label,
  name,
  isClearable,
  placeholder,
  value,
  onAddressChange,
  onAddressClear,
  ref,
}) => {
  const [isLoading] = useState(true);
  const ac = useRef<google.maps.places.Autocomplete | undefined>(undefined);
  const [addressValue, setAddressValue] = useState('');

  const finalId = id || 'input-address';

  useEffect(() => {
    setAddressValue(value ?? '');
  }, [value]);

  useEffect(() => {
    // Remove previous addresses lists. This is
    // a bug of Google.
    document.querySelectorAll('.pac-container').forEach(pac => pac.remove());

    ac.current = new google.maps.places.Autocomplete(
      document.getElementById(finalId) as HTMLInputElement,
      {
        componentRestrictions: {
          // Format: ["us", "cl"]
          country,
        },
        types: ['address'],
      },
    );
    ac.current.setFields(['address_components', 'geometry', 'adr_address']);
    const listener = ac.current.addListener('place_changed', () => {
      // ac.current.addListener("place_changed", () => {
      const addressObject = ac?.current?.getPlace();
      const location = addressObject?.geometry?.location;

      const builtAddress = buildAddressFromComponents(
        addressObject as unknown as google.maps.GeocoderResult,
        addressObject?.adr_address,
      );
      const address = {
        latitude: location?.lat(),
        longitude: location?.lng(),
        ...builtAddress,
      };

      onAddressChange(address);

      setAddressValue(addressAsString(builtAddress));
    });

    return () => google.maps.event.removeListener(listener);
  }, [isLoading]);

  return (
    <Input
      className={classNames('address', className)}
      feedback={feedback}
      id={finalId}
      isClearable={isClearable}
      isInvalid={isInvalid}
      name={name}
      onChange={event => setAddressValue(event.target.value)}
      onClear={onAddressClear}
      placeholder={placeholder}
      ref={ref}
      value={addressValue}
    >
      {label || 'Address'}
    </Input>
  );
};

export const addressAsString = (address: ILocalAddress): string => {
  if (!address) {
    return undefined as unknown as string;
  }

  const { address1, city, province, country } = address;

  if (!address1) {
    return undefined as unknown as string;
  }

  return `${address1}, ${city}, ${province}, ${country}`;
};

export interface ILocalAddress {
  address1?: string;
  address2?: string;
  city?: string;
  city_code?: string;
  country?: string;
  country_code?: string;
  latitude?: number;
  longitude?: number;
  province?: string;
  province_code?: string;
  zip?: string;
}

interface IAddressProps {
  className?: string;
  country: string | string[];
  feedback: string;
  id: string;
  isInvalid: boolean;
  label?: string;
  name: string;
  isClearable?: boolean;
  value?: string;
  placeholder?: string;
  // eslint-disable-next-line no-unused-vars
  onAddressChange: (arg: any) => void;
  // eslint-disable-next-line no-unused-vars
  onAddressClear?: () => void;
  ref?: Ref<InputElement | null>;
}

export default Address;
