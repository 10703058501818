import {
  createContext,
  FC,
  PropsWithChildren,
  use,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  confirmSignUp as authConfirmSignUp,
  signIn as authSignIn,
  signOut as authSignOut,
  signUp as authSignUp,
  updateUserAttributes as authUpdateUserAttributes,
  AuthUser,
  confirmResetPassword,
  ConfirmSignUpOutput,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  getCurrentUser,
  resendSignUpCode,
  ResendSignUpCodeOutput,
  resetPassword,
  ResetPasswordOutput,
  SignInOutput,
  updatePassword,
  UpdateUserAttributesOutput,
} from 'aws-amplify/auth';
import { useRouter } from 'next/navigation';
import { Hub } from '@aws-amplify/core';

import { Button, Spinner, useData } from '@/components';
import {
  useAnalytics,
  useEcomStoreSelector,
  useGetAccount,
  useMigrateUser,
} from '@/data';
import { ACCOUNT_TYPE, PAGE_ROUTES } from '@/constants';
import { useAmplify, usePreviousRoute } from '@/hooks';

const AuthContext = createContext<AuthState | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

export default function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const data = useRef<AuthUserWithAttibutes | null>(null);
  const { migrateUser } = useMigrateUser();
  const { delivery_address, addresses, setState } = useEcomStoreSelector([
    'delivery_address',
    'addresses',
  ]);
  const [isAuth, setIsAuth] = useState(false);
  const { recordSignUp } = useAnalytics();
  const router = useRouter();
  const prevRoute = usePreviousRoute();
  const [isUserVerifiedByBerbix, setIsUserVerifiedByBerbix] = useState(false);

  const { store } = useData();

  useAmplify({
    accountSettings: store.account_setting!,
    analyticsSettings: store.aws_pinpoint_analytics!,
  });

  useEffect(() => {
    setIsUserVerifiedByBerbix(
      data.current?.attributes?.['custom:BERBIX_VERIFY'] === 'true',
    );
  }, [data.current]);

  useGetAccount({
    variables: { email: data.current?.attributes?.email },
    skip: !isAuth || !data.current?.attributes?.email,
    onCompleted: res => {
      if (
        res.getAccountByEmail &&
        res.getAccountByEmail.address1 &&
        !delivery_address.address1
      ) {
        const userInfo = res.getAccountByEmail;
        const addressObj = {
          address1: userInfo.address1 ?? '',
          city: userInfo.city ?? '',
          city_code: userInfo.city_code ?? '',
          province: userInfo.province ?? '',
          province_code: userInfo.province_code ?? '',
          country: userInfo.country ?? '',
          country_code: userInfo.country_code ?? '',
          zip: userInfo.zip ?? '',
          latitude: userInfo.latitude ?? 0,
          longitude: userInfo.longitude ?? 0,
        };

        setState({
          addresses: addresses.length > 0 ? addresses : [addressObj],
          delivery_address: {
            ...delivery_address,
            first_name: userInfo.first_name ?? '',
            last_name: userInfo.last_name ?? '',
            phone: userInfo.phone ?? '',
            address2: userInfo.address2 ?? '',
            ...addressObj,
          },
        });
      }
    },
  });

  const signIn = async (email: string, password: string) => {
    // always lower case the email
    email = email?.toLowerCase();

    // We try to migrate the user from Firebase to Cognito.
    // If this process returns true the migration was successful.
    // In that case we retry the sign in again.
    return authSignIn({ username: email, password })
      .then(data => Hub.dispatch('auth', { event: 'signIn', data }))
      .catch(e => {
        if (process.env.NEXT_PUBLIC_FEATURE_FLAG_MIGRATE_USER === 'true') {
          return migrateUser({
            variables: {
              username: email,
              password,
            },
          }).then(res => {
            return res.data.migrateUser
              ? signIn(email, password)
              : Promise.reject(e);
          });
        }

        return Promise.reject(e);
      });
  };

  const signUp = async (
    email: string,
    password: string,
    marketing: boolean,
    given_name: string,
    family_name: string,
    phone_number: string,
    birthdate: string,
    account_type: ACCOUNT_TYPE,
    account_medical_id: string,
    account_medical_id_expiration_date: string,
  ) => {
    // always lower case the email
    email = email?.toLowerCase();

    return authSignUp({
      username: email,
      password,
      options: {
        userAttributes: {
          email,
          given_name,
          family_name,
          phone_number,
          birthdate,
          'custom:ACCOUNT_ID': process.env.NEXT_PUBLIC_ACCOUNT_ID,
          'custom:STORE_ID': process.env.NEXT_PUBLIC_STORE_ID,
          'custom:ACCEPT_MARKETING': (!!marketing).toString(),
          'custom:BERBIX_VERIFY': 'false',
          // 'custom:BERBIX_ERROR': storedState?.berbix_error?.toString() || '',
          'custom:ACCOUNT_TYPE': account_type || '',
          'custom:ACCOUNT_MEDICAL_ID': account_medical_id || '',
          'custom:ACCOUNT_MED_EXP_D': account_medical_id_expiration_date || '',
        },
      },
    }).then(_res => {
      // Record signup event to AWS Pinpoint.
      recordSignUp(email, !!marketing);

      // Automatically sign in the user after successful sign up.
      return signIn(email, password);
    });
  };

  const signOut = async () => {
    return authSignOut().then(() => {
      Hub.dispatch('auth', { event: 'signOut' });
      setState({ berbix_verified: false, email: '' });
    });
  };

  const changePassword = (
    email: string,
    password: string,
    newPassword: string,
  ) => {
    return updatePassword({ oldPassword: password, newPassword });
  };

  const forgotPassword = (email: string) => {
    const clientMetadata = {
      'custom:ACCOUNT_ID': process.env.NEXT_PUBLIC_ACCOUNT_ID ?? '',
      'custom:STORE_ID': process.env.NEXT_PUBLIC_STORE_ID ?? '',
      'custom:ACCEPT_MARKETING': 'true',
    };
    // always lower case the email
    email = email?.toLowerCase();

    return resetPassword({ username: email, options: { clientMetadata } });
  };

  const forgotPasswordSubmit = (
    email: string,
    code: string,
    newPassword: string,
  ) => {
    // always lower case the email
    email = email?.toLowerCase();

    const clientMetadata = {
      'custom:ACCOUNT_ID': process.env.NEXT_PUBLIC_ACCOUNT_ID ?? '',
      'custom:STORE_ID': process.env.NEXT_PUBLIC_STORE_ID ?? '',
      'custom:ACCEPT_MARKETING': 'true',
    };
    return confirmResetPassword({
      confirmationCode: code,
      newPassword,
      username: email,
      options: { clientMetadata },
    });
  };

  const confirmSignUp = (email: string, code: string) => {
    // always lower case the email
    email = email?.toLowerCase();

    return authConfirmSignUp({ username: email, confirmationCode: code });
  };

  const resendSignUp = (email: string) => {
    // always lower case the email
    email = email?.toLowerCase();

    return resendSignUpCode({ username: email });
  };

  const updateUserAttributes = async (
    attributes: FetchUserAttributesOutput,
  ) => {
    setIsUserVerifiedByBerbix(attributes['custom:BERBIX_VERIFY'] === 'true');

    return getCurrentUser().then(() => {
      return authUpdateUserAttributes({ userAttributes: attributes });
    });
  };

  const goToSignIn = () => router.push(PAGE_ROUTES.SIGNIN);

  const goToSignUp = () => router.push(PAGE_ROUTES.SIGNUP);

  const goToReset = () => router.push(PAGE_ROUTES.RESET);

  const goToForgot = () => router.push(PAGE_ROUTES.FORGOT);

  const goToCheckout = () => router.push(PAGE_ROUTES.CHECKOUT);

  const goToVerifyEmail = () => router.push(PAGE_ROUTES.VERIFY_EMAIL);

  useEffect(() => {
    getCurrentUser()
      .then(async user => {
        const attributes = await fetchUserAttributes();
        setState({
          email: attributes.email,
          berbix_verified: attributes['custom:BERBIX_VERIFY'] === 'true',
          buyer_accepts_marketing:
            attributes['custom:ACCEPT_MARKETING'] === 'true',
        });
        setIsAuth(true);
        const { tokens } = await fetchAuthSession();
        data.current = {
          ...user,
          attributes,
          accessToken: tokens?.accessToken?.toString() ?? '',
        };
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const hubCallback = async ({ payload }) => {
      const isSignIn = ['signIn'].includes(payload.event);
      const isTokenRefresh = ['tokenRefresh'].includes(payload.event);

      // Update stored information with the new login.
      if (isSignIn) {
        const attributes = await fetchUserAttributes();
        const { tokens } = await fetchAuthSession();
        data.current = {
          ...payload.data,
          attributes,
          accessToken: tokens?.accessToken?.toString() ?? '',
        };

        setState({
          email: data.current?.attributes?.email,
          berbix_verified:
            data.current?.attributes?.['custom:BERBIX_VERIFY'] === 'true',
          buyer_accepts_marketing:
            data.current?.attributes?.['custom:ACCEPT_MARKETING'] === 'true',
        });
      }

      setIsAuth(isSignIn || isTokenRefresh);
    };

    const unsubscribe = Hub.listen('auth', hubCallback);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext
      value={{
        loading,
        isAuth,
        email: data.current?.attributes?.email ?? '',
        phone: data.current?.attributes?.phone ?? '',
        birthdate: data.current?.attributes?.birthdate ?? '',
        user: data.current,
        token: data.current?.accessToken ?? '',
        prevRoute,
        isUserVerifiedByBerbix,
        signIn,
        signUp,
        signOut,
        changePassword,
        forgotPassword,
        forgotPasswordSubmit,
        confirmSignUp,
        resendSignUp,
        updateUserAttributes,
        goToSignIn,
        goToSignUp,
        goToReset,
        goToForgot,
        goToCheckout,
        goToVerifyEmail,
      }}
    >
      {children}
    </AuthContext>
  );
}

export const useAuth = () => {
  const context = use(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export const AuthContainer: FC<PropsWithChildren> = ({ children }) => {
  const { loading, isAuth, goToSignIn } = useAuth();

  if (loading) {
    return <Spinner>Loading...</Spinner>;
  }

  if (!isAuth) {
    return (
      <div className="auth__messagge_loggin">
        <div className="auth__messagge_body">
          <span>Already have an account?</span>
          <p>Sign in to earn rewards and checkout faster</p>
        </div>
        <Button color="primary" onClick={goToSignIn}>
          Sign in
        </Button>
      </div>
    );
  }

  return children;
};

interface AuthState {
  loading: boolean;
  isAuth: boolean;
  email: string;
  phone: string;
  birthdate: string;
  user: AuthUserWithAttibutes | null;
  token: string;
  prevRoute: string | null;
  isUserVerifiedByBerbix: boolean;
  signIn: (_email: string, _password: string) => Promise<SignInOutput>;
  signUp: (
    _email: string,
    _password: string,
    _marketing: boolean,
    _given_name: string,
    _family_name: string,
    _phone_number: string,
    _birthdate: string,
    _account_type: ACCOUNT_TYPE,
    _account_medical_id: string,
    _account_medical_id_expiration_date: string,
  ) => Promise<SignInOutput>;
  signOut: () => Promise<void>;
  changePassword: (
    _email: string,
    _password: string,
    _newPassword: string,
  ) => Promise<void>;
  forgotPassword: (_email: string) => Promise<ResetPasswordOutput>;
  forgotPasswordSubmit: (
    _email: string,
    _code: string,
    _newPassword: string,
  ) => Promise<void>;
  confirmSignUp: (
    _email: string,
    _code: string,
  ) => Promise<ConfirmSignUpOutput>;
  resendSignUp: (_email: string) => Promise<ResendSignUpCodeOutput>;
  updateUserAttributes: (
    _attributes: FetchUserAttributesOutput,
  ) => Promise<UpdateUserAttributesOutput>;
  goToSignIn: () => void;
  goToSignUp: () => void;
  goToReset: () => void;
  goToForgot: () => void;
  goToCheckout: () => void;
  goToVerifyEmail: () => void;
}

interface AuthUserWithAttibutes extends AuthUser {
  attributes?: FetchUserAttributesOutput;
  accessToken?: string;
}
