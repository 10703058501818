import { Heading, Icon, Link, Sharing } from '@/components';
import { TEMPLATE_BASE_PATH } from '@/constants';
import { format } from 'date-fns';
import * as prismicH from '@prismicio/helpers';
import { PrismicNextImage } from '@prismicio/next';

import styles from './article.module.scss';
import { BlogPostDocument } from '@/types';
import { FC } from 'react';
import { getFirstPTextAsDescrition, stripHtml } from '@/utils';
import classNames from 'classnames';

const Article: FC<ArticleProps> = ({ article, classCss }) => {
  const Image =
    article.data?.header_image['Normal Size'] &&
    article.data?.header_image['Normal Size'].url
      ? article.data?.header_image['Normal Size']
      : null;

  const headerText = prismicH.asText(article.data?.header_text);

  const publishAt = prismicH.asDate(article.data?.publish_date);
  const url = article.uid || '';
  const title = stripHtml(headerText);
  const description = getFirstPTextAsDescrition(article.data?.main_content);

  return (
    <article className={classNames(styles.article, classCss, 'article')}>
      <Link href={`${TEMPLATE_BASE_PATH.BLOG}${url}`}>
        <figure className={styles.article__figure}>
          {Image ? (
            <PrismicNextImage
              fallbackAlt=""
              field={Image}
              fill
              style={{ objectFit: 'cover', objectPosition: 'center center' }}
            />
          ) : (
            <Icon name="image" />
          )}
        </figure>
        <div className={styles.article__body}>
          <Heading className={styles.article__title} level={5}>
            {title}
          </Heading>
          <p className={styles.article__description}>{description}</p>
          <div className={styles.article__info}>
            {publishAt && (
              <time dateTime={publishAt.toString() || ''}>
                {format(new Date(publishAt), 'PPP')}
              </time>
            )}
            <Sharing
              className={styles.article__sharing}
              include={['facebook', 'twitter', 'whatsapp']}
              // eslint-disable-next-line max-len
              url={`${process.env.NEXT_PUBLIC_STORE_DOMAIN}${TEMPLATE_BASE_PATH.BLOG}${url}`}
            />
          </div>
        </div>
      </Link>
    </article>
  );
};

interface ArticleProps {
  article: BlogPostDocument;
  classCss?: string;
}

export { Article };
