import { FC, useEffect, useRef, useState } from 'react';

import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import { Swiper } from 'swiper/types';

import {
  Autoplay,
  EffectFade,
  FreeMode,
  Pagination,
  Thumbs,
} from 'swiper/modules';

import { format } from 'date-fns';
import {
  Article,
  BannerFeaturePostPlaceholder,
  Heading,
  IconSvg,
  Link,
  Sharing,
  Slider,
} from '@/components';
import { BlogPostDocument, BlogPostDocumentData } from '@/types';
import style from './bannerfeaturedposts.module.scss';
import { asText } from '@prismicio/helpers';
import { TEMPLATE_BASE_PATH } from 'constants/routes';
import { IBlogProps } from 'app/(Storefront)/(Shop)/(Content)/blog/page';

const BannerFeaturePosts: FC<IBannerFeaturePosts> = ({
  articleList,
  props,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const thumbsSwiperRef = useRef<Swiper | undefined>(undefined);

  useEffect(() => {
    setIsLoading(false);
  }, [props]);

  const borderRadius = props?.imagesBorderRadius
    ? `${props?.imagesBorderRadius}px`
    : undefined;

  const renderImage = (banner, sizes, classCss) => {
    return (
      <figure
        className={classNames(classCss, {
          [style.placeholder]: !banner?.url,
        })}
      >
        {banner && banner?.url && (
          <PrismicNextImage
            fallbackAlt=""
            field={banner}
            fill
            priority
            sizes={sizes}
            style={{
              borderRadius,
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        )}
        {!banner?.url && (
          <div>
            <IconSvg name="image" />
            <p>
              <b>There is no banner</b>
              <br />
              Kindly establish a banner
            </p>
          </div>
        )}
      </figure>
    );
  };

  const renderBanner = (blogPost: BlogPostDocument) => {
    const post: BlogPostDocumentData = blogPost?.data;
    const showEffect = props?.applyEfect;
    const headerText = asText(post?.header_text);
    const headerTextColor = post?.header_text_color;
    const publishDate = post?.publish_date!;
    const publishDateColor = post?.publish_date_color ?? 'white';
    const sharingStr = `${process.env.NEXT_PUBLIC_STORE_DOMAIN}/blog/${blogPost?.uid}`;

    return (
      <Link
        className={classNames(
          style.banner_feature__main_banner,
          'banner_feature__main_banner',
        )}
        href={`${TEMPLATE_BASE_PATH.BLOG}${blogPost?.uid || ''}`}
        key={blogPost?.uid}
      >
        {renderImage(post?.header_image, [], style.main_banner__desktop)}
        {renderImage(
          post?.header_image['Normal Size'],
          '(min-width: 640px) 610px, 100wv',
          style.main_banner__mobile,
        )}
        <span className={style.overlay__container}>
          <span
            className={classNames(style.overlay, {
              [style.overlay_effect_shadow]: showEffect,
            })}
          />
        </span>
        {headerText && (
          <div className={style.main__banner_body}>
            <div className={style.main__banner_text}>
              {headerText && (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: headerText,
                  }}
                  style={{
                    color: headerTextColor!,
                  }}
                />
              )}
              {publishDate && (
                <div className={style.main__banner_info}>
                  <time
                    className={style.main__banner_timer}
                    dateTime={publishDate.toString() || ''}
                    style={{
                      color: publishDateColor!,
                    }}
                  >
                    {format(new Date(publishDate), 'PPP')}
                  </time>
                  <Sharing
                    className={classNames(
                      style.main__banner_sharing,
                      'main__banner_sharing',
                    )}
                    iconStyle={{ color: publishDateColor }}
                    include={['facebook', 'twitter', 'whatsapp']}
                    media={undefined}
                    url={sharingStr}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Link>
    );
  };

  if (isLoading) {
    return <BannerFeaturePostPlaceholder darkMode={props?.darkMode} />;
  }

  if (!isLoading && articleList?.length === 0) {
    return null;
  }

  return (
    <div className={style.banner_feature__section}>
      <Heading
        level={2}
        style={{ color: props?.sectionTitleColor || undefined }}
      >
        {props?.featuredTitle || 'Featured posts'}
      </Heading>
      <div className={style.banner_feature__container}>
        <Slider
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            waitForTransition: false,
          }}
          className={classNames(style.banner_feature__carousel)}
          effect={'fade'}
          key={'feature-posts-main'}
          //loop={props?.autoPlay}
          modules={
            props?.autoPlay
              ? [Autoplay, EffectFade, FreeMode, Pagination, Thumbs]
              : [EffectFade, FreeMode, Pagination, Thumbs]
          }
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          showSliderButtons={false}
          showSliderHeader={false}
          showSlidesOutsideContainer
          slidesPerView={1}
          spaceBetween={24}
          thumbs={{ swiper: thumbsSwiperRef.current }}
        >
          {articleList?.map(post => renderBanner(post))}
        </Slider>
        <Slider
          activateThumbs={true}
          className={classNames(style.banner_feature__carrousel_thumbs)}
          direction="vertical"
          key={'feature-posts-thumbs'}
          //loop={true}
          modules={[EffectFade, Pagination, Thumbs]}
          onSwiper={swiper => (thumbsSwiperRef.current = swiper)}
          showSliderButtons={false}
          showSliderHeader={false}
          showSlidesOutsideContainer
          slidesPerView="auto"
          spaceBetween={16}
        >
          {articleList?.map(post => {
            return (
              <Article
                article={post}
                classCss={classNames(
                  'blog-article__feature-posts',
                  style.banner_feature__related_post_article,
                )}
                key={post.uid}
              />
            );
          })}
        </Slider>
        <style>{`
            .blog-article__feature-posts {
              h5 {
                color: ${props?.post_title_color};
              }

              time {
                  color: ${props?.textColor};
                }
            }
          `}</style>
      </div>
    </div>
  );
};

export default BannerFeaturePosts;

interface IBannerFeaturePosts {
  articleList: BlogPostDocument<string>[];
  props: IBlogProps;
}
