export interface JaneEvent {
  data?: JaneEventData;
}

interface JaneEventData {
  payload?: JaneEventPayload;
  messageType: string;
}

export interface JaneEventPayload {
  name: string;
  properties?: JaneEventProperties;
}

export enum JaneReservationMode {
  // eslint-disable-next-line no-unused-vars
  Delivery = 'delivery',
  // eslint-disable-next-line no-unused-vars
  PickUp = 'pickup',
}

interface JaneEventProperties {
  uuid?: string;
  cartId?: string;
  reservationMode?: JaneReservationMode;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerPhone?: string;
  deliveryAddress?: JaneAddress;
  products?: JaneProduct[];
  product?: JaneProduct;
  salesTax?: number;
  storeTax?: number;
  deliveryFee?: number;
  serviceFee?: number;
  discountTotal?: number;
  estimatedTotal?: number;
  preDiscountSubtotal?: number;
  productId?: number;
  storeNotes?: string;
  deliveryWindowStartTime?: string;
  deliveryWindowEndTime?: string;
  paymentMethod?: string;
}

export interface JaneProduct {
  count: number;
  priceId: string;
  productId: number;
  price_id?: string;
}

export interface JaneUser {
  email?: string;
  externalId?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  birthDate?: string;
}

export interface JaneCart {
  messageType: string;
  payload: JaneCartPayload;
}

interface JaneCartPayload {
  deliveryAddress?: JaneAddress;
  reservationMode?: string;
  products: JaneProduct[];
  cognitoToken: string;
  user: JaneUser;
  storeId: string;
  headlessPartnerName: string;
  headlessCheckoutPartnerId: string;
  options: JaneCartOptions;
}

interface JaneCartOptions {
  font: JaneCartFontOption;
  theme: JaneCartTheme;
  redirectUrl: string;
  disableAuthFeatures: boolean;
  disableLoadingSpinner: boolean;
  disableWeightSelection: boolean;
  disableMarketingCheckoutAgreements: boolean;
  disablePrepayment: boolean;
}

interface JaneCartFontOption {
  fontFamily: string;
  url: string;
  color: string;
}

export interface JaneCartTheme {
  themeColor: string;
  navigationColor: string;
  ctaTextColor: string;
  backgroundColor: string;
  font: JaneCartFontOption;
  calloutBackground: string;
  calloutTextColor: string;
  buttonBorderRadius: string;
}

export interface JaneAddress {
  street?: string;
  street2?: string;
  city?: string;
  state_code?: string;
  country_code?: string;
  zipcode?: string;
  lat?: number;
  lng?: number;
}
