import { FC } from 'react';

import { startCase } from 'lodash';

import { Heading, Icon, Link, Rating } from '@/components';
import { Product } from '@/types';
import { useFeaturedFlag } from '@/hooks';
interface IProductFeatsProps {
  product: Product & { category_url: string };
}

const ProductFeats: FC<IProductFeatsProps> = ({ product }) => {
  const { disableProductReviews } = useFeaturedFlag();

  const getValue = type => {
    if (type?.percentage) {
      return `${type?.percentage}%`;
    }

    if (type?.amount) {
      return `${type?.amount}MG`;
    }

    return null;
  };

  const thc = getValue(product?.thc);
  const cbd = getValue(product?.cbd);

  return (
    <div className={'details__feats'}>
      <Heading className={'details__category_title'} level={3}>
        <Link className="details__link" href={product.category_url}>
          {startCase(product.category as string)}
        </Link>
      </Heading>
      {!disableProductReviews && (
        <div className={'acceptance__level'}>
          <Rating
            className={'rating__card'}
            value={product.review_rating || 0}
          />
          <span className={'reviews'}>{`(${
            product?.reviews || 0
          } reviews)`}</span>
        </div>
      )}
      {thc || cbd || product?.dosage ? (
        <>
          <Heading className={'details__h3'} level={3}>
            Details
          </Heading>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {thc ? (
              <div className={'content__flex'}>
                <Icon className={'icon'} name="vector" />
                <p className={'description'}>
                  THC <span className={'highlighted'}>{thc}</span>
                </p>
              </div>
            ) : null}

            {cbd ? (
              <div className={'content__flex'}>
                <Icon className={'icon'} name="water_drop_black" />
                <p className={'description'}>
                  CBD <span className={'highlighted'}>{cbd}</span>
                </p>
              </div>
            ) : null}

            {product?.dosage ? (
              <div className={'content__flex'}>
                <Icon className={'icon'} name="water_drop_black" />
                <p className={'description'}>
                  <span className={'highlighted'}>{product?.dosage} </span>
                  {product?.amount || ''}
                </p>
              </div>
            ) : null}
          </div>
          <small>
            *This % may represent an aggregate of THC/CBD, THCa/CBDa, THCb/CBDb
            within the product. Consumers should review the actual product label
            for exact % of THC/CBD.
          </small>
        </>
      ) : null}
    </div>
  );
};

export { ProductFeats };
