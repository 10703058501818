import React, { JSX } from 'react';

import { BuildTagsParams, OpenGraphMedia } from '@/types';

const defaults = {
  defaultOpenGraphImageHeight: 0,
  defaultOpenGraphImageWidth: 0,
  defaultOpenGraphVideoHeight: 0,
  defaultOpenGraphVideoWidth: 0,
  nofollow: false,
  noindex: false,
  templateTitle: '',
};

const buildOpenGraphMediaTags = (
  mediaType: 'image' | 'video',
  media: ReadonlyArray<OpenGraphMedia> = [],
  {
    defaultWidth,
    defaultHeight,
  }: { defaultWidth?: number; defaultHeight?: number } = {},
) => {
  return media.reduce((tags, medium, index) => {
    tags.push(
      <meta
        content={medium.url}
        key={`og:${mediaType}:0${index}`}
        property={`og:${mediaType}`}
      />,
    );

    if (medium.alt) {
      tags.push(
        <meta
          content={medium.alt}
          key={`og:${mediaType}:alt0${index}`}
          property={`og:${mediaType}:alt`}
        />,
      );
    }

    if (medium.secureUrl) {
      tags.push(
        <meta
          content={medium.secureUrl.toString()}
          key={`og:${mediaType}:secure_url0${index}`}
          property={`og:${mediaType}:secure_url`}
        />,
      );
    }

    if (medium.type) {
      tags.push(
        <meta
          content={medium.type.toString()}
          key={`og:${mediaType}:type0${index}`}
          property={`og:${mediaType}:type`}
        />,
      );
    }

    if (medium.width) {
      tags.push(
        <meta
          content={medium.width.toString()}
          key={`og:${mediaType}:width0${index}`}
          property={`og:${mediaType}:width`}
        />,
      );
    } else if (defaultWidth) {
      tags.push(
        <meta
          content={defaultWidth.toString()}
          key={`og:${mediaType}:width0${index}`}
          property={`og:${mediaType}:width`}
        />,
      );
    }

    if (medium.height) {
      tags.push(
        <meta
          content={medium.height.toString()}
          key={`og:${mediaType}:height${index}`}
          property={`og:${mediaType}:height`}
        />,
      );
    } else if (defaultHeight) {
      tags.push(
        <meta
          content={defaultHeight.toString()}
          key={`og:${mediaType}:height${index}`}
          property={`og:${mediaType}:height`}
        />,
      );
    }

    return tags;
  }, [] as JSX.Element[]);
};

const buildTags = (config: BuildTagsParams) => {
  const tagsToRender: JSX.Element[] = [];

  if (config.titleTemplate) {
    defaults.templateTitle = config.titleTemplate;
  }

  let updatedTitle = '';
  if (config.title) {
    updatedTitle = config.title;
    if (defaults.templateTitle) {
      updatedTitle = defaults.templateTitle.replace(/%s/g, () => updatedTitle);
    }
  } else if (config.defaultTitle) {
    updatedTitle = config.defaultTitle;
  }

  if (updatedTitle) {
    tagsToRender.push(<title key="title">{updatedTitle}</title>);
  }

  const noindex =
    config.noindex ||
    defaults.noindex ||
    config.dangerouslySetAllPagesToNoIndex;
  const nofollow =
    config.nofollow ||
    defaults.nofollow ||
    config.dangerouslySetAllPagesToNoFollow;

  let robotsParams = '';
  if (config.robotsProps) {
    const {
      nosnippet,
      maxSnippet,
      maxImagePreview,
      maxVideoPreview,
      noarchive,
      noimageindex,
      notranslate,
      unavailableAfter,
    } = config.robotsProps;

    robotsParams = `${nosnippet ? ',nosnippet' : ''}${
      maxSnippet ? `,max-snippet:${maxSnippet}` : ''
    }${maxImagePreview ? `,max-image-preview:${maxImagePreview}` : ''}${
      noarchive ? ',noarchive' : ''
    }${unavailableAfter ? `,unavailable_after:${unavailableAfter}` : ''}${
      noimageindex ? ',noimageindex' : ''
    }${maxVideoPreview ? `,max-video-preview:${maxVideoPreview}` : ''}${
      notranslate ? ',notranslate' : ''
    }`;
  }

  if (noindex || nofollow) {
    if (config.dangerouslySetAllPagesToNoIndex) {
      defaults.noindex = true;
    }
    if (config.dangerouslySetAllPagesToNoFollow) {
      defaults.nofollow = true;
    }

    tagsToRender.push(
      <meta
        content={`${noindex ? 'noindex' : 'index'},${
          nofollow ? 'nofollow' : 'follow'
        }${robotsParams}`}
        key="robots"
        name="robots"
      />,
    );
  } else {
    tagsToRender.push(
      <meta
        content={`index,follow${robotsParams}`}
        key="robots"
        name="robots"
      />,
    );
  }

  if (config.description) {
    tagsToRender.push(
      <meta
        content={config.description}
        key="description"
        name="description"
      />,
    );
  }

  if (config.mobileAlternate) {
    tagsToRender.push(
      <link
        href={config.mobileAlternate.href}
        key="mobileAlternate"
        media={config.mobileAlternate.media}
        rel="alternate"
      />,
    );
  }

  if (config.languageAlternates && config.languageAlternates.length > 0) {
    config.languageAlternates.forEach(languageAlternate => {
      tagsToRender.push(
        <link
          href={languageAlternate.href}
          hrefLang={languageAlternate.hrefLang}
          key={`languageAlternate-${languageAlternate.hrefLang}`}
          rel="alternate"
        />,
      );
    });
  }

  if (config.twitter) {
    if (config.twitter.cardType) {
      tagsToRender.push(
        <meta
          content={config.twitter.cardType}
          key="twitter:card"
          name="twitter:card"
        />,
      );
    }

    if (config.twitter.site) {
      tagsToRender.push(
        <meta
          content={config.twitter.site}
          key="twitter:site"
          name="twitter:site"
        />,
      );
    }

    if (config.twitter.handle) {
      tagsToRender.push(
        <meta
          content={config.twitter.handle}
          key="twitter:creator"
          name="twitter:creator"
        />,
      );
    }
  }

  if (config.facebook) {
    if (config.facebook.appId) {
      tagsToRender.push(
        <meta
          content={config.facebook.appId}
          key="fb:app_id"
          property="fb:app_id"
        />,
      );
    }
  }

  if (config.openGraph?.title || updatedTitle) {
    tagsToRender.push(
      <meta
        content={config.openGraph?.title || updatedTitle}
        key="og:title"
        property="og:title"
      />,
    );
  }

  if (config.openGraph?.description || config.description) {
    tagsToRender.push(
      <meta
        content={config.openGraph?.description || config.description}
        key="og:description"
        property="og:description"
      />,
    );
  }

  if (config.openGraph) {
    if (config.openGraph.url || config.canonical) {
      tagsToRender.push(
        <meta
          content={config.openGraph.url || config.canonical}
          key="og:url"
          property="og:url"
        />,
      );
    }

    if (config.openGraph.type) {
      const type = config.openGraph.type.toLowerCase();

      tagsToRender.push(
        <meta content={type} key="og:type" property="og:type" />,
      );

      if (type === 'profile' && config.openGraph.profile) {
        if (config.openGraph.profile.firstName) {
          tagsToRender.push(
            <meta
              content={config.openGraph.profile.firstName}
              key="profile:first_name"
              property="profile:first_name"
            />,
          );
        }

        if (config.openGraph.profile.lastName) {
          tagsToRender.push(
            <meta
              content={config.openGraph.profile.lastName}
              key="profile:last_name"
              property="profile:last_name"
            />,
          );
        }

        if (config.openGraph.profile.username) {
          tagsToRender.push(
            <meta
              content={config.openGraph.profile.username}
              key="profile:username"
              property="profile:username"
            />,
          );
        }

        if (config.openGraph.profile.gender) {
          tagsToRender.push(
            <meta
              content={config.openGraph.profile.gender}
              key="profile:gender"
              property="profile:gender"
            />,
          );
        }
      } else if (type === 'book' && config.openGraph.book) {
        if (
          config.openGraph.book.authors &&
          config.openGraph.book.authors.length
        ) {
          config.openGraph.book.authors.forEach((author, index) => {
            tagsToRender.push(
              <meta
                content={author}
                key={`book:author:0${index}`}
                property="book:author"
              />,
            );
          });
        }

        if (config.openGraph.book.isbn) {
          tagsToRender.push(
            <meta
              content={config.openGraph.book.isbn}
              key="book:isbn"
              property="book:isbn"
            />,
          );
        }

        if (config.openGraph.book.releaseDate) {
          tagsToRender.push(
            <meta
              content={config.openGraph.book.releaseDate}
              key="book:release_date"
              property="book:release_date"
            />,
          );
        }

        if (config.openGraph.book.tags && config.openGraph.book.tags.length) {
          config.openGraph.book.tags.forEach((tag, index) => {
            tagsToRender.push(
              <meta
                content={tag}
                key={`book:tag:0${index}`}
                property="book:tag"
              />,
            );
          });
        }
      } else if (type === 'article' && config.openGraph.article) {
        if (config.openGraph.article.publishedTime) {
          tagsToRender.push(
            <meta
              content={config.openGraph.article.publishedTime}
              key="article:published_time"
              property="article:published_time"
            />,
          );
        }

        if (config.openGraph.article.modifiedTime) {
          tagsToRender.push(
            <meta
              content={config.openGraph.article.modifiedTime}
              key="article:modified_time"
              property="article:modified_time"
            />,
          );
        }

        if (config.openGraph.article.expirationTime) {
          tagsToRender.push(
            <meta
              content={config.openGraph.article.expirationTime}
              key="article:expiration_time"
              property="article:expiration_time"
            />,
          );
        }

        if (
          config.openGraph.article.authors &&
          config.openGraph.article.authors.length
        ) {
          config.openGraph.article.authors.forEach((author, index) => {
            tagsToRender.push(
              <meta
                content={author}
                key={`article:author:0${index}`}
                property="article:author"
              />,
            );
          });
        }

        if (config.openGraph.article.section) {
          tagsToRender.push(
            <meta
              content={config.openGraph.article.section}
              key="article:section"
              property="article:section"
            />,
          );
        }

        if (
          config.openGraph.article.tags &&
          config.openGraph.article.tags.length
        ) {
          config.openGraph.article.tags.forEach((tag, index) => {
            tagsToRender.push(
              <meta
                content={tag}
                key={`article:tag:0${index}`}
                property="article:tag"
              />,
            );
          });
        }
      } else if (
        (type === 'video.movie' ||
          type === 'video.episode' ||
          type === 'video.tv_show' ||
          type === 'video.other') &&
        config.openGraph.video
      ) {
        if (
          config.openGraph.video.actors &&
          config.openGraph.video.actors.length
        ) {
          config.openGraph.video.actors.forEach((actor, index) => {
            if (actor.profile) {
              tagsToRender.push(
                <meta
                  content={actor.profile}
                  key={`video:actor:0${index}`}
                  property="video:actor"
                />,
              );
            }

            if (actor.role) {
              tagsToRender.push(
                <meta
                  content={actor.role}
                  key={`video:actor:role:0${index}`}
                  property="video:actor:role"
                />,
              );
            }
          });
        }

        if (
          config.openGraph.video.directors &&
          config.openGraph.video.directors.length
        ) {
          config.openGraph.video.directors.forEach((director, index) => {
            tagsToRender.push(
              <meta
                content={director}
                key={`video:director:0${index}`}
                property="video:director"
              />,
            );
          });
        }

        if (
          config.openGraph.video.writers &&
          config.openGraph.video.writers.length
        ) {
          config.openGraph.video.writers.forEach((writer, index) => {
            tagsToRender.push(
              <meta
                content={writer}
                key={`video:writer:0${index}`}
                property="video:writer"
              />,
            );
          });
        }

        if (config.openGraph.video.duration) {
          tagsToRender.push(
            <meta
              content={config.openGraph.video.duration.toString()}
              key="video:duration"
              property="video:duration"
            />,
          );
        }

        if (config.openGraph.video.releaseDate) {
          tagsToRender.push(
            <meta
              content={config.openGraph.video.releaseDate}
              key="video:release_date"
              property="video:release_date"
            />,
          );
        }

        if (config.openGraph.video.tags && config.openGraph.video.tags.length) {
          config.openGraph.video.tags.forEach((tag, index) => {
            tagsToRender.push(
              <meta
                content={tag}
                key={`video:tag:0${index}`}
                property="video:tag"
              />,
            );
          });
        }

        if (config.openGraph.video.series) {
          tagsToRender.push(
            <meta
              content={config.openGraph.video.series}
              key="video:series"
              property="video:series"
            />,
          );
        }
      }
    }

    // images
    if (config.defaultOpenGraphImageWidth) {
      defaults.defaultOpenGraphImageWidth = config.defaultOpenGraphImageWidth;
    }

    if (config.defaultOpenGraphImageHeight) {
      defaults.defaultOpenGraphImageHeight = config.defaultOpenGraphImageHeight;
    }

    if (config.openGraph.images && config.openGraph.images.length) {
      tagsToRender.push(
        ...buildOpenGraphMediaTags('image', config.openGraph.images, {
          defaultHeight: defaults.defaultOpenGraphImageHeight,
          defaultWidth: defaults.defaultOpenGraphImageWidth,
        }),
      );
    }

    // videos
    if (config.defaultOpenGraphVideoWidth) {
      defaults.defaultOpenGraphVideoWidth = config.defaultOpenGraphVideoWidth;
    }

    if (config.defaultOpenGraphVideoHeight) {
      defaults.defaultOpenGraphVideoHeight = config.defaultOpenGraphVideoHeight;
    }

    if (config.openGraph.videos && config.openGraph.videos.length) {
      tagsToRender.push(
        ...buildOpenGraphMediaTags('video', config.openGraph.videos, {
          defaultHeight: defaults.defaultOpenGraphVideoHeight,
          defaultWidth: defaults.defaultOpenGraphVideoWidth,
        }),
      );
    }

    if (config.openGraph.locale) {
      tagsToRender.push(
        <meta
          content={config.openGraph.locale}
          key="og:locale"
          property="og:locale"
        />,
      );
    }

    if (config.openGraph.site_name) {
      tagsToRender.push(
        <meta
          content={config.openGraph.site_name}
          key="og:site_name"
          property="og:site_name"
        />,
      );
    }
  }

  if (config.canonical) {
    tagsToRender.push(
      <link href={config.canonical} key="canonical" rel="canonical" />,
    );
  }

  if (config.additionalMetaTags && config.additionalMetaTags.length > 0) {
    config.additionalMetaTags.forEach(tag => {
      tagsToRender.push(
        <meta
          key={`meta:${
            tag.keyOverride ?? tag.name ?? tag.property ?? tag.httpEquiv
          }`}
          {...tag}
        />,
      );
    });
  }

  if (config.additionalLinkTags?.length) {
    config.additionalLinkTags.forEach(tag => {
      tagsToRender.push(
        <link key={`link${tag.keyOverride ?? tag.href}${tag.rel}`} {...tag} />,
      );
    });
  }

  return tagsToRender;
};

export default buildTags;
