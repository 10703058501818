import { FC } from 'react';
import { StatsSlice, StatsSliceDefault } from 'prismicio-types';
import { PrismicRichText } from '@prismicio/react';

import { Container } from '@/components';
import { getSlicePadding, htmlSerializerWithProps } from '@/utils';

import style from './defaultvariation.module.scss';
import classNames from 'classnames';

interface Stats {
  slice: StatsSliceDefault & StatsSlice;
}

export const DefaultVariation: FC<Stats> = ({ slice }) => {
  const background = slice?.primary?.background_color as string;
  const marginBottom = slice?.primary?.bottom_spacing;
  const borderTop = slice?.primary?.border_section as string;
  const borderBottom = slice?.primary?.border_section as string;
  const paddingSection = slice?.primary?.padding_section;

  return (
    <Container
      as="section"
      className={classNames(
        style.stats__container,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
        },
      )}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      style={{ background, borderTop, borderBottom }}
    >
      {slice?.primary?.title && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              alignSelf: slice.primary.title_align,
              textAlign: slice.primary.title_align,
            },
            style.stats__title,
          )}
          field={slice.primary.title}
        />
      )}
      <dl className={style.stats__list}>
        {slice?.items.map((item, index) => (
          <div
            className={style.stats__element}
            key={`${item.stat_title}-${index}`}
            style={{ alignItems: item.stat_align }}
          >
            <dt className={style.stats__element_title}>{item.stat_title}</dt>
            <dd className={style.stats__element_value}>{item.stat_value}</dd>
          </div>
        ))}
      </dl>
      {slice?.primary?.bottom_description && (
        <PrismicRichText
          components={htmlSerializerWithProps(
            {
              alignSelf: slice.primary.bottom_description_align,
              textAlign: slice.primary.bottom_description_align,
            },
            style.stats__bottom_description,
          )}
          field={slice.primary.bottom_description}
        />
      )}
    </Container>
  );
};
