import { FC } from 'react';
import { getSlicePadding, htmlSerializer } from '@/utils';
import { Container } from '@/components';
import { PrismicRichText } from '@prismicio/react';
import { RichTextContentSlice } from '@/types';
import classNames from 'classnames';
import styles from './richtextcontent.module.scss';

const RichText: FC<RichTextContentSlice> = slice => {
  const marginBottom = slice?.primary?.bottom_spacing;
  const paddingSection = slice?.primary?.padding_section;
  const isFullWidthSlice = slice?.primary?.full_width;

  if (!slice?.primary?.active) {
    return null;
  }

  return (
    <Container
      as="section"
      className={classNames('content', getSlicePadding(paddingSection), {
        [styles.full_width]: isFullWidthSlice,
        ['spacing_bottom']: marginBottom,
      })}
    >
      <PrismicRichText
        components={htmlSerializer}
        field={slice.primary.content}
      />
    </Container>
  );
};

export default RichText;
