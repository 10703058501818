import { SiteConfig } from '@/types';

import { TreezClient } from '../api/';

const client = new TreezClient();

//TODO: move this to a SiteService class

/**
 * Endpoint to load site parameters and configuration
 */
export const getSiteConfig = (): Promise<{ data: SiteConfig }> =>
  client.get('config');
