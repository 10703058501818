import { useCustomerCancelOrder } from '@/data';
import { Button } from '@/components';
import { useNotify } from '@/hooks';
import startCase from 'lodash/startCase';
import { useRouter } from 'next/navigation';

const SUCCESS_MESSAGE = 'Order Cancelled Successfully';

const CancelOrderButton = ({
  entityId,
  color,
  className,
  navigateTo,
  callback,
}: {
  entityId: string;
  color: 'link' | 'primary' | 'secondary';
  className?: string;
  navigateTo?: string;
  callback?: () => void;
}) => {
  const {
    cancelOrder,
    result: { loading, error },
  } = useCustomerCancelOrder({
    variables: {
      entity_id: entityId,
    },
  });

  const router = useRouter();

  const { notify } = useNotify();

  const handleCancelOrder = async () => {
    try {
      await cancelOrder();

      if (error) {
        notify('error', startCase(error.message));

        return;
      }

      notify('success', SUCCESS_MESSAGE);
      if (navigateTo) {
        router.push(navigateTo);
        return;
      }

      if (callback) {
        callback();
      }
    } catch (err) {
      notify('error', startCase(err.message));

      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
  return (
    <Button
      className={className}
      color={color}
      loading={loading}
      onClick={() => {
        handleCancelOrder();
      }}
      size="lg"
    >
      Cancel Order
    </Button>
  );
};

export default CancelOrderButton;
