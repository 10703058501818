import { FC } from 'react';
import classNames from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { PrismicNextImage } from '@prismicio/next';

import { getSlicePadding } from '@/utils';
import { Container, Heading, Icon, Link } from '@/components';
import { ImageRowWithHeadingAndButtonSlice as ImageRow } from '@/types';
import {
  useFilteredSliceItemsByLocationSlug,
  useShowByLocationSlug,
} from '@/hooks';

import style from './imagerowwithheadingandbutton.module.scss';

/* eslint-disable-next-line max-len */
const ImageRowWithHeadingAndButton: FC<ImageRow> = slice => {
  const { showContent } = useShowByLocationSlug(slice?.primary?.location_slug);
  const { filteredItems } = useFilteredSliceItemsByLocationSlug(slice?.items);

  const paddingSection = slice?.primary?.padding_section;
  const marginBottom = slice?.primary?.margin_bottom;
  const backgroundColor = slice?.primary?.background;
  const backgroundImage = slice?.primary?.background_image?.url;
  const backgroundStyleFixed = slice?.primary?.background_style_fixed_cover;
  const minHeight = slice?.primary?.min_heigth;

  const sizes =
    /* eslint-disable-next-line max-len */
    '(min-width: 2048px) 412px, (min-width: 1536px) 304px, (min-width: 1280px) 247px, (min-width: 1024px) 208px, (min-width: 420px) 50wv, 100wv';

  if (!slice?.primary?.active || !showContent) {
    return null;
  }

  return (
    <section
      className={classNames(
        style.image_row__section,
        getSlicePadding(paddingSection),
        {
          ['spacing_bottom']: marginBottom,
          ['background__fixed_cover']: backgroundStyleFixed,
          ['background__contained']: !backgroundStyleFixed,
        },
      )}
      style={{
        background: backgroundColor!,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        minHeight: `${minHeight || 0}vh`,
      }}
    >
      <Container className={style.container}>
        <div className={style.content}>
          <div
            className={classNames(style.body, {
              [style.body__square_picture]: slice.variation === 'squarePicture',
            })}
          >
            {slice.variation === 'default' && (
              <Heading
                className={style.body__text_heading}
                color={slice.primary?.heading_color!}
                level={3}
              >
                {prismicH.asText(slice.primary?.heading)}
              </Heading>
            )}
            <Heading
              className={style.body__title}
              color={slice.primary?.title_color!}
              level={2}
            >
              {prismicH.asText(slice.primary?.title)}
            </Heading>
            {slice.variation === 'squarePicture' && (
              <PrismicRichText
                components={{
                  paragraph: ({ children }) => (
                    <p
                      style={{
                        color: slice.primary?.description_color!,
                      }}
                    >
                      {children}
                    </p>
                  ),
                }}
                field={slice.primary?.description}
              />
            )}
            <div className={style.body__contents_images}>
              {filteredItems.map((img, index) => {
                return (
                  <figure
                    className={classNames(style.body__figure, {
                      /* eslint-disable-next-line max-len */
                      [style.body__figure_border_radius_under_sm]:
                        !slice.primary?.background,
                    })}
                    key={index}
                  >
                    {img.image?.url ? (
                      <PrismicNextImage
                        fallbackAlt=""
                        field={img.image}
                        fill
                        sizes={sizes}
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <Icon name="image" />
                    )}
                  </figure>
                );
              })}
            </div>
            {slice.primary?.button_text && (
              <Link
                className={classNames({
                  [style.body__button_primary]:
                    slice.primary?.button_type === 'Button',
                })}
                href={slice.primary?.button_link ?? '/'}
                style={{
                  background: slice.primary?.button_background!,
                  color: slice.primary?.button_text_color!,
                }}
              >
                {slice.primary?.button_text}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ImageRowWithHeadingAndButton;
